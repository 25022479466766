

const satelitesData = `0 VANGUARD 2
1    11U 59001A   22053.83197560  .00000847  00000-0  45179-3 0  9996
2    11  32.8647 264.6509 1466352 126.0358 248.5175 11.85932318689790
0 VANGUARD 3
1 00020U 59007A   22053.60170665  .00000832  00000-0  32375-3 0  9992
2 00020  33.3540 150.1993 1666456 290.4879  52.4980 11.56070084301793
0 EXPLORER 7
1 00022U 59009A   22053.49750630  .00000970  00000-0  93426-4 0  9997
2 00022  50.2831  94.4956 0136813  90.0531 271.6094 14.96180956562418
0 TIROS 1
1    29U 60002B   22053.73599453  .00000075  00000-0  41992-4 0  9997
2    29  48.3791 284.6069 0024160 344.1103  15.9048 14.74533417309456
0 TRANSIT 2A
1    45U 60007A   22053.78590077  .00000201  00000-0  76203-4 0  9992
2    45  66.6938 199.2201 0260624 228.7924 129.0448 14.33783651210919
0 SOLRAD 1 (GREB)
1 00046U 60007B   22053.56859360  .00000150  00000-0  52806-4 0  9998
2 00046  66.6903 184.2260 0206083  30.7482 330.5510 14.49531925228257
0 COURIER 1B
1 00058U 60013A   22053.57007590  .00000103  00000-0  27843-4 0  9990
2 00058  28.3254 213.9685 0164896 121.8823 239.7880 13.46331018 21563
0 EXPLORER 11
1 00107U 61013A   22053.36462913  .00001102  00000-0  12806-3 0  9999
2 00107  28.7906 307.3320 0544222 325.7790  30.8820 14.09575093 53048
0 TRANSIT 4A
1 00116U 61015A   22053.59491362 -.00000005  00000-0  54851-4 0  9995
2 00116  66.8101 144.3927 0081903 295.7647  63.4996 13.91813101 77406
0 SOLRAD 3/INJUN 1
1   117U 61015B   22053.79061784 -.00000049  00000-0  22091-4 0  9998
2   117  66.8101 244.7414 0082529 316.1382  43.3161 13.89881521 75544
0 TIROS 3
1 00162U 61017A   22053.46597274 -.00000070  00000-0  26849-4 0  9991
2 00162  47.9009 328.3344 0044533 162.9380 197.3021 14.44121209163949
0 TRANSIT 4B
1 00202U 61031A   22053.44440530  .00000075  00000-0  64572-4 0  9991
2 00202  32.4390 333.3810 0101119 127.6129 233.3720 13.63561486  5782
0 TRAAC
1   205U 61031B   22053.81871020  .00000048  00000-0  29606-4 0  9996
2   205  32.4412 111.3714 0102305 273.2854  85.6067 13.62426388  4587
0 TIROS 4
1 00226U 62002A   22053.55470977 -.00000073  00000-0  26949-4 0  9991
2 00226  48.2966 294.6206 0078438 246.1904 113.0756 14.45747526140364
0 TIROS 5
1 00309U 62025A   22053.49776455  .00000052  00000-0  45126-4 0  9995
2 00309  58.0907 234.2031 0187097 259.3128  98.6784 14.57001830151929
0 FTV 3502
1   369U 62039A   22053.83720329  .00000503  00000-0  63534-4 0  9997
2   369  98.4743   2.4600 0091774 178.0695 182.0890 14.84082654181797
0 TIROS 6
1   397U 62047A   22053.72178655  .00000404  00000-0  65962-4 0  9991
2   397  58.3050 189.8944 0013697 121.8001 238.4380 14.88736291198537
0 ALOUETTE 1 (S-27)
1 00424U 62049A   22053.41475221  .00000047  00000-0  44206-4 0  9990
2 00424  80.4639  15.1233 0022989 345.1338  14.9129 13.69192853965647
0 ANNA 1B
1   446U 62060A   22053.81766611 -.00000119  00000-0  14384-4 0  9992
2   446  50.1412 289.0608 0070306  19.7513 102.8060 13.35283453893637
0 EXPLORER 16
1 00506U 62070A   22053.30995854 -.00000109  00000-0  23050-4 0  9996
2 00506  52.0028 276.5814 0276698 310.2412  47.4602 13.85206712990314
0 HITCH HIKER 1
1 00614U 63025B   22053.51365970  .00020198 -10814-5  34773-3 0  9992
2 00614  81.9763 228.9109 0502466  87.8717 277.9922 14.72876469687338
0 TRANSIT 5B-1
1 00670U 63038B   22053.30437135  .00000066  00000-0  97197-4 0  9990
2 00670  90.0289 333.9670 0039860 223.5583 136.2415 13.44369388862421
0 RADIATION SAT (5E 1)
1   671U 63038C   22053.84386399  .00000083  00000-0  12579-3 0  9994
2   671  90.0297 333.9349 0039918 180.8481 179.2606 13.44890822863859
0 TRANSIT 5B-2
1 00704U 63049B   22053.62734081  .00000038  00000-0  46921-4 0  9993
2 00704  89.8998  84.4781 0035849 145.3699 326.0739 13.46878690859220
0 TRANSIT 5E 3
1 00705U 63049C   22053.56100324  .00000035  00000-0  41584-4 0  9991
2 00705  89.9002  84.4181 0035881 107.2552   2.4068 13.47374399859928
0 TIROS 8
1 00716U 63054A   22053.50793386  .00000090  00000-0  45917-4 0  9994
2 00716  58.4949  96.5697 0027319  41.8364 318.4756 14.69684003 83832
0 GGSE 1 (GGRS)
1 00728U 64001B   22053.58217419  .00000009  00000-0  44901-4 0  9998
2 00728  69.9102 355.1870 0015643 224.9707 135.0132 13.97218465959548
0 SECOR 1B
1 00729U 64001C   22053.44564916 -.00000047  00000-0  50977-5 0  9995
2 00729  69.8999  72.1889 0016708 292.1532  67.7799 13.94933169957005
0 SOLRAD 7A
1 00730U 64001D   22053.44938698 -.00000019  00000-0  25671-4 0  9990
2 00730  69.9057  59.7776 0016843 258.6897 209.7621 13.95665137840753
0 GREB
1   731U 64001E   22053.76509136 -.00000006  00000-0  35146-4 0  9991
2   731  69.9059  50.6671 0016844 255.0782 104.8458 13.95890813958175
0 OPS 3367 A
1   734U 64002B   22053.85166267  .00000008  00000-0  22243-4 0  9994
2   734  99.1029 269.7912 0015536  73.7958 286.4930 14.28928739 23821
0 OPS 3367 B
1   735U 64002C   22053.80410084  .00000009  00000-0  22927-4 0  9999
2   735  99.1248 263.7937 0016656 178.8911 181.2308 14.27879024 21916
0 OPS 4412 (TRANSIT 9)
1   801U 64026A   22053.71494511  .00000221  00000-0  10805-3 0  9993
2   801  90.5169 102.7153 0050726  70.1058 290.5585 14.14811931963635
0 OPS 4467 A
1 00812U 64031A   22053.57749780  .00000031  00000-0  36729-4 0  9999
2 00812  99.7969   6.9897 0005579 173.6966 186.4280 14.23921954992015
0 OPS 4467 B
1 00813U 64031B   22053.59518480  .00000023  00000-0  33908-4 0  9990
2 00813  99.8047   5.2050 0004557 263.5174  96.5482 14.23101389990770
0 EXPLORER 20
1   870U 64051A   22053.83422166  .00000073  00000-0  48959-4 0  9994
2   870  79.8993 192.2499 0098085 102.1807 259.0356 13.91204999914828
0 COSMOS 44
1 00876U 64053A   22053.07740883  .00000000  00000-0  23374-4 0  9991
2 00876  65.0610 295.3133 0133852 167.5314 192.9156 14.63847306 57834
0 OPS 5798 (TRANSIT 5B-4)
1 00897U 64063B   22053.50155365  .00000056  00000-0  69242-4 0  9993
2 00897  90.1802  41.6412 0021561  79.0093  32.6471 13.53476912832082
0 EXPLORER 22
1 00899U 64064A   22053.51089747  .00000099  00000-0  82208-4 0  9991
2 00899  79.6895  49.9973 0119566 294.4464  64.4251 13.82213343887223
0 CALSPHERE 1
1 00900U 64063C   22053.46035986  .00000375  00000-0  39125-3 0  9999
2 00900  90.1695  38.8095 0028042  12.2902 109.1497 13.73689201854879
0 CALSPHERE 2
1 00902U 64063E   22053.51688094  .00000019  00000-0  14990-4 0  9990
2 00902  90.1812  41.8087 0020104 118.4932   2.1025 13.52707185643234
0 EXPLORER 25 (INJUN-4)
1 00932U 64076B   22053.53084652  .00000453  00000-0  16203-3 0  9999
2 00932  81.3443 147.9111 1131379 232.9255 116.3130 12.64152768619818
0 OPS 6582 (TRANSIT 5E-5)
1 00959U 64083C   22053.44930219  .00000123  00000-0  14811-3 0  9992
2 00959  89.9955 187.1802 0040356 273.1301 153.5573 13.61714251835705
0 OPS 6582 (TRANSIT 5B-5)
1   965U 64083D   22053.82091714  .00000053  00000-0  61878-4 0  9997
2   965  90.0266 195.0823 0040356 201.6689 277.6017 13.56769407830541
0 TIROS 9
1 00978U 65004A   22053.47621103  .00000229  00000-0  30860-3 0  9998
2 00978  96.3873 228.7465 1158468 201.2825 153.6047 12.10907713520889
0 SECOR 3
1 01208U 65016E   22053.44415196 -.00000018  00000-0  25430-4 0  9993
2 01208  70.0796 299.0083 0020406 349.4893 183.5198 13.95637262899325
0 GGSE 2
1 01244U 65016B   22053.41356298  .00000009  00000-0  44716-4 0  9994
2 01244  70.0795 301.7329 0021011 352.9822   7.0986 13.95694985898219
0 OPS 4988 (GREB 6)
1 01271U 65016A   22053.40533134 -.00000013  00000-0  28909-4 0  9991
2 01271  70.0784 276.2274 0021667 336.0082 197.5394 13.96179303898862
0 PORCUPINE 1
1  1272U 65016H   22053.84055333 -.00000001  00000-0  38285-4 0  9995
2  1272  70.0822 355.4838 0021002  21.9802 338.2199 13.94246820897251
0 SOLRAD 7B
1  1291U 65016D   22053.84038263 -.00000016  00000-0  27042-4 0  9994
2  1291  70.0818 344.4770 0019820  21.5097 338.6836 13.94627105897528
0 GGSE 3
1 01292U 65016C   22053.56056613  .00000040  00000-0  62240-4 0  9998
2 01292  70.0765  96.2613 0022649 241.7699 118.1123 14.00689668903859
0 OSCAR 3
1  1293U 65016F   22053.84710079  .00000369  00000-0  25508-3 0  9992
2  1293  70.0729 219.5747 0015031 102.5214 257.7580 14.06565318911017
0 OPS 4682 (SNAPSHOT)
1 01314U 65027A   22053.50655189  .00000010  00000-0  93600-5 0  9994
2 01314  90.2764 330.7186 0032027  79.3973  93.6088 12.91764428427594
0 SECOR 4 (EGRS 4)
1 01315U 65027B   22053.55793323 -.00000021  00000-0 -10398-3 0  9998
2 01315  90.2665 340.1398 0031126 357.7605 178.7343 12.92616797683363
0 EXPLORER 27
1  1328U 65032A   22053.80963938 -.00000079  00000-0  10993-4 0  9991
2  1328  41.1835 277.0032 0257724  31.9870 329.6247 13.38665446777622
0 OPS 8480 (TRANSIT 5B-6)
1 01420U 65048A   22053.61192285  .00000062  00000-0  80272-4 0  9997
2 01420  89.9154 303.1783 0072953 318.0061 102.2834 13.51428028790612
0 TIROS 10
1  1430U 65051A   22053.81490283  .00000075  00000-0  36366-4 0  9992
2  1430  98.3570 173.9639 0057333 132.1031 228.5053 14.40348889969471
0 SECOR 5 (EGRS 5)
1 01506U 65063A   22053.57605197 -.00000002  00000-0  22080-3 0  9992
2 01506  69.2331 110.4917 0786147 334.3597 130.4713 11.78647783432570
0 DODECAPOLE 2
1 01510U 65065C   22053.55894276  .00002400  00000-0  17328-2 0  9996
2 01510  90.0369  16.7479 0041006 118.3382 242.1940 13.95999631812285
0 TEMPSAT 1
1 01512U 65065E   22053.50623625  .00000027  00000-0  36381-4 0  9996
2 01512  89.9043 219.6319 0069715 354.8397  66.5528 13.33445598750056
0 OPS 8464 (TRANSIT 5B-7)
1  1514U 65065F   22053.75676480  .00000061  00000-0  10224-3 0  9997
2  1514  89.8874 236.4905 0068395 278.0516  92.1111 13.35003479751571
0 NAVSPASUR ROD 1
1 01515U 65065G   22053.43511465  .00000301  00000-0  46167-3 0  9996
2 01515  89.9333 217.6432 0056795 183.2404 230.3749 13.48986541767735
0 CALSPHERE 4(A)
1 01520U 65065H   22053.53714337  .00000068  00000-0  11396-3 0  9992
2 01520  90.0019 130.2159 0068507 225.9878 242.1196 13.35871043752315
0 COSMOS 80
1 01570U 65070A   22053.42817994 -.00000102  00000-0 -37058-4 0  9999
2 01570  56.0559  65.9042 0101339 346.8179 162.8037 12.52767439583881
0 COSMOS 81
1  1571U 65070B   22053.68714169 -.00000095  00000-0  44863-5 0  9991
2  1571  56.0594 124.1061 0090071 129.1494  36.4490 12.48837545576244
0 COSMOS 82
1 01572U 65070C   22053.59885359 -.00000077  00000-0  11414-3 0  9996
2 01572  56.0591 150.8466 0073814 316.1560 142.9804 12.45094829567963
0 COSMOS 83
1 01573U 65070D   22053.59248050 -.00000085  00000-0  58474-4 0  9995
2 01573  56.0521 205.2616 0069494 143.8255 216.7376 12.41018763559610
0 COSMOS 84
1 01574U 65070E   22053.47373271 -.00000091  00000-0  96108-5 0  9991
2 01574  56.0512 257.0851 0062414 321.5221 108.1175 12.37010033551452`

const cableData = {
  "type": "FeatureCollection",
  "name": "submarine_cables",
  "crs": {
    "type": "name",
    "properties": {
      "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
    }
  },
  "features": [
    {
      "type": "Feature",
      "properties": {
        "id": "jeju-udo",
        "name": "Jeju-Udo",
        "color": "#3a55a5",
        "feature_id": "jeju-udo-0",
        "coordinates": [
          126.93252888037213,
          33.473453760532834
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              126.95575023131644,
              33.505336013809085
            ],
            [
              126.90930752942782,
              33.44157150725661
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "domestic-submarine-cable-of-maldives-dscom",
        "name": "Domestic Submarine Cable of Maldives (DSCoM)",
        "color": "#939597",
        "feature_id": "domestic-submarine-cable-of-maldives-dscom-0",
        "coordinates": [
          72.75232269632019,
          3.153668530783847
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              72.95565310576106,
              3.608067
            ],
            [
              72.8363099155325,
              3.472925055920427
            ],
            [
              72.75232269632019,
              3.276079687360095
            ],
            [
              72.75232269632019,
              2.939076705497968
            ],
            [
              72.89329549798757,
              2.670853794396919
            ]
          ],
          [
            [
              73.54027213872521,
              4.211914
            ],
            [
              73.6312720742599,
              4.164912849976844
            ],
            [
              73.6312720742599,
              4.052702097268195
            ],
            [
              73.48945837504746,
              3.940895595045532
            ]
          ],
          [
            [
              73.2750658812584,
              5.662178353024018
            ],
            [
              73.0413633070014,
              5.629270004148879
            ],
            [
              73.12502243289185,
              5.435413643888296
            ],
            [
              73.12502243289185,
              5.211384397097532
            ],
            [
              73.07078247131611,
              5.103264
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "nome-to-homer-express-nthe",
        "name": "Nome to Homer Express (NTHE)",
        "color": "#939597",
        "feature_id": "nome-to-homer-express-nthe-0",
        "coordinates": [
          -163.381820177689,
          59.01602594562024
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -165.4063989572757,
              64.50111053726943
            ],
            [
              -165.5998084539864,
              64.31739001144422
            ],
            [
              -165.71230837250218,
              63.08820652156504
            ],
            [
              -165.59980845219815,
              62.47071999993716
            ]
          ],
          [
            [
              -153.63215500000013,
              59.682359410641055
            ],
            [
              -153.56295197922017,
              59.63553746032122
            ],
            [
              -153.51700201293235,
              59.61807806397951
            ],
            [
              -152.99981737755388,
              59.622821769410336
            ],
            [
              -152.09981801512194,
              59.65125477763377
            ],
            [
              -151.54939840564037,
              59.6425
            ]
          ],
          [
            [
              -155.886297,
              59.330386046597624
            ],
            [
              -155.7763760225493,
              59.387330229698335
            ],
            [
              -155.2649563977633,
              59.49092999474283
            ],
            [
              -154.64737685086388,
              59.66780559050696
            ],
            [
              -154.39200703822144,
              59.71199027213399
            ],
            [
              -154.245127145983,
              59.715928987059755
            ],
            [
              -154.1232572353955,
              59.726169492870326
            ],
            [
              -154.0357572995917,
              59.74821803436765
            ],
            [
              -153.9545073592025,
              59.771825338743014
            ],
            [
              -153.88049330000013,
              59.776099309178704
            ]
          ],
          [
            [
              -164.64552912821856,
              62.68469323703468
            ],
            [
              -164.92480893037413,
              62.60043154711258
            ],
            [
              -165.59980845219815,
              62.47071999993716
            ],
            [
              -166.49980781463012,
              61.94619734289441
            ],
            [
              -166.49980781463012,
              61.087844719599644
            ],
            [
              -165.8248082928061,
              60.649722744668196
            ],
            [
              -165.3748086115901,
              60.20556098402312
            ],
            [
              -162.78731044459806,
              58.66118687773917
            ],
            [
              -162.11231092277413,
              58.36740335134627
            ],
            [
              -161.77481116186206,
              58.30835113805557
            ],
            [
              -159.29981291517413,
              58.130601640227894
            ],
            [
              -158.39981355274205,
              58.24920018303547
            ],
            [
              -157.6123141106141,
              58.60262683506721
            ],
            [
              -157.0126656292296,
              58.732118990121364
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "mercator",
        "name": "Mercator",
        "color": "#b9bc33",
        "feature_id": "mercator-0",
        "coordinates": [
          2.1884910115073524,
          51.37667550018287
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              1.440972309852697,
              51.35859517845756
            ],
            [
              1.800052055477209,
              51.37667550018287
            ],
            [
              2.475051577301144,
              51.37667550018287
            ],
            [
              2.912751267230703,
              51.231244364752285
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "iceni",
        "name": "Iceni",
        "color": "#939597",
        "feature_id": "iceni-0",
        "coordinates": [
          3.201748854214088,
          52.790150905196924
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              1.693552130326793,
              52.71521693088616
            ],
            [
              2.250051736098101,
              52.782345847007605
            ],
            [
              3.15005109853027,
              52.782345847007605
            ],
            [
              4.05005046096224,
              52.91822306839288
            ],
            [
              4.700749999999573,
              52.836929
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tam-1",
        "name": "TAM-1",
        "color": "#939597",
        "feature_id": "tam-1-0",
        "coordinates": [
          -76.1834522524671,
          16.828473610088707
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -68.84987695073829,
              21.30959038531813
            ],
            [
              -70.1998760205634,
              20.16397505691083
            ],
            [
              -70.69118567251498,
              19.799436380789984
            ]
          ],
          [
            [
              -80.39426772306935,
              27.638767999999487
            ],
            [
              -79.87486918054941,
              27.16466581281361
            ],
            [
              -79.87486918054941,
              26.361086325391653
            ],
            [
              -80.16016572306934,
              26.010495
            ]
          ],
          [
            [
              -79.64986933934539,
              9.96791518697421
            ],
            [
              -82.34986742664137,
              10.078698006650882
            ],
            [
              -83.03765938887462,
              9.988597517410057
            ]
          ],
          [
            [
              -78.29987029569733,
              11.95585820711483
            ],
            [
              -75.3748723683893,
              11.331480662183736
            ],
            [
              -74.77975278938158,
              10.94044561572673
            ]
          ],
          [
            [
              -70.19987599438633,
              22.14563885230831
            ],
            [
              -71.54987499861143,
              21.425997872385494
            ],
            [
              -73.34987380232134,
              20.796306105108954
            ],
            [
              -74.13737324444938,
              19.952622905164304
            ],
            [
              -74.69987284596938,
              19.316876111628602
            ],
            [
              -75.14987252718537,
              18.251816319028308
            ],
            [
              -75.9373719693134,
              17.39502263470061
            ],
            [
              -78.29987029569733,
              11.95585820711483
            ],
            [
              -79.64986933934539,
              9.96791518697421
            ],
            [
              -79.90000916273998,
              9.354125999999837
            ]
          ],
          [
            [
              -87.97486344184158,
              16.53419619825962
            ],
            [
              -87.86236352213346,
              16.10232559580288
            ],
            [
              -87.94615578765044,
              15.844981598742493
            ]
          ],
          [
            [
              -80.16016897784428,
              26.01054866801087
            ],
            [
              -80.09986902056146,
              25.348717422116806
            ],
            [
              -80.77486854238539,
              24.73717827217618
            ],
            [
              -80.99986838299361,
              24.532657566160623
            ],
            [
              -83.24986678907334,
              24.532657566160623
            ],
            [
              -84.82486567332933,
              23.50508968095727
            ],
            [
              -85.94986487636933,
              21.635297384859456
            ],
            [
              -86.28736463787756,
              20.375041253465525
            ],
            [
              -87.07486407940934,
              18.251816319028308
            ],
            [
              -87.97486344184158,
              16.53419619825962
            ],
            [
              -88.31236320275345,
              16.10232559580288
            ],
            [
              -88.597165,
              15.727178884973625
            ]
          ],
          [
            [
              -86.06236479726944,
              21.635297384859456
            ],
            [
              -86.51236447788934,
              21.268825931479142
            ],
            [
              -86.767566,
              21.09566348377342
            ]
          ],
          [
            [
              -66.10666893347562,
              18.466104232947515
            ],
            [
              -66.03732898259669,
              18.67864702215462
            ],
            [
              -65.69987922164925,
              18.838433217733098
            ],
            [
              -65.3239855347919,
              18.62408068875814
            ],
            [
              -65.08112965997728,
              18.251816319028308
            ],
            [
              -64.74698,
              17.727510000000336
            ]
          ],
          [
            [
              -80.16016897784428,
              26.01054866801087
            ],
            [
              -79.64986933934539,
              25.8559854660721
            ],
            [
              -79.1998696581294,
              25.8559854660721
            ],
            [
              -77.5123708535694,
              25.754704263415306
            ],
            [
              -76.8311713361375,
              25.914589918767092
            ],
            [
              -76.24064175447417,
              25.700526109038975
            ],
            [
              -73.34987380232134,
              24.12261698700334
            ],
            [
              -70.19987599438633,
              22.14563885230831
            ],
            [
              -68.84987695073829,
              21.30959038531813
            ],
            [
              -67.49987794710927,
              20.375041253465525
            ],
            [
              -66.48737866377729,
              19.104405475930548
            ],
            [
              -66.10666893347562,
              18.466104232947515
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "cross-sound-cable",
        "name": "Cross Sound Cable",
        "color": "#b91e73",
        "feature_id": "cross-sound-cable-0",
        "coordinates": [
          -72.87873601507879,
          41.136516011319685
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -72.92498345896666,
              41.30713
            ],
            [
              -72.87702413788848,
              41.14638300214594
            ],
            [
              -72.90921845896675,
              40.9608200000003
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tautira-teahupoo",
        "name": "Tautira-Teahupo'o",
        "color": "#939597",
        "feature_id": "tautira-teahupoo-0",
        "coordinates": [
          -149.14107347695295,
          -17.917633749535156
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -149.17703511077096,
              -17.771815724223984
            ],
            [
              -149.11257091218948,
              -17.83019163808808
            ],
            [
              -149.1216951254482,
              -17.913887619740336
            ],
            [
              -149.2422561184836,
              -17.93719389158673
            ],
            [
              -149.24856523377213,
              -17.849433231595242
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "whidbey-island-seattle",
        "name": "Whidbey Island-Seattle",
        "color": "#ed166f",
        "feature_id": "whidbey-island-seattle-0",
        "coordinates": [
          -122.42525525402752,
          47.76389238568785
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -122.44329313719007,
              47.93851635072443
            ],
            [
              -122.46918400633714,
              47.90307887010553
            ],
            [
              -122.44105402626477,
              47.82760546971153
            ],
            [
              -122.41293050095533,
              47.71418928115125
            ],
            [
              -122.32943910533376,
              47.60356
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "whidbey-island-everett",
        "name": "Whidbey Island-Everett",
        "color": "#55b847",
        "feature_id": "whidbey-island-everett-0",
        "coordinates": [
          -122.281925,
          47.9780225
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -122.357431,
              47.97947
            ],
            [
              -122.206419,
              47.97657500000014
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "whidbey-island-hat-island",
        "name": "Whidbey Island-Hat Island",
        "color": "#ac4a9b",
        "feature_id": "whidbey-island-hat-island-0",
        "coordinates": [
          -122.33861676704005,
          47.99652985730455
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -122.31980253408013,
              48.01358971460917
            ],
            [
              -122.357431,
              47.97947
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "whidbey-island-camano-island",
        "name": "Whidbey Island-Camano Island",
        "color": "#cc1d53",
        "feature_id": "whidbey-island-camano-island-0",
        "coordinates": [
          -122.54171809627985,
          48.120442407144
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -122.50972582333554,
              48.14223038529161
            ],
            [
              -122.57371036922413,
              48.09865442899638
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "israel-coasting-1-ic-1",
        "name": "Israel Coasting 1 (IC-1)",
        "color": "#3864af",
        "feature_id": "israel-coasting-1-ic-1-0",
        "coordinates": [
          34.827748751917085,
          32.30639880955948
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              34.99874016776002,
              32.81159600000012
            ],
            [
              34.76254960982348,
              32.62301664000799
            ],
            [
              34.65004968951936,
              32.24321001626265
            ],
            [
              34.70629964907562,
              32.13213222598799
            ],
            [
              34.76965816775976,
              32.04504300000022
            ],
            [
              34.70629964907562,
              32.03681490022359
            ],
            [
              34.537549768619556,
              31.989118978289895
            ],
            [
              34.48129980846758,
              31.79808736758517
            ],
            [
              34.55600816776038,
              31.66950999999952
            ]
          ],
          [
            [
              35.106361580236154,
              33.036463
            ],
            [
              34.987549449835626,
              32.93829672849765
            ],
            [
              34.998746580236485,
              32.81159600000012
            ],
            [
              34.818799569379536,
              32.62301664000799
            ],
            [
              34.818799569379536,
              32.43331330641712
            ],
            [
              34.871974580235985,
              32.343948000000324
            ],
            [
              34.79067458930365,
              32.27492161975164
            ],
            [
              34.84467858023666,
              32.16241300000027
            ],
            [
              34.76254960922759,
              32.13213222598799
            ],
            [
              34.76966458023625,
              32.04504300000022
            ],
            [
              34.734424629151505,
              32.012970042085556
            ],
            [
              34.78510959324598,
              31.97782943227122
            ],
            [
              34.537549768619556,
              31.79808736758517
            ],
            [
              34.55601458023594,
              31.66950999999952
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "zayo-festoon",
        "name": "Zayo Festoon",
        "color": "#b58636",
        "feature_id": "zayo-festoon-0",
        "coordinates": [
          -119.92123784718282,
          34.25287625691138
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -120.6625517851584,
              35.285426999999714
            ],
            [
              -120.93734009150975,
              35.082922700290226
            ],
            [
              -121.04984001181379,
              34.80627255689055
            ],
            [
              -120.5998403305978,
              34.25018044028607
            ],
            [
              -119.92484080877378,
              34.25018044028607
            ],
            [
              -119.69887249218246,
              34.41925499999968
            ],
            [
              -119.69984096816574,
              34.157137999942535
            ],
            [
              -119.24984128694976,
              33.970745364072826
            ],
            [
              -118.79984160513769,
              33.98629373718458
            ],
            [
              -118.24535355799173,
              34.053396879397155
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "fish-south",
        "name": "FISH South",
        "color": "#939597",
        "feature_id": "fish-south-0",
        "coordinates": [
          -140.05905152238466,
          59.52316531950407
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -140.06232654318993,
              59.52787100202224
            ],
            [
              -139.9498266228859,
              59.584873612944676
            ],
            [
              -139.8373267025819,
              59.584873612944676
            ],
            [
              -139.67348595914996,
              59.54576177245809
            ]
          ],
          [
            [
              -136.55056012798295,
              58.139185632898716
            ],
            [
              -136.46232909346188,
              58.100890131941725
            ],
            [
              -136.34982917315784,
              58.041392779610334
            ],
            [
              -136.22857036481378,
              57.960740659443644
            ]
          ],
          [
            [
              -135.7696107009433,
              58.33071335288972
            ],
            [
              -135.72082551682632,
              58.41785832955844
            ]
          ],
          [
            [
              -135.35237100706024,
              58.193091788204356
            ],
            [
              -135.4338053588249,
              58.11192247006406
            ]
          ],
          [
            [
              -145.75467337578328,
              60.54274832427731
            ],
            [
              -145.96857321885105,
              60.48387290544699
            ],
            [
              -146.0248223193019,
              60.42840036629741
            ],
            [
              -145.79982247869395,
              60.20556098402312
            ],
            [
              -144.56232335534995,
              59.69858962318878
            ],
            [
              -142.64982471018192,
              59.755303236949885
            ],
            [
              -141.52482550714188,
              59.52787100202261
            ],
            [
              -140.06232654318993,
              59.52787100202224
            ],
            [
              -139.49982694166982,
              58.7196487955913
            ],
            [
              -137.6998282168058,
              58.130601640227894
            ],
            [
              -136.55056012798295,
              58.139185632898716
            ],
            [
              -136.47756018154095,
              58.211281169306204
            ],
            [
              -136.39207024426244,
              58.24491118647009
            ],
            [
              -136.28406032350637,
              58.25896032097742
            ],
            [
              -136.11301044900068,
              58.27149460377814
            ],
            [
              -135.98699054145797,
              58.29757462462189
            ],
            [
              -135.88347061740754,
              58.31409797025887
            ],
            [
              -135.7696107009433,
              58.33071335288972
            ],
            [
              -135.6318608020065,
              58.32837695835548
            ],
            [
              -135.54587086509486,
              58.29993407371461
            ],
            [
              -135.44749093727344,
              58.257987058578095
            ],
            [
              -135.35237100706024,
              58.193091788204356
            ],
            [
              -135.27131106653152,
              58.1693557186386
            ],
            [
              -135.17493000487144,
              58.14951957724293
            ],
            [
              -135.06389121870967,
              58.15714408352042
            ],
            [
              -134.97296901308127,
              58.365509963813906
            ],
            [
              -134.9721951498168,
              58.42899050279709
            ],
            [
              -134.92327132187847,
              58.43604469305944
            ],
            [
              -134.84319138063077,
              58.39745579138105
            ],
            [
              -134.76047144132008,
              58.331403741039
            ],
            [
              -134.70139148466535,
              58.32476180540539
            ],
            [
              -134.60315155674118,
              58.34168184160606
            ],
            [
              -134.5422116014511,
              58.33366118989008
            ],
            [
              -134.40686170075347,
              58.29957675077529
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "patara-2",
        "name": "Patara-2",
        "color": "#9a54a1",
        "feature_id": "patara-2-0",
        "coordinates": [
          133.0584880423776,
          -0.03183677258968487
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              134.5499789188764,
              -0.106411275875331
            ],
            [
              134.43747899857246,
              -0.443906656918472
            ],
            [
              134.32497907886446,
              -0.668895743818695
            ],
            [
              134.06198926516882,
              -0.861458343462516
            ]
          ],
          [
            [
              130.50720178281787,
              -0.23790000000011
            ],
            [
              130.77762159124993,
              -0.490195084933788
            ],
            [
              131.227621271274,
              -0.490195084933788
            ],
            [
              131.62498099156832,
              -0.218910724747275
            ],
            [
              132.29998051339228,
              0.006088583243098
            ],
            [
              134.5499789188764,
              -0.106411275875331
            ],
            [
              135.44997828190444,
              -0.500154705903554
            ],
            [
              135.6385523670668,
              -0.729535713493476
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "nuvem",
        "name": "Nuvem",
        "color": "#939597",
        "feature_id": "nuvem-0",
        "coordinates": [
          -44.07372809103043,
          37.05080649154931
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -78.8826698834326,
              33.693557908375226
            ],
            [
              -78.26653698657401,
              33.471699570864644
            ],
            [
              -69.2998766719733,
              32.17975358978947
            ],
            [
              -64.46238009890122,
              32.052708023486105
            ],
            [
              -62.099881771921204,
              32.052708023486105
            ],
            [
              -50.39989006030518,
              35.785661899524484
            ],
            [
              -39.59989771112103,
              37.945510474512275
            ],
            [
              -29.69990469819203,
              40.46344531359833
            ],
            [
              -23.39990918734489,
              40.387320270542546
            ],
            [
              -13.94991588359673,
              38.29952060596935
            ],
            [
              -10.349918432080813,
              38.12273010839229
            ],
            [
              -9.102760000000472,
              38.44299332826864
            ]
          ],
          [
            [
              -64.76951799999988,
              32.31217493263165
            ],
            [
              -64.57488001920524,
              32.24321001626265
            ],
            [
              -64.46238009890122,
              32.052708023486105
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tko-connect",
        "name": "TKO Connect",
        "color": "#de542a",
        "feature_id": "tko-connect-0",
        "coordinates": [
          114.2548420823496,
          22.291691296870397
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              114.25868329401678,
              22.318292678971574
            ],
            [
              114.25781079403887,
              22.29607030006096
            ],
            [
              114.23935699999967,
              22.268850000000214
            ]
          ],
          [
            [
              114.25868329401678,
              22.318292678971574
            ],
            [
              114.24374330400437,
              22.29607030006096
            ],
            [
              114.23935699999967,
              22.268850000000214
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "airraq",
        "name": "Airraq",
        "color": "#939597",
        "feature_id": "airraq-0",
        "coordinates": [
          -161.32281449759424,
          58.38688012871961
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -158.50879580810792,
              59.044985303674
            ],
            [
              -158.51231347304608,
              58.95251729542423
            ],
            [
              -158.62481339335008,
              58.836278671696704
            ],
            [
              -158.39981355274205,
              58.36740335134627
            ],
            [
              -159.29981291517413,
              58.24920018303547
            ],
            [
              -161.09981164003813,
              58.36740335134627
            ],
            [
              -161.77481116186206,
              58.42635692511544
            ],
            [
              -162.11231092277413,
              58.48521196181832
            ],
            [
              -162.33731076338216,
              58.66118687773917
            ],
            [
              -161.9998110024701,
              58.77801269228053
            ],
            [
              -161.8873110821661,
              58.89444683796128
            ],
            [
              -161.71068878535158,
              58.98588663078402
            ],
            [
              -161.9998110024701,
              58.95251729542423
            ],
            [
              -162.33731076338216,
              59.29889402722176
            ],
            [
              -162.33731076338216,
              59.755303236949885
            ],
            [
              -162.28106125084307,
              60.093570225922946
            ],
            [
              -162.25293582315413,
              60.20556098402312
            ],
            [
              -162.03248143321895,
              60.21970496867178
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "javali",
        "name": "JAVALI",
        "color": "#3476bb",
        "feature_id": "javali-0",
        "coordinates": [
          114.59713560544341,
          -8.836169010231032
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              114.09716340843894,
              -8.615167135560483
            ],
            [
              114.41249318505615,
              -8.836169010231032
            ],
            [
              114.97499278657611,
              -8.836169010231032
            ],
            [
              115.15985265561974,
              -8.783705413994738
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "jalapati",
        "name": "Jalapati",
        "color": "#3f5fac",
        "feature_id": "jalapati-0",
        "coordinates": [
          114.43455937194628,
          -8.39017416643702
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              114.3103932573849,
              -8.448027546855428
            ],
            [
              114.47074314379151,
              -8.37331486851241
            ],
            [
              114.52729310373101,
              -8.294580235998978
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "fish-north",
        "name": "FISH North",
        "color": "#4158a7",
        "feature_id": "fish-north-0",
        "coordinates": [
          -146.599258270813,
          60.666610770536266
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -146.35343293589432,
              61.130356463051186
            ],
            [
              -146.55314278937288,
              61.097140438988305
            ],
            [
              -146.64514272187523,
              61.06141701295115
            ],
            [
              -146.7730126280607,
              60.955723296373435
            ],
            [
              -146.81887259441447,
              60.84958475867367
            ],
            [
              -146.7759873645029,
              60.69059578309569
            ],
            [
              -146.47482200051797,
              60.649722744668196
            ],
            [
              -146.0248223193019,
              60.621159019701594
            ],
            [
              -145.79982247869395,
              60.63593440636549
            ],
            [
              -145.77169749861798,
              60.63593440636549
            ],
            [
              -145.75467386865432,
              60.54274832427731
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "sydney-melbourne-adelaide-perth-smap",
        "name": "Sydney-Melbourne-Adelaide-Perth (SMAP)",
        "color": "#939597",
        "feature_id": "sydney-melbourne-adelaide-perth-smap-0",
        "coordinates": [
          132.6150130425469,
          -37.20649255343855
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              134.85085761257702,
              -37.39478470533183
            ],
            [
              135.89997796252462,
              -36.01880330158204
            ],
            [
              136.34997764374052,
              -35.65399339714719
            ],
            [
              137.69997668738856,
              -35.28750958287685
            ],
            [
              138.59988604988442,
              -34.926102000000355
            ]
          ],
          [
            [
              143.9999722250084,
              -39.515593876112206
            ],
            [
              144.22497206502052,
              -38.81782397325085
            ],
            [
              144.32691199280558,
              -38.32915647171265
            ]
          ],
          [
            [
              144.4499719056286,
              -39.515593876112206
            ],
            [
              144.4499719056286,
              -38.81782397325085
            ],
            [
              144.32691199280558,
              -38.32915647171265
            ]
          ],
          [
            [
              151.2070371188603,
              -33.86969599999975
            ],
            [
              151.7987929508469,
              -34.826981990554685
            ],
            [
              150.7249674615547,
              -37.94245352569678
            ],
            [
              149.39996839960057,
              -38.99291515860628
            ],
            [
              147.59996967473646,
              -39.515593876112206
            ],
            [
              146.24997063108842,
              -39.68895338487741
            ],
            [
              144.4499719056286,
              -39.515593876112206
            ],
            [
              143.9999722250084,
              -39.515593876112206
            ],
            [
              142.19997350014447,
              -39.341800653968136
            ],
            [
              140.39997477528036,
              -38.99291515860628
            ],
            [
              134.85085761257702,
              -37.39478470533183
            ],
            [
              127.09256232737633,
              -36.74141812795199
            ],
            [
              119.55018485796725,
              -36.260840217957025
            ],
            [
              115.44676979611498,
              -36.099249601564566
            ],
            [
              113.82821359836939,
              -34.61129978545125
            ],
            [
              113.82821359836939,
              -33.061828893734884
            ],
            [
              115.85731216153286,
              -31.95344133032441
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "ulleung-mainland-2",
        "name": "Ulleung-Mainland 2",
        "color": "#aa422e",
        "feature_id": "ulleung-mainland-2-0",
        "coordinates": [
          130.1262683237948,
          37.277330764633206
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              129.33873337935873,
              37.17514326596569
            ],
            [
              129.59998242550031,
              37.172616592940045
            ],
            [
              130.49998178793246,
              37.35168786972491
            ],
            [
              130.89327660932778,
              37.48932062641171
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "jeju-mainland-3",
        "name": "Jeju-Mainland 3",
        "color": "#aa519f",
        "feature_id": "jeju-mainland-3-0",
        "coordinates": [
          127.53929599383483,
          34.00615714927153
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              128.0274354936125,
              34.7283585499259
            ],
            [
              127.79998370123226,
              34.31215165223537
            ],
            [
              127.3499840194203,
              33.78394328236391
            ],
            [
              127.12498417881241,
              33.502974839876686
            ],
            [
              126.90930752942782,
              33.44156850566958
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "jeju-mainland-2",
        "name": "Jeju-Mainland 2",
        "color": "#7a68ae",
        "feature_id": "jeju-mainland-2-0",
        "coordinates": [
          127.1699926532846,
          33.99757610853794
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              127.28599296347434,
              34.609401751863004
            ],
            [
              127.26560907919244,
              34.4514196909947
            ],
            [
              127.12498417881241,
              33.78394328236391
            ],
            [
              126.90930752942782,
              33.44156850566958
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "yuza-tobishima",
        "name": "Yuza-Tobishima",
        "color": "#2e2f8a",
        "feature_id": "yuza-tobishima-0",
        "coordinates": [
          139.72538937049725,
          39.09503710375708
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              139.54899537752638,
              39.195004615003995
            ],
            [
              139.83747517316462,
              39.03151487972863
            ],
            [
              139.90963512204584,
              39.02099623671364
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "yui",
        "name": "YUI",
        "color": "#632c8c",
        "feature_id": "yui-0",
        "coordinates": [
          126.37558620037007,
          24.501003998270676
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              125.29998547165852,
              24.794620013794997
            ],
            [
              125.43748537425242,
              25.17915174881213
            ],
            [
              126.33748473728028,
              26.26024097157773
            ],
            [
              126.74738444630756,
              26.34876549164227
            ]
          ],
          [
            [
              124.24509621895211,
              24.388365389522505
            ],
            [
              125.09998561393635,
              24.007057109099776
            ],
            [
              125.77498513576032,
              24.01990020343257
            ],
            [
              127.68748377735349,
              25.55188275942578
            ],
            [
              127.79998370004049,
              25.8559854660721
            ],
            [
              127.68080378506451,
              26.212369268639122
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "beaufort",
        "name": "Beaufort",
        "color": "#939597",
        "feature_id": "beaufort-0",
        "coordinates": [
          -5.605707386215869,
          51.45354505472753
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -5.399921938704722,
              51.37665177535368
            ],
            [
              -4.209462782633182,
              51.546886997611324
            ]
          ],
          [
            [
              -6.584105999999679,
              52.17489218348473
            ],
            [
              -5.962421540820714,
              51.586833980054195
            ],
            [
              -5.399921938704722,
              51.37665177535368
            ],
            [
              -4.544402544762761,
              50.82820142743802
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "andromeda",
        "name": "ANDROMEDA",
        "color": "#939597",
        "feature_id": "andromeda-0",
        "coordinates": [
          29.75781177590713,
          33.900998315311824
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              35.00509500000021,
              29.581033231032706
            ],
            [
              34.95220947487067,
              29.283852
            ]
          ],
          [
            [
              34.9719010000002,
              32.761700000000275
            ],
            [
              34.93129948968365,
              32.46495833701209
            ],
            [
              34.87504952953168,
              32.08448598947439
            ],
            [
              34.87504952953168,
              31.510798430048965
            ],
            [
              34.87504952953168,
              31.030017262986235
            ],
            [
              35.00509500000021,
              29.581033231032706
            ]
          ],
          [
            [
              31.050052239791512,
              33.471699570864644
            ],
            [
              31.500051920411693,
              34.06399293012595
            ],
            [
              32.466691235635295,
              34.76662799999977
            ]
          ],
          [
            [
              24.93160657356006,
              35.40468665209255
            ],
            [
              25.38628283097998,
              35.694548738875966
            ],
            [
              25.93362449116799,
              35.7453345587858
            ],
            [
              26.574796150245902,
              35.363653247335435
            ],
            [
              28.8000538337115,
              34.219177704953495
            ],
            [
              31.050052239791512,
              33.471699570864644
            ],
            [
              34.20005000830349,
              32.90681902852478
            ],
            [
              34.9719010000002,
              32.761700000000275
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tpu",
        "name": "TPU",
        "color": "#939597",
        "feature_id": "tpu-0",
        "coordinates": [
          150.17311823337886,
          16.471054970567252
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              122.84998720785634,
              20.691099107429977
            ],
            [
              121.72498800422042,
              19.246083087004678
            ],
            [
              121.08237845945095,
              18.606227215701807
            ]
          ],
          [
            [
              -179.999800397123,
              25.484199086872366
            ],
            [
              -172.7998097902887,
              28.68871408880051
            ],
            [
              -163.79980972733412,
              32.55982671166805
            ],
            [
              -151.19981865328594,
              36.27089464860367
            ],
            [
              -124.1595592195036,
              40.803254999999524
            ]
          ],
          [
            [
              145.52072114709986,
              14.365653759228536
            ],
            [
              145.34997126865645,
              13.656164304892798
            ],
            [
              144.89997158744055,
              13.437424347113762
            ],
            [
              144.78650500000046,
              13.446391000000233
            ]
          ],
          [
            [
              120.88977859589048,
              22.34045000000023
            ],
            [
              122.84998720785634,
              20.691099107429977
            ],
            [
              131.39998115096031,
              18.251816319028308
            ],
            [
              138.59997605041642,
              16.53419619825962
            ],
            [
              144.44997190622448,
              14.583511645118676
            ],
            [
              145.52072114709986,
              14.365653759228536
            ],
            [
              146.24997063108842,
              14.583511645118676
            ],
            [
              151.1999671244645,
              16.965102599435824
            ],
            [
              160.19996074818866,
              20.515592009193767
            ],
            [
              179.99994672169302,
              25.484199086872366
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "cambodia-hong-kong",
        "name": "Cambodia-Hong Kong",
        "color": "#939597",
        "feature_id": "cambodia-hong-kong-0",
        "coordinates": [
          109.99629380188091,
          12.530962973070132
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              103.506729,
              10.629948016412754
            ],
            [
              103.83750067648,
              9.524411345019587
            ],
            [
              104.4000002762123,
              8.190543417795567
            ],
            [
              105.29999964043216,
              7.967776882259614
            ],
            [
              107.99999772772824,
              8.74690746413691
            ],
            [
              109.12499693017233,
              9.96791518697421
            ],
            [
              110.0249962926043,
              12.615395567393307
            ],
            [
              112.7249943804961,
              18.251816319028308
            ],
            [
              113.17499406171223,
              20.796306105108954
            ],
            [
              114.15872000000073,
              22.283323132498424
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "american-1",
        "name": "AmeriCan-1",
        "color": "#866baf",
        "feature_id": "american-1-0",
        "coordinates": [
          -122.59133453336418,
          48.01016245185866
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -122.64715133656836,
              48.29059800000016
            ],
            [
              -122.79358877712184,
              48.27551390305854
            ],
            [
              -122.79358877712184,
              48.2380697210028
            ],
            [
              -122.63793388679312,
              48.11006090495562
            ],
            [
              -122.60981390671354,
              48.03488959703961
            ],
            [
              -122.55355394656868,
              47.95960846190178
            ],
            [
              -122.49730398641672,
              47.90307887010553
            ],
            [
              -122.46918045968638,
              47.82760546971153
            ],
            [
              -122.44105402626477,
              47.71418928115125
            ],
            [
              -122.32943910533376,
              47.60356
            ]
          ],
          [
            [
              -123.4146383365684,
              48.43012000000027
            ],
            [
              -123.07483857728575,
              48.35656994073389
            ],
            [
              -122.64715133656836,
              48.29059800000016
            ]
          ],
          [
            [
              -123.05676002859596,
              48.988680000000215
            ],
            [
              -122.96233865698173,
              48.76606651214587
            ],
            [
              -123.24358989152424,
              48.65471436482147
            ],
            [
              -123.24358989152424,
              48.580342671508475
            ],
            [
              -123.2998384178938,
              48.50586139322314
            ],
            [
              -123.3672515981171,
              48.5203973246571
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "anjana",
        "name": "Anjana",
        "color": "#939597",
        "feature_id": "anjana-0",
        "coordinates": [
          -40.5766069982412,
          38.12152586113558
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -78.8826698834326,
              33.693557908375226
            ],
            [
              -78.26653698657401,
              33.565491482352144
            ],
            [
              -76.94987125204939,
              33.565491482352144
            ],
            [
              -62.099881771921204,
              34.68301765985788
            ],
            [
              -50.39989006030518,
              36.33133835588808
            ],
            [
              -39.59989771112103,
              38.29952060596935
            ],
            [
              -23.39990918734489,
              42.74371346443661
            ],
            [
              -16.199914287888873,
              45.01383364395318
            ],
            [
              -9.899918750864742,
              45.96024524125332
            ],
            [
              -5.849921619920792,
              45.33107107332478
            ],
            [
              -4.724922416284699,
              44.694829089578064
            ],
            [
              -3.810013065607109,
              43.46149931727061
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "sunoque-ii",
        "name": "Sunoque II",
        "color": "#9f4a23",
        "feature_id": "sunoque-ii-0",
        "coordinates": [
          -68.86272863550099,
          48.58908639575682
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -69.08359854223666,
              48.73935708855754
            ],
            [
              -68.84987699075731,
              48.580342671508475
            ],
            [
              -68.70931121562643,
              48.37278902244074
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "sunoque-i",
        "name": "Sunoque I",
        "color": "#2c286e",
        "feature_id": "sunoque-i-0",
        "coordinates": [
          -68.35435428558303,
          48.88002864266636
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -68.15248212795332,
              49.221039999999846
            ],
            [
              -68.39987730954124,
              48.803129141654495
            ],
            [
              -68.45945262418259,
              48.503259270982085
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "vancouver-bowen-island-vancouver-island",
        "name": "Vancouver-Bowen Island-Vancouver Island",
        "color": "#854e9f",
        "feature_id": "vancouver-bowen-island-vancouver-island-0",
        "coordinates": [
          -123.8133115165753,
          49.42901044065823
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -124.35616907525653,
              49.342144420644985
            ],
            [
              -124.08733786002192,
              49.42901044065823
            ],
            [
              -123.74983809910968,
              49.42901044065823
            ],
            [
              -123.63733817880558,
              49.3924124042525
            ],
            [
              -123.42890625369196,
              49.34328348838156
            ],
            [
              -123.338009822251,
              49.38007937128441
            ],
            [
              -123.27652986735708,
              49.37481603900389
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "fish-west",
        "name": "FISH West",
        "color": "#939597",
        "feature_id": "fish-west-0",
        "coordinates": [
          -147.61809727128997,
          60.21192204007292
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -145.75467386865432,
              60.54274832427731
            ],
            [
              -145.77169749861798,
              60.62214016805558
            ],
            [
              -145.79982247869395,
              60.62214016805558
            ],
            [
              -146.0248223193019,
              60.59453398451646
            ],
            [
              -146.615446900898,
              60.53925074150882
            ],
            [
              -146.61186618626186,
              60.48248023184195
            ],
            [
              -146.67169686104995,
              60.52541515489321
            ],
            [
              -147.15173333746708,
              60.427225730910095
            ],
            [
              -147.4986104553648,
              60.29714870250734
            ],
            [
              -147.58986202876156,
              60.224094689366666
            ],
            [
              -147.79578024891984,
              60.13532016318143
            ],
            [
              -147.9082834324972,
              60.079255245040514
            ],
            [
              -148.01078611085998,
              60.065087981861694
            ],
            [
              -148.04982088477396,
              60.03743169846882
            ],
            [
              -148.13419582500202,
              60.009326600079135
            ],
            [
              -148.2185715680913,
              60.009326600079135
            ],
            [
              -148.49982056598995,
              59.84019347070509
            ],
            [
              -149.06232016750997,
              59.84019347070509
            ],
            [
              -149.287320008118,
              59.849612385021544
            ],
            [
              -149.34357074271142,
              59.90606992457441
            ],
            [
              -149.44767066574025,
              60.11004931326181
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "sunoque-iii",
        "name": "Sunoque III",
        "color": "#939597",
        "feature_id": "sunoque-iii-0",
        "coordinates": [
          -66.47500823454709,
          49.679665839235554
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -66.4069432524875,
              50.23091395796058
            ],
            [
              -66.47487867322839,
              49.68443309948506
            ],
            [
              -66.49011413130246,
              49.123838360364886
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "t3",
        "name": "T3",
        "color": "#d02a3f",
        "feature_id": "t3-0",
        "coordinates": [
          45.03562963425202,
          -27.137914527598355
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              30.88039235938433,
              -30.057717076456527
            ],
            [
              32.40005128343955,
              -30.018154882677464
            ],
            [
              38.70004682046351,
              -28.348517239947398
            ],
            [
              45.000042357487644,
              -27.153831285391675
            ],
            [
              47.700040444783724,
              -25.946230718414654
            ],
            [
              52.20003725575194,
              -23.08058350574774
            ],
            [
              54.67503550243981,
              -21.62397766209739
            ],
            [
              55.35003502485981,
              -21.798176401097464
            ],
            [
              56.02503454668374,
              -21.798176401097464
            ],
            [
              56.70003406791196,
              -20.995131543025877
            ],
            [
              57.150033750319835,
              -20.679706953509026
            ],
            [
              57.485483512684,
              -20.4739956609462
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "submarine-cable-in-the-philippines-scip",
        "name": "Submarine Cable in the Philippines (SCiP)",
        "color": "#224092",
        "feature_id": "submarine-cable-in-the-philippines-scip-0",
        "coordinates": [
          122.42603465916588,
          12.165698513473131
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              123.98834884332425,
              10.40642028313701
            ],
            [
              124.05516213475309,
              10.590420891923031
            ],
            [
              124.24666621783987,
              10.932011625131736
            ],
            [
              124.14373629075632,
              11.294709319565555
            ],
            [
              124.01730638032066,
              11.246495
            ],
            [
              123.93298644005355,
              11.427191410552865
            ],
            [
              122.96248712816029,
              11.95585820711483
            ],
            [
              122.39998752664027,
              12.17588718550806
            ],
            [
              122.17498768543632,
              12.06589527357022
            ],
            [
              122.03325741497642,
              11.867904480937568
            ],
            [
              121.83748792512026,
              12.17588718550806
            ],
            [
              121.72498800422042,
              12.395734000022884
            ],
            [
              121.52772251956102,
              12.586423162176118
            ],
            [
              121.55623182195282,
              12.834868817846598
            ],
            [
              121.46453818932153,
              13.04578255071101
            ],
            [
              121.62498807506111,
              13.273238157547667
            ],
            [
              121.47853039186325,
              13.620875075300233
            ],
            [
              121.06600847164387,
              13.762418337904336
            ]
          ],
          [
            [
              124.63191594552123,
              8.454147535358373
            ],
            [
              124.59373597256834,
              8.635699417327544
            ],
            [
              123.74998657028831,
              9.024781435606968
            ],
            [
              123.52498672968031,
              9.08033076823304
            ],
            [
              123.28143690221336,
              9.295503918748068
            ],
            [
              123.52498672968031,
              9.413444258507468
            ],
            [
              123.69373661073243,
              9.801670473167405
            ],
            [
              123.80623652984437,
              10.078698006650882
            ],
            [
              123.83892650668648,
              10.252013798268166
            ]
          ],
          [
            [
              124.84217394836767,
              10.178582995816868
            ],
            [
              124.81873581258029,
              9.96791518697421
            ],
            [
              125.1562355740883,
              9.746236973759864
            ],
            [
              125.43748537425242,
              9.302441529883055
            ],
            [
              125.54061530179015,
              8.9476104639362
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "submarine-cable-in-the-philippines-scip",
        "name": "Submarine Cable in the Philippines (SCiP)",
        "color": "#939597",
        "feature_id": "submarine-cable-in-the-philippines-scip-1",
        "coordinates": [
          120.3430587515426,
          11.523283999196622
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              124.61277595908044,
              11.006888020676286
            ],
            [
              124.42498609211222,
              10.797840764398018
            ],
            [
              124.31248617180833,
              10.742581675476316
            ],
            [
              124.19998625031243,
              10.52144468555204
            ],
            [
              124.14373629075632,
              10.410816505402636
            ],
            [
              123.98834884332425,
              10.40642028313701
            ]
          ],
          [
            [
              124.0847163325669,
              12.585260603442975
            ],
            [
              124.2281112315803,
              12.505588131780542
            ],
            [
              124.28238619313154,
              12.501390118608668
            ]
          ],
          [
            [
              120.48748888087633,
              11.735650161405744
            ],
            [
              120.37498896116833,
              11.790718790556355
            ],
            [
              120.20076908458739,
              12.005434247136094
            ]
          ],
          [
            [
              121.0501884828507,
              12.363012914770593
            ],
            [
              120.71248872148439,
              12.17588718550806
            ],
            [
              120.48748888087633,
              11.735650161405744
            ],
            [
              120.03748919966026,
              11.073982781226704
            ],
            [
              119.50037958074992,
              10.820000490489491
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "taiwan-penghu-kinmen-matsu-no-2-tpkm2",
        "name": "Taiwan Penghu Kinmen Matsu No.2 (TPKM2)",
        "color": "#5a266c",
        "feature_id": "taiwan-penghu-kinmen-matsu-no-2-tpkm2-0",
        "coordinates": [
          119.54182501885109,
          24.361968609765853
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              121.46264819006419,
              25.18160422340455
            ],
            [
              121.2749883236003,
              25.742038029757737
            ],
            [
              120.71248872148439,
              26.394681925388653
            ],
            [
              120.49296999999947,
              26.367331366643725
            ]
          ],
          [
            [
              120.64548999999985,
              24.43246260186106
            ],
            [
              120.26248904026832,
              24.361968609765853
            ],
            [
              118.79999007631633,
              24.361968609765853
            ],
            [
              118.43944000000036,
              24.43748260175778
            ]
          ],
          [
            [
              120.17047428762342,
              23.362789999999762
            ],
            [
              119.92498927935523,
              23.436294941322956
            ],
            [
              119.65616428762345,
              23.57363
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "red2med",
        "name": "Red2Med",
        "color": "#7b231b",
        "feature_id": "red2med-0",
        "coordinates": [
          32.46450486346393,
          29.75791935107452
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              33.08276564295244,
              28.365936333863488
            ],
            [
              32.85009096581916,
              28.951554732193216
            ],
            [
              32.65318110412005,
              29.113614162980156
            ],
            [
              32.5971261438298,
              29.34456698948989
            ],
            [
              32.42805126360403,
              29.63833609362638
            ],
            [
              32.529931191431416,
              29.972545436050268
            ],
            [
              32.400051282843634,
              30.174689758499085
            ],
            [
              32.287551362539546,
              30.36900921360788
            ],
            [
              32.287551362539546,
              30.514495959759188
            ],
            [
              32.23130140238757,
              30.83702058239723
            ],
            [
              32.28445136473579,
              31.259278146448963
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "r100-north",
        "name": "R100 North",
        "color": "#dc673d",
        "feature_id": "r100-north-0",
        "coordinates": [
          -1.9581204936924337,
          59.35416748980043
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -0.966812000000402,
              60.687366
            ],
            [
              -1.006829199999856,
              60.6701315
            ]
          ],
          [
            [
              -1.055590000000251,
              60.49937
            ],
            [
              -1.12492496774874,
              60.47001365336868
            ],
            [
              -1.182159999999868,
              60.459095
            ]
          ],
          [
            [
              -1.287109,
              59.869131
            ],
            [
              -1.237424888052743,
              59.79305890746801
            ],
            [
              -1.293674848204716,
              59.6796637072089
            ],
            [
              -1.518674688812752,
              59.50884868221257
            ],
            [
              -1.613773299999906,
              59.5262096
            ]
          ],
          [
            [
              -1.024927000000218,
              60.341001
            ],
            [
              -1.096799987672739,
              60.345013748995456
            ],
            [
              -1.1414772,
              60.351445
            ]
          ],
          [
            [
              -2.701495800000146,
              59.206322700000015
            ],
            [
              -2.65365959999977,
              59.14894449999997
            ]
          ],
          [
            [
              -2.7662283,
              59.19230080000003
            ],
            [
              -2.701495800000146,
              59.206322700000015
            ]
          ],
          [
            [
              -2.857578,
              59.255413
            ],
            [
              -2.783827099999854,
              59.225208
            ]
          ],
          [
            [
              -3.110331300000269,
              59.11455549999991
            ],
            [
              -3.078059000000252,
              59.143849
            ]
          ],
          [
            [
              -2.836203799999765,
              59.02519980000008
            ],
            [
              -2.81772039999953,
              58.979222100000044
            ]
          ],
          [
            [
              -3.172025800000284,
              58.822744899999975
            ],
            [
              -3.145124800000247,
              58.82968250000002
            ]
          ],
          [
            [
              -3.085608400000154,
              58.8310597
            ],
            [
              -2.9913694,
              58.826402799999954
            ]
          ],
          [
            [
              -6.16069290000047,
              56.91203019999992
            ],
            [
              -6.187421381428749,
              56.93804054995368
            ],
            [
              -6.13117142127669,
              56.95338085470132
            ],
            [
              -6.018671500972772,
              56.922693933032036
            ],
            [
              -5.850779599999896,
              56.9469819
            ]
          ],
          [
            [
              -5.442403500000353,
              56.55241429999995
            ],
            [
              -5.4124115,
              56.5523086
            ]
          ],
          [
            [
              -6.394503299999968,
              56.329393799999934
            ],
            [
              -6.368239099999755,
              56.32334989999998
            ]
          ],
          [
            [
              -6.177803799999964,
              56.29173429999997
            ],
            [
              -6.159296401352776,
              56.2411712282902
            ],
            [
              -6.187421381428749,
              56.178604166899504
            ],
            [
              -6.215546361504721,
              56.115934961294954
            ],
            [
              -6.180188800000366,
              56.10365920000004
            ]
          ],
          [
            [
              -1.518674688812752,
              59.50884868221257
            ],
            [
              -1.799924488976671,
              59.394489266856795
            ],
            [
              -2.137424251676691,
              59.30846566460079
            ],
            [
              -2.419078999999812,
              59.28984459999995
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "piano-isole-minori",
        "name": "Piano Isole Minori",
        "color": "#939597",
        "feature_id": "piano-isole-minori-0",
        "coordinates": [
          11.939137663311072,
          36.840145697172204
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              14.95432543210336,
              38.46838692145903
            ],
            [
              14.965563633598265,
              38.42411761228021
            ]
          ],
          [
            [
              12.434917,
              37.80009191125524
            ],
            [
              11.948015771257332,
              37.54682622128206
            ],
            [
              11.756315907059445,
              37.232354321556215
            ],
            [
              11.943459999999703,
              36.83087296201458
            ],
            [
              12.543815349187497,
              36.240655233214795
            ],
            [
              12.712565230239278,
              36.01348686719726
            ],
            [
              12.860768932962628,
              35.85836361671528
            ]
          ],
          [
            [
              12.607558387730393,
              35.50153147422583
            ],
            [
              12.712565230239278,
              35.60293032290622
            ],
            [
              12.860768932962628,
              35.85836361671528
            ]
          ],
          [
            [
              12.51361800000052,
              38.018253909057734
            ],
            [
              12.341014787775208,
              37.98820701995771
            ],
            [
              12.073620000000204,
              37.968527908318066
            ]
          ],
          [
            [
              13.197330511231087,
              38.708023844992255
            ],
            [
              13.33131479131535,
              38.38775473578434
            ],
            [
              13.247603185879768,
              38.189942704181675
            ]
          ],
          [
            [
              14.358271440312592,
              38.535852973600754
            ],
            [
              14.576656824541459,
              38.56490652272308
            ],
            [
              14.83663846384303,
              38.57831896241984
            ],
            [
              14.968438937975463,
              38.147022082517225
            ]
          ],
          [
            [
              15.233512237625035,
              38.80580638197682
            ],
            [
              15.075900768577327,
              38.63677916998512
            ],
            [
              14.95432543210336,
              38.46838692145903
            ]
          ],
          [
            [
              8.379527706426728,
              39.20657028282142
            ],
            [
              8.302684658371277,
              39.145528666527696
            ]
          ],
          [
            [
              8.336105703397322,
              41.081726281475255
            ],
            [
              8.228993742790493,
              40.94808554634909
            ]
          ],
          [
            [
              15.563363144424187,
              41.918698364448886
            ],
            [
              15.488973558507695,
              42.115432900583585
            ],
            [
              15.508871650161637,
              42.123036161233784
            ]
          ],
          [
            [
              9.842201117068214,
              43.0482648142908
            ],
            [
              10.316441790280749,
              42.81213757811682
            ]
          ],
          [
            [
              13.56544546269916,
              41.21793523548408
            ],
            [
              12.95867920359504,
              40.89578010642026
            ],
            [
              13.428232823149898,
              40.79536733371853
            ],
            [
              13.454176273067247,
              40.78994446306501
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "east-micronesia-cable-system-emcs",
        "name": "East Micronesia Cable System (EMCS)",
        "color": "#939597",
        "feature_id": "east-micronesia-cable-system-emcs-0",
        "coordinates": [
          165.13379079240875,
          2.379492515274677
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              166.9499559664287,
              0.943545268913113
            ],
            [
              166.9499559664287,
              0.718569240875894
            ],
            [
              166.92110320261114,
              0.546700000000243
            ]
          ],
          [
            [
              163.12495867609275,
              5.659359572411404
            ],
            [
              163.01245875578863,
              5.435413643888296
            ],
            [
              163.00810320261044,
              5.327899999999516
            ]
          ],
          [
            [
              159.070334,
              7.786484021061284
            ],
            [
              160.19996074818866,
              7.447522319872292
            ],
            [
              163.12495867609275,
              5.659359572411404
            ],
            [
              165.59995692278082,
              1.618372199773091
            ],
            [
              166.9499559664287,
              0.943545268913113
            ],
            [
              171.89995245980478,
              1.393450214463542
            ],
            [
              172.9791,
              1.329098003182224
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "energy-bridge-cable",
        "name": "Energy Bridge Cable",
        "color": "#d96048",
        "feature_id": "energy-bridge-cable-0",
        "coordinates": [
          36.68098678656122,
          45.427317845472174
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              36.591125385996406,
              45.43102598053383
            ],
            [
              36.770848187126035,
              45.42360971041049
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "digital-e4",
        "name": "Digital E4",
        "color": "#939597",
        "feature_id": "digital-e4-0",
        "coordinates": [
          17.971016684082457,
          58.42655698451543
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              18.062801566480886,
              59.332268
            ],
            [
              17.775061643323397,
              59.06836550247485
            ],
            [
              17.775061643323397,
              58.836278671696704
            ],
            [
              18.11256140423555,
              58.130601640227894
            ],
            [
              18.450061165147503,
              57.89221518473272
            ],
            [
              18.782650929537795,
              57.84997761117046
            ]
          ],
          [
            [
              18.20361019941356,
              57.38689049370953
            ],
            [
              18.000061483931432,
              57.41066230395057
            ],
            [
              17.325061962107526,
              57.41066230395057
            ],
            [
              17.008492651025136,
              57.32475822033851
            ]
          ],
          [
            [
              16.46807660930591,
              56.24572402102595
            ],
            [
              16.087562838763404,
              56.03221697111684
            ],
            [
              15.30006339723144,
              55.65323105219801
            ],
            [
              14.83628372518129,
              55.24821367563857
            ]
          ],
          [
            [
              14.349884566481052,
              55.55649
            ],
            [
              14.512563954507328,
              55.33458061322915
            ],
            [
              14.70832899999968,
              55.182570196103086
            ]
          ],
          [
            [
              14.708333815822385,
              55.101017
            ],
            [
              14.4000640347993,
              54.88412743786745
            ],
            [
              13.648694566480657,
              54.51955
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tikal-amx3",
        "name": "TIKAL-AMX3",
        "color": "#939597",
        "feature_id": "tikal-amx3-0",
        "coordinates": [
          -85.0459708163811,
          22.475410567239777
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -86.76758665233308,
              21.095728792367282
            ],
            [
              -86.51236447788934,
              21.00649984517682
            ],
            [
              -86.17486471697737,
              21.00649984517682
            ],
            [
              -85.72486503635747,
              20.93646800014895
            ]
          ],
          [
            [
              -80.08893152830953,
              26.350585697437936
            ],
            [
              -79.64986933994138,
              25.95717997876443
            ],
            [
              -79.7623692596494,
              25.348717422116806
            ],
            [
              -80.09986902056127,
              24.73717827217618
            ],
            [
              -80.99986838299351,
              23.917101290935022
            ],
            [
              -83.24986678907334,
              23.917101290935022
            ],
            [
              -84.82486567332933,
              22.884654113882362
            ],
            [
              -85.49986519515335,
              21.635297384859456
            ],
            [
              -85.72486503635747,
              20.93646800014895
            ],
            [
              -85.8373649566614,
              20.375041253465525
            ],
            [
              -86.84986423880139,
              18.251816319028308
            ],
            [
              -87.74986360123336,
              16.53419619825962
            ],
            [
              -88.19986328244934,
              16.10232559580288
            ],
            [
              -88.59713531004533,
              15.72723663872111
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "connected-coast",
        "name": "Connected Coast",
        "color": "#ca1d5c",
        "feature_id": "connected-coast-0",
        "coordinates": [
          -130.95742887738925,
          53.52439675040034
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -131.92980347067783,
              53.56252710217001
            ],
            [
              -131.737332440694,
              53.52439675040034
            ],
            [
              -130.8373330782613,
              53.52439675040034
            ],
            [
              -130.61449888485546,
              53.49227407843287
            ],
            [
              -130.61233323765381,
              53.65794367159367
            ],
            [
              -130.49983331734978,
              53.72455893284996
            ],
            [
              -130.43327104649984,
              53.79467134918559
            ],
            [
              -130.3310846916311,
              53.8574734403048
            ],
            [
              -130.25851447378102,
              53.94951651353455
            ]
          ],
          [
            [
              -130.3212780389084,
              54.223392151062626
            ],
            [
              -130.49983331734978,
              54.25004306179315
            ],
            [
              -130.44442013485343,
              54.33705541814703
            ],
            [
              -130.49983331734978,
              54.44675601642598
            ],
            [
              -130.43405684195648,
              54.554394919213024
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "connected-coast",
        "name": "Connected Coast",
        "color": "#939597",
        "feature_id": "connected-coast-1",
        "coordinates": [
          -128.63232255382115,
          53.13257219276224
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -126.75261187443792,
              50.61394812106704
            ],
            [
              -126.75364097118768,
              50.67033552833743
            ],
            [
              -126.72551479577373,
              50.72376904779034
            ],
            [
              -126.64113414855794,
              50.75936358394634
            ],
            [
              -126.49734715005872,
              50.74991847293478
            ],
            [
              -126.52864183757092,
              50.79493410837463
            ],
            [
              -126.55858745940492,
              50.84886632573384
            ]
          ],
          [
            [
              -126.59874742994069,
              50.70054017294757
            ],
            [
              -126.64113414855794,
              50.67033552833743
            ],
            [
              -126.61076460042742,
              50.57383518430757
            ],
            [
              -126.75261187443792,
              50.61394812106704
            ],
            [
              -126.84948623675693,
              50.633651911110036
            ],
            [
              -126.89171679944616,
              50.61138344935594
            ],
            [
              -127.06857708524005,
              50.621468387952206
            ],
            [
              -127.1548470219463,
              50.63120042038267
            ]
          ],
          [
            [
              -125.50343684333382,
              50.412765239933464
            ],
            [
              -125.54674820176254,
              50.37733322590901
            ]
          ],
          [
            [
              -125.9590135447738,
              50.38434418820634
            ],
            [
              -125.83107162474451,
              50.38892868152945
            ],
            [
              -125.74671168450575,
              50.3709967557742
            ],
            [
              -125.66233674427781,
              50.3709967557742
            ],
            [
              -125.54674820176254,
              50.37733322590901
            ],
            [
              -125.4935882407645,
              50.35305805049026
            ],
            [
              -125.44561591823911,
              50.331395037330736
            ],
            [
              -125.41540137561998,
              50.300060895733026
            ],
            [
              -125.44214123814993,
              50.27742985648956
            ],
            [
              -125.3026185053801,
              50.235839030620156
            ]
          ],
          [
            [
              -127.21051233205814,
              51.680119439312506
            ],
            [
              -127.46232046915256,
              51.64504609032713
            ],
            [
              -127.54671040936978,
              51.57518259747877
            ],
            [
              -127.57483538944577,
              51.50521155452452
            ],
            [
              -127.64481414210553,
              51.40557093447978
            ],
            [
              -127.74358659000475,
              51.4000532002448
            ],
            [
              -127.85608650746678,
              51.32981301612912
            ],
            [
              -127.8344383446541,
              51.24964662436554
            ],
            [
              -127.85608650746678,
              51.18900930466256
            ],
            [
              -127.7998352300538,
              51.047774077600735
            ],
            [
              -127.72464476418682,
              50.97750112024528
            ],
            [
              -127.61277278412604,
              50.86035999402688
            ],
            [
              -127.51857675508808,
              50.835104822413825
            ],
            [
              -127.46232046915247,
              50.764000914162494
            ],
            [
              -127.49580677179375,
              50.72087825136845
            ],
            [
              -127.43420048907288,
              50.764000914162494
            ],
            [
              -127.40607683762614,
              50.764000914162494
            ],
            [
              -127.23732696143304,
              50.69278878751939
            ],
            [
              -127.18975204590164,
              50.6121458994042
            ],
            [
              -127.15484280035238,
              50.63120042038267
            ],
            [
              -127.06857708524005,
              50.6125425495689
            ],
            [
              -127.0008357960726,
              50.58255252919659
            ],
            [
              -126.92633711543814,
              50.58835091985668
            ],
            [
              -126.83111117990488,
              50.54768561590086
            ],
            [
              -126.73107733285396,
              50.55003966151622
            ],
            [
              -126.61857741539202,
              50.51428147959945
            ],
            [
              -126.44982753919892,
              50.49639699506395
            ],
            [
              -126.22482770427496,
              50.478499373021776
            ],
            [
              -126.08555943379832,
              50.492768476869855
            ]
          ],
          [
            [
              -127.95147226786973,
              52.063679434507236
            ],
            [
              -127.91233015036143,
              51.95810253986727
            ],
            [
              -127.9404601304338,
              51.853991886922785
            ],
            [
              -127.9404601304338,
              51.71480209466691
            ],
            [
              -127.86370028877161,
              51.603201302999395
            ],
            [
              -127.85608650746678,
              51.54021052359346
            ],
            [
              -127.74358659000475,
              51.4000532002448
            ]
          ],
          [
            [
              -124.81641098708059,
              50.0357787156604
            ],
            [
              -124.82003734097063,
              50.01296739997835
            ],
            [
              -124.76127877803913,
              49.98196414284962
            ]
          ],
          [
            [
              -128.7230158714254,
              52.262322952134014
            ],
            [
              -128.58733467218184,
              52.26898811247557
            ],
            [
              -128.40536610447595,
              52.27159309745261
            ]
          ],
          [
            [
              -130.33006983111557,
              54.313075
            ],
            [
              -130.38751465023006,
              54.28778456596143
            ]
          ],
          [
            [
              -130.25851447378102,
              53.94951651353455
            ],
            [
              -130.278044730545,
              54.142313296728474
            ],
            [
              -130.3212780389084,
              54.223392151062626
            ]
          ],
          [
            [
              -127.69350873628824,
              52.35335745745377
            ],
            [
              -127.72202740406797,
              52.360144369990564
            ],
            [
              -127.7905783226933,
              52.26794328251674
            ],
            [
              -127.95147226786973,
              52.063679434507236
            ],
            [
              -128.06345114247478,
              52.06571648865037
            ],
            [
              -128.11094690818484,
              52.096441035551464
            ],
            [
              -128.12231269840393,
              52.15394371648728
            ],
            [
              -128.14470446316778,
              52.16114984088375
            ],
            [
              -128.11186854799382,
              52.1851741733916
            ],
            [
              -128.40536610447595,
              52.27159309745261
            ],
            [
              -128.4884132567237,
              52.317033868530615
            ],
            [
              -128.47483475187792,
              52.4407664729554
            ],
            [
              -128.5248732143254,
              52.59177881761508
            ],
            [
              -128.52383513984987,
              52.643028264222124
            ],
            [
              -128.5310860122389,
              52.782322857763916
            ],
            [
              -128.5310860122389,
              52.98597938044122
            ],
            [
              -128.58733467218184,
              53.1212193630283
            ],
            [
              -128.70071815397398,
              53.149832031963086
            ],
            [
              -128.9248344330938,
              53.32328423459367
            ],
            [
              -129.14983427370186,
              53.32328423459367
            ],
            [
              -129.2547883357858,
              53.4252931058725
            ],
            [
              -129.26233419400577,
              53.35686906888975
            ],
            [
              -129.3748341143099,
              53.39042745806566
            ],
            [
              -129.43108535193494,
              53.45746493836566
            ],
            [
              -129.60567640153096,
              53.543205102558886
            ],
            [
              -129.82483379552576,
              53.72455893284996
            ],
            [
              -130.04983363613383,
              53.8574734403048
            ],
            [
              -130.25851536025982,
              53.94951651353455
            ]
          ],
          [
            [
              -126.75276731694066,
              52.372147935610116
            ],
            [
              -126.90461720553277,
              52.33823172186351
            ],
            [
              -127.12961704045674,
              52.33823172186351
            ],
            [
              -127.2408669588359,
              52.441217560480055
            ],
            [
              -127.35336687629793,
              52.441217560480055
            ],
            [
              -127.46586679375986,
              52.372587034211904
            ],
            [
              -127.57836909607066,
              52.30385008818368
            ],
            [
              -127.79057491184659,
              52.26794328251674
            ]
          ],
          [
            [
              -126.08555943379832,
              50.492768476869855
            ],
            [
              -125.9590135447738,
              50.38434418820634
            ]
          ],
          [
            [
              -124.83905872097415,
              50.12374815000328
            ],
            [
              -124.90155867511973,
              50.08366067969962
            ]
          ],
          [
            [
              -125.1018185281948,
              50.231479527094876
            ],
            [
              -125.16070877237041,
              50.17239028810644
            ],
            [
              -125.19595794766325,
              50.13705710610179
            ],
            [
              -125.21592527387568,
              50.10125851949144
            ],
            [
              -125.04927488516671,
              50.11121239715351
            ],
            [
              -125.09983714275782,
              50.04700743933884
            ],
            [
              -124.98642332019205,
              50.06310304527369
            ],
            [
              -124.93253726127456,
              50.01296739997835
            ],
            [
              -124.81641098708059,
              50.0357787156604
            ],
            [
              -124.90103138578718,
              50.08449796406856
            ],
            [
              -124.91752768258522,
              50.11738065800708
            ]
          ],
          [
            [
              -123.84400938570295,
              49.173301754209774
            ],
            [
              -123.93561821991449,
              49.25457739751235
            ],
            [
              -124.12351458051003,
              49.25190624440006
            ],
            [
              -124.20451007380896,
              49.352230528909985
            ],
            [
              -124.44320399853743,
              49.34943591538794
            ],
            [
              -124.68672835476221,
              49.44353722792695
            ],
            [
              -124.71356875282484,
              49.493456292187915
            ],
            [
              -124.6971288251041,
              49.513480525797576
            ],
            [
              -124.928266,
              49.673512999999616
            ],
            [
              -125.04910592224834,
              49.82422258215962
            ],
            [
              -125.18619846628766,
              49.919552658402466
            ],
            [
              -125.24816842082204,
              50.02461000000014
            ],
            [
              -125.1971284582686,
              50.021901913959624
            ],
            [
              -125.10128500014203,
              50.01296739997835
            ],
            [
              -124.98642332019205,
              50.06310304527369
            ]
          ],
          [
            [
              -123.114034,
              49.2604400000001
            ],
            [
              -123.58108821865315,
              49.2824545474233
            ],
            [
              -123.84400938570295,
              49.173301754209774
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "haikou-beihai-cable",
        "name": "Haikou-Beihai Cable",
        "color": "#2cb2a0",
        "feature_id": "haikou-beihai-cable-0",
        "coordinates": [
          109.23507396949967,
          20.636748911764926
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              109.10530694412083,
              21.48450099999954
            ],
            [
              109.23749685047628,
              20.62092052699998
            ],
            [
              109.67761999999979,
              19.904800000000137
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "ningbo-zhoushan-cable",
        "name": "Ningbo-Zhoushan Cable",
        "color": "#1d9cbb",
        "feature_id": "ningbo-zhoushan-cable-0",
        "coordinates": [
          121.76356259002122,
          30.160824343859417
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              121.63985462881672,
              30.04311114665998
            ],
            [
              121.72498800422042,
              30.158479119660996
            ],
            [
              121.94569495659752,
              30.171897469456127
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "dalian-yantai-cable",
        "name": "Dalian-Yantai Cable",
        "color": "#31549b",
        "feature_id": "dalian-yantai-cable-0",
        "coordinates": [
          121.60653576225289,
          38.22073141066298
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              121.58836700725394,
              38.93984412079942
            ],
            [
              121.61248808391636,
              38.24063815947207
            ],
            [
              121.4,
              37.53
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "petropavlovsk-kamchatsky---anadyr",
        "name": "Petropavlovsk-Kamchatsky - Anadyr",
        "color": "#af2364",
        "feature_id": "petropavlovsk-kamchatsky---anadyr-0",
        "coordinates": [
          170.2676344215844,
          54.849677985687265
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              177.64027839330404,
              64.7378399814892
            ],
            [
              177.57455843986074,
              64.72253629248203
            ],
            [
              177.5021457567835,
              64.72719091772652
            ],
            [
              178.6499476780448,
              64.28486693839764
            ],
            [
              179.7749468810848,
              63.79239592397769
            ],
            [
              179.99994764163225,
              63.596795905217675
            ]
          ],
          [
            [
              -179.99979746953582,
              63.596795905217675
            ],
            [
              -179.0778290426068,
              62.781080493725966
            ],
            [
              -179.99979825111026,
              61.488538765323995
            ]
          ],
          [
            [
              179.9999468600578,
              61.488538765323995
            ],
            [
              179.57191702491306,
              60.869535166329314
            ],
            [
              173.1249515920039,
              55.90629872677903
            ],
            [
              168.2999550100769,
              54.12204057128261
            ],
            [
              159.74996106697276,
              52.64601998037537
            ],
            [
              158.65001067431294,
              53.016667896883945
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "cadmos-2",
        "name": "CADMOS-2",
        "color": "#939597",
        "feature_id": "cadmos-2-0",
        "coordinates": [
          34.53155372323303,
          34.3300303603024
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              33.61060042587516,
              34.82728147271527
            ],
            [
              33.97505016769546,
              34.59045588265229
            ],
            [
              35.100049370139544,
              34.06399293012595
            ],
            [
              35.485109097955785,
              33.89263712836993
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "norfest",
        "name": "Norfest",
        "color": "#4db748",
        "feature_id": "norfest-0",
        "coordinates": [
          7.781000063263264,
          57.87071671634487
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              10.575066743867296,
              59.06836550247505
            ],
            [
              10.912566504779448,
              58.95251729542423
            ],
            [
              11.178985225833799,
              58.93816561689118
            ]
          ],
          [
            [
              10.237566982955343,
              58.89444683796128
            ],
            [
              10.016367140251418,
              59.08111069751331
            ]
          ],
          [
            [
              9.112567779915338,
              58.36740335134627
            ],
            [
              8.758668030621124,
              58.470047
            ]
          ],
          [
            [
              8.325068337787286,
              57.93205658695149
            ],
            [
              7.994848571718109,
              58.143805
            ]
          ],
          [
            [
              6.525069612923346,
              57.92873815757028
            ],
            [
              6.786929427419381,
              58.06443999999988
            ]
          ],
          [
            [
              10.700836654770796,
              59.459166999999894
            ],
            [
              10.571156746637229,
              59.30270062107644
            ],
            [
              10.575066743867296,
              59.06836550247505
            ],
            [
              10.237566982955343,
              58.89444683796128
            ],
            [
              9.900067222043361,
              58.7196487955913
            ],
            [
              9.450067540827291,
              58.48521196181832
            ],
            [
              9.112567779915338,
              58.36740335134627
            ],
            [
              8.662568098699438,
              58.071153849283064
            ],
            [
              8.325068337787286,
              57.93205658695149
            ],
            [
              7.875068657167304,
              57.872277910468455
            ],
            [
              6.883089359298907,
              57.855814687342985
            ],
            [
              6.525069612923346,
              57.92873815757028
            ],
            [
              5.962570011403358,
              58.26235337816772
            ],
            [
              5.98870999288556,
              58.42414318521941
            ],
            [
              5.512570330187287,
              58.48521196181832
            ],
            [
              5.287570489579423,
              58.641677771384906
            ],
            [
              5.287570489579423,
              58.81686755183256
            ],
            [
              5.512570330783177,
              58.99117670269845
            ],
            [
              5.730770176208466,
              58.97082148666851
            ]
          ],
          [
            [
              10.748015840098589,
              59.918603026720774
            ],
            [
              10.575066743867296,
              59.755303236949885
            ],
            [
              10.575066743867296,
              59.64177978429328
            ],
            [
              10.700836654770796,
              59.459166999999894
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "arimao",
        "name": "ARIMAO",
        "color": "#c45527",
        "feature_id": "arimao-0",
        "coordinates": [
          -71.54107005893857,
          16.928611620389574
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -80.44896128872463,
              22.14594977858718
            ],
            [
              -80.09986902115736,
              21.3561644823302
            ],
            [
              -79.42486949873734,
              20.375041253465525
            ],
            [
              -77.73737069477342,
              19.139836294207043
            ],
            [
              -76.20409564349079,
              18.67617447535966
            ],
            [
              -74.69987284596938,
              17.823934412537824
            ],
            [
              -71.99987475867339,
              17.0726759398961
            ],
            [
              -66.14987890286532,
              15.23578178303569
            ],
            [
              -62.09988177251728,
              14.510916127872264
            ],
            [
              -61.11213835606199,
              14.62900014542359
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "eastern-light-sweden-finland-ii",
        "name": "Eastern Light Sweden-Finland II",
        "color": "#939597",
        "feature_id": "eastern-light-sweden-finland-ii-0",
        "coordinates": [
          21.311911975537868,
          59.90808366284725
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              24.655782122502643,
              60.2067802511458
            ],
            [
              24.41255891053615,
              60.13094306980764
            ],
            [
              23.850058953274385,
              60.074868164985645
            ],
            [
              23.17505900455967,
              60.01869775526416
            ],
            [
              22.966757966073033,
              59.82340864139897
            ],
            [
              22.725058137295406,
              59.79305890746801
            ],
            [
              21.37505899829742,
              59.90607001382671
            ],
            [
              20.907999423920927,
              59.92096373348889
            ],
            [
              20.250059226800033,
              59.62282165244591
            ],
            [
              18.900059329371544,
              59.508848492649406
            ],
            [
              18.33755937210975,
              59.42311514129747
            ],
            [
              17.943566877498768,
              59.40288874272457
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "timor-leste-south-submarine-cable-tlssc",
        "name": "Timor-Leste South Submarine Cable (TLSSC)",
        "color": "#939597",
        "feature_id": "timor-leste-south-submarine-cable-tlssc-0",
        "coordinates": [
          127.49085731822689,
          -9.087719086501778
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              125.57937980498295,
              -8.559447499464255
            ],
            [
              125.77498513397242,
              -8.345488699899732
            ],
            [
              126.4499846575844,
              -8.289830421356356
            ],
            [
              126.8999843382044,
              -8.141369965795263
            ],
            [
              127.3499840194203,
              -8.252720521975078
            ],
            [
              127.57498386002848,
              -9.586362493294047
            ],
            [
              127.23748409911636,
              -10.472598924989882
            ],
            [
              126.4499846575844,
              -11.50333384598423
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "medloop",
        "name": "Medloop",
        "color": "#939597",
        "feature_id": "medloop-0",
        "coordinates": [
          7.781855630424161,
          42.18150710942129
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              7.200069135343199,
              42.90873242772045
            ],
            [
              7.425068975355316,
              42.329239699665635
            ],
            [
              8.414618274945298,
              41.919502734658465
            ],
            [
              8.73873804533585,
              41.919502734658465
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "medloop",
        "name": "Medloop",
        "color": "#9d4529",
        "feature_id": "medloop-1",
        "coordinates": [
          7.30827509334307,
          42.98029988712469
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              5.962570011403358,
              42.6610388144903
            ],
            [
              4.050071366235301,
              41.68837522565789
            ],
            [
              2.70007232258726,
              41.26694507168793
            ],
            [
              2.168725042748747,
              41.38560270176822
            ]
          ],
          [
            [
              8.938867903561913,
              44.41035752885395
            ],
            [
              8.887567939307473,
              44.051519228735145
            ],
            [
              8.437568258091403,
              43.72721479104973
            ],
            [
              7.200069135343199,
              42.90873242772045
            ],
            [
              6.412569692619428,
              42.6610388144903
            ],
            [
              5.962570011403358,
              42.6610388144903
            ],
            [
              5.372530429989041,
              43.29362778902916
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "asia-link-cable-alc",
        "name": "Asia Link Cable (ALC)",
        "color": "#939597",
        "feature_id": "asia-link-cable-alc-0",
        "coordinates": [
          112.42843336933313,
          10.074961718865438
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              104.1141404799101,
              1.925884465105383
            ],
            [
              104.32889093295782,
              2.027683761259311
            ],
            [
              104.66056009282133,
              1.961481175550773
            ]
          ],
          [
            [
              114.07499342354825,
              14.801154224791475
            ],
            [
              109.79999645259218,
              15.994209911785873
            ],
            [
              108.89999709016004,
              16.10232559580288
            ],
            [
              108.19247759137372,
              16.04339300520843
            ]
          ],
          [
            [
              111.14999549624022,
              7.744889052551343
            ],
            [
              112.94999422050827,
              5.883218793719635
            ],
            [
              114.2999932647522,
              5.174038327225986
            ],
            [
              114.88563284987973,
              4.926762452886777
            ]
          ],
          [
            [
              114.63749302506844,
              19.670379638527862
            ],
            [
              112.49999453929237,
              20.410190986733
            ],
            [
              111.14999549564433,
              20.410190986733
            ],
            [
              110.69999581323626,
              20.26954403592967
            ],
            [
              110.34922606291693,
              20.017373844309958
            ]
          ],
          [
            [
              114.74999294537238,
              17.121959690200356
            ],
            [
              116.99999135204828,
              16.857467609772414
            ],
            [
              119.92498927995226,
              16.749771315644608
            ],
            [
              120.35557897432247,
              16.51402516186755
            ]
          ],
          [
            [
              114.97499278657611,
              18.26517080051488
            ],
            [
              116.99999135204828,
              18.03800543960884
            ],
            [
              119.92498927995226,
              16.857467609772414
            ],
            [
              120.35483897484684,
              16.83071677377086
            ]
          ],
          [
            [
              114.15872336423311,
              22.28332923217181
            ],
            [
              114.41249318505615,
              20.796306105108954
            ],
            [
              114.63749302506844,
              19.670379638527862
            ],
            [
              114.97499278657611,
              18.26517080051488
            ],
            [
              114.74999294537238,
              17.121959690200356
            ],
            [
              114.07499342354825,
              14.801154224791475
            ],
            [
              113.39999390232023,
              12.615395567393307
            ],
            [
              112.38749461958415,
              9.96791518697421
            ],
            [
              111.14999549624022,
              7.744889052551343
            ],
            [
              107.99999772772824,
              5.286069860821101
            ],
            [
              107.0999983652961,
              4.725718053703702
            ],
            [
              105.74999932164823,
              3.940475772228723
            ],
            [
              104.90624991936826,
              2.803404866588352
            ],
            [
              104.66056009282133,
              1.961481175550773
            ],
            [
              104.47058772799548,
              1.468426767332062
            ],
            [
              104.28790035741284,
              1.299801162778834
            ],
            [
              104.19209042528556,
              1.26242303552462
            ],
            [
              103.9870105705659,
              1.389451396800126
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "gold-data-1-gd-1liberty-networks-1-ln-1",
        "name": "Gold Data-1 (GD-1)/Liberty Networks-1 (LN-1)",
        "color": "#939597",
        "feature_id": "gold-data-1-gd-1liberty-networks-1-ln-1-0",
        "coordinates": [
          -80.95313552313405,
          16.0271021935348
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -78.7498699775094,
              12.542195817724576
            ],
            [
              -79.42486949873734,
              9.96791518697421
            ],
            [
              -79.75353926650064,
              9.43754699999974
            ]
          ],
          [
            [
              -75.50568227572239,
              10.3870050000003
            ],
            [
              -76.04987188961734,
              10.963556857789229
            ],
            [
              -78.7498699775094,
              12.542195817724576
            ],
            [
              -79.64986934053736,
              13.929303843271725
            ],
            [
              -80.99986838299333,
              16.10232559580288
            ],
            [
              -82.34986742664137,
              17.39502263470061
            ],
            [
              -85.04986551393736,
              19.104405475930548
            ],
            [
              -86.06236479726944,
              20.375041253465525
            ],
            [
              -86.17486471816953,
              21.635297384859456
            ]
          ],
          [
            [
              -86.01795306402698,
              25.562629005382774
            ],
            [
              -84.59986583331738,
              27.89623898952877
            ],
            [
              -84.26591848833185,
              30.07102544482737
            ]
          ],
          [
            [
              -86.01795306402698,
              25.562629005382774
            ],
            [
              -88.19986328304552,
              23.848523186488023
            ],
            [
              -91.79986073277338,
              23.022776999376163
            ],
            [
              -94.49985882006956,
              21.3561644823302
            ],
            [
              -96.14075765764137,
              19.195461502894176
            ]
          ],
          [
            [
              -84.82486567332933,
              24.941363171753835
            ],
            [
              -84.82486567332933,
              23.917101290935022
            ],
            [
              -86.17486471816953,
              21.635297384859456
            ],
            [
              -86.51236447788934,
              21.32123529551194
            ],
            [
              -86.76758665233308,
              21.095728792367282
            ]
          ],
          [
            [
              -80.16222149909751,
              25.933097
            ],
            [
              -80.21236894086556,
              25.348717422116806
            ],
            [
              -80.9998683829937,
              24.73717827217618
            ],
            [
              -83.24986678907334,
              24.73717827217618
            ],
            [
              -84.82486567332933,
              24.941363171753835
            ],
            [
              -86.01795306402698,
              25.562629005382774
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "taiwan-penghu-kinmen-matsu-no-3-tpkm3",
        "name": "Taiwan Penghu Kinmen Matsu No.3 (TPKM3)",
        "color": "#4dbd9a",
        "feature_id": "taiwan-penghu-kinmen-matsu-no-3-tpkm3-0",
        "coordinates": [
          119.32652714053344,
          23.74006483313846
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              119.93434927272565,
              26.15516
            ],
            [
              119.98859609999974,
              26.225407299999745
            ],
            [
              120.14998911996437,
              26.2434248849805
            ],
            [
              120.37498896057238,
              26.293865839752385
            ],
            [
              120.49297000000055,
              26.367336499999624
            ]
          ],
          [
            [
              119.93434927272565,
              26.15516
            ],
            [
              119.86873931920437,
              26.041442343792852
            ],
            [
              119.93907506033597,
              25.973144107820616
            ]
          ],
          [
            [
              119.93434927272565,
              26.15516
            ],
            [
              120.26248903788431,
              25.754704263415306
            ],
            [
              120.93748856149651,
              25.24700643263671
            ],
            [
              121.31110780616902,
              24.988888900000035
            ]
          ],
          [
            [
              118.31584640000031,
              24.413418899999556
            ],
            [
              118.46249031540438,
              24.310716907075857
            ],
            [
              119.2499897575324,
              23.797065778811373
            ],
            [
              119.51201957190816,
              23.60192
            ],
            [
              119.5762539971847,
              23.573729363608095
            ],
            [
              119.65615957190877,
              23.57363
            ],
            [
              119.69998943874938,
              23.436294941322956
            ],
            [
              120.03748919965736,
              23.281375912674093
            ],
            [
              120.18086199999962,
              22.99721
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "unitirreno-cable",
        "name": "Unitirreno Cable",
        "color": "#939597",
        "feature_id": "unitirreno-cable-0",
        "coordinates": [
          10.920768425783734,
          41.05506109627515
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              11.250066265691402,
              41.26694507168793
            ],
            [
              10.912566504779448,
              41.26694507168793
            ],
            [
              9.900067222043361,
              41.05520302146505
            ],
            [
              9.49644750797134,
              40.92357574660872
            ]
          ],
          [
            [
              10.912566504779448,
              41.0693404382163
            ],
            [
              11.250066265691402,
              41.26694507168793
            ],
            [
              11.587566026603383,
              41.43584595024919
            ],
            [
              12.037565707819454,
              41.60430845897952
            ],
            [
              12.226913,
              41.769718
            ]
          ],
          [
            [
              8.938867903561913,
              44.41035752885395
            ],
            [
              9.337567620523373,
              44.051519228735145
            ],
            [
              9.675067381435326,
              43.40114497315386
            ],
            [
              9.787567301143554,
              43.07331078300331
            ],
            [
              10.0125671411555,
              42.41235450073577
            ],
            [
              10.575066743867296,
              41.520132020893186
            ],
            [
              10.912566504779448,
              41.0693404382163
            ],
            [
              11.700065946907301,
              39.69832335493328
            ],
            [
              12.037565708415343,
              38.21117903702327
            ],
            [
              12.150065626335305,
              37.85673997565843
            ],
            [
              12.591375316091586,
              37.650586172786205
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "kochi-lakshadweep-islands-kli-sofc",
        "name": "Kochi-Lakshadweep Islands (KLI-SOFC)",
        "color": "#d32c26",
        "feature_id": "kochi-lakshadweep-islands-kli-sofc-0",
        "coordinates": [
          73.8375658722444,
          10.682877846721738
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              72.72286795461696,
              11.12425863180556
            ],
            [
              72.61877279152398,
              10.889916637992286
            ],
            [
              72.337522990764,
              10.779422194820228
            ],
            [
              72.19544309141469,
              10.8628677474359
            ]
          ],
          [
            [
              73.05120248518665,
              8.274648803882855
            ],
            [
              73.23752235319705,
              8.283326224170281
            ],
            [
              75.65367042863167,
              9.650748624678783
            ],
            [
              76.26955020587488,
              9.938386424893082
            ]
          ],
          [
            [
              73.64411206516394,
              10.069817641044835
            ],
            [
              73.80002195471596,
              9.894039027618874
            ],
            [
              73.57502211410792,
              8.783969455379207
            ],
            [
              73.23752235319705,
              8.33898542602023
            ],
            [
              73.05120248518665,
              8.274648803882855
            ]
          ],
          [
            [
              72.71064272644247,
              11.689816748289559
            ],
            [
              72.39377295091595,
              11.496892979680922
            ],
            [
              72.18593318270163,
              11.598433214485254
            ],
            [
              72.28127303061186,
              11.331480662183736
            ],
            [
              72.28722656010737,
              10.939880964178286
            ],
            [
              72.39377295091595,
              10.889916637992286
            ],
            [
              72.337522990764,
              10.807049625860799
            ],
            [
              72.19544309141469,
              10.8628677474359
            ],
            [
              72.22502307045988,
              10.779422194820228
            ],
            [
              72.45002291106792,
              10.55831195245645
            ],
            [
              72.64071337472492,
              10.564728356964537
            ],
            [
              72.67502275167595,
              10.337042550001456
            ],
            [
              73.35002227350006,
              10.004846997649508
            ],
            [
              73.64410775915096,
              10.069817641044835
            ]
          ],
          [
            [
              76.26955020587488,
              9.938386424893082
            ],
            [
              75.60002068017599,
              10.078698006650882
            ],
            [
              73.78659196423001,
              10.700352001209751
            ],
            [
              73.67409366970384,
              10.81087817916901
            ],
            [
              73.5615921236218,
              10.700352001209751
            ],
            [
              72.72286795461696,
              11.12425863180556
            ],
            [
              72.8303426416459,
              11.142200303720472
            ],
            [
              72.77758267902166,
              11.227182491217889
            ],
            [
              72.94284256195002,
              11.197384742384742
            ],
            [
              73.12502243289185,
              11.331480662183736
            ],
            [
              73.12502243289185,
              11.441766261214156
            ],
            [
              73.0064462362784,
              11.485221714276236
            ],
            [
              72.90002259228399,
              11.66220822386424
            ],
            [
              72.71064272644247,
              11.689816748289559
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "sagres",
        "name": "Sagres",
        "color": "#4ab970",
        "feature_id": "sagres-0",
        "coordinates": [
          -9.158888038057086,
          37.49365483389863
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9.102749315587062,
              38.443079483141986
            ],
            [
              -8.999919388432772,
              38.12273010839229
            ],
            [
              -9.224919229040808,
              37.232354321556215
            ],
            [
              -8.999919389028747,
              36.90321229501777
            ],
            [
              -8.887419468724744,
              36.90321229501777
            ],
            [
              -8.774539548689916,
              37.073387515315204
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "continente-madeira",
        "name": "Continente-Madeira",
        "color": "#37b98e",
        "feature_id": "continente-madeira-0",
        "coordinates": [
          -13.435247523940859,
          36.04351783909436
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9.331559153496016,
              38.69016197235561
            ],
            [
              -10.799918113296798,
              38.29952060596935
            ],
            [
              -13.949915881808863,
              35.60293032290622
            ],
            [
              -16.199914288484848,
              34.06399293012595
            ],
            [
              -16.818663850156838,
              32.81231878328768
            ],
            [
              -16.908893785641084,
              32.64727814971002
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "almera-melilla-alme",
        "name": "Almería-Melilla (ALME)",
        "color": "#2dbdbd",
        "feature_id": "almera-melilla-alme-0",
        "coordinates": [
          -2.543969416720074,
          36.00572179222334
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -2.463794019281238,
              36.84186246473152
            ],
            [
              -2.474924011396666,
              36.452085168401965
            ],
            [
              -2.587423931700698,
              35.72479789980608
            ],
            [
              -2.938093683283,
              35.29227411254928
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "roquetas-melilla-cam",
        "name": "Roquetas-Melilla (CAM)",
        "color": "#2fa685",
        "feature_id": "roquetas-melilla-cam-0",
        "coordinates": [
          -2.6575606697573733,
          35.998666565867694
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -2.612503913933836,
              36.76384217382926
            ],
            [
              -2.587423931700698,
              36.452085168401965
            ],
            [
              -2.699923852004702,
              35.72479789980608
            ],
            [
              -2.938093683283,
              35.29227411254928
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "n0r5ke-viking-2",
        "name": "N0r5ke Viking 2",
        "color": "#939597",
        "feature_id": "n0r5ke-viking-2-0",
        "coordinates": [
          9.561786508580099,
          58.66063430283086
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              10.575066743867296,
              58.95251729542423
            ],
            [
              10.687566664171413,
              59.06836550247505
            ],
            [
              10.627410456786663,
              59.30270062107644
            ],
            [
              10.432776844666904,
              59.420833
            ],
            [
              10.575066743867296,
              59.52787100202244
            ],
            [
              10.713429844666706,
              59.716322
            ],
            [
              10.743816624323387,
              59.755303236949885
            ],
            [
              10.749978844667083,
              59.91228
            ]
          ],
          [
            [
              7.994848571718109,
              58.143805
            ],
            [
              8.325068337787286,
              58.071153849283064
            ],
            [
              8.662568098699438,
              58.18995038432837
            ],
            [
              8.775068019003356,
              58.30835113805557
            ],
            [
              8.758668030621124,
              58.470047
            ],
            [
              9.112567779915338,
              58.48521196181832
            ],
            [
              9.450067540827291,
              58.60262683506721
            ],
            [
              9.900067222043361,
              58.836278671696704
            ],
            [
              10.016391030621492,
              59.081111
            ],
            [
              10.237566982955343,
              59.01049014866166
            ],
            [
              10.575066743867296,
              58.95251729542423
            ],
            [
              10.912566504779448,
              58.9234942737622
            ],
            [
              11.20667303062163,
              58.914588
            ]
          ],
          [
            [
              5.730790175598344,
              58.97083300000011
            ],
            [
              5.681320210643349,
              58.95251729542423
            ],
            [
              5.574100286598963,
              58.936182
            ],
            [
              5.400070409883341,
              58.81686755183256
            ],
            [
              5.400070409883341,
              58.641677771384906
            ],
            [
              5.625070250491405,
              58.48521196181832
            ],
            [
              5.98870999288556,
              58.42414318521941
            ],
            [
              6.075069931707276,
              58.26235337816772
            ],
            [
              6.525069612923346,
              58.04800849338315
            ],
            [
              6.786929427419381,
              58.06443999999988
            ],
            [
              6.883089359298907,
              57.91562072284151
            ],
            [
              7.762568737459446,
              57.93205658695149
            ],
            [
              7.994848571718109,
              58.143805
            ]
          ],
          [
            [
              5.332770458155238,
              60.390723705809705
            ],
            [
              5.231320529427279,
              60.4006285487248
            ],
            [
              5.175070569275306,
              60.37283301419319
            ],
            [
              5.175070569275306,
              60.20556098402312
            ],
            [
              5.287570489579423,
              60.14961328483298
            ],
            [
              5.343820449731396,
              60.06551290255851
            ],
            [
              5.343820449731396,
              60.009326600079135
            ],
            [
              5.512570330187287,
              59.89666699242801
            ],
            [
              5.512570330187287,
              59.78362399299567
            ],
            [
              5.400070409883341,
              59.72695845149599
            ],
            [
              5.287570489579423,
              59.64177978429328
            ],
            [
              5.298330481956754,
              59.446389
            ],
            [
              5.287570489579423,
              59.3849418504106
            ],
            [
              5.297950482226156,
              59.324351
            ],
            [
              5.400070409883341,
              59.15499595157121
            ],
            [
              5.625070250491405,
              59.09726664990825
            ],
            [
              5.737570170795323,
              59.039440006437644
            ],
            [
              5.730790175598344,
              58.97083300000011
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "philippine-domestic-submarine-cable-network-pdscn",
        "name": "Philippine Domestic Submarine Cable Network (PDSCN)",
        "color": "#939597",
        "feature_id": "philippine-domestic-submarine-cable-network-pdscn-0",
        "coordinates": [
          121.84808636692307,
          7.691515447263953
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              123.83892650668648,
              10.252013798268166
            ],
            [
              123.86248648999637,
              10.078698006650882
            ],
            [
              123.80623652984437,
              9.801670473167405
            ],
            [
              123.87820913570422,
              9.675391878909068
            ]
          ],
          [
            [
              122.06327100000036,
              6.908093105542434
            ],
            [
              121.94998784482848,
              6.889421762667638
            ],
            [
              121.72498800422042,
              7.001096155427831
            ],
            [
              121.72498800422042,
              7.447522319872292
            ],
            [
              121.94998784482848,
              7.893494252945061
            ],
            [
              122.41209034937052,
              8.210039921651003
            ],
            [
              122.6784766944962,
              8.073858105718074
            ]
          ],
          [
            [
              123.98834884332425,
              10.40642028313701
            ],
            [
              124.19998625150438,
              10.300149066026535
            ],
            [
              124.64998593272028,
              10.18944276650771
            ],
            [
              124.84217394836767,
              10.178582995816868
            ]
          ],
          [
            [
              123.33503132573671,
              8.564097021577197
            ],
            [
              123.18748696817241,
              8.635699417327544
            ],
            [
              122.73748728695634,
              8.41318535956026
            ],
            [
              122.6784766944962,
              8.073858105718074
            ]
          ],
          [
            [
              123.33503132573671,
              8.564097021577197
            ],
            [
              123.41248680878047,
              8.74690746413691
            ],
            [
              123.63748664938848,
              8.858082310478125
            ],
            [
              124.48123605226439,
              8.635699417327544
            ],
            [
              124.63191594552123,
              8.454147535358373
            ]
          ],
          [
            [
              123.91358645439269,
              9.622537095709703
            ],
            [
              124.19998625150438,
              9.302441529883055
            ],
            [
              124.64998593272028,
              8.635699417327544
            ],
            [
              124.63191594552123,
              8.454147535358373
            ]
          ],
          [
            [
              123.86154340165243,
              11.940899155789083
            ],
            [
              123.93298644005355,
              11.537441867288294
            ],
            [
              124.1999862509085,
              11.294709319565555
            ],
            [
              124.39720203273188,
              11.054861343166067
            ]
          ],
          [
            [
              124.72892524992008,
              9.172531794727831
            ],
            [
              124.76248585064045,
              9.08033076823304
            ],
            [
              124.8164946101592,
              8.982805933411782
            ]
          ],
          [
            [
              126.05252036481781,
              9.855207400456749
            ],
            [
              125.88748505546849,
              9.801670473167405
            ],
            [
              125.83123509531637,
              9.690794260828369
            ],
            [
              125.86972003454414,
              9.498333545906235
            ]
          ],
          [
            [
              123.90123074088766,
              12.105980429992059
            ],
            [
              123.86248648880436,
              12.395734000022884
            ],
            [
              123.89612042234293,
              12.683687101725894
            ]
          ],
          [
            [
              124.60027596793546,
              12.069904662870869
            ],
            [
              124.42498609092044,
              12.06589527357022
            ],
            [
              124.08748633060438,
              12.17588718550806
            ],
            [
              123.90123074088766,
              12.105980429992059
            ]
          ],
          [
            [
              124.39720203273188,
              11.054861343166067
            ],
            [
              124.30291617799182,
              10.932011625131736
            ],
            [
              124.1999862509085,
              10.742581675476316
            ],
            [
              124.08748633120027,
              10.52144468555204
            ],
            [
              123.98834884332425,
              10.40642028313701
            ]
          ],
          [
            [
              125.48676677437308,
              9.782713534000806
            ],
            [
              125.09998561393635,
              9.85709470870242
            ],
            [
              124.8749857727324,
              9.96791518697421
            ],
            [
              124.84217394836767,
              10.178582995816868
            ]
          ],
          [
            [
              123.16748732333238,
              9.146219690585724
            ],
            [
              123.1874869687683,
              8.969223547813073
            ],
            [
              123.24373692832435,
              8.734552646542681
            ],
            [
              123.33503132573671,
              8.564097021577197
            ]
          ],
          [
            [
              122.7499872786972,
              11.583202180445141
            ],
            [
              122.96248712816029,
              11.625479959569759
            ],
            [
              123.29998688907224,
              11.625479959569759
            ],
            [
              123.86154340165243,
              11.940899155789083
            ]
          ],
          [
            [
              123.4128774340995,
              10.483708779671844
            ],
            [
              123.52498672908436,
              10.447696947275745
            ],
            [
              123.63524055782548,
              10.376237129155953
            ]
          ],
          [
            [
              122.56210741119705,
              10.720150965231483
            ],
            [
              122.73748728695634,
              10.656603009743577
            ],
            [
              122.968957,
              10.64073899999974
            ]
          ],
          [
            [
              121.94481206784073,
              11.949266020482952
            ],
            [
              121.95077784426886,
              11.93092980247377
            ]
          ],
          [
            [
              122.08171170471681,
              12.602955744095539
            ],
            [
              122.17498768484043,
              12.725155923562937
            ],
            [
              122.34373756589244,
              12.651987602736313
            ],
            [
              122.34373756589244,
              12.487282342244868
            ],
            [
              122.39998752664027,
              12.06589527357022
            ],
            [
              122.7499872786972,
              11.583202180445141
            ]
          ],
          [
            [
              122.08171170471681,
              12.602955744095539
            ],
            [
              122.11873772528438,
              12.725155923562937
            ],
            [
              122.79373724710828,
              13.273238157547667
            ],
            [
              122.95067073028108,
              13.546888070207757
            ]
          ],
          [
            [
              121.61229277215065,
              13.935275186648965
            ],
            [
              121.78123796437242,
              13.492128176464178
            ],
            [
              121.88473418104374,
              13.429083133052075
            ],
            [
              121.94998784542437,
              13.054150695298716
            ],
            [
              122.06248776572832,
              12.725155923562937
            ],
            [
              122.08171170471681,
              12.602955744095539
            ]
          ],
          [
            [
              121.07814990718268,
              13.635709425447232
            ],
            [
              121.33123828375224,
              13.492128176464178
            ],
            [
              121.4999881642083,
              13.273238157547667
            ],
            [
              121.46453818932153,
              13.04578255071101
            ],
            [
              121.61248493301056,
              12.834868817846598
            ],
            [
              121.70270802000377,
              12.634486176502435
            ],
            [
              121.88855788834607,
              12.193716487973518
            ],
            [
              121.9448109999997,
              11.949244772363812
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "groix-4",
        "name": "Groix 4",
        "color": "#d41f77",
        "feature_id": "groix-4-0",
        "coordinates": [
          -3.4801890371332718,
          47.68245464793432
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -3.445566038986811,
              47.69743263943647
            ],
            [
              -3.487423294132753,
              47.679325091117676
            ],
            [
              -3.482272326026703,
              47.650237709593256
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "thetis",
        "name": "Thetis",
        "color": "#69a0d5",
        "feature_id": "thetis-0",
        "coordinates": [
          26.93845588572387,
          35.695363222847845
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              25.160525741214457,
              37.53871578201091
            ],
            [
              25.031181503020235,
              37.53033301336055
            ],
            [
              24.936036453932786,
              37.44158260544046
            ]
          ],
          [
            [
              25.32858123375334,
              37.439068852078265
            ],
            [
              25.256431343450913,
              37.53033301336055
            ],
            [
              25.207101363906816,
              37.53871578201091
            ]
          ],
          [
            [
              25.23814603454855,
              37.12223292912822
            ],
            [
              25.42505622399557,
              37.26220545235364
            ],
            [
              25.419566227884786,
              37.439068852078265
            ]
          ],
          [
            [
              25.375976258764183,
              37.101840262262925
            ],
            [
              25.368681263932217,
              37.1278819376671
            ],
            [
              25.289696105851903,
              37.12524684923886
            ]
          ],
          [
            [
              25.402346240083602,
              36.47742641187406
            ],
            [
              25.537556144299515,
              36.63285342448927
            ],
            [
              25.537556144299515,
              36.8131986057773
            ],
            [
              25.4036362391696,
              36.97726017314534
            ]
          ],
          [
            [
              26.99507401460926,
              36.771580493133
            ],
            [
              26.816614586178503,
              36.86493814541953
            ],
            [
              26.550055427035574,
              36.87321951208918
            ],
            [
              26.100055745819503,
              36.60275474032986
            ],
            [
              25.76255598490755,
              36.452085168401965
            ],
            [
              25.650056064603433,
              36.36154264004162
            ],
            [
              25.464805590265655,
              36.34542571389255
            ]
          ],
          [
            [
              28.120055780442613,
              36.41157986414535
            ],
            [
              27.900054470683443,
              36.452085168401965
            ],
            [
              27.337554869163455,
              36.63285342448927
            ],
            [
              27.14344500667275,
              36.78290060770803
            ]
          ],
          [
            [
              26.107170134812577,
              35.20695416056722
            ],
            [
              26.32505558642754,
              35.511407641688464
            ],
            [
              26.887555187947555,
              35.63341465389544
            ],
            [
              27.11255502855559,
              35.90725015614032
            ],
            [
              27.56255470977166,
              35.998318944906714
            ],
            [
              27.851088976705284,
              35.927851575374916
            ]
          ],
          [
            [
              20.11287183870283,
              39.385367990966586
            ],
            [
              20.192934930479254,
              39.42299840248003
            ],
            [
              20.2774113097486,
              39.449988876096256
            ]
          ],
          [
            [
              19.858511225015405,
              39.64154007419046
            ],
            [
              19.956685097840875,
              39.64097793514631
            ],
            [
              20.02100005227959,
              39.683031225295395
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "juno",
        "name": "JUNO",
        "color": "#939597",
        "feature_id": "juno-0",
        "coordinates": [
          -149.73852668994311,
          42.42191790592338
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -138.82500430186857,
              40.04369389177543
            ],
            [
              -127.79983522945773,
              47.19740739556977
            ],
            [
              -125.0998371421619,
              48.256798568947545
            ],
            [
              -124.64983746094566,
              48.331645382421584
            ],
            [
              -123.74983809851369,
              48.1818420220076
            ],
            [
              -122.84983873608172,
              48.1818420220076
            ],
            [
              -122.69416384695941,
              48.11006090495562
            ],
            [
              -122.66604386687982,
              48.03488959703961
            ],
            [
              -122.60980390672074,
              47.95960846190178
            ],
            [
              -122.51137147645116,
              47.921930050335504
            ],
            [
              -122.44105402626477,
              47.88421411607979
            ],
            [
              -122.30218058161336,
              47.886323206202945
            ]
          ],
          [
            [
              -179.99981351108067,
              42.740359175634524
            ],
            [
              -151.19983391146837,
              42.740359175634524
            ],
            [
              -138.82500430186857,
              40.04369389177543
            ],
            [
              -129.59983395432175,
              36.14986678681771
            ],
            [
              -122.84983873608172,
              34.867831005273246
            ],
            [
              -120.62152181466485,
              35.12094936772425
            ]
          ],
          [
            [
              143.09997286257644,
              33.93964008831958
            ],
            [
              141.2999741377125,
              34.68301765985788
            ],
            [
              139.9609750856761,
              34.97407819999984
            ]
          ],
          [
            [
              179.9999271925697,
              42.74369770072906
            ],
            [
              172.79992073307184,
              42.74369770072906
            ],
            [
              160.19996074878455,
              39.00237890905848
            ],
            [
              149.39996839960057,
              35.419780517080454
            ],
            [
              143.09997286257644,
              33.93964008831958
            ],
            [
              140.39997477528036,
              33.18971466460036
            ],
            [
              138.59997605041642,
              32.43331330641712
            ],
            [
              137.69997668798445,
              33.00121852265437
            ],
            [
              136.87399727311598,
              34.33682825203164
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "bridge-one",
        "name": "Bridge One",
        "color": "#939597",
        "feature_id": "bridge-one-0",
        "coordinates": [
          130.0459985233728,
          34.81277089915645
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              129.55887245462318,
              35.990414289897785
            ],
            [
              129.71248234580443,
              35.541926812580044
            ],
            [
              130.2749819473244,
              34.31215165223537
            ],
            [
              130.15050203550706,
              33.597549134679156
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "vodafone-greece-domestic",
        "name": "Vodafone Greece Domestic",
        "color": "#aebf3c",
        "feature_id": "vodafone-greece-domestic-0",
        "coordinates": [
          24.542210608247075,
          37.79066938763407
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              24.011428,
              37.88817800000015
            ],
            [
              24.300057020955563,
              37.88634204539153
            ],
            [
              24.750056702171463,
              37.70855130533482
            ],
            [
              24.936036453932786,
              37.44158260544046
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "hdr1",
        "name": "HDR1",
        "color": "#b81e4b",
        "feature_id": "hdr1-0",
        "coordinates": [
          -59.64279082749593,
          46.865405826639204
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -60.224123101322306,
              46.229284
            ],
            [
              -59.849883365841194,
              46.58235508209589
            ],
            [
              -59.39988368462521,
              47.19740739556977
            ],
            [
              -59.284726,
              47.63667299999966
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "hdr2",
        "name": "HDR2",
        "color": "#b4287b",
        "feature_id": "hdr2-0",
        "coordinates": [
          -59.93902786198824,
          47.400453958056204
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -60.46931292762757,
              46.89666
            ],
            [
              -60.29988304765317,
              46.99317357497881
            ],
            [
              -59.62488352582923,
              47.75501398838159
            ],
            [
              -59.39192999999963,
              47.881569
            ]
          ],
          [
            [
              -66.06412896420734,
              45.273428999999595
            ],
            [
              -65.92487906285328,
              45.15945348701308
            ],
            [
              -65.24987954102926,
              45.11977579961189
            ],
            [
              -64.94827799999963,
              45.089458
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "unitel-north-submarine-cable-unsc",
        "name": "Unitel North Submarine Cable (UNSC)",
        "color": "#4bc2c8",
        "feature_id": "unitel-north-submarine-cable-unsc-0",
        "coordinates": [
          11.486300240357792,
          -6.713444862776555
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              12.189865599928936,
              -5.556576787346813
            ],
            [
              11.925065787515337,
              -5.628582347656248
            ],
            [
              11.812565867211418,
              -5.796494123131055
            ],
            [
              11.812565867211418,
              -6.132166432128628
            ],
            [
              12.150065628123372,
              -6.188091595867974
            ],
            [
              12.352065485024895,
              -6.141015427762483
            ]
          ],
          [
            [
              12.14088,
              -5.23097
            ],
            [
              11.700065946907301,
              -5.572600905016571
            ],
            [
              11.475066106299465,
              -5.796494123131055
            ],
            [
              11.475066106299465,
              -6.691145450676487
            ],
            [
              11.812565867211418,
              -7.361072141346966
            ],
            [
              12.375065468731407,
              -7.361072141346966
            ],
            [
              12.866666670000484,
              -7.233333333000246
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "romulo",
        "name": "Romulo",
        "color": "#e1b524",
        "feature_id": "romulo-0",
        "coordinates": [
          1.1119401621181453,
          39.438156638047246
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -0.210385615616985,
              39.66255600000014
            ],
            [
              0.450073917103168,
              39.438156638047246
            ],
            [
              2.250072641967279,
              39.438156638047246
            ],
            [
              2.462767999999841,
              39.49995262687452
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "penbal-4",
        "name": "Penbal-4",
        "color": "#11b690",
        "feature_id": "penbal-4-0",
        "coordinates": [
          1.321871257354303,
          38.929564795738514
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -0.376975497007066,
              39.459646713436825
            ],
            [
              0.450073917103168,
              39.264170198047566
            ],
            [
              0.900073597723349,
              39.03151487972863
            ],
            [
              1.418889999999891,
              38.906115
            ],
            [
              1.800072960155291,
              38.94407095870682
            ],
            [
              2.475072482575314,
              39.264170198047566
            ],
            [
              2.911560000000463,
              39.61353499999987
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "east-coast-cable-system",
        "name": "East Coast Cable System",
        "color": "#939597",
        "feature_id": "east-coast-cable-system-0",
        "coordinates": [
          151.04011461492044,
          -35.06977421794579
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              144.96715153925365,
              -37.81753200000034
            ],
            [
              144.8048516542285,
              -38.25898836030271
            ],
            [
              144.8048516542285,
              -38.52352352301156
            ],
            [
              145.46247118836467,
              -39.08029874589167
            ],
            [
              146.24997063108842,
              -39.16757423638754
            ],
            [
              147.59996967473646,
              -38.99291515860628
            ],
            [
              149.39996839960057,
              -38.46634757786356
            ],
            [
              150.04997356293885,
              -37.94245352569678
            ],
            [
              151.12379905223068,
              -34.826981990554685
            ],
            [
              151.2070371188603,
              -33.86969599999975
            ],
            [
              152.09996648689648,
              -33.179523456669244
            ],
            [
              153.67496536996074,
              -31.851465665577166
            ],
            [
              154.34996489238077,
              -28.74381028114999
            ],
            [
              153.8999652117606,
              -27.951747285219852
            ],
            [
              153.02282700000043,
              -27.468884108165412
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "trans-adriatic-express-tae",
        "name": "Trans Adriatic Express (TAE)",
        "color": "#c072af",
        "feature_id": "trans-adriatic-express-tae-0",
        "coordinates": [
          18.845865667940245,
          40.62759182313721
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              18.405157000000173,
              40.30200700000023
            ],
            [
              18.575061076596427,
              40.50147554220263
            ],
            [
              19.125060686971437,
              40.757615606267876
            ],
            [
              19.376194000000368,
              40.786713
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "olisipo",
        "name": "Olisipo",
        "color": "#939597",
        "feature_id": "olisipo-0",
        "coordinates": [
          -9.289230839482599,
          38.27199840161157
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9.337909149593656,
              38.68884285232525
            ],
            [
              -9.48588404476692,
              38.61206568189243
            ],
            [
              -9.465249059384888,
              38.44269570155157
            ],
            [
              -9.20269924478157,
              38.1880826025359
            ],
            [
              -9.112419308736776,
              37.99143181402275
            ],
            [
              -8.86959721512926,
              37.95721527519197
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "carnival-submarine-network-1-csn-1",
        "name": "Carnival Submarine Network-1 (CSN-1)",
        "color": "#939597",
        "feature_id": "carnival-submarine-network-1-csn-1-0",
        "coordinates": [
          -84.65743758345528,
          17.93171527085167
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -79.53670942010498,
              8.9648260000005
            ],
            [
              -79.42486949873734,
              8.190543417795567
            ],
            [
              -78.97486981752137,
              7.298762754459602
            ],
            [
              -79.1998696581294,
              5.061986954416028
            ],
            [
              -81.4498680642095,
              2.367912558705314
            ],
            [
              -82.34986742723737,
              0.568578852526286
            ],
            [
              -82.12486758662942,
              -1.981015190984778
            ],
            [
              -81.44986806420931,
              -2.468145972656231
            ],
            [
              -80.85595099012791,
              -2.329805834324105
            ]
          ],
          [
            [
              -79.87486917935743,
              11.95585820711483
            ],
            [
              -79.64986933934539,
              12.17588718550806
            ],
            [
              -78.7498699775094,
              12.322472159549733
            ],
            [
              -75.3748723683893,
              11.441766261214156
            ],
            [
              -74.7797830000003,
              10.940435797483616
            ]
          ],
          [
            [
              -81.795793,
              26.139093999999687
            ],
            [
              -83.24986678907334,
              24.941363171753835
            ],
            [
              -84.82486567332933,
              23.711258142484382
            ],
            [
              -86.06236479726944,
              21.635297384859456
            ],
            [
              -85.94986487636933,
              20.375041253465525
            ],
            [
              -85.2748653545454,
              19.104405475930548
            ],
            [
              -84.37486599151727,
              17.39502263470061
            ],
            [
              -83.02486694786923,
              16.10232559580288
            ],
            [
              -81.67486790422127,
              13.929303843271725
            ],
            [
              -79.87486917935743,
              11.95585820711483
            ],
            [
              -79.7623692596494,
              9.96791518697421
            ],
            [
              -79.90025916196689,
              9.350855245144032
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "sea-h2x",
        "name": "SEA-H2X",
        "color": "#939597",
        "feature_id": "sea-h2x-0",
        "coordinates": [
          111.34948698267104,
          10.195380498317945
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              113.84999358294024,
              17.108519960795775
            ],
            [
              116.99999135204828,
              16.749771315644608
            ],
            [
              119.92498927995226,
              16.642014062854088
            ],
            [
              120.32093899886198,
              16.6158941471341
            ]
          ],
          [
            [
              107.99999772772824,
              5.845915088460174
            ],
            [
              109.12499693017233,
              4.164912849976844
            ],
            [
              109.79999645259218,
              2.817450442654064
            ],
            [
              110.3624960541122,
              1.918228780215685
            ],
            [
              110.35370606033919,
              1.520169126642031
            ]
          ],
          [
            [
              114.0749934241442,
              18.251816319028308
            ],
            [
              111.14999549564433,
              18.287425986594144
            ],
            [
              110.04937627533329,
              18.389897000000133
            ]
          ],
          [
            [
              105.74999932164823,
              4.501447394015287
            ],
            [
              104.8359699685594,
              5.398081130463737
            ],
            [
              103.04883123518098,
              7.005165564456178
            ],
            [
              101.70000219070411,
              7.131349142159223
            ],
            [
              100.59971297015935,
              7.075917959575207
            ]
          ],
          [
            [
              103.64609081207684,
              1.338585852071589
            ],
            [
              103.83750067588412,
              1.168506749040887
            ],
            [
              104.14002046217226,
              1.15796892865913
            ],
            [
              104.26002537715979,
              1.468426767332062
            ],
            [
              104.62500011860826,
              2.817450442654064
            ],
            [
              105.74999932164823,
              4.501447394015287
            ],
            [
              107.0999983652961,
              5.174038327225986
            ],
            [
              107.99999772772824,
              5.845915088460174
            ],
            [
              110.02499629320002,
              7.744889052551343
            ],
            [
              111.26249541654417,
              9.96791518697421
            ],
            [
              112.27499469928026,
              12.615395567393307
            ],
            [
              113.84999358294024,
              17.108519960795775
            ],
            [
              114.0749934241442,
              18.251816319028308
            ],
            [
              113.96249350384025,
              20.796306105108954
            ],
            [
              114.20308333280812,
              22.2221
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "hronn",
        "name": "Hronn",
        "color": "#009f94",
        "feature_id": "hronn-0",
        "coordinates": [
          6.4594555137418865,
          55.375967959374464
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              5.400070409883341,
              55.35590422837552
            ],
            [
              5.287570489579423,
              55.32391450012926
            ],
            [
              5.1805,
              55.32214999999985
            ]
          ],
          [
            [
              8.406946000000488,
              55.44848
            ],
            [
              7.650068816559269,
              55.39851702575686
            ],
            [
              5.400070409883341,
              55.35590422837552
            ],
            [
              4.513130000000331,
              55.41867
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "cook-strait",
        "name": "Cook Strait",
        "color": "#951f63",
        "feature_id": "cook-strait-0",
        "coordinates": [
          174.43018359914944,
          -41.370292880343044
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              174.2359708049474,
              -41.339027623451926
            ],
            [
              174.43120066664474,
              -41.37045661248359
            ],
            [
              174.61337053759397,
              -41.30162636085012
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "minoas-east-and-west",
        "name": "Minoas East and West",
        "color": "#60489d",
        "feature_id": "minoas-east-and-west-0",
        "coordinates": [
          23.66363463121445,
          35.665604654168675
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              23.035747220000616,
              36.52322222000015
            ],
            [
              23.287557738815394,
              36.240655233214795
            ],
            [
              23.51255757823165,
              36.05897312258671
            ],
            [
              23.72250000000031,
              35.51233333000022
            ],
            [
              23.400057657331615,
              36.05897312258671
            ],
            [
              23.175057819107565,
              36.240655233214795
            ],
            [
              23.035747220000616,
              36.52322222000015
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "apollo-east-and-west",
        "name": "Apollo East and West",
        "color": "#939597",
        "feature_id": "apollo-east-and-west-0",
        "coordinates": [
          24.847191332389713,
          35.48642068436527
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              23.363239415358947,
              37.97395211499247
            ],
            [
              23.344432695544725,
              37.76786242517883
            ],
            [
              23.400552658172842,
              37.67887792909195
            ],
            [
              23.51255757942343,
              37.58978657360316
            ],
            [
              23.96255726004344,
              36.51238821239372
            ],
            [
              24.41255694125951,
              35.90725015614032
            ],
            [
              24.93160657356006,
              35.40468665209255
            ],
            [
              24.525056861563428,
              35.90725015614032
            ],
            [
              24.075057180943446,
              36.51238821239372
            ],
            [
              23.625057499727376,
              37.58978657360316
            ],
            [
              23.45667262080096,
              37.67887792909195
            ],
            [
              23.400557656381352,
              37.76786242517883
            ],
            [
              23.363239415358947,
              37.97395211499247
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "seamewe-6",
        "name": "SeaMeWe-6",
        "color": "#939597",
        "feature_id": "seamewe-6-0",
        "coordinates": [
          68.01323056693488,
          14.847621353657303
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              58.612532713675705,
              23.814220515025227
            ],
            [
              58.4077828587225,
              23.584129999999732
            ]
          ],
          [
            [
              60.300031518831815,
              15.669513225155328
            ],
            [
              60.300031518831815,
              19.104405475930548
            ],
            [
              60.300031518831815,
              22.469443964829594
            ],
            [
              59.85003183761572,
              23.091785476922468
            ],
            [
              58.950032475183775,
              23.659746441192254
            ],
            [
              58.612532713675705,
              23.814220515025227
            ],
            [
              57.661372759507486,
              24.420846844473175
            ],
            [
              57.15003374972375,
              25.348717422116806
            ],
            [
              56.81253398940768,
              26.159307970773796
            ],
            [
              56.58753414879965,
              26.512189502051882
            ],
            [
              56.36253430819161,
              26.512189502051882
            ],
            [
              55.800034706671596,
              26.159307970773796
            ],
            [
              55.350035025455725,
              25.8559854660721
            ],
            [
              54.34014573908286,
              24.872582666687265
            ],
            [
              52.87503677817176,
              25.450342946923996
            ],
            [
              52.200037256943745,
              26.058287560298936
            ],
            [
              51.63753765542373,
              26.361086325391653
            ],
            [
              51.18753797420766,
              26.461843796189072
            ],
            [
              50.57595273852647,
              26.229438000000187
            ]
          ],
          [
            [
              54.41907568495611,
              24.443964572625504
            ],
            [
              54.34014573908286,
              24.872582666687265
            ]
          ],
          [
            [
              51.519387738526376,
              25.294536999999792
            ],
            [
              52.200037256943745,
              26.058287560298936
            ]
          ],
          [
            [
              85.50001366692797,
              0.118642751260523
            ],
            [
              83.70001494146797,
              5.510071711803135
            ],
            [
              83.025015419644,
              7.744876956882223
            ],
            [
              82.57501573842794,
              9.524411345019587
            ],
            [
              81.45001653598385,
              11.515266158038685
            ],
            [
              80.2429873910547,
              13.063853101883296
            ]
          ],
          [
            [
              65.75491565746177,
              16.45525448044245
            ],
            [
              65.47502785162396,
              20.375041253465525
            ],
            [
              66.15002737344807,
              23.298598065875808
            ],
            [
              67.02854675228852,
              24.889731701235718
            ]
          ],
          [
            [
              70.25491246962179,
              13.251828813229451
            ],
            [
              71.10002386801577,
              16.965102599435824
            ],
            [
              72.76336607969563,
              19.07608000000056
            ]
          ],
          [
            [
              74.70002131714793,
              5.659359572411404
            ],
            [
              74.25002163652775,
              4.837826391986653
            ],
            [
              73.54027200000024,
              4.211916943628012
            ]
          ],
          [
            [
              90.00001047908799,
              1.018588540518891
            ],
            [
              90.90000984092407,
              5.061986954416028
            ],
            [
              91.35000952273603,
              9.524411345019587
            ],
            [
              92.7000085663839,
              14.801154224791475
            ],
            [
              92.70000856578818,
              17.823934412537824
            ],
            [
              91.99482906593991,
              21.42927456664907
            ]
          ],
          [
            [
              101.25000250948804,
              2.030661890474562
            ],
            [
              101.47500234950016,
              2.592701464601845
            ],
            [
              101.44360237174422,
              2.751228763607116
            ]
          ],
          [
            [
              79.65001781052402,
              2.967259208499712
            ],
            [
              79.87501765113208,
              3.191933974144717
            ],
            [
              80.55001717295599,
              5.435413643888296
            ],
            [
              80.53985718074958,
              5.940820740520053
            ]
          ],
          [
            [
              44.55004267627157,
              10.963556857789229
            ],
            [
              43.6500433138396,
              11.399928123027479
            ],
            [
              43.147993669496344,
              11.594869371447714
            ]
          ],
          [
            [
              33.08276564295244,
              28.365936333863488
            ],
            [
              33.3568006038885,
              28.161052262220892
            ],
            [
              33.806300287239566,
              27.364667993860166
            ],
            [
              34.34067490927956,
              26.562513149236622
            ],
            [
              34.76254961101566,
              25.754704263415306
            ],
            [
              35.775048893751716,
              24.12261698700334
            ],
            [
              37.01254801709567,
              22.05298561667763
            ],
            [
              37.80004745922369,
              20.375041253465525
            ],
            [
              39.15004650227564,
              18.251816319028308
            ],
            [
              40.16254578501355,
              16.53419619825962
            ],
            [
              41.68129470911566,
              14.801154224791475
            ],
            [
              42.187544350483705,
              13.929303843271725
            ],
            [
              42.834418892231554,
              13.054150695298716
            ],
            [
              43.14379367306765,
              12.834868817846598
            ],
            [
              43.22816861329568,
              12.615395567393307
            ],
            [
              43.5094184134596,
              12.395734000022884
            ],
            [
              44.55004267627157,
              10.963556857789229
            ],
            [
              45.450042037511906,
              10.853089690745378
            ],
            [
              48.60003980602389,
              11.625479959569759
            ],
            [
              52.65003693696784,
              12.615395567393307
            ],
            [
              54.00003598061588,
              12.834868817846598
            ],
            [
              55.35003502307174,
              13.054150695298716
            ],
            [
              58.95003247279979,
              14.801154224791475
            ],
            [
              60.300031518831815,
              15.669513225155328
            ],
            [
              65.75491565746177,
              16.45525448044245
            ],
            [
              70.25491246962179,
              13.251828813229451
            ],
            [
              71.71468744134648,
              9.199470523025639
            ],
            [
              73.90979788338853,
              6.52510525956319
            ],
            [
              74.70002131714793,
              5.659359572411404
            ],
            [
              78.30001876747187,
              3.491423322320486
            ],
            [
              79.65001781052402,
              2.967259208499712
            ],
            [
              81.00001685476795,
              2.592701464601845
            ],
            [
              85.50001366692797,
              0.118642751260523
            ],
            [
              90.00001047908799,
              1.018588540518891
            ],
            [
              92.7000085663839,
              4.389285926050889
            ],
            [
              94.27500745064,
              5.286069860821101
            ],
            [
              95.40000665367998,
              5.957818681088611
            ],
            [
              97.42500521915215,
              5.510071711803135
            ],
            [
              97.87500490036805,
              5.286069860821101
            ],
            [
              98.66250434249608,
              4.613591578862867
            ],
            [
              99.90000346584,
              3.266814816815753
            ],
            [
              101.25000250948804,
              2.030661890474562
            ],
            [
              102.15000187192001,
              1.524657538529198
            ],
            [
              102.68279723997185,
              1.417686731666521
            ],
            [
              103.34065102845304,
              1.074949758433263
            ],
            [
              103.50000091556822,
              1.144999057563268
            ],
            [
              103.64609081207684,
              1.338585852071589
            ]
          ],
          [
            [
              32.28445136473579,
              31.259278146448963
            ],
            [
              32.00630156177954,
              30.83702058239723
            ],
            [
              31.950051601627564,
              30.077385962079745
            ],
            [
              32.17500143988693,
              29.63833609362638
            ],
            [
              32.287551362539546,
              29.082725788288855
            ],
            [
              33.08276564295244,
              28.365936333863488
            ]
          ],
          [
            [
              32.28445136473579,
              31.259278146448963
            ],
            [
              32.06255152193168,
              30.83702058239723
            ],
            [
              32.06255152193168,
              30.077385962079745
            ],
            [
              32.23124640063858,
              29.63833609362638
            ],
            [
              32.400051282843634,
              29.082725788288855
            ],
            [
              33.08276564295244,
              28.365936333863488
            ]
          ],
          [
            [
              5.372530429989041,
              43.29362778902916
            ],
            [
              5.287570490175341,
              41.744358789482135
            ],
            [
              5.850070091695329,
              38.651811712711236
            ],
            [
              6.750069455319476,
              37.94551049545976
            ],
            [
              9.00006786020731,
              37.41128363492314
            ],
            [
              10.348617230365335,
              37.41128363492314
            ],
            [
              11.137566345387313,
              37.232354321556215
            ],
            [
              11.812565868412491,
              35.419780517080454
            ],
            [
              14.400064035395388,
              33.565491482352144
            ],
            [
              16.65006244147537,
              32.62301664000799
            ],
            [
              19.35006052877148,
              33.00121852265437
            ],
            [
              22.05005861487558,
              33.17402209671795
            ],
            [
              25.20005638457954,
              32.43331330641712
            ],
            [
              27.90005447187562,
              31.766210259726904
            ],
            [
              31.050052239195594,
              31.79808736758517
            ],
            [
              32.06255152193168,
              31.510798430048965
            ],
            [
              32.28445136473579,
              31.259278146448963
            ]
          ],
          [
            [
              35.775048893751716,
              24.12261698700334
            ],
            [
              36.900048095003655,
              24.361968609765853
            ],
            [
              37.80004745803154,
              24.32780311165172
            ],
            [
              38.10697724059963,
              24.070648010417926
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "biznet-nusantara-cable-system-1-bncs-1",
        "name": "Biznet Nusantara Cable System-1 (BNCS-1)",
        "color": "#c46628",
        "feature_id": "biznet-nusantara-cable-system-1-bncs-1-0",
        "coordinates": [
          105.70661248093884,
          -5.950901469087938
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              105.88390920948316,
              -6.073698909871837
            ],
            [
              105.72187434097626,
              -5.973680124487458
            ],
            [
              105.58496037606338,
              -5.769333077764941
            ]
          ],
          [
            [
              105.1642597365915,
              -2.065142653434521
            ],
            [
              104.96249987952004,
              -2.243336428755356
            ],
            [
              104.87451166060183,
              -2.29534634931278
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "hokkaido-akita-cable",
        "name": "Hokkaido-Akita Cable",
        "color": "#c38c2b",
        "feature_id": "hokkaido-akita-cable-0",
        "coordinates": [
          138.82497589102448,
          42.4172401000869
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              140.3436271589475,
              39.692011436751784
            ],
            [
              139.4999754116566,
              40.04369219282995
            ],
            [
              139.2749755710487,
              40.387320290775165
            ],
            [
              138.82497589102448,
              42.079235618164205
            ],
            [
              138.82497589102448,
              42.74371346443661
            ],
            [
              139.0499757316325,
              43.07331078300331
            ],
            [
              140.39997477528036,
              43.64586280559482
            ],
            [
              140.84997445649643,
              43.64586280559482
            ],
            [
              141.3543759999998,
              43.062096
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "topaz",
        "name": "Topaz",
        "color": "#33499e",
        "feature_id": "topaz-0",
        "coordinates": [
          -152.30356639946407,
          49.58728674004678
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -124.80753734982574,
              49.23399525062973
            ],
            [
              -123.9748379397179,
              49.35578708015052
            ],
            [
              -123.114034,
              49.2604400000001
            ]
          ],
          [
            [
              142.19997350014447,
              36.87321951208918
            ],
            [
              142.42497334015658,
              36.14986678681771
            ],
            [
              142.19997350014447,
              35.05222991093683
            ],
            [
              140.84997445649643,
              33.93964008831958
            ],
            [
              138.59997605041642,
              33.18971466460036
            ],
            [
              137.69997668798445,
              33.565491482352144
            ],
            [
              136.87399727311598,
              34.33682825203164
            ]
          ],
          [
            [
              179.99994672169302,
              49.58728674004675
            ],
            [
              172.7999518228328,
              49.58728674004675
            ],
            [
              160.19996074878455,
              46.58235508209589
            ],
            [
              149.39996839960057,
              40.04369219282995
            ],
            [
              142.19997350014447,
              36.87321951208918
            ],
            [
              141.07497429650854,
              36.63285342448927
            ],
            [
              140.71827454919813,
              36.71305911738503
            ]
          ],
          [
            [
              -124.80753734982574,
              49.23399525062973
            ],
            [
              -124.8185887359924,
              49.098645310092905
            ],
            [
              -125.09983714275782,
              48.9511060207831
            ],
            [
              -125.5498368239738,
              48.803129141654495
            ],
            [
              -138.5998275786418,
              49.00033438946335
            ],
            [
              -151.19981865268997,
              49.58728674004675
            ],
            [
              -179.99979825051417,
              49.58728674004675
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "asia-connect-cable-1-acc-1",
        "name": "Asia Connect Cable-1 (ACC-1)",
        "color": "#939597",
        "feature_id": "asia-connect-cable-1-acc-1-0",
        "coordinates": [
          143.89186073707347,
          12.788950174521261
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              128.24998338125656,
              5.061986954416028
            ],
            [
              126.33748473549238,
              5.957818681088611
            ],
            [
              125.61287587560011,
              7.079988883160723
            ]
          ],
          [
            [
              107.77499788652412,
              -3.778666580061156
            ],
            [
              107.77499788712001,
              -4.601453764837203
            ],
            [
              107.32499820590417,
              -5.273944363641391
            ],
            [
              106.82782855810385,
              -6.171876390816408
            ]
          ],
          [
            [
              117.37991922838071,
              -5.945707155070551
            ],
            [
              118.79999007691222,
              -5.273944363641391
            ],
            [
              119.41238964308272,
              -5.152180217334632
            ]
          ],
          [
            [
              123.74998656969242,
              -6.914561059201674
            ],
            [
              125.32498545454442,
              -7.955717094334744
            ],
            [
              125.5807152727871,
              -8.570689999999786
            ]
          ],
          [
            [
              126.8999843382044,
              2.068137876964443
            ],
            [
              125.99998497636832,
              2.143087178471944
            ],
            [
              124.8749857727324,
              1.805788280129235
            ],
            [
              124.83963579837055,
              1.490779296094786
            ]
          ],
          [
            [
              104.3350575410582,
              1.091076758263085
            ],
            [
              104.11006048280015,
              1.091076758263085
            ],
            [
              104.01663700000026,
              1.066798000000432
            ]
          ],
          [
            [
              127.79998370063637,
              -4.676208028750978
            ],
            [
              123.74998656969242,
              -6.914561059201674
            ],
            [
              120.14998911996437,
              -6.914561059201674
            ],
            [
              117.37991922838071,
              -5.945707155070551
            ],
            [
              116.58310164737688,
              -5.525204085834929
            ],
            [
              114.46970572643434,
              -5.721872747834027
            ],
            [
              112.04999485867219,
              -5.049857167366866
            ],
            [
              109.34999676839627,
              -3.479268678969987
            ],
            [
              107.77499788652412,
              -3.778666580061156
            ],
            [
              106.93124848484004,
              -3.029995968008762
            ],
            [
              106.7644986029674,
              -2.130918480960247
            ],
            [
              106.4249988434722,
              -0.331409329660175
            ],
            [
              105.29999964043216,
              0.793562652607278
            ],
            [
              104.84999995921609,
              0.906050180868988
            ],
            [
              104.62500011860826,
              1.074774789350442
            ],
            [
              104.3350575410582,
              1.091076758263085
            ],
            [
              103.85310700000068,
              1.293877684611573
            ]
          ],
          [
            [
              179.99992672230294,
              19.952622905164304
            ],
            [
              151.1999671244645,
              16.10232559580288
            ],
            [
              146.24997063108842,
              14.256644994553568
            ],
            [
              145.34997126865645,
              13.601498202276503
            ],
            [
              144.89997158744055,
              13.382708036125592
            ],
            [
              144.77675167413477,
              13.490037504528004
            ],
            [
              143.9999722250084,
              12.834868817846598
            ],
            [
              137.24997700676838,
              9.96791518697421
            ],
            [
              133.19997987582443,
              8.190543417795567
            ],
            [
              128.24998338125656,
              5.061986954416028
            ],
            [
              126.8999843382044,
              2.068137876964443
            ],
            [
              126.4499846569885,
              0.118588418888407
            ],
            [
              126.4499846569885,
              -1.231315750217505
            ],
            [
              126.57449456878439,
              -1.751747198540869
            ],
            [
              127.23748409911636,
              -2.580536704984041
            ],
            [
              127.46248393972442,
              -3.254657364797595
            ],
            [
              127.79998370063637,
              -4.676208028750978
            ],
            [
              127.79998370063637,
              -6.467627592690604
            ],
            [
              127.46248393972442,
              -8.252720521975078
            ],
            [
              127.79998370063637,
              -9.586362493294047
            ],
            [
              128.92498290367635,
              -10.620064860363328
            ],
            [
              129.93758218693742,
              -12.218965051762638
            ],
            [
              130.50000678851072,
              -12.438888123083757
            ],
            [
              130.84314154543083,
              -12.46747433620365
            ]
          ],
          [
            [
              -179.99979825051417,
              19.952622905164304
            ],
            [
              -172.79980335105807,
              21.635297384859456
            ],
            [
              -163.79980972673806,
              21.635297384859456
            ],
            [
              -161.4405642356392,
              22.469372680574452
            ],
            [
              -152.99981737755388,
              26.964304734562802
            ],
            [
              -147.59982120296192,
              28.951554732193216
            ],
            [
              -138.5998275786418,
              30.514495959759188
            ],
            [
              -127.79983522945773,
              33.18971466460036
            ],
            [
              -122.39983905486565,
              33.37780603565923
            ],
            [
              -120.59984033000163,
              33.37780603565923
            ],
            [
              -118.79984160513769,
              33.89296086026752
            ],
            [
              -118.39945344493319,
              33.8624748689854
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "medusa-submarine-cable-system",
        "name": "Medusa Submarine Cable System",
        "color": "#939597",
        "feature_id": "medusa-submarine-cable-system-0",
        "coordinates": [
          10.833326758993252,
          37.63976885848092
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              20.060997550717985,
              32.1148467730242
            ],
            [
              19.800060208199653,
              32.43331330641712
            ],
            [
              19.687560287299476,
              32.81231878328768
            ],
            [
              19.35006052817539,
              35.23621340052833
            ]
          ],
          [
            [
              14.4000640347993,
              35.23621340052833
            ],
            [
              13.27506488411342,
              33.09551711711572
            ],
            [
              13.181510236711375,
              32.87557616202682
            ]
          ],
          [
            [
              31.050052239791512,
              33.00121852265437
            ],
            [
              31.500051920411693,
              33.87739543625136
            ],
            [
              32.46668800000049,
              34.76662920076811
            ]
          ],
          [
            [
              27.000055108251473,
              33.87739543625136
            ],
            [
              26.574796150245902,
              35.27186093831318
            ],
            [
              25.93362449116799,
              35.65397803725264
            ],
            [
              25.38628283097998,
              35.60313403310184
            ],
            [
              25.127860266004774,
              35.3383934315591
            ]
          ],
          [
            [
              9.000067859611391,
              37.94551049545976
            ],
            [
              9.562567461131408,
              37.58978657360316
            ],
            [
              9.867357245811377,
              37.27681625347506
            ]
          ],
          [
            [
              11.812565866615529,
              37.232354321556215
            ],
            [
              12.060515691561449,
              37.54682622128206
            ],
            [
              12.591375316091586,
              37.650586172786205
            ]
          ],
          [
            [
              8.10006849777534,
              38.21117903702327
            ],
            [
              6.975069294139445,
              37.44106375352243
            ],
            [
              6.557241714000355,
              37.00295935602452
            ]
          ],
          [
            [
              2.250072641967279,
              37.41128363492314
            ],
            [
              2.812572243487267,
              37.232354321556215
            ],
            [
              3.035712085412854,
              36.76212778210993
            ]
          ],
          [
            [
              4.950070728667271,
              41.18233034647952
            ],
            [
              2.70007232258726,
              41.18233034647952
            ],
            [
              2.168725042748747,
              41.38560270176822
            ]
          ],
          [
            [
              6.075069932303194,
              38.651811712711236
            ],
            [
              5.400070410479259,
              41.744358789482135
            ],
            [
              5.372530429989041,
              43.29362778902916
            ],
            [
              5.062570649567277,
              41.744358789482135
            ],
            [
              4.950070728667271,
              41.18233034647952
            ],
            [
              4.837570808959242,
              40.04369219282995
            ],
            [
              4.725070888059435,
              38.651811712711236
            ]
          ],
          [
            [
              -2.249924170192742,
              35.876870570092734
            ],
            [
              -2.474924011396666,
              35.450334489671604
            ],
            [
              -2.93303499999962,
              35.170020000000136
            ]
          ],
          [
            [
              -4.499922576272752,
              35.78566189952613
            ],
            [
              -4.724922416880787,
              35.60293032290622
            ],
            [
              -5.391811944449927,
              35.565918934421404
            ]
          ],
          [
            [
              -9.449919069648843,
              38.12273010839229
            ],
            [
              -9.112419308736776,
              37.94551049545976
            ],
            [
              -8.86959721512926,
              37.95721527519197
            ]
          ],
          [
            [
              -9.331559153496016,
              38.69016197235561
            ],
            [
              -9.449919069648843,
              38.12273010839229
            ],
            [
              -9.674918909064814,
              37.232354321556215
            ],
            [
              -9.562418989356786,
              36.69301553274448
            ],
            [
              -8.999919388432772,
              36.33133835588808
            ],
            [
              -5.843848196000124,
              36.13475690999988
            ],
            [
              -5.512421859604729,
              36.180141298407335
            ],
            [
              -5.279170071717544,
              36.29793294218615
            ],
            [
              -4.499922576272752,
              35.78566189952613
            ],
            [
              -2.249924170192742,
              35.876870570092734
            ],
            [
              2.250072641967279,
              37.41128363492314
            ],
            [
              4.725070888059435,
              38.651811712711236
            ],
            [
              6.075069932303194,
              38.651811712711236
            ],
            [
              8.10006849777534,
              38.21117903702327
            ],
            [
              9.000067859611391,
              37.94551049545976
            ],
            [
              10.348617229769246,
              37.94551049545976
            ],
            [
              10.912566505375338,
              37.58978657360316
            ],
            [
              11.812565866615529,
              37.232354321556215
            ],
            [
              12.993815030999286,
              35.96797434759347
            ],
            [
              14.4000640347993,
              35.23621340052833
            ],
            [
              16.65006244087931,
              34.867831005273246
            ],
            [
              19.35006052817539,
              35.23621340052833
            ],
            [
              22.05005861487558,
              34.85244570884623
            ],
            [
              23.400057658523423,
              34.52869067055079
            ],
            [
              25.20005638398345,
              34.12610104005762
            ],
            [
              27.000055108251473,
              33.87739543625136
            ],
            [
              31.050052239791512,
              33.00121852265437
            ],
            [
              31.950051601627564,
              31.79808736758517
            ],
            [
              32.23130140238757,
              31.510798430048965
            ],
            [
              32.28445136473579,
              31.259278146448963
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "saudi-vision",
        "name": "Saudi Vision",
        "color": "#c76a28",
        "feature_id": "saudi-vision-0",
        "coordinates": [
          36.69992999587873,
          25.543088498954887
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              37.800047457435625,
              23.848523186488023
            ],
            [
              37.968797337891715,
              23.917101290935022
            ],
            [
              38.107017239975306,
              24.07061699999977
            ]
          ],
          [
            [
              35.60629901210348,
              26.562513149236622
            ],
            [
              35.60629901150759,
              27.097918575216056
            ],
            [
              35.69652894758784,
              27.35401899999954
            ]
          ],
          [
            [
              39.182863,
              21.48154278085252
            ],
            [
              38.8125467407676,
              22.05298561667763
            ],
            [
              37.800047457435625,
              23.848523186488023
            ],
            [
              36.56254833468759,
              25.754704263415306
            ],
            [
              35.60629901210348,
              26.562513149236622
            ],
            [
              34.46137982377101,
              27.91695317820549
            ],
            [
              34.46137982377101,
              27.991483447904812
            ],
            [
              34.48950480265529,
              28.115586087217935
            ],
            [
              34.57387974288315,
              28.313851747133725
            ],
            [
              34.767629606224915,
              29.089044023022637
            ],
            [
              34.95220947487067,
              29.283852
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "scotland-northern-ireland-4",
        "name": "Scotland-Northern Ireland 4",
        "color": "#a4ab37",
        "feature_id": "scotland-northern-ireland-4-0",
        "coordinates": [
          -5.373545682142918,
          55.15334082372826
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -5.809831648320881,
              54.85780200253134
            ],
            [
              -5.624921779312757,
              55.04558670490414
            ],
            [
              -5.174922098096772,
              55.23848220955432
            ],
            [
              -4.854172325318928,
              55.240403607445785
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "scotland-northern-ireland-3",
        "name": "Scotland-Northern Ireland 3",
        "color": "#44b649",
        "feature_id": "scotland-northern-ireland-3-0",
        "coordinates": [
          -5.2959281963958915,
          54.68794520830264
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -5.538691840398826,
              54.6430377426982
            ],
            [
              -5.287422018400775,
              54.68951871778469
            ],
            [
              -5.174922098096772,
              54.75449236881684
            ],
            [
              -5.115502140190387,
              54.844025368730655
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "hawaiki-nui",
        "name": "Hawaiki Nui",
        "color": "#939597",
        "feature_id": "hawaiki-nui-0",
        "coordinates": [
          142.19485932093093,
          -9.586887491267305
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              104.14960045538581,
              1.184523360363414
            ],
            [
              104.01663700000026,
              1.066798000000432
            ]
          ],
          [
            [
              109.57499660960016,
              -3.479268678969987
            ],
            [
              108.22499756833614,
              -4.601453764837203
            ],
            [
              107.77499788712001,
              -5.273944363641391
            ],
            [
              106.82786855747966,
              -6.171587999999645
            ]
          ],
          [
            [
              129.37498258489242,
              -8.69780485225661
            ],
            [
              129.14998274428442,
              -10.620064860363328
            ],
            [
              129.93758218693742,
              -12.108990934944188
            ],
            [
              130.50000678851072,
              -12.329010708606262
            ],
            [
              130.84315500000045,
              -12.467503628742634
            ]
          ],
          [
            [
              171.89995245980478,
              -46.93367824881395
            ],
            [
              171.22495293798082,
              -46.3155908501611
            ],
            [
              170.50279200000026,
              -45.87876365838102
            ]
          ],
          [
            [
              166.0499566039969,
              -47.54471343076844
            ],
            [
              166.9499559664287,
              -46.93367824881395
            ],
            [
              167.84995532886083,
              -46.54820067396079
            ],
            [
              168.3475049763919,
              -46.41305600000035
            ]
          ],
          [
            [
              149.39996839960057,
              -38.642301250436645
            ],
            [
              154.79996457359667,
              -42.78983315675495
            ],
            [
              161.5499597918367,
              -46.00389974600219
            ],
            [
              166.0499566039969,
              -47.54471343076844
            ],
            [
              168.7499546912928,
              -47.8475899972257
            ],
            [
              171.89995245980478,
              -46.93367824881395
            ],
            [
              173.24995150345282,
              -44.4189453512997
            ],
            [
              172.63622193822457,
              -43.53205440000025
            ]
          ],
          [
            [
              151.2070371188603,
              -33.86969599999975
            ],
            [
              151.34879701843633,
              -34.826981990554685
            ],
            [
              150.27496777914686,
              -37.94245352569678
            ],
            [
              149.39996839960057,
              -38.642301250436645
            ],
            [
              147.59996967473646,
              -39.16757423638754
            ],
            [
              146.24997063108842,
              -39.341800653968136
            ],
            [
              145.34997126686855,
              -39.16757423638754
            ],
            [
              144.69235173392437,
              -38.52352352301156
            ],
            [
              144.69235173392437,
              -38.25898836030271
            ],
            [
              144.96715153925365,
              -37.81753200000034
            ]
          ],
          [
            [
              158.84996170513668,
              -25.5408960762594
            ],
            [
              157.94996234270454,
              -26.350174904573805
            ],
            [
              153.8999652117606,
              -27.35385293623142
            ],
            [
              153.02282700000043,
              -27.468884108165412
            ]
          ],
          [
            [
              163.7999581985126,
              -13.698987269610853
            ],
            [
              161.0999601112167,
              -18.880139975101287
            ],
            [
              158.84996170513668,
              -25.5408960762594
            ],
            [
              157.04996298027257,
              -28.74381028114999
            ],
            [
              154.79996457419256,
              -32.042386559187065
            ],
            [
              152.09996648689648,
              -33.6490453774241
            ],
            [
              151.2070371188603,
              -33.86969599999975
            ]
          ],
          [
            [
              -156.82481466848606,
              18.251816319028308
            ],
            [
              -156.82481466908214,
              19.104405475930548
            ],
            [
              -156.374814986674,
              19.74098736552503
            ],
            [
              -155.83123598289623,
              20.04029812487792
            ]
          ],
          [
            [
              -153.89981674058203,
              17.538106329024004
            ],
            [
              -156.82481466848606,
              18.251816319028308
            ],
            [
              -157.4998141897139,
              18.89166158430325
            ],
            [
              -158.06231379242618,
              20.796306105108954
            ],
            [
              -158.056894349398,
              21.335422205733266
            ]
          ],
          [
            [
              179.99994672169302,
              -6.020298561392025
            ],
            [
              163.7999581985126,
              -13.698987269610853
            ],
            [
              154.79996457419256,
              -15.874323281689755
            ],
            [
              152.09996648689648,
              -15.006817032918917
            ],
            [
              148.49996903716843,
              -12.383840433185668
            ],
            [
              144.89997158684466,
              -10.472598924989882
            ],
            [
              142.19997349954858,
              -9.586362493294047
            ],
            [
              138.59997605041642,
              -9.95592161622909
            ],
            [
              134.5499789188764,
              -9.586362493294047
            ],
            [
              131.39998115036443,
              -9.142360877005416
            ],
            [
              129.37498258489242,
              -8.69780485225661
            ],
            [
              128.24998338185245,
              -7.807134147543906
            ],
            [
              126.8999843382044,
              -7.361072141346966
            ],
            [
              123.74998656969242,
              -6.691145450676487
            ],
            [
              120.14998911996437,
              -6.691145450676487
            ],
            [
              117.37991922838071,
              -5.721872747834027
            ],
            [
              116.58310164737688,
              -5.301207834067725
            ],
            [
              114.53977753131775,
              -5.497950688314972
            ],
            [
              112.04999485867219,
              -4.825692499217524
            ],
            [
              109.57499660960016,
              -3.479268678969987
            ],
            [
              108.89999708777609,
              -3.029995968008762
            ],
            [
              106.08749908256002,
              -0.331409329660175
            ],
            [
              104.84999995921609,
              0.624825760007468
            ],
            [
              104.40000027800002,
              0.906050180868988
            ],
            [
              104.28750035769608,
              1.01853421661562
            ],
            [
              104.17785043477741,
              1.184523360363414
            ],
            [
              103.85310700000068,
              1.293877684611573
            ]
          ],
          [
            [
              -118.24535355799173,
              34.053396879397155
            ],
            [
              -118.79984160513769,
              33.84625607000376
            ],
            [
              -120.59984033000163,
              33.283811019051
            ],
            [
              -127.79983522945773,
              29.34456698948989
            ],
            [
              -138.5998275786418,
              24.12261698700334
            ],
            [
              -147.599821203558,
              19.670379638527862
            ],
            [
              -153.89981674058203,
              17.538106329024004
            ],
            [
              -158.39981355274205,
              12.761732180728359
            ],
            [
              -170.99980462619405,
              2.367912558705314
            ],
            [
              -179.99979458628485,
              -6.020298561392025
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "blue",
        "name": "Blue",
        "color": "#7c287d",
        "feature_id": "blue-0",
        "coordinates": [
          11.415894293844019,
          40.69245881608015
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              14.850063715419452,
              38.68109155517462
            ],
            [
              13.95006435298751,
              38.41714222345635
            ],
            [
              13.358654772543531,
              38.116121616583314
            ]
          ],
          [
            [
              9.000067859611391,
              44.051519228735145
            ],
            [
              8.938867903561913,
              44.41035752885395
            ]
          ],
          [
            [
              14.850063715419452,
              38.68109155517462
            ],
            [
              13.500064671771412,
              38.856519049872816
            ],
            [
              12.150065628123372,
              39.69832335493328
            ],
            [
              11.137566345387313,
              41.0693404382163
            ],
            [
              10.687566663575524,
              41.520132020893186
            ],
            [
              10.125067060863358,
              42.41235450073577
            ],
            [
              9.900067220851383,
              43.07331078300331
            ],
            [
              9.618817421283381,
              43.40114497315386
            ],
            [
              9.000067859611391,
              44.051519228735145
            ]
          ],
          [
            [
              35.00509500000021,
              29.602772333573512
            ],
            [
              34.76254960922759,
              31.030017262986235
            ],
            [
              34.76254960922759,
              31.510798430048965
            ],
            [
              34.769679604772676,
              32.04501185826473
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "blue",
        "name": "Blue",
        "color": "#939597",
        "feature_id": "blue-1",
        "coordinates": [
          24.40510989764323,
          34.74140390494779
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              21.375059093051448,
              34.435956905755745
            ],
            [
              21.600058933659483,
              33.6903890393485
            ],
            [
              22.61255821639557,
              33.00121852265437
            ],
            [
              22.63911819817619,
              32.76363502668917
            ]
          ],
          [
            [
              21.600058933659483,
              35.72479789980608
            ],
            [
              21.375059093051448,
              34.435956905755745
            ],
            [
              19.91256012909949,
              32.81231878328768
            ],
            [
              19.91256012909949,
              32.43331330641712
            ],
            [
              20.066760020458872,
              32.11691354443619
            ]
          ],
          [
            [
              31.050052239791512,
              33.18971466460036
            ],
            [
              31.500051920411693,
              33.970745364072826
            ],
            [
              32.46665123625965,
              34.76657169708608
            ]
          ],
          [
            [
              24.01216722549529,
              35.51204255863749
            ],
            [
              23.737557420031465,
              35.876870570092734
            ],
            [
              22.95005797790344,
              36.05897312258671
            ],
            [
              22.05005861487558,
              35.541926812580044
            ]
          ],
          [
            [
              10.687566663575524,
              41.520132020893186
            ],
            [
              9.900067222043361,
              41.18233034647952
            ],
            [
              9.613157425293053,
              41.00477368759772
            ]
          ],
          [
            [
              11.137566345387313,
              41.0693404382163
            ],
            [
              11.700065946907301,
              41.26694507168793
            ],
            [
              12.262565548427318,
              41.520132020893186
            ],
            [
              12.495760000000303,
              41.90311000000014
            ]
          ],
          [
            [
              8.775068019003356,
              43.5100310084091
            ],
            [
              9.337567620523373,
              43.40114497315386
            ],
            [
              9.450067542019468,
              43.07331078300331
            ],
            [
              9.562567461131408,
              42.90873242772045
            ],
            [
              9.45088100000018,
              42.68833887338428
            ]
          ],
          [
            [
              8.775068019003356,
              43.5100310084091
            ],
            [
              9.000067859611391,
              44.051519228735145
            ]
          ],
          [
            [
              34.769679604772676,
              32.04501185826473
            ],
            [
              33.75005032708759,
              32.43331330641712
            ],
            [
              31.050052239791512,
              33.18971466460036
            ],
            [
              25.20005638398345,
              34.544136272978676
            ],
            [
              23.400057658523423,
              34.99080971857586
            ],
            [
              22.05005861487558,
              35.541926812580044
            ],
            [
              21.600058933659483,
              35.72479789980608
            ],
            [
              18.00006148452735,
              37.05299936423356
            ],
            [
              15.75006307844734,
              37.67887792909195
            ],
            [
              15.525063237243387,
              38.06370455144145
            ],
            [
              15.637563157547504,
              38.24063815947207
            ],
            [
              15.30006339663555,
              38.50523297944315
            ],
            [
              14.850063715419452,
              38.68109155517462
            ]
          ],
          [
            [
              8.775068019003356,
              43.5100310084091
            ],
            [
              7.200069135343199,
              43.155434676482145
            ],
            [
              6.412569692619428,
              42.8262780103758
            ],
            [
              5.962570011403358,
              42.8262780103758
            ],
            [
              5.372530429989041,
              43.29362778902916
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "raman",
        "name": "Raman",
        "color": "#939597",
        "feature_id": "raman-0",
        "coordinates": [
          50.51187004053443,
          14.013272070190965
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              62.77502976551986,
              20.375041253465525
            ],
            [
              62.77502976551986,
              22.469443964829594
            ],
            [
              59.85003183761572,
              24.225251377403623
            ],
            [
              58.50003279396768,
              23.891388761253097
            ],
            [
              58.004427676309035,
              23.679602315303896
            ]
          ],
          [
            [
              55.350035025455725,
              15.886035719079104
            ],
            [
              54.78753542333982,
              16.53419619825962
            ],
            [
              54.14808587692781,
              17.095827186725558
            ]
          ],
          [
            [
              44.55004267627157,
              12.285833556268273
            ],
            [
              43.6500433138396,
              11.900871787261877
            ],
            [
              43.16166670000027,
              11.54917744167588
            ]
          ],
          [
            [
              35.6965489475738,
              27.354010300438773
            ],
            [
              35.100049370139544,
              27.497802509202273
            ],
            [
              34.48950480265529,
              27.91695317820549
            ],
            [
              34.47544731261357,
              27.991483447904812
            ],
            [
              34.51762978273118,
              28.115586087217935
            ],
            [
              34.630129702439206,
              28.313851747133725
            ],
            [
              34.82387956578097,
              29.089044023022637
            ],
            [
              35.00509500000021,
              29.581033231032706
            ]
          ],
          [
            [
              72.87590260996691,
              19.076074257285313
            ],
            [
              70.2000245055838,
              19.635064099942582
            ],
            [
              66.60002705585575,
              20.16397503197578
            ],
            [
              62.77502976551986,
              20.375041253465525
            ],
            [
              58.950032475183775,
              17.609605913225096
            ],
            [
              55.350035025455725,
              15.886035719079104
            ],
            [
              48.600039807215495,
              13.273238157547667
            ],
            [
              45.450042038703714,
              12.505588131780542
            ],
            [
              44.55004267627157,
              12.285833556268273
            ],
            [
              43.88195913315431,
              12.458770746872005
            ],
            [
              43.42504347323157,
              12.615395567393307
            ],
            [
              43.34066853300351,
              12.834868817846598
            ],
            [
              43.25629359277548,
              13.054150695298716
            ],
            [
              43.03129375216761,
              13.929303843271725
            ],
            [
              42.5250441107996,
              14.801154224791475
            ],
            [
              41.850044588975464,
              16.53419619825962
            ],
            [
              39.487546262591536,
              20.375041253465525
            ],
            [
              39.150046501083665,
              20.93646800014895
            ],
            [
              38.70004682046351,
              22.05298561667763
            ],
            [
              37.46254769711774,
              24.12261698700334
            ],
            [
              36.4500484143835,
              25.754704263415306
            ],
            [
              35.6965489475738,
              27.354010300438773
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "kaliningrad-cable",
        "name": "Kaliningrad Cable",
        "color": "#12b5d6",
        "feature_id": "kaliningrad-cable-0",
        "coordinates": [
          22.80125065308468,
          59.29901003504426
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              20.50804500000038,
              54.708935
            ],
            [
              20.250059890011443,
              55.16357807203099
            ],
            [
              20.250059890011443,
              56.178604166899504
            ],
            [
              20.475059730619478,
              57.571890279005004
            ],
            [
              21.375059093051448,
              58.641677771384906
            ],
            [
              22.275058456079336,
              59.16460804545571
            ],
            [
              23.175057818511476,
              59.394489266856795
            ],
            [
              23.85005734033541,
              59.56588346342974
            ],
            [
              24.4125569418554,
              59.6796637072089
            ],
            [
              25.31255630428737,
              59.736409384078485
            ],
            [
              27.45005479006346,
              59.622821769410336
            ],
            [
              28.669815,
              59.445881
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "leif-erikson",
        "name": "Leif Erikson",
        "color": "#939597",
        "feature_id": "leif-erikson-0",
        "coordinates": [
          -25.586688355893813,
          56.84963915992199
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              7.996258571315195,
              58.15106571642474
            ],
            [
              7.875068657167304,
              57.93205658695149
            ],
            [
              6.883089359298907,
              57.7959091408226
            ],
            [
              4.725070888655324,
              58.05131589106017
            ],
            [
              2.025072801359244,
              60.35428947498107
            ],
            [
              0.000074235291265,
              60.978877356942235
            ],
            [
              -2.249924170788745,
              60.978877356942235
            ],
            [
              -5.399921938704722,
              60.46539258705809
            ],
            [
              -7.649920344784732,
              60.01869762196867
            ],
            [
              -8.999919388432772,
              59.6796637072089
            ],
            [
              -12.599916838756798,
              59.06836550247485
            ],
            [
              -23.39990918734489,
              57.08606597586797
            ],
            [
              -39.59989771112103,
              55.33458061322915
            ],
            [
              -53.99988751062921,
              54.64614507279089
            ],
            [
              -56.24988591670922,
              54.51574605479345
            ],
            [
              -58.04988464157316,
              54.25369445417246
            ],
            [
              -59.3998836852212,
              53.72455893284996
            ],
            [
              -60.32608400000028,
              53.301683
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "deep-blue-one",
        "name": "Deep Blue One",
        "color": "#939597",
        "feature_id": "deep-blue-one-0",
        "coordinates": [
          -56.188607023028396,
          7.65528916916756
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -57.37488511915315,
              8.635699417327544
            ],
            [
              -57.48738503886118,
              8.190543417795567
            ],
            [
              -57.82488480036922,
              7.298762754459602
            ],
            [
              -58.15486456660838,
              6.804293299288759
            ]
          ],
          [
            [
              -55.455601660384545,
              7.42587221539884
            ],
            [
              -55.34988655427716,
              6.554242425496881
            ],
            [
              -55.173851000000326,
              5.82403
            ]
          ],
          [
            [
              -61.65081209004636,
              10.686261032786234
            ],
            [
              -61.42488225009727,
              10.963556857789229
            ],
            [
              -60.9748825688812,
              11.07005723775336
            ],
            [
              -60.74988272886924,
              11.05558134154964
            ]
          ],
          [
            [
              -60.735342739169454,
              11.182456999999374
            ],
            [
              -60.74988272886924,
              11.05558134154964
            ]
          ],
          [
            [
              -60.74988272886924,
              11.05558134154964
            ],
            [
              -60.299883047057264,
              10.853089690745378
            ],
            [
              -57.37488511915315,
              8.635699417327544
            ],
            [
              -56.47488575672118,
              7.744889052551343
            ],
            [
              -55.455601660384545,
              7.42587221539884
            ],
            [
              -53.99988751062921,
              7.55905892601092
            ],
            [
              -53.094585528943554,
              7.357410349749699
            ],
            [
              -52.42488862637313,
              5.957818681088611
            ],
            [
              -52.32092869942332,
              4.941547448310331
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "apricot",
        "name": "Apricot",
        "color": "#939597",
        "feature_id": "apricot-0",
        "coordinates": [
          127.7804576704203,
          9.761217779215883
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              104.17500043679624,
              1.018409236452044
            ],
            [
              104.11887547655553,
              1.018409236452044
            ],
            [
              104.01663700000026,
              1.066798000000432
            ]
          ],
          [
            [
              109.12499692898021,
              -3.029995968008762
            ],
            [
              107.88749780742413,
              -4.601453764837203
            ],
            [
              107.43749812620823,
              -5.273944363641391
            ],
            [
              107.12099835041957,
              -5.981154260263196
            ]
          ],
          [
            [
              126.57346403647031,
              5.40467665837852
            ],
            [
              126.33748473668439,
              5.659359572411404
            ],
            [
              126.11248489607638,
              5.957818681088611
            ],
            [
              125.61287587560011,
              7.079988883160723
            ]
          ],
          [
            [
              125.54998529455636,
              16.24638821747709
            ],
            [
              123.52498672908436,
              15.597287859114289
            ],
            [
              121.94998784542437,
              15.669513225155328
            ],
            [
              121.56018812156228,
              15.761539465842048
            ]
          ],
          [
            [
              127.3499840194203,
              19.246083087004678
            ],
            [
              125.99998497636832,
              21.32123529551194
            ],
            [
              124.14876628719324,
              23.757798070792347
            ],
            [
              122.84998720785634,
              24.63495976683049
            ],
            [
              121.80144795065141,
              24.863504112487874
            ]
          ],
          [
            [
              126.44998465639262,
              18.251816319028308
            ],
            [
              131.39998115096031,
              17.39502263470061
            ],
            [
              138.59997605041642,
              15.669513225155328
            ],
            [
              143.09997286257644,
              13.929303843271725
            ],
            [
              143.9999722250084,
              13.601498202276503
            ],
            [
              144.65753175859138,
              13.385305518498171
            ]
          ],
          [
            [
              103.64609081207684,
              1.338585852071589
            ],
            [
              103.83750067588412,
              1.182753739294157
            ],
            [
              104.02421554420968,
              1.202092036773706
            ],
            [
              104.14960045538581,
              1.184523360363414
            ],
            [
              104.17500043679624,
              1.018409236452044
            ],
            [
              104.28750035769608,
              0.962292662396749
            ],
            [
              104.40000027800002,
              0.849806826211477
            ],
            [
              104.84999995921609,
              0.68107206531252
            ],
            [
              106.19999900286413,
              -0.331409329660175
            ],
            [
              109.12499692898021,
              -3.029995968008762
            ],
            [
              109.79999645080429,
              -3.479268678969987
            ],
            [
              112.04999485867219,
              -4.601453764837203
            ],
            [
              114.60984933620139,
              -5.273944363641391
            ],
            [
              116.80810148738883,
              -4.179995582158739
            ],
            [
              117.7124908479029,
              -2.730375485267956
            ],
            [
              117.96440181128682,
              -1.515333651974942
            ],
            [
              118.76628430204576,
              -0.810555324740847
            ],
            [
              119.69998944053637,
              0.568578852526286
            ],
            [
              119.92498928054832,
              1.01853421661562
            ],
            [
              120.59998880177616,
              1.468426767332062
            ],
            [
              124.1999862509085,
              3.416559618323163
            ],
            [
              126.57346403647031,
              5.40467665837852
            ],
            [
              128.24998338185245,
              7.447522319872292
            ],
            [
              127.3499840194203,
              11.882475268284423
            ],
            [
              125.54998529455636,
              16.24638821747709
            ],
            [
              126.44998465639262,
              18.251816319028308
            ],
            [
              127.3499840194203,
              19.246083087004678
            ],
            [
              130.94998146974442,
              20.796306105108954
            ],
            [
              138.59997605041642,
              23.711258142484382
            ],
            [
              139.7249752528605,
              24.941363171753835
            ],
            [
              140.84997445649643,
              30.901396088515583
            ],
            [
              141.07497429710443,
              32.43331330641712
            ],
            [
              140.96247437680037,
              33.93964008831958
            ],
            [
              140.56872465573642,
              34.40502275071583
            ],
            [
              140.37184979460855,
              34.69072647741018
            ],
            [
              140.10466248388653,
              34.85244570884623
            ],
            [
              139.96097900000026,
              34.97407799999968
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "scylla",
        "name": "Scylla",
        "color": "#b82a3f",
        "feature_id": "scylla-0",
        "coordinates": [
          3.1697065756605127,
          52.623261418527164
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              1.729273010906667,
              52.46882263773057
            ],
            [
              2.250072641967279,
              52.623261418527164
            ],
            [
              3.825071526223184,
              52.623261418527164
            ],
            [
              4.275071207439254,
              52.55491468334145
            ],
            [
              4.613670967572091,
              52.45850170510121
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "polar-express",
        "name": "Polar Express",
        "color": "#939597",
        "feature_id": "polar-express-0",
        "coordinates": [
          123.47856543112633,
          76.30644611449145
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              131.9107473900959,
              43.15461540791463
            ],
            [
              132.2999805127964,
              42.356956853355285
            ],
            [
              133.19997987522854,
              41.85617959436382
            ],
            [
              134.5499789188764,
              41.744358789482135
            ],
            [
              137.9249765279964,
              43.07331078300331
            ],
            [
              140.84997445590054,
              45.59408578894727
            ],
            [
              141.9749736589405,
              45.75130568537162
            ],
            [
              142.64997318076465,
              45.90808394896206
            ],
            [
              142.64997318076465,
              46.375769707492964
            ],
            [
              142.7262426579845,
              46.94899298742823
            ],
            [
              142.87497302137248,
              46.375769707492964
            ],
            [
              143.09997286198055,
              45.90808394896206
            ],
            [
              143.99997222441252,
              45.75130568537162
            ],
            [
              148.49996903657254,
              46.375769707492964
            ],
            [
              153.44996552994863,
              48.35656994073389
            ],
            [
              158.8499617045408,
              50.835111137952765
            ],
            [
              159.2999613857567,
              52.23455229126495
            ],
            [
              158.65001067431294,
              53.016667896883945
            ],
            [
              159.74996106697276,
              52.509290959647046
            ],
            [
              161.99995947305277,
              52.782322857763916
            ],
            [
              168.2999550100769,
              53.8574734403048
            ],
            [
              174.02495095443587,
              55.90629872677903
            ],
            [
              179.97797408100845,
              60.528451203359
            ]
          ],
          [
            [
              -179.99979825111026,
              60.528451203359
            ],
            [
              -179.5278259292099,
              60.869535166329314
            ],
            [
              -178.62782656677786,
              62.781080493725966
            ],
            [
              -179.9778256104259,
              63.79239592397769
            ]
          ],
          [
            [
              179.99994672169302,
              63.79239592397769
            ],
            [
              178.6499476780448,
              64.38232115988146
            ],
            [
              177.5021457567835,
              64.72719091772652
            ],
            [
              178.6499476780448,
              64.4794309101757
            ],
            [
              179.99994672169302,
              64.1870673067454
            ]
          ],
          [
            [
              -179.99979825111026,
              64.1870673067454
            ],
            [
              -172.79980335165416,
              63.99042806051594
            ],
            [
              -170.09980526435817,
              64.76870273314239
            ],
            [
              -169.1998059019262,
              66.07850491576798
            ],
            [
              -170.54980494557398,
              67.66857826204097
            ],
            [
              -175.94980112016623,
              69.47590081058132
            ],
            [
              -179.99979672288995,
              70.17422909007658
            ]
          ],
          [
            [
              179.99994824991276,
              70.17422909007658
            ],
            [
              177.749948315613,
              70.55228024514142
            ],
            [
              171.89995245980478,
              70.70155250919242
            ],
            [
              170.09995373494084,
              70.25039973315279
            ],
            [
              170.30700000000033,
              69.70289999999989
            ],
            [
              169.64995405372477,
              70.25039973315279
            ],
            [
              159.74996106697276,
              72.13481545023674
            ],
            [
              148.9499687177886,
              72.81230997105112
            ],
            [
              141.7499738183325,
              73.07627580961613
            ],
            [
              134.99997860009248,
              72.81230997105112
            ],
            [
              131.39998115036443,
              71.99624816540918
            ],
            [
              128.8645,
              71.6375
            ],
            [
              130.49998178793246,
              72.27235077127645
            ],
            [
              130.94998146914853,
              74.09330000483297
            ],
            [
              123.74998656969242,
              76.27568108311115
            ],
            [
              112.94999422050827,
              77.49983814503042
            ],
            [
              104.8499999586202,
              77.883517178072
            ],
            [
              99.45000378402821,
              77.49983814503042
            ],
            [
              94.50000729065212,
              77.20425577257376
            ],
            [
              86.85001270998012,
              75.95170116422631
            ],
            [
              81.00001685417206,
              74.45913348599123
            ],
            [
              80.531,
              73.5077
            ],
            [
              78.75001844809205,
              73.71908229166698
            ],
            [
              71.55002354863595,
              73.9695009786144
            ],
            [
              65.70002769282789,
              72.81230997105112
            ],
            [
              63.00002960553181,
              70.55228024514122
            ],
            [
              61.66370000000029,
              69.7512
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "polar-express",
        "name": "Polar Express",
        "color": "#cf3a26",
        "feature_id": "polar-express-1",
        "coordinates": [
          48.42333327279073,
          69.78245285336132
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              61.66370000000029,
              69.7512
            ],
            [
              60.7500311994518,
              70.4018983878988
            ],
            [
              59.40003215580393,
              70.55228024514142
            ],
            [
              57.60003343093982,
              70.4018983878988
            ],
            [
              53.1000366187798,
              69.94402310253798
            ],
            [
              44.100042994459585,
              69.63309228121122
            ],
            [
              35.550049051355614,
              69.47590081058132
            ],
            [
              35.100049370139544,
              69.3175488665178
            ],
            [
              35.114219360101515,
              69.18399665130568
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "darwin-jakarta-singapore-cable-djsc",
        "name": "Darwin-Jakarta-Singapore Cable (DJSC)",
        "color": "#752222",
        "feature_id": "darwin-jakarta-singapore-cable-djsc-0",
        "coordinates": [
          114.16800775684536,
          -19.800107039490683
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              118.57724023471044,
              -20.31344226536234
            ],
            [
              117.97499050962776,
              -20.048661670363828
            ],
            [
              115.19999262658828,
              -19.800107039490683
            ],
            [
              111.59999517686023,
              -19.800107039490683
            ],
            [
              109.7999964519963,
              -20.433922197637315
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "zeus",
        "name": "Zeus",
        "color": "#2181bf",
        "feature_id": "zeus-0",
        "coordinates": [
          3.1322239085293404,
          52.486461320100375
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              1.729273010906667,
              52.46882263773057
            ],
            [
              2.250072641967279,
              52.486461320100375
            ],
            [
              3.825071526223184,
              52.486461320100375
            ],
            [
              4.524191030960452,
              52.3636663818082
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "india-asia-xpress-iax",
        "name": "India Asia Xpress (IAX)",
        "color": "#939597",
        "feature_id": "india-asia-xpress-iax-0",
        "coordinates": [
          83.1442779108975,
          1.9602618339176274
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              71.3250237086238,
              16.965102599435824
            ],
            [
              66.60002705585575,
              18.67864702215462
            ],
            [
              72.87590260996691,
              19.076074257285313
            ]
          ],
          [
            [
              75.37502083897186,
              5.659359572411404
            ],
            [
              74.25002163652775,
              4.613591578862867
            ],
            [
              73.5403521392646,
              4.212345781871875
            ]
          ],
          [
            [
              101.25000250948804,
              2.143087178471944
            ],
            [
              101.41875238934821,
              2.592701464601845
            ],
            [
              101.44360237174422,
              2.751228763607116
            ]
          ],
          [
            [
              98.10000474097606,
              5.286069860821101
            ],
            [
              99.00000410340803,
              5.957818681088611
            ],
            [
              100.0661133481664,
              6.613518860854185
            ]
          ],
          [
            [
              85.50001366692797,
              1.01853421661562
            ],
            [
              83.92501477969202,
              5.510071711803135
            ],
            [
              83.25001525965618,
              7.744876956882223
            ],
            [
              82.80001557844011,
              9.524411345019587
            ],
            [
              81.45001653598385,
              11.735650161405744
            ],
            [
              80.2429873910547,
              13.063853101883296
            ]
          ],
          [
            [
              79.65001781052402,
              3.191933974144717
            ],
            [
              80.43751725265204,
              5.435413643888296
            ],
            [
              80.53985718074958,
              5.940820740520053
            ]
          ],
          [
            [
              72.87590260996691,
              19.076074257285313
            ],
            [
              71.3250237086238,
              16.965102599435824
            ],
            [
              71.04513590397798,
              13.492128176464178
            ],
            [
              72.5049108757027,
              9.361978911834058
            ],
            [
              74.70002131774493,
              6.688675551202238
            ],
            [
              75.37502083897186,
              5.659359572411404
            ],
            [
              78.30001876747187,
              3.715978119297972
            ],
            [
              79.65001781052402,
              3.191933974144717
            ],
            [
              81.00001685476795,
              2.817450442654064
            ],
            [
              85.50001366692797,
              1.01853421661562
            ],
            [
              90.00001047908799,
              1.918228780215685
            ],
            [
              92.7000085663839,
              4.613591578862867
            ],
            [
              94.27500745064,
              5.510071711803135
            ],
            [
              95.40000665367998,
              6.069699469735895
            ],
            [
              97.42500521915215,
              5.622041180883144
            ],
            [
              98.10000474097606,
              5.286069860821101
            ],
            [
              98.88750418310408,
              4.613591578862867
            ],
            [
              100.01250338614412,
              3.266814816815753
            ],
            [
              101.25000250948804,
              2.143087178471944
            ],
            [
              102.15000187192001,
              1.805788280129235
            ],
            [
              102.68279723997185,
              1.558264177552559
            ],
            [
              103.34065102845304,
              1.383978004154756
            ],
            [
              103.50000091556822,
              1.327518855965934
            ],
            [
              103.64609081207684,
              1.338585852071589
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "india-europe-xpress-iex",
        "name": "India Europe Xpress (IEX)",
        "color": "#939597",
        "feature_id": "india-europe-xpress-iex-0",
        "coordinates": [
          48.499723234913176,
          12.151337376868213
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              25.20005638398345,
              34.40502275071583
            ],
            [
              24.975056542779498,
              34.867831005273246
            ],
            [
              24.7684,
              35.07162999999974
            ]
          ],
          [
            [
              35.521924071875446,
              26.562513149236622
            ],
            [
              35.21254929044366,
              27.497802509202273
            ],
            [
              35.22314928293443,
              28.05096910114824
            ]
          ],
          [
            [
              37.34051417738746,
              24.138084599448433
            ],
            [
              37.80004745803154,
              24.12261698700334
            ],
            [
              38.10697724059963,
              24.070648010417926
            ]
          ],
          [
            [
              44.55004267627157,
              11.515266158038685
            ],
            [
              43.6500433138396,
              11.620598816364094
            ],
            [
              43.147993669496344,
              11.594869371447714
            ]
          ],
          [
            [
              54.000035981807684,
              13.382708036125592
            ],
            [
              54.112535901515685,
              14.583511645118676
            ],
            [
              54.112535901515685,
              16.53419619825962
            ],
            [
              54.14808587692781,
              17.095827186725558
            ]
          ],
          [
            [
              66.60002705585575,
              18.67864702215462
            ],
            [
              71.3250237086238,
              16.965102599435824
            ],
            [
              72.87590260996691,
              19.076074257285313
            ]
          ],
          [
            [
              32.65318110412005,
              29.113614162980156
            ],
            [
              33.07505080526349,
              28.951554732193216
            ],
            [
              33.53928797639452,
              28.161052262220892
            ],
            [
              34.537549769215445,
              27.364667993860166
            ],
            [
              35.521924071875446,
              26.562513149236622
            ],
            [
              36.337548494079556,
              25.754704263415306
            ],
            [
              37.350047776813625,
              24.12261698700334
            ],
            [
              38.587546900159566,
              22.05298561667763
            ],
            [
              39.037546580779576,
              20.93646800014895
            ],
            [
              39.37504634228762,
              20.375041253465525
            ],
            [
              41.737544668671546,
              16.53419619825962
            ],
            [
              42.468794150647625,
              14.801154224791475
            ],
            [
              42.97504379201547,
              13.929303843271725
            ],
            [
              43.22816861269959,
              13.054150695298716
            ],
            [
              43.32660604296558,
              12.834868817846598
            ],
            [
              43.41098098319361,
              12.615395567393307
            ],
            [
              43.87504315444764,
              12.395734000022884
            ],
            [
              44.55004267627157,
              11.515266158038685
            ],
            [
              45.450042038703714,
              11.405009147532837
            ],
            [
              48.600039807215495,
              12.17588718550806
            ],
            [
              52.650036938159616,
              13.163718917913684
            ],
            [
              54.000035981807684,
              13.382708036125592
            ],
            [
              55.35003502426392,
              13.492128176464178
            ],
            [
              58.95003247399197,
              15.23578178303569
            ],
            [
              60.97503103946394,
              16.53419619825962
            ],
            [
              66.60002705585575,
              18.67864702215462
            ],
            [
              70.2000245055838,
              18.89166158430325
            ],
            [
              72.87590260996691,
              19.076074257285313
            ]
          ],
          [
            [
              29.672353215170432,
              31.047641997876354
            ],
            [
              29.98130299690345,
              30.85311851188075
            ],
            [
              30.375052717967577,
              30.78065656729467
            ],
            [
              31.050052239791512,
              30.41752891425901
            ],
            [
              31.725051761615447,
              30.174689758499085
            ],
            [
              32.17505144283152,
              29.589085940778546
            ],
            [
              32.65318110412005,
              29.113614162980156
            ]
          ],
          [
            [
              29.672353215170432,
              31.047641997876354
            ],
            [
              29.98130299690345,
              30.80481662242596
            ],
            [
              30.375052717967577,
              30.6839556754382
            ],
            [
              31.050052239791512,
              30.320465424761352
            ],
            [
              31.725051761615447,
              30.077385962079745
            ],
            [
              32.17505144283152,
              29.54020324258323
            ],
            [
              32.65318110412005,
              29.113614162980156
            ]
          ],
          [
            [
              8.483758225965857,
              44.305748230542434
            ],
            [
              9.225067700219455,
              44.051519228735145
            ],
            [
              9.562567461131408,
              43.40114497315386
            ],
            [
              9.675067381435326,
              43.07331078300331
            ],
            [
              9.900067221447472,
              42.41235450073577
            ],
            [
              10.462566823563378,
              41.520132020893186
            ],
            [
              11.475066106299465,
              39.69832335493328
            ],
            [
              11.812565867211418,
              37.85673997565843
            ],
            [
              13.668814552823221,
              35.96797434759347
            ],
            [
              14.4000640347993,
              35.648652461506344
            ],
            [
              16.65006244087931,
              35.419780517080454
            ],
            [
              19.35006052817539,
              35.78566189952613
            ],
            [
              22.05005861487558,
              35.26683686030964
            ],
            [
              23.400057658523423,
              34.80627255689055
            ],
            [
              25.20005638398345,
              34.40502275071583
            ],
            [
              27.900054471279333,
              32.52821504536482
            ],
            [
              29.672353215170432,
              31.047641997876354
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "firmina",
        "name": "Firmina",
        "color": "#939597",
        "feature_id": "firmina-0",
        "coordinates": [
          -34.33898550137792,
          1.422609150038653
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -41.849896117201126,
              -27.951747285219852
            ],
            [
              -45.22489372632114,
              -25.13418654706126
            ],
            [
              -46.41249288501473,
              -24.00886839636476
            ]
          ],
          [
            [
              -50.84988974152117,
              -35.95811819864912
            ],
            [
              -53.99988751003313,
              -35.409856394925356
            ],
            [
              -54.94999683756278,
              -34.96666536873374
            ]
          ],
          [
            [
              -78.8826698834326,
              33.693557908375226
            ],
            [
              -78.37904024020864,
              33.18971466460036
            ],
            [
              -77.39987093386137,
              32.369989921143485
            ],
            [
              -74.69987284596938,
              30.901396088515583
            ],
            [
              -73.34987380232134,
              30.320465424761352
            ],
            [
              -69.2998766713773,
              28.359233526108653
            ],
            [
              -63.449880815569244,
              25.754704263415306
            ],
            [
              -48.59989133544116,
              16.53419619825962
            ],
            [
              -38.69989834868906,
              7.744889052551343
            ],
            [
              -33.749901855313055,
              0.568578852526286
            ],
            [
              -30.48740414473096,
              -3.816084200032321
            ],
            [
              -27.89990599950599,
              -9.290424301035614
            ],
            [
              -28.349731034615672,
              -13.697820288632613
            ],
            [
              -30.149729759479698,
              -18.025284192896805
            ],
            [
              -36.33740002230502,
              -24.41918455361531
            ],
            [
              -39.599897711717006,
              -25.946230718414654
            ],
            [
              -41.849896117201126,
              -27.951747285219852
            ],
            [
              -44.09989452328114,
              -32.61276000573585
            ],
            [
              -50.84988974152117,
              -35.95811819864912
            ],
            [
              -53.99988751003313,
              -36.683250670190546
            ],
            [
              -56.69544560047457,
              -36.47095527632115
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "ionian",
        "name": "Ionian",
        "color": "#be432b",
        "feature_id": "ionian-0",
        "coordinates": [
          18.93871075691635,
          38.981265934904116
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              17.1271521023086,
              39.08066915816414
            ],
            [
              17.775061643323397,
              39.03151487972863
            ],
            [
              19.80006020879557,
              38.94407095870682
            ],
            [
              20.751719534631093,
              38.95926674743771
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "sape-labuan-bajo-ende-kupang-cable-systems",
        "name": "Sape-Labuan Bajo-Ende-Kupang Cable Systems",
        "color": "#2982bd",
        "feature_id": "sape-labuan-bajo-ende-kupang-cable-systems-0",
        "coordinates": [
          122.54750304427111,
          -9.609222459948285
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              118.97619995148753,
              -8.505601593938906
            ],
            [
              119.2499897575324,
              -8.197049140471307
            ],
            [
              119.58748951844436,
              -8.141369965795263
            ],
            [
              119.86873931920437,
              -8.197049140471307
            ],
            [
              119.90755929170408,
              -8.475554575636494
            ]
          ],
          [
            [
              121.64273806248687,
              -8.845694469061982
            ],
            [
              121.72498800422042,
              -9.06830600387434
            ],
            [
              122.84998720726028,
              -9.808147286237755
            ],
            [
              123.29998688907224,
              -9.900514027809777
            ],
            [
              123.58338668830928,
              -10.182939736570972
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "kupang-alor-cable-systems",
        "name": "Kupang-Alor Cable Systems",
        "color": "#8fc73e",
        "feature_id": "kupang-alor-cable-systems-0",
        "coordinates": [
          123.6265333733646,
          -9.092224759625765
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              123.58333668774881,
              -10.183333435365995
            ],
            [
              123.46873676893242,
              -9.697273227790134
            ],
            [
              123.52498672908436,
              -9.142360877005416
            ],
            [
              123.74998656969242,
              -9.031272829743102
            ],
            [
              124.08748633060438,
              -8.920150538055964
            ],
            [
              124.1999862509085,
              -8.69780485225661
            ],
            [
              124.40375610655602,
              -8.320653715924948
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "denpasar-waingapu-cable-systems",
        "name": "Denpasar-Waingapu Cable Systems",
        "color": "#73469b",
        "feature_id": "denpasar-waingapu-cable-systems-0",
        "coordinates": [
          116.69216573688439,
          -9.364432611249072
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              118.08222058479092,
              -8.84114915636195
            ],
            [
              118.01249063418831,
              -9.142360877005416
            ],
            [
              117.6749908732763,
              -9.364432611249072
            ],
            [
              117.22499119206023,
              -9.364432611249072
            ],
            [
              116.54999167023632,
              -9.364432611249072
            ],
            [
              116.09999198902025,
              -9.253414272929234
            ],
            [
              115.64999230780435,
              -9.142360877005416
            ],
            [
              115.42499246719629,
              -8.920150538055964
            ],
            [
              115.26057258367305,
              -8.657096255417912
            ]
          ],
          [
            [
              115.64999230780435,
              -9.142360877005416
            ],
            [
              115.7062422679563,
              -8.864576677888891
            ],
            [
              115.8749921490083,
              -8.679270038068381
            ],
            [
              116.07252200848032,
              -8.561426607445838
            ]
          ],
          [
            [
              118.08222058479092,
              -8.84114915636195
            ],
            [
              118.23749047479632,
              -9.142360877005416
            ],
            [
              118.68749015601239,
              -9.253414272929234
            ],
            [
              119.2499897581283,
              -9.179382545871192
            ],
            [
              120.03748919966026,
              -9.179382545871192
            ],
            [
              120.26248904026832,
              -9.364432611249072
            ],
            [
              120.25301904757285,
              -9.645765890160561
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "bifrost",
        "name": "Bifrost",
        "color": "#939597",
        "feature_id": "bifrost-0",
        "coordinates": [
          -152.38305336538835,
          37.366299635910025
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -123.97340935607646,
              45.14659036063941
            ],
            [
              -125.09983714216165,
              44.53466416326725
            ],
            [
              -129.59983395432175,
              43.07331078300331
            ],
            [
              -138.59982757864196,
              40.387320290775165
            ],
            [
              -151.19981865328594,
              37.70855130533482
            ],
            [
              -163.79980972733412,
              34.06399293012595
            ],
            [
              -172.7998097902887,
              30.25570294203996
            ],
            [
              -179.999800397123,
              27.097918575216056
            ]
          ],
          [
            [
              -122.84983873608172,
              35.05222991093683
            ],
            [
              -121.94983937484164,
              34.31215165223537
            ],
            [
              -120.59984033000163,
              33.565491482352144
            ],
            [
              -117.44984256148982,
              32.33831157801282
            ],
            [
              -117.06378442547152,
              32.35759867460288
            ]
          ],
          [
            [
              109.34999677018433,
              -3.029995968008762
            ],
            [
              107.99999772772824,
              -4.601453764837203
            ],
            [
              107.54999804651217,
              -5.273944363641391
            ],
            [
              106.82782855810385,
              -6.171876390816408
            ]
          ],
          [
            [
              126.4499846569885,
              5.659359572411404
            ],
            [
              126.22498481578438,
              5.957818681088611
            ],
            [
              125.61287587560011,
              7.079988883160723
            ]
          ],
          [
            [
              124.1999862509085,
              3.865649782481938
            ],
            [
              124.64998593331634,
              1.805788280129235
            ],
            [
              124.83963579837055,
              1.490779296094786
            ]
          ],
          [
            [
              179.99994672169302,
              27.097918575216056
            ],
            [
              173.6999511846689,
              25.484199086872366
            ],
            [
              160.19996074818866,
              22.191942630775543
            ],
            [
              151.1999671244645,
              18.67864702215462
            ],
            [
              145.79997094927663,
              16.24638821747709
            ],
            [
              144.6749717462366,
              14.365653759228536
            ],
            [
              144.56247182593248,
              13.929303843271725
            ],
            [
              144.77675167413477,
              13.490037504528004
            ]
          ],
          [
            [
              144.56247182593248,
              13.929303843271725
            ],
            [
              143.9999722250084,
              13.273238157547667
            ],
            [
              137.24997700676838,
              11.735650161405744
            ],
            [
              133.19997987582443,
              9.96791518697421
            ],
            [
              129.59998242550031,
              7.893494252945061
            ],
            [
              126.4499846569885,
              5.659359572411404
            ],
            [
              124.1999862509085,
              3.865649782481938
            ],
            [
              120.59998880177616,
              2.367912558705314
            ],
            [
              119.47498959814024,
              1.01853421661562
            ],
            [
              119.24998975693651,
              0.568578852526286
            ],
            [
              118.3162923516883,
              -0.810555324740847
            ],
            [
              117.85190631946367,
              -1.290401396141533
            ],
            [
              117.73941082764068,
              -1.515333651974942
            ],
            [
              117.48749100610289,
              -2.730375485267956
            ],
            [
              116.58310164737688,
              -4.179995582158739
            ],
            [
              114.67992114108486,
              -5.049857167366866
            ],
            [
              112.04999485867219,
              -4.377144375531941
            ],
            [
              110.02499629200824,
              -3.479268678969987
            ],
            [
              109.34999677018433,
              -3.029995968008762
            ],
            [
              106.31249892316825,
              -0.331409329660175
            ],
            [
              105.63749940134412,
              0.118588418888407
            ],
            [
              104.84999995921609,
              0.568578852526286
            ],
            [
              104.17500043739219,
              0.685071778220589
            ],
            [
              103.89375063663218,
              0.793562652607278
            ],
            [
              103.89375063663218,
              1.01853421661562
            ],
            [
              103.64609081207684,
              1.338585852071589
            ]
          ],
          [
            [
              -120.62152181466485,
              35.12094936772425
            ],
            [
              -122.84983873608172,
              35.05222991093683
            ],
            [
              -129.59983395432175,
              38.29952060596935
            ],
            [
              -138.59982757864196,
              40.387320290775165
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "echo",
        "name": "Echo",
        "color": "#939597",
        "feature_id": "echo-0",
        "coordinates": [
          139.07700091618725,
          9.346665749207517
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              144.65753175859138,
              13.385305518498171
            ],
            [
              143.9999722250084,
              13.382708036125592
            ],
            [
              143.54997254319645,
              13.419186961310118
            ]
          ],
          [
            [
              143.88747230410857,
              13.965698203819443
            ],
            [
              144.22497206442463,
              13.710817738179543
            ],
            [
              144.69470173285575,
              13.464772962370034
            ]
          ],
          [
            [
              135.44997828130855,
              6.554242425496881
            ],
            [
              134.7749787594844,
              7.001096155427831
            ],
            [
              134.5609408257699,
              7.531746239289589
            ]
          ],
          [
            [
              109.57499661138823,
              -3.029995968008762
            ],
            [
              108.11249764803219,
              -4.601453764837203
            ],
            [
              107.66249796681612,
              -5.273944363641391
            ],
            [
              107.12099835041957,
              -5.981154260263196
            ]
          ],
          [
            [
              179.99994672169302,
              26.293865839752385
            ],
            [
              173.6999511846689,
              24.669041914124136
            ],
            [
              160.19996074818866,
              21.3561644823302
            ],
            [
              151.1999671244645,
              17.823934412537824
            ],
            [
              145.79997094927663,
              15.813887260619538
            ],
            [
              143.88747230410857,
              13.965698203819443
            ],
            [
              143.54997254319645,
              13.419186961310118
            ],
            [
              139.4999754122525,
              9.672311317776519
            ],
            [
              135.44997828130855,
              6.554242425496881
            ],
            [
              131.39998115036443,
              3.865649782481938
            ],
            [
              129.59998242550031,
              1.618372199773091
            ],
            [
              128.69998306306852,
              -1.981015190984778
            ],
            [
              128.02498354124438,
              -2.880195580251495
            ],
            [
              127.3499840194203,
              -4.676208028750978
            ],
            [
              123.74998656969242,
              -6.467627592690604
            ],
            [
              120.14998911996437,
              -6.467627592690604
            ],
            [
              116.58310164737688,
              -4.852974874840811
            ],
            [
              114.74999294596827,
              -4.825692499217524
            ],
            [
              112.04999485867219,
              -4.15276774801373
            ],
            [
              110.24999613321236,
              -3.479268678969987
            ],
            [
              109.57499661138823,
              -3.029995968008762
            ],
            [
              106.53749876377609,
              -0.331409329660175
            ],
            [
              105.29999964043216,
              0.906050180868988
            ],
            [
              104.84999995921609,
              1.187252773694183
            ],
            [
              104.62500011860826,
              1.250557147797139
            ],
            [
              104.28790035741284,
              1.29982615632926
            ],
            [
              104.18975042694322,
              1.310150970358706
            ],
            [
              103.9870105705659,
              1.389451396800126
            ]
          ],
          [
            [
              -124.1595592195036,
              40.803253108521204
            ],
            [
              -139.0501810250949,
              39.00237890905848
            ],
            [
              -151.19981865328594,
              36.993119919861954
            ],
            [
              -163.79980972733412,
              33.315153958129144
            ],
            [
              -172.7998097902887,
              29.475236194966442
            ],
            [
              -179.999800397123,
              26.293865839752385
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "jscfs",
        "name": "JSCFS",
        "color": "#939597",
        "feature_id": "jscfs-0",
        "coordinates": [
          -77.84875084100733,
          17.98210713046697
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -77.92138056441907,
              18.469357593227244
            ],
            [
              -78.1873703759894,
              18.55426456504901
            ],
            [
              -78.35612025644538,
              18.447578822532193
            ],
            [
              -78.34826026201351,
              18.278671433780033
            ],
            [
              -78.29987029629342,
              18.073658723981566
            ],
            [
              -78.07487045568539,
              17.966677204124778
            ],
            [
              -77.8483706161399,
              18.023694555394542
            ],
            [
              -77.84987061507735,
              17.85963086953788
            ],
            [
              -77.39987093326538,
              17.716802179008738
            ],
            [
              -76.94987125204939,
              17.770376320835908
            ],
            [
              -76.66665145328088,
              17.949944181323474
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "confluence-1",
        "name": "Confluence-1",
        "color": "#939597",
        "feature_id": "confluence-1-0",
        "coordinates": [
          -76.76886657310834,
          33.08826394443952
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -74.69987284596938,
              36.90321229501777
            ],
            [
              -76.05919805488558,
              36.755008440642456
            ]
          ],
          [
            [
              -79.1998696581294,
              29.931250704427015
            ],
            [
              -80.54986870177734,
              30.223305674181546
            ],
            [
              -81.65572729397282,
              30.332031818467613
            ]
          ],
          [
            [
              -77.39987093386137,
              32.55982671166805
            ],
            [
              -78.26653698657401,
              33.18971466460036
            ],
            [
              -78.8826698834326,
              33.693557908375226
            ]
          ],
          [
            [
              -74.06287329782184,
              40.152905263922435
            ],
            [
              -73.93747338606026,
              40.02845597599401
            ],
            [
              -74.02487332354937,
              39.00237890905848
            ],
            [
              -74.69987284596938,
              36.90321229501777
            ],
            [
              -74.9248726871734,
              35.541926812580044
            ],
            [
              -76.04987189021341,
              33.6903890393485
            ],
            [
              -77.39987093386137,
              32.55982671166805
            ],
            [
              -79.1998696581294,
              29.931250704427015
            ],
            [
              -79.64986933934539,
              26.562513149236622
            ],
            [
              -80.08893152830953,
              26.350585697437936
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "kafos",
        "name": "KAFOS",
        "color": "#bc74b0",
        "feature_id": "kafos-0",
        "coordinates": [
          28.059768458575576,
          41.8784657502913
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              28.9882337004031,
              41.04061756347724
            ],
            [
              29.02505367372345,
              41.43584595024919
            ],
            [
              28.350054151899513,
              41.85617959436382
            ],
            [
              27.985354410256235,
              41.884178753453995
            ],
            [
              28.237554231595595,
              42.19047067556437
            ],
            [
              28.237554231595595,
              42.85377505385463
            ],
            [
              28.350054151899513,
              43.18278481586091
            ]
          ],
          [
            [
              27.91285446161595,
              43.20837344983079
            ],
            [
              28.350054151899513,
              43.18278481586091
            ],
            [
              28.800053833115584,
              43.42838486264793
            ],
            [
              28.800053833115584,
              43.67299239292208
            ],
            [
              28.582753987052882,
              43.81718785722393
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "caribbean-express-cx",
        "name": "Caribbean Express (CX)",
        "color": "#939597",
        "feature_id": "caribbean-express-cx-0",
        "coordinates": [
          -84.21598227906084,
          19.003815370285395
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -84.37486599211334,
              19.104405475930548
            ],
            [
              -85.27486535514149,
              18.251816319028308
            ],
            [
              -87.18736400030944,
              16.53419619825962
            ],
            [
              -87.94615578765044,
              15.844981598742493
            ]
          ],
          [
            [
              -84.82486567332933,
              21.635297384859456
            ],
            [
              -86.17486471697737,
              21.111485983488905
            ],
            [
              -86.51236447788934,
              21.05900217332902
            ],
            [
              -86.76758665233308,
              21.095728792367282
            ]
          ],
          [
            [
              -79.53736941904137,
              9.96791518697421
            ],
            [
              -76.04987188961734,
              10.509154616655167
            ],
            [
              -75.50573165009138,
              10.386791448721794
            ]
          ],
          [
            [
              -80.08893152830953,
              26.350585697437936
            ],
            [
              -79.53736941904137,
              25.95717997876443
            ],
            [
              -79.42486949873734,
              25.348717422116806
            ],
            [
              -79.42486949873734,
              24.73717827217618
            ],
            [
              -80.99986838299351,
              23.298598065875808
            ],
            [
              -83.24986678907334,
              23.298598065875808
            ],
            [
              -84.82486567332933,
              22.261369678340685
            ],
            [
              -84.82486567332933,
              21.635297384859456
            ],
            [
              -85.04986551393736,
              20.375041253465525
            ],
            [
              -84.37486599211334,
              19.104405475930548
            ],
            [
              -81.67486790481735,
              17.39502263470061
            ],
            [
              -80.3248688611693,
              16.10232559580288
            ],
            [
              -80.09986902115736,
              13.929303843271725
            ],
            [
              -79.42486949933343,
              11.95585820711483
            ],
            [
              -79.53736941904137,
              9.96791518697421
            ],
            [
              -79.75347926594715,
              9.43772198486991
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "b2js-jakarta-bangka-batam-singapore-cable-system",
        "name": "B2JS (Jakarta-Bangka-Batam-Singapore) Cable System",
        "color": "#31b34a",
        "feature_id": "b2js-jakarta-bangka-batam-singapore-cable-system-0",
        "coordinates": [
          105.87849184990611,
          -1.9300287838029533
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              106.82782855810385,
              -6.171876390816408
            ],
            [
              106.53749876377609,
              -5.273944363641391
            ],
            [
              106.76249860438415,
              -4.601453764837203
            ],
            [
              106.76249860378826,
              -3.479268678969987
            ],
            [
              106.54745875672049,
              -3.079924874677914
            ],
            [
              106.31249892257236,
              -2.580536704984041
            ],
            [
              105.86249924135629,
              -1.906058394384871
            ],
            [
              105.58270944015786,
              -1.55387979369155
            ],
            [
              105.52499948104,
              -1.231315750217505
            ],
            [
              105.18749972012822,
              0.118588418888407
            ],
            [
              104.84999995921609,
              0.345586247302265
            ],
            [
              104.17500043739219,
              0.456083447840484
            ],
            [
              103.72500075617612,
              0.793562652607278
            ],
            [
              103.72500075617612,
              1.01853421661562
            ],
            [
              103.87813064769747,
              1.134723598626692
            ],
            [
              103.96260058785819,
              1.134723598626692
            ],
            [
              103.92187561670804,
              1.185378176915862
            ],
            [
              103.94648059927786,
              1.327258925921086
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "maroc-telecom-west-africa",
        "name": "Maroc Telecom West Africa",
        "color": "#b27032",
        "feature_id": "maroc-telecom-west-africa-0",
        "coordinates": [
          -16.675451618935483,
          8.694340537828282
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -16.199914288484848,
              23.848523186488023
            ],
            [
              -16.087414368180845,
              23.745587983103547
            ],
            [
              -15.934424476560338,
              23.721081977553723
            ]
          ],
          [
            [
              -3.7124231341447,
              3.266814816815753
            ],
            [
              -4.026242911831901,
              5.323508791824736
            ]
          ],
          [
            [
              1.350073278939419,
              4.164912849976844
            ],
            [
              1.350073279535309,
              5.061986954416028
            ],
            [
              1.2278033661525,
              6.126307297218631
            ]
          ],
          [
            [
              2.250072641967279,
              4.052702097268195
            ],
            [
              2.250072641967279,
              5.061986954416028
            ],
            [
              2.440112507341183,
              6.356673335458169
            ]
          ],
          [
            [
              -7.631920358132049,
              33.60539511325592
            ],
            [
              -8.999919389028747,
              33.315153958129144
            ],
            [
              -12.149917156944838,
              30.126049846722907
            ],
            [
              -13.274916359984843,
              28.161052262220892
            ],
            [
              -14.399915563024848,
              26.964304734562802
            ],
            [
              -16.199914288484848,
              23.848523186488023
            ],
            [
              -16.64991396910486,
              22.884654113882362
            ],
            [
              -17.999913012752813,
              19.952622905164304
            ],
            [
              -17.999913013348873,
              16.53419619825962
            ],
            [
              -17.999913012752813,
              11.735650161405744
            ],
            [
              -16.64991396910486,
              8.635699417327544
            ],
            [
              -15.299914926648881,
              7.744889052551343
            ],
            [
              -14.399915564216826,
              6.852191098754417
            ],
            [
              -12.149917156944838,
              5.061986954416028
            ],
            [
              -10.799918113296798,
              3.715978119297972
            ],
            [
              -6.299921301732752,
              3.715978119297972
            ],
            [
              -3.7124231341447,
              3.266814816815753
            ],
            [
              -0.899925126544701,
              3.279837005485092
            ],
            [
              1.350073278939419,
              4.164912849976844
            ],
            [
              2.250072641967279,
              4.052702097268195
            ],
            [
              5.400070410479259,
              1.918228780215685
            ],
            [
              7.200069135343199,
              0.793562652607278
            ],
            [
              8.55006817899124,
              0.568578852526286
            ],
            [
              9.454267538448192,
              0.394465191855375
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "galapagos-cable-system",
        "name": "Galapagos Cable System",
        "color": "#939597",
        "feature_id": "galapagos-cable-system-0",
        "coordinates": [
          -85.17329128464077,
          -1.0813464460989053
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -89.61436228100118,
              -0.909172611722227
            ],
            [
              -89.88736208760552,
              -0.968864549257972
            ],
            [
              -90.11236192821347,
              -0.912622173290856
            ],
            [
              -90.31130405300462,
              -0.742410110898021
            ],
            [
              -90.44986168912544,
              -0.912622173290856
            ],
            [
              -90.67486152973348,
              -0.968864549257972
            ],
            [
              -90.96211132624296,
              -0.961035665226774
            ]
          ],
          [
            [
              -80.71613109211359,
              -0.949727245539691
            ],
            [
              -81.4498680648054,
              -1.081346446098905
            ],
            [
              -89.09986264547749,
              -1.081346446098905
            ],
            [
              -89.61436228100118,
              -0.909172611722227
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "africa-1",
        "name": "Africa-1",
        "color": "#939597",
        "feature_id": "africa-1-0",
        "coordinates": [
          49.23087858642239,
          11.889778321852418
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              7.425068975355316,
              37.94551049545976
            ],
            [
              5.625070250491405,
              37.35168786972491
            ],
            [
              5.055680653852221,
              36.75152814511773
            ]
          ],
          [
            [
              32.28445136473579,
              31.259278146448963
            ],
            [
              32.118801482083654,
              30.83702058239723
            ],
            [
              32.17505144283152,
              30.077385962079745
            ],
            [
              32.28748136258923,
              29.63833609362638
            ],
            [
              32.45645624288596,
              29.34456698948989
            ],
            [
              32.878270945260255,
              28.951554732193216
            ],
            [
              33.08276564295244,
              28.365936333863488
            ]
          ],
          [
            [
              35.6965489475738,
              27.354010300438773
            ],
            [
              35.100049370139544,
              27.097918575216056
            ],
            [
              34.50942478913956,
              26.562513149236622
            ]
          ],
          [
            [
              42.89066885178764,
              13.054150695298716
            ],
            [
              43.1786936471521,
              13.1445250990119
            ],
            [
              43.24734359852002,
              13.320340603999938
            ]
          ],
          [
            [
              60.97503104005983,
              16.749771315644608
            ],
            [
              60.97503104005983,
              22.469443964829594
            ],
            [
              59.85003183701983,
              23.401884131852626
            ],
            [
              58.50003279396768,
              24.04558710925602
            ],
            [
              56.92503390911571,
              24.711631506331287
            ],
            [
              56.339934323605746,
              25.0514826710928
            ]
          ],
          [
            [
              32.28445136473579,
              31.259278146448963
            ],
            [
              32.118801482083654,
              31.510798430048965
            ],
            [
              31.27505207980363,
              31.79808736758517
            ],
            [
              27.900054470683443,
              31.957307911004875
            ],
            [
              25.200056383387533,
              32.717717936758305
            ],
            [
              22.05005861487558,
              33.45605770170522
            ],
            [
              19.350060527579473,
              33.37780603565923
            ],
            [
              16.650062440283392,
              33.00121852265437
            ],
            [
              14.400064034203382,
              33.84625607000376
            ],
            [
              12.150065628130307,
              35.419780517080454
            ],
            [
              11.925065787515337,
              35.78566189952613
            ],
            [
              11.700065946907301,
              36.240655233214795
            ],
            [
              11.36256618480337,
              37.232354321556215
            ],
            [
              10.348617229173357,
              37.58978657360316
            ],
            [
              9.000067859015502,
              37.58978657360316
            ],
            [
              7.425068975355316,
              37.94551049545976
            ],
            [
              6.75006945353141,
              38.651811712711236
            ],
            [
              5.737570170795323,
              41.744358789482135
            ],
            [
              5.372530429392953,
              43.29362778902916
            ]
          ],
          [
            [
              32.28445136473579,
              31.259278146448963
            ],
            [
              32.1750514422356,
              30.83702058239723
            ],
            [
              32.287551363135435,
              30.077385962079745
            ],
            [
              32.343736322141524,
              29.63833609362638
            ],
            [
              32.512711202438425,
              29.34456698948989
            ],
            [
              32.934525904812716,
              28.951554732193216
            ],
            [
              33.08276564295244,
              28.365936333863488
            ],
            [
              33.38487558519094,
              28.161052262220892
            ],
            [
              33.918800206947566,
              27.364667993860166
            ],
            [
              34.50942478854367,
              26.562513149236622
            ],
            [
              34.987549450431516,
              25.754704263415306
            ],
            [
              36.0000487331676,
              24.12261698700334
            ],
            [
              37.237547856511526,
              22.05298561667763
            ],
            [
              38.02504729863958,
              20.375041253465525
            ],
            [
              39.37504634228762,
              18.251816319028308
            ],
            [
              40.387545625025695,
              16.53419619825962
            ],
            [
              41.79379462882369,
              14.801154224791475
            ],
            [
              42.30004427019156,
              13.929303843271725
            ],
            [
              42.89066885178764,
              13.054150695298716
            ],
            [
              43.17191865195173,
              12.834868817846598
            ],
            [
              43.25629359217959,
              12.615395567393307
            ],
            [
              43.50941841286368,
              12.395734000022884
            ],
            [
              44.550042675675655,
              11.073982781226704
            ],
            [
              45.450042038107796,
              10.963556857789229
            ],
            [
              48.600039806619776,
              11.735650161405744
            ],
            [
              52.650036937563726,
              12.725155923562937
            ],
            [
              54.000035981807684,
              12.944533868662859
            ],
            [
              54.67503550363159,
              12.615395567393307
            ],
            [
              53.550036300591586,
              9.524411345019587
            ],
            [
              50.850038213295704,
              5.510071711803135
            ],
            [
              47.700040444187806,
              1.468426767332062
            ],
            [
              45.22504219809568,
              -1.006358951224712
            ],
            [
              42.30004427019156,
              -3.254657364797595
            ],
            [
              39.67289613069207,
              -4.052924364763157
            ]
          ],
          [
            [
              44.550042675675655,
              11.184367066436806
            ],
            [
              43.650043313243685,
              11.489461709300429
            ],
            [
              43.147993668900284,
              11.594869371447714
            ]
          ],
          [
            [
              45.450042038107796,
              10.963556857789229
            ],
            [
              45.23580218987712,
              10.705441883254508
            ],
            [
              45.01088234921272,
              10.435118748992778
            ]
          ],
          [
            [
              54.00003598121177,
              12.944533868662859
            ],
            [
              55.35003502485981,
              13.710817738179543
            ],
            [
              58.95003247458786,
              15.452760959322148
            ],
            [
              60.97503104005983,
              16.749771315644608
            ],
            [
              65.70002769282789,
              20.375041253465525
            ],
            [
              66.2625272943479,
              23.298598065875808
            ],
            [
              67.02854675169263,
              24.889731701235718
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "sednalink-fibre",
        "name": "SednaLink Fibre",
        "color": "#939597",
        "feature_id": "sednalink-fibre-0",
        "coordinates": [
          -59.98881855581561,
          59.14228054508973
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -60.32608400000028,
              53.301683
            ],
            [
              -59.3998836852212,
              53.7578270471366
            ],
            [
              -58.04988464157316,
              54.28654244078854
            ],
            [
              -57.14988527914119,
              54.515746054793624
            ],
            [
              -56.6998855979252,
              55.419806214460095
            ],
            [
              -56.81023254156939,
              56.85959652714294
            ],
            [
              -62.99988113494925,
              61.304658531911684
            ],
            [
              -64.79987985981327,
              62.15710017959822
            ],
            [
              -66.14987890346123,
              62.57453442007588
            ],
            [
              -67.94987762832534,
              63.39212305870191
            ],
            [
              -68.39987730954124,
              63.59296341313572
            ],
            [
              -68.5196772246739,
              63.748596953565425
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "natitua-sud",
        "name": "Natitua Sud",
        "color": "#56c5cd",
        "feature_id": "natitua-sud-0",
        "coordinates": [
          -148.94982024720593,
          -20.4601744714345
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -149.17482008781397,
              -22.423530538252976
            ],
            [
              -150.74981897206996,
              -22.423530538252976
            ],
            [
              -151.34291855191267,
              -22.451259432002047
            ]
          ],
          [
            [
              -149.48591986742795,
              -23.346921000731925
            ],
            [
              -149.17482008781397,
              -22.423530538252976
            ],
            [
              -148.94982024720593,
              -20.855024450960002
            ],
            [
              -148.94982024720593,
              -18.097730413625754
            ],
            [
              -149.06232016750997,
              -17.77663542458734
            ],
            [
              -149.30812077403647,
              -17.723342219804465
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "cogim",
        "name": "COGIM",
        "color": "#2da348",
        "feature_id": "cogim-0",
        "coordinates": [
          -63.09871067071035,
          47.99957081518147
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -64.30898020757125,
              48.471795243193526
            ],
            [
              -63.89988049738122,
              48.35656994073389
            ],
            [
              -62.54988145373326,
              47.75501398838159
            ],
            [
              -61.95568187466979,
              47.382579621269315
            ]
          ],
          [
            [
              -64.30898020757125,
              48.471795243193526
            ],
            [
              -63.89988049738122,
              48.20683973227631
            ],
            [
              -62.54988145373326,
              47.603526230147054
            ],
            [
              -61.95568187466979,
              47.382579621269315
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "nzadi-cable-system",
        "name": "Nzadi Cable System",
        "color": "#939597",
        "feature_id": "nzadi-cable-system-0",
        "coordinates": [
          11.890182179105153,
          -7.257207799586837
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              12.352065485024895,
              -6.141015427762483
            ],
            [
              12.150065628123372,
              -6.24401084163074
            ],
            [
              11.700065946907301,
              -6.24401084163074
            ]
          ],
          [
            [
              13.2350248601241,
              -8.812561807472918
            ],
            [
              12.60006530933947,
              -8.252720521975078
            ],
            [
              11.925065787515337,
              -7.361072141346966
            ],
            [
              11.700065946907301,
              -6.691145450676487
            ],
            [
              11.700065946907301,
              -6.24401084163074
            ],
            [
              11.700065946907301,
              -5.796494123131055
            ],
            [
              11.925065787515337,
              -5.572600905016571
            ],
            [
              12.189865599928936,
              -5.556576787346813
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "au-aleutian",
        "name": "AU-Aleutian",
        "color": "#939597",
        "feature_id": "au-aleutian-0",
        "coordinates": [
          -163.1289861938159,
          54.78822349913088
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -152.88731745784605,
              57.93214153471675
            ],
            [
              -152.88193814671203,
              57.86944326210158
            ]
          ],
          [
            [
              -162.89981036490207,
              54.62453363273431
            ],
            [
              -163.0123102852061,
              54.75458482702223
            ],
            [
              -163.23731012581422,
              54.81945422425164
            ],
            [
              -163.41500999992988,
              54.850957576706435
            ]
          ],
          [
            [
              -158.84981323395803,
              55.58979765418974
            ],
            [
              -158.96231315426206,
              55.78006042872178
            ],
            [
              -159.14481302497745,
              55.91748576408034
            ]
          ],
          [
            [
              -158.77211328900142,
              56.25498350864953
            ],
            [
              -158.62481339335008,
              56.28291483663344
            ],
            [
              -158.50721347665896,
              56.32136337173188
            ],
            [
              -158.40881354636645,
              56.294514501432815
            ]
          ],
          [
            [
              -162.30441078668878,
              55.04920181729881
            ],
            [
              -162.44981068368608,
              55.01343862290665
            ]
          ],
          [
            [
              -162.44981068368608,
              55.01343862290665
            ],
            [
              -162.5623106039901,
              55.07789244004107
            ],
            [
              -162.70401050360863,
              55.20414950086246
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "au-aleutian",
        "name": "AU-Aleutian",
        "color": "#2d56a6",
        "feature_id": "au-aleutian-1",
        "coordinates": [
          -159.80395885589587,
          55.39091486944679
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -152.54981769693399,
              57.902264580877784
            ],
            [
              -152.50173842565377,
              57.92520170162089
            ]
          ],
          [
            [
              -165.93730821311013,
              54.36317932422034
            ],
            [
              -165.8248082928061,
              54.297579451743616
            ],
            [
              -165.7730505169718,
              54.13563909483611
            ]
          ],
          [
            [
              -166.5332550565608,
              53.884514690832674
            ],
            [
              -166.49980781463012,
              54.03413235288798
            ],
            [
              -166.27480797402217,
              54.297579451743616
            ],
            [
              -165.93730821311013,
              54.36317932422034
            ],
            [
              -165.59980845219815,
              54.42867459701131
            ],
            [
              -165.14980877098208,
              54.297579451743616
            ],
            [
              -164.2498094085501,
              54.297579451743616
            ],
            [
              -163.34981004611814,
              54.42867459701131
            ],
            [
              -162.89981036490207,
              54.62453363273431
            ],
            [
              -162.5623106039901,
              54.81945422425164
            ],
            [
              -162.44981068368608,
              54.98117280667424
            ],
            [
              -162.30441078668878,
              55.04920181729881
            ]
          ],
          [
            [
              -153.9860166795172,
              57.53731864742798
            ],
            [
              -153.96856734948318,
              57.66903988014627
            ],
            [
              -154.349816421798,
              57.75250737948306
            ],
            [
              -154.799816103014,
              57.69243021067541
            ],
            [
              -155.47481562483802,
              57.32986760241204
            ],
            [
              -156.82481466848606,
              56.469799876841364
            ],
            [
              -157.49981419031008,
              56.40760669952738
            ],
            [
              -157.94981387152606,
              56.41452207827379
            ],
            [
              -158.40881354636645,
              56.294514501432815
            ]
          ],
          [
            [
              -158.40881354636645,
              56.294514501432815
            ],
            [
              -158.1748137121341,
              56.345311717086645
            ],
            [
              -158.0498138006852,
              56.27944535366353
            ],
            [
              -157.95606442397008,
              56.06721100545158
            ],
            [
              -158.39981355274205,
              55.716742341796504
            ],
            [
              -158.84981323395803,
              55.58979765418974
            ],
            [
              -159.29981291517413,
              55.462441022744116
            ],
            [
              -159.7498125963901,
              55.39860800738413
            ],
            [
              -160.19981227760607,
              55.33467174196275
            ],
            [
              -160.49631206756285,
              55.341553400505745
            ],
            [
              -160.64981195882206,
              55.43053741585322
            ],
            [
              -160.8748117994301,
              55.39860800738413
            ],
            [
              -161.09981164003813,
              55.33467174196275
            ],
            [
              -161.32481148064608,
              55.30266486319479
            ],
            [
              -161.5498113212541,
              55.30266486319479
            ],
            [
              -161.77481116186206,
              55.23857355917991
            ],
            [
              -161.9998110024701,
              55.07789244004107
            ],
            [
              -162.11231092277413,
              55.01343862290665
            ],
            [
              -162.30441078668878,
              55.04920181729881
            ]
          ],
          [
            [
              -152.39511780652478,
              57.79442233367661
            ],
            [
              -152.3810685141858,
              57.872362884096276
            ],
            [
              -152.54981769693399,
              57.902264580877784
            ],
            [
              -152.88731745784605,
              57.93214153471675
            ],
            [
              -152.99981737814997,
              57.961993642608604
            ],
            [
              -153.16856793641995,
              57.991820801948336
            ],
            [
              -153.44981705936607,
              58.02162314067965
            ],
            [
              -153.89981674058203,
              57.991820801948336
            ],
            [
              -154.1248165811901,
              57.81248509700279
            ],
            [
              -153.91231673172692,
              57.66903988014627
            ],
            [
              -153.9860166795172,
              57.53731864742798
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "sealink",
        "name": "SEALink",
        "color": "#b08c34",
        "feature_id": "sealink-0",
        "coordinates": [
          -133.5728855655222,
          57.16786004279635
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -132.82953166696967,
              56.01522795549937
            ],
            [
              -132.91128279861326,
              56.12703903462045
            ],
            [
              -132.89728280888463,
              56.19050811225165
            ],
            [
              -132.72971293122976,
              56.26414244600613
            ],
            [
              -132.68226177043363,
              56.290901656933244
            ],
            [
              -132.6480829917154,
              56.35047071868672
            ],
            [
              -132.6480829917154,
              56.38164333600656
            ],
            [
              -132.6480829917154,
              56.47504144368915
            ],
            [
              -132.64813299025775,
              56.53717947317284
            ],
            [
              -132.70453414081948,
              56.599105613036215
            ],
            [
              -132.78895907958974,
              56.66104033500301
            ],
            [
              -132.83120037858578,
              56.722763716398305
            ],
            [
              -132.96985275504602,
              56.80779122608294
            ],
            [
              -132.844882847329,
              56.91336556277733
            ],
            [
              -132.95198276875283,
              57.02097743143682
            ],
            [
              -133.2329425620245,
              57.0817458672619
            ],
            [
              -133.5163311798385,
              57.12497316683124
            ],
            [
              -133.57328231292297,
              57.16816090817329
            ],
            [
              -133.60188111983007,
              57.22966820564031
            ],
            [
              -133.57424231079767,
              57.31396150003877
            ],
            [
              -133.6085222856475,
              57.37303699157914
            ],
            [
              -133.63518109624013,
              57.558771470979536
            ],
            [
              -133.7463121845549,
              57.707894171078124
            ],
            [
              -133.76848216971047,
              57.823712369918354
            ],
            [
              -133.93518088371752,
              57.98640326883037
            ],
            [
              -134.06858194953583,
              58.10124945897567
            ],
            [
              -134.1927818584138,
              58.16513091439583
            ],
            [
              -134.29583062763368,
              58.2074722872718
            ],
            [
              -134.57990043354195,
              58.242595438937855
            ],
            [
              -134.6554803780911,
              58.2916726856476
            ],
            [
              -134.78693028165003,
              58.41219086765227
            ],
            [
              -134.78291914433507,
              58.498125955375684
            ],
            [
              -134.74729145098985,
              58.55104926823199
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "colombian-festoon",
        "name": "Colombian Festoon",
        "color": "#34b44a",
        "feature_id": "colombian-festoon-0",
        "coordinates": [
          -75.572066881937,
          10.345771350329215
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -74.2052931188053,
              11.241938042153947
            ],
            [
              -74.36237308565332,
              11.331480662183736
            ],
            [
              -74.47487300595733,
              11.221152507138186
            ],
            [
              -74.61193345573746,
              11.012160871628708
            ]
          ],
          [
            [
              -74.95280235488772,
              11.005163154152314
            ],
            [
              -75.14987252778135,
              11.073982781226704
            ],
            [
              -75.26237244808537,
              10.963556857789229
            ],
            [
              -75.50573165009138,
              10.386791448721794
            ],
            [
              -75.82487204960537,
              10.18944276650771
            ],
            [
              -75.82487205020136,
              9.894039027618874
            ],
            [
              -75.55866223759489,
              9.496381979983852
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "iris",
        "name": "IRIS",
        "color": "#94479b",
        "feature_id": "iris-0",
        "coordinates": [
          -14.825616934992468,
          59.69105121774612
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9.048319354741835,
              53.273886331643325
            ],
            [
              -9.899918751460802,
              53.05365242709044
            ],
            [
              -10.799918113892772,
              53.05365242709044
            ],
            [
              -11.249917795108757,
              53.32328423459367
            ],
            [
              -12.599916838160823,
              54.94878902385568
            ],
            [
              -12.599916838756798,
              55.67438282806273
            ],
            [
              -12.374916998148876,
              56.42826046882331
            ],
            [
              -12.599916838756798,
              58.836278671696704
            ],
            [
              -13.949915882404753,
              59.52787100202244
            ],
            [
              -21.149910781860854,
              60.869535166329314
            ],
            [
              -21.824910303684874,
              62.15710017959802
            ],
            [
              -22.72490966611693,
              62.98618902459583
            ],
            [
              -22.27490998490086,
              63.49271968258239
            ],
            [
              -21.37812062019495,
              63.85625749318217
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "amitie",
        "name": "Amitie",
        "color": "#4bb748",
        "feature_id": "amitie-0",
        "coordinates": [
          -35.611006018074534,
          47.229908495775845
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -10.799918113296798,
              49.441203723128126
            ],
            [
              -7.199920663568747,
              50.88245364291035
            ],
            [
              -5.399921938704722,
              51.02419288763869
            ],
            [
              -4.544402544762761,
              50.82820142743802
            ]
          ],
          [
            [
              -70.9502755028152,
              42.46364601310964
            ],
            [
              -69.2998766713773,
              42.41235450073577
            ],
            [
              -61.199882409489234,
              41.744358789482135
            ],
            [
              -50.39989006030518,
              43.564400497117504
            ],
            [
              -39.999995084534305,
              46.17414676370771
            ],
            [
              -23.39990918734489,
              50.16726116292705
            ],
            [
              -16.199914287888873,
              50.16726116292705
            ],
            [
              -10.799918113296798,
              49.441203723128126
            ],
            [
              -5.849921620516682,
              46.99317357497881
            ],
            [
              -3.149923533220687,
              45.11977579961189
            ],
            [
              -1.2118249061879,
              44.893811870344166
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "gulf-of-mexico-fiber-optic-network",
        "name": "Gulf of Mexico Fiber Optic Network",
        "color": "#b09330",
        "feature_id": "gulf-of-mexico-fiber-optic-network-0",
        "coordinates": [
          -91.16801373144628,
          28.05519878947112
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -95.3443582218182,
              28.949542146640226
            ],
            [
              -94.04985913885349,
              28.094910059657792
            ],
            [
              -91.79986073277357,
              27.89623898952877
            ],
            [
              -88.6498629642615,
              28.68871408880051
            ],
            [
              -88.19986328304552,
              29.475236194966442
            ],
            [
              -88.5561630306394,
              30.36577371017563
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "havsil",
        "name": "Havsil",
        "color": "#2b2f83",
        "feature_id": "havsil-0",
        "coordinates": [
          8.36187517574561,
          57.632883266799354
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              8.616168131569623,
              57.1116502612407
            ],
            [
              8.662568098699438,
              57.289273621719786
            ],
            [
              8.100068496583532,
              57.93205658695149
            ],
            [
              7.996258571315195,
              58.15106571642474
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "grace-hopper",
        "name": "Grace Hopper",
        "color": "#28b08c",
        "feature_id": "grace-hopper-0",
        "coordinates": [
          -38.24102401874376,
          41.426819311460434
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -16.199914287888873,
              46.272182853813746
            ],
            [
              -9.899918750864742,
              46.89076287862241
            ],
            [
              -5.849921619920792,
              45.96024524125332
            ],
            [
              -4.274922736260805,
              44.694829089578064
            ],
            [
              -2.94919367482359,
              43.27422025200056
            ]
          ],
          [
            [
              -72.93786409419286,
              40.75584308487123
            ],
            [
              -71.09987539624133,
              39.78482855593708
            ],
            [
              -68.39987730894534,
              39.524987333511575
            ],
            [
              -61.199882409489234,
              38.651811712711236
            ],
            [
              -50.39989006030518,
              39.00237890905848
            ],
            [
              -39.59989771112103,
              41.0693404382163
            ],
            [
              -23.39990918734489,
              45.33107107332478
            ],
            [
              -16.199914287888873,
              46.272182853813746
            ],
            [
              -10.799918113296798,
              49.294684219425534
            ],
            [
              -8.099920026000802,
              49.73293362369091
            ],
            [
              -4.544402544762761,
              50.82820142743802
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "asia-direct-cable-adc",
        "name": "Asia Direct Cable (ADC)",
        "color": "#939597",
        "feature_id": "asia-direct-cable-adc-0",
        "coordinates": [
          120.88988111749379,
          20.058334551396115
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              114.29999326356042,
              14.801154224791475
            ],
            [
              116.99999135204828,
              13.710817738179543
            ],
            [
              118.79999007691222,
              13.492128176464178
            ],
            [
              120.14998912056026,
              13.492128176464178
            ],
            [
              121.06600847164387,
              13.762418337904336
            ]
          ],
          [
            [
              116.99999135204828,
              19.316876111628602
            ],
            [
              117.22499119206023,
              19.952622905164304
            ],
            [
              117.22499119206023,
              20.796306105108954
            ],
            [
              116.99999135204828,
              22.469443964829594
            ],
            [
              116.67753158048177,
              23.355006811273626
            ]
          ],
          [
            [
              115.19999262718417,
              18.251816319028308
            ],
            [
              114.52499310536027,
              20.796306105108954
            ],
            [
              114.20292333351765,
              22.222050419736746
            ]
          ],
          [
            [
              113.17499406171223,
              12.615395567393307
            ],
            [
              112.94999422110416,
              12.834868817846598
            ],
            [
              111.59999517745612,
              13.492128176464178
            ],
            [
              110.02499629320002,
              13.820086409698149
            ],
            [
              109.21959686375268,
              13.782910441432165
            ]
          ],
          [
            [
              107.99999772772824,
              5.398081130463737
            ],
            [
              105.29999964043216,
              6.405200795356109
            ],
            [
              103.27500107495999,
              7.744889052551343
            ],
            [
              100.80000282767625,
              9.524411345019587
            ],
            [
              100.12500330585212,
              11.294709319565555
            ],
            [
              100.23750322675212,
              12.17588718550806
            ],
            [
              100.5750029864723,
              12.834868817846598
            ],
            [
              100.93057273577531,
              13.174371211662333
            ]
          ],
          [
            [
              139.97546699999984,
              35.005433
            ],
            [
              140.1749749340764,
              34.89859296336232
            ],
            [
              140.34372481453246,
              34.69072647741018
            ],
            [
              140.51247469558447,
              34.40502275071583
            ],
            [
              140.84997445649643,
              33.93964008831958
            ],
            [
              140.84997445649643,
              32.43331330641712
            ],
            [
              140.39997477528036,
              30.901396088515583
            ],
            [
              138.82497589102448,
              28.161052262220892
            ],
            [
              137.24997700676838,
              26.562513149236622
            ],
            [
              132.74998019460836,
              23.50508968095727
            ],
            [
              130.94998146974442,
              22.67720619658283
            ],
            [
              125.99998497636832,
              21.111485983488905
            ],
            [
              122.84998720785634,
              20.26954403592967
            ],
            [
              121.04998848299223,
              20.058334551396143
            ],
            [
              120.14998912056026,
              20.058334551396143
            ],
            [
              116.99999135204828,
              19.316876111628602
            ],
            [
              115.19999262718417,
              18.251816319028308
            ],
            [
              114.29999326356042,
              14.801154224791475
            ],
            [
              113.17499406171223,
              12.615395567393307
            ],
            [
              112.16249477897614,
              9.96791518697421
            ],
            [
              110.92499565563222,
              7.744889052551343
            ],
            [
              107.99999772772824,
              5.398081130463737
            ],
            [
              105.74999932164823,
              4.389285926050889
            ],
            [
              104.68125007876003,
              2.817450442654064
            ],
            [
              104.3161753373827,
              1.468426767332062
            ],
            [
              104.20615041532531,
              1.341894944206274
            ],
            [
              103.91973061822782,
              1.228443589211935
            ],
            [
              103.64609081207684,
              1.338585852071589
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "eaufon-3",
        "name": "EAUFON 3",
        "color": "#939597",
        "feature_id": "eaufon-3-0",
        "coordinates": [
          -68.97503762005137,
          60.340351862264384
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -69.2998766719733,
              61.304658531911684
            ],
            [
              -69.63737643288535,
              61.14218811914628
            ],
            [
              -69.65026704875348,
              61.0399977354345
            ]
          ],
          [
            [
              -69.07487683136534,
              60.093570225922946
            ],
            [
              -69.52487651258124,
              60.009326600079135
            ],
            [
              -69.8623762734933,
              60.009326600079135
            ],
            [
              -70.02623500000023,
              60.023414999999886
            ]
          ],
          [
            [
              -69.03737685793058,
              59.51719275174028
            ],
            [
              -69.48737653914665,
              59.40286331928206
            ],
            [
              -69.5974889999999,
              59.30574
            ]
          ],
          [
            [
              -68.39987730954124,
              59.29889402722176
            ],
            [
              -69.07487683136534,
              58.95251729542423
            ],
            [
              -69.41237659227731,
              58.89444683796128
            ],
            [
              -69.52487651258124,
              58.80715791514564
            ],
            [
              -69.63737643288535,
              58.77801269228053
            ],
            [
              -69.9373962203485,
              58.697407
            ]
          ],
          [
            [
              -67.94987762832534,
              58.95251729542423
            ],
            [
              -68.17487746893329,
              58.54396856415465
            ],
            [
              -68.17487746893329,
              58.42635692511544
            ],
            [
              -68.2873773892373,
              58.24920018303547
            ],
            [
              -68.41883729610988,
              58.102996
            ]
          ],
          [
            [
              -71.95388901309515,
              61.59626
            ],
            [
              -71.99987475926929,
              61.62709041825664
            ],
            [
              -71.99987475926929,
              61.73382762714013
            ],
            [
              -71.77487491866134,
              61.78705797379007
            ],
            [
              -71.32487523744535,
              61.73382762714013
            ],
            [
              -70.64987571562133,
              61.51998376783524
            ],
            [
              -69.2998766719733,
              61.304658531911684
            ],
            [
              -68.84987699075731,
              61.087844719599644
            ],
            [
              -68.84987699075731,
              60.649722744668196
            ],
            [
              -69.07487683136534,
              60.093570225922946
            ],
            [
              -69.03737685793058,
              59.51719275174028
            ],
            [
              -68.39987730954124,
              59.29889402722176
            ],
            [
              -67.94987762832534,
              58.95251729542423
            ],
            [
              -66.5998785846773,
              59.06836550247485
            ],
            [
              -66.14987890346123,
              58.95251729542423
            ],
            [
              -65.9951190130946,
              58.710155
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "eaufon-2",
        "name": "EAUFON 2",
        "color": "#c11a7d",
        "feature_id": "eaufon-2-0",
        "coordinates": [
          -78.53781712108294,
          62.76322167039169
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -78.7498699775094,
              62.47071999993716
            ],
            [
              -78.29987029629342,
              62.47071999993716
            ],
            [
              -77.94346054877742,
              62.41502369498167
            ]
          ],
          [
            [
              -78.97486981811736,
              61.087844719599644
            ],
            [
              -78.52487013690137,
              60.978877356942235
            ],
            [
              -78.14424040654298,
              60.817683039865955
            ]
          ],
          [
            [
              -75.64685217571625,
              62.20128337288588
            ],
            [
              -75.48733080434772,
              62.395514162727466
            ],
            [
              -74.8123312825237,
              62.34334021969963
            ],
            [
              -74.63081906543265,
              62.11332960460868
            ]
          ],
          [
            [
              -78.52487013690137,
              59.755303236949885
            ],
            [
              -78.97486981811736,
              60.649722744668196
            ],
            [
              -78.97486981811736,
              61.087844719599644
            ],
            [
              -78.7498699775094,
              61.51998376783524
            ],
            [
              -78.7498699775094,
              62.47071999993716
            ],
            [
              -78.52487013690137,
              62.781080493725966
            ],
            [
              -77.39987093386137,
              62.98618902459583
            ],
            [
              -76.04987189021341,
              62.781080493725966
            ],
            [
              -75.64685217571625,
              62.20128337288588
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "eaufon-1",
        "name": "EAUFON 1",
        "color": "#5b6cb3",
        "feature_id": "eaufon-1-0",
        "coordinates": [
          -77.22173960190065,
          56.73124145046779
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -78.29987029629342,
              55.67438282806256
            ],
            [
              -78.07487045568539,
              55.419806214460095
            ],
            [
              -77.76378669168503,
              55.27457419139
            ]
          ],
          [
            [
              -78.7498699775094,
              58.130601640228065
            ],
            [
              -78.29987029629342,
              58.36740335134627
            ],
            [
              -78.1050704342914,
              58.455132585223964
            ]
          ],
          [
            [
              -77.17487109325342,
              56.55247760361351
            ],
            [
              -76.72487141203734,
              56.55247760361334
            ],
            [
              -76.547137162946,
              56.55156255991264
            ]
          ],
          [
            [
              -77.26920696392496,
              60.03711467356487
            ],
            [
              -78.52487013690137,
              59.755303236949885
            ],
            [
              -79.19986965872539,
              59.06836550247485
            ],
            [
              -79.19986965872539,
              58.60262683506721
            ],
            [
              -78.7498699775094,
              58.130601640228065
            ],
            [
              -77.39987093386137,
              57.41066230395057
            ],
            [
              -77.17487109325342,
              56.55247760361351
            ],
            [
              -77.39987093386137,
              56.178604166899504
            ],
            [
              -77.84987061507735,
              55.92731356432168
            ],
            [
              -78.29987029629342,
              55.67438282806256
            ],
            [
              -79.19986965872539,
              55.419806214460095
            ],
            [
              -80.09986902115736,
              54.905692844320384
            ],
            [
              -80.09986902115736,
              54.38492939974421
            ],
            [
              -79.64986933994138,
              53.85747344030463
            ],
            [
              -78.89347339140438,
              53.77361440535725
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "2africa",
        "name": "2Africa",
        "color": "#939597",
        "feature_id": "2africa-0",
        "coordinates": [
          50.48931098560888,
          3.8924803275509703
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              25.20005638398345,
              34.33537907402828
            ],
            [
              24.943931564828745,
              34.867831005273246
            ],
            [
              24.7684,
              35.07162999999974
            ]
          ],
          [
            [
              45.450042038703714,
              11.073982781226704
            ],
            [
              45.17955222972515,
              10.705441883254508
            ],
            [
              45.01088234980861,
              10.435118748992778
            ]
          ],
          [
            [
              55.350035025455725,
              26.26024097157773
            ],
            [
              54.900035344239626,
              25.754704263415306
            ],
            [
              54.45003566183195,
              24.8393128255928
            ],
            [
              54.41907568495611,
              24.443964572625504
            ]
          ],
          [
            [
              60.52503135943985,
              16.10232559580288
            ],
            [
              60.975031038867854,
              16.31838002635962
            ],
            [
              66.60002705585575,
              18.251816319028308
            ],
            [
              70.2000245055838,
              18.785187974742087
            ],
            [
              72.87590260996691,
              19.076074257285313
            ]
          ],
          [
            [
              60.52503135943985,
              22.469443964829594
            ],
            [
              65.25002801161182,
              24.259444485784854
            ],
            [
              67.02854675228852,
              24.889731701235718
            ]
          ],
          [
            [
              58.50003279396768,
              23.891388761253097
            ],
            [
              58.16253303305572,
              23.865671119262572
            ],
            [
              58.004427676309035,
              23.679602315303896
            ]
          ],
          [
            [
              57.03753383001572,
              25.348717422116806
            ],
            [
              57.38011358673319,
              24.420846844473175
            ],
            [
              57.71253335183965,
              24.12261698700334
            ],
            [
              58.50003279396768,
              23.891388761253097
            ],
            [
              59.85003183761572,
              23.19523175611701
            ],
            [
              60.52503135943985,
              22.469443964829594
            ],
            [
              60.52503135943985,
              19.104405475930548
            ],
            [
              60.52503135943985,
              16.10232559580288
            ],
            [
              58.95003247339588,
              15.018578573757566
            ],
            [
              55.35003502366783,
              13.273238157547667
            ],
            [
              54.900035344239626,
              12.615395567393307
            ]
          ],
          [
            [
              51.300037894511576,
              26.964304734562802
            ],
            [
              50.96253813359962,
              26.562513149236622
            ],
            [
              50.576018407413954,
              26.22949483839116
            ]
          ],
          [
            [
              52.200037256943745,
              26.562513149236622
            ],
            [
              51.86253749603159,
              26.058287560298936
            ],
            [
              51.51927773920005,
              25.294608758024538
            ]
          ],
          [
            [
              50.62503837268767,
              27.364667993860166
            ],
            [
              50.28753861177569,
              26.964304734562802
            ],
            [
              50.21419866373054,
              26.285375359318067
            ]
          ],
          [
            [
              49.16253940873568,
              28.75448641587161
            ],
            [
              47.97484025011303,
              29.37410420420028
            ]
          ],
          [
            [
              48.531779855571585,
              29.92363278689706
            ],
            [
              49.16253940873568,
              28.75448641587161
            ],
            [
              50.17503869147157,
              28.061823659710228
            ],
            [
              50.62503837268767,
              27.364667993860166
            ],
            [
              51.300037894511576,
              26.964304734562802
            ],
            [
              52.200037256943745,
              26.562513149236622
            ],
            [
              53.550036300591586,
              26.26024097157773
            ],
            [
              55.350035025455725,
              26.26024097157773
            ],
            [
              55.800034706671596,
              26.361086325391653
            ],
            [
              56.250034387887666,
              26.713351447732805
            ],
            [
              56.86878394955983,
              26.562513149236622
            ],
            [
              57.03753383001572,
              26.159307970773796
            ],
            [
              57.03753383001572,
              25.348717422116806
            ],
            [
              56.339934323605746,
              25.0514826710928
            ]
          ],
          [
            [
              34.59379972936759,
              26.562513149236622
            ],
            [
              35.21254929044366,
              27.097918575216056
            ],
            [
              35.6965489475738,
              27.354010300438773
            ]
          ],
          [
            [
              37.350047776815444,
              22.05298561667763
            ],
            [
              39.182756478507656,
              21.481533475503085
            ]
          ],
          [
            [
              41.850044588975464,
              -11.943944931746927
            ],
            [
              42.0750444295835,
              -12.053986862571492
            ],
            [
              42.75004395140763,
              -12.053986862571492
            ],
            [
              43.24330360197786,
              -11.70058928227246
            ]
          ],
          [
            [
              -18.67491253517281,
              31.28673881439167
            ],
            [
              -16.649913969700833,
              30.25570294203996
            ],
            [
              -15.862414526976806,
              28.951554732193216
            ],
            [
              -15.59656471590344,
              27.95773368288536
            ]
          ],
          [
            [
              55.45302495190066,
              -4.565748350533681
            ],
            [
              55.35003502485981,
              -3.928327304142726
            ],
            [
              54.90003534364374,
              -1.081346446098905
            ],
            [
              54.00003598121177,
              1.168506749040887
            ],
            [
              51.750037576323734,
              5.510071711803135
            ]
          ],
          [
            [
              5.400070410479259,
              2.367912558705314
            ],
            [
              7.200069135343199,
              3.154491498099929
            ],
            [
              7.650068816559269,
              3.491423322320486
            ],
            [
              7.999287318573636,
              4.541590692825167
            ]
          ],
          [
            [
              1.800072960155291,
              -11.356308769001004
            ],
            [
              11.70006594750339,
              -8.846050186819042
            ],
            [
              12.60006530993536,
              -8.830631937053113
            ],
            [
              13.2350248601241,
              -8.812561807472918
            ]
          ],
          [
            [
              27.000055108251473,
              -34.734661512708556
            ],
            [
              25.875055905211468,
              -34.364025894526776
            ],
            [
              25.62325608358887,
              -33.96247066676
            ]
          ],
          [
            [
              5.372530429989041,
              43.29362778902916
            ],
            [
              5.175070569275306,
              42.85377505385463
            ],
            [
              4.050071366235301,
              41.85617959436382
            ],
            [
              2.70007232258726,
              41.351450286896835
            ],
            [
              2.168725042748747,
              41.38560270176822
            ]
          ],
          [
            [
              8.938867903561913,
              44.41035752885395
            ],
            [
              8.775068019003356,
              44.051519228735145
            ],
            [
              8.325068338383204,
              43.72721479104973
            ],
            [
              7.200069135343199,
              43.07331078300331
            ],
            [
              6.412569692619428,
              42.74371346443661
            ],
            [
              5.962570011403358,
              42.74371346443661
            ],
            [
              5.175070569275306,
              42.85377505385463
            ]
          ],
          [
            [
              32.28445136473579,
              31.259278146448963
            ],
            [
              32.1750514422356,
              31.510798430048965
            ],
            [
              31.72505176101953,
              31.79808736758517
            ],
            [
              30.60005255857544,
              32.24321001626265
            ],
            [
              28.8000538337115,
              32.90681902852478
            ],
            [
              25.20005638398345,
              34.33537907402828
            ],
            [
              23.400057658523423,
              34.71384862261547
            ],
            [
              22.05005861487558,
              35.12894023821613
            ],
            [
              19.35006052817539,
              35.60293032290622
            ],
            [
              16.65006244087931,
              35.23621340052833
            ],
            [
              14.4000640347993,
              35.511407641688464
            ],
            [
              13.162564911455348,
              35.96797434759347
            ],
            [
              12.487565389631243,
              36.87321951208918
            ],
            [
              11.70006594750339,
              37.85673997565843
            ],
            [
              11.250066265691402,
              39.69832335493328
            ],
            [
              10.35006690325946,
              41.520132020893186
            ],
            [
              9.787567301739443,
              42.41235450073577
            ],
            [
              9.562567461727298,
              43.07331078300331
            ],
            [
              9.450067540827291,
              43.40114497315386
            ],
            [
              9.112567779915338,
              44.051519228735145
            ],
            [
              8.938867903561913,
              44.41035752885395
            ]
          ],
          [
            [
              33.41295056589729,
              28.161052262220892
            ],
            [
              33.300050645275604,
              28.293214058016247
            ],
            [
              33.08276564295244,
              28.365936333863488
            ]
          ],
          [
            [
              36.11254865347152,
              24.12261698700334
            ],
            [
              36.900048095003655,
              24.259444485784854
            ],
            [
              37.80004745803154,
              24.225251377401804
            ],
            [
              38.10697724059963,
              24.070648010417926
            ]
          ],
          [
            [
              38.137547218943496,
              20.375041253465525
            ],
            [
              37.589237574003334,
              19.9190434481514
            ],
            [
              37.21967786917094,
              19.615566594546237
            ]
          ],
          [
            [
              44.55004267627157,
              11.184367066436806
            ],
            [
              43.6500433138396,
              11.45511199738803
            ],
            [
              43.147993669496344,
              11.594869371447714
            ]
          ],
          [
            [
              54.000035981807684,
              13.054150695298716
            ],
            [
              54.112535901515685,
              13.382708036125592
            ],
            [
              54.2250358218198,
              14.583511645118676
            ],
            [
              54.2250358218198,
              16.53419619825962
            ],
            [
              54.14808587692781,
              17.095827186725558
            ]
          ],
          [
            [
              48.600039807215495,
              1.468426767332062
            ],
            [
              46.800041082351555,
              1.918228780215685
            ],
            [
              45.34418211369595,
              2.041205223228673
            ]
          ],
          [
            [
              41.17504506655564,
              -4.900422453147314
            ],
            [
              40.72504538533957,
              -4.676208028750978
            ],
            [
              39.74506607956607,
              -3.946125186873786
            ]
          ],
          [
            [
              40.95004522654352,
              -5.273944363641391
            ],
            [
              40.500045544731705,
              -4.676208028750978
            ],
            [
              39.67289769319086,
              -4.053024114605478
            ]
          ],
          [
            [
              40.387545625023506,
              -6.169450529574419
            ],
            [
              39.8250460229076,
              -6.467627592690604
            ],
            [
              39.2696764169327,
              -6.823132108349142
            ]
          ],
          [
            [
              42.300044269595645,
              -14.861883917662055
            ],
            [
              45.000042356891726,
              -15.296387760621855
            ],
            [
              46.315441425050665,
              -15.713729798684284
            ]
          ],
          [
            [
              42.30004427019156,
              -15.22403228464728
            ],
            [
              41.400044907163675,
              -14.644301977123689
            ],
            [
              40.68539697592695,
              -14.565582936090522
            ]
          ],
          [
            [
              35.3250492113435,
              -26.55162080165701
            ],
            [
              33.75005032708759,
              -26.350174904573805
            ],
            [
              32.580621155522095,
              -25.968268155408055
            ]
          ],
          [
            [
              30.88039235938433,
              -30.057717076456527
            ],
            [
              32.40005128343955,
              -29.823143843762693
            ],
            [
              32.85005096465545,
              -29.431979622206228
            ],
            [
              33.75005032708759,
              -28.348517239947398
            ],
            [
              35.3250492113435,
              -26.55162080165701
            ],
            [
              41.17504506715156,
              -23.287413403488756
            ],
            [
              42.30004427019156,
              -20.57441905727606
            ],
            [
              42.30004427019156,
              -15.22403228464728
            ],
            [
              42.300044269595645,
              -14.861883917662055
            ],
            [
              41.850044588975464,
              -11.943944931746927
            ],
            [
              41.40004490775959,
              -9.290424301035614
            ],
            [
              40.387545625023506,
              -6.169450529574419
            ],
            [
              40.95004522654352,
              -5.273944363641391
            ],
            [
              41.17504506655564,
              -4.900422453147314
            ],
            [
              42.30004427019156,
              -3.479268678969987
            ],
            [
              45.450042038703714,
              -1.231315750217505
            ],
            [
              48.600039807215495,
              1.468426767332062
            ],
            [
              51.750037576323734,
              5.510071711803135
            ],
            [
              54.000035981807684,
              9.524411345019587
            ],
            [
              54.900035344239626,
              12.615395567393307
            ],
            [
              54.000035981807684,
              13.054150695298716
            ],
            [
              52.650036938159616,
              12.834868817846598
            ],
            [
              48.600039807215495,
              11.845776373625682
            ],
            [
              45.450042038703714,
              11.073982781226704
            ],
            [
              44.55004267627157,
              11.184367066436806
            ],
            [
              43.31254355292762,
              12.615395567393307
            ],
            [
              43.21410612266146,
              12.834868817846598
            ],
            [
              42.97504379201547,
              13.054150695298716
            ],
            [
              42.35629423034351,
              13.929303843271725
            ],
            [
              41.850044588975464,
              14.801154224791475
            ],
            [
              40.50004554532981,
              16.53419619825962
            ],
            [
              39.487546262591536,
              18.251816319028308
            ],
            [
              38.137547218943496,
              20.375041253465525
            ],
            [
              37.350047776815444,
              22.05298561667763
            ],
            [
              36.11254865347152,
              24.12261698700334
            ],
            [
              35.10004937073546,
              25.754704263415306
            ],
            [
              34.59379972936759,
              26.562513149236622
            ],
            [
              33.918800207543484,
              27.364667993860166
            ],
            [
              33.41295056589729,
              28.161052262220892
            ],
            [
              32.906450924701346,
              28.951554732193216
            ],
            [
              32.65318110412005,
              29.113614162980156
            ],
            [
              32.56895616318988,
              29.34456698948989
            ],
            [
              32.39998128289315,
              29.63833609362638
            ],
            [
              32.529931191431416,
              29.972545436050268
            ],
            [
              32.737551043755616,
              30.25570294203996
            ],
            [
              32.62505112345167,
              30.83702058239723
            ],
            [
              32.28445136473579,
              31.259278146448963
            ]
          ],
          [
            [
              32.65318110412005,
              29.113614162980156
            ],
            [
              32.62520112394134,
              29.34456698948989
            ],
            [
              32.456226243644636,
              29.63833609362638
            ],
            [
              32.529931191431416,
              29.972545436050268
            ],
            [
              32.850050964059534,
              30.25570294203996
            ],
            [
              32.737551043755616,
              30.83702058239723
            ],
            [
              32.28445136473579,
              31.259278146448963
            ]
          ],
          [
            [
              18.445861168718807,
              -33.72721819637752
            ],
            [
              17.775061643323397,
              -34.11602012163186
            ],
            [
              17.325061962107526,
              -34.857839362235865
            ],
            [
              18.000061483931432,
              -36.321527759917814
            ],
            [
              19.80006020939146,
              -36.683250670190546
            ],
            [
              23.40005765911934,
              -36.683250670190546
            ],
            [
              26.100055745819503,
              -35.836608037492084
            ],
            [
              27.000055108251473,
              -34.734661512708556
            ],
            [
              31.500051920411693,
              -31.340410556277845
            ],
            [
              32.40005128343955,
              -29.823143843762693
            ]
          ],
          [
            [
              10.800066584475331,
              -5.124561675456391
            ],
            [
              11.250066265691402,
              -5.572600905016571
            ],
            [
              12.349965487108477,
              -5.933373731471249
            ]
          ],
          [
            [
              0.000074235291265,
              -6.467627592690604
            ],
            [
              10.800066584475331,
              -5.124561675456391
            ],
            [
              11.863635831628983,
              -4.778787768919273
            ]
          ],
          [
            [
              -3.599923213840782,
              -2.580536704984041
            ],
            [
              0.000074235291265,
              -6.467627592690604
            ],
            [
              1.800072960155291,
              -11.356308769001004
            ],
            [
              6.750069454127299,
              -18.026426383713385
            ],
            [
              8.10006849777534,
              -23.493922445897766
            ],
            [
              13.05006499115126,
              -30.309953344646914
            ],
            [
              15.30006339723144,
              -32.61276000573585
            ],
            [
              16.65006244087931,
              -33.74264465652948
            ],
            [
              17.55006180331145,
              -33.92953699245888
            ],
            [
              18.155531374391046,
              -33.34805845646778
            ]
          ],
          [
            [
              3.423511810692077,
              6.439066911484701
            ],
            [
              3.825071526223184,
              4.164912849976844
            ],
            [
              2.250072641967279,
              1.131014326431796
            ],
            [
              1.575073120143173,
              0.343586284889255
            ],
            [
              0.450073917103168,
              -0.631398185258206
            ],
            [
              -3.599923213840782,
              -2.580536704984041
            ],
            [
              -10.799918113296798,
              -2.580536704984041
            ],
            [
              -15.299914925456818,
              1.918228780215685
            ],
            [
              -19.799911737616924,
              8.635699417327544
            ],
            [
              -21.14991078126488,
              11.735650161405744
            ],
            [
              -21.14092484990462,
              14.365653759228536
            ],
            [
              -21.14991078126488,
              19.952622905164304
            ],
            [
              -20.299911384604457,
              22.884654113882362
            ],
            [
              -18.67491253517281,
              31.28673881439167
            ],
            [
              -17.099913650320843,
              35.05222991093683
            ],
            [
              -16.246065123519145,
              39.46712719019058
            ],
            [
              -16.199914287888873,
              39.69832335493328
            ],
            [
              -16.199914288484848,
              44.694829089578064
            ],
            [
              -11.249917795108757,
              48.70423463096077
            ],
            [
              -7.199920663568747,
              50.740281893948264
            ],
            [
              -5.399921938704722,
              50.95337730033461
            ],
            [
              -4.544402544762761,
              50.82820142743802
            ]
          ],
          [
            [
              -0.204315619320994,
              5.558285889905761
            ],
            [
              0.450073917103168,
              3.279837005485092
            ],
            [
              0.450073917103168,
              -0.631398185258206
            ]
          ],
          [
            [
              -3.599923213840782,
              -2.580536704984041
            ],
            [
              -4.374922664823828,
              1.468426767332062
            ],
            [
              -4.499922576272752,
              2.367912558705314
            ],
            [
              -4.274922735664717,
              3.266814816815753
            ],
            [
              -4.026242911831901,
              5.323508791824736
            ]
          ],
          [
            [
              -21.14092484990462,
              14.365653759228536
            ],
            [
              -18.449912693968884,
              14.365653759228536
            ],
            [
              -17.44571340535299,
              14.686594841995088
            ]
          ],
          [
            [
              -16.199914287888873,
              39.69832335493328
            ],
            [
              -13.949915882404753,
              38.768859224553665
            ],
            [
              -10.799918113296798,
              38.47588134813884
            ],
            [
              -9.337919149586526,
              38.68882888776801
            ]
          ],
          [
            [
              3.825071526223184,
              4.164912849976844
            ],
            [
              5.400070410479259,
              2.367912558705314
            ],
            [
              7.200069135343199,
              1.243490076978134
            ],
            [
              8.55006817899124,
              1.01853421661562
            ],
            [
              9.454267538448192,
              0.394465191855375
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "sir-abu-nuayr-cable",
        "name": "Sir Abu Nu’ayr Cable",
        "color": "#9f54a0",
        "feature_id": "sir-abu-nuayr-cable-0",
        "coordinates": [
          54.79394747078382,
          25.5067805473588
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              55.39223499496501,
              25.352332240929258
            ],
            [
              55.068785224695716,
              25.55188275942578
            ],
            [
              54.45003566183195,
              25.450342946923996
            ],
            [
              54.22113582458252,
              25.24085583479993
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "senegal-horn-of-africa-regional-express-share-cable",
        "name": "Senegal Horn of Africa Regional Express (SHARE) Cable",
        "color": "#c43292",
        "feature_id": "senegal-horn-of-africa-regional-express-share-cable-0",
        "coordinates": [
          -20.50834370394503,
          14.583511645118676
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -17.44571340535299,
              14.686594841995088
            ],
            [
              -18.449912693968884,
              14.583511645118676
            ],
            [
              -22.49990982491292,
              14.583511645118676
            ],
            [
              -23.521209101414883,
              14.923035560171769
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "gondwana-2picot-2",
        "name": "Gondwana-2/Picot-2",
        "color": "#d41f26",
        "feature_id": "gondwana-2picot-2-0",
        "coordinates": [
          172.0543582176921,
          -19.97806576844846
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              167.1749558070367,
              -22.319497901243864
            ],
            [
              167.11970584617634,
              -22.215387660513244
            ],
            [
              166.94507315738767,
              -22.15896231236269
            ]
          ],
          [
            [
              167.39995564764476,
              -21.484467259778455
            ],
            [
              167.62495548825277,
              -21.589112646478924
            ],
            [
              167.87954515164918,
              -21.548062859078698
            ]
          ],
          [
            [
              167.83395603859978,
              -20.96971618702372
            ],
            [
              167.60895480118282,
              -20.86464932352473
            ],
            [
              167.26050262143454,
              -20.937043135849024
            ]
          ],
          [
            [
              166.9499559664287,
              -22.52748528666686
            ],
            [
              167.1749558070367,
              -22.735159984412718
            ],
            [
              167.49425558084178,
              -22.671917357180575
            ]
          ],
          [
            [
              166.61245620492096,
              -22.45819078937805
            ],
            [
              166.61245620492096,
              -22.39464038427937
            ],
            [
              166.56362811510715,
              -22.265370242946062
            ]
          ],
          [
            [
              178.43744782917761,
              -18.12381094353711
            ],
            [
              178.0874480765248,
              -18.560495634148936
            ],
            [
              167.83395603859978,
              -20.96971618702372
            ],
            [
              167.39995564764476,
              -21.484467259778455
            ],
            [
              167.1749558070367,
              -22.319497901243864
            ],
            [
              166.9499559664287,
              -22.52748528666686
            ],
            [
              166.61245620492096,
              -22.45819078937805
            ],
            [
              166.43925632880908,
              -22.30330806462004
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "java-kalimantan-sulawesi-jakasusi",
        "name": "Java-Kalimantan-Sulawesi (JAKASUSI)",
        "color": "#30a7df",
        "feature_id": "java-kalimantan-sulawesi-jakasusi-0",
        "coordinates": [
          114.81725815914584,
          -4.378143197677048
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              112.71972094673194,
              -7.271863121195906
            ],
            [
              112.78124434005238,
              -6.616650693475464
            ],
            [
              112.83749430020433,
              -6.39309949782384
            ],
            [
              113.96249350384025,
              -4.825692499217524
            ],
            [
              114.18749334444826,
              -4.377144375531941
            ],
            [
              114.66674769244,
              -3.882827780552503
            ],
            [
              114.52499310476432,
              -4.252498775658097
            ],
            [
              114.74999294596827,
              -4.377144375531941
            ],
            [
              116.58310164737688,
              -4.404364233745071
            ],
            [
              118.79999007691222,
              -5.049857167366866
            ],
            [
              119.38468966210954,
              -5.343245784035673
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "ixchel",
        "name": "Ixchel",
        "color": "#6ac5ae",
        "feature_id": "ixchel-0",
        "coordinates": [
          -87.0022100738826,
          20.505433864493767
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -87.07026408326414,
              20.629718018017286
            ],
            [
              -87.01848911994196,
              20.515592009193767
            ],
            [
              -86.87916421864102,
              20.428653099962673
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "dos-continentes-l-ll",
        "name": "DOS CONTINENTES l & ll",
        "color": "#31b2ac",
        "feature_id": "dos-continentes-l-ll-0",
        "coordinates": [
          -5.317170136013715,
          35.89240754440482
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -5.604462809431084,
              36.014276783200074
            ],
            [
              -5.399921938704722,
              35.96797434759347
            ],
            [
              -5.334736438007837,
              35.89388140994376
            ],
            [
              -5.323321992968886,
              35.889837117183504
            ],
            [
              -5.313642702950744,
              35.89388140994376
            ],
            [
              -5.287422018400775,
              36.05897312258671
            ],
            [
              -5.325166132287478,
              36.210325320952876
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "crosschannel-fibre",
        "name": "CrossChannel Fibre",
        "color": "#547cbe",
        "feature_id": "crosschannel-fibre-0",
        "coordinates": [
          0.2589884282093351,
          50.26698212513418
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -0.134425668831739,
              50.82847935144261
            ],
            [
              0.000074235887269,
              50.597677199053464
            ],
            [
              0.450073917103168,
              50.02292045625484
            ],
            [
              0.797773670193607,
              49.87730699116304
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "x-link-submarine-cable",
        "name": "X-Link Submarine Cable",
        "color": "#bb3726",
        "feature_id": "x-link-submarine-cable-0",
        "coordinates": [
          -58.426836847760526,
          10.006678144787003
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -58.15486456660838,
              6.804293299288759
            ],
            [
              -57.93738472067315,
              7.298762754459602
            ],
            [
              -58.49988432219324,
              10.410816505402636
            ],
            [
              -59.17488384401726,
              12.615395567393307
            ],
            [
              -59.536383587927375,
              13.062625710060047
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "maldives-sri-lanka-cable-msc",
        "name": "Maldives Sri Lanka Cable (MSC)",
        "color": "#4bb648",
        "feature_id": "maldives-sri-lanka-cable-msc-0",
        "coordinates": [
          76.75325063896727,
          5.408740500220119
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              73.5403521392646,
              4.212345781871875
            ],
            [
              74.25002163652775,
              4.389285926050889
            ],
            [
              79.20001812990384,
              6.405200795356109
            ],
            [
              79.86681765753698,
              6.833088156653076
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "eviny-digital",
        "name": "Eviny Digital",
        "color": "#ca3693",
        "feature_id": "eviny-digital-0",
        "coordinates": [
          5.510618083927852,
          59.58239554653858
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              5.332770458155238,
              60.390723705809705
            ],
            [
              5.400120410443975,
              60.35426474321949
            ],
            [
              5.46882036177621,
              60.29859553499119
            ],
            [
              5.512570330783177,
              60.18692233975918
            ],
            [
              5.456320370631232,
              60.07486799642308
            ],
            [
              5.456325370031891,
              59.94835018490326
            ],
            [
              5.568820290339431,
              59.89666699242801
            ],
            [
              5.568820290339431,
              59.78362399299567
            ],
            [
              5.540705310256158,
              59.68439114114261
            ],
            [
              5.512575330183864,
              59.64177978429328
            ],
            [
              5.498517840142142,
              59.613338746875456
            ],
            [
              5.512575330183864,
              59.584873612944676
            ],
            [
              5.4844453501114,
              59.549258289422255
            ],
            [
              5.456320370035314,
              59.52787100202244
            ],
            [
              5.4844453501114,
              59.47077184460525
            ],
            [
              5.568825290335838,
              59.413568401417535
            ],
            [
              5.596945270415318,
              59.37061568324745
            ],
            [
              5.596945270415318,
              59.327600860309644
            ],
            [
              5.568820290339431,
              59.29889402722176
            ],
            [
              5.524480322346278,
              59.279267580371936
            ],
            [
              5.850070091695329,
              59.1068949571908
            ],
            [
              5.850070091695329,
              59.049084544661525
            ],
            [
              5.730770176208466,
              58.97082148666851
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tokelau-submarine-cable",
        "name": "Tokelau Submarine Cable",
        "color": "#7f6fb2",
        "feature_id": "tokelau-submarine-cable-0",
        "coordinates": [
          -172.04578978025606,
          -9.178183024905252
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -172.51090355571756,
              -8.55909289982796
            ],
            [
              -172.46230359014612,
              -8.846050186819042
            ],
            [
              -172.3498036698421,
              -9.06830600387434
            ],
            [
              -172.12480382923417,
              -9.179382545871192
            ],
            [
              -171.81150405117864,
              -9.174626307490229
            ],
            [
              -171.67480414801807,
              -9.3459320066398
            ],
            [
              -171.44980430741032,
              -9.3459320066398
            ],
            [
              -171.26150444080352,
              -9.383770856814323
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "mist",
        "name": "MIST",
        "color": "#939597",
        "feature_id": "mist-0",
        "coordinates": [
          83.31787647150728,
          2.463243273823508
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              101.25000250948804,
              2.199296754027785
            ],
            [
              101.36250242919627,
              2.592701464601845
            ],
            [
              101.44360237174422,
              2.751228763607116
            ]
          ],
          [
            [
              97.42500521915215,
              5.845915088460174
            ],
            [
              99.00000410340803,
              6.405200795356109
            ],
            [
              100.0661133481664,
              6.613518860854185
            ]
          ],
          [
            [
              72.87590260996691,
              19.076074257285313
            ],
            [
              71.55002354923184,
              16.965102599435824
            ],
            [
              71.49513558519405,
              13.492128176464178
            ],
            [
              72.90002259288099,
              9.443204702286806
            ],
            [
              75.09513303492321,
              6.770440250104599
            ],
            [
              78.69513048464998,
              3.940475772228723
            ],
            [
              79.65001781052402,
              3.416559618323163
            ],
            [
              81.00001685476795,
              3.042156042425745
            ],
            [
              85.50001366692797,
              1.918228780215685
            ],
            [
              90.00001047908799,
              2.817450442654064
            ],
            [
              92.7000085663839,
              4.837826391986653
            ],
            [
              94.27500745064,
              5.733989114150035
            ],
            [
              95.40000665367998,
              6.181557032537114
            ],
            [
              97.42500521915215,
              5.845915088460174
            ],
            [
              98.32500458158412,
              5.286069860821101
            ],
            [
              99.11250402371215,
              4.613591578862867
            ],
            [
              100.12500330644806,
              3.266814816815753
            ],
            [
              101.25000250948804,
              2.199296754027785
            ],
            [
              102.15000187192001,
              1.862009426867957
            ],
            [
              102.68279723997185,
              1.614492576238035
            ],
            [
              103.34065102845304,
              1.412069619499448
            ],
            [
              103.50000091556822,
              1.383728077246843
            ],
            [
              103.64609081207684,
              1.338585852071589
            ]
          ],
          [
            [
              81.00001685476795,
              3.042156042425745
            ],
            [
              82.57501573902388,
              5.51004741056704
            ],
            [
              82.80001557963189,
              7.744876956882223
            ],
            [
              82.35001589841599,
              9.524411345019587
            ],
            [
              81.45001653598385,
              11.294709319565555
            ],
            [
              80.2429873910547,
              13.063853101883296
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "la-gomera-el-hierro",
        "name": "La Gomera-El Hierro",
        "color": "#82489c",
        "feature_id": "la-gomera-el-hierro-0",
        "coordinates": [
          -17.406432376031034,
          27.779808227112085
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -17.10821364444101,
              28.08794070557161
            ],
            [
              -17.08851365839672,
              27.946250262571624
            ],
            [
              -17.437413411232882,
              27.763588526057674
            ],
            [
              -17.774913172144863,
              27.763588526057674
            ],
            [
              -17.890913089969388,
              27.820205885436337
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tegopa",
        "name": "TEGOPA",
        "color": "#397cbf",
        "feature_id": "tegopa-0",
        "coordinates": [
          -17.104673858647516,
          28.177643715718204
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -17.765193179030604,
              28.663271035325494
            ],
            [
              -17.58982330326421,
              28.663271035325494
            ],
            [
              -17.364803462670494,
              28.56451109930117
            ],
            [
              -17.212413570624847,
              28.359233526108653
            ],
            [
              -17.09375632253179,
              28.159242763304235
            ],
            [
              -17.10821364444101,
              28.08794070557161
            ],
            [
              -16.976044634742863,
              28.045558044929322
            ],
            [
              -16.76241388940886,
              27.91280889046331
            ],
            [
              -16.537414048800827,
              27.91280889046331
            ],
            [
              -16.48116408864888,
              27.962503359972544
            ],
            [
              -16.51801406254387,
              28.059088061264703
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tenerife-la-palma",
        "name": "Tenerife-La Palma",
        "color": "#cd1875",
        "feature_id": "tenerife-la-palma-0",
        "coordinates": [
          -17.153280432843218,
          28.689992052050172
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -16.586414014088774,
              28.3975939856212
            ],
            [
              -16.76241388940886,
              28.55704546571141
            ],
            [
              -17.364803462670494,
              28.761938001940052
            ],
            [
              -17.58982330326421,
              28.761938001940052
            ],
            [
              -17.765193179030604,
              28.663271035325494
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tenerife-gran-canaria",
        "name": "Tenerife-Gran Canaria",
        "color": "#368ccb",
        "feature_id": "tenerife-gran-canaria-0",
        "coordinates": [
          -16.114966533512558,
          28.154948775403653
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -15.694014646272876,
              28.14994322532678
            ],
            [
              -15.862414526976806,
              28.21058804973515
            ],
            [
              -16.31241420819279,
              28.111449436442342
            ],
            [
              -16.538584047971995,
              28.04674170420907
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "candalta",
        "name": "CANDALTA",
        "color": "#f0a51f",
        "feature_id": "candalta-0",
        "coordinates": [
          -16.008811791029615,
          28.225523187644825
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -16.371744166162927,
              28.355537519987053
            ],
            [
              -16.199914287888873,
              28.26014491451133
            ],
            [
              -15.974914447280838,
              28.111449436442342
            ],
            [
              -15.749914606672803,
              28.012130816282678
            ],
            [
              -15.699964642057836,
              27.99976141196052
            ]
          ],
          [
            [
              -16.371744166162927,
              28.355537519987053
            ],
            [
              -16.199914287888873,
              28.309722768786855
            ],
            [
              -15.974914447280838,
              28.21058804973515
            ],
            [
              -15.749914606672803,
              28.06177953770711
            ],
            [
              -15.699964642057836,
              27.99976141196052
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "transcan-2",
        "name": "TRANSCAN-2",
        "color": "#7fae40",
        "feature_id": "transcan-2-0",
        "coordinates": [
          -14.851191350219779,
          27.854638516814504
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -14.358415593019743,
              28.04871862868299
            ],
            [
              -14.512415483924855,
              27.89623898952877
            ],
            [
              -15.187415005748875,
              27.813351446514247
            ],
            [
              -15.390735173618907,
              27.89546221316101
            ]
          ],
          [
            [
              -13.547572416832708,
              28.959501465241516
            ],
            [
              -13.612416120896796,
              28.65581241773313
            ],
            [
              -13.862220631432962,
              28.496470563064292
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "converge-domestic-submarine-cable-network-cdscn",
        "name": "Converge Domestic Submarine Cable Network (CDSCN)",
        "color": "#a24a9c",
        "feature_id": "converge-domestic-submarine-cable-network-cdscn-0",
        "coordinates": [
          120.91814381110439,
          11.95585820711483
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              123.29490876766968,
              9.246348603115479
            ],
            [
              123.4687367695283,
              9.08033076823304
            ],
            [
              123.74998657028831,
              8.91365715578047
            ],
            [
              124.53748601241617,
              8.635699417327544
            ],
            [
              124.63191594552123,
              8.454147535358373
            ]
          ],
          [
            [
              123.91358645439269,
              9.622537095709703
            ],
            [
              124.19998625150438,
              9.524411345019587
            ],
            [
              124.64998593272028,
              9.524411345019587
            ],
            [
              125.09998561393635,
              9.413444258507468
            ],
            [
              125.32498545454442,
              9.246926926415313
            ],
            [
              125.42068851174744,
              8.96893416150806
            ]
          ],
          [
            [
              123.69139286179657,
              10.238265933645806
            ],
            [
              123.74998657028831,
              10.078698006650882
            ],
            [
              123.74998657028831,
              9.801670473167405
            ],
            [
              123.87820913570422,
              9.675391878909068
            ]
          ],
          [
            [
              123.4128774340995,
              10.483708779671844
            ],
            [
              123.52498672968031,
              10.410816505402636
            ],
            [
              123.63524055782548,
              10.376237129155953
            ]
          ],
          [
            [
              122.58944051743,
              10.786617082837381
            ],
            [
              122.84998720785634,
              10.742581675476316
            ],
            [
              122.96776056192462,
              10.739415483408436
            ]
          ],
          [
            [
              124.00760357528995,
              11.045900953925155
            ],
            [
              124.1341362981531,
              10.932011625131736
            ],
            [
              124.42498609211222,
              10.853089690745378
            ],
            [
              124.61277595908044,
              11.006888020676286
            ]
          ],
          [
            [
              123.61668588346967,
              12.366641586121915
            ],
            [
              123.525036729645,
              12.505588131780542
            ],
            [
              123.35623684922442,
              12.615395567393307
            ],
            [
              123.01873708831224,
              12.834868817846598
            ],
            [
              122.84998720785634,
              13.054150695298716
            ],
            [
              122.84998720785634,
              13.273238157547667
            ],
            [
              122.95067073028108,
              13.546888070207757
            ]
          ],
          [
            [
              121.52772251956102,
              12.586423162176118
            ],
            [
              121.61248808451225,
              12.395734000022884
            ],
            [
              121.78641796189484,
              12.158056685859094
            ],
            [
              121.94481206784073,
              11.949266020482952
            ],
            [
              122.06248776572832,
              11.95585820711483
            ],
            [
              122.39998752664027,
              11.845776373625682
            ],
            [
              122.7499872786972,
              11.583202180445141
            ],
            [
              122.96248712816029,
              11.735650161405744
            ],
            [
              123.29998688907224,
              11.735650161405744
            ],
            [
              123.4687367695283,
              11.95585820711483
            ],
            [
              123.50906877220675,
              12.218076875737609
            ],
            [
              123.52498672968031,
              11.95585820711483
            ],
            [
              123.82048402610715,
              11.537441867288294
            ],
            [
              123.9363145632918,
              11.083374737000184
            ]
          ],
          [
            [
              121.44881632545892,
              13.809939475817998
            ],
            [
              121.61248808451225,
              13.492128176464178
            ],
            [
              121.72498800481614,
              13.054150695298716
            ],
            [
              121.72498800481614,
              12.725155923562937
            ],
            [
              121.52772251956102,
              12.586423162176118
            ],
            [
              121.4999881642083,
              12.395734000022884
            ],
            [
              121.38748824390436,
              12.175838310300918
            ],
            [
              120.93748856268829,
              11.95585820711483
            ],
            [
              120.37498896116833,
              11.95585820711483
            ],
            [
              120.20076908458739,
              12.005434247136094
            ],
            [
              120.37498896116833,
              11.735650161405744
            ],
            [
              119.92498927995226,
              11.073982781226704
            ],
            [
              119.50037958074992,
              10.820000490489491
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "guadeloupe-cable-des-iles-du-sud-gcis",
        "name": "Guadeloupe Cable des Iles du Sud (GCIS)",
        "color": "#31ac49",
        "feature_id": "guadeloupe-cable-des-iles-du-sud-gcis-0",
        "coordinates": [
          -61.3504426727622,
          16.113973083936457
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -61.49826219811416,
              15.98876894276924
            ],
            [
              -61.564677072940256,
              16.042456277649705
            ]
          ],
          [
            [
              -61.199882409489234,
              16.21038241802077
            ],
            [
              -61.274442903544895,
              16.251075987819178
            ]
          ],
          [
            [
              -61.36863228994521,
              16.10232559580288
            ],
            [
              -61.31912060626978,
              15.953463637141539
            ]
          ],
          [
            [
              -61.578837219159084,
              15.87297951222115
            ],
            [
              -61.49826219811416,
              15.98876894276924
            ],
            [
              -61.36863228994521,
              16.10232559580288
            ],
            [
              -61.199882409489234,
              16.21038241802077
            ],
            [
              -61.07385710814174,
              16.303571371032774
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "rockabill",
        "name": "Rockabill",
        "color": "#35b44a",
        "feature_id": "rockabill-0",
        "coordinates": [
          -4.571461647951509,
          53.76256062499536
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -6.114621432404959,
              53.49317504982963
            ],
            [
              -5.624921779312757,
              53.669053535347956
            ],
            [
              -4.499922576272752,
              53.768910566668154
            ],
            [
              -3.599923213840782,
              53.768910566668154
            ],
            [
              -3.006373634316702,
              53.64793339883286
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "oman-australia-cable-oac",
        "name": "Oman Australia Cable (OAC)",
        "color": "#939597",
        "feature_id": "oman-australia-cable-oac-0",
        "coordinates": [
          57.392959292506504,
          16.597281615381036
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              60.75003120004769,
              16.53419619825962
            ],
            [
              55.01253526394785,
              16.642014062854088
            ],
            [
              54.14808587692781,
              17.095827186725558
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "oman-australia-cable-oac",
        "name": "Oman Australia Cable (OAC)",
        "color": "#41b878",
        "feature_id": "oman-australia-cable-oac-1",
        "coordinates": [
          78.17583978898486,
          -12.014780121636164
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              72.11252315015597,
              -7.73281288533596
            ],
            [
              72.337522990764,
              -7.509800774121629
            ],
            [
              72.41667293469342,
              -7.333331811022703
            ]
          ],
          [
            [
              89.1000111178482,
              -19.72952545002093
            ],
            [
              95.40000665308409,
              -13.99027116703789
            ],
            [
              96.83231563842301,
              -12.19311300919901
            ]
          ],
          [
            [
              115.85731216153286,
              -31.95344133032441
            ],
            [
              113.84999358353613,
              -31.468437004267116
            ],
            [
              112.04999485867219,
              -30.309953344646914
            ],
            [
              89.1000111178482,
              -19.72952545002093
            ],
            [
              72.11252315015597,
              -7.73281288533596
            ],
            [
              65.25002801220771,
              4.164912849976844
            ],
            [
              61.200030881263785,
              15.669513225155328
            ],
            [
              60.75003120004769,
              16.53419619825962
            ],
            [
              60.75003120004769,
              19.104405475930548
            ],
            [
              60.75003120004769,
              22.469443964829594
            ],
            [
              59.85003183761572,
              23.298598065875808
            ],
            [
              58.950032475183775,
              23.711258142484382
            ],
            [
              58.591142729424746,
              23.61510487139472
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tanjun-pandan-sungai-kakap-cable-system",
        "name": "Tanjun Pandan-Sungai Kakap Cable System",
        "color": "#939597",
        "feature_id": "tanjun-pandan-sungai-kakap-cable-system-0",
        "coordinates": [
          108.11568048125864,
          -1.2267692284729605
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              107.6628879665399,
              -2.767442755874555
            ],
            [
              107.60664800638088,
              -2.517708985005584
            ],
            [
              107.64799797708824,
              -2.237970538313903
            ],
            [
              108.11249764803219,
              -1.231315750217505
            ],
            [
              108.89999709016004,
              -0.106411275875331
            ],
            [
              109.18222689022608,
              -0.061391357195134
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "hainan-to-hong-kong-express-h2he",
        "name": "Hainan to Hong Kong Express (H2HE)",
        "color": "#64c3c7",
        "feature_id": "hainan-to-hong-kong-express-h2he-0",
        "coordinates": [
          112.54647871523943,
          20.711529826905405
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              110.76181217748294,
              20.04530796190923
            ],
            [
              111.14999549564433,
              20.304717766641232
            ],
            [
              112.83749430080022,
              20.796306105108954
            ],
            [
              113.56874378277612,
              21.635297384859456
            ],
            [
              113.68124370308007,
              21.84429407917378
            ],
            [
              113.73749366323219,
              22.10511054810837
            ]
          ],
          [
            [
              113.57675158960345,
              22.270716559591165
            ],
            [
              113.73749366323219,
              22.10511054810837
            ],
            [
              114.0749934241442,
              22.10511054810837
            ],
            [
              114.20292333351765,
              22.222050419736746
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "ketchcan1-submarine-fiber-cable-system",
        "name": "KetchCan1 Submarine Fiber Cable System",
        "color": "#bf1e5a",
        "feature_id": "ketchcan1-submarine-fiber-cable-system-0",
        "coordinates": [
          -130.96964048299532,
          54.78868595868127
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -130.3263334396628,
              54.3130644955053
            ],
            [
              -130.44358460849705,
              54.29748595281848
            ],
            [
              -130.55608452595908,
              54.330299043611234
            ],
            [
              -130.61233323705773,
              54.559258765782204
            ],
            [
              -130.72483315736176,
              54.68951871778469
            ],
            [
              -130.9498329979697,
              54.75449236881684
            ],
            [
              -131.06238415450144,
              54.94878902385568
            ],
            [
              -131.11868411319574,
              55.07780072164758
            ],
            [
              -131.23108403073118,
              55.14215100378169
            ],
            [
              -131.64783250350035,
              55.3420878350874
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "equiano",
        "name": "Equiano",
        "color": "#7e2c18",
        "feature_id": "equiano-0",
        "coordinates": [
          -20.699911100048894,
          12.475163784729318
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              1.575073120143173,
              0.793562652607278
            ],
            [
              1.687573039851372,
              3.828234303320954
            ],
            [
              1.575073120143173,
              5.061986954416028
            ],
            [
              1.2278033661525,
              6.126307297218631
            ]
          ],
          [
            [
              7.650068815963351,
              -13.99027116703789
            ],
            [
              -3.599923214436672,
              -15.296387760621855
            ],
            [
              -5.711521717964501,
              -15.918564131427416
            ]
          ],
          [
            [
              1.575073120143173,
              0.793562652607278
            ],
            [
              2.250072641967279,
              1.580886840914019
            ],
            [
              3.600071685615319,
              4.164912849976844
            ],
            [
              3.423511810692077,
              6.439066911484701
            ]
          ],
          [
            [
              9.00006786020731,
              -23.493922445897766
            ],
            [
              13.05006499115126,
              -22.665969967794723
            ],
            [
              14.533463940297622,
              -22.68542973223063
            ]
          ],
          [
            [
              18.445861168718807,
              -33.72721819637752
            ],
            [
              17.55006180331145,
              -33.74264465652948
            ],
            [
              16.65006244087931,
              -33.55534420877598
            ],
            [
              15.525063237839305,
              -32.61276000573585
            ],
            [
              13.50006467236733,
              -30.309953344646914
            ],
            [
              9.00006786020731,
              -23.493922445897766
            ],
            [
              7.650068816559269,
              -18.026426383713385
            ],
            [
              7.650068815963351,
              -13.99027116703789
            ],
            [
              8.325068338383204,
              -10.620064860363328
            ],
            [
              7.650068816559269,
              -6.616650693475464
            ],
            [
              7.200069135343199,
              -4.825692499217524
            ],
            [
              4.050071366831219,
              -1.231315750217505
            ],
            [
              2.250072641967279,
              1.580886840914019
            ]
          ],
          [
            [
              1.575073120143173,
              0.793562652607278
            ],
            [
              0.225074076495304,
              -0.093411304877662
            ],
            [
              -3.599923213840782,
              -1.681168935905106
            ],
            [
              -10.799918113296798,
              -1.681168935905106
            ],
            [
              -14.849915244240833,
              2.367912558705314
            ],
            [
              -19.349912056400854,
              8.635699417327544
            ],
            [
              -20.699911100048894,
              11.735650161405744
            ],
            [
              -20.699911100048894,
              19.952622905164304
            ],
            [
              -19.84991170219638,
              22.884654113882362
            ],
            [
              -19.12491221579282,
              27.763588526057674
            ],
            [
              -17.999913013348873,
              30.25570294203996
            ],
            [
              -17.099913650320843,
              31.67051304708704
            ],
            [
              -13.949915881808863,
              33.93964008831958
            ],
            [
              -10.349918432080813,
              38.034173900641946
            ],
            [
              -9.56241898995276,
              38.29952060596935
            ],
            [
              -9.102749315587062,
              38.443079483141986
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "south-pacific-cable-system-spscmistral",
        "name": "South Pacific Cable System (SPSC)/Mistral",
        "color": "#d18e29",
        "feature_id": "south-pacific-cable-system-spscmistral-0",
        "coordinates": [
          -83.51924740133123,
          -9.94094004567475
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -86.39986455818149,
              -2.430680261964397
            ],
            [
              -84.14986615150539,
              -2.580536704984041
            ],
            [
              -81.009068376476,
              -2.228447783119123
            ]
          ],
          [
            [
              -76.4998715708334,
              -18.453813775777263
            ],
            [
              -72.89987412110536,
              -19.30538407236122
            ],
            [
              -70.3067559580946,
              -18.473543073651314
            ]
          ],
          [
            [
              -82.79986710785735,
              -11.50333384598423
            ],
            [
              -79.1998696581294,
              -12.603512104971372
            ],
            [
              -76.87428130559798,
              -12.278420041799535
            ]
          ],
          [
            [
              -90.82222367756134,
              13.934797333208934
            ],
            [
              -91.57486089156936,
              13.054150695298716
            ],
            [
              -92.24986041339346,
              9.524411345019587
            ],
            [
              -90.89986137034151,
              3.715978119297972
            ],
            [
              -87.74986360123408,
              0.568578852526286
            ],
            [
              -86.39986455818149,
              -2.430680261964397
            ],
            [
              -85.04986551393736,
              -6.616650693475464
            ],
            [
              -82.79986710785735,
              -11.50333384598423
            ],
            [
              -79.1998696581294,
              -15.441023659568003
            ],
            [
              -76.4998715708334,
              -18.453813775777263
            ],
            [
              -74.02487332414536,
              -27.153831285391675
            ],
            [
              -72.22487459928134,
              -31.851465665577166
            ],
            [
              -71.62043502747201,
              -33.0455412324782
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "n0r5ke-viking",
        "name": "N0r5ke Viking",
        "color": "#dabb26",
        "feature_id": "n0r5ke-viking-0",
        "coordinates": [
          5.651900351431948,
          62.38446699850115
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              9.637665064777536,
              63.61946636684087
            ],
            [
              9.815742282376135,
              63.576280591644036
            ],
            [
              9.90006722263928,
              63.48851288982226
            ],
            [
              10.293816943703206,
              63.46341549544039
            ],
            [
              10.392059061607597,
              63.43100204742538
            ]
          ],
          [
            [
              7.160713694473003,
              62.73722946432855
            ],
            [
              7.425068975951234,
              62.66077045886655
            ],
            [
              7.681611762963911,
              62.563956240896886
            ]
          ],
          [
            [
              9.663153327971344,
              63.68839703736208
            ],
            [
              9.618817421879271,
              63.67623095645985
            ],
            [
              9.637665064777536,
              63.61946636684087
            ],
            [
              9.112567779915338,
              63.542885601288305
            ],
            [
              8.975247877194136,
              63.50462478492514
            ],
            [
              8.693958076462593,
              63.40817512674508
            ],
            [
              8.380908298229883,
              63.36151643295429
            ],
            [
              8.093378501918721,
              63.301036488500756
            ],
            [
              7.875068656571415,
              63.17294695953282
            ],
            [
              7.72966876017,
              63.11078338854429
            ],
            [
              7.762568738651254,
              63.02023453756422
            ],
            [
              7.650068815963351,
              62.91797878525176
            ],
            [
              7.425068974759427,
              62.81536487879316
            ],
            [
              7.160713694473003,
              62.73722946432855
            ],
            [
              6.862569374431217,
              62.76392332890575
            ],
            [
              6.637569533823182,
              62.738168854300056
            ],
            [
              6.465792311761874,
              62.613002975496556
            ],
            [
              6.300069772911229,
              62.66077045886655
            ],
            [
              6.187569852607282,
              62.609058908203394
            ],
            [
              6.174386268196514,
              62.471487316816024
            ],
            [
              5.962570011999247,
              62.50536500000058
            ],
            [
              5.850070091695329,
              62.453382412508034
            ],
            [
              5.400070410479259,
              62.29689073879055
            ],
            [
              5.287570488983533,
              62.244545161699875
            ],
            [
              5.343820449731396,
              62.13958079203451
            ],
            [
              5.522335948269301,
              62.03599154304587
            ],
            [
              5.456320370035314,
              61.9814494856501
            ],
            [
              5.49850784014933,
              61.93554024603813
            ],
            [
              5.062570648375498,
              61.9814494856501
            ],
            [
              4.837570808959242,
              61.92855599360783
            ],
            [
              4.725070888655324,
              61.822493741892
            ],
            [
              4.725070888655324,
              61.71606370959984
            ],
            [
              4.97585196040373,
              61.596307262996845
            ],
            [
              4.725070888655324,
              61.5557270894935
            ],
            [
              4.725070888655324,
              61.448373642843535
            ],
            [
              4.950070729263189,
              61.23255301306608
            ],
            [
              5.293429861024407,
              61.172244368269645
            ],
            [
              4.837570808959242,
              61.01524141470435
            ],
            [
              4.725070888655324,
              60.79643169535018
            ],
            [
              4.855539546229977,
              60.621972493449306
            ],
            [
              5.062570649567277,
              60.57611674038779
            ],
            [
              5.287570490175341,
              60.46539258705809
            ],
            [
              5.332770458155238,
              60.390723705809705
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "celtic-norse",
        "name": "Celtic Norse",
        "color": "#939597",
        "feature_id": "celtic-norse-0",
        "coordinates": [
          -2.3702052201275023,
          61.46307640287898
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              10.216466998498731,
              63.33348031552026
            ],
            [
              9.90006722263928,
              63.475978110242295
            ],
            [
              9.787617302300049,
              63.576280591644036
            ],
            [
              9.675067382031244,
              63.65127632517789
            ],
            [
              9.45006754142321,
              63.62629973033458
            ],
            [
              9.337567621119263,
              63.65127632517789
            ],
            [
              9.225067700815345,
              63.72607438667927
            ],
            [
              9.00006786020731,
              63.82549832634285
            ],
            [
              8.775068019599274,
              63.87507906940428
            ],
            [
              7.875068657167304,
              63.72607438667927
            ],
            [
              6.750069454127299,
              63.72607438667927
            ],
            [
              0.450073917103168,
              62.29689073879055
            ],
            [
              -3.149923532624712,
              61.23255301306608
            ],
            [
              -5.399921938704722,
              60.35428947498107
            ],
            [
              -7.649920344784732,
              59.6796637072089
            ],
            [
              -8.999919388432772,
              58.75856894488285
            ],
            [
              -10.349918432080813,
              57.571890279005004
            ],
            [
              -10.799918113296798,
              56.345222926819986
            ],
            [
              -10.349918432080813,
              55.33458061322915
            ],
            [
              -9.449919069648843,
              54.559258765782204
            ],
            [
              -9.232149223918952,
              54.207113520678774
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "mandji-fiber-optic-cable",
        "name": "Mandji Fiber Optic Cable",
        "color": "#939597",
        "feature_id": "mandji-fiber-optic-cable-0",
        "coordinates": [
          9.225067700815345,
          1.0738514996111803
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              9.341367618427313,
              1.175232424070032
            ],
            [
              9.225067700815345,
              1.131014326431796
            ],
            [
              9.225067700815345,
              1.01853421661562
            ],
            [
              9.31956763387089,
              0.93484644390108
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "okinawa-cellular-cable",
        "name": "Okinawa Cellular Cable",
        "color": "#504a9f",
        "feature_id": "okinawa-cellular-cable-0",
        "coordinates": [
          128.68334289984077,
          29.27169533674899
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              127.9772835756315,
              26.59157896846108
            ],
            [
              127.79998370123226,
              26.562513149236622
            ],
            [
              127.57498386062443,
              26.763586569619832
            ],
            [
              127.68748378092832,
              27.16466581281361
            ],
            [
              128.47498322305617,
              28.359233526108653
            ],
            [
              128.6999830636644,
              29.34456698948989
            ],
            [
              129.1499827448803,
              30.514495959759188
            ],
            [
              129.59998242609637,
              31.28673881439167
            ],
            [
              130.04998210731227,
              31.574717129337273
            ],
            [
              130.55708174807816,
              31.59652468890519
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "cabo-verde-telecom-domestic-submarine-cable-phase-2",
        "name": "Cabo Verde Telecom Domestic Submarine Cable Phase 2",
        "color": "#364da0",
        "feature_id": "cabo-verde-telecom-domestic-submarine-cable-phase-2-0",
        "coordinates": [
          -24.56759067138637,
          16.084567433773145
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -25.059408011740402,
              17.019177136056072
            ],
            [
              -25.03115803175291,
              16.911292789233244
            ],
            [
              -25.060796682631604,
              16.82344518100311
            ],
            [
              -25.077407998988974,
              16.72458551142151
            ],
            [
              -25.077407998988974,
              16.616813850378605
            ],
            [
              -23.962408788864877,
              15.452760959322148
            ],
            [
              -23.751080813571633,
              15.276859352491755
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "cabo-verde-telecom-domestic-submarine-cable-phase-3",
        "name": "Cabo Verde Telecom Domestic Submarine Cable Phase 3",
        "color": "#21b595",
        "feature_id": "cabo-verde-telecom-domestic-submarine-cable-phase-3-0",
        "coordinates": [
          -24.552897138117146,
          15.100182190335289
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -22.919050152989968,
              16.174753793884634
            ],
            [
              -23.062409426432907,
              15.994209911785873
            ],
            [
              -23.23110930692428,
              15.669513225155328
            ],
            [
              -23.287409267040857,
              15.23578178303569
            ],
            [
              -23.209772603289423,
              15.12991920861748
            ],
            [
              -23.287409267040857,
              15.018578573757566
            ],
            [
              -23.521209101414883,
              14.923035560171769
            ],
            [
              -23.512409107648892,
              14.801154224791475
            ],
            [
              -23.624909027952924,
              14.692360031374477
            ],
            [
              -23.849908868560874,
              14.583511645118676
            ],
            [
              -24.299908549776944,
              14.692360031374477
            ],
            [
              -24.412408470080948,
              14.746763925027949
            ],
            [
              -24.50093379799378,
              14.897153768217777
            ],
            [
              -24.581158350536953,
              14.85553088506029
            ],
            [
              -24.695416082095676,
              14.872992709661645
            ],
            [
              -24.637408310688897,
              15.018578573757566
            ],
            [
              -24.52490839038498,
              15.127208002058438
            ],
            [
              -24.299908549776944,
              15.23578178303569
            ],
            [
              -24.07490870916891,
              15.344299555517438
            ],
            [
              -23.751080813571633,
              15.276859352491755
            ],
            [
              -24.07490870916891,
              15.452760959322148
            ],
            [
              -25.189907919293006,
              16.616813850378605
            ],
            [
              -25.189907919293006,
              16.72458551142151
            ],
            [
              -25.060796682631604,
              16.82344518100311
            ],
            [
              -25.087407991904882,
              16.911292789233244
            ],
            [
              -25.059408011740402,
              17.019177136056072
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "cabo-verde-telecom-domestic-submarine-cable-phase-1",
        "name": "Cabo Verde Telecom Domestic Submarine Cable Phase 1",
        "color": "#b32c57",
        "feature_id": "cabo-verde-telecom-domestic-submarine-cable-phase-1-0",
        "coordinates": [
          -23.099947086292758,
          16.626458747530876
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -25.060796682631604,
              16.82344518100311
            ],
            [
              -24.97490807160088,
              16.642014062854088
            ],
            [
              -24.637408310688897,
              16.53419619825962
            ],
            [
              -24.357135071737133,
              16.56583652167643
            ],
            [
              -24.187408629472912,
              16.426318069774425
            ],
            [
              -23.39990918734489,
              16.53419619825962
            ],
            [
              -22.935309907096382,
              16.677097964299264
            ],
            [
              -22.949909506128904,
              16.426318069774425
            ],
            [
              -22.919050152989968,
              16.174753793884634
            ],
            [
              -23.17490934673694,
              15.994209911785873
            ],
            [
              -23.343659227192916,
              15.669513225155328
            ],
            [
              -23.39990918734489,
              15.23578178303569
            ],
            [
              -23.39990918734489,
              15.018578573757566
            ],
            [
              -23.521209101414883,
              14.923035560171769
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "miyazaki-okinawa-cable-moc",
        "name": "Miyazaki-Okinawa Cable (MOC)",
        "color": "#b0d135",
        "feature_id": "miyazaki-okinawa-cable-moc-0",
        "coordinates": [
          130.61608243107938,
          28.675897447067
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              131.29461013185616,
              32.09764082018094
            ],
            [
              131.73748091187227,
              31.67051304708704
            ],
            [
              131.84998083217639,
              31.28673881439167
            ],
            [
              131.39998115096031,
              29.540507745394393
            ],
            [
              128.6999830636644,
              26.562513149236622
            ],
            [
              128.02498354184027,
              26.159307970773796
            ],
            [
              127.68084378563216,
              26.212414126750332
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "japan-information-highway-jih",
        "name": "Japan Information Highway (JIH)",
        "color": "#6ebe44",
        "feature_id": "japan-information-highway-jih-0",
        "coordinates": [
          139.17757553149406,
          34.38447406955143
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              132.74998019460836,
              31.094262827639596
            ],
            [
              132.07498067278445,
              31.67051304708704
            ],
            [
              131.29461013185616,
              32.09764082018094
            ]
          ],
          [
            [
              127.68084378563216,
              26.212414126750332
            ],
            [
              128.02498354184027,
              26.058287560298936
            ],
            [
              128.6999830636644,
              26.361086325391653
            ],
            [
              131.39998115096031,
              29.34456698948989
            ],
            [
              132.74998019460836,
              31.094262827639596
            ],
            [
              134.99997860068837,
              31.478822672736243
            ],
            [
              136.57497748494447,
              32.24321001626265
            ],
            [
              136.79997732555248,
              32.81231878328768
            ],
            [
              136.87399727311598,
              34.33682825203164
            ],
            [
              137.69997668798445,
              34.03292178996395
            ],
            [
              138.59997605041642,
              34.12610104005762
            ],
            [
              139.4999754122525,
              34.52869067055079
            ],
            [
              139.92185011339248,
              34.73696430511873
            ],
            [
              139.99216256358258,
              34.85244570884623
            ],
            [
              139.9548550906076,
              34.97657002902224
            ],
            [
              140.06247501377246,
              34.99080971857586
            ],
            [
              140.3437248151284,
              35.006169091971316
            ],
            [
              140.7374745355966,
              35.1749317874752
            ],
            [
              140.84997445649643,
              35.419780517080454
            ],
            [
              140.96247437680037,
              35.78566189952613
            ],
            [
              140.84997445649643,
              36.05897312258671
            ],
            [
              140.26989674242904,
              36.3421943691647
            ],
            [
              140.84997445649643,
              36.14986678681771
            ],
            [
              141.2999741377125,
              36.42191605012607
            ],
            [
              141.7499738189284,
              36.87321951208918
            ],
            [
              141.7499738189284,
              37.58978657360316
            ],
            [
              141.2999741377125,
              38.034173900641946
            ],
            [
              140.75817764652615,
              38.31438700373923
            ],
            [
              141.2999741377125,
              38.12273010839229
            ],
            [
              141.7499738189284,
              38.21117903702327
            ],
            [
              142.19997350014447,
              38.651811712711236
            ],
            [
              142.4249733407523,
              40.04369219282995
            ],
            [
              142.19997350014447,
              41.0693404382163
            ],
            [
              141.7499738189284,
              41.40772623743587
            ],
            [
              141.2999741377125,
              41.57626183009805
            ],
            [
              140.84997445649643,
              41.57626183009805
            ],
            [
              140.39997477528036,
              41.40772623743587
            ],
            [
              139.94997509406446,
              41.23875232896668
            ],
            [
              139.8374751737605,
              41.15395052136796
            ],
            [
              139.61247533315233,
              40.89949091487156
            ],
            [
              139.49997541284839,
              40.387320290775165
            ],
            [
              139.7249752534564,
              40.04369219282995
            ],
            [
              140.3436271589475,
              39.692011436751784
            ]
          ],
          [
            [
              139.8374751737605,
              41.15395052136796
            ],
            [
              139.38746049255505,
              41.57626183009805
            ],
            [
              139.0499757316325,
              42.079235618164205
            ],
            [
              139.0499757316325,
              42.74371346443661
            ],
            [
              139.27497557224055,
              43.07331078300331
            ],
            [
              140.39997477528036,
              43.564400497117504
            ],
            [
              140.84997445649643,
              43.564400497117504
            ],
            [
              141.31540412678189,
              43.171175265998414
            ]
          ],
          [
            [
              139.94997509406446,
              41.23875232896668
            ],
            [
              139.61247533315233,
              41.57626183009805
            ],
            [
              139.27497557224055,
              42.079235618164205
            ],
            [
              139.27497557224055,
              42.74371346443661
            ],
            [
              139.49997541284839,
              43.07331078300331
            ],
            [
              140.39997477528036,
              43.48282788090381
            ],
            [
              140.84997445649643,
              43.48282788090381
            ],
            [
              141.31540412678189,
              43.171175265998414
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "lazaro-cardenas-manzanillo-santiago-submarine-cable-system-lcmsscs",
        "name": "Lazaro Cardenas-Manzanillo Santiago Submarine Cable System (LCMSSCS)",
        "color": "#4cb748",
        "feature_id": "lazaro-cardenas-manzanillo-santiago-submarine-cable-system-lcmsscs-0",
        "coordinates": [
          -103.53391735089956,
          18.05407066325256
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -102.19435336862126,
              17.956784079019087
            ],
            [
              -102.14985340014547,
              17.716802179008738
            ],
            [
              -101.8123536392335,
              17.609605913225096
            ],
            [
              -101.61055378219038,
              17.664117239853425
            ]
          ],
          [
            [
              -104.30815187118662,
              19.085789444146997
            ],
            [
              -104.28735188592155,
              18.67864702215462
            ],
            [
              -103.9498521250095,
              18.251816319028308
            ],
            [
              -103.04985276257753,
              17.823934412537824
            ],
            [
              -102.59985308136154,
              17.823934412537824
            ],
            [
              -102.19435336862126,
              17.956784079019087
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "fibra-optica-al-pacfico",
        "name": "Fibra Optica al Pacífico",
        "color": "#7e3a96",
        "feature_id": "fibra-optica-al-pacfico-0",
        "coordinates": [
          -75.4377408454582,
          -15.911012613246953
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -76.87428130559798,
              -12.278420041799535
            ],
            [
              -77.39987093329539,
              -12.822995625629687
            ],
            [
              -77.39987093326538,
              -13.698987269610853
            ],
            [
              -76.04987188961734,
              -15.441023659568003
            ],
            [
              -73.79987348353733,
              -17.16855309422607
            ],
            [
              -72.44987443988929,
              -17.59799899615561
            ],
            [
              -71.32367523769945,
              -17.641173816666765
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "batam-sarawak-internet-cable-system-basics",
        "name": "Batam Sarawak Internet Cable System (BaSICS)",
        "color": "#3660ac",
        "feature_id": "batam-sarawak-internet-cable-system-basics-0",
        "coordinates": [
          107.23518747604223,
          2.212046232388502
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              110.29359610292167,
              1.675048741325099
            ],
            [
              110.02499629320002,
              1.918228780215685
            ],
            [
              109.57499661198418,
              2.367912558705314
            ],
            [
              106.19999900286413,
              2.143087178471944
            ],
            [
              104.73750003891215,
              1.468426767332062
            ],
            [
              104.62500011860826,
              1.369994553366951
            ],
            [
              104.28790035741284,
              1.173518198634099
            ],
            [
              104.13310046707468,
              1.173205764381919
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "eastern-light-sweden-finland-i",
        "name": "Eastern Light Sweden-Finland I",
        "color": "#944098",
        "feature_id": "eastern-light-sweden-finland-i-0",
        "coordinates": [
          22.61855165919033,
          59.731932505209926
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              18.062756752613467,
              59.332308261070324
            ],
            [
              18.90006084695932,
              59.451717318905224
            ],
            [
              20.25005989060736,
              59.56588346342974
            ],
            [
              21.375059093647366,
              59.6796637072089
            ],
            [
              22.725058137295406,
              59.736409384078485
            ],
            [
              22.96675718482345,
              59.82340942681512
            ],
            [
              23.175057818511476,
              59.736409384078485
            ],
            [
              23.85005734033541,
              59.736409384078485
            ],
            [
              24.4125569418554,
              59.849612385021544
            ],
            [
              24.932478448538234,
              60.17116346096182
            ],
            [
              25.20005638398345,
              60.07486799642308
            ],
            [
              26.10005574641542,
              60.07486799642308
            ],
            [
              26.775055268239356,
              60.29859553499119
            ],
            [
              26.883648941310668,
              60.50114056075515
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "sorsogon-samar-submarine-fiber-optical-interconnection-project-sssfoip",
        "name": "Sorsogon-Samar Submarine Fiber Optical Interconnection Project (SSSFOIP)",
        "color": "#bf3b28",
        "feature_id": "sorsogon-samar-submarine-fiber-optical-interconnection-project-sssfoip-0",
        "coordinates": [
          124.22831176599172,
          12.614974341566125
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              124.10708631731555,
              12.645255061583299
            ],
            [
              124.2281112315803,
              12.615395567393307
            ],
            [
              124.28238619313154,
              12.501390118608668
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "manatua",
        "name": "Manatua",
        "color": "#de1f29",
        "feature_id": "manatua-0",
        "coordinates": [
          -162.21224462541835,
          -20.995131543025877
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -151.8748181745139,
              -17.383402005942372
            ],
            [
              -151.87481817451416,
              -16.953454989810012
            ],
            [
              -151.74951826327774,
              -16.506319370588244
            ]
          ],
          [
            [
              -160.19981227700998,
              -20.995131543025877
            ],
            [
              -160.19981227700998,
              -20.15254378601884
            ],
            [
              -160.08731235670606,
              -19.30538407236122
            ],
            [
              -159.77491257801293,
              -18.825724826038964
            ]
          ],
          [
            [
              -159.86231251669412,
              -20.995131543025877
            ],
            [
              -159.86231251669412,
              -21.100125697241367
            ],
            [
              -159.7697125816967,
              -21.22434283051234
            ]
          ],
          [
            [
              -169.64980558254612,
              -18.026426383713385
            ],
            [
              -169.87480542315404,
              -18.453813775777263
            ],
            [
              -169.91670539347172,
              -19.016714981418488
            ]
          ],
          [
            [
              -171.76669408292253,
              -13.833489255757883
            ],
            [
              -171.44980430741003,
              -13.698987269610853
            ],
            [
              -170.77480478558613,
              -13.698987269610853
            ],
            [
              -170.0998052637621,
              -14.135775375064753
            ],
            [
              -169.64980558254612,
              -15.006817032918917
            ],
            [
              -169.64980558254612,
              -18.026426383713385
            ],
            [
              -166.4998078152263,
              -18.880139975101287
            ],
            [
              -163.79980972733412,
              -20.995131543025877
            ],
            [
              -160.19981227700998,
              -20.995131543025877
            ],
            [
              -159.86231251669412,
              -20.995131543025877
            ],
            [
              -156.59981482728193,
              -20.57441905727606
            ],
            [
              -151.8748181745139,
              -17.383402005942372
            ],
            [
              -150.97481881208193,
              -17.383402005942372
            ],
            [
              -150.07481944965014,
              -17.919416202114803
            ],
            [
              -149.51231984812995,
              -17.919416202114803
            ],
            [
              -149.32331998201923,
              -17.75044654103135
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "malta-italy-interconnector",
        "name": "Malta-Italy Interconnector",
        "color": "#bf3b28",
        "feature_id": "malta-italy-interconnector-0",
        "coordinates": [
          14.565709909689161,
          36.363815050467736
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              14.550263928396447,
              36.784772286316525
            ],
            [
              14.625063875407335,
              36.60275474032986
            ],
            [
              14.512563955103388,
              36.14986678681771
            ],
            [
              14.458763993215797,
              35.934055517066156
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "caribbean-regional-communications-infrastructure-program-carcip",
        "name": "Caribbean Regional Communications Infrastructure Program (CARCIP)",
        "color": "#a69b33",
        "feature_id": "caribbean-regional-communications-infrastructure-program-carcip-0",
        "coordinates": [
          -61.55578124830757,
          12.669103176552909
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -61.32304895989587,
              13.054150695298716
            ],
            [
              -61.26685736204357,
              13.026751030444217
            ],
            [
              -61.244682377752454,
              12.99754041050673
            ]
          ],
          [
            [
              -61.3906322743602,
              12.956082453283926
            ],
            [
              -61.278332353914564,
              12.928720698349338
            ],
            [
              -61.18718241848596,
              12.877712443605333
            ]
          ],
          [
            [
              -61.448857233113074,
              12.84262005691349
            ],
            [
              -61.39265727292572,
              12.787673734344708
            ],
            [
              -61.33898231094953,
              12.69962561864898
            ]
          ],
          [
            [
              -61.53533217185351,
              12.72891132912099
            ],
            [
              -61.478982211772404,
              12.674135433811642
            ],
            [
              -61.443192784000885,
              12.611394520710775
            ]
          ],
          [
            [
              -61.59358213058863,
              12.558545570699522
            ],
            [
              -61.53733217043667,
              12.531043226499293
            ],
            [
              -61.47859314954795,
              12.473742852030739
            ]
          ],
          [
            [
              -61.59308347979395,
              12.170910831959134
            ],
            [
              -61.477778438014084,
              12.277222829820545
            ],
            [
              -61.53338217323487,
              12.342743321766505
            ],
            [
              -61.58963213338683,
              12.395734000022884
            ],
            [
              -61.59358213058863,
              12.558545570699522
            ],
            [
              -61.53533217185351,
              12.72891132912099
            ],
            [
              -61.448857233113074,
              12.84262005691349
            ],
            [
              -61.3906322743602,
              12.956082453283926
            ],
            [
              -61.32304895989587,
              13.054150695298716
            ],
            [
              -61.20828240353856,
              13.145460938850391
            ]
          ],
          [
            [
              -61.142786746811325,
              13.373112400304763
            ],
            [
              -61.199882409489234,
              13.437424347113762
            ],
            [
              -61.25613236964119,
              13.41001911215676
            ],
            [
              -61.24088238044452,
              13.290916333074449
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "energinet-laeso-varberg",
        "name": "Energinet Laeso-Varberg",
        "color": "#373996",
        "feature_id": "energinet-laeso-varberg-0",
        "coordinates": [
          11.557203093552545,
          57.105449150617744
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              10.88716652336899,
              57.256997748271914
            ],
            [
              11.025066425679285,
              57.17764775045606
            ],
            [
              11.70006594750339,
              57.08606597586797
            ],
            [
              12.251265557028319,
              57.10529657756172
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "energinet-lyngsa-laeso",
        "name": "Energinet Lyngsa-Laeso",
        "color": "#a74234",
        "feature_id": "energinet-lyngsa-laeso-0",
        "coordinates": [
          10.701035387650052,
          57.22562397392139
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              10.514616787286627,
              57.24491811100583
            ],
            [
              10.687566664767303,
              57.22335372144116
            ],
            [
              10.88716652336899,
              57.256997748271914
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "skagenfiber-west",
        "name": "Skagenfiber West",
        "color": "#2ab34a",
        "feature_id": "skagenfiber-west-0",
        "coordinates": [
          9.90006722263928,
          58.336423287848476
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              10.016367140251418,
              59.08111069751331
            ],
            [
              10.012567142943197,
              58.93317132635269
            ],
            [
              9.90006722263928,
              58.34773024524864
            ],
            [
              9.90006722263928,
              57.812399750516676
            ],
            [
              9.959267180701602,
              57.58818834277187
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "pasuli",
        "name": "PASULI",
        "color": "#939597",
        "feature_id": "pasuli-0",
        "coordinates": [
          105.00346543425971,
          -2.1623603588707
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              104.87451166060183,
              -2.29534634931278
            ],
            [
              104.96249987952004,
              -2.187128507840782
            ],
            [
              105.1642597365915,
              -2.065142653434521
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "sjjk",
        "name": "SJJK",
        "color": "#32499f",
        "feature_id": "sjjk-0",
        "coordinates": [
          113.7186194342261,
          -4.956509167211948
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              112.59819447032245,
              -6.953337827182054
            ],
            [
              112.49999453988826,
              -6.641483533889414
            ],
            [
              112.49999453988826,
              -6.39309949782384
            ],
            [
              112.89374426095222,
              -5.833801119425374
            ],
            [
              112.83749430080022,
              -5.777839699209608
            ],
            [
              112.58559447924856,
              -5.793509456683637
            ]
          ],
          [
            [
              114.66674769244,
              -3.882827780552503
            ],
            [
              114.0749934241442,
              -4.377144375531941
            ],
            [
              113.84999358353613,
              -4.825692499217524
            ],
            [
              112.94999422110416,
              -5.721872747834027
            ],
            [
              112.83749430080022,
              -5.721872747834027
            ],
            [
              112.58559447924856,
              -5.793509456683637
            ]
          ],
          [
            [
              105.58496037606338,
              -5.769333077764941
            ],
            [
              105.69374936149612,
              -6.001651664913879
            ],
            [
              105.88389922679218,
              -6.073710601062686
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "link-5-phase-2",
        "name": "Link 5 Phase-2",
        "color": "#c82026",
        "feature_id": "link-5-phase-2-0",
        "coordinates": [
          105.0899856959658,
          -0.953859025087695
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              103.48820092392756,
              -0.823472321509598
            ],
            [
              103.95000059678412,
              -0.781386636225506
            ],
            [
              104.40000027800002,
              -0.781386636225506
            ],
            [
              105.74999932164823,
              -1.118839506905204
            ],
            [
              106.08749908256002,
              -1.343787247896245
            ],
            [
              106.19999900286413,
              -1.681168935905106
            ],
            [
              106.13251858191788,
              -1.851497937336347
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "link-4-phase-2",
        "name": "Link 4 Phase-2",
        "color": "#32499f",
        "feature_id": "link-4-phase-2-0",
        "coordinates": [
          106.94530716809211,
          -2.5337266678270964
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              106.13251858191788,
              -1.851497937336347
            ],
            [
              106.31249892316825,
              -1.79361712035498
            ],
            [
              106.81974856382791,
              -2.130918480960247
            ],
            [
              107.0999983652961,
              -3.029995968008762
            ],
            [
              107.32499820590417,
              -3.254657364797595
            ],
            [
              107.54999804651217,
              -3.254657364797595
            ],
            [
              107.69638466156053,
              -3.195961467831239
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "link-3-phase-2",
        "name": "Link 3 Phase-2",
        "color": "#32499f",
        "feature_id": "link-3-phase-2-0",
        "coordinates": [
          107.36856158675513,
          -4.704474226031891
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              106.98749844499216,
              -5.273944363641391
            ],
            [
              107.12099835041957,
              -5.981154260263196
            ]
          ],
          [
            [
              107.69638466156053,
              -3.195961467831239
            ],
            [
              107.66249796681612,
              -3.479268678969987
            ],
            [
              107.43749812620823,
              -4.601453764837203
            ],
            [
              106.98749844499216,
              -5.273944363641391
            ],
            [
              106.83339855415792,
              -6.128964849210604
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "link-2-phase-2",
        "name": "Link 2 Phase-2",
        "color": "#4a499e",
        "feature_id": "link-2-phase-2-0",
        "coordinates": [
          114.24409282969995,
          -8.929573210379402
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              113.45679386208272,
              -8.374087789277866
            ],
            [
              113.6249937429283,
              -8.623660111129396
            ],
            [
              114.2999932647522,
              -8.957195081743208
            ],
            [
              114.97499278657611,
              -8.957195081743208
            ],
            [
              115.15985265561974,
              -8.783705413994738
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "link-1-phase-2",
        "name": "Link 1 Phase-2",
        "color": "#5a9f43",
        "feature_id": "link-1-phase-2-0",
        "coordinates": [
          115.67268185049087,
          -8.623660111129396
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              115.25956289748419,
              -8.69510191560289
            ],
            [
              115.53749238809613,
              -8.623660111129396
            ],
            [
              115.8749921490083,
              -8.623660111129396
            ],
            [
              116.04726202697086,
              -8.485465010786783
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "link-3-phase-1",
        "name": "Link 3 Phase-1",
        "color": "#5a9f43",
        "feature_id": "link-3-phase-1-0",
        "coordinates": [
          116.81114646890248,
          -7.955717094334744
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              116.04726202697086,
              -8.485465010786783
            ],
            [
              116.0437420294642,
              -8.401139048122928
            ],
            [
              116.09999198961614,
              -8.17849027894485
            ],
            [
              116.54999167083221,
              -7.955717094334744
            ],
            [
              117.44999103326418,
              -7.955717094334744
            ],
            [
              117.86151417923728,
              -8.108113429686256
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "link-2-phase-1",
        "name": "Link 2 Phase-1",
        "color": "#5a9f43",
        "feature_id": "link-2-phase-1-0",
        "coordinates": [
          118.62829725367507,
          -0.13306273837292792
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              117.53549097269544,
              0.3245436533999
            ],
            [
              117.89999071448025,
              0.231087797340564
            ],
            [
              119.2499897581283,
              -0.443906656918472
            ],
            [
              119.66610274459995,
              -0.711958991791477
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "link-1-phase-1",
        "name": "Link 1 Phase-1",
        "color": "#37b19b",
        "feature_id": "link-1-phase-1-0",
        "coordinates": [
          119.05234026140161,
          -7.0359837894711745
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              117.86151417923728,
              -8.108113429686256
            ],
            [
              118.34999039569615,
              -7.732822794391694
            ],
            [
              119.47498959873613,
              -6.616650693475464
            ],
            [
              119.58748951904025,
              -6.169450529574419
            ],
            [
              119.80928936191529,
              -5.669034918116978
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "prat",
        "name": "Prat",
        "color": "#18b199",
        "feature_id": "prat-0",
        "coordinates": [
          -71.9964215180612,
          -30.33361208563278
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -72.95341123369906,
              -41.460374244790415
            ],
            [
              -73.34987380232134,
              -41.60922308603497
            ],
            [
              -73.79987348353733,
              -41.641926259541066
            ],
            [
              -74.2498731647534,
              -41.39859245412438
            ],
            [
              -74.2498731647534,
              -40.54922829806904
            ],
            [
              -73.79336348874511,
              -39.15216193718996
            ],
            [
              -73.39677376969296,
              -38.78712693980813
            ],
            [
              -73.57487364352536,
              -38.70085673739973
            ],
            [
              -73.79987348413331,
              -38.34880601117126
            ],
            [
              -73.79987348353733,
              -37.04328040742419
            ],
            [
              -73.57487364352536,
              -36.863477581370745
            ],
            [
              -73.10897397357303,
              -36.831684322274725
            ],
            [
              -73.12487396171339,
              -35.95811819864912
            ],
            [
              -72.89987412170134,
              -35.47096027456567
            ],
            [
              -72.4160644644366,
              -35.318374761635
            ],
            [
              -72.44987444048536,
              -34.734661512708556
            ],
            [
              -72.22487459987732,
              -33.9917435564351
            ],
            [
              -71.60009504247702,
              -33.54688527485865
            ],
            [
              -71.77487491806535,
              -33.36763676394733
            ],
            [
              -71.62043502747201,
              -33.0455412324782
            ],
            [
              -71.77487491806535,
              -31.851465665577166
            ],
            [
              -71.99987475867339,
              -30.309953344646914
            ],
            [
              -71.54987507745732,
              -29.92069711126888
            ],
            [
              -71.25021708661282,
              -29.906307231590603
            ],
            [
              -71.54987507745732,
              -29.529912966149226
            ],
            [
              -71.77487491866134,
              -28.74381028114999
            ],
            [
              -71.5498750780533,
              -27.420446776433664
            ],
            [
              -70.81235560051896,
              -27.061994602084383
            ],
            [
              -71.09987539683732,
              -26.215682285093067
            ],
            [
              -71.09987539624133,
              -24.31670674946909
            ],
            [
              -70.76237563532928,
              -23.905969261790176
            ],
            [
              -70.39997354830841,
              -23.65002363064994
            ],
            [
              -70.76237563532928,
              -23.493922445897766
            ],
            [
              -70.76237563592535,
              -22.94251974031627
            ],
            [
              -70.64987571562133,
              -22.52748528666686
            ],
            [
              -70.19765603597794,
              -22.08837968437996
            ],
            [
              -70.64987571562133,
              -21.274950941109722
            ],
            [
              -70.64987571562133,
              -20.855024450960002
            ],
            [
              -70.14221005903548,
              -20.216704864852915
            ],
            [
              -70.64987571562133,
              -19.588268775768398
            ],
            [
              -70.64987571502535,
              -19.30538407236122
            ],
            [
              -70.3067559580946,
              -18.473543073651314
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "ultramar-ge",
        "name": "Ultramar GE",
        "color": "#41b87b",
        "feature_id": "ultramar-ge-0",
        "coordinates": [
          6.401350083724907,
          -0.6166957139394924
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              6.733269466028645,
              0.333286471885856
            ],
            [
              6.875069365576195,
              0.118588418888407
            ],
            [
              6.750069454127299,
              -0.331409329660175
            ],
            [
              5.650070233377079,
              -1.231315750217505
            ],
            [
              5.63687024272798,
              -1.435160298356124
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "umo",
        "name": "UMO",
        "color": "#34a496",
        "feature_id": "umo-0",
        "coordinates": [
          97.38392862392826,
          7.594235564906711
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              96.2482060528076,
              16.758772278045996
            ],
            [
              96.44170591573064,
              14.833379080264962
            ],
            [
              96.75000569732802,
              11.294709319565555
            ],
            [
              97.42500521915215,
              7.354454266299882
            ],
            [
              99.67500362523216,
              5.286069860821101
            ],
            [
              100.46250306736002,
              4.613591578862867
            ],
            [
              100.80000282827214,
              3.266814816815753
            ],
            [
              101.25000250948804,
              2.817450442654064
            ],
            [
              102.15000187192001,
              2.199296754027785
            ],
            [
              102.68279723997185,
              1.783118248528041
            ],
            [
              103.34065102845304,
              1.383978004153676
            ],
            [
              103.50000091556822,
              1.28531735946305
            ],
            [
              103.6471008113613,
              1.338165966949504
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "polar-circle-cable",
        "name": "Polar Circle Cable",
        "color": "#51b847",
        "feature_id": "polar-circle-cable-0",
        "coordinates": [
          13.003013674052,
          66.312054201844
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              10.392059061607597,
              63.43100204742538
            ],
            [
              10.293816943703206,
              63.475978110242664
            ],
            [
              9.90006722263928,
              63.5010867925096
            ],
            [
              9.843817262487306,
              63.576280591644036
            ],
            [
              9.675067382031244,
              63.676208784313815
            ],
            [
              9.506323501571046,
              63.67623095645985
            ],
            [
              9.45006754142321,
              63.72607438667927
            ],
            [
              9.45006754142321,
              63.82549832634285
            ],
            [
              9.618817421879271,
              63.9245724837925
            ],
            [
              9.90006722263928,
              64.12167519439029
            ],
            [
              10.237566983551233,
              64.31739001144422
            ],
            [
              10.462566824159268,
              64.46326983353543
            ],
            [
              10.80006658507125,
              64.53591955943637
            ],
            [
              11.025066425679285,
              64.70468599661166
            ],
            [
              11.237370962780744,
              64.86207950442943
            ],
            [
              11.475066106895355,
              64.96778031868584
            ],
            [
              11.70006594750339,
              65.01533984410813
            ],
            [
              11.925065788111255,
              65.13386892664585
            ],
            [
              12.207765587844165,
              65.47355705315002
            ],
            [
              12.178190608795347,
              65.55616358500063
            ],
            [
              12.262565549023208,
              65.6259608208081
            ],
            [
              12.543815349783216,
              65.83404693088383
            ],
            [
              12.631465287691299,
              66.02153874887077
            ],
            [
              12.768815190391422,
              66.1544195909438
            ],
            [
              13.0182650136789,
              66.19806628775794
            ],
            [
              12.993815030999286,
              66.3808044991722
            ],
            [
              13.05006499115126,
              66.44832399589339
            ],
            [
              12.937565070847313,
              66.56045213116752
            ],
            [
              13.05006499115126,
              66.7165854620128
            ],
            [
              13.50006467236733,
              66.95995474248622
            ],
            [
              13.9500643535834,
              67.13543790495441
            ],
            [
              14.4000640347993,
              67.2860055353949
            ],
            [
              14.512563955103388,
              67.4394931950778
            ],
            [
              14.906313676167343,
              67.61151472096557
            ],
            [
              15.131313516775379,
              67.65432532704776
            ],
            [
              15.356313357383414,
              67.78229156045435
            ],
            [
              15.581313197991449,
              67.90956187665077
            ],
            [
              15.887562981041043,
              67.97293705344714
            ],
            [
              16.143812799511437,
              68.05716856580699
            ],
            [
              16.171887779622978,
              68.1411682869288
            ],
            [
              16.127072811370283,
              68.22074777678267
            ],
            [
              16.06516285522781,
              68.28972225489503
            ],
            [
              16.152422793411944,
              68.36187412811796
            ],
            [
              16.31256267996733,
              68.38642806305168
            ],
            [
              16.66881242759669,
              68.4173574082686
            ],
            [
              17.073261876821988,
              68.4078545509303
            ],
            [
              17.560022733754863,
              68.42054850557756
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tverrlinken",
        "name": "Tverrlinken",
        "color": "#1bbaae",
        "feature_id": "tverrlinken-0",
        "coordinates": [
          13.563638846080806,
          66.26218454683192
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              13.018229076204165,
              66.19806628775794
            ],
            [
              13.05006499115126,
              66.24521776261767
            ],
            [
              13.218814871607321,
              66.290494626255
            ],
            [
              13.387564752063213,
              66.31310255415389
            ],
            [
              13.563638846080806,
              66.29296835834745
            ],
            [
              13.563638846080806,
              66.24773433541898
            ],
            [
              13.624478646731319,
              66.22416306028722
            ],
            [
              13.781314473127338,
              66.25652447701611
            ],
            [
              13.9500643535834,
              66.25652447701611
            ],
            [
              14.141958748893472,
              66.31371064643912
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "national-digital-transmission-network-ndtn",
        "name": "National Digital Transmission Network (NDTN)",
        "color": "#a3492e",
        "feature_id": "national-digital-transmission-network-ndtn-0",
        "coordinates": [
          122.22013011901967,
          10.321516903939624
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              122.43363382518382,
              10.425900114515514
            ],
            [
              122.56210741119705,
              10.720150965231483
            ]
          ],
          [
            [
              121.0501884828507,
              12.363012914770593
            ],
            [
              121.04998848299223,
              11.735650161405744
            ],
            [
              121.2749883236003,
              11.294709319565555
            ],
            [
              121.4999881642083,
              10.410816505402636
            ],
            [
              121.94998784542437,
              10.18944276650771
            ],
            [
              122.43363382518382,
              10.425900114515514
            ],
            [
              122.28748760633616,
              9.96791518697421
            ],
            [
              122.28748760633616,
              9.524411345019587
            ],
            [
              122.84998720785634,
              8.858082310478125
            ],
            [
              123.1874869687683,
              8.858082310478125
            ],
            [
              123.29998688907224,
              9.08033076823304
            ],
            [
              123.28143690221336,
              9.295503918748068
            ]
          ],
          [
            [
              121.61229277215065,
              13.935275186648965
            ],
            [
              121.72498800481614,
              13.492128176464178
            ],
            [
              121.83748792512026,
              13.054150695298716
            ],
            [
              121.83748792512026,
              12.725155923562937
            ],
            [
              121.72498800481614,
              12.395734000022884
            ],
            [
              121.38748824390436,
              12.230866087669114
            ],
            [
              121.0501884828507,
              12.363012914770593
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "rnne-rdvig",
        "name": "Rønne-Rødvig",
        "color": "#2aaf8a",
        "feature_id": "rnne-rdvig-0",
        "coordinates": [
          13.533267873522249,
          55.113215716596756
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              12.371865471594333,
              55.256124793284926
            ],
            [
              12.825065150543395,
              55.14215100378169
            ],
            [
              14.4000640347993,
              55.07780072164758
            ],
            [
              14.708363816396854,
              55.101035976100604
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "chennai-andaman-nicobar-islands-cable",
        "name": "Chennai-Andaman & Nicobar Islands Cable",
        "color": "#b0c435",
        "feature_id": "chennai-andaman-nicobar-islands-cable-0",
        "coordinates": [
          86.7274583214336,
          12.31494911385849
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              92.72647339138612,
              11.6233755892342
            ],
            [
              92.81250848668802,
              11.294709319565555
            ],
            [
              92.250008885168,
              11.073982781226704
            ],
            [
              89.10001111665602,
              11.735650161405744
            ],
            [
              83.70001494206386,
              13.054150695298716
            ],
            [
              81.45001653598385,
              12.834868817846598
            ],
            [
              80.2429873910547,
              13.063853101883296
            ]
          ],
          [
            [
              92.91387560237851,
              12.506922878216642
            ],
            [
              92.92360298633176,
              12.45624046231191
            ],
            [
              92.9350669936164,
              12.396497036071935
            ],
            [
              92.98750836271651,
              12.17588718550806
            ],
            [
              92.98760601889737,
              11.976015925931847
            ],
            [
              92.8707584454232,
              11.735650161405744
            ],
            [
              92.72647339138612,
              11.6233755892342
            ],
            [
              92.86875844683996,
              11.294709319565555
            ],
            [
              92.75625852653607,
              10.853089690745378
            ],
            [
              92.59717660798114,
              10.709095116735966
            ],
            [
              92.7000085663839,
              10.410816505402636
            ],
            [
              92.92500840699213,
              9.524411345019587
            ],
            [
              92.81641473392077,
              9.177521361414023
            ],
            [
              93.03750832729602,
              9.08033076823304
            ],
            [
              93.31875812805603,
              8.301880168760817
            ],
            [
              93.48145332530132,
              8.172274246767415
            ],
            [
              93.40638306598177,
              8.079175518240902
            ],
            [
              93.45958302829436,
              7.967776882259614
            ],
            [
              93.54375796866393,
              7.856347922592533
            ],
            [
              93.937507689728,
              7.298762754459602
            ],
            [
              93.92744910310356,
              7.031336722040379
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "dunant",
        "name": "Dunant",
        "color": "#ab7a2b",
        "feature_id": "dunant-0",
        "coordinates": [
          -38.67344282149969,
          39.2539382433383
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -1.968324369680687,
              46.69399663348955
            ],
            [
              -2.699923851408727,
              46.58235508209589
            ],
            [
              -5.399921938704722,
              46.58235508209589
            ],
            [
              -9.899918750864742,
              46.272182853813746
            ],
            [
              -16.199914287888873,
              45.33107107332478
            ],
            [
              -23.39990918734489,
              43.40114497315386
            ],
            [
              -39.59989771112103,
              39.00237890905848
            ],
            [
              -50.39989006030518,
              37.41128363492314
            ],
            [
              -61.199882409489234,
              37.58978657360316
            ],
            [
              -72.44987443988929,
              37.232354321556215
            ],
            [
              -74.69987284596938,
              36.72307894944538
            ],
            [
              -76.05919805488558,
              36.755008440642456
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "gulf-of-california-cable",
        "name": "Gulf of California Cable",
        "color": "#863f98",
        "feature_id": "gulf-of-california-cable-0",
        "coordinates": [
          -109.82192082171117,
          24.990270773742253
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -110.30544762264611,
              24.102536621844706
            ],
            [
              -110.13734774172961,
              24.532657566160623
            ],
            [
              -109.57484814020961,
              25.348717422116806
            ],
            [
              -109.0503485117701,
              25.600681534740346
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "coral-sea-cable-system-cs",
        "name": "Coral Sea Cable System (CS²)",
        "color": "#3c4b9f",
        "feature_id": "coral-sea-cable-system-cs-0",
        "coordinates": [
          155.40559836145107,
          -21.18378787673899
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              157.19068553683346,
              -8.242089438962438
            ],
            [
              157.27496282088057,
              -7.955717094334744
            ],
            [
              157.38746274118452,
              -7.732822794391694
            ]
          ],
          [
            [
              156.39586188114404,
              -6.708991470563916
            ],
            [
              156.59996329905667,
              -7.286684094287295
            ],
            [
              157.38746274118452,
              -7.732822794391694
            ],
            [
              158.3999620239206,
              -8.06711903252912
            ],
            [
              159.74996106756865,
              -8.901626855396557
            ],
            [
              159.94976092602863,
              -9.429053646438376
            ]
          ],
          [
            [
              160.70132758111177,
              -8.774637297685615
            ],
            [
              160.64996043000062,
              -9.179382545871192
            ],
            [
              160.53746050969667,
              -9.290424301035614
            ],
            [
              160.19996074878455,
              -9.290424301035614
            ],
            [
              159.94976092602863,
              -9.429053646438376
            ]
          ],
          [
            [
              154.79996457419256,
              -15.441023659568003
            ],
            [
              152.09996648689648,
              -14.135775375064753
            ],
            [
              148.49996903716843,
              -11.50333384598423
            ],
            [
              147.2624699138245,
              -10.177457430361159
            ],
            [
              147.1885196909836,
              -9.479589292697398
            ]
          ],
          [
            [
              151.20699836948356,
              -33.86955536437554
            ],
            [
              152.09996648689648,
              -33.36763676394733
            ],
            [
              154.3499648929765,
              -31.851465665577166
            ],
            [
              155.69996393662453,
              -28.74381028114999
            ],
            [
              155.69996393662453,
              -25.5408960762594
            ],
            [
              155.24996425540863,
              -18.880139975101287
            ],
            [
              154.79996457419256,
              -15.441023659568003
            ],
            [
              157.04996298027257,
              -11.943944931746927
            ],
            [
              159.18746146604863,
              -10.177457430361159
            ],
            [
              159.29996138635258,
              -9.734235342300764
            ],
            [
              159.24376142616532,
              -9.290424301035614
            ],
            [
              159.52496122696058,
              -9.012754814881703
            ],
            [
              159.74996106756865,
              -9.012754814881703
            ],
            [
              159.94976092602863,
              -9.429053646438376
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "malbec",
        "name": "Malbec",
        "color": "#b13885",
        "feature_id": "malbec-0",
        "coordinates": [
          -49.04790358181043,
          -32.87518112808766
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -43.209565153998795,
              -22.903486555497864
            ],
            [
              -43.649894842065066,
              -23.905969261790176
            ],
            [
              -44.99989388571311,
              -24.521579207604788
            ],
            [
              -46.12489308875311,
              -25.13418654706126
            ]
          ],
          [
            [
              -46.41249054126638,
              -24.00886625539158
            ],
            [
              -46.12489308875311,
              -25.13418654706126
            ]
          ],
          [
            [
              -46.12489308875311,
              -25.13418654706126
            ],
            [
              -46.34989292936115,
              -27.951747285219852
            ],
            [
              -48.59989133544116,
              -32.61276000573585
            ],
            [
              -53.99988751003313,
              -35.775783044315574
            ],
            [
              -56.69544224110189,
              -36.47097855291446
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "st-pierre-and-miquelon-cable",
        "name": "St. Pierre and Miquelon Cable",
        "color": "#63ae45",
        "feature_id": "st-pierre-and-miquelon-cable-0",
        "coordinates": [
          -56.18893456436548,
          47.06659226124515
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -55.83589977066387,
              47.07196443816753
            ],
            [
              -56.13738599580921,
              47.12091150337983
            ],
            [
              -56.19363595596117,
              47.12087748071977
            ],
            [
              -56.34988584527227,
              47.083686216782894
            ],
            [
              -56.19363595596117,
              47.08262225469679
            ],
            [
              -56.137335995844595,
              46.890660369874894
            ],
            [
              -56.18058596520585,
              46.775830298450956
            ],
            [
              -55.968636115353135,
              46.813826285336035
            ],
            [
              -55.80328623248853,
              46.868958275284484
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "sea2shore",
        "name": "sea2shore",
        "color": "#50c2bc",
        "feature_id": "sea2shore-0",
        "coordinates": [
          -71.50328331663817,
          41.30829079074047
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -71.593675046429,
              41.191963141608795
            ],
            [
              -71.49162511872218,
              41.323294052209405
            ],
            [
              -71.44277515332791,
              41.441393468408165
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "nordbalt",
        "name": "NordBalt",
        "color": "#7b439a",
        "feature_id": "nordbalt-0",
        "coordinates": [
          18.28746856397302,
          55.716652093821665
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              15.908662966093914,
              56.74380010331279
            ],
            [
              16.087562839359293,
              56.59379297841909
            ],
            [
              16.20006275966341,
              56.345222926819986
            ],
            [
              16.31256267996733,
              56.03221697111684
            ],
            [
              17.325061962703415,
              55.71665209382169
            ],
            [
              20.475059731215396,
              55.71665209382169
            ],
            [
              21.14995925311044,
              55.69574860959892
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "southeast-asia-japan-cable-2-sjc2",
        "name": "Southeast Asia-Japan Cable 2 (SJC2)",
        "color": "#939597",
        "feature_id": "southeast-asia-japan-cable-2-sjc2-0",
        "coordinates": [
          120.24622973092599,
          20.280825284596403
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              126.22498481697616,
              25.55188275942578
            ],
            [
              123.74998657028831,
              25.653336613275968
            ],
            [
              122.84998720785634,
              25.754704263415306
            ],
            [
              121.94998784542437,
              25.653336613275968
            ],
            [
              121.48768817292179,
              25.181626281877442
            ]
          ],
          [
            [
              128.24998338244833,
              28.359233526108653
            ],
            [
              127.12498417940836,
              29.540507745394393
            ],
            [
              125.77498513576032,
              30.320465424761352
            ],
            [
              124.64998593272028,
              30.901396088515583
            ],
            [
              122.84998720785634,
              31.28673881439167
            ],
            [
              122.17498768603221,
              31.142418511463745
            ],
            [
              121.89608788360775,
              30.93566174731481
            ]
          ],
          [
            [
              129.59998242609637,
              28.75448641587161
            ],
            [
              129.37498258548837,
              30.901396088515583
            ],
            [
              129.1499827448803,
              31.67051304708704
            ],
            [
              129.26248266518442,
              32.81231878328768
            ],
            [
              129.37498258548837,
              34.31215165223537
            ],
            [
              128.99948285149597,
              35.17030187110507
            ]
          ],
          [
            [
              138.59997605041642,
              32.052708023486105
            ],
            [
              137.69997668798445,
              33.09551711711572
            ],
            [
              136.87399727311598,
              34.33682825203164
            ]
          ],
          [
            [
              100.59697562834847,
              7.078508072193671
            ],
            [
              101.70000219070411,
              7.410337121715031
            ],
            [
              103.04883123518098,
              7.451587710516492
            ],
            [
              105.29999964043216,
              6.181557032537114
            ],
            [
              107.0999983652961,
              4.837826391986653
            ]
          ],
          [
            [
              112.94999422110416,
              12.615395567393307
            ],
            [
              111.59999517745612,
              13.273238157547667
            ],
            [
              110.02499629320002,
              13.710817738179543
            ],
            [
              109.21959686375268,
              13.782910441432165
            ]
          ],
          [
            [
              120.14998912056026,
              20.26954403592967
            ],
            [
              120.48748888147222,
              21.635297384859456
            ],
            [
              120.66208875778412,
              22.24916819612369
            ]
          ],
          [
            [
              114.74999294596827,
              18.251816319028308
            ],
            [
              114.2999932647522,
              20.796306105108954
            ],
            [
              114.20309333339699,
              22.222140388552646
            ]
          ],
          [
            [
              103.98701228931466,
              1.389451396800126
            ],
            [
              104.19209042528556,
              1.27648207407276
            ],
            [
              104.28790035741284,
              1.327893755020796
            ],
            [
              104.4565502379397,
              1.468426767332062
            ],
            [
              104.84999995921609,
              2.817450442654064
            ],
            [
              105.74999932164823,
              4.052702097268195
            ],
            [
              107.0999983652961,
              4.837826391986653
            ],
            [
              107.99999772772824,
              5.510071711803135
            ],
            [
              110.69999581502415,
              7.744889052551343
            ],
            [
              111.93749493836825,
              9.96791518697421
            ],
            [
              112.94999422110416,
              12.615395567393307
            ],
            [
              114.52499310476432,
              17.108519960795775
            ],
            [
              114.74999294596827,
              18.251816319028308
            ],
            [
              116.99999135204828,
              19.529070924351004
            ],
            [
              120.14998912056026,
              20.26954403592967
            ],
            [
              121.04998848299223,
              20.375041253465525
            ],
            [
              122.84998720785634,
              20.901439785237727
            ],
            [
              124.87498577332835,
              22.05298561667763
            ],
            [
              125.77498513576032,
              24.12261698700334
            ],
            [
              126.22498481697616,
              25.55188275942578
            ],
            [
              126.67498449819223,
              26.159307970773796
            ],
            [
              128.24998338244833,
              28.359233526108653
            ],
            [
              129.59998242609637,
              28.75448641587161
            ],
            [
              131.39998115096031,
              29.049948644465616
            ],
            [
              134.99997860068837,
              30.126049846722907
            ],
            [
              136.79997732555248,
              30.901396088515583
            ],
            [
              138.59997605041642,
              32.052708023486105
            ],
            [
              139.0499757316325,
              32.43331330641712
            ],
            [
              139.7249752534564,
              33.93964008831958
            ],
            [
              140.23122489482446,
              34.40502275071583
            ],
            [
              140.2030999141525,
              34.69072647741018
            ],
            [
              140.0343500336966,
              34.85244570884623
            ],
            [
              139.95467509073487,
              34.9767277606654
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "no-uk",
        "name": "NO-UK",
        "color": "#ac84bb",
        "feature_id": "no-uk-0",
        "coordinates": [
          2.489703629959081,
          56.52964381322204
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -1.617778330734808,
              54.978249214505325
            ],
            [
              -0.899925126544701,
              55.33458061322915
            ],
            [
              0.900073598319238,
              55.84318584148099
            ],
            [
              2.534972440141729,
              56.549192472345595
            ],
            [
              3.375071845007284,
              58.05131589106017
            ],
            [
              4.050071366831219,
              58.641677771384906
            ],
            [
              5.175070569871224,
              58.90413203005053
            ],
            [
              5.512570330783177,
              59.02014280892385
            ],
            [
              5.730770176208466,
              58.97082148666851
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "sakhalin-kuril-islands-cable",
        "name": "Sakhalin-Kuril Islands Cable",
        "color": "#c1b230",
        "feature_id": "sakhalin-kuril-islands-cable-0",
        "coordinates": [
          146.9626603754605,
          45.57302306563193
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              142.7262426585804,
              46.94899298742823
            ],
            [
              143.4374726234884,
              47.04430546733278
            ],
            [
              145.57497110926445,
              46.272182853813746
            ],
            [
              147.59996967473646,
              45.25192738121436
            ],
            [
              147.88151244403892,
              45.086689968098796
            ],
            [
              147.59996967473646,
              45.172673246984374
            ],
            [
              147.05997005727735,
              45.03772308017787
            ],
            [
              146.75634527236807,
              44.694829089578064
            ],
            [
              146.6999703123045,
              44.37405751055866
            ],
            [
              146.24997063108842,
              44.051519228735145
            ],
            [
              145.8585646583641,
              44.03453155100814
            ],
            [
              146.24997063108842,
              43.889587736299546
            ],
            [
              146.74723590382106,
              43.79361974189811
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "meltingpot-indianoceanic-submarine-system-metiss",
        "name": "Meltingpot Indianoceanic Submarine System (METISS)",
        "color": "#69bd45",
        "feature_id": "meltingpot-indianoceanic-submarine-system-metiss-0",
        "coordinates": [
          44.94191055148988,
          -26.361278448096442
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              54.000035981807684,
              -20.57441905727606
            ],
            [
              54.450035663023755,
              -20.679706953509026
            ],
            [
              54.900035344239626,
              -20.890063499753126
            ],
            [
              55.30323505860932,
              -20.944427907258927
            ]
          ],
          [
            [
              46.98534095108366,
              -25.022510261484882
            ],
            [
              47.250040763567654,
              -25.337712186601237
            ],
            [
              47.700040444783724,
              -25.5408960762594
            ]
          ],
          [
            [
              30.90155234499045,
              -30.02161828745679
            ],
            [
              32.40005128343955,
              -29.92069711126888
            ],
            [
              38.70004682046351,
              -27.553513996438255
            ],
            [
              45.000042357487644,
              -26.350174904573805
            ],
            [
              47.700040444783724,
              -25.5408960762594
            ],
            [
              49.050039488431565,
              -24.7261180292069
            ],
            [
              51.750037575727674,
              -23.08058350574774
            ],
            [
              53.550036300591586,
              -20.995131543025877
            ],
            [
              54.000035981807684,
              -20.57441905727606
            ],
            [
              54.900035344239626,
              -20.15254378601884
            ],
            [
              55.800034706671596,
              -20.15254378601884
            ],
            [
              56.700034069103765,
              -20.046895587328578
            ],
            [
              57.49513350584786,
              -20.12212441603404
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "java-bali-cable-system-jbcs",
        "name": "Java Bali Cable System (JBCS)",
        "color": "#9b3794",
        "feature_id": "java-bali-cable-system-jbcs-0",
        "coordinates": [
          114.42270747463047,
          -8.37620604082781
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              114.3103932573849,
              -8.448027546855428
            ],
            [
              114.47074314379151,
              -8.345488699899732
            ],
            [
              114.52729310373101,
              -8.294580235998978
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "jakarta-surabaya-cable-system-jayabaya",
        "name": "Jakarta Surabaya Cable System (JAYABAYA)",
        "color": "#4b3c97",
        "feature_id": "jakarta-surabaya-cable-system-jayabaya-0",
        "coordinates": [
          110.10010340348808,
          -6.867191961808345
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              107.12099835041957,
              -5.981154260263196
            ],
            [
              107.32499820590417,
              -5.833801119425374
            ],
            [
              107.54999804651217,
              -5.833801119425374
            ],
            [
              108.44999740894414,
              -6.169450529574419
            ],
            [
              108.6749972495522,
              -6.39309949782384
            ],
            [
              108.6749972495522,
              -6.56077224456368
            ],
            [
              108.5573442550797,
              -6.7166534198806
            ],
            [
              108.7874971698561,
              -6.616650693475464
            ],
            [
              109.79999645259218,
              -6.616650693475464
            ],
            [
              110.02499629320002,
              -6.730871350313521
            ],
            [
              110.18788680280707,
              -7.026520241492761
            ],
            [
              110.24999613380825,
              -6.730871350313521
            ],
            [
              110.69999581502415,
              -6.281287025048499
            ],
            [
              111.37499533684829,
              -6.169450529574419
            ],
            [
              112.16249477897614,
              -6.39309949782384
            ],
            [
              112.61249446019221,
              -6.616650693475464
            ],
            [
              112.71972094673194,
              -7.271863121195906
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "southern-cross-next",
        "name": "Southern Cross NEXT",
        "color": "#b61e51",
        "feature_id": "southern-cross-next-0",
        "coordinates": [
          -152.7418827007631,
          11.451524073293513
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              179.34974953238176,
              -16.80801177359578
            ],
            [
              179.09994735985677,
              -17.16855309422607
            ],
            [
              179.09994735985677,
              -17.705202172686143
            ],
            [
              179.32494720046478,
              -18.880139975101287
            ],
            [
              179.54994704107284,
              -19.30538407236122
            ]
          ],
          [
            [
              178.43822782862517,
              -18.12306964099227
            ],
            [
              178.6499476786407,
              -18.880139975101287
            ],
            [
              179.09994735985677,
              -19.72952545002093
            ]
          ],
          [
            [
              -171.44980430741032,
              -9.290424301035614
            ],
            [
              -171.67480414801807,
              -9.290424301035614
            ],
            [
              -171.81150405117864,
              -9.174626307490229
            ]
          ],
          [
            [
              -157.42781424071944,
              1.872154031030135
            ],
            [
              -157.72481403032197,
              2.817450442654064
            ],
            [
              -158.84981323336194,
              4.164912849976844
            ],
            [
              -160.19981227700998,
              5.061986954416028
            ]
          ],
          [
            [
              -179.99979825051417,
              -18.880139975101287
            ],
            [
              -177.2998001632181,
              -16.738110438702392
            ],
            [
              -175.49980143835418,
              -14.57172649133264
            ],
            [
              -173.92480255409808,
              -11.943944931746927
            ],
            [
              -172.79980335105827,
              -11.06203210990941
            ],
            [
              -171.67480414801807,
              -10.620064860363328
            ],
            [
              -171.44980430741032,
              -9.290424301035614
            ],
            [
              -169.19980590133002,
              -4.825692499217524
            ],
            [
              -162.89981036430618,
              2.367912558705314
            ],
            [
              -160.19981227700998,
              5.061986954416028
            ],
            [
              -159.29981291457804,
              5.957818681088611
            ],
            [
              -138.5998275786418,
              23.298598065875808
            ],
            [
              -127.79983522945773,
              28.55704546571141
            ],
            [
              -120.59984033000163,
              33.09551711711572
            ],
            [
              -118.79984160513769,
              33.79952573458132
            ],
            [
              -118.2453498104448,
              34.053596693794645
            ]
          ],
          [
            [
              173.69995118526478,
              -24.7261180292069
            ],
            [
              175.04995022891282,
              -30.309953344646914
            ],
            [
              175.5057116247971,
              -33.348019756446746
            ],
            [
              174.9374503086087,
              -36.14003339129533
            ],
            [
              174.99370026876065,
              -36.5929784279503
            ],
            [
              174.77324144056072,
              -36.78413802465403
            ]
          ],
          [
            [
              151.1962571270927,
              -33.9135716055703
            ],
            [
              152.09996648689648,
              -34.022829775550775
            ],
            [
              154.80012535991872,
              -32.802083892991675
            ],
            [
              160.64996043000062,
              -31.468437004267116
            ],
            [
              166.49995628580868,
              -27.951747285219852
            ],
            [
              173.69995118526478,
              -24.7261180292069
            ],
            [
              178.19994799742463,
              -20.57441905727606
            ],
            [
              179.09994735985677,
              -19.72952545002093
            ],
            [
              179.54994704107284,
              -19.30538407236122
            ],
            [
              179.99994672228874,
              -18.880139975101287
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "havfrueaec-2",
        "name": "Havfrue/AEC-2",
        "color": "#30aa9f",
        "feature_id": "havfrueaec-2-0",
        "coordinates": [
          -32.133407561101365,
          50.019286209842136
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              7.996258571315195,
              58.15106571642474
            ],
            [
              7.987568576875333,
              57.93205658695149
            ],
            [
              6.300069772911229,
              57.571890279005004
            ],
            [
              4.500071048047289,
              57.571890279005004
            ]
          ],
          [
            [
              -74.06286329723287,
              40.15283384719578
            ],
            [
              -71.09987539624133,
              40.55848045058687
            ],
            [
              -68.39987730894534,
              41.23875232896668
            ],
            [
              -61.199882409489234,
              42.41235450073577
            ],
            [
              -50.39989006030518,
              44.694829089578064
            ],
            [
              -39.59989771112103,
              47.50228998113275
            ],
            [
              -23.39990918734489,
              52.963398105593654
            ],
            [
              -16.199914287888873,
              55.07780072164758
            ],
            [
              -8.999919388432772,
              58.99117670269845
            ],
            [
              -5.399921938704722,
              59.6796637072089
            ],
            [
              -1.799924488976671,
              59.451717318905224
            ],
            [
              1.800072960751208,
              58.52439396084483
            ],
            [
              4.500071048047289,
              57.571890279005004
            ],
            [
              5.400070410479259,
              56.717468482041056
            ],
            [
              6.300069772911229,
              56.22032688484515
            ],
            [
              7.200069135343199,
              55.84318584148099
            ],
            [
              7.650068816559269,
              55.779970327098255
            ],
            [
              8.329168335478954,
              55.75165023178093
            ]
          ],
          [
            [
              -16.199914287888873,
              55.07780072164758
            ],
            [
              -12.599916838160823,
              54.81936191424907
            ],
            [
              -10.799918113296798,
              54.165971787151676
            ],
            [
              -10.349918432080813,
              53.901684726074365
            ],
            [
              -9.696518894955119,
              53.770801861757974
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "damai-cable-system",
        "name": "DAMAI Cable System",
        "color": "#813e97",
        "feature_id": "damai-cable-system-0",
        "coordinates": [
          100.33318952416269,
          2.4859760578043506
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              98.67598433294691,
              3.752031394331624
            ],
            [
              98.88750418310408,
              3.828234303320954
            ],
            [
              99.45000378402821,
              3.528852944408214
            ],
            [
              99.78750354553605,
              3.266814816815753
            ],
            [
              100.35000314705607,
              2.817450442654064
            ],
            [
              100.31333654252106,
              2.452337743745375
            ],
            [
              100.46250306736002,
              2.705081160335851
            ],
            [
              100.57500298766413,
              2.705081160335851
            ],
            [
              101.25000250948804,
              2.425986659073317
            ],
            [
              101.75825214943936,
              2.143087178471944
            ],
            [
              101.87075206974325,
              1.976445096071245
            ],
            [
              101.7281271707802,
              1.805588379583355
            ],
            [
              101.44766236946415,
              1.665522797277134
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "japan-guam-australia-south-jga-s",
        "name": "Japan-Guam-Australia South (JGA-S)",
        "color": "#944b9d",
        "feature_id": "japan-guam-australia-south-jga-s-0",
        "coordinates": [
          162.3703170388957,
          -8.243227349050471
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              157.94996234270454,
              -25.5408960762594
            ],
            [
              153.8999652117606,
              -26.55162080165701
            ],
            [
              153.08946578592602,
              -26.65185387937018
            ]
          ],
          [
            [
              144.69469829535794,
              13.464777824932952
            ],
            [
              144.8156466471772,
              13.273238157547667
            ],
            [
              145.34997126865645,
              12.834868817846598
            ],
            [
              146.24997063108842,
              12.17588718550806
            ],
            [
              147.14996999352056,
              11.95585820711483
            ],
            [
              149.39996839960057,
              10.853089690745378
            ],
            [
              151.1999671244645,
              9.524411345019587
            ],
            [
              152.99996584932862,
              8.190543417795567
            ],
            [
              155.24996425540863,
              4.164912849976844
            ],
            [
              156.59996329905667,
              -0.331409329660175
            ],
            [
              157.7249625020967,
              -3.029995968008762
            ],
            [
              159.29996138635258,
              -4.825692499217524
            ],
            [
              161.5499597924326,
              -6.616650693475464
            ],
            [
              162.44995915486456,
              -8.401139048122928
            ],
            [
              162.89995883608063,
              -10.177457430361159
            ],
            [
              161.99995947364866,
              -13.698987269610853
            ],
            [
              159.29996138635258,
              -18.880139975101287
            ],
            [
              157.94996234270454,
              -25.5408960762594
            ],
            [
              156.1499636178406,
              -28.74381028114999
            ],
            [
              154.54016356638905,
              -31.851465665577166
            ],
            [
              152.09996648689648,
              -33.461541290548496
            ],
            [
              151.27398707202818,
              -33.76116106060903
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "japan-guam-australia-north-jga-n",
        "name": "Japan-Guam-Australia North (JGA-N)",
        "color": "#5bba4d",
        "feature_id": "japan-guam-australia-north-jga-n-0",
        "coordinates": [
          139.81213538696952,
          23.81381590596061
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              144.69469829535794,
              13.48913559272836
            ],
            [
              144.34997197706534,
              14.042738209008093
            ],
            [
              143.77497238440057,
              15.23578178303569
            ],
            [
              143.09997286257644,
              16.965102599435824
            ],
            [
              140.84997445649643,
              21.635297384859456
            ],
            [
              139.27497557224055,
              24.941363171753835
            ],
            [
              139.0499757316325,
              27.763588526057674
            ],
            [
              139.27497557224055,
              30.901396088515583
            ],
            [
              139.61247533315233,
              32.43331330641712
            ],
            [
              139.61247533315233,
              33.93964008831958
            ],
            [
              139.7812252136084,
              34.40502275071583
            ],
            [
              139.94997509346857,
              34.69072647741018
            ],
            [
              140.0062250536205,
              34.85244570884623
            ],
            [
              139.9610261718478,
              34.97406980589034
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tonga-domestic-cable-extension-tdce",
        "name": "Tonga Domestic Cable Extension (TDCE)",
        "color": "#e11e25",
        "feature_id": "tonga-domestic-cable-extension-tdce-0",
        "coordinates": [
          -174.93558692339224,
          -19.727367489131723
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -174.93730183683417,
              -19.72952545002093
            ],
            [
              -174.5998020759221,
              -19.72952545002093
            ],
            [
              -174.3521422513667,
              -19.81379624685283
            ]
          ],
          [
            [
              -173.98368505307081,
              -18.65921570866675
            ],
            [
              -174.26230231501015,
              -18.880139975101287
            ],
            [
              -174.93730183683417,
              -19.72952545002093
            ],
            [
              -175.1623016774422,
              -20.57441905727606
            ],
            [
              -175.20000165073517,
              -21.13346565929308
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "paniolo-cable-network",
        "name": "Paniolo Cable Network",
        "color": "#eb2d24",
        "feature_id": "paniolo-cable-network-0",
        "coordinates": [
          -159.0244435640541,
          21.437225127981492
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -156.44000048737098,
              20.66359808291429
            ],
            [
              -156.26231506636998,
              20.48046637597571
            ],
            [
              -155.98106587237416,
              20.26954403592967
            ],
            [
              -155.8314059821754,
              20.039988105686604
            ]
          ],
          [
            [
              -157.66890586680287,
              21.272011061320086
            ],
            [
              -157.4998141897139,
              21.111485983488905
            ],
            [
              -157.16231442880195,
              21.00649984517682
            ],
            [
              -157.0238851072874,
              21.093359005003947
            ],
            [
              -156.82481466789,
              21.00649984517682
            ],
            [
              -156.68448273605244,
              20.900892404445557
            ]
          ],
          [
            [
              -159.72421261392935,
              21.97028767315794
            ],
            [
              -159.63731267549,
              21.84429407917378
            ],
            [
              -158.84981323336194,
              21.32123529551194
            ],
            [
              -158.39981355214616,
              21.32123529551194
            ],
            [
              -158.22066328843272,
              21.463446823448095
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "bodo-rost-cable",
        "name": "Bodo-Rost Cable",
        "color": "#395eab",
        "feature_id": "bodo-rost-cable-0",
        "coordinates": [
          13.223634135474441,
          67.33614800460242
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              12.070165685321228,
              67.50255969476498
            ],
            [
              12.15006562871929,
              67.4394931950778
            ],
            [
              13.9500643535834,
              67.2662194729948
            ],
            [
              14.4000640347993,
              67.28599754127353
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "malta-gozo-cable",
        "name": "Malta-Gozo Cable",
        "color": "#af2724",
        "feature_id": "malta-gozo-cable-0",
        "coordinates": [
          14.307914119340296,
          35.97958757021516
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              14.271364125971758,
              36.02048335169246
            ],
            [
              14.307914100079415,
              35.97958759177732
            ],
            [
              14.344464074187073,
              35.938670628992185
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "mauritius-and-rodrigues-submarine-cable-system-mars",
        "name": "Mauritius and Rodrigues Submarine Cable System (MARS)",
        "color": "#2fb34a",
        "feature_id": "mauritius-and-rodrigues-submarine-cable-system-mars-0",
        "coordinates": [
          60.49855604599219,
          -20.368445549627353
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              57.485483512684,
              -20.4739956609462
            ],
            [
              57.71253335183965,
              -20.679706953509026
            ],
            [
              58.050033112751606,
              -20.679706953509026
            ],
            [
              59.400032156399845,
              -20.57441905727606
            ],
            [
              61.650030562479856,
              -20.15254378601884
            ],
            [
              62.77502976551986,
              -19.68301691579981
            ],
            [
              63.33752936703985,
              -19.570614456630267
            ],
            [
              63.44822928861899,
              -19.674355986985773
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "palapa-ring-east",
        "name": "Palapa Ring East",
        "color": "#cfc12a",
        "feature_id": "palapa-ring-east-0",
        "coordinates": [
          135.66860583860839,
          -1.155981803828098
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              134.32497907886446,
              -1.681168935905106
            ],
            [
              133.87497939764833,
              -2.018492325403201
            ],
            [
              133.48208167334192,
              -2.202398136007332
            ]
          ],
          [
            [
              135.50163840155787,
              -3.372233814094784
            ],
            [
              135.56247820220838,
              -3.029995968008762
            ],
            [
              135.89997796312034,
              -2.580536704984041
            ],
            [
              136.12497780372834,
              -2.130918480960247
            ],
            [
              136.15700903103723,
              -1.756135138662984
            ],
            [
              136.3499776443364,
              -2.018492325403201
            ],
            [
              136.79997732555248,
              -2.018492325403201
            ],
            [
              136.96882720593763,
              -1.906058394384871
            ],
            [
              137.02502716612523,
              -1.681168935905106
            ],
            [
              136.79997732555248,
              -1.456253566768368
            ],
            [
              136.3499776443364,
              -1.456253566768368
            ],
            [
              135.89997796312034,
              -1.343787247896245
            ],
            [
              135.67497812251227,
              -1.175078194688609
            ],
            [
              135.61872816236033,
              -1.006508927978018
            ],
            [
              135.6385523670668,
              -0.729535713493476
            ],
            [
              135.44997828190444,
              -0.837630979982777
            ],
            [
              134.99997860068837,
              -0.837630979982777
            ],
            [
              134.32497907886446,
              -0.781386636225506
            ],
            [
              134.06198926516882,
              -0.861458343462516
            ],
            [
              134.2124791585605,
              -1.006358951224712
            ],
            [
              134.32497907886446,
              -1.231315750217505
            ],
            [
              134.32497907886446,
              -1.681168935905106
            ],
            [
              134.6624788397764,
              -2.130918480960247
            ],
            [
              135.1124785209923,
              -3.029995968008762
            ],
            [
              135.50163840155787,
              -3.372233814094784
            ]
          ],
          [
            [
              139.3964598611798,
              -7.10240453607264
            ],
            [
              139.0499757316325,
              -7.175078903541362
            ],
            [
              138.59997605041642,
              -7.063446338991156
            ],
            [
              138.26247628950446,
              -6.616650693475464
            ],
            [
              138.14997636920035,
              -5.945707155070551
            ],
            [
              138.12136310822027,
              -5.535665777980654
            ],
            [
              137.8124766082884,
              -5.497950688314972
            ],
            [
              137.24997700676838,
              -5.273944363641391
            ],
            [
              137.02497716616054,
              -5.049857167366866
            ],
            [
              136.88962726204366,
              -4.550247539955819
            ],
            [
              136.74372736540053,
              -4.377144375531941
            ],
            [
              135.7874780428164,
              -3.591554479962213
            ],
            [
              135.50163840155787,
              -3.372233814094784
            ]
          ],
          [
            [
              124.45350169691167,
              -8.160824687115849
            ],
            [
              124.64998593272028,
              -8.06711903252912
            ],
            [
              125.32498545454442,
              -8.06711903252912
            ],
            [
              125.66248521486048,
              -8.085683049445066
            ],
            [
              126.4499846575844,
              -8.122808517987067
            ],
            [
              126.8999843388003,
              -7.955717094334744
            ],
            [
              127.34998402001636,
              -7.955717094334744
            ],
            [
              127.69148768434198,
              -8.158093867003885
            ],
            [
              127.78788370920816,
              -8.150378145203206
            ],
            [
              127.91248362153638,
              -8.01142187411753
            ],
            [
              128.92498290427224,
              -8.01142187411753
            ],
            [
              129.59998242609637,
              -8.17849027894485
            ],
            [
              130.04998210731227,
              -8.17849027894485
            ],
            [
              130.8374815494403,
              -8.06711903252912
            ],
            [
              131.21306722087192,
              -8.052228527722747
            ],
            [
              130.8374815494403,
              -7.844284877164426
            ],
            [
              130.7249816291364,
              -7.621331259952996
            ],
            [
              130.8374815494403,
              -7.175078903541362
            ],
            [
              131.62498099156832,
              -6.281287025048499
            ],
            [
              132.29998051339228,
              -5.777839699209608
            ],
            [
              132.75212863058638,
              -5.626541451914449
            ],
            [
              132.6937302344564,
              -5.385957847172989
            ],
            [
              132.86453011346023,
              -5.128744870833515
            ],
            [
              133.20212987430145,
              -5.128744870833515
            ],
            [
              134.0999792382564,
              -5.945707155070551
            ],
            [
              134.26872911871246,
              -6.057590432424689
            ],
            [
              134.55017423183406,
              -6.194693482599263
            ],
            [
              134.77497876008053,
              -6.169450529574419
            ],
            [
              135.22497844129637,
              -5.833801119425374
            ],
            [
              136.01247788342445,
              -5.609922463067903
            ],
            [
              136.46247756464052,
              -5.385957847172989
            ],
            [
              136.68747740524836,
              -5.049857167366866
            ],
            [
              136.88962726204366,
              -4.550247539955819
            ]
          ],
          [
            [
              120.25301904757285,
              -9.645765890160561
            ],
            [
              120.59998880177616,
              -9.623336788342698
            ],
            [
              121.61248808451225,
              -10.288167912195235
            ],
            [
              121.87987070759587,
              -10.457810041841412
            ],
            [
              122.06248776572832,
              -10.39883957712749
            ],
            [
              122.84998720785634,
              -10.620064860363328
            ],
            [
              123.03524098287087,
              -10.733304228874601
            ],
            [
              123.07498704846441,
              -10.509472025995535
            ],
            [
              123.29998688907224,
              -10.343606996551301
            ],
            [
              123.58338668830928,
              -10.182939736570972
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "palapa-ring-west",
        "name": "Palapa Ring West",
        "color": "#34ae9c",
        "feature_id": "palapa-ring-west-0",
        "coordinates": [
          106.54848431714115,
          3.2374647686098967
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              102.08008004585733,
              1.489220605654509
            ],
            [
              101.7281271707802,
              1.637214579479661
            ],
            [
              101.44766236946415,
              1.665522797277134
            ]
          ],
          [
            [
              102.66702150506265,
              0.932556749588656
            ],
            [
              102.57030157358,
              1.220865326014433
            ],
            [
              102.08008004585733,
              1.489220605654509
            ]
          ],
          [
            [
              103.40488379485404,
              0.769834409364108
            ],
            [
              103.2750010743641,
              0.962392648220998
            ],
            [
              103.05000123375626,
              1.168506749040887
            ],
            [
              102.73892645412448,
              1.220865326014433
            ],
            [
              102.66702150506265,
              0.932556749588656
            ]
          ],
          [
            [
              103.96260058785819,
              1.134723598626692
            ],
            [
              103.87813064769747,
              1.106743972267083
            ],
            [
              103.50000091556822,
              0.962392648220998
            ],
            [
              103.40488379485404,
              0.769834409364108
            ]
          ],
          [
            [
              104.63544932995566,
              -0.162758734486573
            ],
            [
              104.40000027800002,
              -0.218910724747275
            ],
            [
              104.11875047724001,
              -0.331409329660175
            ],
            [
              103.46670093915824,
              -0.816543192375462
            ]
          ],
          [
            [
              108.98720405963192,
              0.906050180868988
            ],
            [
              108.7874971698561,
              1.243490076978134
            ],
            [
              108.44999740834825,
              2.967259208499712
            ],
            [
              108.44999740834825,
              3.416559618323163
            ],
            [
              108.37749745970777,
              3.940874826242975
            ],
            [
              108.14286872026699,
              3.945639298144044
            ],
            [
              107.99999772772824,
              3.840706312160734
            ],
            [
              107.32499820590417,
              3.491423322320486
            ],
            [
              106.87499852468827,
              3.266814816815753
            ],
            [
              106.21112899497965,
              3.207140223034116
            ],
            [
              105.97499916225613,
              2.592701464601845
            ],
            [
              105.74999932164823,
              2.143087178471944
            ],
            [
              104.86707219123898,
              1.476960063746986
            ],
            [
              104.62500011860826,
              1.299726182129234
            ],
            [
              104.28790035741284,
              1.117266982599347
            ],
            [
              103.96260058785819,
              1.134723598626692
            ],
            [
              104.03475053674654,
              0.906050180868988
            ],
            [
              104.0601005187884,
              0.793562652607278
            ],
            [
              104.17500043739219,
              0.341586322057481
            ],
            [
              104.40000027800002,
              0.006088583243098
            ],
            [
              104.63544932995566,
              -0.162758734486573
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "palapa-ring-middle",
        "name": "Palapa Ring Middle",
        "color": "#9d402f",
        "feature_id": "palapa-ring-middle-0",
        "coordinates": [
          126.30923830411129,
          4.037101645710038
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              122.55994835082257,
              -5.389069040125207
            ],
            [
              122.57073740567941,
              -5.439965887100271
            ],
            [
              122.59686238717225,
              -5.507088069175353
            ]
          ],
          [
            [
              122.5129774465972,
              -3.998469097245277
            ],
            [
              122.79378724766909,
              -3.984542968792908
            ],
            [
              122.90623716800829,
              -4.040605164746324
            ],
            [
              122.96248712816029,
              -4.0967133582636
            ],
            [
              123.12391279505485,
              -4.136112209115112
            ],
            [
              122.96248712816029,
              -4.377144375531941
            ],
            [
              122.84998720785634,
              -4.489307688629395
            ],
            [
              122.7937372477044,
              -4.657520205679987
            ],
            [
              122.72108104917467,
              -4.832406856866896
            ],
            [
              122.79173724912116,
              -4.825692499217524
            ],
            [
              122.8561102217839,
              -4.792242310211464
            ]
          ],
          [
            [
              122.79275724839854,
              -0.938788738945078
            ],
            [
              123.07498704846441,
              -1.118839506905204
            ],
            [
              123.29998688907224,
              -1.146958988943794
            ],
            [
              123.34022123556977,
              -1.301025233930005
            ],
            [
              123.41248680937619,
              -1.203172122899247
            ],
            [
              123.63748664998437,
              -1.231315750217505
            ],
            [
              123.6937366101362,
              -1.400021081752755
            ],
            [
              123.52752579038147,
              -1.600245159061188
            ],
            [
              123.69378661010106,
              -1.624992367351439
            ],
            [
              124.03128637101301,
              -1.849888622119352
            ],
            [
              124.31248617180833,
              -2.130918480960247
            ],
            [
              124.53748601241617,
              -2.130918480960247
            ],
            [
              124.64998593272028,
              -2.018492325403201
            ],
            [
              124.77400928236094,
              -1.826803651259553
            ],
            [
              124.98748569363224,
              -2.018492325403201
            ],
            [
              125.6624852154562,
              -2.355745736646099
            ],
            [
              125.88748505606551,
              -2.580536704984041
            ],
            [
              126.11248489667227,
              -2.580536704984041
            ],
            [
              126.16873485682439,
              -2.468145972656231
            ],
            [
              126.11248489667227,
              -2.299542189691408
            ],
            [
              125.93699502099113,
              -2.201477967855737
            ]
          ],
          [
            [
              127.36131213699136,
              0.795808528724933
            ],
            [
              127.37810900009228,
              0.739317546778636
            ],
            [
              127.40408554419028,
              0.674041311933507
            ],
            [
              127.46248394032031,
              0.68107206531252
            ],
            [
              127.56141387023749,
              0.734977909038349
            ]
          ],
          [
            [
              124.83963579837055,
              1.490779296094786
            ],
            [
              124.7624858530244,
              1.805788280129235
            ],
            [
              125.2124855342403,
              2.592701464601845
            ],
            [
              125.37293463932659,
              2.754634201538266
            ],
            [
              125.2124855342403,
              2.817450442654064
            ],
            [
              125.2124855342403,
              3.266814816815753
            ],
            [
              125.48553221581139,
              3.600934571622815
            ],
            [
              125.32498545454442,
              3.715978119297972
            ],
            [
              125.43748537484831,
              3.940475772228723
            ],
            [
              126.4499846575844,
              4.052702097268195
            ],
            [
              126.71726962448716,
              4.042960839837178
            ],
            [
              126.8999843388003,
              3.940475772228723
            ],
            [
              127.12498417940836,
              3.715978119297972
            ],
            [
              127.34998402001636,
              3.266814816815753
            ],
            [
              127.91248362153638,
              2.367912558705314
            ],
            [
              128.1374834621444,
              2.255504211923693
            ],
            [
              128.4007645256335,
              2.365668382611432
            ],
            [
              128.1937334222964,
              2.143087178471944
            ],
            [
              128.1374834621444,
              1.918228780215685
            ],
            [
              127.96785467606097,
              1.714620455999579
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "ulysses-2",
        "name": "Ulysses 2",
        "color": "#b96528",
        "feature_id": "ulysses-2-0",
        "coordinates": [
          3.1715390842772706,
          52.55491468334145
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              1.729273010906667,
              52.46882263773057
            ],
            [
              2.250072641967279,
              52.55491468334145
            ],
            [
              3.825071526223184,
              52.55491468334145
            ],
            [
              4.275071207439254,
              52.52070133452017
            ],
            [
              4.613670967572091,
              52.45850170510121
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "natitua",
        "name": "Natitua",
        "color": "#b75c28",
        "feature_id": "natitua-0",
        "coordinates": [
          -144.18136825994162,
          -13.277979584962026
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -143.99982375323387,
              -16.953454989810012
            ],
            [
              -143.43732415171385,
              -17.061035019881473
            ],
            [
              -142.42482486897788,
              -17.49073216111117
            ],
            [
              -141.18732574563384,
              -18.026426383713385
            ],
            [
              -140.90812594342162,
              -18.21574609011921
            ]
          ],
          [
            [
              -145.7998224780979,
              -15.657788279357618
            ],
            [
              -145.7998224780979,
              -15.874323281689755
            ],
            [
              -145.7435733833309,
              -16.090625820394905
            ],
            [
              -145.6245784616174,
              -16.310348412455653
            ]
          ],
          [
            [
              -146.9248216811379,
              -15.54943449312637
            ],
            [
              -146.86857255795104,
              -15.657788279357618
            ],
            [
              -146.67384529643192,
              -15.741365822737464
            ]
          ],
          [
            [
              -146.58732192022583,
              -15.54943449312637
            ],
            [
              -146.64357272302706,
              -15.441023659568003
            ],
            [
              -146.78614990437427,
              -15.289888093159902
            ]
          ],
          [
            [
              -147.14982152174582,
              -15.54943449312637
            ],
            [
              -146.9248216811379,
              -15.54943449312637
            ],
            [
              -146.58732192022583,
              -15.54943449312637
            ],
            [
              -145.7998224780979,
              -15.657788279357618
            ],
            [
              -145.34982279688208,
              -15.982503786245815
            ],
            [
              -145.12482295627387,
              -16.30669306561819
            ],
            [
              -144.56232335475386,
              -16.738110438702392
            ],
            [
              -143.99982375323387,
              -16.953454989810012
            ],
            [
              -143.77482391262583,
              -16.84581334779884
            ],
            [
              -143.77482391262583,
              -16.738110438702392
            ],
            [
              -143.86720665968107,
              -16.539560977104543
            ]
          ],
          [
            [
              -147.14982152174582,
              -15.22403228464728
            ],
            [
              -147.37482136235388,
              -15.115452462868433
            ],
            [
              -147.65128601025373,
              -15.116206681570276
            ]
          ],
          [
            [
              -139.38732702076982,
              -9.845097085826893
            ],
            [
              -139.04982725985775,
              -9.845097085826893
            ],
            [
              -139.02111634269687,
              -9.754639758300243
            ]
          ],
          [
            [
              -145.3498227968819,
              -14.135775375064753
            ],
            [
              -145.12482295627404,
              -14.244842547315386
            ],
            [
              -144.97218634565309,
              -14.44976861091078
            ]
          ],
          [
            [
              -145.9123223984019,
              -14.789380798539128
            ],
            [
              -145.9123223984019,
              -14.57172649133264
            ],
            [
              -145.95812314720624,
              -14.402858435885264
            ]
          ],
          [
            [
              -140.14211164232333,
              -8.860524087732387
            ],
            [
              -139.72482678168188,
              -9.290424301035614
            ],
            [
              -139.38732702076982,
              -9.845097085826893
            ],
            [
              -139.49982694107385,
              -10.177457430361159
            ],
            [
              -140.39982630350582,
              -11.06203210990941
            ],
            [
              -144.8998231156658,
              -13.698987269610853
            ],
            [
              -145.3498227968819,
              -14.135775375064753
            ],
            [
              -145.4623227171859,
              -14.57172649133264
            ],
            [
              -145.9123223984019,
              -14.789380798539128
            ],
            [
              -146.69982184052995,
              -14.789380798539128
            ],
            [
              -147.14982152174582,
              -15.22403228464728
            ],
            [
              -147.14982152174582,
              -15.54943449312637
            ],
            [
              -147.37482136235388,
              -15.874323281689755
            ],
            [
              -148.72482040600192,
              -17.383402005942372
            ],
            [
              -149.30812077403647,
              -17.723342219804465
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "curie",
        "name": "Curie",
        "color": "#b2692e",
        "feature_id": "curie-0",
        "coordinates": [
          -88.4513402213149,
          6.516022999881244
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -85.94986487636933,
              0.568578852526286
            ],
            [
              -82.34986742664147,
              2.367912558705314
            ],
            [
              -80.09986902056127,
              5.061986954416028
            ],
            [
              -79.42486949873734,
              7.298762754459602
            ],
            [
              -79.64986933934539,
              8.190543417795567
            ],
            [
              -79.56661939832038,
              8.950317108800647
            ]
          ],
          [
            [
              -71.62043502747201,
              -33.0455412324782
            ],
            [
              -72.67487428049732,
              -31.851465665577166
            ],
            [
              -79.64986933934539,
              -18.453813775777263
            ],
            [
              -84.14986615150539,
              -11.50333384598423
            ],
            [
              -85.49986519515335,
              -6.616650693475464
            ],
            [
              -85.94986487636933,
              0.568578852526286
            ],
            [
              -87.06475643430807,
              5.534179292238576
            ],
            [
              -92.69986009460936,
              9.524411345019587
            ],
            [
              -98.09985626920162,
              12.615395567393307
            ],
            [
              -104.39985180622548,
              16.10232559580288
            ],
            [
              -108.44984893716952,
              18.67864702215462
            ],
            [
              -114.29984479297748,
              22.469443964829594
            ],
            [
              -118.7998416051375,
              27.763588526057674
            ],
            [
              -119.69984096756966,
              31.28673881439167
            ],
            [
              -119.24984128635367,
              32.81231878328768
            ],
            [
              -118.79984160513769,
              33.75276987113051
            ],
            [
              -118.41596126184774,
              33.919920018514716
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "venezuelan-festoon",
        "name": "Venezuelan Festoon",
        "color": "#3cac48",
        "feature_id": "venezuelan-festoon-0",
        "coordinates": [
          -67.69388910487417,
          10.642183589613154
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -71.45109585055843,
              10.395928632135309
            ],
            [
              -71.54987507745732,
              10.468102245057224
            ],
            [
              -71.5984101993246,
              10.688176132322553
            ],
            [
              -71.54987507745732,
              11.073982781226704
            ],
            [
              -71.09987539624133,
              11.405009147532837
            ],
            [
              -70.42487587441731,
              11.625479959569759
            ],
            [
              -70.2043160306639,
              11.708782466419052
            ],
            [
              -69.86237627289731,
              11.625479959569759
            ],
            [
              -69.67780609114857,
              11.40280739842251
            ],
            [
              -69.52487651198534,
              11.515266158038685
            ],
            [
              -69.2998766713773,
              11.625479959569759
            ],
            [
              -68.7373770698573,
              11.57037848436471
            ],
            [
              -68.39987730894534,
              11.294709319565555
            ],
            [
              -68.28449653130713,
              10.928560854771177
            ],
            [
              -68.06237754803328,
              10.742581675476316
            ],
            [
              -68.0095943823004,
              10.478139223021032
            ],
            [
              -67.72487778712132,
              10.632033208117747
            ],
            [
              -67.38737802620926,
              10.742581675476316
            ],
            [
              -67.16237818560131,
              10.742581675476316
            ],
            [
              -66.87829838684605,
              10.606498174462416
            ],
            [
              -66.59987858408131,
              10.742581675476316
            ],
            [
              -66.26237882316926,
              10.742581675476316
            ],
            [
              -66.14987890286532,
              10.632033208117747
            ],
            [
              -66.09812112703104,
              10.480347852297058
            ],
            [
              -65.69987922164925,
              10.410816505402636
            ],
            [
              -65.24987954043327,
              10.410816505402636
            ],
            [
              -64.6448995002568,
              10.20251427017962
            ],
            [
              -64.40813013673643,
              10.410816505402636
            ],
            [
              -64.18210685935347,
              10.454323438205137
            ],
            [
              -64.40613013815326,
              10.632033208117747
            ],
            [
              -64.34988017800121,
              10.797840764398018
            ],
            [
              -64.01238041708925,
              10.742581675476316
            ],
            [
              -63.84763444004682,
              10.955215720095936
            ],
            [
              -63.449880815569244,
              10.853089690745378
            ],
            [
              -63.25051572242633,
              10.66677586312187
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "fibra-optica-austral",
        "name": "Fibra Optica Austral",
        "color": "#23a94c",
        "feature_id": "fibra-optica-austral-0",
        "coordinates": [
          -75.75521397094205,
          -50.41720732696811
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -71.54987507745732,
              -53.36079173838132
            ],
            [
              -71.21237531654535,
              -53.29360179765794
            ],
            [
              -70.9333325752588,
              -53.16665547850178
            ]
          ],
          [
            [
              -76.04987188961734,
              -47.948158003955456
            ],
            [
              -75.14987252718537,
              -47.87275031309153
            ],
            [
              -74.69987284596938,
              -47.79723273066813
            ],
            [
              -74.2498731647534,
              -47.79723273066813
            ],
            [
              -73.53688749863102,
              -47.8012146645996
            ]
          ],
          [
            [
              -72.95341123369906,
              -41.460374244790415
            ],
            [
              -73.34987380232134,
              -41.65126697659352
            ],
            [
              -73.79987348353733,
              -41.73527248346838
            ],
            [
              -74.2498731647534,
              -41.90295427099872
            ],
            [
              -75.14987252718537,
              -43.55557627361341
            ],
            [
              -76.04987188961734,
              -46.72843318742704
            ],
            [
              -76.04987188961734,
              -47.948158003955456
            ],
            [
              -75.59987220840135,
              -51.718874274733054
            ],
            [
              -75.14987252718537,
              -52.27301020156594
            ],
            [
              -74.13737324444938,
              -52.820304304805035
            ],
            [
              -72.89987412110536,
              -53.15890429141288
            ],
            [
              -71.54987507745732,
              -53.36079173838132
            ],
            [
              -71.09987539624133,
              -53.894509657193765
            ],
            [
              -70.19987603380928,
              -54.42149711540557
            ],
            [
              -68.84987699016132,
              -54.94179496686152
            ],
            [
              -68.1748774683373,
              -55.00636393692005
            ],
            [
              -67.60586142678865,
              -54.935200597435625
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "djibouti-africa-regional-express-1-dare1",
        "name": "Djibouti Africa Regional Express 1 (DARE1)",
        "color": "#a8b737",
        "feature_id": "djibouti-africa-regional-express-1-dare1-0",
        "coordinates": [
          53.0298696643689,
          6.357170959077223
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              45.34418211369595,
              2.041205223228673
            ],
            [
              46.800041082351555,
              2.030661890474562
            ],
            [
              49.500039169647664,
              1.468426767332062
            ]
          ],
          [
            [
              49.18792939074925,
              11.275556936623303
            ],
            [
              48.82503964782373,
              11.735650161405744
            ],
            [
              48.600039807215495,
              11.95585820711483
            ]
          ],
          [
            [
              44.55004267627157,
              11.294709319565555
            ],
            [
              45.450042038703714,
              11.184367066436806
            ],
            [
              48.600039807215495,
              11.95585820711483
            ],
            [
              52.650036938159616,
              12.944533868662859
            ],
            [
              54.000035981807684,
              13.163718917913684
            ],
            [
              55.12503518484766,
              12.615395567393307
            ],
            [
              54.450035663023755,
              9.524411345019587
            ],
            [
              52.650036938755704,
              5.510071711803135
            ],
            [
              49.500039169647664,
              1.468426767332062
            ],
            [
              45.67504187931155,
              -1.456253566768368
            ],
            [
              42.30004427019156,
              -3.703826470668162
            ],
            [
              39.672896131287985,
              -4.052924364763157
            ]
          ],
          [
            [
              44.55004267627157,
              11.294709319565555
            ],
            [
              43.6500433138396,
              11.510285103755706
            ],
            [
              43.147993669496344,
              11.594869371447714
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "peace-cable",
        "name": "PEACE Cable",
        "color": "#939597",
        "feature_id": "peace-cable-0",
        "coordinates": [
          80.95229027940306,
          2.381156735431233
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              58.950032475183775,
              15.669513225155328
            ],
            [
              60.975031037675876,
              15.886035719079104
            ],
            [
              65.75491565746177,
              15.590227667792675
            ],
            [
              69.80491674977293,
              13.251828813229451
            ],
            [
              71.31957572416837,
              9.118188242772504
            ],
            [
              73.07152247079176,
              6.622458884185221
            ],
            [
              73.5146861662106,
              6.443299995633765
            ],
            [
              74.25002163593186,
              5.659359572411404
            ],
            [
              78.30001876747187,
              3.266814816815753
            ],
            [
              79.65001781052402,
              2.742538766119736
            ],
            [
              81.00001685476795,
              2.367912558705314
            ],
            [
              85.50001366692797,
              -0.781332308789217
            ],
            [
              90.00001047908799,
              0.118642751260523
            ],
            [
              92.7000085663839,
              4.164912849976844
            ],
            [
              94.27500745064,
              5.061986954416028
            ],
            [
              95.40000665367998,
              5.845915088460174
            ],
            [
              97.42500521915215,
              5.398081130463737
            ],
            [
              97.65000505975999,
              5.286069860821101
            ],
            [
              98.43750450188801,
              4.613591578862867
            ],
            [
              99.78750354553605,
              3.266814816815753
            ],
            [
              101.25000250948804,
              1.974446286104069
            ],
            [
              102.15000187192001,
              1.749565394075489
            ],
            [
              102.68279723997185,
              1.502034277710749
            ],
            [
              103.34065102845304,
              1.159233669139525
            ],
            [
              103.64621081139578,
              1.338645835654745
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "peace-cable",
        "name": "PEACE Cable",
        "color": "#0090c5",
        "feature_id": "peace-cable-1",
        "coordinates": [
          14.870896923788365,
          33.51897987858331
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              45.450042038703714,
              11.515266158038685
            ],
            [
              45.33754211780371,
              11.073982781226704
            ],
            [
              45.01088234980861,
              10.435118748992778
            ]
          ],
          [
            [
              32.65318110412005,
              29.113614162980156
            ],
            [
              31.725051761615447,
              29.197363639715576
            ],
            [
              31.050052239791512,
              29.442584645837485
            ],
            [
              30.375052717967577,
              29.80930712715275
            ],
            [
              29.98130299690345,
              30.320335942474912
            ],
            [
              29.700933195520264,
              31.072270031660224
            ]
          ],
          [
            [
              37.91254737893155,
              20.375041253465525
            ],
            [
              38.100047245508904,
              20.515592009193767
            ],
            [
              39.182756478507656,
              21.481533475503085
            ]
          ],
          [
            [
              11.981315748271214,
              35.419780517080454
            ],
            [
              13.668814552823221,
              35.694348446523605
            ],
            [
              14.350104069595545,
              35.95240101739202
            ]
          ],
          [
            [
              27.900054471279333,
              31.861808602270827
            ],
            [
              31.500051920411693,
              34.25018044028607
            ],
            [
              32.46665123625965,
              34.76657169708608
            ]
          ],
          [
            [
              50.400038532079606,
              1.468426767332062
            ],
            [
              52.200037256943745,
              -2.130918480960247
            ],
            [
              54.000035981807684,
              -3.928327304142726
            ],
            [
              55.44505495814283,
              -4.617611322442229
            ]
          ],
          [
            [
              44.55004267627157,
              11.625479959569759
            ],
            [
              43.24223110273755,
              12.615395567393307
            ],
            [
              43.15785616250969,
              12.834868817846598
            ],
            [
              42.86254387230764,
              13.054150695298716
            ],
            [
              42.24379431063568,
              13.929303843271725
            ],
            [
              41.737544669267635,
              14.801154224791475
            ],
            [
              40.275045705317666,
              16.53419619825962
            ],
            [
              39.26254642257959,
              18.251816319028308
            ],
            [
              37.91254737893155,
              20.375041253465525
            ],
            [
              37.1250479368035,
              22.05298561667763
            ],
            [
              35.887548813459574,
              24.12261698700334
            ],
            [
              34.87504953072349,
              25.754704263415306
            ],
            [
              34.42504984891153,
              26.562513149236622
            ],
            [
              33.86255024739151,
              27.364667993860166
            ],
            [
              33.370838095731244,
              28.161052262220892
            ],
            [
              32.65318110412005,
              29.113614162980156
            ],
            [
              31.725051761615447,
              29.099110450974905
            ],
            [
              31.050052239791512,
              29.34456698948989
            ],
            [
              30.375052717967577,
              29.711645057947948
            ],
            [
              29.98130299690345,
              30.223089656566856
            ],
            [
              29.700933195520264,
              31.072270031660224
            ]
          ],
          [
            [
              29.700933195520264,
              31.072270031660224
            ],
            [
              27.900054471279333,
              31.861808602270827
            ],
            [
              25.20005638398345,
              32.575628370353314
            ],
            [
              22.05005861487558,
              33.315153958129144
            ],
            [
              19.35006052817539,
              33.18971466460036
            ],
            [
              16.65006244087931,
              32.81231878328768
            ],
            [
              14.4000640347993,
              33.70598849685862
            ],
            [
              11.981315748271214,
              35.419780517080454
            ],
            [
              11.812565867807308,
              35.78566189952613
            ],
            [
              11.250066265095512,
              37.232354321556215
            ],
            [
              10.348617229769246,
              37.500588446053314
            ],
            [
              9.450067540827291,
              37.500588446053314
            ],
            [
              9.000067859611391,
              37.500588446053314
            ],
            [
              7.200069135343199,
              37.94551049545976
            ],
            [
              6.525069613519264,
              38.651811712711236
            ],
            [
              5.625070251087294,
              41.744358789482135
            ],
            [
              5.372530429989041,
              43.29362778902916
            ]
          ],
          [
            [
              55.350035025455725,
              13.929303843271725
            ],
            [
              55.350035025455725,
              12.615395567393307
            ],
            [
              54.900035344239626,
              9.524411345019587
            ],
            [
              53.55003630118787,
              5.510071711803135
            ],
            [
              50.400038532079606,
              1.468426767332062
            ],
            [
              45.900041719919614,
              -1.681168935905106
            ],
            [
              42.30004427019156,
              -3.928327304142726
            ],
            [
              39.672896131287985,
              -4.052924364763157
            ]
          ],
          [
            [
              67.02854675228852,
              24.889731701235718
            ],
            [
              65.25002801220771,
              22.884654113882362
            ],
            [
              64.35002864977574,
              20.375041253465525
            ],
            [
              60.97503104065572,
              16.965102599435824
            ],
            [
              58.950032475183775,
              15.669513225155328
            ],
            [
              55.350035025455725,
              13.929303843271725
            ]
          ],
          [
            [
              55.350035025455725,
              13.929303843271725
            ],
            [
              48.600039807215495,
              12.285833556268273
            ],
            [
              45.450042038703714,
              11.515266158038685
            ],
            [
              44.55004267627157,
              11.625479959569759
            ]
          ],
          [
            [
              9.450067540827291,
              37.500588446053314
            ],
            [
              9.675067381435326,
              37.35168786972491
            ],
            [
              9.867357245811377,
              37.27681625347506
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "jupiter",
        "name": "JUPITER",
        "color": "#63c5b9",
        "feature_id": "jupiter-0",
        "coordinates": [
          -148.28846974678893,
          43.44389200911036
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -123.95633795222734,
              45.231085444465265
            ],
            [
              -125.09983714216165,
              44.694829089578064
            ],
            [
              -129.59983395432175,
              43.40114497315386
            ],
            [
              -138.48723921702876,
              41.40772623743587
            ]
          ],
          [
            [
              141.9749736595364,
              34.40502275071583
            ],
            [
              141.07497429710443,
              32.052708023486105
            ],
            [
              140.62497461588836,
              30.901396088515583
            ],
            [
              138.82497589102448,
              27.763588526057674
            ],
            [
              137.24997700676838,
              26.159307970773796
            ],
            [
              133.19997987582443,
              22.469443964829594
            ],
            [
              127.79998370123226,
              17.39502263470061
            ],
            [
              124.64998593272028,
              15.23578178303569
            ],
            [
              123.52498672968031,
              14.256644994553568
            ],
            [
              122.95008713694452,
              14.11652289884887
            ]
          ],
          [
            [
              140.84997445649643,
              34.40502275071583
            ],
            [
              138.59997605041642,
              33.75276987113051
            ],
            [
              137.69997668798445,
              33.84625607000376
            ],
            [
              136.87399727311598,
              34.33682825203164
            ]
          ],
          [
            [
              179.9999271925697,
              44.05150153460201
            ],
            [
              172.79992073307184,
              44.05150153460201
            ],
            [
              160.19996074878455,
              40.72920412488665
            ],
            [
              149.39996839960057,
              35.78566189952613
            ],
            [
              143.09997286257644,
              34.59045588265229
            ],
            [
              141.9749736595364,
              34.40502275071583
            ],
            [
              140.84997445649643,
              34.40502275071583
            ],
            [
              140.39997477468464,
              34.69072647741018
            ],
            [
              140.18903742411456,
              34.89859296336232
            ],
            [
              139.97546699999984,
              35.005433
            ]
          ],
          [
            [
              -118.39945344740454,
              33.815511031610924
            ],
            [
              -120.59984033000163,
              33.70598849685862
            ],
            [
              -122.39983905486565,
              33.93964008831958
            ],
            [
              -129.59983395432175,
              37.58978657360316
            ],
            [
              -138.48723921702876,
              41.40772623743587
            ],
            [
              -151.19983391146837,
              44.04871607104835
            ],
            [
              -179.99981350929244,
              44.04871607104835
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "wall-li",
        "name": "WALL-LI",
        "color": "#939597",
        "feature_id": "wall-li-0",
        "coordinates": [
          -73.5534947989728,
          40.29093211392018
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -73.58682634802493,
              40.75710357176081
            ],
            [
              -73.46237372262536,
              40.47295490579842
            ],
            [
              -73.51862368277732,
              40.30157665795872
            ],
            [
              -73.79987348353733,
              40.21572406083388
            ],
            [
              -74.06286329723287,
              40.15283384719578
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "crosslake-fibre",
        "name": "Crosslake Fibre",
        "color": "#bfa82f",
        "feature_id": "crosslake-fibre-0",
        "coordinates": [
          -78.99633893060788,
          43.35045980757377
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -79.38531972876184,
              43.644625404178896
            ],
            [
              -79.08736973782538,
              43.48282788090381
            ],
            [
              -78.91861985736939,
              43.23744835244082
            ],
            [
              -78.86236989721743,
              43.07331078300331
            ],
            [
              -78.8784612465251,
              42.88543648440384
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "gtmo-pr",
        "name": "GTMO-PR",
        "color": "#28a557",
        "feature_id": "gtmo-pr-0",
        "coordinates": [
          -71.5121630761125,
          17.219005195339975
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -66.1857511006541,
              18.472993987729723
            ],
            [
              -66.48737866377729,
              18.731925895976644
            ],
            [
              -67.0498782652973,
              18.89166158430325
            ],
            [
              -67.72487778712132,
              18.465364393137207
            ],
            [
              -68.06237754922526,
              18.251816319028308
            ],
            [
              -68.51237722924927,
              17.823934412537824
            ],
            [
              -69.2998766713773,
              17.716802179008738
            ],
            [
              -70.64987571502535,
              17.287636299591014
            ],
            [
              -71.99987475867339,
              17.180187287481402
            ],
            [
              -74.69987284596938,
              17.39502263470061
            ],
            [
              -75.14987252718537,
              18.251816319028308
            ],
            [
              -75.26237244808537,
              19.670379638527862
            ],
            [
              -75.15816600409609,
              19.939616197540232
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "gtmo-1",
        "name": "GTMO-1",
        "color": "#8bc63f",
        "feature_id": "gtmo-1-0",
        "coordinates": [
          -74.95689086841348,
          24.464180286998896
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -80.14394128072004,
              26.05231628642727
            ],
            [
              -79.64986933934539,
              25.95717997876443
            ],
            [
              -78.41237021600136,
              26.159307970773796
            ],
            [
              -77.5123708535694,
              25.653336613275968
            ],
            [
              -76.8311713361375,
              25.813358882055525
            ],
            [
              -76.24064175447417,
              25.599112345903848
            ],
            [
              -75.59987220840135,
              25.14511970405232
            ],
            [
              -74.92487268657733,
              24.430271928048597
            ],
            [
              -74.69987284596938,
              24.01990020343257
            ],
            [
              -74.58737292566536,
              23.711258142484382
            ],
            [
              -74.58737292566536,
              23.298598065875808
            ],
            [
              -74.69987284596938,
              22.884654113882362
            ],
            [
              -74.47487300536135,
              22.05298561667763
            ],
            [
              -74.02487332414536,
              21.21639789994191
            ],
            [
              -73.91237340384134,
              20.796306105108954
            ],
            [
              -74.02482332418074,
              20.375041253465525
            ],
            [
              -74.09132327707152,
              20.183174721867687
            ],
            [
              -74.30612312490535,
              19.952622905164304
            ],
            [
              -74.69987284596938,
              19.74098736552503
            ],
            [
              -74.92487268657733,
              19.793828591740805
            ],
            [
              -75.15816600409609,
              19.939616197540232
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "havhingstennorth-sea-connect-nsc",
        "name": "Havhingsten/North Sea Connect (NSC)",
        "color": "#3075bb",
        "feature_id": "havhingstennorth-sea-connect-nsc-0",
        "coordinates": [
          3.3008231274255877,
          55.4680419128899
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              8.193168431226582,
              55.764614544717034
            ],
            [
              7.650068816559269,
              55.526080187888624
            ],
            [
              5.400070409883341,
              55.5473006113408
            ],
            [
              3.150072004399249,
              55.462350188098384
            ],
            [
              -0.899925126544701,
              55.07780072164758
            ],
            [
              -1.617778330734808,
              54.978249214505325
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "havhingstenceltixconnect-2-cc-2",
        "name": "Havhingsten/CeltixConnect-2 (CC-2)",
        "color": "#3567b1",
        "feature_id": "havhingstenceltixconnect-2-cc-2-0",
        "coordinates": [
          -4.583345454615824,
          53.82919426492603
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -4.499922576272752,
              53.83535026397598
            ],
            [
              -4.499722576414371,
              53.96791403087386
            ],
            [
              -4.566622529021913,
              54.10028616652036
            ]
          ],
          [
            [
              -4.949922257488737,
              53.802143574575354
            ],
            [
              -4.949922257488737,
              53.96791403087386
            ],
            [
              -4.760422391732305,
              54.08721396659914
            ]
          ],
          [
            [
              -6.085471453650968,
              53.549690264818906
            ],
            [
              -5.624921779312757,
              53.60536470257486
            ],
            [
              -4.949922257488737,
              53.802143574575354
            ],
            [
              -4.499922576272752,
              53.83535026397598
            ],
            [
              -3.599923213840782,
              53.83535026397598
            ],
            [
              -3.050753602877677,
              53.80897597127557
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "cobracable",
        "name": "COBRAcable",
        "color": "#59af46",
        "feature_id": "cobracable-0",
        "coordinates": [
          7.426709494486715,
          54.904227331954615
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              8.718388991504298,
              55.523118197393956
            ],
            [
              8.10006849777534,
              55.526080187888624
            ],
            [
              7.650068816559269,
              55.33458061322915
            ],
            [
              6.975069294735334,
              54.03403825672413
            ],
            [
              6.816022175096919,
              53.44280469148259
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "kanawa",
        "name": "Kanawa",
        "color": "#6fc6b1",
        "feature_id": "kanawa-0",
        "coordinates": [
          -56.102765001579144,
          10.704754187067351
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -61.11213835606199,
              14.62900014542359
            ],
            [
              -61.1998324095247,
              14.474609432069002
            ],
            [
              -61.08738248918522,
              14.365653759228536
            ],
            [
              -60.9748825688812,
              14.365653759228536
            ],
            [
              -59.849883365841194,
              13.929303843271725
            ],
            [
              -58.049884640977254,
              12.615395567393307
            ],
            [
              -54.89988687246519,
              9.524411345019587
            ],
            [
              -52.87488830699313,
              5.957818681088611
            ],
            [
              -52.64555499839999,
              5.168298539392282
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "fly-lion3",
        "name": "FLY-LION3",
        "color": "#c7b22e",
        "feature_id": "fly-lion3-0",
        "coordinates": [
          44.02971817153852,
          -12.264700887720124
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              43.24330360197786,
              -11.70058928227246
            ],
            [
              43.14379367247156,
              -11.943944931746927
            ],
            [
              43.31254355292762,
              -12.163983680780518
            ],
            [
              43.537543393535486,
              -12.163983680780518
            ],
            [
              43.87504315444764,
              -12.163983680780518
            ],
            [
              44.55004267627157,
              -12.603512104971372
            ],
            [
              45.000042357487644,
              -12.822995625629687
            ],
            [
              45.16576224009026,
              -12.81709644553311
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "nelson-levin",
        "name": "Nelson-Levin",
        "color": "#403c97",
        "feature_id": "nelson-levin-0",
        "coordinates": [
          174.11213952095213,
          -40.54922829806904
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              173.28395005607203,
              -41.272264261522736
            ],
            [
              173.47495134465672,
              -40.890286050098695
            ],
            [
              173.9249510258728,
              -40.54922829806904
            ],
            [
              174.82495038830476,
              -40.54922829806904
            ],
            [
              175.28531582578577,
              -40.625274631911594
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "png-lng",
        "name": "PNG LNG",
        "color": "#cf297b",
        "feature_id": "png-lng-0",
        "coordinates": [
          145.69032833274045,
          -8.492326203968872
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              144.24020634990947,
              -7.413515958216607
            ],
            [
              144.89997158744055,
              -7.955717094334744
            ],
            [
              145.57497110926445,
              -8.401139048122928
            ],
            [
              146.6999703123045,
              -9.290424301035614
            ],
            [
              147.1885196909836,
              -9.479589292697398
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "kumul-domestic-submarine-cable-system",
        "name": "Kumul Domestic Submarine Cable System",
        "color": "#a76c35",
        "feature_id": "kumul-domestic-submarine-cable-system-0",
        "coordinates": [
          150.11278431665923,
          -9.549626145302739
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              145.34997126865645,
              -3.479268678969987
            ],
            [
              145.79997094987252,
              -2.130918480960247
            ],
            [
              146.6999703123045,
              -1.681168935905106
            ],
            [
              147.14996999352056,
              -1.79361712035498
            ],
            [
              147.27907146456383,
              -2.034985895581542
            ]
          ],
          [
            [
              152.2124664072006,
              -3.928327304142726
            ],
            [
              152.2124664072006,
              -4.040555289062013
            ],
            [
              152.27457573820178,
              -4.342382000415256
            ]
          ],
          [
            [
              151.1999671244645,
              -3.928327304142726
            ],
            [
              152.2124664072006,
              -3.928327304142726
            ],
            [
              152.43746624780843,
              -4.15276774801373
            ],
            [
              152.54996616811255,
              -4.377144375531941
            ],
            [
              152.66246608841666,
              -4.825692499217524
            ],
            [
              152.99996584932862,
              -5.049857167366866
            ],
            [
              153.8999652117606,
              -4.825692499217524
            ],
            [
              154.79996457419256,
              -4.825692499217524
            ],
            [
              155.24996425540863,
              -5.273944363641391
            ],
            [
              155.4749640960167,
              -5.721872747834027
            ],
            [
              155.56783512397587,
              -6.225662994684143
            ]
          ],
          [
            [
              150.52496760264054,
              -4.825692499217524
            ],
            [
              150.41246768233665,
              -5.273944363641391
            ],
            [
              150.13873740749958,
              -5.551120494376022
            ]
          ],
          [
            [
              146.24997063108842,
              -4.825692499217524
            ],
            [
              146.6999703123045,
              -4.601453764837203
            ],
            [
              149.39996839960057,
              -4.601453764837203
            ],
            [
              150.52496760264054,
              -4.825692499217524
            ],
            [
              151.1999671244645,
              -3.928327304142726
            ],
            [
              149.84996808081647,
              -2.580536704984041
            ],
            [
              149.84996808081647,
              -2.130918480960247
            ],
            [
              150.52496760264054,
              -2.130918480960247
            ],
            [
              150.80856115174018,
              -2.578139138209878
            ]
          ],
          [
            [
              143.6583709045021,
              -3.580053610579313
            ],
            [
              143.9999722250084,
              -3.479268678969987
            ],
            [
              144.44997190622448,
              -3.029995968008762
            ],
            [
              144.44997190622448,
              -2.580536704984041
            ]
          ],
          [
            [
              141.2999741377125,
              -2.130918480960247
            ],
            [
              141.29987648153164,
              -2.68969840259993
            ]
          ],
          [
            [
              145.78474096066176,
              -5.23368424614938
            ],
            [
              146.02497079048058,
              -5.049857167366866
            ],
            [
              146.24997063108842,
              -4.825692499217524
            ],
            [
              146.24997063108842,
              -4.377144375531941
            ],
            [
              145.34997126865645,
              -3.479268678969987
            ],
            [
              144.44997190622448,
              -2.580536704984041
            ],
            [
              142.19997350014447,
              -2.130918480960247
            ],
            [
              141.2999741377125,
              -2.130918480960247
            ],
            [
              140.84997445649643,
              -2.355745736646099
            ],
            [
              140.6691145846195,
              -2.591565453841469
            ]
          ],
          [
            [
              146.99293885476297,
              -6.739375698979131
            ],
            [
              147.3749698341284,
              -7.063446338991156
            ],
            [
              148.04996935595253,
              -7.062898168976972
            ]
          ],
          [
            [
              148.29352387091618,
              -8.604445748342641
            ],
            [
              148.9499687183845,
              -8.401139048122928
            ],
            [
              149.39996839960057,
              -8.401139048122928
            ]
          ],
          [
            [
              150.45875671204502,
              -10.315743405478372
            ],
            [
              150.7499674432486,
              -10.39883957712749
            ],
            [
              151.1999671244645,
              -10.39883957712749
            ]
          ],
          [
            [
              147.1885196909836,
              -9.479589292697398
            ],
            [
              147.3749698341284,
              -10.177457430361159
            ],
            [
              149.39996839960057,
              -11.06203210990941
            ],
            [
              150.7499674432486,
              -11.06203210990941
            ],
            [
              151.1999671244645,
              -10.841130095525784
            ],
            [
              151.1999671244645,
              -10.39883957712749
            ],
            [
              150.97496728385667,
              -10.177457430361159
            ],
            [
              150.29996776203237,
              -9.734235342300764
            ],
            [
              149.84996808081647,
              -9.290424301035614
            ],
            [
              149.39996839960057,
              -8.846050186819042
            ],
            [
              149.39996839960057,
              -8.401139048122928
            ],
            [
              148.04996935595253,
              -7.063446338991156
            ],
            [
              148.04996935595253,
              -6.39309949782384
            ],
            [
              147.59996967473646,
              -5.945707155070551
            ],
            [
              146.90630490718834,
              -5.585376278267006
            ],
            [
              146.02497079048058,
              -5.273944363641391
            ],
            [
              145.78474096066176,
              -5.23368424614938
            ]
          ],
          [
            [
              145.77174829265064,
              -7.963744467834914
            ],
            [
              145.57497110926445,
              -8.401139048122928
            ]
          ],
          [
            [
              143.20993372217916,
              -9.07814229926305
            ],
            [
              143.9999722250084,
              -8.846050186819042
            ],
            [
              144.89997158744055,
              -7.955717094334744
            ]
          ],
          [
            [
              144.89997158744055,
              -7.955717094334744
            ],
            [
              145.57497110926445,
              -8.401139048122928
            ],
            [
              146.6999703123045,
              -9.290424301035614
            ],
            [
              147.1885196909836,
              -9.479589292697398
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "strategic-evolution-underwater-link-seul",
        "name": "Strategic Evolution Underwater Link (SEUL)",
        "color": "#bd254b",
        "feature_id": "strategic-evolution-underwater-link-seul-0",
        "coordinates": [
          -88.13645167977197,
          17.914295863394358
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -88.29445457772995,
              17.925607489676395
            ],
            [
              -88.09086335966593,
              17.911032138193548
            ],
            [
              -87.9783422706509,
              17.911032138193548
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "labuan-brunei-submarine-cable",
        "name": "Labuan-Brunei Submarine Cable",
        "color": "#939597",
        "feature_id": "labuan-brunei-submarine-cable-0",
        "coordinates": [
          114.98447468523975,
          5.126623341957696
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              114.88563284987973,
              4.926762452886777
            ],
            [
              114.97499278657611,
              5.120007115780538
            ],
            [
              115.16894264918031,
              5.255340365327868
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "marea",
        "name": "MAREA",
        "color": "#288da3",
        "feature_id": "marea-0",
        "coordinates": [
          -38.761551954919014,
          39.92360026061516
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -76.05920188300789,
              36.75500543613887
            ],
            [
              -74.69987284596938,
              36.8131986057773
            ],
            [
              -72.44987443988929,
              37.41128363492314
            ],
            [
              -61.199882409489234,
              37.94551049545976
            ],
            [
              -50.39989006030518,
              37.94551049545976
            ],
            [
              -39.59989771112103,
              39.69832335493328
            ],
            [
              -23.39990918734489,
              44.051519228735145
            ],
            [
              -16.199914287888873,
              45.64654149518748
            ],
            [
              -9.899918750864742,
              46.58235508209589
            ],
            [
              -5.849921619920792,
              45.64654149518748
            ],
            [
              -4.499922576272752,
              44.694829089578064
            ],
            [
              -2.94919367482359,
              43.27422025200056
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "brazilian-festoon",
        "name": "Brazilian Festoon",
        "color": "#a36929",
        "feature_id": "brazilian-festoon-0",
        "coordinates": [
          -39.023203791638025,
          -14.833362589631577
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -35.21118082012924,
              -5.794842609646821
            ],
            [
              -34.87490105835306,
              -6.169450529574419
            ],
            [
              -34.76240113804903,
              -6.616650693475464
            ],
            [
              -34.86103106817862,
              -7.115296841294736
            ],
            [
              -34.649901217745025,
              -7.398261494590969
            ],
            [
              -34.649901217745025,
              -7.732822794391694
            ],
            [
              -34.87197106042865,
              -8.055326726100276
            ],
            [
              -34.76240113804903,
              -8.401139048122928
            ],
            [
              -35.09990089896101,
              -9.290424301035614
            ],
            [
              -35.73496044907887,
              -9.652223223190191
            ],
            [
              -35.887400341089034,
              -10.177457430361159
            ],
            [
              -36.44989994260905,
              -10.730617682527622
            ],
            [
              -37.07479949992427,
              -10.909607685768748
            ],
            [
              -37.01239954412904,
              -11.172420921128236
            ],
            [
              -37.181149424585016,
              -11.558448514962322
            ],
            [
              -37.570509148759015,
              -11.846669756599255
            ],
            [
              -37.68739906595306,
              -12.273934999624657
            ],
            [
              -38.02489882686504,
              -12.822995625629687
            ],
            [
              -38.50448848711929,
              -12.969972035343062
            ],
            [
              -38.474898508081026,
              -13.480286782092094
            ],
            [
              -38.69989834868906,
              -14.244842547315386
            ],
            [
              -39.04769810230445,
              -14.787969163702286
            ],
            [
              -38.812398268993064,
              -15.22403228464728
            ],
            [
              -38.69989834868906,
              -15.874323281689755
            ],
            [
              -38.812398268993064,
              -16.30669306561819
            ],
            [
              -39.064688090268504,
              -16.451075727726476
            ],
            [
              -38.92489818929701,
              -16.953454989810012
            ],
            [
              -38.92489818929701,
              -17.59799899615561
            ],
            [
              -39.37489787051308,
              -18.453813775777263
            ],
            [
              -39.85912752748027,
              -18.716690158772792
            ],
            [
              -39.59989771112103,
              -18.98655325687281
            ],
            [
              -39.59989771112103,
              -19.72952545002093
            ],
            [
              -39.824897551729066,
              -20.15254378601884
            ],
            [
              -40.30165721398805,
              -20.294028446525374
            ],
            [
              -40.499897073553086,
              -20.995131543025877
            ],
            [
              -41.02723669998073,
              -21.631144867598294
            ],
            [
              -40.94989675476907,
              -21.832990805201973
            ],
            [
              -41.17489659537699,
              -22.25009967909017
            ],
            [
              -41.399896435985056,
              -22.35418408364265
            ],
            [
              -41.78563616272342,
              -22.371792110839323
            ],
            [
              -41.849896117201126,
              -22.665969967794723
            ],
            [
              -42.299895798417,
              -23.08058350574774
            ],
            [
              -42.749895479633096,
              -23.08058350574774
            ],
            [
              -43.209565153998795,
              -22.903486555497864
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "nationwide-submarine-cable-ooredoo-maldives-nascom",
        "name": "Nationwide Submarine Cable Ooredoo Maldives (NaSCOM)",
        "color": "#ef7422",
        "feature_id": "nationwide-submarine-cable-ooredoo-maldives-nascom-0",
        "coordinates": [
          73.49377676750905,
          3.0771439148073227
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              73.08918245887716,
              -0.605519711481634
            ],
            [
              72.99768252369688,
              0.281087260908294
            ],
            [
              72.99768252369688,
              0.531080606428844
            ],
            [
              72.99768252369688,
              0.7810638415977
            ],
            [
              73.42463222124152,
              2.776778986293493
            ],
            [
              73.53957213981701,
              3.276079687360095
            ],
            [
              73.5403521392646,
              3.962982118955608
            ],
            [
              73.5403521392646,
              4.212345781871875
            ],
            [
              73.49035217468503,
              4.461629537081564
            ],
            [
              73.27082233020181,
              4.854566321204942
            ],
            [
              73.07082247188356,
              5.103622553806417
            ],
            [
              73.181272393044,
              5.211384397097532
            ],
            [
              73.181272393044,
              5.435413643888296
            ],
            [
              73.1708224010427,
              5.601440402765235
            ],
            [
              73.1715024005612,
              6.125830038600412
            ],
            [
              73.07150247140208,
              6.622826415013179
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "saba-statia-cable-system-sscs",
        "name": "Saba, Statia Cable System (SSCS)",
        "color": "#c01f38",
        "feature_id": "saba-statia-cable-system-sscs-0",
        "coordinates": [
          -63.316647428490754,
          17.616040674676356
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -62.72976132570861,
              17.298635546518767
            ],
            [
              -62.829761254867734,
              17.298635546518767
            ],
            [
              -62.98704114344912,
              17.38645312863801
            ],
            [
              -62.98552567577276,
              17.4758936446012
            ],
            [
              -63.08709107257286,
              17.481859236798527
            ],
            [
              -63.25164095600422,
              17.521161556886454
            ],
            [
              -63.2442168987635,
              17.615097550173232
            ],
            [
              -63.351690885127965,
              17.616496978706607
            ],
            [
              -63.351690885127965,
              17.71178205639049
            ],
            [
              -63.155061024422324,
              17.92932775779633
            ],
            [
              -63.05505687651619,
              18.024447867032848
            ],
            [
              -62.9436311742012,
              17.9845119674776
            ],
            [
              -62.85054881207171,
              17.89791564702213
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "east-west-submarine-cable-system",
        "name": "East-West Submarine Cable System",
        "color": "#ed164f",
        "feature_id": "east-west-submarine-cable-system-0",
        "coordinates": [
          107.46181632127386,
          3.3123415341326847
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              103.85068066714334,
              2.295702456949584
            ],
            [
              104.40000027800002,
              2.480311786858834
            ],
            [
              105.74999932164823,
              2.592701464601845
            ],
            [
              105.97499916225613,
              2.817450442654064
            ],
            [
              106.21112899497965,
              3.207140223034116
            ],
            [
              106.87499852468827,
              3.154491498099929
            ],
            [
              107.32499820590417,
              3.266814816815753
            ],
            [
              107.99999772772824,
              3.491423322320486
            ],
            [
              108.29022752212674,
              3.66676047852701
            ],
            [
              108.89999709016004,
              3.266814816815753
            ],
            [
              109.79999645259218,
              2.592701464601845
            ],
            [
              110.24999613380825,
              1.918228780215685
            ],
            [
              110.35370606033919,
              1.520169126642031
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "smpcs-packet-1",
        "name": "SMPCS Packet-1",
        "color": "#a43a95",
        "feature_id": "smpcs-packet-1-0",
        "coordinates": [
          127.32155160528421,
          -3.169471179209552
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              126.8999843388003,
              1.01853421661562
            ],
            [
              127.34998402001636,
              1.01853421661562
            ],
            [
              127.46248394032031,
              0.906050180868988
            ],
            [
              127.56141387023749,
              0.734977909038349
            ]
          ],
          [
            [
              127.34998402001636,
              1.01853421661562
            ],
            [
              127.34998402001636,
              0.906050180868988
            ],
            [
              127.37537400202993,
              0.789853009413861
            ]
          ],
          [
            [
              128.19070342444294,
              -3.645746178185419
            ],
            [
              128.02498354184027,
              -4.040555289062013
            ],
            [
              127.79998370123226,
              -4.15276774801373
            ],
            [
              126.4499846575844,
              -4.15276774801373
            ],
            [
              125.09998561393635,
              -3.816084221750117
            ],
            [
              123.07498704846441,
              -3.816084221750117
            ],
            [
              122.5129774465972,
              -3.998469097245277
            ]
          ],
          [
            [
              127.48045392759028,
              -0.626808465190322
            ],
            [
              127.34998402001636,
              -0.781386636225506
            ],
            [
              127.34998402001636,
              -1.006358951224712
            ]
          ],
          [
            [
              126.67498449819223,
              -1.231315750217505
            ],
            [
              127.34998402001636,
              -1.006358951224712
            ],
            [
              127.79998370123226,
              -1.006358951224712
            ],
            [
              128.24998338244833,
              -1.231315750217505
            ],
            [
              129.1499827448803,
              -1.231315750217505
            ],
            [
              129.59998242609637,
              -0.781386636225506
            ],
            [
              130.39002186642503,
              -0.59019055694165
            ],
            [
              130.8901215121497,
              -0.59019055694165
            ],
            [
              131.29539122505295,
              -0.882055944384604
            ]
          ],
          [
            [
              126.68699448968422,
              -2.201477967855737
            ],
            [
              125.93699502099113,
              -2.201477967855737
            ]
          ],
          [
            [
              127.34998402001636,
              -3.254657364797595
            ],
            [
              127.08582420714973,
              -3.233401495651691
            ]
          ],
          [
            [
              129.1499827448803,
              -3.928327304142726
            ],
            [
              129.1499827448803,
              -3.703826470668162
            ],
            [
              128.95525288282877,
              -3.297846628597966
            ]
          ],
          [
            [
              129.87205223335977,
              -4.520172025984351
            ],
            [
              129.82498226670444,
              -4.15276774801373
            ]
          ],
          [
            [
              124.83963579837055,
              1.490779296094786
            ],
            [
              125.09998561393635,
              1.243490076978134
            ],
            [
              125.99998497636832,
              1.01853421661562
            ],
            [
              126.8999843388003,
              1.01853421661562
            ],
            [
              126.8999843388003,
              0.568578852526286
            ],
            [
              126.8999843388003,
              0.118588418888407
            ],
            [
              126.67498449819223,
              -1.231315750217505
            ],
            [
              126.68699448968422,
              -2.201477967855737
            ],
            [
              127.12498417940836,
              -2.580536704984041
            ],
            [
              127.34998402001636,
              -3.254657364797595
            ],
            [
              127.57498386062443,
              -3.703826470668162
            ],
            [
              127.79998370123226,
              -3.928327304142726
            ],
            [
              128.02498354184027,
              -3.928327304142726
            ],
            [
              128.19070342444294,
              -3.645746178185419
            ],
            [
              128.47498322305617,
              -3.816084221750117
            ],
            [
              129.1499827448803,
              -3.928327304142726
            ],
            [
              129.82498226670444,
              -4.15276774801373
            ],
            [
              130.49998178852834,
              -4.15276774801373
            ],
            [
              131.39998115096031,
              -3.928327304142726
            ],
            [
              132.29998051339228,
              -3.479268678969987
            ],
            [
              132.74998019460836,
              -3.366969497295258
            ],
            [
              133.00857001142109,
              -3.111418827305897
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "smpcs-packet-2",
        "name": "SMPCS Packet-2",
        "color": "#33b44a",
        "feature_id": "smpcs-packet-2-0",
        "coordinates": [
          131.7481483166599,
          -2.9282857462319782
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              136.57497748494447,
              -5.049857167366866
            ],
            [
              136.88962726204366,
              -4.550247539955819
            ]
          ],
          [
            [
              134.0999792382564,
              -4.15276774801373
            ],
            [
              133.79147945680054,
              -3.679367344668947
            ]
          ],
          [
            [
              132.75203019315612,
              -5.635389324566262
            ],
            [
              132.63753027426915,
              -5.385957847172989
            ],
            [
              132.63963027278143,
              -5.128744870833515
            ],
            [
              132.85535011996345,
              -4.427385673631022
            ]
          ],
          [
            [
              134.06198926516882,
              -0.861458343462516
            ],
            [
              134.32497907886446,
              -0.725141539440244
            ],
            [
              134.5499789194723,
              -0.556402272850676
            ]
          ],
          [
            [
              136.05329785450718,
              -1.187185613026656
            ],
            [
              135.89997796312034,
              -1.400021081752755
            ]
          ],
          [
            [
              139.49997541284839,
              -1.681168935905106
            ],
            [
              139.35329551675804,
              -2.175227145407234
            ]
          ],
          [
            [
              140.6691145846195,
              -2.591565453841469
            ],
            [
              140.39997477528036,
              -2.130918480960247
            ],
            [
              139.49997541284839,
              -1.681168935905106
            ],
            [
              137.69997668798445,
              -1.231315750217505
            ],
            [
              136.79997732555248,
              -1.231315750217505
            ],
            [
              136.3499776443364,
              -1.400021081752755
            ],
            [
              135.89997796312034,
              -1.400021081752755
            ],
            [
              135.44997828190444,
              -1.175078194688609
            ],
            [
              134.99997860068837,
              -0.781386636225506
            ],
            [
              134.5499789194723,
              -0.556402272850676
            ],
            [
              133.64997955704033,
              -0.331409329660175
            ],
            [
              132.29998051339228,
              -0.106411275875331
            ],
            [
              131.62498099156832,
              -0.331409329660175
            ],
            [
              131.29539122505295,
              -0.882055944384604
            ],
            [
              131.04539140215516,
              -0.757068845388176
            ],
            [
              130.8901215121497,
              -0.690184231258399
            ],
            [
              130.39002186642503,
              -0.690184231258399
            ],
            [
              130.27498194792028,
              -1.231315750217505
            ],
            [
              130.7249816291364,
              -1.681168935905106
            ],
            [
              131.39998115096031,
              -2.580536704984041
            ],
            [
              131.84998083217639,
              -3.029995968008762
            ],
            [
              132.29998051339228,
              -3.254657364797595
            ],
            [
              132.74998019460836,
              -3.254657364797595
            ],
            [
              133.00857001142109,
              -3.111418827305897
            ],
            [
              132.74998019460836,
              -3.479268678969987
            ],
            [
              132.6053502970656,
              -3.679267550854945
            ],
            [
              132.6053502970656,
              -4.178090661818857
            ],
            [
              132.85535011996345,
              -4.427385673631022
            ],
            [
              133.64997955704033,
              -4.377144375531941
            ],
            [
              134.0999792382564,
              -4.15276774801373
            ],
            [
              134.99997860068837,
              -4.713582177229341
            ],
            [
              135.89997796312034,
              -4.825692499217524
            ],
            [
              136.57497748494447,
              -5.049857167366866
            ],
            [
              136.79997732555248,
              -6.169450529574419
            ],
            [
              136.79997732555248,
              -8.401139048122928
            ],
            [
              137.24997700676838,
              -8.846050186819042
            ],
            [
              138.59997605041642,
              -9.06830600387434
            ],
            [
              139.94997509406446,
              -8.846050186819042
            ],
            [
              140.4050547716818,
              -8.499084023854977
            ]
          ],
          [
            [
              130.85760153518734,
              -0.383358280481133
            ],
            [
              130.8901215121497,
              -0.490195084933788
            ],
            [
              131.29539122505295,
              -0.882055944384604
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "luwuk-tutuyan-cable-system-ltcs",
        "name": "Luwuk Tutuyan Cable System (LTCS)",
        "color": "#554b9f",
        "feature_id": "luwuk-tutuyan-cable-system-ltcs-0",
        "coordinates": [
          124.09471861195684,
          -0.5754637530094442
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              124.57498598585093,
              0.761435633994978
            ],
            [
              124.64998593272028,
              0.568578852526286
            ],
            [
              124.64998593272028,
              0.118588418888407
            ],
            [
              123.74998657028831,
              -1.006358951224712
            ],
            [
              123.29998688907224,
              -1.0907197553434
            ],
            [
              123.07498704846441,
              -1.062599741028748
            ],
            [
              122.79275724839854,
              -0.938788738945078
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tarakan-selor-cable-system-tscs",
        "name": "Tarakan Selor Cable System (TSCS)",
        "color": "#3db557",
        "feature_id": "tarakan-selor-cable-system-tscs-0",
        "coordinates": [
          117.89999071448025,
          3.041428189035514
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              117.57851094221976,
              3.327354396392252
            ],
            [
              117.61874091372025,
              3.154491498099929
            ],
            [
              117.89999071448025,
              3.042156042425745
            ],
            [
              117.89999071448025,
              2.817450442654064
            ],
            [
              117.81942077155682,
              2.572022500481324
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "sumatera-bangka-cable-system-sbcs",
        "name": "Sumatera Bangka Cable System (SBCS)",
        "color": "#bab034",
        "feature_id": "sumatera-bangka-cable-system-sbcs-0",
        "coordinates": [
          104.74267069755896,
          -2.45781340167386
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              105.1642597365915,
              -2.065142653434521
            ],
            [
              104.96249987952004,
              -2.130918480960247
            ],
            [
              104.84999995921609,
              -2.243336428755356
            ],
            [
              104.73750003891215,
              -2.468145972656231
            ],
            [
              104.62500011860826,
              -2.636728372378741
            ],
            [
              104.62500011860826,
              -2.805287932308005
            ],
            [
              104.75732002487146,
              -2.990989901799765
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "s-u-b-cable-system",
        "name": "S-U-B Cable System",
        "color": "#cd5628",
        "feature_id": "s-u-b-cable-system-0",
        "coordinates": [
          116.6115716301326,
          -6.351176587962328
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              114.60399304939611,
              -3.327586828573203
            ],
            [
              114.18749334444826,
              -3.703826470668162
            ],
            [
              113.96249350384025,
              -4.377144375531941
            ],
            [
              113.73749366323219,
              -4.825692499217524
            ],
            [
              113.17499406171223,
              -5.721872747834027
            ],
            [
              112.7249943804961,
              -6.39309949782384
            ],
            [
              112.7249943804961,
              -6.616650693475464
            ],
            [
              112.74667436513809,
              -7.258591948594869
            ],
            [
              112.83749430080022,
              -6.616650693475464
            ],
            [
              112.94999422110416,
              -6.39309949782384
            ],
            [
              114.38597134932175,
              -6.321671514079981
            ],
            [
              116.07081354037959,
              -6.321671514079981
            ],
            [
              117.37991922838071,
              -6.39309949782384
            ],
            [
              118.79999007691222,
              -5.385957847172989
            ],
            [
              119.41238964308272,
              -5.152180217334632
            ],
            [
              118.79999007691222,
              -5.161910662112973
            ],
            [
              116.58310164737688,
              -4.628705101793656
            ],
            [
              114.74999294596827,
              -4.601453764837203
            ],
            [
              114.2999932647522,
              -4.377144375531941
            ],
            [
              114.2999932647522,
              -3.703826470668162
            ],
            [
              114.60399304939611,
              -3.327586828573203
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "indigo-west",
        "name": "INDIGO-West",
        "color": "#a47c2c",
        "feature_id": "indigo-west-0",
        "coordinates": [
          106.52510864320797,
          -14.83315464057938
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              103.85303066547843,
              1.294067636025204
            ],
            [
              103.83750067648,
              1.01853421661562
            ],
            [
              103.83750067648,
              0.793562652607278
            ],
            [
              104.17500043739219,
              0.627825578639062
            ],
            [
              104.84999995921609,
              0.512331397069687
            ],
            [
              105.52499948104,
              0.118588418888407
            ],
            [
              106.19999900286413,
              -0.781386636225506
            ],
            [
              106.98549844640908,
              -2.130918480960247
            ],
            [
              107.0999983652961,
              -3.029995968008762
            ],
            [
              107.0999983652961,
              -4.601453764837203
            ],
            [
              106.64999868408003,
              -5.273944363641391
            ],
            [
              106.70624864423215,
              -5.721872747834027
            ],
            [
              106.83339855415792,
              -6.171588071824203
            ],
            [
              106.4249988434722,
              -5.777839699209608
            ],
            [
              105.88026922936368,
              -5.924173215600462
            ],
            [
              105.74999932164823,
              -6.001651664913879
            ],
            [
              105.29999964043216,
              -6.057590432424689
            ],
            [
              104.84999995921609,
              -6.616650693475464
            ],
            [
              104.84999995921609,
              -7.509810688339655
            ],
            [
              105.29999964043216,
              -11.943944931746927
            ],
            [
              108.89999708956415,
              -20.433922197637315
            ],
            [
              111.14999549624022,
              -27.153831285391675
            ],
            [
              112.94999422110416,
              -30.309953344646914
            ],
            [
              113.84999358353613,
              -31.08383471876715
            ],
            [
              115.85731216153286,
              -31.95344133032441
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "junior",
        "name": "Junior",
        "color": "#902c8c",
        "feature_id": "junior-0",
        "coordinates": [
          -44.59798566136823,
          -23.927642902465152
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -46.328062944825774,
              -23.961842897596995
            ],
            [
              -44.99989388571311,
              -24.111502734257556
            ],
            [
              -43.649894842065066,
              -23.493922445897766
            ],
            [
              -43.209565153998795,
              -22.903486555497864
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "skagerrak-4",
        "name": "Skagerrak 4",
        "color": "#60bb46",
        "feature_id": "skagerrak-4-0",
        "coordinates": [
          8.933237151289035,
          57.372833879784395
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              7.996258571315195,
              58.15106571642474
            ],
            [
              8.437568258687293,
              57.93205658695149
            ],
            [
              8.775068019599274,
              57.571890279005004
            ],
            [
              9.112567780511228,
              57.14714566273739
            ],
            [
              9.112567780511228,
              56.840738642145595
            ],
            [
              9.225067700815345,
              56.717468482041056
            ],
            [
              9.281317660967318,
              56.59379297841909
            ],
            [
              9.337567621119263,
              56.5628106790318
            ],
            [
              9.611687426930246,
              56.510791530389184
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "atisa",
        "name": "Atisa",
        "color": "#56be8d",
        "feature_id": "atisa-0",
        "coordinates": [
          144.8895850351337,
          14.530318141710865
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              145.62927107079807,
              14.958774985807963
            ],
            [
              145.46247118896056,
              14.9098938602896
            ],
            [
              145.12497142804838,
              14.9098938602896
            ]
          ],
          [
            [
              144.78747166713643,
              14.365653759228536
            ],
            [
              145.0124715077445,
              14.365653759228536
            ],
            [
              145.1343514214037,
              14.144936296053942
            ]
          ],
          [
            [
              144.69470173285575,
              13.464772962370034
            ],
            [
              144.67497174683248,
              13.929303843271725
            ],
            [
              144.78747166713643,
              14.365653759228536
            ],
            [
              145.12497142804838,
              14.9098938602896
            ],
            [
              145.34997126865645,
              15.127208002058438
            ],
            [
              145.69958102098977,
              15.151813296858165
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tui-samoa",
        "name": "Tui-Samoa",
        "color": "#ba8c34",
        "feature_id": "tui-samoa-0",
        "coordinates": [
          -176.2024591526966,
          -14.475800138388479
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -176.17500034981677,
              -13.28200057514735
            ],
            [
              -175.7248012789621,
              -13.698987269610853
            ],
            [
              -175.49980143835418,
              -14.135775375064753
            ]
          ],
          [
            [
              -178.1581082284118,
              -14.29683787673278
            ],
            [
              -177.74979984443416,
              -14.57172649133264
            ],
            [
              -177.2998001632181,
              -15.006817032918917
            ]
          ],
          [
            [
              179.34974953238176,
              -16.80801177359578
            ],
            [
              179.54994704107284,
              -17.16855309422607
            ],
            [
              179.99993262111886,
              -17.59799899615561
            ]
          ],
          [
            [
              -171.76669408292253,
              -13.833489255757883
            ],
            [
              -171.8998039886261,
              -13.480286782092094
            ],
            [
              -172.23730374953826,
              -13.261386000117113
            ],
            [
              -172.79980335105807,
              -13.261386000117113
            ],
            [
              -175.49980143835418,
              -14.135775375064753
            ],
            [
              -177.2998001632181,
              -15.006817032918917
            ],
            [
              -179.54979856929822,
              -17.16855309422607
            ],
            [
              -179.99979825051417,
              -17.59799899615561
            ]
          ],
          [
            [
              -171.8998039886261,
              -13.480286782092094
            ],
            [
              -172.01230390893014,
              -13.589662250511992
            ],
            [
              -172.17816102513325,
              -13.670594022635811
            ]
          ],
          [
            [
              178.43744782917761,
              -18.12381094353711
            ],
            [
              178.87496111451927,
              -18.1345594776067
            ],
            [
              179.99993262111886,
              -17.59799899615561
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "bugio",
        "name": "BUGIO",
        "color": "#d33894",
        "feature_id": "bugio-0",
        "coordinates": [
          -9.356394683523376,
          38.45053529435128
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9.331559153496016,
              38.69016197235561
            ],
            [
              -9.431509082690525,
              38.61206568189243
            ],
            [
              -9.352749138484882,
              38.44269570155157
            ],
            [
              -9.20269924478157,
              38.36471443161014
            ],
            [
              -9.102749315587062,
              38.443079483141986
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "quintillion-subsea-cable-network",
        "name": "Quintillion Subsea Cable Network",
        "color": "#baab31",
        "feature_id": "quintillion-subsea-cable-network-0",
        "coordinates": [
          -161.33751646696302,
          71.19090757693874
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -161.11856210313954,
              71.2456293000943
            ],
            [
              -160.649811958226,
              70.80045445855757
            ],
            [
              -160.0383428956656,
              70.6369472699777
            ]
          ],
          [
            [
              -168.29980653889805,
              68.12014116880246
            ],
            [
              -166.94980749525,
              67.4394931950778
            ],
            [
              -165.59980845160197,
              67.09168603590075
            ],
            [
              -163.79980972673806,
              66.91588535690659
            ],
            [
              -162.59668101868573,
              66.89834123906633
            ]
          ],
          [
            [
              -167.84980685768198,
              68.61776755908173
            ],
            [
              -167.3998071764661,
              68.45310188361526
            ],
            [
              -166.80805792891852,
              68.34777271452057
            ]
          ],
          [
            [
              -156.78863527988355,
              71.29055679250217
            ],
            [
              -156.59981482728193,
              71.38369549089958
            ],
            [
              -156.59981482728193,
              71.6688723488943
            ]
          ],
          [
            [
              -148.33844147955003,
              70.25564680477646
            ],
            [
              -148.0498208841779,
              70.50227660282823
            ],
            [
              -148.4998205653939,
              70.80045445855757
            ],
            [
              -155.2498157836339,
              71.6688723488943
            ],
            [
              -158.399813552146,
              71.6688723488943
            ],
            [
              -161.11856210313954,
              71.2456293000943
            ],
            [
              -162.8998103643061,
              70.80045445855757
            ],
            [
              -166.94980749525,
              69.10459505606227
            ],
            [
              -167.84980685768198,
              68.61776755908173
            ],
            [
              -168.29980653889805,
              68.12014116880246
            ],
            [
              -168.52480637950612,
              66.01760945207974
            ],
            [
              -168.07480669829013,
              64.8962822062035
            ],
            [
              -166.94980749525,
              64.31739001144422
            ],
            [
              -166.04980813281807,
              64.31739001144422
            ],
            [
              -165.4063989572757,
              64.50111053726943
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "arsat-submarine-fiber-optic-cable",
        "name": "ARSAT Submarine Fiber Optic Cable",
        "color": "#e12825",
        "feature_id": "arsat-submarine-fiber-optic-cable-0",
        "coordinates": [
          -68.46704320378186,
          -52.55423589863918
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -68.41974729486917,
              -52.39021716428195
            ],
            [
              -68.45812726768037,
              -52.54751033211764
            ],
            [
              -68.60578716307674,
              -52.65889476082427
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "balok",
        "name": "BALOK",
        "color": "#35bba4",
        "feature_id": "balok-0",
        "coordinates": [
          115.91094573738204,
          -8.399360919774722
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              115.74667223991136,
              -8.38626016131127
            ],
            [
              115.9332421077435,
              -8.401139048122928
            ],
            [
              116.04726202697086,
              -8.485465010786783
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "pencan-9",
        "name": "Pencan-9",
        "color": "#86c440",
        "feature_id": "pencan-9-0",
        "coordinates": [
          -11.540609046184585,
          33.07476541382135
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -6.428881209780371,
              36.73129423644173
            ],
            [
              -7.199920663568747,
              36.42191605012607
            ],
            [
              -8.774919547824737,
              35.419780517080454
            ],
            [
              -10.799918113296798,
              33.93964008831958
            ],
            [
              -14.399915563024848,
              29.73606949729205
            ],
            [
              -14.849915244240833,
              28.951554732193216
            ],
            [
              -15.450744818607603,
              28.09637444868102
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "taba-aqaba",
        "name": "Taba-Aqaba",
        "color": "#98a439",
        "feature_id": "taba-aqaba-0",
        "coordinates": [
          34.9596252575156,
          29.52324844386527
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              34.894869516086914,
              29.492576422604117
            ],
            [
              34.950974476341685,
              29.512254681383894
            ],
            [
              35.00509500000021,
              29.581033231032706
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tannat",
        "name": "Tannat",
        "color": "#34a8a7",
        "feature_id": "tannat-0",
        "coordinates": [
          -47.401374671328654,
          -31.994362705245862
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -46.328062944825774,
              -23.961842897596995
            ],
            [
              -45.89989324814505,
              -25.13418654706126
            ],
            [
              -45.44989356692909,
              -27.951747285219852
            ],
            [
              -47.69989197300907,
              -32.61276000573585
            ],
            [
              -50.84988974152117,
              -34.48775447869497
            ],
            [
              -53.54988782881715,
              -35.22626671976636
            ],
            [
              -54.95018683683219,
              -34.900416027051364
            ]
          ],
          [
            [
              -53.54988782881715,
              -35.22626671976636
            ],
            [
              -53.99988751003313,
              -35.59302880961411
            ],
            [
              -56.69544224110189,
              -36.47097855291446
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "south-atlantic-inter-link-sail",
        "name": "South Atlantic Inter Link (SAIL)",
        "color": "#c62a26",
        "feature_id": "south-atlantic-inter-link-sail-0",
        "coordinates": [
          -13.859932011165085,
          -2.6142006522582335
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -38.54296845985962,
              -3.718735129291019
            ],
            [
              -35.999900261393066,
              -2.580536704984041
            ],
            [
              -34.199901536528955,
              -2.130918480960247
            ],
            [
              -25.199907912208914,
              -1.906058394384871
            ],
            [
              -10.799918113296798,
              -2.805287932308005
            ],
            [
              -3.599923213840782,
              -2.805287932308005
            ],
            [
              0.450073917103168,
              -1.081346446098905
            ],
            [
              6.300069772911229,
              1.918228780215685
            ],
            [
              8.10006849777534,
              2.480311786858834
            ],
            [
              9.00006786020731,
              2.592701464601845
            ],
            [
              9.91022721544212,
              2.933124533518679
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "brusa",
        "name": "BRUSA",
        "color": "#c86d28",
        "feature_id": "brusa-0",
        "coordinates": [
          -42.08907980888398,
          8.420314618733528
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -63.449880815569244,
              24.941363171753835
            ],
            [
              -65.69987922164925,
              20.375041253465525
            ],
            [
              -66.14987890286532,
              19.104405475930548
            ],
            [
              -66.10666604285262,
              18.4661054185857
            ]
          ],
          [
            [
              -35.549900580176995,
              0.568578852526286
            ],
            [
              -36.899899623825036,
              -0.781386636225506
            ],
            [
              -38.542964866112186,
              -3.718736532579186
            ]
          ],
          [
            [
              -43.20956312275021,
              -22.90349520937403
            ],
            [
              -42.299895798417,
              -23.905969261790176
            ],
            [
              -40.94989675476907,
              -24.31670674946909
            ],
            [
              -37.34989930504102,
              -23.493922445897766
            ],
            [
              -32.849902492881,
              -18.026426383713385
            ],
            [
              -31.049903768016975,
              -13.698987269610853
            ],
            [
              -29.92490456497697,
              -9.290424301035614
            ],
            [
              -31.499903436794966,
              -4.825692486823471
            ],
            [
              -35.549900580176995,
              0.568578852526286
            ],
            [
              -41.399896435985056,
              7.744889052551343
            ],
            [
              -48.59989133544116,
              14.801154224791475
            ],
            [
              -57.599884959761184,
              21.21639789994191
            ],
            [
              -63.449880815569244,
              24.941363171753835
            ],
            [
              -69.2998766713773,
              29.73606949729205
            ],
            [
              -73.34987380232134,
              33.565491482352144
            ],
            [
              -75.59987220840135,
              35.78566189952613
            ],
            [
              -75.82487204900939,
              36.14986678681771
            ],
            [
              -76.05919805488558,
              36.755008440642456
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "ellalink",
        "name": "EllaLink",
        "color": "#c22c75",
        "feature_id": "ellalink-0",
        "coordinates": [
          -22.723329545789227,
          17.549567050736243
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -16.199914288484848,
              34.435956905755745
            ],
            [
              -13.049916519972783,
              33.93964008831958
            ],
            [
              -9.899918750864742,
              33.93964008831958
            ],
            [
              -7.631920358132049,
              33.60539511325592
            ]
          ],
          [
            [
              -38.542964866112186,
              -3.718736532579186
            ],
            [
              -36.44989994260905,
              -0.781386636225506
            ],
            [
              -35.09990089896101,
              0.568578852526286
            ],
            [
              -31.049903768016975,
              3.715978119297972
            ],
            [
              -27.85950075469046,
              7.716632622882912
            ],
            [
              -25.649907593424984,
              11.294709319565555
            ],
            [
              -24.299908549776944,
              14.365653759228536
            ],
            [
              -24.07490870916891,
              14.801154224791475
            ],
            [
              -23.39990918734489,
              16.10232559580288
            ],
            [
              -21.599910462480864,
              19.952622905164304
            ],
            [
              -19.599911879298674,
              22.884654113882362
            ],
            [
              -18.449912693968884,
              24.941363171753835
            ],
            [
              -17.66241325184083,
              27.763588526057674
            ],
            [
              -17.470384579281784,
              28.275606244082894
            ],
            [
              -17.099913650320843,
              28.951554732193216
            ],
            [
              -17.198548585293963,
              29.690264251007818
            ],
            [
              -17.54520361315727,
              32.07431422012104
            ],
            [
              -17.32491349092888,
              32.81231878328768
            ],
            [
              -17.099913650320843,
              33.18971466460036
            ],
            [
              -16.199914288484848,
              34.435956905755745
            ],
            [
              -13.949915881808863,
              35.78566189952613
            ],
            [
              -12.149917156944838,
              36.69301553274448
            ],
            [
              -9.449919069648843,
              37.85673997565843
            ],
            [
              -9.112419308736776,
              37.899560460110024
            ],
            [
              -8.86959721512926,
              37.95721527519197
            ]
          ],
          [
            [
              -23.521209101414883,
              14.923035560171769
            ],
            [
              -23.849908868560874,
              14.801154224791475
            ],
            [
              -24.07490870916891,
              14.801154224791475
            ]
          ],
          [
            [
              -17.099913650320843,
              33.18971466460036
            ],
            [
              -16.874913809712808,
              32.81231878328768
            ],
            [
              -16.908898160638017,
              32.647276965637786
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "gulf2africa-g2a",
        "name": "Gulf2Africa (G2A)",
        "color": "#d1b32a",
        "feature_id": "gulf2africa-g2a-0",
        "coordinates": [
          49.076721625552835,
          13.832149683028206
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              49.18792939074925,
              11.275556936623303
            ],
            [
              48.600039807215495,
              13.601498202276503
            ]
          ],
          [
            [
              54.14808587692781,
              17.095827186725558
            ],
            [
              54.000035981807684,
              16.53419619825962
            ],
            [
              52.650036938159616,
              15.561165635263421
            ],
            [
              48.600039807215495,
              13.601498202276503
            ],
            [
              45.450042038703714,
              12.834868817846598
            ],
            [
              45.123342270140824,
              10.81596353465244
            ],
            [
              45.01088234980861,
              10.435118748992778
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "nigeria-cameroon-submarine-cable-system-ncscs",
        "name": "Nigeria Cameroon Submarine Cable System (NCSCS)",
        "color": "#36b449",
        "feature_id": "nigeria-cameroon-submarine-cable-system-ncscs-0",
        "coordinates": [
          5.613815221846409,
          2.9887897201357703
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              3.423511810692077,
              6.439066911484701
            ],
            [
              4.050071366831219,
              4.164912849976844
            ],
            [
              5.400070410479259,
              3.042156042425745
            ],
            [
              6.300069772911229,
              2.817450442654064
            ],
            [
              8.10006849777534,
              2.705081160335851
            ],
            [
              9.00006786020731,
              2.817450442654064
            ],
            [
              9.91022721544212,
              2.933124533518679
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "san-andres-isla-tolu-submarine-cable-sait",
        "name": "San Andres Isla Tolu Submarine Cable (SAIT)",
        "color": "#c28c2b",
        "feature_id": "san-andres-isla-tolu-submarine-cable-sait-0",
        "coordinates": [
          -78.77573743973245,
          10.715014824972656
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -81.70055036987333,
              12.584703013278954
            ],
            [
              -78.29987029569733,
              10.410816505402636
            ],
            [
              -76.4998715708334,
              9.96791518697421
            ],
            [
              -75.55866223759489,
              9.496381979983852
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "lynn-canal-fiber",
        "name": "Lynn Canal Fiber",
        "color": "#45bfb2",
        "feature_id": "lynn-canal-fiber-0",
        "coordinates": [
          -135.23193605581744,
          58.90140007130901
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -135.31389103529196,
              59.45834443079909
            ],
            [
              -135.38508098306187,
              59.30041545947938
            ],
            [
              -135.44500093910028,
              59.2358377826385
            ],
            [
              -135.3499810088137,
              59.08261616806959
            ],
            [
              -135.25154108103624,
              58.96140764309092
            ],
            [
              -135.2016911176098,
              58.808825576745505
            ],
            [
              -135.07029121401413,
              58.684515846670934
            ],
            [
              -135.02390124804907,
              58.59201827449451
            ],
            [
              -134.74729145098985,
              58.55104926823199
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "hifn-hawaii-island-fibre-network",
        "name": "HIFN (Hawaii Island Fibre Network)",
        "color": "#d2da26",
        "feature_id": "hifn-hawaii-island-fibre-network-0",
        "coordinates": [
          -156.8200810094662,
          20.603833377097743
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -157.55220471967436,
              20.732751122479783
            ],
            [
              -157.16231442880195,
              20.901439785237727
            ],
            [
              -157.0238851072874,
              21.093359005003947
            ]
          ],
          [
            [
              -156.75972530109394,
              20.636456177868524
            ],
            [
              -156.8968852004637,
              20.746451785321682
            ]
          ],
          [
            [
              -157.6960046141724,
              21.27755746368038
            ],
            [
              -157.74600457748895,
              21.173966491361455
            ],
            [
              -157.74600457748895,
              20.893863273745836
            ],
            [
              -157.55220471967436,
              20.732751122479783
            ],
            [
              -157.34732486998882,
              20.562320024196396
            ],
            [
              -156.8968852004637,
              20.562320024196396
            ],
            [
              -156.75972530109394,
              20.636456177868524
            ],
            [
              -156.46309551872278,
              20.78215184399818
            ],
            [
              -156.487314906978,
              20.585819096040467
            ],
            [
              -156.374814986674,
              20.48046637597571
            ],
            [
              -156.09356518591372,
              20.26954403592967
            ],
            [
              -155.8314059821754,
              20.039988105686604
            ]
          ],
          [
            [
              -159.36856338706377,
              21.974943031077714
            ],
            [
              -159.17960352569816,
              21.924671936671842
            ],
            [
              -158.84981323336214,
              21.635297384859456
            ],
            [
              -158.45606405653848,
              21.4602691988772
            ],
            [
              -158.22066328843272,
              21.463446823448095
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "hics-hawaii-inter-island-cable-system",
        "name": "HICS (Hawaii Inter-Island Cable System)",
        "color": "#c92c40",
        "feature_id": "hics-hawaii-inter-island-cable-system-0",
        "coordinates": [
          -156.70771642607986,
          20.78215184399818
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -157.6960046141724,
              21.27755746368038
            ],
            [
              -157.6960046141724,
              21.173966491361455
            ],
            [
              -157.4998141897139,
              21.053169495094266
            ],
            [
              -157.16231442880195,
              20.953979036598952
            ],
            [
              -157.00733511942968,
              21.011512865948674
            ],
            [
              -156.82481466789,
              20.953979036598952
            ],
            [
              -156.75357530560603,
              20.880185347559603
            ],
            [
              -156.71309533530507,
              20.78215184399818
            ],
            [
              -156.46309551872278,
              20.78215184399818
            ],
            [
              -156.43106554222229,
              20.585819096040467
            ],
            [
              -156.31856562476025,
              20.48046637597571
            ],
            [
              -156.03731522576194,
              20.26954403592967
            ],
            [
              -155.8314059821754,
              20.039988105686604
            ]
          ],
          [
            [
              -159.36856338706377,
              21.974943031077714
            ],
            [
              -159.26846346050425,
              21.8820852936976
            ],
            [
              -158.84981323336214,
              21.530685333962452
            ],
            [
              -158.39981355214616,
              21.32123529551194
            ],
            [
              -158.11949430347016,
              21.339697571515984
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "unisur",
        "name": "Unisur",
        "color": "#af5b39",
        "feature_id": "unisur-0",
        "coordinates": [
          -55.66534984935997,
          -35.848967439550705
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -54.95018683683219,
              -34.900416027051364
            ],
            [
              -55.34988655368117,
              -35.59302880961411
            ],
            [
              -55.79988623489716,
              -35.95811819864912
            ],
            [
              -56.69544560047457,
              -36.47095527632115
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "transcan-3",
        "name": "TRANSCAN-3",
        "color": "#d82729",
        "feature_id": "transcan-3-0",
        "coordinates": [
          -14.846845214219456,
          28.53159336365468
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -15.699964642057836,
              27.99976141196052
            ],
            [
              -15.63741468696486,
              28.194108049097963
            ],
            [
              -15.524914766660828,
              28.293214058016247
            ],
            [
              -14.399915563620823,
              28.68871408880051
            ],
            [
              -13.827315969255693,
              28.863419398977413
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "northern-lights",
        "name": "Northern Lights",
        "color": "#b3c134",
        "feature_id": "northern-lights-0",
        "coordinates": [
          -3.599923213840782,
          58.881066344862205
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -3.354957518022701,
              59.051457394755545
            ],
            [
              -3.599923213840782,
              58.99117670269845
            ],
            [
              -3.599923213840782,
              58.75856894488285
            ],
            [
              -3.376583372056757,
              58.67032899288881
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "jambi-batam-cable-system-jiba",
        "name": "Jambi-Batam Cable System (JIBA)",
        "color": "#574099",
        "feature_id": "jambi-batam-cable-system-jiba-0",
        "coordinates": [
          104.00625055693607,
          0.04308486918304766
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              103.95970058991267,
              1.133513836518915
            ],
            [
              104.006575556706,
              0.906050180868988
            ],
            [
              104.03462553683514,
              0.793562652607278
            ],
            [
              104.03437553701198,
              0.627825578639062
            ],
            [
              104.00625055693607,
              0.118588418888407
            ],
            [
              104.00625055693607,
              -0.331409329660175
            ],
            [
              103.46670093915824,
              -0.816543192375462
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "segunda-fos-canal-de-chacao",
        "name": "Segunda FOS Canal de Chacao",
        "color": "#9fa237",
        "feature_id": "segunda-fos-canal-de-chacao-0",
        "coordinates": [
          -73.32529820833415,
          -41.906663419836406
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -73.15529394016347,
              -41.80693750006313
            ],
            [
              -73.25265387119285,
              -41.872416746798734
            ],
            [
              -73.50446369280843,
              -41.99112728870605
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "sistem-kabel-rakyat-1malaysia-skr1m",
        "name": "Sistem Kabel Rakyat 1Malaysia (SKR1M)",
        "color": "#b64d26",
        "feature_id": "sistem-kabel-rakyat-1malaysia-skr1m-0",
        "coordinates": [
          114.36861347528733,
          5.228992886947509
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              103.85068066714334,
              2.295702456949584
            ],
            [
              104.40000027800002,
              2.536507846510915
            ],
            [
              105.74999932164823,
              3.210654701478347
            ],
            [
              107.0999983652961,
              3.828234303320954
            ],
            [
              107.99999772772824,
              4.389285926050889
            ],
            [
              108.44999740894414,
              4.389285926050889
            ],
            [
              108.89999709016004,
              4.164912849976844
            ],
            [
              109.79999645259218,
              2.367912558705314
            ],
            [
              110.13749621350414,
              1.918228780215685
            ],
            [
              110.35370606033919,
              1.520169126642031
            ],
            [
              110.47499597441615,
              1.918228780215685
            ],
            [
              110.69999581502415,
              2.367912558705314
            ],
            [
              111.59999517745612,
              3.042156042425745
            ],
            [
              112.49999453988826,
              3.266814816815753
            ],
            [
              113.06835413725688,
              3.197006076977885
            ],
            [
              113.17499406171223,
              3.715978119297972
            ],
            [
              113.39999390232023,
              4.164912849976844
            ],
            [
              114.00741347201856,
              4.425309062538702
            ],
            [
              114.0749934241442,
              5.061986954416028
            ],
            [
              115.64999230840024,
              5.957818681088611
            ],
            [
              116.07431200780823,
              5.981320525191649
            ],
            [
              115.64999230840024,
              6.069699469735895
            ],
            [
              110.69999581502415,
              5.510071711803135
            ],
            [
              107.99999772772824,
              5.398081130463737
            ],
            [
              105.29999964043216,
              4.613591578862867
            ],
            [
              103.72500075617612,
              4.389285926050889
            ],
            [
              103.39521098980222,
              4.116310078259517
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "aqualink",
        "name": "Aqualink",
        "color": "#25b35d",
        "feature_id": "aqualink-0",
        "coordinates": [
          174.7113119849627,
          -37.88080585940045
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              173.68300119667649,
              -42.40269875623838
            ],
            [
              174.1499508658848,
              -42.29250328834095
            ],
            [
              174.71245046740475,
              -41.62324076294928
            ],
            [
              174.76712042867618,
              -41.28051296355844
            ]
          ],
          [
            [
              173.50613132197256,
              -42.51606222990878
            ],
            [
              173.6999511846689,
              -42.78983315675495
            ],
            [
              173.24995150345282,
              -43.44677235710097
            ],
            [
              172.63622193822457,
              -43.532054981068285
            ]
          ],
          [
            [
              174.99747026549423,
              -40.919112232059234
            ],
            [
              174.82495038770887,
              -40.94695905083634
            ],
            [
              174.83800037846405,
              -41.10579929869007
            ]
          ],
          [
            [
              175.04999905640736,
              -39.93333487547832
            ],
            [
              174.82495038770887,
              -40.09176946134497
            ],
            [
              174.82495038770887,
              -40.6061925832843
            ],
            [
              175.051960226893,
              -40.86278592990223
            ]
          ],
          [
            [
              174.08333091307904,
              -39.066666256623506
            ],
            [
              174.14995086648068,
              -38.81782397325085
            ],
            [
              174.2624507867848,
              -38.46634757786356
            ],
            [
              174.59995054769675,
              -37.93590700435155
            ],
            [
              174.87183035509466,
              -37.80138208221919
            ],
            [
              174.59995054769675,
              -37.66923969785131
            ],
            [
              174.4874506273928,
              -37.40161074814375
            ],
            [
              174.59995054769675,
              -37.04328040742419
            ],
            [
              174.77046042690606,
              -36.88418050095055
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "bt-highlands-and-islands-submarine-cable-system",
        "name": "BT Highlands and Islands Submarine Cable System",
        "color": "#48c1c4",
        "feature_id": "bt-highlands-and-islands-submarine-cable-system-0",
        "coordinates": [
          -5.759517843572752,
          58.04737095177353
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -2.939523681673961,
              59.27976821327732
            ],
            [
              -3.093673573068742,
              59.241407618692165
            ],
            [
              -3.149923533220687,
              59.183824132138824
            ],
            [
              -3.095073572076927,
              59.118039875964286
            ]
          ],
          [
            [
              -5.028092202112504,
              55.75184157675699
            ],
            [
              -4.985982231943467,
              55.74730813687762
            ],
            [
              -4.943872261774544,
              55.742774170166626
            ]
          ],
          [
            [
              -4.86730231601743,
              55.79334124050908
            ],
            [
              -4.883882304272134,
              55.78675194417505
            ],
            [
              -4.900462292526612,
              55.78016153313595
            ]
          ],
          [
            [
              -5.141422121828413,
              55.643559473635236
            ],
            [
              -5.000747221483891,
              55.65942265518194
            ],
            [
              -4.889372300382831,
              55.69304893491429
            ]
          ],
          [
            [
              -5.019992207850606,
              55.86941398754456
            ],
            [
              -5.0417921924072,
              55.864001988519874
            ],
            [
              -5.063592176963908,
              55.858589235368186
            ]
          ],
          [
            [
              -5.533421844132192,
              55.47654965962829
            ],
            [
              -5.420921923828161,
              55.47654965962829
            ],
            [
              -5.332101986748995,
              55.50194290033235
            ]
          ],
          [
            [
              -6.125411424761126,
              55.63612077746126
            ],
            [
              -5.96242154022471,
              55.58970711313168
            ],
            [
              -5.697581727839776,
              55.565501435695694
            ]
          ],
          [
            [
              -6.089911449909636,
              55.846750945319116
            ],
            [
              -6.098116444097258,
              55.84724498115801
            ],
            [
              -6.106321438284681,
              55.84773901071773
            ]
          ],
          [
            [
              -5.614571786644802,
              55.89168249844937
            ],
            [
              -5.858221614040986,
              55.833673555351936
            ],
            [
              -5.953831546309999,
              55.833673555351936
            ]
          ],
          [
            [
              -5.365791962882753,
              56.01942001909663
            ],
            [
              -5.343501978673203,
              56.01385285953853
            ],
            [
              -5.321211994463596,
              56.00828489747781
            ]
          ],
          [
            [
              -5.669351747838164,
              56.4558441736221
            ],
            [
              -5.556821827555467,
              56.4558441736221
            ],
            [
              -5.47580188495067,
              56.43776501147141
            ]
          ],
          [
            [
              -6.09396144704067,
              56.68857026168783
            ],
            [
              -6.070281463815718,
              56.65279887225901
            ],
            [
              -6.070281463815718,
              56.62185065462566
            ]
          ],
          [
            [
              -6.808510940847043,
              56.50378880151479
            ],
            [
              -6.715941006424458,
              56.4660986568791
            ],
            [
              -6.35617126128875,
              56.582124582372074
            ],
            [
              -6.27238132064636,
              56.582124582372074
            ]
          ],
          [
            [
              -5.24538204818225,
              56.722533265758415
            ],
            [
              -5.239522052333541,
              56.7220504122194
            ],
            [
              -5.233662056484746,
              56.721567552480195
            ]
          ],
          [
            [
              -5.906461579867312,
              57.05920793730024
            ],
            [
              -5.850171619743605,
              57.02861361880741
            ],
            [
              -5.820431640811762,
              57.00848292332864
            ]
          ],
          [
            [
              -7.328140572736515,
              57.1055355392275
            ],
            [
              -7.320160578389704,
              57.093533957744626
            ],
            [
              -7.31218058404275,
              57.081528490010754
            ],
            [
              -7.307195587574256,
              57.07757471896545
            ],
            [
              -7.302210591105592,
              57.07362052649796
            ],
            [
              -7.316690580847848,
              57.04945954995475
            ],
            [
              -7.331170570590132,
              57.02528284471751
            ],
            [
              -7.390325528684144,
              57.02528284471751
            ],
            [
              -7.449480486778242,
              57.02528284471751
            ]
          ],
          [
            [
              -7.274140610990628,
              57.36669044941743
            ],
            [
              -7.038200778132733,
              57.36669044941743
            ],
            [
              -6.792990951841603,
              57.54483225323753
            ],
            [
              -6.69289102275323,
              57.54483225323753
            ],
            [
              -6.581661101549599,
              57.43632363474572
            ]
          ],
          [
            [
              -7.008370799264441,
              57.76940715085976
            ],
            [
              -6.91462086567779,
              57.67895376061
            ],
            [
              -7.022330789375133,
              57.585685134484436
            ],
            [
              -7.163350689475209,
              57.59937295262881
            ]
          ],
          [
            [
              -6.310761293457659,
              58.20906859046727
            ],
            [
              -6.119061429259602,
              58.136377321810016
            ],
            [
              -5.475071885467827,
              57.976955282546385
            ],
            [
              -5.196212083014672,
              57.934079351013565
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "c-lion1",
        "name": "C-Lion1",
        "color": "#7dc042",
        "feature_id": "c-lion1-0",
        "coordinates": [
          18.851062398884636,
          56.3743407954166
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              24.93247657353959,
              60.171163188940454
            ],
            [
              24.4125569418554,
              59.736409384078485
            ],
            [
              23.85005734033541,
              59.622821769410336
            ],
            [
              23.175057818511476,
              59.451717318905224
            ],
            [
              22.275058456079336,
              59.22222391484422
            ],
            [
              21.15005925303933,
              58.641677771384906
            ],
            [
              20.25005989060736,
              57.571890279005004
            ],
            [
              19.125060687567355,
              56.46971137654694
            ],
            [
              17.325061962703415,
              55.84318584148099
            ],
            [
              15.30006339723144,
              55.58970711313168
            ],
            [
              14.4000640347993,
              55.33458061322915
            ],
            [
              13.50006467236733,
              54.94878902385568
            ],
            [
              12.60006530993536,
              54.68951871778469
            ],
            [
              12.15006562871929,
              54.29748595281848
            ],
            [
              12.037565708415343,
              54.165971787151676
            ],
            [
              12.132485641173247,
              54.079177416570104
            ]
          ],
          [
            [
              23.175057818511476,
              59.451717318905224
            ],
            [
              22.95005797790344,
              59.736409384078485
            ],
            [
              22.966757966073033,
              59.82340864139897
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "far-east-submarine-cable-system",
        "name": "Far East Submarine Cable System",
        "color": "#a8346b",
        "feature_id": "far-east-submarine-cable-system-0",
        "coordinates": [
          144.41887892143464,
          53.58378795809725
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              151.28336706538326,
              59.58333960684476
            ],
            [
              151.1999671244645,
              59.33716441962133
            ],
            [
              150.86246736355255,
              58.99117670269845
            ],
            [
              147.59996967473646,
              56.09502251152725
            ],
            [
              143.5499725437925,
              53.768910566668154
            ],
            [
              142.94245297416515,
              53.57753961709912
            ],
            [
              143.5499725437925,
              53.63571515699499
            ],
            [
              154.79996457419256,
              52.963398105593654
            ],
            [
              156.28707352071072,
              52.8124095088161
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "isles-of-scilly-cable",
        "name": "Isles of Scilly Cable",
        "color": "#804399",
        "feature_id": "isles-of-scilly-cable-0",
        "coordinates": [
          -5.992594434900553,
          50.026881511982594
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -6.30817129529234,
              49.919910522553806
            ],
            [
              -6.074921460528742,
              50.02292045625484
            ],
            [
              -5.654511758350935,
              50.04314791189421
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "new-cross-pacific-ncp-cable-system",
        "name": "New Cross Pacific (NCP) Cable System",
        "color": "#22ad97",
        "feature_id": "new-cross-pacific-ncp-cable-system-0",
        "coordinates": [
          150.18288606612575,
          39.04324962041338
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              121.89607788361477,
              30.935660541111474
            ],
            [
              121.61248808391636,
              30.93356762160542
            ],
            [
              121.27498832300435,
              31.126369331761197
            ],
            [
              121.27498832300435,
              31.510798430048965
            ],
            [
              121.39529823837174,
              31.619800328867854
            ]
          ],
          [
            [
              131.39998115096031,
              29.1482487910327
            ],
            [
              128.6999830636644,
              26.159307970773796
            ],
            [
              127.34998402001636,
              25.55188275942578
            ],
            [
              125.99998497636832,
              25.348717422116806
            ],
            [
              124.19998625150438,
              24.941363171753835
            ],
            [
              122.84998720785634,
              25.043329056612095
            ],
            [
              122.17498768603221,
              24.992356687673748
            ],
            [
              121.80144795065141,
              24.863504112487874
            ]
          ],
          [
            [
              140.39997477528036,
              33.565491482352144
            ],
            [
              140.1749749346723,
              33.93964008831958
            ],
            [
              140.11872497452052,
              34.40502275071583
            ],
            [
              140.14684995400054,
              34.69072647741018
            ],
            [
              140.14684995400054,
              34.89859296336232
            ],
            [
              139.97546699999984,
              35.005433
            ]
          ],
          [
            [
              128.24998338244833,
              30.514495959759188
            ],
            [
              128.24998338244833,
              31.67051304708704
            ],
            [
              128.47498322305617,
              32.81231878328768
            ],
            [
              128.92498290427224,
              34.31215165223537
            ],
            [
              128.99949285148878,
              35.17037876180012
            ]
          ],
          [
            [
              125.99998497636832,
              30.901396088515583
            ],
            [
              124.19998625150438,
              31.67051304708704
            ],
            [
              122.84998720785634,
              31.861808602270827
            ],
            [
              121.94998784542437,
              31.71837400188724
            ],
            [
              121.39529823837174,
              31.619800328867854
            ]
          ],
          [
            [
              179.99995828233065,
              47.19740739556977
            ],
            [
              172.7999518228328,
              47.19740739556977
            ],
            [
              160.19996074878455,
              44.051519228735145
            ],
            [
              149.39996839960057,
              38.651811712711236
            ],
            [
              143.09997286257644,
              34.68301765985788
            ],
            [
              140.39997477528036,
              33.565491482352144
            ],
            [
              138.59997605041642,
              32.81231878328768
            ],
            [
              136.79997732555248,
              31.67051304708704
            ],
            [
              134.99997860068837,
              30.901396088515583
            ],
            [
              132.74998019460836,
              30.514495959759188
            ],
            [
              131.39998115096031,
              29.1482487910327
            ],
            [
              130.49998178852834,
              29.34456698948989
            ],
            [
              128.24998338244833,
              30.514495959759188
            ],
            [
              125.99998497636832,
              30.901396088515583
            ],
            [
              122.17498768603221,
              30.85311851188075
            ],
            [
              121.92508786246773,
              30.86475026744725
            ],
            [
              121.89393788393855,
              30.899508355977588
            ],
            [
              121.89607788361477,
              30.935660541111474
            ]
          ],
          [
            [
              -123.96253794783513,
              45.20223210018429
            ],
            [
              -125.09983714216165,
              45.64654149518748
            ],
            [
              -129.59983395432175,
              45.96024524125332
            ],
            [
              -138.59982757864196,
              46.58235508209589
            ],
            [
              -151.19981865268997,
              47.19740739556977
            ],
            [
              -179.99979825051417,
              47.19740739556977
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "monet",
        "name": "Monet",
        "color": "#5bba46",
        "feature_id": "monet-0",
        "coordinates": [
          -40.09319418360187,
          7.245978255095167
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -34.649901217745025,
              0.568578852526286
            ],
            [
              -35.999900261393066,
              -0.781386636225506
            ],
            [
              -38.54296845985962,
              -3.718735129291019
            ]
          ],
          [
            [
              -46.328062944825774,
              -23.961842897596995
            ],
            [
              -44.54989420449712,
              -25.179443898921164
            ],
            [
              -41.399896435985056,
              -25.337712186601237
            ],
            [
              -37.01239954412904,
              -23.80307964083599
            ],
            [
              -31.949728484343723,
              -18.025284192896805
            ],
            [
              -30.149729759479698,
              -13.697820288632613
            ],
            [
              -29.249905043153944,
              -9.290424301035614
            ],
            [
              -31.162403672774985,
              -4.489307673129048
            ],
            [
              -34.649901217745025,
              0.568578852526286
            ],
            [
              -40.499897073553086,
              7.744889052551343
            ],
            [
              -48.59989133544116,
              15.669513225155328
            ],
            [
              -57.599884959761184,
              20.796306105108954
            ],
            [
              -69.2998766713773,
              25.754704263415306
            ],
            [
              -73.34987380232134,
              27.763588526057674
            ],
            [
              -76.94987125204939,
              27.962503359972544
            ],
            [
              -77.84987061448136,
              27.763588526057674
            ],
            [
              -78.74986997691342,
              27.26471187783389
            ],
            [
              -79.64986933934539,
              26.763586569619832
            ],
            [
              -80.08893155227206,
              26.35058457732009
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "sea-us",
        "name": "SEA-US",
        "color": "#4eb748",
        "feature_id": "sea-us-0",
        "coordinates": [
          -150.15189957591022,
          27.02379429258849
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              144.69470173285575,
              13.464772962370034
            ],
            [
              144.89997158744055,
              13.273238157547667
            ],
            [
              145.34997126865645,
              13.492128176464178
            ],
            [
              146.24997063108842,
              14.038469666260113
            ],
            [
              147.14996999352056,
              14.14758350694865
            ],
            [
              151.1999671244645,
              14.365653759228536
            ],
            [
              160.19996074878455,
              15.669513225155328
            ],
            [
              179.99992672230294,
              18.251816319028308
            ]
          ],
          [
            [
              125.0656106382879,
              1.378623021535844
            ],
            [
              125.99998497636832,
              1.918228780215685
            ],
            [
              126.4499846575844,
              3.266814816815753
            ],
            [
              126.4499846575844,
              5.061986954416028
            ],
            [
              133.19997987582443,
              9.08033076823304
            ],
            [
              137.24997700676838,
              10.853089690745378
            ],
            [
              143.9999722250084,
              13.054150695298716
            ],
            [
              144.69469829535794,
              13.464777824932952
            ]
          ],
          [
            [
              125.61287587560011,
              7.079988883160723
            ],
            [
              125.99998497636832,
              5.957818681088611
            ],
            [
              126.4499846575844,
              5.061986954416028
            ]
          ],
          [
            [
              133.19997987582443,
              9.08033076823304
            ],
            [
              133.64997955704033,
              8.190543417795567
            ],
            [
              134.5609408257699,
              7.531746239289589
            ]
          ],
          [
            [
              137.24997700676838,
              10.853089690745378
            ],
            [
              137.69997668798445,
              9.96791518697421
            ],
            [
              138.06149986937814,
              9.443922836169293
            ]
          ],
          [
            [
              -118.39945344493319,
              33.8624748689854
            ],
            [
              -120.59984033000163,
              33.18971466460036
            ],
            [
              -122.39983905486565,
              33.18971466460036
            ],
            [
              -127.79983522945773,
              32.81231878328768
            ],
            [
              -138.5998275786418,
              29.73606949729205
            ],
            [
              -147.59982120296192,
              28.161052262220892
            ],
            [
              -152.99981737755388,
              25.754704263415306
            ],
            [
              -157.94981387093,
              22.67720619658283
            ],
            [
              -158.399813552146,
              22.05298561667763
            ],
            [
              -158.45606295953144,
              21.73983373091116
            ],
            [
              -158.45606350661404,
              21.635297384859456
            ],
            [
              -158.22066328843272,
              21.463446823448095
            ],
            [
              -158.39981355214616,
              21.268825931479142
            ],
            [
              -158.84981323336194,
              21.00649984517682
            ],
            [
              -163.79980972673806,
              19.952622905164304
            ],
            [
              -172.79980335105807,
              19.952622905164304
            ],
            [
              -179.99979825051417,
              18.251816319028308
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "faster",
        "name": "FASTER",
        "color": "#4bb748",
        "feature_id": "faster-0",
        "coordinates": [
          -152.11059071043024,
          45.96024524125332
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              138.59997605041642,
              32.24321001626265
            ],
            [
              136.79997732555248,
              31.094262827639596
            ],
            [
              134.99997860068837,
              30.320465424761352
            ],
            [
              131.39998115096031,
              29.246454972180487
            ],
            [
              129.59998242609637,
              28.951554732193216
            ],
            [
              128.24998338244833,
              28.55704546571141
            ],
            [
              122.84998720785634,
              26.461843796189072
            ],
            [
              121.94998784542437,
              25.754704263415306
            ],
            [
              121.4625881907028,
              25.181712818924467
            ]
          ],
          [
            [
              -124.40833763202659,
              43.1186640985502
            ],
            [
              -125.9998365045937,
              43.07331078300331
            ],
            [
              -129.59983395432175,
              43.72721479104973
            ],
            [
              -138.5998275786418,
              45.01383364395318
            ],
            [
              -151.19981865269014,
              45.96024524125332
            ],
            [
              -179.99979825051417,
              45.96024524125332
            ]
          ],
          [
            [
              140.39997477528036,
              33.00121852265437
            ],
            [
              140.0624750143684,
              33.93964008831958
            ],
            [
              140.39997477528036,
              34.40502275071583
            ],
            [
              140.28747485438052,
              34.69072647741018
            ],
            [
              140.07653750381044,
              34.85244570884623
            ],
            [
              139.9548550906076,
              34.97657002902224
            ]
          ],
          [
            [
              138.59997605041642,
              32.24321001626265
            ],
            [
              140.39997477528036,
              33.00121852265437
            ],
            [
              143.09997286257644,
              34.31215165223537
            ],
            [
              149.39996839960057,
              37.58978657360316
            ],
            [
              160.19996074878455,
              42.74371346443661
            ],
            [
              172.7999518228328,
              45.96024524125332
            ],
            [
              179.99995828233065,
              45.96024524125332
            ]
          ],
          [
            [
              136.87399727311598,
              34.33682825203164
            ],
            [
              137.69997668798445,
              33.18971466460036
            ],
            [
              138.59997605041642,
              32.24321001626265
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "kerch-strait-cable",
        "name": "Kerch Strait Cable",
        "color": "#36ada8",
        "feature_id": "kerch-strait-cable-0",
        "coordinates": [
          36.63054428182684,
          45.368624377764036
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              36.477388395015794,
              45.35698507082441
            ],
            [
              36.67504825499154,
              45.372006522678475
            ],
            [
              36.770848187126035,
              45.42360971041049
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "north-west-cable-system",
        "name": "North-West Cable System",
        "color": "#4f479c",
        "feature_id": "north-west-cable-system-0",
        "coordinates": [
          122.92707989390621,
          -13.957692677548891
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              129.92498219586352,
              -10.011516755342626
            ],
            [
              130.1624820276164,
              -9.734235342300764
            ],
            [
              130.27498194792028,
              -9.734235342300764
            ],
            [
              130.27498194792028,
              -9.95592161622909
            ],
            [
              130.1624820276164,
              -10.177457430361159
            ]
          ],
          [
            [
              129.20623270503225,
              -11.778847244506409
            ],
            [
              129.82498226670444,
              -10.841130095525784
            ],
            [
              130.1624820276164,
              -10.177457430361159
            ],
            [
              129.92498219586352,
              -10.011516755342626
            ],
            [
              129.7124823464005,
              -10.06670853150743
            ],
            [
              129.59998242609637,
              -10.620064860363328
            ],
            [
              129.0374828245762,
              -11.723727283361256
            ]
          ],
          [
            [
              130.50000678851072,
              -12.219087219884159
            ],
            [
              130.5001317884223,
              -11.999142688339674
            ],
            [
              130.63208169494752,
              -11.762596324883674
            ]
          ],
          [
            [
              118.57724023471044,
              -20.31344226536234
            ],
            [
              118.57499023630416,
              -18.880139975101287
            ],
            [
              118.12499055508832,
              -18.240251410711636
            ],
            [
              118.2374904753922,
              -17.59799899615561
            ],
            [
              120.59998880177616,
              -15.22403228464728
            ],
            [
              121.61248808451225,
              -14.898126357061557
            ],
            [
              122.28748760633616,
              -14.026655889819637
            ],
            [
              123.29998688907224,
              -13.917484462464614
            ],
            [
              124.42498609211222,
              -13.589662250511992
            ],
            [
              126.4499846575844,
              -11.50333384598423
            ],
            [
              127.34998402001636,
              -11.50333384598423
            ],
            [
              129.0374828245762,
              -11.723727283361256
            ],
            [
              129.20623270503225,
              -11.778847244506409
            ],
            [
              129.93758218693742,
              -11.998971512032199
            ],
            [
              130.50000678851072,
              -12.219087219884159
            ],
            [
              130.84314154543083,
              -12.46747433620365
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "terra-sw",
        "name": "TERRA SW",
        "color": "#63bb45",
        "feature_id": "terra-sw-0",
        "coordinates": [
          -155.87811075842,
          59.33341963679686
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -154.89976666569265,
              59.718120043251616
            ],
            [
              -154.87637668285322,
              59.695436046444314
            ],
            [
              -154.94356663355785,
              59.63309075919986
            ],
            [
              -155.04512655904622,
              59.59752775931577
            ],
            [
              -155.79356600993754,
              59.40085271737078
            ],
            [
              -155.89473593571196,
              59.32777430348355
            ],
            [
              -155.75918603516115,
              59.373802346117884
            ],
            [
              -155.2649563977633,
              59.46236377142185
            ],
            [
              -154.79356674360838,
              59.46997939044749
            ],
            [
              -154.75274677355688,
              59.44218602760361
            ]
          ],
          [
            [
              -154.84668670463589,
              59.7080485715488
            ],
            [
              -154.79200674475308,
              59.52787100202244
            ],
            [
              -154.75274677355688,
              59.44218602760361
            ]
          ],
          [
            [
              -154.85884669571448,
              59.94918397333103
            ],
            [
              -154.8525657444611,
              59.9624117448507
            ],
            [
              -154.84771606848528,
              59.972621618602176
            ],
            [
              -154.70811616737907,
              60.06032279981204
            ],
            [
              -154.55811627364037,
              60.128876021749335
            ],
            [
              -154.51763694605037,
              60.14198209826003
            ],
            [
              -154.42950701070876,
              60.17915062699825
            ],
            [
              -154.39043703937324,
              60.19468724270362
            ],
            [
              -154.33692707863196,
              60.20036807902241
            ]
          ],
          [
            [
              -153.88047741351613,
              59.77610434297918
            ],
            [
              -153.95606735805796,
              59.76396060900032
            ],
            [
              -154.0357572995917,
              59.73404564131664
            ],
            [
              -154.1232572353955,
              59.71198775040105
            ],
            [
              -154.245127145983,
              59.70174290294207
            ],
            [
              -154.39200703822144,
              59.697802518247215
            ],
            [
              -154.64737685086388,
              59.653599109675724
            ],
            [
              -154.69512681583112,
              59.61807806397951
            ],
            [
              -154.75450677226564,
              59.52153142088008
            ],
            [
              -154.75274677355688,
              59.44218602760361
            ]
          ],
          [
            [
              -154.84668670463589,
              59.7080485715488
            ],
            [
              -154.90611602711408,
              59.75471391016691
            ]
          ],
          [
            [
              -153.88047741351613,
              59.77610434297918
            ],
            [
              -153.95294736034703,
              59.77968821615622
            ],
            [
              -154.04356729386168,
              59.75687472421299
            ],
            [
              -154.09356725717814,
              59.767107730226684
            ],
            [
              -154.10611659384122,
              59.787212090872714
            ],
            [
              -154.12012723769186,
              59.76631720126949
            ],
            [
              -154.16866720207952,
              59.74738423416082
            ],
            [
              -154.31543709439865,
              59.76081319116531
            ],
            [
              -154.4732569786106,
              59.755303236949885
            ],
            [
              -154.64644685154622,
              59.706893461930036
            ],
            [
              -154.82168672297766,
              59.71671818723854
            ],
            [
              -154.90611602711408,
              59.75471391016691
            ]
          ],
          [
            [
              -151.5442491275403,
              59.646565622018784
            ],
            [
              -152.09981801512194,
              59.622821769410336
            ],
            [
              -152.99981737755388,
              59.56588346342974
            ],
            [
              -153.54512765955266,
              59.61807806397951
            ],
            [
              -153.5910776258405,
              59.63553746032122
            ],
            [
              -153.63213759571602,
              59.68237455442966
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "alaska-united-turnagain-arm-auta",
        "name": "Alaska United Turnagain Arm (AUTA)",
        "color": "#3e4b9f",
        "feature_id": "alaska-united-turnagain-arm-auta-0",
        "coordinates": [
          -149.35712107079763,
          60.92189594566955
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -149.73219045699616,
              61.01569206774266
            ],
            [
              -149.6314105309355,
              60.96963910759561
            ],
            [
              -149.4837106392987,
              60.95361622164211
            ],
            [
              -149.35138073638532,
              60.92045755707332
            ],
            [
              -149.19513085102145,
              60.92197391282372
            ],
            [
              -149.08732014920366,
              60.88930284264339
            ],
            [
              -148.98981100165878,
              60.82829123683615
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "acs-alaska-oregon-network-akorn",
        "name": "ACS Alaska-Oregon Network (AKORN)",
        "color": "#4c4fa1",
        "feature_id": "acs-alaska-oregon-network-akorn-0",
        "coordinates": [
          -137.94389964435564,
          54.73163712377132
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -151.29166931285096,
              60.68992891270409
            ],
            [
              -151.04161876476013,
              60.567312045135765
            ],
            [
              -150.91661885331132,
              60.444228327647664
            ],
            [
              -151.04161876476013,
              60.258724767959194
            ],
            [
              -151.41919921928587,
              60.02342960326277
            ],
            [
              -151.5442491275403,
              59.835530750006114
            ],
            [
              -151.5442491275403,
              59.646565622018784
            ],
            [
              -152.09981801512194,
              59.50884868221257
            ],
            [
              -152.09981801512194,
              59.1068949571908
            ],
            [
              -151.19981865268997,
              58.8750683108978
            ],
            [
              -146.24982215931388,
              58.52439396084483
            ],
            [
              -139.94982662228983,
              56.840738642145595
            ],
            [
              -139.04982725985775,
              55.84318584148099
            ],
            [
              -137.24982853499384,
              54.03403825672413
            ],
            [
              -134.09983076648177,
              50.740281893948264
            ],
            [
              -127.79983522945773,
              45.33107107332478
            ],
            [
              -125.09983714216165,
              44.051519228735145
            ],
            [
              -124.09993785049977,
              43.98220183349406
            ]
          ],
          [
            [
              -151.29166931285096,
              60.68992891270409
            ],
            [
              -151.29166931285096,
              60.84842055964978
            ],
            [
              -150.2334100892656,
              61.1572910527679
            ],
            [
              -149.85841036439217,
              61.217558335568384
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "adria-1",
        "name": "Adria-1",
        "color": "#65b545",
        "feature_id": "adria-1-0",
        "coordinates": [
          19.252270468971318,
          41.16624298529169
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              19.91959012471534,
              39.61953623629293
            ],
            [
              19.575060368783426,
              39.78482855593708
            ],
            [
              19.01256076726341,
              40.04369219282995
            ],
            [
              18.90006084695932,
              40.387320290775165
            ],
            [
              19.125060687567355,
              41.0693404382163
            ],
            [
              19.4500604573345,
              41.31691028026597
            ],
            [
              19.125060687567355,
              41.40772623743587
            ],
            [
              18.11256140483144,
              42.41235450073577
            ],
            [
              18.10651140911733,
              42.642077955426515
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "africa-coast-to-europe-ace",
        "name": "Africa Coast to Europe (ACE)",
        "color": "#8cc63f",
        "feature_id": "africa-coast-to-europe-ace-0",
        "coordinates": [
          -15.412392583191377,
          7.410105237917236
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -17.44571340535299,
              14.686594841995088
            ],
            [
              -17.549913332132803,
              13.929303843271725
            ],
            [
              -17.099913650916818,
              12.17588718550806
            ],
            [
              -16.64991396910486,
              11.735650161405744
            ],
            [
              -16.199914287888873,
              11.680570534838523
            ],
            [
              -15.791145687384272,
              11.774131923775144
            ]
          ],
          [
            [
              18.449961165814358,
              -33.69332014378628
            ],
            [
              17.55006180331145,
              -33.55534420877598
            ],
            [
              16.20006275966341,
              -32.61276000573585
            ],
            [
              14.85006371601537,
              -30.309953344646914
            ],
            [
              11.70006594750339,
              -23.493922445897766
            ],
            [
              10.35006690385535,
              -18.026426383713385
            ],
            [
              10.35006690385535,
              -10.620064860363328
            ],
            [
              9.00006786020731,
              -6.616650693475464
            ],
            [
              8.55006817899124,
              -4.825692499217524
            ],
            [
              6.987569285284223,
              0.118588418888407
            ],
            [
              6.733269466028645,
              0.333286471885856
            ]
          ],
          [
            [
              7.200069135343199,
              1.01853421661562
            ],
            [
              8.55006817899124,
              0.793562652607278
            ],
            [
              9.454267538448192,
              0.394465191855375
            ]
          ],
          [
            [
              7.200069135343199,
              1.468426767332062
            ],
            [
              9.00006786020731,
              1.468426767332062
            ],
            [
              9.768227316036075,
              1.860150409321903
            ]
          ],
          [
            [
              3.423511810692077,
              6.439066911484701
            ],
            [
              2.475072482575314,
              4.164912849976844
            ],
            [
              2.250072641967279,
              3.828234303320954
            ]
          ],
          [
            [
              2.440112507341183,
              6.356673335458169
            ],
            [
              1.800072960751208,
              5.061986954416028
            ],
            [
              1.800072960751208,
              3.828234303320954
            ]
          ],
          [
            [
              -0.204315619320994,
              5.558285889905761
            ],
            [
              -0.674925285936666,
              3.279837005485092
            ],
            [
              -0.674925285936666,
              2.830478071896835
            ]
          ],
          [
            [
              -10.797188115230767,
              6.300378530564558
            ],
            [
              -12.599916838160823,
              4.613591578862867
            ]
          ],
          [
            [
              -13.238096386068491,
              8.485442435793999
            ],
            [
              -14.849915244240833,
              6.852191098754417
            ]
          ],
          [
            [
              -15.749914606672803,
              7.744889052551343
            ],
            [
              -13.70382605614111,
              9.513434601362718
            ]
          ],
          [
            [
              -17.999913012752813,
              13.492128176464178
            ],
            [
              -17.099913650320843,
              13.492128176464178
            ],
            [
              -16.58136401766629,
              13.456136894896872
            ]
          ],
          [
            [
              -15.978284444893546,
              18.083868491706937
            ],
            [
              -17.099913650320843,
              18.251816319028308
            ],
            [
              -18.449912693968884,
              18.251816319028308
            ]
          ],
          [
            [
              -4.338542690595716,
              47.81102015174923
            ],
            [
              -5.849921619920792,
              47.65407102366086
            ],
            [
              -7.649920344784732,
              46.89076287862241
            ],
            [
              -10.799918113296798,
              43.40114497315386
            ],
            [
              -10.799918113296798,
              39.69832335493328
            ],
            [
              -10.349918432080813,
              39.00237890905848
            ],
            [
              -9.331559153496016,
              38.69016197235561
            ],
            [
              -9.899918750864742,
              38.29952060596935
            ],
            [
              -11.249917794512783,
              36.51238821239372
            ],
            [
              -11.699917475728853,
              35.419780517080454
            ],
            [
              -13.274916359984843,
              32.052708023486105
            ],
            [
              -14.174915722416813,
              29.73606949729205
            ],
            [
              -14.737415323936801,
              28.161052262220892
            ],
            [
              -15.299914925456818,
              26.964304734562802
            ]
          ],
          [
            [
              2.475072482575314,
              4.164912849976844
            ],
            [
              5.400070410479259,
              2.592701464601845
            ],
            [
              6.300069772911229,
              2.367912558705314
            ],
            [
              7.200069135343199,
              1.468426767332062
            ],
            [
              7.200069135343199,
              1.01853421661562
            ],
            [
              6.733269466028645,
              0.333286471885856
            ]
          ],
          [
            [
              -16.51801406254387,
              28.059088061264703
            ],
            [
              -16.199914287888873,
              27.56430948794184
            ],
            [
              -15.299914925456818,
              26.964304734562802
            ],
            [
              -17.099913650320843,
              22.884654113882362
            ],
            [
              -18.449912693968884,
              19.952622905164304
            ],
            [
              -18.449912693968884,
              16.53419619825962
            ],
            [
              -17.999913012752813,
              15.23578178303569
            ],
            [
              -17.44571340535299,
              14.686594841995088
            ],
            [
              -17.999913012752813,
              13.929303843271725
            ],
            [
              -17.999913012752813,
              13.492128176464178
            ],
            [
              -18.449912693968884,
              11.735650161405744
            ],
            [
              -17.099913650320843,
              8.635699417327544
            ],
            [
              -15.749914606672803,
              7.744889052551343
            ],
            [
              -14.849915244240833,
              6.852191098754417
            ],
            [
              -12.599916838160823,
              4.613591578862867
            ],
            [
              -10.799918113296798,
              2.817450442654064
            ],
            [
              -5.399921938704722,
              2.817450442654064
            ],
            [
              -4.38742265596872,
              3.266814816815753
            ],
            [
              -4.026242911831901,
              5.323508791824736
            ],
            [
              -3.824923054448732,
              3.266814816815753
            ],
            [
              -3.149923532624712,
              2.817450442654064
            ],
            [
              -0.674925285936666,
              2.830478071896835
            ],
            [
              1.575073120143173,
              3.828234303320954
            ],
            [
              2.250072641967279,
              3.828234303320954
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "alaska-united-east-au-east",
        "name": "Alaska United East (AU-East)",
        "color": "#d88227",
        "feature_id": "alaska-united-east-au-east-0",
        "coordinates": [
          -135.49180070038952,
          55.29638226769842
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -146.35343293589432,
              61.130356463051186
            ],
            [
              -146.5655427802754,
              61.1211622680666
            ],
            [
              -146.67394270074547,
              61.07351149028989
            ],
            [
              -146.8290725869311,
              60.97605861690994
            ],
            [
              -146.8992325354567,
              60.86794329208297
            ],
            [
              -147.08317240050525,
              60.81100723809513
            ],
            [
              -147.52160207884185,
              60.7882580984502
            ],
            [
              -147.88996180858683,
              60.77965282959889
            ],
            [
              -148.03166170462575,
              60.77038115412259
            ],
            [
              -148.2117815724768,
              60.793738298603614
            ],
            [
              -148.32843148689415,
              60.793738298603614
            ],
            [
              -148.39855143544912,
              60.79676347493199
            ],
            [
              -148.5215513452076,
              60.781322325047
            ],
            [
              -148.6847312254872,
              60.7730276878024
            ]
          ],
          [
            [
              -135.8998294913458,
              55.84318584148099
            ],
            [
              -134.4665305067084,
              56.033580239111444
            ],
            [
              -134.4154516944513,
              56.466474307352456
            ],
            [
              -134.52473046547883,
              56.84950003985648
            ],
            [
              -134.61379219497502,
              57.01007188259328
            ],
            [
              -134.74495930054167,
              57.237736704880774
            ],
            [
              -134.76370924947747,
              57.49556889756499
            ],
            [
              -134.80901526468486,
              57.59460906161556
            ],
            [
              -134.85911522919366,
              57.734649479402236
            ],
            [
              -134.88098521370074,
              58.01297892893797
            ],
            [
              -134.95979129568087,
              58.10720953221971
            ],
            [
              -134.96702128978032,
              58.197824452869355
            ],
            [
              -134.98333014060262,
              58.28982779937181
            ],
            [
              -135.0010951286137,
              58.365509963813906
            ],
            [
              -135.0003212653491,
              58.42899050279709
            ],
            [
              -134.92327132187847,
              58.45076363488815
            ],
            [
              -134.84319138063077,
              58.41219086765227
            ],
            [
              -134.76047144132008,
              58.34616641889022
            ],
            [
              -134.70139148466535,
              58.33952725767904
            ],
            [
              -134.60315155674118,
              58.356440225770825
            ],
            [
              -134.5422116014511,
              58.3484229247305
            ],
            [
              -134.40686170075347,
              58.29957675077529
            ]
          ],
          [
            [
              -122.3157605716501,
              47.82410083595485
            ],
            [
              -122.44105402626477,
              47.8747791617829
            ],
            [
              -122.46918400633714,
              47.921930050335504
            ],
            [
              -122.56761643660673,
              47.95960846190178
            ],
            [
              -122.62387139675518,
              48.03488959703961
            ],
            [
              -122.65199137683467,
              48.11006090495562
            ],
            [
              -122.84983873608172,
              48.2380697210028
            ],
            [
              -123.74983809851369,
              48.29423569031499
            ],
            [
              -124.64983746094566,
              48.55552775661812
            ],
            [
              -125.0998371421619,
              48.55552775661812
            ],
            [
              -131.39983267918578,
              50.740281893948264
            ],
            [
              -134.54983044769776,
              54.03403825672413
            ],
            [
              -135.8998294913458,
              55.84318584148099
            ],
            [
              -138.1498278974258,
              57.08606597586797
            ],
            [
              -144.8998231156658,
              58.52439396084483
            ],
            [
              -147.59982120296192,
              59.451717318905224
            ],
            [
              -147.98004174249775,
              59.849491839756666
            ],
            [
              -147.86071183004677,
              59.95730042115315
            ],
            [
              -147.76134190295164,
              60.01588909309447
            ],
            [
              -147.72926192648782,
              60.10213978192459
            ],
            [
              -147.61520201017032,
              60.237669158236145
            ],
            [
              -147.53332125007122,
              60.304476348798715
            ],
            [
              -147.45905212473303,
              60.47899019642449
            ],
            [
              -147.47692129002542,
              60.590316134898785
            ],
            [
              -147.63376199655337,
              60.620197465359496
            ],
            [
              -147.86442101551702,
              60.6207763637542
            ],
            [
              -148.03610170136815,
              60.665011707179104
            ],
            [
              -148.03610170136815,
              60.69939513380339
            ],
            [
              -148.08639166447188,
              60.75819549198792
            ],
            [
              -148.19582078075035,
              60.78210822700612
            ],
            [
              -148.32580148882366,
              60.78094157058311
            ],
            [
              -148.4135414244514,
              60.78620827245604
            ],
            [
              -148.51818134768,
              60.772241563154324
            ],
            [
              -148.6847312254872,
              60.7730276878024
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "alaska-united-west-au-west",
        "name": "Alaska United West (AU-West)",
        "color": "#30b995",
        "feature_id": "alaska-united-west-au-west-0",
        "coordinates": [
          -136.29325737407112,
          53.97488465252326
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -131.64788372493703,
              55.34196841314818
            ],
            [
              -131.6278637396252,
              55.18053443385054
            ],
            [
              -131.74758365179008,
              54.99362892777967
            ],
            [
              -131.6248325197938,
              54.81936191424907
            ],
            [
              -131.84983236040176,
              54.559258765782204
            ],
            [
              -133.19983140404972,
              54.559258765782204
            ],
            [
              -134.99983012891383,
              54.4285813960183
            ],
            [
              -136.34982917256178,
              54.03403825672413
            ]
          ],
          [
            [
              -149.44767066574025,
              60.11004931326181
            ],
            [
              -149.37169494834603,
              59.90606992457441
            ],
            [
              -148.0498208841779,
              59.451717318905224
            ],
            [
              -145.7998224780979,
              58.52439396084483
            ],
            [
              -139.49982694107385,
              56.840738642145595
            ],
            [
              -138.5998275786418,
              55.84318584148099
            ],
            [
              -136.34982917256178,
              54.03403825672413
            ],
            [
              -133.19983140404972,
              50.740281893948264
            ],
            [
              -127.79983522945773,
              47.50228998113275
            ],
            [
              -125.09983714216165,
              46.427489586178865
            ],
            [
              -123.92376939189985,
              46.16522099122727
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "alba-1",
        "name": "ALBA-1",
        "color": "#e11e25",
        "feature_id": "alba-1-0",
        "coordinates": [
          -71.07810156992376,
          15.648402153871956
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -75.82892204614033,
              20.029343742363466
            ],
            [
              -76.04987188961734,
              19.529070924351004
            ],
            [
              -76.72487141144136,
              18.67864702215462
            ],
            [
              -77.1032411434007,
              18.398661383088154
            ]
          ],
          [
            [
              -75.71237212870537,
              19.96373301197181
            ],
            [
              -75.59987220840135,
              19.104405475930548
            ],
            [
              -75.14987252718537,
              18.251816319028308
            ],
            [
              -71.09987539624133,
              15.669513225155328
            ],
            [
              -67.94987762772926,
              12.615395567393307
            ],
            [
              -67.0498782652973,
              11.294709319565555
            ],
            [
              -66.88962837881978,
              10.603529760437084
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "aletar",
        "name": "Aletar",
        "color": "#57b947",
        "feature_id": "aletar-0",
        "coordinates": [
          32.773344583317055,
          33.178888886080955
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              29.893513059094772,
              31.191465077638554
            ],
            [
              30.150052877359542,
              31.67051304708704
            ],
            [
              35.55004905195153,
              34.77547607575676
            ],
            [
              35.897798805602406,
              34.89170328553857
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "alonso-de-ojeda",
        "name": "Alonso de Ojeda",
        "color": "#95ad3b",
        "feature_id": "alonso-de-ojeda-0",
        "coordinates": [
          -69.40457564357698,
          12.145180356098109
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -69.87858626141407,
              12.414622452999794
            ],
            [
              -69.74987635259329,
              12.285833556268273
            ],
            [
              -69.07487683076927,
              12.01088236045885
            ],
            [
              -68.89264695986272,
              12.090439618305055
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "alpal-2",
        "name": "Alpal-2",
        "color": "#b55927",
        "feature_id": "alpal-2-0",
        "coordinates": [
          2.7000723231831785,
          38.08046938787112
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              2.901442180531063,
              36.800219896643256
            ],
            [
              2.700072323183178,
              37.232354321556215
            ],
            [
              2.700072323183178,
              38.651811712711236
            ],
            [
              2.970972131275403,
              39.35484412819346
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "americas-i-north",
        "name": "Americas-I North",
        "color": "#9b4d9d",
        "feature_id": "americas-i-north-0",
        "coordinates": [
          -70.13277581463579,
          27.173023594546805
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -64.93708976201644,
              18.372992194090983
            ],
            [
              -64.79987985921728,
              18.89166158430325
            ],
            [
              -65.02487969982532,
              20.796306105108954
            ],
            [
              -66.82487842468926,
              23.711258142484382
            ],
            [
              -69.2998766713773,
              26.763586569619832
            ],
            [
              -73.34987380232134,
              28.75448641587161
            ],
            [
              -76.04987188961734,
              28.55704546571141
            ],
            [
              -77.39987093326538,
              28.359233526108653
            ],
            [
              -79.64986933934539,
              27.763588526057674
            ],
            [
              -80.39425132826774,
              27.638731771078767
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "amerigo-vespucci",
        "name": "Amerigo Vespucci",
        "color": "#b59a30",
        "feature_id": "amerigo-vespucci-0",
        "coordinates": [
          -68.57712870433583,
          12.0700746294292
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -68.27663739624965,
              12.15106719856388
            ],
            [
              -68.51237722924927,
              12.06589527357022
            ],
            [
              -68.89264695986272,
              12.090439618305055
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "antillas-1",
        "name": "Antillas 1",
        "color": "#3e60ac",
        "feature_id": "antillas-1-0",
        "coordinates": [
          -68.42888898863063,
          18.59865513032625
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -70.16178606079262,
              18.699363841238295
            ],
            [
              -69.69362639244132,
              18.144943564296312
            ],
            [
              -69.2998766713773,
              17.931002277731153
            ],
            [
              -68.51237722924927,
              17.931002277731153
            ],
            [
              -68.28737738864132,
              18.251816319028308
            ],
            [
              -68.43815728182749,
              18.621371316104643
            ],
            [
              -68.1748774683373,
              18.785187974742087
            ],
            [
              -67.49987794651328,
              19.104405475930548
            ],
            [
              -67.0498782652973,
              18.998067525949068
            ],
            [
              -66.48737866377729,
              18.785187974742087
            ],
            [
              -66.01686899768669,
              18.442038833890734
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "seax-1",
        "name": "SEAX-1",
        "color": "#61a042",
        "feature_id": "seax-1-0",
        "coordinates": [
          104.39998912954829,
          1.9181839669934675
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              103.8506842608908,
              2.295706828435243
            ],
            [
              104.32889093295782,
              2.140106066877451
            ],
            [
              104.40000027800002,
              1.918228780215685
            ],
            [
              104.28810035727113,
              1.468426767332062
            ],
            [
              104.17793012281672,
              1.263407170707467
            ],
            [
              104.13781784654563,
              1.263407170707467
            ]
          ],
          [
            [
              103.94648497427451,
              1.327257988673239
            ],
            [
              104.13781784654563,
              1.263407170707467
            ]
          ],
          [
            [
              103.96259824410978,
              1.134724535942212
            ],
            [
              104.0156255502948,
              1.223182297279749
            ],
            [
              104.13781784654563,
              1.263407170707467
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "apollo",
        "name": "Apollo",
        "color": "#d36f27",
        "feature_id": "apollo-0",
        "coordinates": [
          -38.356193046914996,
          40.718016129004695
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -72.87218414072119,
              40.80058099504518
            ],
            [
              -71.09987539624133,
              40.21572406083388
            ],
            [
              -68.39987730894534,
              40.55848045058687
            ],
            [
              -61.199882409489234,
              40.72920412488665
            ],
            [
              -50.39989006030518,
              42.079235618164205
            ],
            [
              -39.59989771112103,
              44.37405751055866
            ],
            [
              -23.39990918734489,
              48.40638249553794
            ],
            [
              -16.199914287888873,
              49.294684219425534
            ],
            [
              -10.799918113296798,
              50.16726116292705
            ],
            [
              -8.099920026000802,
              50.45463912589386
            ],
            [
              -5.399921938704722,
              50.88245364291035
            ],
            [
              -4.544402544762761,
              50.82820142743802
            ]
          ],
          [
            [
              -74.0470933084045,
              40.123492658236984
            ],
            [
              -71.09987539624133,
              39.69832335493328
            ],
            [
              -68.39987730894534,
              39.351217571171134
            ],
            [
              -61.199882409489234,
              38.29952060596935
            ],
            [
              -50.39989006030518,
              38.47588134813884
            ],
            [
              -39.59989771112103,
              40.387320290775165
            ],
            [
              -23.39990918734489,
              44.694829089578064
            ],
            [
              -16.199914287888873,
              45.96024524125332
            ],
            [
              -5.399921938704722,
              49.14772788577403
            ],
            [
              -4.499922576272752,
              49.00033438946335
            ],
            [
              -3.459883313046362,
              48.730552979168635
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "arcos",
        "name": "ARCOS",
        "color": "#51489d",
        "feature_id": "arcos-0",
        "coordinates": [
          -75.07080241424389,
          11.27276961225553
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -74.19504320359542,
              22.629891679112745
            ],
            [
              -74.47487300536135,
              22.67720619658283
            ],
            [
              -74.58737292566536,
              22.884654113882362
            ],
            [
              -74.47487300536135,
              23.298598065875808
            ],
            [
              -74.47487300536135,
              23.711258142484382
            ],
            [
              -74.92487268657733,
              24.225251377401804
            ],
            [
              -75.52590226080238,
              24.403328403350315
            ],
            [
              -75.77889084339488,
              24.410331177006384
            ],
            [
              -76.4998715708334,
              24.532657566160623
            ],
            [
              -76.94987125204939,
              24.941363171753835
            ],
            [
              -77.34020097553618,
              25.067217613601912
            ],
            [
              -77.84987061448136,
              25.145210227401265
            ],
            [
              -79.1998696581294,
              25.754704263415306
            ],
            [
              -79.64986933934539,
              25.754704263415306
            ],
            [
              -80.16222149850142,
              25.93320697846923
            ]
          ],
          [
            [
              -72.11940467399728,
              21.851088325532913
            ],
            [
              -72.44987443988929,
              22.469443964829594
            ],
            [
              -73.34987380232134,
              22.884654113882362
            ],
            [
              -73.79987348353733,
              22.884654113882362
            ],
            [
              -74.19504320359542,
              22.629891679112745
            ]
          ],
          [
            [
              -66.01686899709078,
              18.441839618642774
            ],
            [
              -66.48737866377729,
              18.625351394064836
            ],
            [
              -67.0498782652973,
              18.67864702215462
            ],
            [
              -67.38737802620926,
              18.465364393137207
            ],
            [
              -67.6123778668173,
              17.823934412537824
            ],
            [
              -68.39987730894534,
              13.929303843271725
            ],
            [
              -68.89264695986272,
              12.090439618305055
            ],
            [
              -69.07487683076927,
              11.95585820711483
            ],
            [
              -69.74987635259329,
              12.17588718550806
            ],
            [
              -70.12819608458811,
              12.355002950040102
            ],
            [
              -70.42487587441731,
              12.17588718550806
            ],
            [
              -70.2043160306639,
              11.708782466419052
            ],
            [
              -70.42487587441731,
              11.735650161405744
            ],
            [
              -70.64987571502535,
              12.17588718550806
            ],
            [
              -71.32487523684928,
              12.615395567393307
            ],
            [
              -71.99987475867339,
              12.615395567393307
            ],
            [
              -72.67487428049732,
              12.17588718550806
            ],
            [
              -72.89987412110536,
              11.735650161405744
            ],
            [
              -72.95270408368008,
              11.48311475929384
            ],
            [
              -73.34987380232134,
              11.735650161405744
            ],
            [
              -73.79987348353733,
              11.735650161405744
            ],
            [
              -74.69987284596938,
              11.515266158038685
            ],
            [
              -75.3748723677934,
              11.073982781226704
            ],
            [
              -75.50573227509092,
              10.386807451633246
            ],
            [
              -76.04987188961734,
              10.410816505402636
            ],
            [
              -77.4198709196932,
              9.672311317776519
            ],
            [
              -77.93346055586152,
              9.125435434149182
            ],
            [
              -78.29987029629342,
              9.672311317776519
            ],
            [
              -79.08736973782538,
              9.746236973759864
            ],
            [
              -79.75352926591177,
              9.437623338483888
            ],
            [
              -80.09986902056127,
              9.746236973759864
            ],
            [
              -82.34986742664137,
              9.96791518697421
            ],
            [
              -83.03765938887453,
              9.988597517410057
            ],
            [
              -83.24986678907334,
              11.294709319565555
            ],
            [
              -83.47486662968137,
              11.735650161405744
            ],
            [
              -83.7715488504407,
              11.991681428073548
            ],
            [
              -83.47486662968137,
              12.17588718550806
            ],
            [
              -83.24986678907352,
              12.615395567393307
            ],
            [
              -83.24986678907352,
              13.492128176464178
            ],
            [
              -83.39644912564066,
              14.016107024140126
            ],
            [
              -83.0248669484653,
              14.365653759228536
            ],
            [
              -82.91236702816137,
              15.018578573757566
            ],
            [
              -83.24986678907334,
              15.452760959322148
            ],
            [
              -83.47486662968137,
              15.452760959322148
            ],
            [
              -83.77681884657427,
              15.2613102139087
            ]
          ],
          [
            [
              -72.11940467399728,
              21.851088325532913
            ],
            [
              -71.99987475867339,
              21.635297384859456
            ],
            [
              -70.76237563532928,
              20.375041253465525
            ],
            [
              -70.69118568576094,
              19.79943635579707
            ],
            [
              -70.19987603380928,
              19.952622905164304
            ],
            [
              -69.2998766713773,
              19.74098736552503
            ],
            [
              -68.62487714955328,
              19.104405475930548
            ],
            [
              -68.51237722924927,
              18.89166158430325
            ],
            [
              -68.43815728182749,
              18.621371316104643
            ],
            [
              -68.1748774683373,
              18.89166158430325
            ],
            [
              -67.49987794651328,
              19.210675111642757
            ],
            [
              -67.0498782652973,
              19.104405475930548
            ],
            [
              -66.48737866377729,
              18.838433217733098
            ],
            [
              -66.01686899709078,
              18.441839618642774
            ]
          ],
          [
            [
              -83.77681884657427,
              15.2613102139087
            ],
            [
              -83.69986647028932,
              15.669513225155328
            ],
            [
              -84.14986615150539,
              16.10232559580288
            ],
            [
              -85.04986551393755,
              16.10232559580288
            ],
            [
              -85.95469724872686,
              15.915243688695739
            ],
            [
              -86.39986455758559,
              16.10232559580288
            ],
            [
              -86.84986423880157,
              16.10232559580288
            ],
            [
              -87.94615578765044,
              15.844981598742493
            ],
            [
              -88.19986328244953,
              15.886035719079104
            ],
            [
              -88.59713531004533,
              15.72723663872111
            ],
            [
              -88.42486312305738,
              16.10232559580288
            ],
            [
              -88.08736336214541,
              16.53419619825962
            ],
            [
              -87.97486344184139,
              16.965102599435824
            ],
            [
              -88.18218561448214,
              17.496385204838987
            ],
            [
              -87.74986360123354,
              17.823934412537824
            ],
            [
              -87.52486376062541,
              18.251816319028308
            ],
            [
              -87.29986392001756,
              19.529070924351004
            ],
            [
              -87.29986392001756,
              19.952622905164304
            ],
            [
              -87.46353614173478,
              20.212733353176475
            ],
            [
              -87.18736399971336,
              20.058334551396143
            ],
            [
              -86.84986423880157,
              20.16397503197578
            ],
            [
              -86.62486439819335,
              20.375041253465525
            ],
            [
              -86.62486439819335,
              20.796306105108954
            ],
            [
              -86.76758665233308,
              21.095728792367282
            ],
            [
              -86.51236447788934,
              21.21639789994191
            ],
            [
              -86.17486471697737,
              21.425997872385494
            ],
            [
              -85.8373649566614,
              21.635297384859456
            ],
            [
              -84.82486567332933,
              23.298598065875808
            ],
            [
              -83.24986678907334,
              24.32780311165172
            ],
            [
              -80.99986838299351,
              24.32780311165172
            ],
            [
              -80.54986870177734,
              24.73717827217618
            ],
            [
              -79.98736910025744,
              25.348717422116806
            ],
            [
              -80.16222149850142,
              25.93320697846923
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "asia-america-gateway-aag-cable-system",
        "name": "Asia-America Gateway (AAG) Cable System",
        "color": "#69479c",
        "feature_id": "asia-america-gateway-aag-cable-system-0",
        "coordinates": [
          131.95058542442726,
          16.401665504833574
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -179.99979825051417,
              19.104405475930548
            ],
            [
              -172.79980335105807,
              20.796306105108954
            ],
            [
              -163.79980972673806,
              20.796306105108954
            ],
            [
              -158.84981323336194,
              21.111485983488905
            ],
            [
              -158.39981355214616,
              21.478351011076086
            ],
            [
              -158.24204999203226,
              21.54876173571651
            ],
            [
              -158.3435619450625,
              21.73983373091116
            ],
            [
              -158.28731363184195,
              22.05298561667763
            ],
            [
              -157.94981387093,
              22.469443964829594
            ],
            [
              -152.99981737755388,
              24.941363171753835
            ],
            [
              -147.59982120296192,
              27.364667993860166
            ],
            [
              -138.5998275786418,
              28.951554732193216
            ],
            [
              -127.79983522945773,
              32.43331330641712
            ],
            [
              -122.39983905486591,
              34.867831005273246
            ],
            [
              -120.84720164908995,
              35.367078251717174
            ]
          ],
          [
            [
              100.93057273577531,
              13.174371211662333
            ],
            [
              100.68750290737214,
              12.834868817846598
            ],
            [
              100.46250306616818,
              12.17588718550806
            ],
            [
              100.35000314646018,
              11.294709319565555
            ],
            [
              101.02500266828409,
              9.524411345019587
            ],
            [
              103.27500107495999,
              7.967776882259614
            ],
            [
              105.29999964043216,
              6.628746603597904
            ],
            [
              107.99999772772824,
              5.957818681088611
            ]
          ],
          [
            [
              179.99992672230294,
              19.104405475930548
            ],
            [
              160.19996074878455,
              16.53419619825962
            ],
            [
              151.1999671244645,
              15.23578178303569
            ],
            [
              147.14996999352056,
              14.365653759228536
            ],
            [
              146.24997063108842,
              14.14758350694865
            ],
            [
              145.34997126865645,
              13.546819477168867
            ],
            [
              144.89997158744055,
              13.327979290563633
            ],
            [
              144.80954165150203,
              13.549094363149067
            ],
            [
              143.9999722250084,
              13.492128176464178
            ],
            [
              143.09997286257644,
              13.710817738179543
            ],
            [
              138.59997605041642,
              14.801154224791475
            ],
            [
              131.39998115096031,
              16.53419619825962
            ],
            [
              125.99998497636832,
              18.251816319028308
            ],
            [
              122.39998752664027,
              18.89166158430325
            ],
            [
              121.04998848299223,
              19.104405475930548
            ],
            [
              120.37498896116833,
              18.67864702215462
            ],
            [
              120.14998912056026,
              16.965102599435824
            ],
            [
              120.38973895071936,
              16.58259233069336
            ],
            [
              119.92498927995226,
              16.965102599435824
            ],
            [
              116.99999135204828,
              18.251816319028308
            ],
            [
              115.31249254748829,
              19.529070924351004
            ],
            [
              114.52499310536027,
              20.375041253465525
            ],
            [
              113.79374362338419,
              21.635297384859456
            ],
            [
              113.94911351331862,
              22.27149389584997
            ],
            [
              113.73749366323219,
              21.635297384859456
            ],
            [
              113.51249382262418,
              20.796306105108954
            ],
            [
              113.39999390232023,
              18.251816319028308
            ],
            [
              111.14999549624022,
              12.615395567393307
            ],
            [
              110.69999581502415,
              9.96791518697421
            ],
            [
              109.34999677137611,
              7.298762754459602
            ],
            [
              107.99999772772824,
              5.957818681088611
            ],
            [
              106.64999868408003,
              5.061986954416028
            ],
            [
              105.74999932164823,
              4.613591578862867
            ],
            [
              103.85068066714334,
              2.295702456949584
            ],
            [
              104.40000027800002,
              2.255504211923693
            ],
            [
              104.77306230253552,
              1.961481175550773
            ],
            [
              104.68115007883117,
              1.468426767332062
            ],
            [
              104.28790035741284,
              1.215621515287864
            ],
            [
              104.1543904519925,
              1.197800481146658
            ],
            [
              103.9870105705659,
              1.389451396800126
            ]
          ],
          [
            [
              109.34999677137611,
              7.298762754459602
            ],
            [
              112.94999422050827,
              5.659359572411404
            ],
            [
              114.2999932647522,
              5.061986954416028
            ],
            [
              114.88563284987973,
              4.926762452886777
            ]
          ],
          [
            [
              107.0791983800311,
              10.342138429682905
            ],
            [
              107.77499788712001,
              9.746236973759864
            ],
            [
              108.6749972495522,
              9.524411345019587
            ],
            [
              110.24999613380825,
              9.746236973759864
            ],
            [
              110.69999581502415,
              9.96791518697421
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "atlantic-crossing-1-ac-1",
        "name": "Atlantic Crossing-1 (AC-1)",
        "color": "#50429a",
        "feature_id": "atlantic-crossing-1-ac-1-0",
        "coordinates": [
          -31.533730162850958,
          50.79317098436681
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -72.9122741123211,
              40.773520734289946
            ],
            [
              -71.09987539624133,
              40.64389687373827
            ],
            [
              -68.39987730894534,
              41.40772623743587
            ],
            [
              -61.199882409489234,
              42.74371346443661
            ],
            [
              -50.39989006030518,
              45.172673246984374
            ],
            [
              -39.59989771112103,
              48.1067757091962
            ],
            [
              -23.39990918734489,
              53.502097882664344
            ],
            [
              -16.199914287888873,
              55.58970711313168
            ],
            [
              -8.999919388432772,
              59.22222391484422
            ],
            [
              -5.399921938704722,
              59.79305890746801
            ],
            [
              -1.799924488976671,
              59.56588346342974
            ],
            [
              1.800072960751208,
              58.641677771384906
            ],
            [
              5.400070410479259,
              56.46971137654694
            ],
            [
              7.650068816559269,
              55.07780072164758
            ],
            [
              8.10006849777534,
              54.94878902385568
            ],
            [
              8.38336829708311,
              54.8984194044711
            ]
          ],
          [
            [
              -5.698461727216397,
              50.07870033214297
            ],
            [
              -6.299921301136777,
              50.16726116292705
            ],
            [
              -8.099920026000802,
              50.16726116292705
            ],
            [
              -10.799918113296798,
              49.87814473780409
            ],
            [
              -16.199914287888873,
              48.70423463096077
            ],
            [
              -23.39990918734489,
              47.19740739556977
            ],
            [
              -39.59989771112103,
              43.07331078300331
            ],
            [
              -50.39989006030518,
              41.0693404382163
            ],
            [
              -61.199882409489234,
              40.04369219282995
            ],
            [
              -68.39987730894534,
              40.21572406083388
            ],
            [
              -71.09987539624133,
              40.04369219282995
            ],
            [
              -72.9122741123211,
              40.773520734289946
            ]
          ],
          [
            [
              8.38336829708311,
              54.8984194044711
            ],
            [
              8.10006849777534,
              54.81936191424907
            ],
            [
              7.200069135343199,
              54.559258765782204
            ],
            [
              5.400070410479259,
              53.768910566668154
            ],
            [
              4.500071048047289,
              53.23359531864921
            ],
            [
              4.275071207439254,
              52.827662548128615
            ],
            [
              4.387571127743172,
              52.623261418527164
            ],
            [
              4.656810937011386,
              52.48640042521362
            ],
            [
              4.275071207439254,
              52.486461320100375
            ],
            [
              3.600071685615319,
              52.35686935757306
            ],
            [
              3.150072004399249,
              52.14259270367222
            ],
            [
              2.475072482575314,
              51.586833980054195
            ],
            [
              1.46147320061857,
              50.981023970649176
            ],
            [
              0.900073598319238,
              50.526212361408795
            ],
            [
              0.000074235887269,
              50.16726116292705
            ],
            [
              -2.249924170192742,
              50.02292045625484
            ],
            [
              -3.599923213840782,
              49.87814473780409
            ],
            [
              -4.499922576272752,
              49.80559362880811
            ],
            [
              -5.399921938704722,
              49.87814473780409
            ],
            [
              -5.698461727216397,
              50.07870033214297
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "atlas-offshore",
        "name": "Atlas Offshore",
        "color": "#5ab946",
        "feature_id": "atlas-offshore-0",
        "coordinates": [
          1.2484415384739123,
          37.70380875960663
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -6.035761488270055,
              35.470615921385786
            ],
            [
              -5.849921619920792,
              35.78566189952613
            ],
            [
              -5.624921779312757,
              35.83127933955626
            ],
            [
              -4.499922576272752,
              36.14986678681771
            ],
            [
              -2.249924170192742,
              36.240655233214795
            ],
            [
              2.250072641967279,
              38.12273010839229
            ],
            [
              4.050071366831219,
              39.00237890905848
            ],
            [
              5.062570648971388,
              40.04369219282995
            ],
            [
              5.175070569871224,
              41.744358789482135
            ],
            [
              5.372530429989041,
              43.29362778902916
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "australia-japan-cable-ajc",
        "name": "Australia-Japan Cable (AJC)",
        "color": "#46bfb2",
        "feature_id": "australia-japan-cable-ajc-0",
        "coordinates": [
          157.2364841664153,
          0.5461351447820846
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              136.87399727311598,
              34.33682825203164
            ],
            [
              137.69997668798445,
              34.12610104005762
            ],
            [
              138.59997605041642,
              34.219177704953495
            ],
            [
              139.4999754122525,
              34.57501887961877
            ],
            [
              139.92185011339248,
              34.76007352296514
            ],
            [
              140.06247501377246,
              34.89859296336232
            ],
            [
              140.10823235209378,
              34.967799876340905
            ],
            [
              140.06247501377246,
              35.01384769751846
            ],
            [
              139.97546699999984,
              35.005433
            ]
          ],
          [
            [
              151.22848710426095,
              -33.88203865028561
            ],
            [
              151.24508709250128,
              -33.808913582018675
            ],
            [
              151.24508709250128,
              -33.73712305097797
            ],
            [
              152.09996648689648,
              -33.461541290548496
            ],
            [
              154.79996457419256,
              -31.851465665577166
            ]
          ],
          [
            [
              148.49996903716843,
              13.492128176464178
            ],
            [
              147.14996999352056,
              13.929303843271725
            ],
            [
              146.24997063108842,
              13.929303843271725
            ],
            [
              145.34997126865645,
              13.710817738179543
            ],
            [
              144.80954165150203,
              13.549094363149067
            ],
            [
              144.8044415972819,
              13.529423110453422
            ],
            [
              144.8003616580052,
              13.513685339593195
            ],
            [
              145.34997126865645,
              13.054150695298716
            ],
            [
              146.24997063108842,
              12.615395567393307
            ],
            [
              147.14996999352056,
              12.615395567393307
            ],
            [
              148.49996903716843,
              13.054150695298716
            ]
          ],
          [
            [
              136.87399727311598,
              34.33682825203164
            ],
            [
              137.69997668798445,
              33.283811019051
            ],
            [
              138.59997605041642,
              32.62301664000799
            ],
            [
              140.84997445649643,
              31.67051304708704
            ],
            [
              143.5499725437925,
              30.901396088515583
            ]
          ],
          [
            [
              139.97546699999984,
              35.005433
            ],
            [
              140.16091244403847,
              34.89859296336232
            ],
            [
              140.1749749340764,
              34.69072647741018
            ],
            [
              140.1749749346723,
              34.40502275071583
            ],
            [
              140.2874748549764,
              33.93964008831958
            ],
            [
              142.19997350014447,
              32.81231878328768
            ],
            [
              143.5499725437925,
              30.901396088515583
            ],
            [
              147.59996967473646,
              23.298598065875808
            ],
            [
              148.9499687183845,
              16.53419619825962
            ],
            [
              148.49996903716843,
              13.492128176464178
            ],
            [
              148.49996903716843,
              13.054150695298716
            ],
            [
              151.1999671244645,
              9.96791518697421
            ],
            [
              153.44996553054452,
              8.190543417795567
            ],
            [
              156.1499636178406,
              4.164912849976844
            ],
            [
              157.49996266148864,
              -0.331409329660175
            ],
            [
              158.3999620239206,
              -3.029995968008762
            ],
            [
              159.97496090817666,
              -4.825692499217524
            ],
            [
              161.99995947364866,
              -6.616650693475464
            ],
            [
              162.89995883608063,
              -8.401139048122928
            ],
            [
              163.3499585172967,
              -10.177457430361159
            ],
            [
              162.89995883608063,
              -13.698987269610853
            ],
            [
              160.19996074878455,
              -18.880139975101287
            ],
            [
              158.3999620239206,
              -25.5408960762594
            ],
            [
              156.59996329905667,
              -28.74381028114999
            ],
            [
              154.79996457419256,
              -31.851465665577166
            ],
            [
              152.09996648689648,
              -33.55534420877598
            ],
            [
              151.22848710426095,
              -33.88203865028561
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "apcn-2",
        "name": "APCN-2",
        "color": "#29b14a",
        "feature_id": "apcn-2-0",
        "coordinates": [
          124.32171228437667,
          23.367721518158334
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              140.75095452664314,
              36.79060054148874
            ],
            [
              141.18747421740838,
              35.77425344590853
            ],
            [
              141.07497429710443,
              35.408319788563404
            ],
            [
              140.96247437620465,
              35.1634363374163
            ],
            [
              140.3437248151284,
              34.97160644824112
            ],
            [
              140.06247501377246,
              34.96776525378368
            ],
            [
              139.9548550906076,
              34.9650466035836
            ],
            [
              140.04841252373453,
              34.840904848139274
            ],
            [
              140.23122489422857,
              34.6791629819069
            ],
            [
              140.2874748549764,
              34.39341949240347
            ],
            [
              139.8374751737605,
              33.927972678693465
            ],
            [
              139.7249752534564,
              32.421443555350606
            ],
            [
              139.0499757316325,
              30.889328974889338
            ],
            [
              137.69997668798445,
              29.919063056618427
            ],
            [
              134.99997860068837,
              29.135966407362588
            ],
            [
              128.6999830636644,
              25.944535413791584
            ],
            [
              127.34998402001636,
              25.336008217188635
            ],
            [
              125.99998497636832,
              25.132479722461284
            ],
            [
              125.09998561393635,
              25.132479722461284
            ],
            [
              123.74998657028831,
              25.437644438648675
            ],
            [
              122.84998720785634,
              25.640659590796815
            ],
            [
              121.94998784542437,
              25.539194978687192
            ],
            [
              121.4625881907028,
              25.168986122701654
            ],
            [
              121.94998784542437,
              25.23428656210018
            ],
            [
              122.28748760633616,
              24.928611492263542
            ],
            [
              122.62498736724834,
              24.007054825363127
            ],
            [
              122.17498768603221,
              22.45644844059953
            ],
            [
              121.02775554672925,
              21.75825909959923
            ],
            [
              120.12788799225589,
              21.75825909959923
            ],
            [
              118.79999007691222,
              22.19628282803035
            ],
            [
              118.34999039569615,
              22.404454165066625
            ],
            [
              117.28124115280829,
              22.871697869961935
            ],
            [
              116.67753158048177,
              23.34209588629264
            ],
            [
              116.77499151144028,
              22.45644844059953
            ],
            [
              116.54999167083221,
              20.783159233732903
            ],
            [
              115.64999230840024,
              18.23846081095263
            ],
            [
              114.74999294596827,
              14.787557926773019
            ],
            [
              114.2999932647522,
              13.040451242220257
            ],
            [
              113.39999390232023,
              9.954064678408741
            ],
            [
              111.59999517745612,
              7.730954611329906
            ],
            [
              109.12499693076828,
              5.943831970446496
            ],
            [
              108.44999740894414,
              5.496074035021934
            ],
            [
              107.99999772772824,
              5.160032981867232
            ],
            [
              107.0999983652961,
              4.599574515521397
            ],
            [
              105.74999932164823,
              3.814203076255154
            ],
            [
              104.96249987952004,
              2.803404866588352
            ],
            [
              104.48462521805104,
              1.45436885137326
            ],
            [
              104.28790035741284,
              1.285767245880294
            ],
            [
              104.15918044859936,
              1.197018152576135
            ],
            [
              103.89502063573255,
              1.295434785911254
            ]
          ],
          [
            [
              103.89502063573255,
              1.309493642625654
            ],
            [
              104.19697042182847,
              1.315741998126299
            ],
            [
              104.28790035741284,
              1.369994553366951
            ],
            [
              104.34425031749404,
              1.468426767332062
            ],
            [
              104.62500011860826,
              2.367912558705314
            ],
            [
              103.95000059678412,
              3.491423322320486
            ],
            [
              103.32266104119762,
              3.815173420941221
            ],
            [
              103.95000059678412,
              4.052702097268195
            ],
            [
              107.99999772772824,
              7.075530930004675
            ],
            [
              108.44999740894414,
              7.744889052551343
            ],
            [
              109.79999645259218,
              9.96791518697421
            ],
            [
              110.69999581502415,
              12.615395567393307
            ],
            [
              113.17499406171223,
              18.251816319028308
            ],
            [
              113.39999390232023,
              20.796306105108954
            ],
            [
              113.68124370308007,
              21.635297384859456
            ],
            [
              113.94911351331862,
              22.27149389584997
            ],
            [
              114.24379330456495,
              21.84429407917378
            ],
            [
              115.19999262718417,
              21.32123529551194
            ],
            [
              116.54999167083221,
              21.111485983488905
            ],
            [
              118.79999007691222,
              21.425997872385494
            ],
            [
              121.02775554672925,
              21.24799675992793
            ],
            [
              122.84998720785634,
              21.425997872385494
            ],
            [
              123.74998657028831,
              22.05298561667763
            ],
            [
              124.64998593272028,
              24.12261698700334
            ],
            [
              124.81873581317635,
              25.55188275942578
            ],
            [
              124.98748569363224,
              26.159307970773796
            ],
            [
              124.64998593272028,
              30.514495959759188
            ],
            [
              122.84998720785634,
              31.67051304708704
            ],
            [
              121.94998784542437,
              31.622627415989484
            ],
            [
              121.39529823837174,
              31.619800328867854
            ],
            [
              121.94998784542437,
              31.814021800022772
            ],
            [
              122.84998720785634,
              32.052708023486105
            ],
            [
              124.19998625150438,
              32.052708023486105
            ],
            [
              128.58279314668286,
              31.157626347303676
            ],
            [
              129.1499827448803,
              30.901396088515583
            ],
            [
              130.49998178852834,
              29.73606949729205
            ],
            [
              132.74998019460836,
              29.540507745394393
            ],
            [
              134.99997860068837,
              29.540507745394393
            ],
            [
              137.69997668798445,
              30.320465424761352
            ],
            [
              139.0499757316325,
              31.094262827639596
            ],
            [
              140.39997477528036,
              32.43331330641712
            ],
            [
              142.42497334015658,
              35.05222991093683
            ],
            [
              142.4249733407523,
              35.78566189952613
            ],
            [
              141.9749736589405,
              36.51238821239372
            ],
            [
              140.75095452664314,
              36.80186137248688
            ]
          ],
          [
            [
              128.58279314668286,
              31.157626347303676
            ],
            [
              128.47498322305617,
              31.67051304708704
            ],
            [
              128.6999830636644,
              32.81231878328768
            ],
            [
              129.0374828245762,
              34.31215165223537
            ],
            [
              128.99949285148878,
              35.17037876180012
            ],
            [
              129.26248266518442,
              34.31215165223537
            ],
            [
              129.1499827448803,
              32.81231878328768
            ],
            [
              128.92498290427224,
              31.67051304708704
            ],
            [
              129.1499827448803,
              30.901396088515583
            ]
          ],
          [
            [
              114.74999294596827,
              14.801154224791475
            ],
            [
              116.99999135204828,
              13.929303843271725
            ],
            [
              118.79999007691222,
              13.710817738179543
            ],
            [
              120.14998912056026,
              13.601498202276503
            ],
            [
              121.06600847164387,
              13.762418337904336
            ],
            [
              120.14998912056026,
              13.382708036125592
            ],
            [
              118.79999007691222,
              13.273238157547667
            ],
            [
              116.99999135204828,
              13.054150695298716
            ],
            [
              115.64999230840024,
              12.944533868662859
            ],
            [
              114.74999294596827,
              12.944533868662859
            ],
            [
              114.2999932647522,
              13.054150695298716
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "australia-singapore-cable-asc",
        "name": "Australia-Singapore Cable (ASC)",
        "color": "#c82026",
        "feature_id": "australia-singapore-cable-asc-0",
        "coordinates": [
          107.56909925038454,
          -15.172733324076177
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              105.97499916225613,
              -10.39883957712749
            ],
            [
              105.86249924195218,
              -10.39883957712749
            ],
            [
              105.697069844276,
              -10.437358645528604
            ]
          ],
          [
            [
              105.74999932164823,
              -5.945707155070551
            ],
            [
              105.88390920948316,
              -6.073698909871837
            ]
          ],
          [
            [
              103.94648059927786,
              1.327258925921086
            ],
            [
              103.78125071632823,
              1.01853421661562
            ],
            [
              103.78125071632823,
              0.793562652607278
            ],
            [
              104.17500043739219,
              0.513331356305002
            ],
            [
              104.84999995921609,
              0.40083503392583
            ],
            [
              105.29999964043216,
              0.118588418888407
            ],
            [
              105.97499916225613,
              -0.781386636225506
            ],
            [
              106.87499852468827,
              -2.130918480960247
            ],
            [
              106.98749844499216,
              -3.029995968008762
            ],
            [
              106.87499852468827,
              -4.601453764837203
            ],
            [
              106.53749876377609,
              -5.161910662112973
            ],
            [
              106.31249892316825,
              -5.497950688314972
            ],
            [
              105.74999932164823,
              -5.945707155070551
            ],
            [
              105.29999964043216,
              -6.169450529574419
            ],
            [
              105.07499979982416,
              -6.616650693475464
            ],
            [
              105.29999964043216,
              -7.509810688339655
            ],
            [
              105.97499916225613,
              -10.39883957712749
            ],
            [
              106.19999900286413,
              -11.943944931746927
            ],
            [
              109.7999964519963,
              -20.433922197637315
            ],
            [
              112.04999485867219,
              -27.153831285391675
            ],
            [
              113.39999390232023,
              -30.309953344646914
            ],
            [
              113.84999358353613,
              -30.890946871471897
            ],
            [
              115.85731216153286,
              -31.95344133032441
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "azores-fiber-optic-system-afos",
        "name": "Azores Fiber Optic System (AFOS)",
        "color": "#514da0",
        "feature_id": "azores-fiber-optic-system-afos-0",
        "coordinates": [
          -25.654950906469026,
          37.06633635848618
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -27.963385954535113,
              39.01140845880104
            ],
            [
              -28.124905840112945,
              38.82731109526628
            ],
            [
              -28.34990568072098,
              38.82731109526628
            ],
            [
              -28.34990568072098,
              38.739615313825766
            ],
            [
              -28.213135777610034,
              38.68429209182298
            ],
            [
              -28.462405601024983,
              38.651811712711236
            ],
            [
              -28.647515469891403,
              38.52540431175973
            ],
            [
              -28.57490552132893,
              38.47588134813884
            ],
            [
              -28.461235601853815,
              38.43266966366386
            ],
            [
              -28.34990568072098,
              38.29952060596935
            ],
            [
              -25.296927843479097,
              36.90250859939607
            ],
            [
              -25.14121795378537,
              36.95715362213733
            ],
            [
              -25.07226800263024,
              36.87595534218087
            ],
            [
              -24.91860811148439,
              36.95684197844173
            ],
            [
              -24.97490807160088,
              37.232354321556215
            ],
            [
              -25.537407673120953,
              37.58978657360316
            ],
            [
              -25.668707580106883,
              37.73960462631459
            ],
            [
              -25.874907434032934,
              37.58978657360316
            ],
            [
              -26.77490679646499,
              37.94551049545976
            ],
            [
              -26.99990663707294,
              38.47588134813884
            ],
            [
              -27.215776484148705,
              38.658902437108935
            ],
            [
              -27.449906318288924,
              38.651811712711236
            ],
            [
              -27.67490615889696,
              38.651811712711236
            ],
            [
              -27.963385954535113,
              39.01140845880104
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "bahamas-2",
        "name": "Bahamas 2",
        "color": "#85459a",
        "feature_id": "bahamas-2-0",
        "coordinates": [
          -78.76969475201558,
          26.495264303009378
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -80.39425132826774,
              27.638731771078767
            ],
            [
              -79.64986933934539,
              27.364667993860166
            ],
            [
              -78.80201993996981,
              26.539621981474113
            ],
            [
              -78.52487013630538,
              26.159307970773796
            ],
            [
              -77.73737069417734,
              25.348717422116806
            ],
            [
              -77.34020097553618,
              25.067217613601912
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "bahamas-domestic-submarine-network-bdsni",
        "name": "Bahamas Domestic Submarine Network (BDSNi)",
        "color": "#2aae5d",
        "feature_id": "bahamas-domestic-submarine-network-bdsni-0",
        "coordinates": [
          -73.74030475770796,
          19.30394855819307
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -75.78014208069646,
              23.516551982193818
            ],
            [
              -75.59987220840135,
              23.711258142484382
            ],
            [
              -75.14987252718537,
              23.814220515025227
            ],
            [
              -74.8404927463529,
              23.65223548382565
            ]
          ],
          [
            [
              -73.79987348353733,
              22.780969584994693
            ],
            [
              -74.02487332652933,
              22.884654113882362
            ],
            [
              -74.36237308505733,
              23.298598065875808
            ],
            [
              -74.36237308505733,
              23.711258142484382
            ],
            [
              -74.5257029693529,
              24.05263241523417
            ]
          ],
          [
            [
              -77.39953093350621,
              26.02529561689903
            ],
            [
              -77.96237053478538,
              26.159307970773796
            ],
            [
              -78.73673998621481,
              26.51699468577729
            ]
          ],
          [
            [
              -77.34020097553618,
              25.067217613601912
            ],
            [
              -77.39987093326538,
              25.348717422116806
            ],
            [
              -78.18737037539341,
              26.159307970773796
            ],
            [
              -78.73673998621481,
              26.51699468577729
            ]
          ],
          [
            [
              -77.34020097553618,
              25.067217613601912
            ],
            [
              -77.39987093326538,
              24.941363171753835
            ],
            [
              -77.84460061821471,
              24.68971388062542
            ]
          ],
          [
            [
              -76.24064175447417,
              25.196209451872335
            ],
            [
              -76.24064175447417,
              25.294427933466267
            ],
            [
              -76.8311713361375,
              25.71204125730199
            ],
            [
              -77.28737101296136,
              25.754704263415306
            ],
            [
              -77.39953093350621,
              26.02529561689903
            ]
          ],
          [
            [
              -76.24064175447417,
              25.196209451872335
            ],
            [
              -76.94987125204939,
              25.043329056612095
            ],
            [
              -77.34020097553618,
              25.067217613601912
            ]
          ],
          [
            [
              -75.78014208069646,
              23.516551982193818
            ],
            [
              -75.71237212870537,
              23.711258142484382
            ],
            [
              -76.04987189021341,
              24.259444485784854
            ],
            [
              -76.5744515185962,
              24.424991122673873
            ],
            [
              -76.04987189021341,
              24.532657566160623
            ],
            [
              -75.9373719693134,
              24.941363171753835
            ],
            [
              -76.24064175447417,
              25.196209451872335
            ]
          ],
          [
            [
              -75.52590226080238,
              24.403328403350315
            ],
            [
              -75.82487204900939,
              24.532657566160623
            ],
            [
              -75.82487204900939,
              24.941363171753835
            ],
            [
              -76.24064175447417,
              25.196209451872335
            ]
          ],
          [
            [
              -75.73337211382882,
              22.183257204025097
            ],
            [
              -75.59987220840135,
              22.469443964829594
            ],
            [
              -75.59987220840135,
              23.298598065875808
            ],
            [
              -75.14987252718537,
              23.711258142484382
            ],
            [
              -74.8404927463529,
              23.65223548382565
            ]
          ],
          [
            [
              -73.68298356634328,
              20.950514348762226
            ],
            [
              -73.79987348353733,
              21.21639789994191
            ],
            [
              -75.14987252718537,
              21.84429407917378
            ],
            [
              -75.73337211382882,
              22.183257204025097
            ]
          ],
          [
            [
              -74.96618265731293,
              23.098344040477286
            ],
            [
              -74.8666227278422,
              23.298598065875808
            ],
            [
              -74.8404927463529,
              23.65223548382565
            ]
          ],
          [
            [
              -74.5257029693529,
              24.05263241523417
            ],
            [
              -74.8123727662734,
              23.298598065875808
            ],
            [
              -74.96618265731293,
              23.098344040477286
            ]
          ],
          [
            [
              -74.5257029693529,
              24.05263241523417
            ],
            [
              -74.92487268657733,
              24.32780311165172
            ],
            [
              -75.52590226080238,
              24.403328403350315
            ]
          ],
          [
            [
              -73.06413400474209,
              22.401747618851005
            ],
            [
              -73.34987380232134,
              22.780969584994693
            ],
            [
              -73.79987348353733,
              22.780969584994693
            ]
          ],
          [
            [
              -73.68298356634328,
              20.950514348762226
            ],
            [
              -73.57487364292938,
              21.425997872385494
            ],
            [
              -73.34987380232134,
              22.05298561667763
            ],
            [
              -73.06413400474209,
              22.401747618851005
            ]
          ],
          [
            [
              -73.68298356634328,
              20.950514348762226
            ],
            [
              -73.84592345091508,
              20.144772979217038
            ],
            [
              -73.91237340384134,
              19.952622905164304
            ],
            [
              -73.6873735632334,
              19.104405475930548
            ],
            [
              -72.83434416752735,
              18.61193247312403
            ],
            [
              -72.51027439710151,
              18.61193247312403
            ],
            [
              -72.34313451550491,
              18.54287844069512
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "bahamas-internet-cable-system-bics",
        "name": "Bahamas Internet Cable System (BICS)",
        "color": "#209fb8",
        "feature_id": "bahamas-internet-cable-system-bics-0",
        "coordinates": [
          -77.76608890937214,
          26.90608082756492
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -78.73673998621481,
              26.51699468577729
            ],
            [
              -79.1998696581294,
              26.361086325391653
            ],
            [
              -79.64986933934539,
              26.361086325391653
            ],
            [
              -80.08893155227206,
              26.35058457732009
            ]
          ],
          [
            [
              -76.78766136696045,
              25.407833241806458
            ],
            [
              -77.17487109265734,
              25.55188275942578
            ],
            [
              -77.39987093326538,
              25.754704263415306
            ],
            [
              -77.39953093350621,
              26.02529561689903
            ],
            [
              -77.39987093326538,
              26.159307970773796
            ],
            [
              -77.17487109265734,
              26.26024097157773
            ],
            [
              -77.17487109265734,
              26.562513149236622
            ],
            [
              -77.39987093326538,
              26.863990173960673
            ],
            [
              -77.82194063426726,
              26.91250005261429
            ],
            [
              -77.96237053538137,
              26.84715089063849
            ],
            [
              -78.07487045568539,
              26.79706431733885
            ],
            [
              -78.15172040124413,
              26.703096291958445
            ],
            [
              -78.29987029629342,
              26.671579137162894
            ],
            [
              -78.52487013690137,
              26.59627352404476
            ],
            [
              -78.73673998621481,
              26.51699468577729
            ],
            [
              -79.1998696581294,
              26.461843796189072
            ],
            [
              -79.64986933934539,
              26.461843796189072
            ],
            [
              -80.06769156785538,
              26.378324328211022
            ]
          ],
          [
            [
              -78.73673998621481,
              26.51699468577729
            ],
            [
              -78.29987029569733,
              26.159307970773796
            ],
            [
              -77.5123708535694,
              25.348717422116806
            ],
            [
              -77.45299089563474,
              25.06844950998564
            ],
            [
              -77.39987093326538,
              25.348717422116806
            ],
            [
              -77.17487109265734,
              25.450342946923996
            ],
            [
              -76.78766136696045,
              25.407833241806458
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "balalink",
        "name": "Balalink",
        "color": "#3a4ea1",
        "feature_id": "balalink-0",
        "coordinates": [
          1.1368861410635418,
          39.351217571171134
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -0.376975497007066,
              39.459646713436825
            ],
            [
              0.450073917103168,
              39.351217571171134
            ],
            [
              2.250072641967279,
              39.351217571171134
            ],
            [
              2.605252390354707,
              39.55232618527285
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "baltica",
        "name": "Baltica",
        "color": "#5f459b",
        "feature_id": "baltica-0",
        "coordinates": [
          13.460776938063049,
          54.87279132253062
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              11.940005777527603,
              54.5772362672833
            ],
            [
              12.60006530993536,
              54.624440877767114
            ],
            [
              13.50006467236733,
              54.88412743786745
            ],
            [
              14.4000640347993,
              54.94878902385568
            ],
            [
              14.85006371601537,
              54.94878902385568
            ],
            [
              14.992153615357665,
              55.031146415148356
            ]
          ],
          [
            [
              13.828284439853434,
              55.43133165094372
            ],
            [
              13.9500643535834,
              55.33458061322915
            ],
            [
              14.4000640347993,
              55.0133467567922
            ],
            [
              14.85006371601537,
              55.0133467567922
            ],
            [
              14.992153615357665,
              55.031146415148356
            ],
            [
              15.075063556623405,
              54.88412743786745
            ],
            [
              15.412563317535358,
              54.559258765782204
            ],
            [
              15.575063202418846,
              54.17263354490274
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "bass-strait-1",
        "name": "Bass Strait-1",
        "color": "#66439a",
        "feature_id": "bass-strait-1-0",
        "coordinates": [
          145.906182578485,
          -39.897015711279636
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              146.11970072337314,
              -38.819880879605506
            ],
            [
              146.02497079048058,
              -39.16757423638754
            ],
            [
              145.79997094987252,
              -40.54922829806904
            ],
            [
              145.62380107467308,
              -40.950501388145966
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "bass-strait-2",
        "name": "Bass Strait-2",
        "color": "#5da344",
        "feature_id": "bass-strait-2-0",
        "coordinates": [
          145.48965518221675,
          -39.69147281699597
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              145.72966099968082,
              -38.63330288478144
            ],
            [
              145.57497110926445,
              -39.16757423638754
            ],
            [
              145.34997126865645,
              -40.54922829806904
            ],
            [
              145.29411130822828,
              -40.76023099257819
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "basslink",
        "name": "Basslink",
        "color": "#9dc93b",
        "feature_id": "basslink-0",
        "coordinates": [
          146.97751931966715,
          -39.740482351060706
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              147.0712600492794,
              -38.443402817441694
            ],
            [
              147.03747007321644,
              -38.81782397325085
            ],
            [
              146.9249701529125,
              -40.54922829806904
            ],
            [
              146.85017020590166,
              -41.033054194589255
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "batam-singapore-cable-system-bscs",
        "name": "Batam Singapore Cable System (BSCS)",
        "color": "#ac3781",
        "feature_id": "batam-singapore-cable-system-bscs-0",
        "coordinates": [
          103.96381189752483,
          1.261447307079493
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              103.9870105705659,
              1.389451396800126
            ],
            [
              103.9500255967665,
              1.185378176915862
            ],
            [
              103.96260058785819,
              1.134723598626692
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "sweden-latvia",
        "name": "Sweden-Latvia",
        "color": "#42b985",
        "feature_id": "sweden-latvia-0",
        "coordinates": [
          19.342613473857597,
          57.75509344512014
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              21.570078955493614,
              57.389720408424836
            ],
            [
              21.15005925303933,
              57.39045872350093
            ],
            [
              19.35006052817539,
              57.752422007042185
            ],
            [
              19.04186074650724,
              57.86298070545203
            ],
            [
              18.90006084695932,
              58.05131589106017
            ],
            [
              18.56256108604734,
              58.641677771384906
            ],
            [
              18.56256108604734,
              59.1068949571908
            ],
            [
              18.337561245439304,
              59.279742670960275
            ],
            [
              18.062761440110165,
              59.332309018187175
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "bcs-east-west-interlink",
        "name": "BCS East-West Interlink",
        "color": "#348bcb",
        "feature_id": "bcs-east-west-interlink-0",
        "coordinates": [
          19.948215743265337,
          56.717219875603234
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              21.082679300772185,
              56.02850685047366
            ],
            [
              20.70005957182343,
              56.15772578558838
            ],
            [
              19.125060687567355,
              57.32978111682823
            ],
            [
              18.829450896980262,
              57.43973589319194
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "bcs-north---phase-1",
        "name": "BCS North - Phase 1",
        "color": "#38b77b",
        "feature_id": "bcs-north---phase-1-0",
        "coordinates": [
          21.807959696679802,
          59.994076620712605
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              24.93247657353959,
              60.171163188940454
            ],
            [
              24.4125569418554,
              59.90606992457441
            ],
            [
              23.85005734033541,
              59.79305890746801
            ],
            [
              22.966757966073033,
              59.82340864139897
            ],
            [
              22.61255821699146,
              59.90606992457441
            ],
            [
              22.275058456079336,
              60.01869762196867
            ],
            [
              22.298598439403577,
              60.305754431168936
            ],
            [
              22.05005861547147,
              60.07486799642308
            ],
            [
              21.375059093647366,
              59.849612385021544
            ],
            [
              20.25005989060736,
              59.736409384078485
            ],
            [
              19.91256012969538,
              59.962431634152296
            ],
            [
              19.93776011184346,
              60.11403575461435
            ],
            [
              19.687560289087344,
              59.962431634152296
            ],
            [
              19.125060687567355,
              59.622821769410336
            ],
            [
              18.688360996929788,
              59.29276676569168
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "bcs-north---phase-2",
        "name": "BCS North - Phase 2",
        "color": "#b1d235",
        "feature_id": "bcs-north---phase-2-0",
        "coordinates": [
          25.961669232482933,
          60.130942866098394
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              26.883645191313576,
              60.501141253200814
            ],
            [
              27.00005510884756,
              60.35428947498107
            ],
            [
              27.168804989303453,
              60.130942866098394
            ],
            [
              27.45005479006346,
              59.962431634152296
            ],
            [
              28.125054311887567,
              59.849612385021544
            ],
            [
              28.49995404630505,
              59.799986209492914
            ]
          ],
          [
            [
              26.883645191313576,
              60.501141253200814
            ],
            [
              26.775055268239356,
              60.35428947498107
            ],
            [
              26.10005574641542,
              60.130942866098394
            ],
            [
              25.20005638398345,
              60.130942866098394
            ],
            [
              24.93247657353959,
              60.171163188940454
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "berytar",
        "name": "BERYTAR",
        "color": "#c02028",
        "feature_id": "berytar-0",
        "coordinates": [
          35.54357374187808,
          34.27032090775191
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              35.897798805602406,
              34.89170328553857
            ],
            [
              35.77504889255957,
              34.68301765985788
            ],
            [
              35.85922883292588,
              34.43974246013238
            ],
            [
              35.55004905195153,
              34.31215165223537
            ],
            [
              35.485109097955785,
              33.89263712836993
            ],
            [
              35.3250492113435,
              33.565491482352144
            ],
            [
              35.38637916789688,
              33.45019231608532
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "bharat-lanka-cable-system",
        "name": "Bharat Lanka Cable System",
        "color": "#26ae4a",
        "feature_id": "bharat-lanka-cable-system-0",
        "coordinates": [
          78.76007118215173,
          7.506418248635953
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              78.14522887712647,
              8.802499062914308
            ],
            [
              78.30001876747187,
              8.190543417795567
            ],
            [
              79.20001812990384,
              6.852191098754417
            ],
            [
              79.86681765753698,
              6.833088156653076
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "bicentenario",
        "name": "Bicentenario",
        "color": "#52b847",
        "feature_id": "bicentenario-0",
        "coordinates": [
          -55.765452161876865,
          -35.747636785049934
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -54.95018683683219,
              -34.900416027051364
            ],
            [
              -55.57488639428921,
              -35.59302880961411
            ],
            [
              -56.024886075505194,
              -35.95811819864912
            ],
            [
              -56.69544560047457,
              -36.47095527632115
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "botnia",
        "name": "Botnia",
        "color": "#dba926",
        "feature_id": "botnia-0",
        "coordinates": [
          20.810619391542986,
          63.23067355549529
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              21.616368922701383,
              63.095388681301586
            ],
            [
              21.375059093647366,
              63.09669186983908
            ],
            [
              21.15005925303933,
              63.09669186983908
            ],
            [
              20.70005957182343,
              63.27431307074423
            ],
            [
              20.475059731215396,
              63.526173423791136
            ],
            [
              20.394303098455197,
              63.70251569426429
            ],
            [
              20.26304988140535,
              63.82595266205354
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "bt-mt-1",
        "name": "BT-MT-1",
        "color": "#d12c7d",
        "feature_id": "bt-mt-1-0",
        "coordinates": [
          -4.25470213580715,
          54.40539032874918
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -3.357053385891987,
              54.210914913404395
            ],
            [
              -3.599923213840782,
              54.165971787151676
            ],
            [
              -4.274922735664717,
              54.165971787151676
            ],
            [
              -4.423852630161463,
              54.16728900359925
            ],
            [
              -4.209052782327632,
              54.25163802974663
            ],
            [
              -4.265272742500855,
              54.440993353921044
            ],
            [
              -4.522672560156451,
              54.440993353921044
            ],
            [
              -4.635172480460398,
              54.37549446567266
            ],
            [
              -4.691422440612428,
              54.222445613689786
            ],
            [
              -5.235222055379666,
              54.30232935229833
            ],
            [
              -5.559391825734764,
              54.30232935229833
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "cadmos",
        "name": "CADMOS",
        "color": "#5d4a9e",
        "feature_id": "cadmos-0",
        "coordinates": [
          34.571766536489434,
          34.40408323892177
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              35.485109097955785,
              33.89263712836993
            ],
            [
              35.52192407127956,
              33.90074250722242
            ],
            [
              35.563039042153434,
              33.89189835097628
            ]
          ],
          [
            [
              33.61060042587516,
              34.82728147271527
            ],
            [
              33.97505016769546,
              34.68301765985788
            ],
            [
              35.100049370139544,
              34.157137999942535
            ],
            [
              35.485109097955785,
              33.89263712836993
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "cam-ring",
        "name": "CAM Ring",
        "color": "#a85137",
        "feature_id": "cam-ring-0",
        "coordinates": [
          -21.455193224583468,
          34.40442594845223
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -16.34766418322141,
              33.047315908721146
            ],
            [
              -16.42491412849691,
              32.81231878328768
            ],
            [
              -16.64991396910486,
              32.62301664000799
            ],
            [
              -16.908893785641084,
              32.64727814971002
            ],
            [
              -17.125113632468896,
              32.55889959458756
            ],
            [
              -18.449912693968884,
              32.81231878328768
            ],
            [
              -24.74990823099293,
              36.14986678681771
            ],
            [
              -25.649907593424984,
              36.87321951208918
            ],
            [
              -25.762407513728903,
              37.58978657360316
            ],
            [
              -25.668707580106883,
              37.73960462631459
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "canalink",
        "name": "Canalink",
        "color": "#5a4a9e",
        "feature_id": "canalink-0",
        "coordinates": [
          -11.928272506517743,
          32.88482972146167
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -16.412574137834554,
              28.310603161410427
            ],
            [
              -16.031164407432783,
              28.39196396138891
            ],
            [
              -15.749914606672803,
              28.55704546571141
            ],
            [
              -14.962415164544836,
              28.951554732193216
            ],
            [
              -13.724916040604825,
              29.73606949729205
            ],
            [
              -10.124918591472778,
              33.93964008831958
            ],
            [
              -8.324919866608838,
              35.419780517080454
            ],
            [
              -6.749920982352762,
              36.14986678681771
            ],
            [
              -6.087131451879088,
              36.27672346192381
            ]
          ],
          [
            [
              -7.199920663568747,
              36.51238821239372
            ],
            [
              -6.299921301136777,
              35.60293032290622
            ],
            [
              -6.035761488270055,
              35.470615921385786
            ]
          ],
          [
            [
              -15.524914766064853,
              28.359233526108653
            ],
            [
              -15.487704792424722,
              28.1343621820335
            ]
          ],
          [
            [
              -15.412414845760821,
              28.55704546571141
            ],
            [
              -15.749914606672803,
              28.50762719638061
            ],
            [
              -16.031164407432783,
              28.34246846660676
            ],
            [
              -16.41256413724568,
              28.31058555362941
            ]
          ],
          [
            [
              -16.51801406254387,
              28.059088061264703
            ],
            [
              -16.42491412849691,
              27.962503359972544
            ],
            [
              -16.537414048800827,
              27.863091576993526
            ],
            [
              -16.76241388940886,
              27.863091576993526
            ],
            [
              -16.98121373440904,
              28.159242763304235
            ],
            [
              -17.099913650320843,
              28.359233526108653
            ],
            [
              -17.364803462670494,
              28.663271035325494
            ],
            [
              -17.58982330326421,
              28.712616152639697
            ],
            [
              -17.765193179030604,
              28.663271035325494
            ]
          ],
          [
            [
              -15.412414845760821,
              28.55704546571141
            ],
            [
              -15.299914925456818,
              28.458185766004647
            ],
            [
              -15.243664965304845,
              28.161052262220892
            ],
            [
              -15.299914925456818,
              28.061823659710228
            ],
            [
              -15.398744855444761,
              27.95939426104593
            ]
          ],
          [
            [
              -6.363011256443286,
              36.62627284935817
            ],
            [
              -7.199920663568747,
              36.51238821239372
            ],
            [
              -8.999919388432772,
              35.419780517080454
            ],
            [
              -11.024917953904747,
              33.93964008831958
            ],
            [
              -14.624915403632798,
              29.73606949729205
            ],
            [
              -15.074915084848868,
              28.951554732193216
            ],
            [
              -15.412414845760821,
              28.55704546571141
            ],
            [
              -15.524914766064853,
              28.359233526108653
            ],
            [
              -16.031164407432783,
              28.29295870317665
            ],
            [
              -16.51801406254387,
              28.059088061264703
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "cantat-3",
        "name": "CANTAT-3",
        "color": "#a6469a",
        "feature_id": "cantat-3-0",
        "coordinates": [
          -4.94796197068905,
          62.56349540000835
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              7.199969135414307,
              55.58982013593325
            ],
            [
              7.650068816559269,
              55.58970711313168
            ],
            [
              8.329168335478954,
              55.75165023178093
            ]
          ],
          [
            [
              -7.424920504176782,
              62.71239189403053
            ],
            [
              -7.199920663568747,
              62.50536500000058
            ],
            [
              -7.147190700923147,
              62.290125832285554
            ]
          ],
          [
            [
              4.500071048047289,
              55.96930907315377
            ],
            [
              4.950070729263189,
              56.09502251152725
            ]
          ],
          [
            [
              4.228491240437108,
              56.078513174181296
            ],
            [
              4.500071048047289,
              55.96930907315377
            ],
            [
              4.56433100252508,
              55.80429094977458
            ],
            [
              4.612570968351207,
              55.747268737822395
            ],
            [
              4.748510872050332,
              55.715908549840066
            ]
          ],
          [
            [
              7.200069135343199,
              55.58970711313168
            ],
            [
              7.650068816559269,
              55.20639768894037
            ],
            [
              8.10006849777534,
              55.0133467567922
            ],
            [
              8.38336829708311,
              54.8984194044711
            ]
          ],
          [
            [
              7.200069135343199,
              55.58970711313168
            ],
            [
              6.300069772911229,
              55.84318584148099
            ],
            [
              4.950070729263189,
              56.09502251152725
            ],
            [
              3.600071685615319,
              58.05131589106017
            ],
            [
              2.700072323183178,
              60.35428947498107
            ],
            [
              1.800072960751208,
              61.23255301306608
            ],
            [
              0.450073917103168,
              61.9814494856501
            ],
            [
              -2.249924170192742,
              62.4013092496138
            ],
            [
              -7.424920504176782,
              62.71239189403053
            ],
            [
              -10.799918113296798,
              62.609058908203394
            ],
            [
              -16.199914287888873,
              63.031574219730345
            ],
            [
              -18.449912693968884,
              63.12213306123374
            ],
            [
              -19.799911737616924,
              63.22367528305821
            ],
            [
              -20.26729140652074,
              63.437770648439766
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "caribbean-bermuda-u-s-cbus",
        "name": "Caribbean-Bermuda U.S. (CBUS)",
        "color": "#b2355e",
        "feature_id": "caribbean-bermuda-u-s-cbus-0",
        "coordinates": [
          -64.34988017800121,
          25.323244998704865
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -64.80163985797051,
              32.2586528368615
            ],
            [
              -64.57488001860925,
              31.28673881439167
            ],
            [
              -64.34988017800121,
              28.161052262220892
            ],
            [
              -64.34988017800121,
              24.941363171753835
            ],
            [
              -64.34988017800121,
              20.796306105108954
            ],
            [
              -64.34988017800121,
              18.89166158430325
            ],
            [
              -64.597150002833,
              18.41441211576617
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "caucasus-cable-system",
        "name": "Caucasus Cable System",
        "color": "#d02c91",
        "feature_id": "caucasus-cable-system-0",
        "coordinates": [
          34.88847264393638,
          42.49061443096954
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              41.66752471827439,
              42.14675635811534
            ],
            [
              41.40004490775959,
              42.079235618164205
            ],
            [
              35.55004905195153,
              42.41235450073577
            ],
            [
              28.575053993103467,
              43.23744835244082
            ],
            [
              28.1674342818651,
              43.41462116994657
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "cayman-jamaica-fiber-system-cjfs",
        "name": "Cayman-Jamaica Fiber System (CJFS)",
        "color": "#85489c",
        "feature_id": "cayman-jamaica-fiber-system-cjfs-0",
        "coordinates": [
          -79.06738928977623,
          19.278274980698257
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -76.66662145270622,
              17.94977294265982
            ],
            [
              -76.38737165112538,
              17.966677204124778
            ],
            [
              -76.16237181051734,
              18.180575095602677
            ],
            [
              -76.20409564349079,
              18.46288613823782
            ],
            [
              -76.49987157202538,
              18.57203905256688
            ]
          ],
          [
            [
              -76.44608160953467,
              18.17632819876971
            ],
            [
              -76.49987157202538,
              18.57203905256688
            ]
          ],
          [
            [
              -76.49987157202538,
              18.57203905256688
            ],
            [
              -77.0623711729494,
              18.607582468591005
            ]
          ],
          [
            [
              -77.1032411434007,
              18.398661383088154
            ],
            [
              -77.0623711729494,
              18.607582468591005
            ]
          ],
          [
            [
              -77.0623711729494,
              18.607582468591005
            ],
            [
              -77.73737069477342,
              18.607582468591005
            ],
            [
              -77.92138056441907,
              18.469357593227244
            ],
            [
              -78.1873703759894,
              18.660883672360313
            ],
            [
              -79.42486949873734,
              19.529070924351004
            ],
            [
              -79.87764917798397,
              19.69042598563081
            ],
            [
              -80.09986902056146,
              19.529070924351004
            ],
            [
              -80.54986870177753,
              19.316876111628602
            ],
            [
              -81.16676076149959,
              19.282955912662572
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "ceiba-2",
        "name": "Ceiba-2",
        "color": "#4fbe9c",
        "feature_id": "ceiba-2-0",
        "coordinates": [
          9.2766769981543,
          2.945927882878692
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              9.250947682481751,
              3.002760948286323
            ],
            [
              9.91022721544212,
              2.933124533518679
            ]
          ],
          [
            [
              9.768227316036075,
              1.860150409321903
            ],
            [
              9.250947682481751,
              3.002760948286323
            ],
            [
              9.052797822852995,
              3.809525949617012
            ],
            [
              8.832297979057273,
              3.809525949617012
            ],
            [
              8.782298014477703,
              3.750863902152105
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "ceiba-1",
        "name": "Ceiba-1",
        "color": "#3366b1",
        "feature_id": "ceiba-1-0",
        "coordinates": [
          9.389137995287143,
          3.053284809650876
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              9.768227316036075,
              1.860150409321903
            ],
            [
              9.425067559133595,
              2.967159342638126
            ],
            [
              9.052797822852995,
              3.859513756649619
            ],
            [
              8.782298014477703,
              3.859513756649619
            ],
            [
              8.782298014477703,
              3.750863902152105
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "celtixconnect-1-cc-1",
        "name": "CeltixConnect-1 (CC-1)",
        "color": "#aa4f9e",
        "feature_id": "celtixconnect-1-cc-1-0",
        "coordinates": [
          -5.440452195766142,
          53.30189162384994
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -6.248311337697743,
              53.348124632595216
            ],
            [
              -5.624921779312757,
              53.30087964873988
            ],
            [
              -4.630392483846578,
              53.30633550153618
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "challenger-bermuda-1-cb-1",
        "name": "Challenger Bermuda-1 (CB-1)",
        "color": "#89499c",
        "feature_id": "challenger-bermuda-1-cb-1-0",
        "coordinates": [
          -66.27905465789769,
          37.325965834785364
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -71.64596500938637,
              41.4121212139155
            ],
            [
              -71.09987539624133,
              41.0693404382163
            ],
            [
              -68.39987730894534,
              39.00237890905848
            ],
            [
              -66.51654530978234,
              37.58978657360316
            ],
            [
              -63.899880496785315,
              34.68301765985788
            ],
            [
              -63.67488065617728,
              33.565491482352144
            ],
            [
              -63.67488065617728,
              32.81231878328768
            ],
            [
              -64.23738025769728,
              32.33831157801282
            ],
            [
              -64.80163985797051,
              32.2586528368615
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "columbus-ii-b",
        "name": "Columbus-II b",
        "color": "#69c6b6",
        "feature_id": "columbus-ii-b-0",
        "coordinates": [
          -70.42061125399958,
          26.51210265895338
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -64.93708976201644,
              18.372992194090983
            ],
            [
              -64.9123797795213,
              18.89166158430325
            ],
            [
              -65.24987954043327,
              20.796306105108954
            ],
            [
              -67.27487810590533,
              23.711258142484382
            ],
            [
              -69.2998766713773,
              25.95717997876443
            ],
            [
              -73.34987380232134,
              27.962503359972544
            ],
            [
              -76.94987125204939,
              28.061823659710228
            ],
            [
              -77.84987061448136,
              27.863091576993526
            ],
            [
              -79.64986933934539,
              26.964304734562802
            ],
            [
              -80.05334157838351,
              26.715271953494778
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "columbus-iii-azores-portugal",
        "name": "Columbus-III Azores-Portugal",
        "color": "#c35437",
        "feature_id": "columbus-iii-azores-portugal-0",
        "coordinates": [
          -17.422228036962622,
          36.993119919861954
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9.331559153496016,
              38.69016197235561
            ],
            [
              -10.349918432080813,
              38.21117903702327
            ],
            [
              -13.949915882404753,
              36.993119919861954
            ],
            [
              -23.399909187940864,
              36.993119919861954
            ],
            [
              -24.74990823099293,
              37.232354321556215
            ],
            [
              -25.42490775281695,
              37.58978657360316
            ],
            [
              -25.668707580106883,
              37.73960462631459
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "comoros-domestic-cable-system",
        "name": "Comoros Domestic Cable System",
        "color": "#5cc4c1",
        "feature_id": "comoros-domestic-cable-system-0",
        "coordinates": [
          44.38348080171153,
          -12.159732244027708
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              43.4892034277801,
              -11.923115063020134
            ],
            [
              44.029043045352836,
              -11.808811027678217
            ],
            [
              44.400042782532864,
              -12.166271132901954
            ],
            [
              44.029043045352836,
              -12.019795534362657
            ],
            [
              43.73969325033076,
              -12.287868671415396
            ],
            [
              43.54749338648705,
              -12.045379517998498
            ],
            [
              43.4892034277801,
              -11.923115063020134
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "corse-continent-4-cc4",
        "name": "Corse-Continent 4 (CC4)",
        "color": "#34bfc8",
        "feature_id": "corse-continent-4-cc4-0",
        "coordinates": [
          7.949098709147364,
          43.03637757194946
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              7.017449264712866,
              43.55300871311741
            ],
            [
              7.200069135343199,
              43.40114497315386
            ],
            [
              8.55006817899124,
              42.74371346443661
            ],
            [
              8.937177904759153,
              42.63668577913154
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "corse-continent-5-cc5",
        "name": "Corse-Continent 5 (CC5)",
        "color": "#32c0cf",
        "feature_id": "corse-continent-5-cc5-0",
        "coordinates": [
          7.185488436669317,
          42.2511810982017
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              5.878200071767793,
              43.10319473312765
            ],
            [
              6.075069932303194,
              42.74371346443661
            ],
            [
              6.300069772911229,
              42.57825408607275
            ],
            [
              7.425068975355316,
              42.16268022756137
            ],
            [
              8.414618274945298,
              41.835738425063056
            ],
            [
              8.73873804533585,
              41.919502734658465
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "danica-north",
        "name": "Danica North",
        "color": "#3c61ad",
        "feature_id": "danica-north-0",
        "coordinates": [
          12.745254304550457,
          55.77884269856111
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              12.955535058117334,
              55.77057758158506
            ],
            [
              12.716565227405795,
              55.779970327098255
            ],
            [
              12.54557534853663,
              55.720775126305966
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "danice",
        "name": "DANICE",
        "color": "#5bba46",
        "feature_id": "danice-0",
        "coordinates": [
          -5.161393410267806,
          62.67977190838903
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -20.14185149538352,
              63.642367197144665
            ],
            [
              -20.699911101240872,
              63.3248621310789
            ],
            [
              -20.69991110064487,
              62.98618902459583
            ],
            [
              -19.799911737616924,
              62.50536500000058
            ],
            [
              -14.399915563024848,
              62.29689073879055
            ],
            [
              -10.799918113296798,
              62.71239189403053
            ],
            [
              -7.424920504176782,
              62.81536487879316
            ],
            [
              -2.249924170192742,
              62.50536500000058
            ],
            [
              0.450073917103168,
              62.08696177092739
            ],
            [
              2.025072801359244,
              61.23255301306608
            ],
            [
              2.925072163791214,
              60.35428947498107
            ],
            [
              3.825071526223184,
              58.05131589106017
            ],
            [
              5.175070569871224,
              56.22032688484515
            ],
            [
              6.300069772911229,
              55.96930907315377
            ],
            [
              7.200069135343199,
              55.71665209382169
            ],
            [
              7.650068816559269,
              55.65323105219801
            ],
            [
              8.329168335478954,
              55.75165023178093
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "denmark-sweden-17",
        "name": "Denmark-Sweden 17",
        "color": "#34a89e",
        "feature_id": "denmark-sweden-17-0",
        "coordinates": [
          12.562760303895828,
          56.10501725400175
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              12.536885354692657,
              56.0750809772897
            ],
            [
              12.562760336362658,
              56.105017291564536
            ],
            [
              12.588635318032487,
              56.134930342754785
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "denmark-sweden-18",
        "name": "Denmark-Sweden 18",
        "color": "#373e98",
        "feature_id": "denmark-sweden-18-0",
        "coordinates": [
          12.643960278839899,
          56.03727869909534
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              12.592155315538946,
              56.03063574342079
            ],
            [
              12.643960278839899,
              56.03727869909534
            ],
            [
              12.695765242140624,
              56.043920511503416
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "dhiraagu-cable-network",
        "name": "Dhiraagu Cable Network",
        "color": "#b91f32",
        "feature_id": "dhiraagu-cable-network-0",
        "coordinates": [
          73.05517256759464,
          3.0898789230642194
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              73.07150247140208,
              6.622826415013179
            ],
            [
              72.97150254224294,
              6.125830038600412
            ],
            [
              72.97082254272445,
              5.601440402765235
            ],
            [
              73.07082247188356,
              5.103622553806417
            ],
            [
              73.07082247188356,
              4.854566321204942
            ],
            [
              73.29035231636678,
              4.461629537081564
            ],
            [
              73.5403521392646,
              4.212345781871875
            ],
            [
              73.12502243348774,
              3.828234303320954
            ],
            [
              72.95568255344978,
              3.608488161798789
            ],
            [
              72.97732253812,
              3.276079687360095
            ],
            [
              73.54475213614768,
              1.918908398538576
            ],
            [
              73.57502211470381,
              1.01853421661562
            ],
            [
              73.45559219930917,
              0.28928715351492
            ],
            [
              73.46652219156641,
              0.060338533670517
            ],
            [
              73.42688221964778,
              -0.297909859053817
            ],
            [
              73.35002227409598,
              -0.443906656918472
            ],
            [
              73.08918245887716,
              -0.605519711481634
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "dhiraagu-slt-submarine-cable-network",
        "name": "Dhiraagu-SLT Submarine Cable Network",
        "color": "#a44399",
        "feature_id": "dhiraagu-slt-submarine-cable-network-0",
        "coordinates": [
          76.84260880413498,
          5.221141038488042
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              79.87208765380373,
              6.927036656836265
            ],
            [
              79.20001812990384,
              6.181557032537114
            ],
            [
              74.25002163652775,
              4.164912849976844
            ],
            [
              73.50002216783449,
              4.166668198861885
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "east-west",
        "name": "East-West",
        "color": "#30ace2",
        "feature_id": "east-west-0",
        "coordinates": [
          -70.67175258722082,
          17.388055736679462
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -76.71676141718658,
              17.949953694577488
            ],
            [
              -76.27487173022537,
              17.39502263470061
            ],
            [
              -75.3748723677934,
              17.180187287481402
            ],
            [
              -74.69987284596938,
              16.965102599435824
            ],
            [
              -71.99987475867339,
              16.965102599435824
            ],
            [
              -70.64987571502535,
              17.39502263470061
            ],
            [
              -69.86237627289731,
              17.823934412537824
            ],
            [
              -69.2998766713773,
              17.716802179008738
            ],
            [
              -67.49987794651328,
              17.39502263470061
            ],
            [
              -66.14987890286532,
              17.609605913225096
            ],
            [
              -64.597150002833,
              18.41441211576617
            ]
          ],
          [
            [
              -70.16178606079262,
              18.699363841238295
            ],
            [
              -69.91862623304927,
              18.144943564296312
            ],
            [
              -69.86237627289731,
              17.823934412537824
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "eastern-caribbean-fiber-system-ecfs",
        "name": "Eastern Caribbean Fiber System (ECFS)",
        "color": "#b4d333",
        "feature_id": "eastern-caribbean-fiber-system-ecfs-0",
        "coordinates": [
          -61.199882409489234,
          14.315637237869822
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -61.65081209004636,
              10.686261032786234
            ],
            [
              -61.5373821704012,
              11.073982781226704
            ],
            [
              -61.5373821704012,
              11.294709319565555
            ],
            [
              -61.5373821704012,
              11.625479959569759
            ],
            [
              -61.64988209070522,
              11.845776373625682
            ],
            [
              -61.7905819910321,
              12.008779421336584
            ],
            [
              -61.873071932595394,
              12.19152678955166
            ],
            [
              -61.81853197123205,
              12.395734000022884
            ],
            [
              -61.481032210319995,
              13.054150695298716
            ],
            [
              -61.20828240353856,
              13.145460938850391
            ],
            [
              -61.199882409489234,
              13.054150695298716
            ],
            [
              -59.849883365841194,
              13.054150695298716
            ],
            [
              -59.60740353761618,
              13.084493800663012
            ],
            [
              -59.849883365841194,
              13.163718917913684
            ],
            [
              -60.74988272827325,
              14.038469666260113
            ],
            [
              -60.9748825688812,
              14.14758350694865
            ],
            [
              -61.00681254626171,
              13.994508755694056
            ],
            [
              -61.199882409489234,
              14.14758350694865
            ],
            [
              -61.199882409489234,
              14.365653759228536
            ],
            [
              -61.094172484375164,
              14.615455776713844
            ],
            [
              -61.36863228994521,
              14.801154224791475
            ],
            [
              -61.5373821704012,
              15.018578573757566
            ],
            [
              -61.5373821704012,
              15.23578178303569
            ],
            [
              -61.64988209070522,
              15.452760959322148
            ],
            [
              -61.60779212052215,
              15.676204818171186
            ],
            [
              -61.43915723998468,
              15.892797111330793
            ],
            [
              -61.41387225789684,
              15.98876894276924
            ],
            [
              -61.53303217348282,
              16.24132860028527
            ],
            [
              -61.76238201100924,
              16.590029438056348
            ],
            [
              -61.85794194331372,
              17.05148171404491
            ],
            [
              -62.099881771921204,
              16.965102599435824
            ],
            [
              -62.549881453137274,
              16.965102599435824
            ],
            [
              -62.72976132570861,
              17.09087600571297
            ],
            [
              -62.72976132570861,
              17.298635546518767
            ],
            [
              -62.72976132570861,
              17.40237987467563
            ],
            [
              -63.07366108208683,
              18.031045075146636
            ],
            [
              -63.05813109308839,
              18.144943564296312
            ],
            [
              -63.057161093775605,
              18.21768116250081
            ],
            [
              -63.22488097496121,
              18.30522807897634
            ],
            [
              -63.449880815569244,
              18.358623372153403
            ],
            [
              -64.12488033739326,
              18.358623372153403
            ],
            [
              -64.34988017800121,
              18.358623372153403
            ],
            [
              -64.597150002833,
              18.41441211576617
            ]
          ],
          [
            [
              -61.5373821704012,
              15.23578178303569
            ],
            [
              -61.3706822884929,
              15.252077499919722
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "eclink",
        "name": "ECLink",
        "color": "#663c97",
        "feature_id": "eclink-0",
        "coordinates": [
          -65.19034678537976,
          11.278035921850005
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -61.64929209112324,
              10.687695708022744
            ],
            [
              -62.099881771921204,
              11.294709319565555
            ],
            [
              -63.899880496785315,
              11.515266158038685
            ],
            [
              -65.69987922164925,
              11.184367066436806
            ],
            [
              -68.39987730894534,
              11.735650161405744
            ],
            [
              -68.89264695986272,
              12.090439618305055
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "elektra-globalconnect-1-gc1",
        "name": "Elektra-GlobalConnect 1 (GC1)",
        "color": "#c88129",
        "feature_id": "elektra-globalconnect-1-gc1-0",
        "coordinates": [
          11.931558055236366,
          54.29065286855922
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              11.940005777527603,
              54.5772362672833
            ],
            [
              11.925065788111255,
              54.49397229925464
            ],
            [
              11.925065788111255,
              54.29748595281848
            ],
            [
              12.132485641173247,
              54.079177416570104
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "emerald-bridge-fibres",
        "name": "Emerald Bridge Fibres",
        "color": "#ec1c24",
        "feature_id": "emerald-bridge-fibres-0",
        "coordinates": [
          -5.393337381404365,
          53.54170333979508
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -6.197971373358996,
              53.41013850693267
            ],
            [
              -5.624921779312757,
              53.54157968163065
            ],
            [
              -5.090742157730631,
              53.54186491531689
            ],
            [
              -4.630392483846578,
              53.30633550153618
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "aec-1",
        "name": "AEC-1",
        "color": "#923c96",
        "feature_id": "aec-1-0",
        "coordinates": [
          -40.62232040192541,
          46.63726305614682
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9.232149223918952,
              54.207113520678774
            ],
            [
              -9.56241898995276,
              54.559258765782204
            ],
            [
              -10.799918113296798,
              55.07780072164758
            ],
            [
              -12.599916838160823,
              55.07780072164758
            ],
            [
              -16.199914287888873,
              54.29748595281848
            ],
            [
              -23.39990918734489,
              52.4179012603154
            ],
            [
              -39.59989771112103,
              46.89076287862241
            ],
            [
              -50.39989006030518,
              44.21300917863164
            ],
            [
              -61.199882409489234,
              42.079235618164205
            ],
            [
              -68.39987730894534,
              41.0693404382163
            ],
            [
              -71.09987539624133,
              40.47295490579842
            ],
            [
              -72.87218414072119,
              40.80058099504518
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "esat-2",
        "name": "ESAT-2",
        "color": "#27baad",
        "feature_id": "esat-2-0",
        "coordinates": [
          -4.615319758346743,
          53.53513602111022
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -6.215401361011459,
              53.331711289007416
            ],
            [
              -5.624921779312757,
              53.43513085622595
            ],
            [
              -3.599923213840782,
              53.63571515699499
            ],
            [
              -3.006373634316702,
              53.64793339883286
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "est-tet",
        "name": "Est-Tet",
        "color": "#2ca887",
        "feature_id": "est-tet-0",
        "coordinates": [
          -5.174922098096772,
          35.95304731852565
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -5.391811944449927,
              35.565918934421404
            ],
            [
              -5.174922098096772,
              35.78566189952613
            ],
            [
              -5.174922098096772,
              36.240655233214795
            ],
            [
              -5.145872118676039,
              36.42741977174609
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "farice-1",
        "name": "FARICE-1",
        "color": "#62bd60",
        "feature_id": "farice-1-0",
        "coordinates": [
          -6.556732613004385,
          63.179471746966584
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -6.927950856234673,
              62.24411205903692
            ],
            [
              -6.897247538141272,
              62.40594616938375
            ],
            [
              -7.199920663568747,
              62.71239189403053
            ],
            [
              -7.199920663568747,
              63.3248621310789
            ]
          ],
          [
            [
              -3.346213393571077,
              58.615419520861536
            ],
            [
              -3.824923054448732,
              58.75856894488285
            ],
            [
              -3.824923054448732,
              59.451717318905224
            ],
            [
              -3.149923532624712,
              60.35428947498107
            ],
            [
              -2.699923851408727,
              61.875570783571504
            ],
            [
              -2.699923851408727,
              62.08696177092739
            ],
            [
              -5.399921938704722,
              62.91797878525176
            ],
            [
              -7.199920663568747,
              63.3248621310789
            ],
            [
              -10.799918113296798,
              64.70468599661166
            ],
            [
              -10.799918113296798,
              65.08652060243708
            ],
            [
              -12.149917156944838,
              65.27540872550341
            ],
            [
              -13.499916200592793,
              65.27540872550341
            ],
            [
              -14.018075833523653,
              65.25084981890586
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "fehmarn-blt",
        "name": "Fehmarn Bält",
        "color": "#af4298",
        "feature_id": "fehmarn-blt-0",
        "coordinates": [
          11.260634989711548,
          54.599844524432626
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              11.212566292852728,
              54.49618504236204
            ],
            [
              11.25006626628732,
              54.59302163749791
            ],
            [
              11.35856618942509,
              54.66306630403628
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "fiber-optic-gulf-fog",
        "name": "Fiber Optic Gulf (FOG)",
        "color": "#2859a8",
        "feature_id": "fiber-optic-gulf-fog-0",
        "coordinates": [
          51.619007540901066,
          27.175477152805456
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              51.300037894511576,
              27.364667993860166
            ],
            [
              50.850038213295704,
              26.562513149236622
            ],
            [
              50.576018407413954,
              26.22949483839116
            ]
          ],
          [
            [
              52.650036938159616,
              26.562513149236622
            ],
            [
              52.08753733663963,
              26.058287560298936
            ],
            [
              51.51927773920005,
              25.294608758024538
            ]
          ],
          [
            [
              47.97484025011303,
              29.37410420420028
            ],
            [
              48.600039807215495,
              28.951554732193216
            ],
            [
              49.16253940873568,
              28.55704546571141
            ],
            [
              50.17503869147157,
              27.863091576993526
            ],
            [
              51.300037894511576,
              27.364667993860166
            ],
            [
              51.97503741633571,
              26.964304734562802
            ],
            [
              52.650036938159616,
              26.562513149236622
            ],
            [
              53.550036300591586,
              25.754704263415306
            ],
            [
              54.900035344239626,
              25.55188275942578
            ],
            [
              55.308535054854815,
              25.26935399813027
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "fibralink",
        "name": "Fibralink",
        "color": "#c83a26",
        "feature_id": "fibralink-0",
        "coordinates": [
          -74.90047466726729,
          18.151229656501897
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -77.92138056441907,
              18.469357593227244
            ],
            [
              -77.73737069477342,
              18.500930002687937
            ],
            [
              -77.28737101355735,
              18.500930002687937
            ],
            [
              -77.1032411434007,
              18.398661383088154
            ]
          ],
          [
            [
              -73.79987348353733,
              19.952622905164304
            ],
            [
              -73.57487364292938,
              19.104405475930548
            ],
            [
              -72.80265418997686,
              18.86477035965592
            ],
            [
              -72.60866432740099,
              18.865044781697776
            ]
          ],
          [
            [
              -76.66662145270622,
              17.94977294265982
            ],
            [
              -76.27487173022537,
              17.609605913225096
            ],
            [
              -75.3748723677934,
              17.609605913225096
            ],
            [
              -74.8123727662734,
              18.251816319028308
            ],
            [
              -74.36237308505733,
              19.316876111628602
            ],
            [
              -73.79987348353733,
              19.952622905164304
            ]
          ],
          [
            [
              -73.79987348353733,
              19.952622905164304
            ],
            [
              -73.34987380232134,
              20.375041253465525
            ],
            [
              -71.54987507745732,
              20.375041253465525
            ],
            [
              -70.98737547593731,
              20.16397503197578
            ],
            [
              -70.69118568576094,
              19.79943635579707
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "finland-estonia-2-eesf-2",
        "name": "Finland-Estonia 2 (EESF-2)",
        "color": "#b91e4e",
        "feature_id": "finland-estonia-2-eesf-2-0",
        "coordinates": [
          25.13024862644491,
          59.7869702219985
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              24.752496701038922,
              59.43639985926242
            ],
            [
              25.087556463679334,
              59.6796637072089
            ],
            [
              25.20005638398345,
              59.962431634152296
            ],
            [
              25.087556463679334,
              60.07486799642308
            ],
            [
              24.93247657353959,
              60.171163188940454
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "flag-atlantic-1-fa-1",
        "name": "FLAG Atlantic-1 (FA-1)",
        "color": "#80479b",
        "feature_id": "flag-atlantic-1-fa-1-0",
        "coordinates": [
          -37.83893068917163,
          42.2026330036247
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -5.674921743892327,
              50.06393817056218
            ],
            [
              -6.299921301136777,
              50.09514516168238
            ],
            [
              -8.099920026000802,
              49.87814473780409
            ],
            [
              -10.799918113296798,
              49.58728674004675
            ],
            [
              -16.199914287888873,
              48.1067757091962
            ],
            [
              -23.39990918734489,
              46.58235508209589
            ],
            [
              -39.59989771112103,
              42.41235450073577
            ],
            [
              -50.39989006030518,
              40.04369219282995
            ],
            [
              -61.199882409489234,
              39.351217571171134
            ],
            [
              -68.39987730894534,
              39.871225135616044
            ],
            [
              -71.67868498620714,
              41.097018351055965
            ],
            [
              -72.16564464124035,
              41.217596026703234
            ],
            [
              -72.3658444994169,
              41.217596026703234
            ],
            [
              -72.82077417714045,
              41.14638300214594
            ],
            [
              -73.19440391245772,
              41.022498134754784
            ],
            [
              -73.34411380640178,
              40.90945238298872
            ]
          ],
          [
            [
              -73.65597358547735,
              40.60068600870835
            ],
            [
              -71.09987539624133,
              39.871225135616044
            ],
            [
              -68.39987730894534,
              39.69832335493328
            ],
            [
              -61.199882409489234,
              39.00237890905848
            ],
            [
              -50.39989006030518,
              39.524987333511575
            ],
            [
              -39.59989771112103,
              41.744358789482135
            ],
            [
              -23.39990918734489,
              45.96024524125332
            ],
            [
              -16.199914287888873,
              46.58235508209589
            ],
            [
              -5.399921938704722,
              49.294684219425534
            ],
            [
              -4.499922576272752,
              49.294684219425534
            ],
            [
              -3.599923213840782,
              49.14772788577403
            ],
            [
              -2.81242377171273,
              48.85250340834858
            ],
            [
              -2.767793803328971,
              48.534938683234884
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "flag-north-asia-loopreach-north-asia-loop",
        "name": "FLAG North Asia Loop/REACH North Asia Loop",
        "color": "#c6b12e",
        "feature_id": "flag-north-asia-loopreach-north-asia-loop-0",
        "coordinates": [
          139.60237302402342,
          30.689317362291803
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              121.02775554672925,
              20.933105646773356
            ],
            [
              118.79999007691222,
              21.111485983488905
            ],
            [
              116.54999167083221,
              20.901439785237727
            ],
            [
              115.19999262718417,
              21.111485983488905
            ],
            [
              114.131293384261,
              21.84429407917378
            ],
            [
              113.93202352542542,
              22.227650940806967
            ],
            [
              114.30004326471689,
              21.84429407917378
            ],
            [
              115.19999262718417,
              21.425951327903107
            ],
            [
              116.54999167083221,
              21.21639789994191
            ],
            [
              118.79999007691222,
              21.635297384859456
            ],
            [
              120.12788799225589,
              21.666805652999244
            ],
            [
              121.02775554672925,
              21.666805652999244
            ],
            [
              121.94998784542437,
              22.469443964829594
            ],
            [
              122.39998752664027,
              24.01990020343257
            ],
            [
              122.17498768603221,
              24.532657566160623
            ],
            [
              121.80144795065141,
              24.863504112487874
            ],
            [
              122.17498768603221,
              24.941363171753835
            ],
            [
              122.84998720785634,
              24.941363171753835
            ],
            [
              125.99998497636832,
              24.32780311165172
            ],
            [
              128.6999830636644,
              24.12261698700334
            ],
            [
              132.74998019460836,
              24.532657566160623
            ],
            [
              137.24997700676838,
              27.763588526057674
            ],
            [
              138.82497589102448,
              29.34456698948989
            ],
            [
              139.7249752534564,
              30.901396088515583
            ],
            [
              140.1749749346723,
              32.43331330641712
            ],
            [
              140.62497461588836,
              33.93964008831958
            ],
            [
              140.45622473483658,
              34.69072647741018
            ],
            [
              140.17854415596688,
              34.967799876340905
            ],
            [
              140.09059999384837,
              35.01384769751846
            ],
            [
              140.01677504674288,
              35.036003087164914
            ],
            [
              140.07653750381044,
              35.01384769751846
            ],
            [
              140.1644817951919,
              34.967799876340905
            ],
            [
              140.4280997547605,
              34.69072647741018
            ],
            [
              140.51247469558447,
              33.93964008831958
            ],
            [
              139.94997509406446,
              32.43331330641712
            ],
            [
              139.0499757316325,
              31.478822672736243
            ],
            [
              137.69997668798445,
              30.70813999354155
            ],
            [
              134.99997860068837,
              29.931250704427015
            ],
            [
              132.74998019460836,
              29.931250704427015
            ],
            [
              130.49998178852834,
              30.126049846722907
            ],
            [
              129.59998242609637,
              30.901396088515583
            ],
            [
              129.37498258548837,
              31.67051304708704
            ],
            [
              129.37498258548837,
              32.81231878328768
            ],
            [
              129.48748250579226,
              34.31215165223537
            ],
            [
              128.99949285148878,
              35.17037876180012
            ],
            [
              128.81248298396835,
              34.31215165223537
            ],
            [
              128.24998338244833,
              32.81231878328768
            ],
            [
              128.02498354184027,
              31.67051304708704
            ],
            [
              127.34998402001636,
              29.540507745394393
            ],
            [
              127.12498417940836,
              28.55704546571141
            ],
            [
              125.99998497636832,
              26.159307970773796
            ],
            [
              125.6624852154562,
              25.55188275942578
            ],
            [
              125.32498545454442,
              24.12261698700334
            ],
            [
              124.42498609211222,
              22.05298561667763
            ],
            [
              122.84998720785634,
              21.111485983488905
            ],
            [
              121.02775554672925,
              20.933105646773356
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "flores-corvo-cable-system",
        "name": "Flores-Corvo Cable System",
        "color": "#425daa",
        "feature_id": "flores-corvo-cable-system-0",
        "coordinates": [
          -30.995581748343454,
          39.63909968175773
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -28.647515469891403,
              38.52540431175973
            ],
            [
              -28.799905361936965,
              38.38775473578434
            ],
            [
              -29.699904724368935,
              38.651811712711236
            ],
            [
              -30.59990408680099,
              39.351217571171134
            ],
            [
              -31.128423712392788,
              39.46251104100091
            ],
            [
              -31.108153726752107,
              39.566820275642186
            ],
            [
              -31.110503725087398,
              39.67224286345825
            ],
            [
              -30.59990408680099,
              39.524987333511575
            ],
            [
              -28.799905361936965,
              39.00237890905848
            ],
            [
              -28.124905840112945,
              38.91489898424143
            ],
            [
              -27.963385954535113,
              39.01140845880104
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "gemini-bermuda",
        "name": "Gemini Bermuda",
        "color": "#d73426",
        "feature_id": "gemini-bermuda-0",
        "coordinates": [
          -67.4409395957178,
          37.60260352598644
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -74.0470933084045,
              40.123492658236984
            ],
            [
              -71.09987539624133,
              39.524987333511575
            ],
            [
              -67.4165446722143,
              37.58978657360316
            ],
            [
              -64.79987985921728,
              34.68301765985788
            ],
            [
              -64.12488033739326,
              33.565491482352144
            ],
            [
              -63.899880496785315,
              32.81231878328768
            ],
            [
              -64.23738025769728,
              32.43331330641712
            ],
            [
              -64.81066985157355,
              32.24775179744316
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "geo-eirgrid",
        "name": "Geo-Eirgrid",
        "color": "#50c3c9",
        "feature_id": "geo-eirgrid-0",
        "coordinates": [
          -4.5655466754901965,
          53.58672242534149
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -6.165841396120214,
              53.52654276893227
            ],
            [
              -5.624921779312757,
              53.702365556682366
            ],
            [
              -3.330833404466404,
              53.45193909085103
            ],
            [
              -3.027563619305653,
              53.20172844509676
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "georgia-russia",
        "name": "Georgia-Russia",
        "color": "#ee7122",
        "feature_id": "georgia-russia-0",
        "coordinates": [
          39.72576960161075,
          43.61545611441076
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              37.77279747733587,
              44.72369712320864
            ],
            [
              37.80004745803154,
              44.37405751055866
            ],
            [
              38.47504697985545,
              43.889587736299546
            ],
            [
              39.37504634228762,
              43.564400497117504
            ],
            [
              39.726116093586484,
              43.61550655407004
            ],
            [
              39.82504602350352,
              43.40114497315386
            ],
            [
              40.50004554532762,
              42.74371346443661
            ],
            [
              41.40004490775959,
              42.24601493182962
            ],
            [
              41.66752471827439,
              42.14675635811534
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "germany-denmark-3",
        "name": "Germany-Denmark 3",
        "color": "#e61d2d",
        "feature_id": "germany-denmark-3-0",
        "coordinates": [
          12.037565708415343,
          54.38254333816769
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              11.940005777527603,
              54.5772362672833
            ],
            [
              12.037565708415343,
              54.49397229925464
            ],
            [
              12.037565708415343,
              54.29748595281848
            ],
            [
              12.149575629066447,
              54.19157680986666
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "glo-1",
        "name": "Glo-1",
        "color": "#3665b0",
        "feature_id": "glo-1-0",
        "coordinates": [
          -18.763594901721206,
          20.248687623409523
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -0.204315619320994,
              5.558285889905761
            ],
            [
              -0.449925445328716,
              3.279837005485092
            ],
            [
              -0.449925445328716,
              2.156121468705749
            ]
          ],
          [
            [
              -4.544402544762761,
              50.82820142743802
            ],
            [
              -6.074881460557094,
              50.382957439948456
            ],
            [
              -7.649920344784732,
              49.73293362369091
            ],
            [
              -9.899918750864742,
              48.70423463096077
            ],
            [
              -14.399915563024848,
              44.051519228735145
            ],
            [
              -14.399915563024848,
              39.69832335493328
            ],
            [
              -13.499916200592793,
              35.78566189952613
            ],
            [
              -12.824916678768858,
              33.18971466460036
            ],
            [
              -12.599916838160823,
              30.126049846722907
            ],
            [
              -13.499916200592793,
              28.161052262220892
            ],
            [
              -14.624915403632798,
              26.964304734562802
            ],
            [
              -17.549913331536914,
              22.884654113882362
            ],
            [
              -18.89991237518487,
              19.952622905164304
            ],
            [
              -18.89991237518487,
              14.801154224791475
            ],
            [
              -18.89991237518487,
              11.735650161405744
            ],
            [
              -17.549913331536914,
              8.635699417327544
            ],
            [
              -13.049916519376808,
              4.164912849976844
            ],
            [
              -10.799918113296798,
              1.918228780215685
            ],
            [
              -3.599923213840782,
              1.918228780215685
            ],
            [
              -0.449925445328716,
              2.156121468705749
            ],
            [
              1.575073120143173,
              3.266814816815753
            ],
            [
              2.250072641967279,
              3.379125568250004
            ],
            [
              2.700072323183178,
              4.164912849976844
            ],
            [
              3.423511810692077,
              6.439066911484701
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "southern-caribbean-fiber",
        "name": "Southern Caribbean Fiber",
        "color": "#44c0bb",
        "feature_id": "southern-caribbean-fiber-0",
        "coordinates": [
          -63.106187025004886,
          16.267778494508377
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -62.2217566861799,
              16.770717046677362
            ],
            [
              -62.21233169285668,
              16.83045355356245
            ],
            [
              -62.156081732704635,
              16.857419758344406
            ],
            [
              -62.043631812365234,
              16.803627104178844
            ]
          ],
          [
            [
              -62.85054881207171,
              17.89791564702213
            ],
            [
              -63.056031094576085,
              17.877381240664903
            ],
            [
              -63.168631014809236,
              17.93095470636061
            ],
            [
              -63.168631014809236,
              18.037957897069617
            ],
            [
              -63.07814455016468,
              18.089928806917726
            ]
          ],
          [
            [
              -64.81925984548829,
              17.773909269375793
            ],
            [
              -64.57488001860925,
              17.9845119674776
            ],
            [
              -64.12488033739326,
              17.931002277731153
            ],
            [
              -63.9248804790751,
              17.180187287481402
            ],
            [
              -62.374881577108795,
              15.452760959322148
            ],
            [
              -61.76238201100924,
              14.692360031374477
            ],
            [
              -61.31238232979325,
              14.14758350694865
            ]
          ],
          [
            [
              -61.71594204390779,
              16.02893825919861
            ],
            [
              -61.874881931313254,
              15.886035719079104
            ],
            [
              -61.98738185161727,
              15.94013010690935
            ],
            [
              -63.05613109450532,
              17.180187287481402
            ],
            [
              -63.17071434666629,
              17.422853263294712
            ],
            [
              -63.11238105465728,
              17.770328706497423
            ],
            [
              -63.22488097496121,
              17.87742882640819
            ],
            [
              -63.22488097496121,
              18.037957897069617
            ],
            [
              -63.07814455016468,
              18.089928806917726
            ]
          ],
          [
            [
              -66.10666893347562,
              18.466104232947515
            ],
            [
              -65.69987922164925,
              18.518710038590683
            ],
            [
              -65.36237946073729,
              18.465364393137207
            ],
            [
              -65.13737962012925,
              18.251816319028308
            ],
            [
              -64.68737993891327,
              18.091435124357048
            ],
            [
              -64.12488033739326,
              18.144943564296312
            ],
            [
              -63.449880815569244,
              18.251816319028308
            ],
            [
              -63.22488097496121,
              18.198340634646257
            ],
            [
              -63.07814455016468,
              18.089928806917726
            ]
          ],
          [
            [
              -61.57168214610279,
              16.24447966793278
            ],
            [
              -61.47013221804178,
              15.98876894276924
            ],
            [
              -61.495272200232264,
              15.892797111330793
            ],
            [
              -61.60779212052215,
              15.83866113843132
            ],
            [
              -61.71594204390779,
              16.02893825919861
            ]
          ],
          [
            [
              -61.20828240353856,
              13.145460938850391
            ],
            [
              -61.59343213069488,
              13.054150695298716
            ],
            [
              -61.93093189160683,
              12.395734000022884
            ],
            [
              -61.93133189132351,
              12.19152678955166
            ],
            [
              -61.7905819910321,
              12.008779421336584
            ]
          ],
          [
            [
              -60.98660256057862,
              14.032406258622586
            ],
            [
              -61.199882409489234,
              13.929303843271725
            ],
            [
              -61.42488225009727,
              13.492128176464178
            ],
            [
              -61.42488225009727,
              13.273238157547667
            ],
            [
              -61.20828240353856,
              13.145460938850391
            ]
          ],
          [
            [
              -64.81925984548829,
              17.773909269375793
            ],
            [
              -64.68737993891327,
              18.091435124357048
            ]
          ],
          [
            [
              -61.31238232979325,
              14.14758350694865
            ],
            [
              -61.199882409489234,
              14.038469666260113
            ],
            [
              -60.98660256057862,
              14.032406258622586
            ]
          ],
          [
            [
              -61.31238232979325,
              14.14758350694865
            ],
            [
              -60.9748825688812,
              14.256644994553568
            ],
            [
              -60.74988272827325,
              14.14758350694865
            ],
            [
              -59.849883365841194,
              13.273238157547667
            ],
            [
              -59.60740353761618,
              13.084493800663012
            ]
          ],
          [
            [
              -61.094172484375164,
              14.615455776713844
            ],
            [
              -61.31238232979325,
              14.801154224791475
            ],
            [
              -61.42488225009727,
              15.018578573757566
            ],
            [
              -61.392512273028444,
              15.308036758161222
            ],
            [
              -61.5373821704012,
              15.452760959322148
            ],
            [
              -61.72028204083327,
              15.83866113843132
            ],
            [
              -61.71594204390779,
              16.02893825919861
            ]
          ],
          [
            [
              -61.71594204390779,
              16.02893825919861
            ],
            [
              -61.874881931313254,
              15.994221447252016
            ],
            [
              -62.099881771921204,
              16.53419619825962
            ],
            [
              -62.043631812365234,
              16.803627104178844
            ],
            [
              -61.98738185161727,
              16.965102599435824
            ],
            [
              -61.84688195174468,
              17.159549933439052
            ]
          ],
          [
            [
              -62.72976132570861,
              17.298635546518767
            ],
            [
              -63.05613109450532,
              17.180187287481402
            ]
          ],
          [
            [
              -61.094172484375164,
              14.615455776713844
            ],
            [
              -61.143632449337275,
              14.365653759228536
            ],
            [
              -61.143632449337275,
              14.14758350694865
            ],
            [
              -60.98660256057862,
              14.032406258622586
            ]
          ],
          [
            [
              -61.7905819910321,
              12.008779421336584
            ],
            [
              -61.814831973853245,
              11.861435172630735
            ],
            [
              -61.64988209070522,
              11.294709319565555
            ],
            [
              -61.593632130553246,
              11.073982781226704
            ],
            [
              -61.64929209112324,
              10.687695708022744
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "globalconnect-2-gc2",
        "name": "GlobalConnect 2 (GC2)",
        "color": "#3880c2",
        "feature_id": "globalconnect-2-gc2-0",
        "coordinates": [
          11.284722502689306,
          57.483603421231265
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              10.516666785834417,
              57.33104423288563
            ],
            [
              10.80006658507125,
              57.4510360539646
            ],
            [
              11.70006594750339,
              57.51151320633309
            ],
            [
              12.076235681021274,
              57.48768909927358
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "globalconnect-3-gc3",
        "name": "GlobalConnect 3 (GC3)",
        "color": "#d13829",
        "feature_id": "globalconnect-3-gc3-0",
        "coordinates": [
          10.974669081828239,
          55.33230861812649
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              11.149386337610053,
              55.32605364145988
            ],
            [
              11.025066425679285,
              55.33458061322915
            ],
            [
              10.799576585418407,
              55.32441516057577
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "globalconnect-kpn",
        "name": "GlobalConnect-KPN",
        "color": "#e03225",
        "feature_id": "globalconnect-kpn-0",
        "coordinates": [
          11.828189393563662,
          54.28682468920536
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              11.940005777527603,
              54.5772362672833
            ],
            [
              11.812565867807308,
              54.49397229925464
            ],
            [
              11.812565867807308,
              54.29748595281848
            ],
            [
              12.132485641173247,
              54.079177416570104
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "globenet",
        "name": "GlobeNet",
        "color": "#7b489c",
        "feature_id": "globenet-0",
        "coordinates": [
          -39.356356011659614,
          2.193155708986552
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -80.08893155227206,
              26.35058457732009
            ],
            [
              -79.64986933934539,
              26.813799487940685
            ],
            [
              -77.84987061448136,
              28.951554732193216
            ],
            [
              -76.4998715708334,
              30.901396088515583
            ],
            [
              -75.14987252718537,
              33.565491482352144
            ],
            [
              -74.47487300595733,
              35.541926812580044
            ],
            [
              -73.91237340443733,
              39.00237890905848
            ],
            [
              -74.33781310245585,
              39.60388206573748
            ],
            [
              -73.34987380232134,
              39.351217571171134
            ],
            [
              -71.09987539624133,
              39.00237890905848
            ],
            [
              -68.31654403464637,
              37.58978657360316
            ],
            [
              -65.69987922164925,
              34.68301765985788
            ],
            [
              -64.57488001860925,
              33.565491482385426
            ],
            [
              -64.12488033739326,
              32.81231878328768
            ],
            [
              -64.23738025769728,
              32.52821504536482
            ],
            [
              -64.77341987796179,
              32.31966263149877
            ],
            [
              -64.34988017800121,
              31.28673881439167
            ],
            [
              -57.599884959761184,
              22.05298561667763
            ],
            [
              -52.19988878516919,
              15.669513225155328
            ],
            [
              -46.79989261057713,
              10.410816505402636
            ],
            [
              -38.69989834868906,
              1.468426767332062
            ],
            [
              -38.02489882686504,
              -1.231315750217505
            ],
            [
              -38.54296845985962,
              -3.718735129291019
            ],
            [
              -35.999900261393066,
              -3.479268678969987
            ],
            [
              -34.199901536528955,
              -4.15276774801373
            ],
            [
              -32.17490296483794,
              -5.49795068212461
            ],
            [
              -31.27490360862501,
              -9.290424301035614
            ],
            [
              -32.849902492881,
              -13.698987269610853
            ],
            [
              -34.649901217745025,
              -18.026426383713385
            ],
            [
              -38.02489882686504,
              -22.87343495354625
            ],
            [
              -40.94989675476907,
              -23.905969261790176
            ],
            [
              -42.299895798417,
              -23.700175468198324
            ],
            [
              -43.209565153998795,
              -22.903486555497864
            ],
            [
              -42.299895798417,
              -23.597156567259958
            ],
            [
              -40.94989675476907,
              -23.70010845220321
            ],
            [
              -38.362398587776994,
              -22.562119511835817
            ],
            [
              -35.549900580176995,
              -18.026426383713385
            ],
            [
              -33.749901855313055,
              -13.698987269610853
            ],
            [
              -31.949903130448945,
              -9.290424301035614
            ],
            [
              -32.51240272885991,
              -5.833801116332353
            ],
            [
              -34.199901536528955,
              -4.489307688629395
            ],
            [
              -35.999900261393066,
              -3.703826470668162
            ],
            [
              -38.54296845985962,
              -3.718735129291019
            ]
          ],
          [
            [
              -80.08893155227206,
              26.35058457732009
            ],
            [
              -79.64986933934539,
              26.663094151095322
            ],
            [
              -77.84987061448136,
              27.364667993860166
            ],
            [
              -76.94987125204939,
              27.16466581281361
            ],
            [
              -73.34987380232134,
              24.532657566160623
            ],
            [
              -69.2998766713773,
              22.469443964829594
            ],
            [
              -67.94987762772926,
              20.585819096040467
            ],
            [
              -67.83737770742533,
              18.67864702215462
            ],
            [
              -68.1748774683373,
              17.823934412537824
            ],
            [
              -68.39987730894534,
              15.23578178303569
            ],
            [
              -67.49987794651328,
              12.615395567393307
            ],
            [
              -67.27487810590533,
              11.294709319565555
            ],
            [
              -66.96042832866442,
              10.599588212552732
            ],
            [
              -66.71237850438533,
              10.853089690745378
            ],
            [
              -65.69987922164925,
              11.405009147532837
            ],
            [
              -63.899880496785315,
              11.735650161405744
            ],
            [
              -62.99988113435326,
              11.735650161405744
            ],
            [
              -61.199882409489234,
              11.735650161405744
            ],
            [
              -59.39988368462521,
              11.294709319565555
            ],
            [
              -54.89988687246519,
              9.08033076823304
            ],
            [
              -51.29988942273715,
              6.852191098754417
            ],
            [
              -46.79989261057713,
              5.061986954416028
            ],
            [
              -40.94989675476907,
              1.468426767332062
            ],
            [
              -39.149898029905046,
              -1.231315750217505
            ],
            [
              -38.54296845985962,
              -3.718735129291019
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "globenet",
        "name": "GlobeNet",
        "color": "#939597",
        "feature_id": "globenet-1",
        "coordinates": [
          -72.33573647214467,
          13.765504793167963
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -68.39987730894534,
              15.23578178303569
            ],
            [
              -70.19987603380928,
              14.801154224791475
            ],
            [
              -71.54987507745732,
              14.14758350694865
            ],
            [
              -74.2498731647534,
              12.834868817846598
            ],
            [
              -74.92487268657733,
              11.735650161405744
            ],
            [
              -74.77975278938158,
              10.94044561572673
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "go-1-mediterranean-cable-system",
        "name": "GO-1 Mediterranean Cable System",
        "color": "#49b751",
        "feature_id": "go-1-mediterranean-cable-system-0",
        "coordinates": [
          13.336982061256037,
          36.642482372416254
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              12.591375316091586,
              37.650586172786205
            ],
            [
              12.712565230239278,
              37.232354321556215
            ],
            [
              13.05006499115126,
              36.87321951208918
            ],
            [
              14.062564273887318,
              36.05897312258671
            ],
            [
              14.411884026425952,
              35.95058770661464
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "gondwana-1",
        "name": "Gondwana-1",
        "color": "#583c97",
        "feature_id": "gondwana-1-0",
        "coordinates": [
          158.5532566644646,
          -27.953469875203837
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              151.20699711948444,
              -33.86955173177813
            ],
            [
              152.09996648689648,
              -33.74264465652948
            ],
            [
              154.80001816943465,
              -32.23291009389612
            ],
            [
              157.49996266148864,
              -28.74381028114999
            ],
            [
              165.5999569233767,
              -22.665969967794723
            ],
            [
              166.43925632880908,
              -22.30330806462004
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "oteglobe-kokkini-bari",
        "name": "OTEGLOBE Kokkini-Bari",
        "color": "#eb8d22",
        "feature_id": "oteglobe-kokkini-bari-0",
        "coordinates": [
          19.37472434465883,
          39.98581587040226
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              16.86881228591494,
              41.125709058522546
            ],
            [
              18.00006148452735,
              41.15410110990115
            ],
            [
              18.675061006351456,
              40.89949091487156
            ],
            [
              19.125060687567355,
              40.387320290775165
            ],
            [
              19.237560607871444,
              40.04369219282995
            ],
            [
              19.92399012159845,
              39.75405284068893
            ],
            [
              19.125060687567355,
              40.04369219282995
            ],
            [
              19.01256076726341,
              40.387320290775165
            ],
            [
              18.56256108604734,
              40.814402154698826
            ],
            [
              18.00006148452735,
              41.0693404382163
            ],
            [
              16.86881228591494,
              41.125709058522546
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "greenland-connect-north",
        "name": "Greenland Connect North",
        "color": "#b1542d",
        "feature_id": "greenland-connect-north-0",
        "coordinates": [
          -53.99988751003313,
          66.40121848608959
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -51.72996911806467,
              64.1812188111395
            ],
            [
              -52.19988878516919,
              64.12167519439029
            ],
            [
              -52.87488830699313,
              64.31739001144422
            ],
            [
              -53.09988814760116,
              64.70468599661166
            ],
            [
              -53.09988814760116,
              65.08652060243708
            ],
            [
              -52.90572233117308,
              65.40767479539323
            ],
            [
              -53.54988782881715,
              65.5562256550422
            ],
            [
              -53.99988751003313,
              65.83404693088383
            ],
            [
              -53.99988751003313,
              66.3808044991722
            ],
            [
              -53.99988751003313,
              66.56045213116752
            ],
            [
              -53.67338536157229,
              66.93947156321047
            ],
            [
              -54.22488735064117,
              67.0039445723607
            ],
            [
              -54.4498871912492,
              67.2662194729948
            ],
            [
              -54.4498871912492,
              68.12014116880246
            ],
            [
              -53.54988782881715,
              68.61776755908173
            ],
            [
              -52.85911591887751,
              68.70697594519777
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "greenland-connect",
        "name": "Greenland Connect",
        "color": "#f47920",
        "feature_id": "greenland-connect-0",
        "coordinates": [
          -47.19126135426103,
          59.82634572178074
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -53.960277538689255,
              48.20755282132379
            ],
            [
              -53.54988782881715,
              48.0692013806549
            ],
            [
              -53.09988814760116,
              48.256798568947545
            ],
            [
              -52.19988878516919,
              48.70423463096077
            ],
            [
              -49.49989069787313,
              50.740281893948264
            ],
            [
              -49.04989101665714,
              51.8655716555981
            ],
            [
              -50.84988974152117,
              60.130942866098394
            ],
            [
              -51.74988910395311,
              61.23255301306608
            ],
            [
              -52.64988846638518,
              62.71239189403053
            ],
            [
              -52.64988846638518,
              63.526173423791136
            ],
            [
              -51.97488894456117,
              63.82549832634285
            ],
            [
              -51.72996911806467,
              64.1812188111395
            ],
            [
              -51.86238902425714,
              63.82549832634285
            ],
            [
              -52.42488862577723,
              63.526173423791136
            ],
            [
              -52.19988878516919,
              62.71239189403053
            ],
            [
              -50.39989006030518,
              61.23255301306608
            ],
            [
              -48.59989133544116,
              60.35428947498107
            ],
            [
              -46.79989261057713,
              59.6796637072089
            ],
            [
              -45.89989324814505,
              59.451717318905224
            ],
            [
              -43.19989516084908,
              59.22222391484422
            ],
            [
              -27.899905999504995,
              61.01524141470435
            ],
            [
              -22.49990982491292,
              62.08696177092739
            ],
            [
              -20.92491094065693,
              63.3248621310789
            ],
            [
              -20.14185149538352,
              63.642367197144665
            ]
          ],
          [
            [
              -46.035393152155706,
              60.719028410192635
            ],
            [
              -46.34989292936115,
              60.35428947498107
            ],
            [
              -46.79989261057713,
              59.6796637072089
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "guam-okinawa-kyushu-incheon-goki",
        "name": "Guam Okinawa Kyushu Incheon (GOKI)",
        "color": "#48b64e",
        "feature_id": "guam-okinawa-kyushu-incheon-goki-0",
        "coordinates": [
          131.44483294188095,
          21.9023042791803
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              144.8003616580052,
              13.513685339593195
            ],
            [
              144.44997190622448,
              13.929303843271725
            ],
            [
              138.59997605041642,
              17.39502263470061
            ],
            [
              131.84998083217639,
              21.635297384859456
            ],
            [
              128.6999830636644,
              23.711258142484382
            ],
            [
              127.57498386062443,
              24.941363171753835
            ],
            [
              127.12498417940836,
              25.95717997876443
            ],
            [
              127.12498417940836,
              26.562513149236622
            ],
            [
              128.02498354184027,
              28.55704546571141
            ],
            [
              128.24998338244833,
              29.540507745394393
            ],
            [
              128.92498290427224,
              30.514495959759188
            ],
            [
              129.0374828245762,
              31.67051304708704
            ],
            [
              129.59998242609637,
              33.565491482352144
            ],
            [
              130.04998210731227,
              33.93964008831958
            ],
            [
              130.45721181882723,
              34.03316211713022
            ],
            [
              131.0319114117045,
              33.83952016970099
            ]
          ],
          [
            [
              127.12498417940836,
              25.95717997876443
            ],
            [
              127.46248394032031,
              26.159307970773796
            ],
            [
              127.68084378563216,
              26.212414126750332
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "guernsey-jersey-4",
        "name": "Guernsey-Jersey-4",
        "color": "#3d7abe",
        "feature_id": "guernsey-jersey-4-0",
        "coordinates": [
          -2.389985809619816,
          49.31280629009663
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -2.201384204578886,
              49.24804518533196
            ],
            [
              -2.362424090496745,
              49.294684219425534
            ],
            [
              -2.558073951896546,
              49.42332570943023
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "gulf-bridge-international-cable-system-gbicsmiddle-east-north-africa-mena-cable-system",
        "name": "Gulf Bridge International Cable System (GBICS)/Middle East North Africa (MENA) Cable System",
        "color": "#b8d432",
        "feature_id": "gulf-bridge-international-cable-system-gbicsmiddle-east-north-africa-mena-cable-system-0",
        "coordinates": [
          64.39265203531767,
          22.007312917886935
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              49.27503932903963,
              28.951554732193216
            ],
            [
              49.16253940873568,
              28.853067255226364
            ]
          ],
          [
            [
              56.333724328601164,
              25.121690004958737
            ],
            [
              56.700034069103765,
              25.348717422116806
            ],
            [
              56.86878394955983,
              26.159307970773796
            ],
            [
              56.700034069103765,
              26.562513149236622
            ],
            [
              56.250034387887666,
              26.562513149236622
            ],
            [
              55.800034706671596,
              26.2097854122473
            ],
            [
              55.350035025455725,
              25.95717997876443
            ],
            [
              53.550036300591586,
              25.95717997876443
            ],
            [
              52.200037256943745,
              25.8559854660721
            ],
            [
              51.45198778686901,
              25.53892654713283
            ],
            [
              51.750037575727674,
              26.159307970773796
            ],
            [
              51.750037575727674,
              26.562513149236622
            ],
            [
              51.52503773511961,
              26.964304734562802
            ],
            [
              50.850038213295704,
              27.364667993860166
            ],
            [
              50.17503869147157,
              28.161052262220892
            ],
            [
              49.16253940873568,
              28.853067255226364
            ],
            [
              48.600039807215495,
              29.049948644465616
            ],
            [
              47.97484025011303,
              29.37410420420028
            ]
          ],
          [
            [
              56.333724328601164,
              25.121690004958737
            ],
            [
              57.09378379016769,
              25.348717422116806
            ],
            [
              57.09378379016769,
              26.159307970773796
            ],
            [
              56.9250339097116,
              26.562513149236622
            ],
            [
              56.250034387887666,
              26.763586569619832
            ],
            [
              55.800034706671596,
              26.512189502051882
            ],
            [
              55.350035025455725,
              26.512189502051882
            ],
            [
              53.550036300591586,
              26.461843796189072
            ],
            [
              53.100036619375715,
              26.562513149236622
            ],
            [
              52.42503709755158,
              26.964304734562802
            ],
            [
              51.750037575727674,
              27.364667993860166
            ],
            [
              50.400038532079606,
              28.55704546571141
            ],
            [
              49.27503932903963,
              28.951554732193216
            ],
            [
              48.82503964782373,
              29.540507745394393
            ],
            [
              48.531779855571585,
              29.92363278689706
            ]
          ],
          [
            [
              58.17620302337187,
              23.684877531684634
            ],
            [
              58.331282913511615,
              23.917101290935022
            ],
            [
              58.50003279396768,
              24.12261698700334
            ]
          ],
          [
            [
              50.656188350620795,
              26.241586178580576
            ],
            [
              51.07503805390374,
              26.562513149236622
            ],
            [
              51.52503773511961,
              26.964304734562802
            ]
          ],
          [
            [
              50.21419866373054,
              26.285375359318067
            ],
            [
              50.231288651623714,
              26.461843796189072
            ],
            [
              50.400038532079606,
              26.964304734562802
            ],
            [
              50.850038213295704,
              27.364667993860166
            ]
          ],
          [
            [
              50.842718218481366,
              28.970348642858426
            ],
            [
              50.400038532079606,
              28.55704546571141
            ]
          ],
          [
            [
              56.333724328601164,
              25.121690004958737
            ],
            [
              56.9250339097116,
              24.762719791019123
            ],
            [
              58.50003279396768,
              24.12261698700334
            ],
            [
              59.85003183761572,
              24.63495976683231
            ],
            [
              63.67502912795163,
              22.469443964829594
            ],
            [
              66.60002705585575,
              20.585819096040467
            ],
            [
              70.2000245055838,
              19.846840405431607
            ],
            [
              72.87590260996691,
              19.076074257285313
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "hantru1-cable-system",
        "name": "HANTRU1 Cable System",
        "color": "#45469c",
        "feature_id": "hantru1-cable-system-0",
        "coordinates": [
          155.96859490890475,
          9.858275424294789
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              159.07026154907427,
              7.786404758723677
            ],
            [
              158.84996170513668,
              8.635699417327544
            ],
            [
              158.84996170513668,
              9.524411345019587
            ]
          ],
          [
            [
              167.39995564824065,
              8.635699417327544
            ],
            [
              169.64995405432066,
              7.744889052551343
            ],
            [
              171.19624295891518,
              7.077763788446845
            ]
          ],
          [
            [
              144.69470173285575,
              13.464772962370034
            ],
            [
              144.84372162728843,
              13.273238157547667
            ],
            [
              145.34997126865645,
              12.944533868662859
            ],
            [
              146.24997063108842,
              12.395734000022884
            ],
            [
              147.14996999352056,
              12.17588718550806
            ],
            [
              149.39996839960057,
              11.294709319565555
            ],
            [
              151.1999671244645,
              10.410816505402636
            ],
            [
              158.84996170513668,
              9.524411345019587
            ],
            [
              163.7999581985126,
              9.08033076823304
            ],
            [
              167.39995564824065,
              8.635699417327544
            ],
            [
              167.40581564408942,
              9.1864874758231
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "hawaiki",
        "name": "Hawaiki",
        "color": "#3851a3",
        "feature_id": "hawaiki-0",
        "coordinates": [
          -169.4062918842406,
          -1.196005655510163
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              179.99994672228874,
              -23.905969261790176
            ],
            [
              173.69995118526478,
              -28.150316035846004
            ],
            [
              170.9999530979687,
              -29.137613161610005
            ],
            [
              166.94995596702458,
              -32.61276000573585
            ],
            [
              165.5999569233767,
              -34.11602012163186
            ]
          ],
          [
            [
              -173.69980271349004,
              -11.943944931746927
            ],
            [
              -172.07822386223182,
              -12.89421363936296
            ],
            [
              -170.99980462619405,
              -13.698987269610853
            ],
            [
              -170.6957048416213,
              -14.276544564158712
            ]
          ],
          [
            [
              -158.056894349398,
              21.335422205733266
            ],
            [
              -157.83731395062597,
              21.134806167482367
            ],
            [
              -157.61231411001802,
              21.134806167482367
            ],
            [
              -157.4998141897139,
              21.16977956388078
            ],
            [
              -152.99981737755388,
              25.348717422116806
            ],
            [
              -147.59982120296192,
              29.73606949729205
            ],
            [
              -138.5998275786418,
              34.31215165223537
            ],
            [
              -128.2498349106737,
              42.74371346443661
            ],
            [
              -125.09983714216165,
              45.172673246984374
            ],
            [
              -124.19983777972968,
              45.80361417369457
            ],
            [
              -123.52483825790574,
              45.80361417369457
            ],
            [
              -122.98980067585494,
              45.52289882456287
            ]
          ],
          [
            [
              -158.056894349398,
              21.335422205733266
            ],
            [
              -158.28731363184195,
              20.796306105108954
            ],
            [
              -159.07481307397,
              18.67864702215462
            ],
            [
              -161.99981100187404,
              13.054150695298716
            ],
            [
              -167.3998071764661,
              2.367912558705314
            ],
            [
              -171.44980430741003,
              -4.825692499217524
            ],
            [
              -172.79980335105807,
              -10.177457430361159
            ],
            [
              -173.69980271349004,
              -11.943944931746927
            ],
            [
              -176.39980080078612,
              -18.453813775777263
            ],
            [
              -179.99979825051417,
              -23.905969261790176
            ]
          ],
          [
            [
              174.57446056575415,
              -36.12614990708741
            ],
            [
              174.59995054769675,
              -35.59302880961411
            ],
            [
              174.14995086648068,
              -34.857839362235865
            ],
            [
              172.7999518228328,
              -34.11602012163186
            ],
            [
              165.5999569233767,
              -34.11602012163186
            ],
            [
              159.29996138635258,
              -35.04226072286545
            ],
            [
              155.69996393662453,
              -34.857839362235865
            ],
            [
              152.09996648689648,
              -34.11602012163186
            ],
            [
              151.20699711948444,
              -33.86955173177813
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "hawk",
        "name": "Hawk",
        "color": "#ad4599",
        "feature_id": "hawk-0",
        "coordinates": [
          15.65165193292981,
          34.373226284127924
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              32.46665123625965,
              34.76657169708608
            ],
            [
              31.500051920411693,
              34.157137999942535
            ],
            [
              29.645663234673975,
              32.84186407440686
            ],
            [
              28.8000538337115,
              32.33831157801282
            ]
          ],
          [
            [
              5.372530429989041,
              43.29362778902916
            ],
            [
              5.850070091695329,
              41.744358789482135
            ],
            [
              6.975069294735334,
              38.651811712711236
            ],
            [
              7.650068816559269,
              37.94551049545976
            ],
            [
              9.000067859611391,
              37.67887792909195
            ],
            [
              10.348617229769246,
              37.67887792909195
            ],
            [
              10.912566505375338,
              37.32187222983512
            ],
            [
              11.475066105703547,
              37.232354321556215
            ],
            [
              12.262565549023208,
              36.240655233214795
            ],
            [
              12.60006530993536,
              35.78566189952613
            ],
            [
              13.162564911457537,
              35.419780517080454
            ],
            [
              14.4000640347993,
              34.68301765985788
            ],
            [
              16.65006244087931,
              34.12610104005762
            ],
            [
              19.35006052817539,
              34.49779087043359
            ],
            [
              22.05005861487558,
              34.29666313310531
            ],
            [
              25.20005638398345,
              33.565491482352144
            ],
            [
              28.8000538337115,
              32.33831157801282
            ],
            [
              29.893513059094772,
              31.191465077638554
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "exa-north-and-south",
        "name": "EXA North and South",
        "color": "#4cb96a",
        "feature_id": "exa-north-and-south-0",
        "coordinates": [
          -5.766007052697006,
          55.06781714328207
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -70.9502755028152,
              42.46364601310964
            ],
            [
              -69.2998766713773,
              42.57825408607275
            ],
            [
              -65.69987922164925,
              42.74371346443661
            ],
            [
              -63.899880496785315,
              44.051519228735145
            ],
            [
              -63.5724907287112,
              44.653228704914795
            ],
            [
              -61.199882409489234,
              44.53466416326725
            ],
            [
              -50.39989006030518,
              46.73677946695426
            ],
            [
              -39.59989771112103,
              49.87814473780409
            ],
            [
              -23.39990918734489,
              54.03403825672413
            ],
            [
              -16.199914287888873,
              54.81936191424907
            ],
            [
              -12.599916838160823,
              55.33458061322915
            ],
            [
              -8.999919388432772,
              55.58970711313168
            ],
            [
              -6.676191034583809,
              55.415759313160976
            ],
            [
              -6.074921460528742,
              55.33458061322915
            ],
            [
              -4.949922257488737,
              54.36308597431912
            ],
            [
              -4.949922257488737,
              54.100057482410676
            ],
            [
              -4.837372337220188,
              53.96791403087386
            ],
            [
              -3.599923213840782,
              53.702365556682366
            ],
            [
              -3.006373634316702,
              53.64793339883286
            ],
            [
              -3.599923213840782,
              53.56895929103041
            ],
            [
              -5.624921779312757,
              53.36805813650224
            ],
            [
              -6.248311337697743,
              53.348124632595216
            ],
            [
              -5.624921779312757,
              53.23359531864921
            ],
            [
              -5.399921938704722,
              52.691501594646866
            ],
            [
              -5.624921779312757,
              52.14259270367222
            ],
            [
              -7.199920663568747,
              51.44682015166964
            ],
            [
              -10.799918113296798,
              50.740281893948264
            ],
            [
              -16.199914287888873,
              50.740281893948264
            ],
            [
              -23.39990918734489,
              51.30637567738265
            ],
            [
              -39.59989771112103,
              49.294684219425534
            ],
            [
              -50.39989006030518,
              46.116434772202325
            ],
            [
              -61.199882409489234,
              44.37405751055866
            ],
            [
              -63.5724907287112,
              44.653228704914795
            ]
          ],
          [
            [
              -6.676191034583809,
              55.13093005815972
            ],
            [
              -6.676191034583809,
              55.415759313160976
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "exa-express",
        "name": "EXA Express",
        "color": "#b03d2b",
        "feature_id": "exa-express-0",
        "coordinates": [
          -33.52168409534514,
          49.46815678918273
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -3.010863631136061,
              51.29364574899657
            ],
            [
              -4.049922895056767,
              51.44682015166964
            ],
            [
              -5.399921938704722,
              51.30637567738265
            ],
            [
              -7.199920663568747,
              51.30637567738265
            ],
            [
              -10.799918113296798,
              50.597677199053464
            ],
            [
              -16.199914287888873,
              50.45463912589386
            ],
            [
              -23.39990918734489,
              50.740281893948264
            ],
            [
              -39.59989771112103,
              48.70423463096077
            ],
            [
              -50.39989006030518,
              45.489027144440826
            ],
            [
              -61.199882409489234,
              44.21300917863164
            ],
            [
              -63.57581072635929,
              44.646178570763
            ]
          ],
          [
            [
              -8.472719761905978,
              51.89860855381119
            ],
            [
              -7.649920344784732,
              51.586833980054195
            ],
            [
              -7.199920663568747,
              51.30637567738265
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "high-capacity-undersea-guernsey-optical-fibre-hugo",
        "name": "High-capacity Undersea Guernsey Optical-fibre (HUGO)",
        "color": "#57bf9b",
        "feature_id": "high-capacity-undersea-guernsey-optical-fibre-hugo-0",
        "coordinates": [
          -4.141858113524648,
          49.67498805692733
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -2.537573966418904,
              49.50954697267542
            ],
            [
              -3.599923213840782,
              49.58728674004675
            ],
            [
              -4.499922576272752,
              49.73293362369091
            ],
            [
              -5.399921938704722,
              49.80559362880811
            ],
            [
              -5.654511758350935,
              50.04314791189421
            ]
          ],
          [
            [
              -2.558223951790211,
              49.42685110088382
            ],
            [
              -2.924923692016677,
              49.294684219425534
            ],
            [
              -3.459883313046362,
              48.87341683445018
            ],
            [
              -3.459883313046362,
              48.730552979168635
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "hokkaido-sakhalin-cable-system-hscs",
        "name": "Hokkaido-Sakhalin Cable System (HSCS)",
        "color": "#adc136",
        "feature_id": "hokkaido-sakhalin-cable-system-hscs-0",
        "coordinates": [
          141.2325731513499,
          44.947768328778835
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              141.31540412678189,
              43.171175265998414
            ],
            [
              141.07497429710443,
              43.564400497117504
            ],
            [
              141.07497429710443,
              44.051519228735145
            ],
            [
              141.2999741377125,
              45.33107107332478
            ],
            [
              141.63747389862445,
              46.272182853813746
            ],
            [
              141.8593537414428,
              46.68485289093087
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "honotua",
        "name": "Honotua",
        "color": "#ba8c34",
        "feature_id": "honotua-0",
        "coordinates": [
          -153.67260521721985,
          1.0339561627904459
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -155.8563559638703,
              20.003739828700503
            ],
            [
              -156.26231506696607,
              19.74098736552503
            ],
            [
              -156.59981482728193,
              19.104405475930548
            ],
            [
              -156.59981482728193,
              18.251816319028308
            ],
            [
              -154.79981610241802,
              5.957818681088611
            ],
            [
              -150.29981929025791,
              -13.698987269610853
            ],
            [
              -149.51231984812995,
              -17.16855309422607
            ],
            [
              -149.4410806705751,
              -17.51215245847922
            ],
            [
              -149.56857057763548,
              -17.433258753637716
            ],
            [
              -149.737319689334,
              -17.379584661169133
            ],
            [
              -149.82951038559526,
              -17.53831879598863
            ]
          ],
          [
            [
              -149.737319689334,
              -17.379584661169133
            ],
            [
              -150.07481944964997,
              -17.276008870126873
            ],
            [
              -150.74981897147407,
              -16.953454989810012
            ],
            [
              -151.0310695046416,
              -16.84581334779884
            ],
            [
              -150.99996952686274,
              -16.729596913676545
            ]
          ],
          [
            [
              -151.0310695046416,
              -16.84581334779884
            ],
            [
              -151.19981865269014,
              -16.736195168143666
            ],
            [
              -151.3685692570277,
              -16.6823203057713
            ],
            [
              -151.44297920183917,
              -16.73053543115003
            ]
          ],
          [
            [
              -151.3685692570277,
              -16.6823203057713
            ],
            [
              -151.48106917448973,
              -16.52060477978003
            ],
            [
              -151.64981833450202,
              -16.46666943640099
            ],
            [
              -151.74947897696904,
              -16.50585915193267
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "i2i-cable-network-i2icn",
        "name": "i2i Cable Network (i2icn)",
        "color": "#d86526",
        "feature_id": "i2i-cable-network-i2icn-0",
        "coordinates": [
          92.97396523862929,
          8.794167243172097
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              80.2429873910547,
              13.063853101883296
            ],
            [
              81.45001653598385,
              12.395734000022884
            ],
            [
              83.70001494206386,
              11.294709319565555
            ],
            [
              89.10001111665602,
              9.96791518697421
            ],
            [
              93.15000824759997,
              8.740828945067847
            ],
            [
              95.40000665367998,
              7.744889052551343
            ],
            [
              97.42500521915215,
              6.740481724921096
            ],
            [
              99.4500037846241,
              5.286069860821101
            ],
            [
              100.23750322675212,
              4.613591578862867
            ],
            [
              100.68750290796802,
              3.266814816815753
            ],
            [
              101.25000250948804,
              2.705081160335851
            ],
            [
              102.15000187192001,
              2.143087178471944
            ],
            [
              102.68279723997185,
              1.755006673795577
            ],
            [
              103.34065102845304,
              1.355886056053208
            ],
            [
              103.50000091556822,
              1.25729927808527
            ],
            [
              103.64609081207684,
              1.338585852071589
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "ingrid",
        "name": "INGRID",
        "color": "#41b549",
        "feature_id": "ingrid-0",
        "coordinates": [
          -2.3702775130576867,
          49.34057361627839
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -2.021314332142055,
              49.22527732021615
            ],
            [
              -1.916424406447106,
              49.25668077735244
            ],
            [
              -1.650024595167196,
              49.28328299965523
            ]
          ],
          [
            [
              -2.529413972199507,
              49.451047180325105
            ],
            [
              -2.304174131761641,
              49.294684219425534
            ],
            [
              -2.201384204578886,
              49.24804518533196
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "interchange-cable-network-1-icn1",
        "name": "Interchange Cable Network 1 (ICN1)",
        "color": "#cd2b42",
        "feature_id": "interchange-cable-network-1-icn1-0",
        "coordinates": [
          173.4144568591635,
          -18.24880002598107
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              168.32300499434402,
              -17.730502326180016
            ],
            [
              168.63745477158457,
              -17.919416202114803
            ],
            [
              169.1999543731046,
              -18.026426383713385
            ],
            [
              177.29994863499283,
              -18.453813775777263
            ],
            [
              178.0874480765248,
              -18.453813775777263
            ],
            [
              178.43744782917761,
              -18.12381094353711
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "ip-only-denmark-sweden",
        "name": "IP-Only Denmark-Sweden",
        "color": "#3465b0",
        "feature_id": "ip-only-denmark-sweden-0",
        "coordinates": [
          12.642148694188222,
          55.526080187888624
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              12.415005441033458,
              55.647554790974624
            ],
            [
              12.487565389631243,
              55.55790652931407
            ],
            [
              12.60006530993536,
              55.526080187888624
            ],
            [
              12.712565230239278,
              55.526080187888624
            ],
            [
              12.916665085653108,
              55.53356806062777
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "italy-albania",
        "name": "Italy-Albania",
        "color": "#a8842d",
        "feature_id": "italy-albania-0",
        "coordinates": [
          18.160839301623355,
          41.238752328966655
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              16.86881228591494,
              41.125709058522546
            ],
            [
              18.00006148452735,
              41.23875232896668
            ],
            [
              19.125060687567355,
              41.23875232896668
            ],
            [
              19.4500604573345,
              41.31691028026597
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "italy-croatia",
        "name": "Italy-Croatia",
        "color": "#c23f2f",
        "feature_id": "italy-croatia-0",
        "coordinates": [
          12.86304544552965,
          45.33107107332478
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              12.257409117404194,
              45.496082427884346
            ],
            [
              12.376065468618947,
              45.33107107332478
            ],
            [
              13.275064831759295,
              45.33107107332478
            ],
            [
              13.533478213424985,
              45.433276695186294
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "italy-greece-1-ig-1",
        "name": "Italy-Greece 1 (IG-1)",
        "color": "#55b847",
        "feature_id": "italy-greece-1-ig-1-0",
        "coordinates": [
          19.282686617727137,
          39.95054065921238
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              18.485701140495706,
              40.14820187022195
            ],
            [
              18.675061006351456,
              40.04369219282995
            ],
            [
              19.80006020939146,
              39.871225135616044
            ],
            [
              19.968810089847352,
              39.7847901333038
            ],
            [
              19.968810089847352,
              39.741589529321175
            ],
            [
              20.01546005680015,
              39.68362591227577
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "italy-libya",
        "name": "Italy-Libya",
        "color": "#2767b2",
        "feature_id": "italy-libya-0",
        "coordinates": [
          12.848474053890214,
          35.26510367864702
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              12.591375316091586,
              37.650586172786205
            ],
            [
              12.543815349783216,
              37.232354321556215
            ],
            [
              12.60006530993536,
              36.87321951208918
            ],
            [
              12.825065150547204,
              35.419780517080454
            ],
            [
              13.162564911455348,
              33.18971466460036
            ],
            [
              13.187364893886866,
              32.87762290319543
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "italy-monaco",
        "name": "Italy-Monaco",
        "color": "#51459c",
        "feature_id": "italy-monaco-0",
        "coordinates": [
          8.09860957318611,
          43.80787484134072
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              7.426728974775415,
              43.73825568793552
            ],
            [
              7.875068657167304,
              43.72721479104973
            ],
            [
              8.325068338383204,
              43.889587736299546
            ],
            [
              8.483758225965857,
              44.305748230542434
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "jaka2ladema",
        "name": "JaKa2LaDeMa",
        "color": "#4a499e",
        "feature_id": "jaka2ladema-0",
        "coordinates": [
          109.57936272970508,
          -2.5891805154390397
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              117.5510609616654,
              0.327276576000315
            ],
            [
              117.89999071448025,
              0.118588418888407
            ],
            [
              119.2499897581283,
              -1.006358951224712
            ],
            [
              119.38846966002788,
              -1.142074966989412
            ]
          ],
          [
            [
              111.14999549624022,
              -3.479268678969987
            ],
            [
              112.04999485867219,
              -3.928327304142726
            ],
            [
              112.94999422110416,
              -3.928327304142726
            ],
            [
              114.0749934241442,
              -3.703826470668162
            ],
            [
              114.60399304939611,
              -3.327586828573203
            ]
          ],
          [
            [
              109.97196633076723,
              -1.85872398669315
            ],
            [
              109.57499661198418,
              -1.906058394384871
            ],
            [
              109.34999677137611,
              -2.130918480960247
            ]
          ],
          [
            [
              109.33554678161275,
              -0.027021392288191
            ],
            [
              108.89999709016004,
              -0.331409329660175
            ],
            [
              109.12499693076828,
              -1.681168935905106
            ],
            [
              109.34999677137611,
              -2.130918480960247
            ],
            [
              109.79999645259218,
              -3.029995968008762
            ],
            [
              110.47499597441615,
              -3.479268678969987
            ],
            [
              111.14999549624022,
              -3.479268678969987
            ],
            [
              111.59999517745612,
              -3.254657364797595
            ],
            [
              111.75292506911941,
              -2.849274293012257
            ]
          ],
          [
            [
              114.09716340843894,
              -8.615167135560483
            ],
            [
              114.41249318505615,
              -8.893723131858536
            ],
            [
              114.97499278657611,
              -8.893723131858536
            ],
            [
              115.22152261133627,
              -8.656661268449483
            ]
          ],
          [
            [
              116.08153200269348,
              -8.585998888200606
            ],
            [
              115.8749921490083,
              -8.566064314426171
            ],
            [
              115.53749238809613,
              -8.595852074426745
            ],
            [
              115.33144253346805,
              -8.536714291275615
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "jakabare",
        "name": "JAKABARE",
        "color": "#33af85",
        "feature_id": "jakabare-0",
        "coordinates": [
          107.05910632420759,
          -1.522079247933675
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              104.84999995921609,
              1.355961034999325
            ],
            [
              104.62500011860826,
              1.355961034999325
            ],
            [
              104.20156041857692,
              1.328818505780021
            ],
            [
              103.9870105705659,
              1.389451396800126
            ]
          ],
          [
            [
              104.13320046700372,
              1.173685663377114
            ],
            [
              104.28790035741284,
              1.201587148227134
            ],
            [
              104.62500011860826,
              1.299726182129234
            ],
            [
              104.84999995921609,
              1.355961034999325
            ],
            [
              105.29999964043216,
              1.243490076978134
            ],
            [
              106.87499852468827,
              -0.331409329660175
            ],
            [
              107.15324832757338,
              -2.130918480960247
            ],
            [
              107.26974824504398,
              -3.029995968008762
            ],
            [
              107.54999804651217,
              -4.601453764837203
            ],
            [
              107.0999983652961,
              -5.273944363641391
            ],
            [
              107.12099835041957,
              -5.981154260263196
            ]
          ],
          [
            [
              109.18222689022608,
              -0.061391357195134
            ],
            [
              108.89999709016004,
              0.006088583243098
            ],
            [
              106.87499852468827,
              -0.331409329660175
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "janna",
        "name": "Janna",
        "color": "#ba9f39",
        "feature_id": "janna-0",
        "coordinates": [
          10.744941686900233,
          38.192000756395146
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              9.109447782721531,
              39.215608817480586
            ],
            [
              9.45006754142321,
              38.82731109526628
            ],
            [
              11.70006594750339,
              37.72338353997506
            ],
            [
              12.591375316091586,
              37.650586172786205
            ]
          ],
          [
            [
              9.49644750797134,
              40.92357574660872
            ],
            [
              9.900067222043361,
              41.097606154138276
            ],
            [
              10.575066743867296,
              41.43584595024919
            ],
            [
              11.25006626628732,
              41.744358789482135
            ],
            [
              11.796845878943572,
              42.09141425047335
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "japan-u-s-s1-jus-s1",
        "name": "Japan-U.S. S1 (JUS-S1)",
        "color": "#ae4b9c",
        "feature_id": "japan-u-s-s1-jus-s1-0",
        "coordinates": [
          -139.69165266568973,
          27.9671271130444
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -158.22066328843272,
              21.463446823448095
            ],
            [
              -158.39981354930399,
              21.635297384859456
            ],
            [
              -158.399812452297,
              21.73983373091116
            ],
            [
              -158.17481371153804,
              22.05298561667763
            ],
            [
              -157.94981387093,
              22.261369678340685
            ],
            [
              -152.99981737755388,
              24.532657566160623
            ],
            [
              -147.59982120296192,
              26.562513149236622
            ],
            [
              -138.5998275786418,
              28.161052262220892
            ],
            [
              -127.79983522945773,
              32.052708023486105
            ],
            [
              -122.39983905486591,
              34.68301765985788
            ],
            [
              -120.84720164908995,
              35.367078251717174
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "jerry-newton",
        "name": "Jerry Newton",
        "color": "#4365af",
        "feature_id": "jerry-newton-0",
        "coordinates": [
          -68.59059221921494,
          12.158312080067503
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -68.28414739152548,
              12.163814736152517
            ],
            [
              -68.51237722924927,
              12.17588718550806
            ],
            [
              -68.89264695986272,
              12.090439618305055
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "jonah",
        "name": "Jonah",
        "color": "#43b549",
        "feature_id": "jonah-0",
        "coordinates": [
          24.521713238387406,
          34.790471557559954
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              34.769679604772676,
              32.04501185826473
            ],
            [
              33.75005032708759,
              32.52821504536482
            ],
            [
              31.050052239791512,
              33.283811019051
            ],
            [
              25.20005638398345,
              34.61360601019041
            ],
            [
              23.400057658523423,
              35.082922700290226
            ],
            [
              22.05005861547147,
              35.876870570092734
            ],
            [
              19.80006020939146,
              37.67887792909195
            ],
            [
              19.18276064669223,
              39.48474996079955
            ],
            [
              18.90006084695932,
              40.04369219282995
            ],
            [
              18.787560926655374,
              40.387320290775165
            ],
            [
              18.00006148452735,
              40.98447035812865
            ],
            [
              16.86881228591494,
              41.125709058522546
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "kattegat-2",
        "name": "Kattegat 2",
        "color": "#7fc241",
        "feature_id": "kattegat-2-0",
        "coordinates": [
          11.914794736272313,
          56.93701515888509
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              10.514616787286627,
              57.24491811100583
            ],
            [
              10.687566664767303,
              57.23857647500998
            ],
            [
              10.966956466845033,
              57.272647240874335
            ]
          ],
          [
            [
              11.199286302260361,
              57.31375088583721
            ],
            [
              11.70006594750339,
              57.02488552657641
            ],
            [
              12.15006562871929,
              56.840738642145595
            ],
            [
              12.68336525092488,
              56.683369081239334
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "kodiak-kenai-fiber-link-kkfl",
        "name": "Kodiak Kenai Fiber Link (KKFL)",
        "color": "#36bcac",
        "feature_id": "kodiak-kenai-fiber-link-kkfl-0",
        "coordinates": [
          -151.64981833390584,
          58.401267347419406
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -149.44767066574025,
              60.11004931326181
            ],
            [
              -149.39981992782594,
              59.90606992457441
            ],
            [
              -149.39981992782594,
              59.6796637072089
            ],
            [
              -149.84981960904193,
              59.451717318905224
            ],
            [
              -151.19981865268997,
              58.99117670269845
            ],
            [
              -151.42481849329792,
              58.52439396084483
            ],
            [
              -151.42481849329792,
              58.05131589106017
            ],
            [
              -151.8748181745139,
              57.571890279005004
            ],
            [
              -152.32906855174068,
              57.42449129028316
            ],
            [
              -152.09981801512194,
              57.571890279005004
            ],
            [
              -152.09981801512194,
              57.69234458026085
            ],
            [
              -152.39517850323767,
              57.79442221787269
            ],
            [
              -152.09981801512194,
              57.812399750516676
            ],
            [
              -151.64981833390584,
              58.05131589106017
            ],
            [
              -151.64981833390584,
              58.52439396084483
            ],
            [
              -152.32481785572998,
              59.1068949571908
            ],
            [
              -152.32481785572998,
              59.50884868221257
            ],
            [
              -152.09981801512194,
              59.56588346342974
            ],
            [
              -151.5442491275403,
              59.646565622018784
            ],
            [
              -152.09981801512194,
              59.6796637072089
            ],
            [
              -152.09981801512194,
              59.90606992457441
            ],
            [
              -151.64981833390584,
              60.35428947498107
            ],
            [
              -151.2687186038806,
              60.553102538800744
            ],
            [
              -151.59185909261018,
              60.70906894445227
            ],
            [
              -151.59185909261018,
              60.867466201164945
            ],
            [
              -150.2334100892656,
              61.217558335568384
            ],
            [
              -149.85841036439217,
              61.217558335568384
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "korea-japan-cable-network-kjcn",
        "name": "Korea-Japan Cable Network (KJCN)",
        "color": "#b32d25",
        "feature_id": "korea-japan-cable-network-kjcn-0",
        "coordinates": [
          129.12059853162665,
          35.10111055511703
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              131.0319114117045,
              33.83952016970099
            ],
            [
              130.49998178852834,
              34.31215165223537
            ],
            [
              128.99949285148878,
              35.17037876180012
            ],
            [
              130.04998210731227,
              34.31215165223537
            ],
            [
              130.40164185819341,
              33.59022724332917
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "kuwait-iran",
        "name": "Kuwait-Iran",
        "color": "#c72379",
        "feature_id": "kuwait-iran-0",
        "coordinates": [
          49.26005253379532,
          29.34456698948989
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              49.950038850863734,
              29.442584645837485
            ],
            [
              50.31204859441263,
              29.246027433733815
            ]
          ],
          [
            [
              49.500039169647664,
              29.34456698948989
            ],
            [
              49.478069185211325,
              29.072736419111635
            ]
          ],
          [
            [
              47.97484025011303,
              29.37410420420028
            ],
            [
              48.48753988691158,
              29.34456698948989
            ],
            [
              49.500039169647664,
              29.34456698948989
            ],
            [
              49.950038850863734,
              29.442584645837485
            ],
            [
              50.52112844629863,
              29.570753352727053
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "lanis-1",
        "name": "Lanis-1",
        "color": "#53bc84",
        "feature_id": "lanis-1-0",
        "coordinates": [
          -3.8299893271552037,
          53.89757264331388
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -3.050753602877677,
              53.80897597127557
            ],
            [
              -3.599923213840782,
              53.868530644567244
            ],
            [
              -4.387222656110339,
              53.96791403087386
            ],
            [
              -4.566622529021913,
              54.10028616652036
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "lanis-2",
        "name": "Lanis-2",
        "color": "#324ca0",
        "feature_id": "lanis-2-0",
        "coordinates": [
          -5.080617264613153,
          54.408690786113596
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -4.691422440612428,
              54.222445613689786
            ],
            [
              -4.791422369771567,
              54.28083898900164
            ],
            [
              -5.384291949777236,
              54.54294403514453
            ],
            [
              -5.484341878900892,
              54.54294403514453
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "latvia-sweden-1-lv-se-1",
        "name": "Latvia-Sweden 1 (LV-SE 1)",
        "color": "#43b549",
        "feature_id": "latvia-sweden-1-lv-se-1-0",
        "coordinates": [
          19.67056714864438,
          58.014915360983906
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              21.570078955493614,
              57.389720408424836
            ],
            [
              21.15005925303933,
              57.4510360539646
            ],
            [
              19.575060368783426,
              58.05131589106017
            ],
            [
              18.45006116574342,
              58.40671668748652
            ],
            [
              18.00006148452735,
              58.75856894488285
            ],
            [
              17.946541522441493,
              58.90309907219313
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "lfon-libyan-fiber-optic-network",
        "name": "LFON (Libyan Fiber Optic Network)",
        "color": "#36af8a",
        "feature_id": "lfon-libyan-fiber-optic-network-0",
        "coordinates": [
          18.62786938044954,
          30.686408759572714
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              12.083365675970299,
              32.93354641183947
            ],
            [
              12.375065469327325,
              33.00121852265437
            ],
            [
              12.529855359672894,
              32.774885686861666
            ],
            [
              12.642240573027038,
              32.96372252942484
            ],
            [
              13.05006499115126,
              33.00121852265437
            ],
            [
              13.187364893886866,
              32.87762290319543
            ],
            [
              13.50006467236733,
              33.00121852265437
            ],
            [
              14.175064194191435,
              32.81231878328768
            ],
            [
              14.264514130824125,
              32.649989370963
            ],
            [
              14.4000640347993,
              32.81231878328768
            ],
            [
              14.85006371601537,
              32.81231878328768
            ],
            [
              15.075063556623405,
              32.62301664000799
            ],
            [
              15.094983542511955,
              32.37460980808484
            ],
            [
              15.30006339723144,
              32.33831157801282
            ],
            [
              16.425062600271445,
              31.478822672736243
            ],
            [
              16.588442484531726,
              31.20558735149686
            ],
            [
              16.875062281487345,
              31.28673881439167
            ],
            [
              17.55006180331145,
              31.28673881439167
            ],
            [
              18.225061325135385,
              30.901396088515583
            ],
            [
              18.411971192726753,
              30.586989976235827
            ],
            [
              18.675061006351456,
              30.70813999354155
            ],
            [
              19.35006052817539,
              30.70813999354155
            ],
            [
              19.57652036774914,
              30.378447643853804
            ],
            [
              19.575060368783426,
              30.70813999354155
            ],
            [
              19.80006020939146,
              31.861808602270827
            ],
            [
              20.066760020458872,
              32.11691354443619
            ],
            [
              20.025060049999325,
              32.43331330641712
            ],
            [
              20.25005989060736,
              32.717717936758305
            ],
            [
              20.70005957182343,
              32.81231878328768
            ],
            [
              20.949959394792188,
              32.71697755177149
            ],
            [
              21.15005925303933,
              33.00121852265437
            ],
            [
              21.6000589342554,
              33.00121852265437
            ],
            [
              21.741758833873917,
              32.882141080671744
            ],
            [
              22.05005861547147,
              33.00121852265437
            ],
            [
              22.50005829668737,
              33.00121852265437
            ],
            [
              22.63911819817619,
              32.76363502668917
            ],
            [
              22.95005797790344,
              32.81231878328768
            ],
            [
              23.85005734033541,
              32.43331330641712
            ],
            [
              23.960407262162676,
              32.07985133144845
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "libreville-port-gentil-cable",
        "name": "Libreville-Port Gentil Cable",
        "color": "#48c2c9",
        "feature_id": "libreville-port-gentil-cable-0",
        "coordinates": [
          8.90976872924449,
          -0.003377499758215663
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              8.781608014966395,
              -0.720651899991594
            ],
            [
              8.775049269612765,
              -0.331409329660175
            ],
            [
              9.020377845819638,
              0.265947444189607
            ],
            [
              9.454267538448192,
              0.394465191855375
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "lower-indian-ocean-network-lion",
        "name": "Lower Indian Ocean Network (LION)",
        "color": "#c64d2b",
        "feature_id": "lower-indian-ocean-network-lion-0",
        "coordinates": [
          53.69753767143021,
          -19.685595665633713
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              57.5100934952502,
              -20.077536205633123
            ],
            [
              56.700034069103765,
              -20.15254378601884
            ],
            [
              55.800034706671596,
              -20.469058485457424
            ],
            [
              55.57503486606376,
              -20.57441905727606
            ],
            [
              55.54778488536789,
              -20.89726651520496
            ],
            [
              55.350035025455725,
              -20.57441905727606
            ],
            [
              52.200037256943745,
              -18.880139975101287
            ],
            [
              49.40023924034699,
              -18.146086570227396
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "lower-indian-ocean-network-2-lion2",
        "name": "Lower Indian Ocean Network 2 (LION2)",
        "color": "#973a95",
        "feature_id": "lower-indian-ocean-network-2-lion2-0",
        "coordinates": [
          46.247408018834335,
          -11.943944931746927
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              45.450042038703714,
              -11.943944931746927
            ],
            [
              45.16576224009026,
              -12.81709644553311
            ]
          ],
          [
            [
              39.70014611198383,
              -4.050300939496424
            ],
            [
              42.30004427019156,
              -5.049857167366866
            ],
            [
              43.200043632623704,
              -5.721872747834027
            ],
            [
              45.450042038703714,
              -11.943944931746927
            ],
            [
              47.250040763567654,
              -11.943944931746927
            ],
            [
              48.600039807215495,
              -11.50333384598423
            ],
            [
              49.500039169647664,
              -11.50333384598423
            ],
            [
              50.400038532079606,
              -12.383840433185668
            ],
            [
              51.750037575727674,
              -17.16855309422607
            ],
            [
              52.200037256943745,
              -18.880139975101287
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "mainone",
        "name": "MainOne",
        "color": "#603f98",
        "feature_id": "mainone-0",
        "coordinates": [
          -19.280078359401585,
          10.541976821281878
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -3.599923213840782,
              0.118588418888407
            ],
            [
              -3.924922983607843,
              1.468426767332062
            ],
            [
              -4.049922895056767,
              2.367912558705314
            ],
            [
              -4.049922895056767,
              3.266814816815753
            ],
            [
              -4.026242911831901,
              5.323508791824736
            ]
          ],
          [
            [
              -19.803965545293096,
              15.23578178303569
            ],
            [
              -18.449912693968884,
              15.23578178303569
            ],
            [
              -17.44571340535299,
              14.686594841995088
            ]
          ],
          [
            [
              -0.204315619320994,
              5.558285889905761
            ],
            [
              0.000074235887269,
              3.279837005485092
            ],
            [
              0.000074235887269,
              0.806604849908751
            ]
          ],
          [
            [
              -9.107439312264688,
              38.64265833034625
            ],
            [
              -9.449919069648843,
              38.21117903702327
            ],
            [
              -9.787418830560796,
              37.232354321556215
            ],
            [
              -10.124918591472778,
              35.78566189952613
            ],
            [
              -10.574918272688848,
              33.93964008831958
            ],
            [
              -13.949915881808863,
              29.73606949729205
            ],
            [
              -14.399915563024848,
              28.951554732193216
            ],
            [
              -14.624915403632798,
              28.161052262220892
            ],
            [
              -15.074915084848868,
              26.964304734562802
            ],
            [
              -18.449912693968884,
              22.884654113882362
            ],
            [
              -19.799911737616924,
              19.952622905164304
            ],
            [
              -19.804120576691247,
              15.23578178303569
            ],
            [
              -19.799911737616924,
              11.735650161405744
            ],
            [
              -18.449912693968884,
              8.635699417327544
            ],
            [
              -13.949915881808863,
              3.266814816815753
            ],
            [
              -10.799918113296798,
              0.118588418888407
            ],
            [
              -3.599923213840782,
              0.118588418888407
            ],
            [
              0.000074235887269,
              0.806604849908751
            ],
            [
              1.575073120143173,
              1.918228780215685
            ],
            [
              2.250072641967279,
              2.480311786858834
            ],
            [
              3.150072004399249,
              4.164912849976844
            ],
            [
              3.423511810692077,
              6.439066911484701
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "malaysia-cambodia-thailand-mct-cable",
        "name": "Malaysia-Cambodia-Thailand (MCT) Cable",
        "color": "#b7922e",
        "feature_id": "malaysia-cambodia-thailand-mct-cable-0",
        "coordinates": [
          103.6480647779089,
          7.308585029265686
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              103.50000091556822,
              8.190543417795567
            ],
            [
              101.92500203131218,
              9.524411345019587
            ],
            [
              101.25000250948804,
              11.294709319565555
            ],
            [
              101.1375025891841,
              12.17588718550806
            ],
            [
              101.27734249012033,
              12.670662156670659
            ]
          ],
          [
            [
              103.50674091079347,
              10.630401703210367
            ],
            [
              103.61250083587217,
              9.524411345019587
            ],
            [
              103.50000091556822,
              8.190543417795567
            ],
            [
              103.95000059678412,
              5.510071711803135
            ],
            [
              103.72500075617612,
              4.613591578862867
            ],
            [
              103.39521098980222,
              4.116310078259517
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "mariana-guam-cable",
        "name": "Mariana-Guam Cable",
        "color": "#5e479c",
        "feature_id": "mariana-guam-cable-0",
        "coordinates": [
          145.40516004909395,
          14.362316164146677
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              145.75114098446412,
              15.17820120186434
            ],
            [
              145.79997094987252,
              15.070969022342098
            ],
            [
              145.63747106498883,
              15.011508499399383
            ],
            [
              145.6874710295684,
              14.801154224791475
            ],
            [
              145.40822122739164,
              14.365653759228536
            ],
            [
              145.21247136606272,
              14.152228172439436
            ],
            [
              145.12497142804838,
              13.929303843271725
            ],
            [
              145.0124715077445,
              13.710817738179543
            ],
            [
              144.80954165150203,
              13.549094363149067
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "mataram-kupang-cable-system-mkcs",
        "name": "Mataram Kupang Cable System (MKCS)",
        "color": "#d4254a",
        "feature_id": "mataram-kupang-cable-system-mkcs-0",
        "coordinates": [
          121.44644788312527,
          -9.06830600387434
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              118.08222058479092,
              -8.84114915636195
            ],
            [
              117.89999071388436,
              -8.586582275145616
            ],
            [
              117.56249095297241,
              -8.364039699657866
            ],
            [
              117.33749111236435,
              -8.17849027894485
            ],
            [
              117.22499119265612,
              -7.732822794391694
            ]
          ],
          [
            [
              116.65492159590289,
              -8.537099969976481
            ],
            [
              116.54999167023632,
              -8.252720521975078
            ],
            [
              116.54999167023632,
              -8.029988442955243
            ],
            [
              116.77499151084433,
              -7.807134147543906
            ],
            [
              117.22499119265612,
              -7.732822794391694
            ],
            [
              118.2374904753922,
              -7.732822794391694
            ],
            [
              118.57499023630416,
              -7.955717094334744
            ],
            [
              118.76036010439051,
              -8.319317925115513
            ],
            [
              118.77186509624042,
              -8.391864542982574
            ],
            [
              118.74490011534257,
              -8.464269028556998
            ]
          ],
          [
            [
              120.25301904757285,
              -9.645765890160561
            ],
            [
              120.59998880177616,
              -9.290424301035614
            ],
            [
              120.59998880177616,
              -9.06830600387434
            ]
          ],
          [
            [
              123.58338668830928,
              -10.182939736570972
            ],
            [
              123.29998688907224,
              -10.011319800856569
            ],
            [
              122.84998720726028,
              -9.918984265715636
            ],
            [
              121.72498800422042,
              -9.179382545871192
            ],
            [
              121.64273806248687,
              -8.845694469061982
            ],
            [
              121.4999881642083,
              -9.06830600387434
            ],
            [
              120.59998880177616,
              -9.06830600387434
            ],
            [
              119.2499897581283,
              -9.06830600387434
            ],
            [
              118.71181013937976,
              -8.79644401019425
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "matrix-cable-system",
        "name": "Matrix Cable System",
        "color": "#b0c335",
        "feature_id": "matrix-cable-system-0",
        "coordinates": [
          107.11651447578888,
          -2.2750520620072336
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              103.96260058785819,
              1.134723598626692
            ],
            [
              104.01992054725221,
              1.212637187567935
            ],
            [
              104.16611044369009,
              1.230603090362649
            ],
            [
              104.28790035741284,
              1.187552709061862
            ],
            [
              104.62500011860826,
              1.187252773694183
            ],
            [
              104.84999995921609,
              1.131014326431796
            ],
            [
              105.29999964043216,
              1.131014326431796
            ]
          ],
          [
            [
              105.29999964043216,
              1.131014326431796
            ],
            [
              104.84999995921609,
              1.243490076978134
            ],
            [
              104.62500011860826,
              1.229455855593812
            ],
            [
              104.19443042362789,
              1.295159856377438
            ],
            [
              103.9870105705659,
              1.389451396800126
            ]
          ],
          [
            [
              106.83339855415792,
              -6.171588071824203
            ],
            [
              106.87499852468827,
              -5.273944363641391
            ],
            [
              107.32499820590417,
              -4.601453764837203
            ],
            [
              107.21349828489204,
              -3.029995968008762
            ],
            [
              107.0979983667132,
              -2.130918480960247
            ],
            [
              106.76249860438415,
              -0.331409329660175
            ],
            [
              105.29999964043216,
              1.131014326431796
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "maya-1",
        "name": "Maya-1",
        "color": "#934499",
        "feature_id": "maya-1-0",
        "coordinates": [
          -83.45467702366884,
          18.236931910672098
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -80.16016897784436,
              26.01054866801087
            ],
            [
              -79.64986933934539,
              25.348717422116806
            ],
            [
              -79.87486917995342,
              24.73717827217618
            ],
            [
              -80.99986838299351,
              23.711258142484382
            ],
            [
              -83.24986678907334,
              23.711258142484382
            ],
            [
              -84.82486567332933,
              22.67720619658283
            ],
            [
              -85.2748653545454,
              21.635297384859456
            ],
            [
              -85.49986519515335,
              20.375041253465525
            ],
            [
              -84.82486567332933,
              19.104405475930548
            ],
            [
              -82.12486758603333,
              17.39502263470061
            ],
            [
              -80.77486854238529,
              16.10232559580288
            ],
            [
              -80.54986870177734,
              13.929303843271725
            ],
            [
              -80.09986902056127,
              13.054150695298716
            ],
            [
              -78.29987029569733,
              10.632033208117747
            ],
            [
              -76.4998715708334,
              10.078698006650882
            ],
            [
              -75.55866223759489,
              9.496381979983852
            ]
          ],
          [
            [
              -86.76758665233308,
              21.095728792367282
            ],
            [
              -86.51236447788934,
              21.163951238165083
            ],
            [
              -86.17486471697737,
              21.32123529551194
            ],
            [
              -85.2748653545454,
              21.635297384859456
            ]
          ],
          [
            [
              -83.03765938887453,
              9.988597517410057
            ],
            [
              -82.91236702816137,
              10.410816505402636
            ],
            [
              -80.77486854238529,
              11.95585820711483
            ],
            [
              -80.09986902056127,
              13.054150695298716
            ]
          ],
          [
            [
              -87.94615578765044,
              15.844981598742493
            ],
            [
              -87.29986392001756,
              16.53419619825962
            ],
            [
              -85.49986519515353,
              18.251816319028308
            ],
            [
              -84.82486567332933,
              19.104405475930548
            ]
          ],
          [
            [
              -82.12486758603333,
              17.39502263470061
            ],
            [
              -81.16676076149959,
              19.282955912662572
            ]
          ],
          [
            [
              -79.75352926591177,
              9.437623338483888
            ],
            [
              -79.1998696581294,
              9.96791518697421
            ],
            [
              -78.29987029569733,
              10.632033208117747
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "med-cable-network",
        "name": "Med Cable Network",
        "color": "#3d80bf",
        "feature_id": "med-cable-network-0",
        "coordinates": [
          2.922309022542237,
          36.90995314592746
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              7.755438741914361,
              36.90282046530186
            ],
            [
              7.650068816559269,
              37.232354321556215
            ],
            [
              6.300069772911229,
              38.651811712711236
            ],
            [
              5.512570330783177,
              41.744358789482135
            ],
            [
              5.372530429989041,
              43.29362778902916
            ]
          ],
          [
            [
              6.300069772911229,
              38.651811712711236
            ],
            [
              5.400070410479259,
              38.21117903702327
            ],
            [
              3.150072004399249,
              37.05299936423356
            ],
            [
              3.035712085412854,
              36.76212778210993
            ],
            [
              2.812572243487267,
              37.05299936423356
            ],
            [
              1.800072960751208,
              37.05299936423356
            ],
            [
              0.000074235887269,
              36.33133835588808
            ],
            [
              -0.642015309250468,
              35.701641134808256
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "melita-1",
        "name": "Melita 1",
        "color": "#824c9e",
        "feature_id": "melita-1-0",
        "coordinates": [
          14.706025675533994,
          36.312829675470994
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              14.458753993222814,
              35.93413648626421
            ],
            [
              14.625063875407335,
              36.14986678681771
            ],
            [
              14.85006371601537,
              36.60275474032986
            ],
            [
              14.853873713316574,
              36.733882871591675
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "mid-atlantic-crossing-mac",
        "name": "Mid-Atlantic Crossing (MAC)",
        "color": "#5bba46",
        "feature_id": "mid-atlantic-crossing-mac-0",
        "coordinates": [
          -65.33994891192256,
          19.089598131162404
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -74.2498731647534,
              33.565491482352144
            ],
            [
              -75.59987220840135,
              30.901396088515583
            ],
            [
              -77.39987093326538,
              28.951554732193216
            ],
            [
              -79.1998696581294,
              26.964304734562802
            ],
            [
              -79.64986933934539,
              26.361086325391653
            ],
            [
              -80.16016897784436,
              26.01054866801087
            ],
            [
              -79.64986933934539,
              26.26024097157773
            ],
            [
              -78.97486981752137,
              26.964304734562802
            ],
            [
              -77.84987061448136,
              27.663994423746914
            ],
            [
              -76.94987125204939,
              27.763588526057674
            ],
            [
              -73.34987380232134,
              26.964304734562802
            ],
            [
              -69.2998766713773,
              24.941363171753835
            ],
            [
              -67.0498782652973,
              22.05298561667763
            ],
            [
              -65.92487906225729,
              20.375041253465525
            ],
            [
              -65.24987954043327,
              18.89166158430325
            ],
            [
              -65.19362958028121,
              18.251816319028308
            ],
            [
              -64.81925984548829,
              17.773909269375793
            ],
            [
              -64.57488001860925,
              18.0914826524251
            ],
            [
              -64.23738025769728,
              18.358623372153403
            ],
            [
              -64.23738025769728,
              18.89166158430325
            ],
            [
              -64.59988000089903,
              20.91311676631949
            ],
            [
              -65.24987954043327,
              24.941363171753835
            ],
            [
              -66.59987858408131,
              28.161052262220892
            ],
            [
              -72.44987443988929,
              38.651811712711236
            ],
            [
              -72.9122741123211,
              40.773520734289946
            ],
            [
              -72.67487428049732,
              38.651811712711236
            ],
            [
              -74.2498731647534,
              33.565491482352144
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "moratelindo-international-cable-system-1-mic-1",
        "name": "Moratelindo International Cable System-1 (MIC-1)",
        "color": "#344fa2",
        "feature_id": "moratelindo-international-cable-system-1-mic-1-0",
        "coordinates": [
          103.98140354665279,
          1.2606675774959228
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              103.9870105705659,
              1.389451396800126
            ],
            [
              103.97812557686021,
              1.185378176915862
            ],
            [
              103.96260058785819,
              1.134723598626692
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tampnet-offshore-foc-network",
        "name": "Tampnet Offshore FOC Network",
        "color": "#97489b",
        "feature_id": "tampnet-offshore-foc-network-0",
        "coordinates": [
          2.790859004752832,
          56.74783401585216
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              2.700072323183178,
              58.99117670269845
            ],
            [
              3.375071845007284,
              59.90606992457441
            ],
            [
              4.500071048047289,
              60.57611674038759
            ],
            [
              4.830250814144932,
              60.587340230884934
            ]
          ],
          [
            [
              3.600071685615319,
              56.345222926819986
            ],
            [
              4.500071048047289,
              56.59379297841909
            ],
            [
              6.300069772911229,
              57.69234458026085
            ],
            [
              6.637569533227293,
              57.92873815757028
            ],
            [
              6.752799452193415,
              58.082771129772794
            ]
          ],
          [
            [
              -2.106804271580245,
              57.15379590661516
            ],
            [
              -1.799924488976671,
              57.08606597586797
            ],
            [
              2.700072323183178,
              56.840738642145595
            ]
          ],
          [
            [
              1.729273010906667,
              52.46882263773057
            ],
            [
              1.912572881055326,
              52.623261418527164
            ],
            [
              2.250072641967279,
              53.23359531864921
            ],
            [
              2.475072482575314,
              54.03403825672413
            ],
            [
              2.812572243487267,
              54.81936191424907
            ],
            [
              3.600071685615319,
              56.345222926819986
            ],
            [
              2.984972121357828,
              56.549192472345595
            ],
            [
              2.700072323183178,
              56.840738642145595
            ],
            [
              2.475072482575314,
              57.812399750516676
            ],
            [
              2.700072323183178,
              58.99117670269845
            ],
            [
              4.050071366831219,
              58.8750683108978
            ],
            [
              5.175070569871224,
              58.99117670269845
            ],
            [
              5.524480322346278,
              59.279267580371936
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "northstar",
        "name": "NorthStar",
        "color": "#df2e91",
        "feature_id": "northstar-0",
        "coordinates": [
          -135.9452309508062,
          54.36598433526308
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -148.6847312254872,
              60.7730276878024
            ],
            [
              -148.5212613454204,
              60.76902362218718
            ],
            [
              -148.4135414244514,
              60.78620827245604
            ],
            [
              -148.3139814974957,
              60.777128895046644
            ],
            [
              -148.19748158296838,
              60.776342870973
            ],
            [
              -148.09962084889906,
              60.752420948495455
            ],
            [
              -148.08273166715713,
              60.72557180082265
            ],
            [
              -148.0608116832391,
              60.69119678436098
            ],
            [
              -148.05646168643068,
              60.65876959082513
            ],
            [
              -147.87570181904903,
              60.60642348854208
            ],
            [
              -147.63385199648735,
              60.60251643847562
            ],
            [
              -147.50573209048525,
              60.57448569437847
            ],
            [
              -147.49523209818884,
              60.485025728482015
            ],
            [
              -147.56803204477754,
              60.31180235217164
            ],
            [
              -147.64054199157908,
              60.251238005321085
            ],
            [
              -147.76252108770387,
              60.11873415270091
            ],
            [
              -147.78878188281968,
              60.02401418328765
            ],
            [
              -147.89425180543947,
              59.96228646929305
            ],
            [
              -148.0092917210379,
              59.87790332473858
            ],
            [
              -148.08131166819888,
              59.839334333097895
            ],
            [
              -147.82482104356984,
              59.451717318905224
            ],
            [
              -145.3498227968819,
              58.52439396084483
            ],
            [
              -139.04982725985775,
              56.840738642145595
            ],
            [
              -138.1498278974258,
              55.84318584148099
            ],
            [
              -135.4498298101298,
              54.03403825672413
            ],
            [
              -132.29983204161775,
              50.740281893948264
            ],
            [
              -127.79983522945773,
              47.805412175893
            ],
            [
              -125.09983714216165,
              46.58235508209589
            ],
            [
              -124.19983777972968,
              46.0383951936513
            ],
            [
              -123.52483825790574,
              45.881984904500825
            ],
            [
              -122.98980067585494,
              45.52289882456287
            ]
          ],
          [
            [
              -146.35343293589432,
              61.130356463051186
            ],
            [
              -146.55934278482414,
              61.1091536349258
            ],
            [
              -146.65954271131034,
              61.06746482894039
            ],
            [
              -146.8010426074959,
              60.965892581920855
            ],
            [
              -146.8200825935267,
              60.919223034679874
            ],
            [
              -146.8590525649356,
              60.858765344213595
            ],
            [
              -147.06984241028505,
              60.78766268631912
            ],
            [
              -147.32995221944992,
              60.778700885367044
            ],
            [
              -147.53078207210683,
              60.76640117651834
            ],
            [
              -147.6833211438099,
              60.76501417254232
            ],
            [
              -147.78747188378077,
              60.76463322432923
            ],
            [
              -147.88996180858683,
              60.765898150319714
            ],
            [
              -148.0282417071348,
              60.756524791852456
            ],
            [
              -148.21046157344534,
              60.78816537105905
            ],
            [
              -148.32238149133283,
              60.78737961765452
            ],
            [
              -148.3868814440111,
              60.79100075570173
            ],
            [
              -148.47316138070994,
              60.7834700860044
            ],
            [
              -148.51818134768,
              60.77741693630949
            ],
            [
              -148.6847312254872,
              60.7730276878024
            ]
          ],
          [
            [
              -134.74729145098985,
              58.55104926823199
            ],
            [
              -134.89544134229646,
              58.498125955375684
            ],
            [
              -134.983811277462,
              58.49169307012278
            ],
            [
              -135.03889123705144,
              58.460243297756676
            ],
            [
              -135.05266122694877,
              58.413573683867384
            ],
            [
              -135.02922124414602,
              58.365509963813906
            ],
            [
              -135.01477125474756,
              58.30573481193784
            ],
            [
              -135.01477125474756,
              58.2388425542629
            ],
            [
              -135.06389121870967,
              58.186808702747555
            ],
            [
              -135.17493000487144,
              58.17919055335861
            ],
            [
              -135.27131106653152,
              58.19901015539148
            ],
            [
              -135.35237100706024,
              58.22272642917167
            ],
            [
              -135.44749093727344,
              58.28756755123473
            ],
            [
              -135.54587086509486,
              58.32947954580658
            ],
            [
              -135.6318608020065,
              58.357898675160754
            ],
            [
              -135.7696107009433,
              58.36023311803251
            ],
            [
              -135.88347061740754,
              58.34363161367776
            ],
            [
              -135.98699054145797,
              58.3271220669817
            ],
            [
              -136.11301044900068,
              58.30106382103432
            ],
            [
              -136.28406032350637,
              58.28854000125975
            ],
            [
              -136.39207024426244,
              58.27450259262446
            ],
            [
              -136.47756018154095,
              58.24090063696545
            ],
            [
              -136.55056012798295,
              58.19852006981361
            ],
            [
              -136.87126989268737,
              58.14519214129464
            ],
            [
              -139.04982725985775,
              56.840738642145595
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "oran-valencia-orval",
        "name": "Oran-Valencia (ORVAL)",
        "color": "#2cb56f",
        "feature_id": "oran-valencia-orval-0",
        "coordinates": [
          0.7108684280158002,
          37.75874153644594
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              0.675073757711203,
              37.58978657360316
            ],
            [
              2.812572243487267,
              37.14273000200086
            ],
            [
              3.035712085412854,
              36.76212778210993
            ]
          ],
          [
            [
              -0.376975497007066,
              39.459646713436825
            ],
            [
              0.450073917103168,
              39.17701458492104
            ],
            [
              0.900073598319238,
              38.651811712711236
            ],
            [
              0.675073757711203,
              37.58978657360316
            ],
            [
              0.000074235887269,
              36.51238821239372
            ],
            [
              -0.642015309250468,
              35.701641134808256
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "pacific-caribbean-cable-system-pccs",
        "name": "Pacific Caribbean Cable System (PCCS)",
        "color": "#44b97a",
        "feature_id": "pacific-caribbean-cable-system-pccs-0",
        "coordinates": [
          -64.74486945319329,
          20.4858606454211
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -66.10666893347562,
              18.466104232947515
            ],
            [
              -65.69987922164925,
              18.57203905256688
            ],
            [
              -64.83573983381382,
              18.575280919370726
            ],
            [
              -64.597150002833,
              18.41441211576617
            ]
          ],
          [
            [
              -76.04987188961734,
              10.853089690745378
            ],
            [
              -75.50573227509092,
              10.386807451633246
            ]
          ],
          [
            [
              -70.04992614003528,
              12.616917900441507
            ],
            [
              -69.74987635259329,
              13.929303843271725
            ]
          ],
          [
            [
              -70.04992614003528,
              12.616917900441507
            ],
            [
              -69.2998766713773,
              12.615395567393307
            ],
            [
              -68.96237691046534,
              12.395734000022884
            ],
            [
              -68.9569769142907,
              12.168849063089965
            ]
          ],
          [
            [
              -81.65563040282986,
              30.332100867462543
            ],
            [
              -80.54986870177734,
              30.320465424761352
            ],
            [
              -79.1998696581294,
              30.126049846722907
            ],
            [
              -73.34987380232134,
              29.540507745394393
            ],
            [
              -69.2998766713773,
              27.56430948794184
            ],
            [
              -66.37487874347327,
              23.711258142484382
            ],
            [
              -64.79987985921728,
              20.796306105108954
            ],
            [
              -64.46238009830532,
              18.89166158430325
            ],
            [
              -64.597150002833,
              18.41441211576617
            ],
            [
              -64.34988017800121,
              18.30522807897634
            ],
            [
              -64.23738025769728,
              18.251816319028308
            ],
            [
              -64.23738025769728,
              17.823934412537824
            ],
            [
              -66.14987890286532,
              16.10232559580288
            ],
            [
              -69.74987635259329,
              13.929303843271725
            ],
            [
              -71.54987507745732,
              13.929303843271725
            ],
            [
              -74.2498731647534,
              12.615395567393307
            ],
            [
              -76.04987188961734,
              10.853089690745378
            ],
            [
              -78.29987029569733,
              10.853089690745378
            ],
            [
              -79.31236957843342,
              9.96791518697421
            ],
            [
              -79.75347926594715,
              9.43772198486991
            ]
          ],
          [
            [
              -79.56661939832038,
              8.950317108800647
            ],
            [
              -79.31236957843342,
              8.190543417795567
            ],
            [
              -78.74986997691342,
              7.298762754459602
            ],
            [
              -78.74986997691342,
              5.061986954416028
            ],
            [
              -80.99986838299333,
              2.367912558705314
            ],
            [
              -80.99986838299333,
              0.568578852526286
            ],
            [
              -80.88736846268931,
              -0.331409329660175
            ],
            [
              -80.71613109211359,
              -0.949727245539691
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "pacific-crossing-1-pc-1",
        "name": "Pacific Crossing-1 (PC-1)",
        "color": "#05a5d7",
        "feature_id": "pacific-crossing-1-pc-1-0",
        "coordinates": [
          -126.73328748926828,
          39.94054027582578
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -179.99979825051417,
              49.00033438946335
            ],
            [
              -151.19981865268997,
              49.00033438946335
            ],
            [
              -138.5998275786418,
              48.40638249553794
            ],
            [
              -125.0998371421619,
              48.40638249553794
            ],
            [
              -124.64983746094566,
              48.481009942103015
            ],
            [
              -123.74983809851369,
              48.256798568947545
            ],
            [
              -122.84983873608172,
              48.2193340142027
            ],
            [
              -122.66605031524857,
              48.11006090495562
            ],
            [
              -122.63793033587939,
              48.03488959703961
            ],
            [
              -122.58168037714842,
              47.95960846190178
            ],
            [
              -122.48324649637519,
              47.921930050335504
            ],
            [
              -122.44105402626477,
              47.90307887010553
            ],
            [
              -122.30218058161336,
              47.886323206202945
            ],
            [
              -122.44105402626477,
              47.8936473521681
            ],
            [
              -122.49731043904819,
              47.921930050335504
            ],
            [
              -122.5957414166827,
              47.95960846190178
            ],
            [
              -122.6519863768381,
              48.03488959703961
            ],
            [
              -122.68010635691786,
              48.11006090495562
            ],
            [
              -122.84983873608172,
              48.20059144803923
            ],
            [
              -123.74983809851369,
              48.2193340142027
            ],
            [
              -124.64983746094566,
              48.40638249553794
            ],
            [
              -125.0998371421619,
              48.1067757091962
            ],
            [
              -127.34983554824174,
              42.74371346443661
            ],
            [
              -126.44983618580969,
              38.651811712711236
            ],
            [
              -122.84983873608172,
              35.60293032290622
            ],
            [
              -120.62152181466485,
              35.12094936772425
            ],
            [
              -122.84983873608172,
              35.419780517080454
            ],
            [
              -129.59983395432175,
              38.651811712711236
            ],
            [
              -138.59982757864196,
              42.74371346443661
            ],
            [
              -151.19983391146837,
              45.3283291702821
            ],
            [
              -179.99981350929244,
              45.3283291702821
            ]
          ],
          [
            [
              179.9999271925697,
              45.33105376585331
            ],
            [
              172.79992073307184,
              45.33105376585331
            ],
            [
              160.19996074878455,
              42.079235618164205
            ],
            [
              143.09997286257644,
              34.12610104005762
            ],
            [
              138.59997605041642,
              33.00121852265437
            ],
            [
              137.69997668798445,
              33.471699570864644
            ],
            [
              136.87399727311598,
              34.33682825203164
            ],
            [
              137.69997668798445,
              33.75276987113051
            ],
            [
              138.59997605041642,
              33.565491482352144
            ],
            [
              140.84997445649643,
              34.31215165223537
            ],
            [
              141.52497397832056,
              35.05222991093683
            ],
            [
              141.52497397832056,
              35.78566189952613
            ],
            [
              140.6124746247436,
              36.38348373531238
            ],
            [
              142.19997350014447,
              36.14986678681771
            ],
            [
              149.39996839960057,
              39.69832335493328
            ],
            [
              160.19996074878455,
              45.96024524125332
            ],
            [
              172.7999518228328,
              49.00033438946335
            ],
            [
              179.99995828233065,
              49.00033438946335
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "palawan-iloilo-cable-system",
        "name": "Palawan-Iloilo Cable System",
        "color": "#4db74a",
        "feature_id": "palawan-iloilo-cable-system-0",
        "coordinates": [
          120.74805537239743,
          10.999877242154184
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              119.50037958074992,
              10.820000490489491
            ],
            [
              120.14998912056026,
              10.853089690745378
            ],
            [
              121.04998848299223,
              11.073982781226704
            ],
            [
              121.4999881642083,
              10.853089690745378
            ],
            [
              121.9412978515804,
              10.749301721782956
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "pan-american-crossing-pac",
        "name": "Pan-American Crossing (PAC)",
        "color": "#36a9b7",
        "feature_id": "pan-american-crossing-pac-0",
        "coordinates": [
          -102.80128879703568,
          16.23046473604046
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -85.04986551393736,
              7.744889052551343
            ],
            [
              -84.59986583272148,
              8.635699417327544
            ],
            [
              -84.45220835106024,
              9.525851215144371
            ]
          ],
          [
            [
              -118.79984160513769,
              31.28673881439167
            ],
            [
              -117.44984256148982,
              32.43331330641712
            ],
            [
              -117.03822444362808,
              32.53101418059239
            ]
          ],
          [
            [
              -106.42187223254342,
              23.199717218127375
            ],
            [
              -107.54984957473773,
              22.05298561667763
            ],
            [
              -107.99984925595354,
              20.375041253465525
            ]
          ],
          [
            [
              -79.54654941253825,
              8.934106573765888
            ],
            [
              -79.7623692596494,
              8.190543417795567
            ],
            [
              -79.64986933934539,
              7.298762754459602
            ],
            [
              -80.09986902056146,
              6.852191098754417
            ],
            [
              -80.99986838299351,
              6.852191098754417
            ],
            [
              -85.04986551393736,
              7.744889052551343
            ],
            [
              -87.29986392001737,
              9.524411345019587
            ],
            [
              -98.09985626920162,
              14.365653759228536
            ],
            [
              -102.59985308136154,
              16.10232559580288
            ],
            [
              -106.64985021230558,
              18.67864702215462
            ],
            [
              -107.99984925595354,
              20.375041253465525
            ],
            [
              -111.59984670568159,
              22.469443964829594
            ],
            [
              -116.09984351784178,
              27.763588526057674
            ],
            [
              -118.79984160513769,
              31.28673881439167
            ],
            [
              -120.5998403300018,
              32.81231878328768
            ],
            [
              -121.04984001121787,
              34.31215165223537
            ],
            [
              -120.62152181466485,
              35.12094936772425
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "penbal-5",
        "name": "Penbal-5",
        "color": "#a82990",
        "feature_id": "penbal-5-0",
        "coordinates": [
          1.8683599906210873,
          39.833527443176905
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              2.00134281816986,
              41.30436622489077
            ],
            [
              1.800072960751208,
              40.72920412488665
            ],
            [
              1.800072960751208,
              40.04369219282995
            ],
            [
              2.025072801359244,
              39.351217571171134
            ],
            [
              2.475072482575314,
              39.17701458492104
            ],
            [
              2.970972131275403,
              39.35484412819346
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "pencan-8",
        "name": "Pencan-8",
        "color": "#a52d5f",
        "feature_id": "pencan-8-0",
        "coordinates": [
          -11.862998867675145,
          33.22377025645193
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -6.087131451879088,
              36.27672346192381
            ],
            [
              -6.749920982352762,
              36.240655233214795
            ],
            [
              -8.549919707216787,
              35.876870570092734
            ],
            [
              -9.449919069648843,
              35.419780517080454
            ],
            [
              -11.249917794512783,
              33.93964008831958
            ],
            [
              -14.849915244240833,
              29.73606949729205
            ],
            [
              -15.187415005152786,
              28.951554732193216
            ],
            [
              -16.031164407432783,
              28.441436356005823
            ],
            [
              -16.371744166162927,
              28.355537519987053
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "persona",
        "name": "Persona",
        "color": "#8fac3c",
        "feature_id": "persona-0",
        "coordinates": [
          -59.26685070080069,
          46.764182420836505
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -60.13538316359052,
              46.251455775311484
            ],
            [
              -59.39988368462521,
              46.58235508209589
            ],
            [
              -58.949884003409224,
              47.19740739556977
            ],
            [
              -58.68650418998989,
              47.61768369957326
            ]
          ],
          [
            [
              -58.68650418998989,
              47.61768369957326
            ],
            [
              -59.17488384401726,
              47.19740739556977
            ],
            [
              -59.624883525233244,
              46.58235508209589
            ],
            [
              -60.13538316359052,
              46.251455775311484
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "picot-1",
        "name": "Picot-1",
        "color": "#c83994",
        "feature_id": "picot-1-0",
        "coordinates": [
          166.2639883897493,
          -20.98014509134987
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              166.48901629355865,
              -20.705648050304404
            ],
            [
              166.46636630960435,
              -20.827183610422736
            ],
            [
              166.16186652531465,
              -20.977074429932117
            ]
          ],
          [
            [
              165.34155710642963,
              -20.94561398973883
            ],
            [
              165.85776674074188,
              -21.024574704206998
            ],
            [
              166.16186652531465,
              -20.977074429932117
            ],
            [
              166.68042615796216,
              -20.99266679172058
            ],
            [
              166.98120594488674,
              -20.848996911269097
            ],
            [
              167.15151582423775,
              -20.780630532391598
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "pipe-pacific-cable-1-ppc-1",
        "name": "PIPE Pacific Cable-1 (PPC-1)",
        "color": "#8ac53f",
        "feature_id": "pipe-pacific-cable-1-ppc-1-0",
        "coordinates": [
          153.63480133865457,
          -9.027622797144517
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              145.78474096066176,
              -5.23368424614938
            ],
            [
              146.02497079048058,
              -5.161910662112973
            ],
            [
              146.6749703300147,
              -5.137011581064712
            ],
            [
              146.90776526532372,
              -4.838945214956084
            ]
          ],
          [
            [
              151.20699711948444,
              -33.86955173177813
            ],
            [
              152.09996648689648,
              -33.273630771422376
            ],
            [
              153.8999652117606,
              -31.851465665577166
            ],
            [
              154.79996457419256,
              -28.74381028114999
            ],
            [
              154.79996457419256,
              -25.5408960762594
            ],
            [
              154.3499648929765,
              -18.880139975101287
            ],
            [
              153.8999652117606,
              -15.441023659568003
            ],
            [
              154.79996457419256,
              -11.943944931746927
            ],
            [
              154.79996457419256,
              -10.177457430361159
            ],
            [
              152.99996584932862,
              -8.401139048122928
            ],
            [
              149.39996839960057,
              -7.063446338991156
            ],
            [
              148.04996935595253,
              -6.225371753845536
            ],
            [
              147.59996967473646,
              -5.777839699209608
            ],
            [
              147.31871987397645,
              -5.273944363641391
            ],
            [
              146.90776526532372,
              -4.838945214956084
            ],
            [
              145.89997087903149,
              -2.330766659231585
            ],
            [
              146.65530736297208,
              1.519276295401426
            ],
            [
              146.24997063108842,
              7.744889052551343
            ],
            [
              145.34997126865645,
              12.17588718550806
            ],
            [
              145.12497142804838,
              12.615395567393307
            ],
            [
              144.78747166713643,
              13.273238157547667
            ],
            [
              144.69470173285575,
              13.464772962370034
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "poseidon",
        "name": "POSEIDON",
        "color": "#a66d2c",
        "feature_id": "poseidon-0",
        "coordinates": [
          33.05448156025122,
          34.49779087043359
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              32.46665123625965,
              34.76657169708608
            ],
            [
              32.737551044351505,
              34.49779087043359
            ],
            [
              33.30005064587152,
              34.49779087043359
            ],
            [
              33.61060042587516,
              34.82728147271527
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "qatar-u-a-e-submarine-cable-system",
        "name": "Qatar-U.A.E. Submarine Cable System",
        "color": "#d01c59",
        "feature_id": "qatar-u-a-e-submarine-cable-system-0",
        "coordinates": [
          52.885280159010534,
          25.142069263822577
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              51.51927773920005,
              25.294608758024538
            ],
            [
              52.200037256943745,
              25.653336613275968
            ],
            [
              52.42024710094509,
              25.668921307105336
            ],
            [
              52.650036938159616,
              25.450342946923996
            ],
            [
              52.8659567852003,
              25.152632913545176
            ],
            [
              54.000035981807684,
              24.532657566160623
            ],
            [
              54.41907568495611,
              24.443964572625504
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "fos-quellon-chacabuco",
        "name": "FOS Quellon-Chacabuco",
        "color": "#299bb8",
        "feature_id": "fos-quellon-chacabuco-0",
        "coordinates": [
          -73.56565434043573,
          -44.649493139065754
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -73.47946371051864,
              -43.11747634819616
            ],
            [
              -73.50998368889798,
              -44.1216190589748
            ],
            [
              -73.5707236458692,
              -44.697560745039
            ],
            [
              -73.63151360280503,
              -45.134057886198974
            ],
            [
              -73.56061365303125,
              -45.33926251743932
            ],
            [
              -73.42174375140809,
              -45.35326269275848
            ],
            [
              -73.2800938517541,
              -45.28212252426303
            ],
            [
              -73.19831390968778,
              -45.28184811337704
            ],
            [
              -73.04762401643788,
              -45.417940386955564
            ],
            [
              -72.80509418824833,
              -45.458300941353144
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "russia-japan-cable-network-rjcn",
        "name": "Russia-Japan Cable Network (RJCN)",
        "color": "#eb8c22",
        "feature_id": "russia-japan-cable-network-rjcn-0",
        "coordinates": [
          137.9821123297743,
          37.48637670310478
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              132.8740001067516,
              42.81267161587229
            ],
            [
              133.64997955704033,
              42.329239699665635
            ],
            [
              134.77497876008053,
              41.40772623743587
            ],
            [
              137.02497716616054,
              38.651811712711236
            ],
            [
              138.24196630403404,
              37.169971110143194
            ],
            [
              137.24997700676838,
              38.651811712711236
            ],
            [
              134.99997860068837,
              41.40772623743587
            ],
            [
              133.64997955704033,
              42.41235450073577
            ],
            [
              132.8740001067516,
              42.81267161587229
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "safe",
        "name": "SAFE",
        "color": "#a9499b",
        "feature_id": "safe-0",
        "coordinates": [
          76.9832940949149,
          -2.763906192892051
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              31.757961738301788,
              -28.95055966653794
            ],
            [
              32.62505112404759,
              -29.529912966149226
            ],
            [
              33.30005064587152,
              -30.309953344646914
            ]
          ],
          [
            [
              76.26955020587488,
              9.938386424893082
            ],
            [
              76.05002036139186,
              9.524411345019587
            ],
            [
              76.50002004260776,
              4.164912849976844
            ],
            [
              77.4000194050399,
              0.568578852526286
            ]
          ],
          [
            [
              54.450035663023755,
              -20.57441905727606
            ],
            [
              54.900035344239626,
              -20.784921868990978
            ],
            [
              55.279235075611155,
              -21.000051587769235
            ],
            [
              55.350035025455725,
              -20.784921868990978
            ],
            [
              55.800034706671596,
              -20.57441905727606
            ],
            [
              56.700034069103765,
              -20.784921868990978
            ],
            [
              57.150033750319835,
              -20.995131543025877
            ],
            [
              57.82503327214377,
              -20.995131543025877
            ],
            [
              58.27503295335984,
              -20.57441905727606
            ],
            [
              60.300031518831815,
              -18.880139975101287
            ],
            [
              63.0000296061277,
              -14.57172649133264
            ],
            [
              65.70002769342378,
              -11.943944931746927
            ],
            [
              72.9000225928799,
              -8.401139048122928
            ],
            [
              75.15002099895989,
              -6.616650693475464
            ],
            [
              76.95001972382383,
              -3.029995968008762
            ],
            [
              77.4000194050399,
              0.568578852526286
            ],
            [
              81.00001685476795,
              1.468426767332062
            ],
            [
              85.50001366692797,
              -1.906008422084255
            ],
            [
              90.00001047908799,
              -1.006308958972141
            ],
            [
              92.7000085663839,
              3.715978119297972
            ],
            [
              94.27500745064,
              4.613591578862867
            ],
            [
              95.40000665367998,
              5.622041180883144
            ],
            [
              97.42500521915215,
              5.733989114150035
            ],
            [
              99.67500362523216,
              5.398081130463737
            ],
            [
              100.36299313785392,
              5.35384659465538
            ]
          ],
          [
            [
              18.445861168718807,
              -33.72721819637752
            ],
            [
              18.00006148452735,
              -34.11602012163186
            ],
            [
              17.775061643919315,
              -34.857839362235865
            ],
            [
              18.45006116574342,
              -36.321527759917814
            ],
            [
              19.80006020939146,
              -37.40161074814375
            ],
            [
              23.40005765911934,
              -37.40161074814375
            ],
            [
              26.10005574641542,
              -36.321527759917814
            ],
            [
              33.30005064587152,
              -30.309953344646914
            ],
            [
              38.70004682046351,
              -28.74381028114999
            ],
            [
              41.40004490775959,
              -28.74381028114999
            ],
            [
              45.45004166665507,
              -28.743810607354476
            ],
            [
              47.250040763567654,
              -27.951747285219852
            ],
            [
              52.650036938159616,
              -23.08058350574774
            ],
            [
              54.000035981807684,
              -20.995131543025877
            ],
            [
              54.450035663023755,
              -20.57441905727606
            ],
            [
              54.900035344239626,
              -20.36362554441115
            ],
            [
              55.800034706671596,
              -20.36362554441115
            ],
            [
              56.700034069103765,
              -20.57441905727606
            ],
            [
              57.150033750319835,
              -20.57441905727606
            ],
            [
              57.485483512684,
              -20.4739956609462
            ],
            [
              57.600033431535735,
              -20.679706953509026
            ],
            [
              57.82503327214377,
              -20.995131543025877
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "sint-maarten-puerto-rico-network-one-smpr-1",
        "name": "Sint Maarten Puerto Rico Network One (SMPR-1)",
        "color": "#3fb449",
        "feature_id": "sint-maarten-puerto-rico-network-one-smpr-1-0",
        "coordinates": [
          -64.49579046440827,
          18.772674867159765
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -66.01686899709078,
              18.441839618642774
            ],
            [
              -65.69987922164925,
              18.67864702215462
            ],
            [
              -65.24987954043327,
              18.89166158430325
            ],
            [
              -63.899880496785315,
              18.67864702215462
            ],
            [
              -63.14568103106724,
              18.05968349844707
            ],
            [
              -63.11555538455045,
              18.04770473193915
            ],
            [
              -63.07366108208683,
              18.031045075146636
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "samoa-american-samoa-sas",
        "name": "Samoa-American Samoa (SAS)",
        "color": "#924d9e",
        "feature_id": "samoa-american-samoa-sas-0",
        "coordinates": [
          -171.1504644126999,
          -13.880916628765306
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -171.76669408292253,
              -13.833489255757883
            ],
            [
              -171.44980430741003,
              -13.80826146481482
            ],
            [
              -170.99980462619405,
              -13.917484462464614
            ],
            [
              -170.6957048416213,
              -14.276544564158712
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "sat-3wasc",
        "name": "SAT-3/WASC",
        "color": "#04a6c7",
        "feature_id": "sat-3wasc-0",
        "coordinates": [
          -19.061011257817853,
          11.072258398466374
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -10.349918432080813,
              33.93964008831958
            ],
            [
              -8.549919707216787,
              35.419780517080454
            ],
            [
              -7.199920663568747,
              36.33133835588808
            ],
            [
              -6.428881209780371,
              36.73129423644173
            ]
          ],
          [
            [
              7.650068816559269,
              0.343586284889255
            ],
            [
              8.55006817899124,
              0.343586284889255
            ],
            [
              9.454267538448192,
              0.394465191855375
            ]
          ],
          [
            [
              2.250072641967279,
              2.92980888035018
            ],
            [
              2.025072801359244,
              3.828234303320954
            ],
            [
              2.025072801359244,
              5.061986954416028
            ],
            [
              2.440112507341183,
              6.356673335458169
            ]
          ],
          [
            [
              -14.849915244240833,
              26.964304734562802
            ],
            [
              -15.862464526941437,
              27.807911809695895
            ],
            [
              -15.862464526941437,
              27.94275199342127
            ],
            [
              -15.699964642057836,
              27.99976141196052
            ]
          ],
          [
            [
              13.37242476278874,
              -8.776668856663491
            ],
            [
              12.60006530993536,
              -9.014619375760589
            ],
            [
              11.70006594750339,
              -9.512401827330194
            ],
            [
              9.675067382031244,
              -10.620064860363328
            ]
          ],
          [
            [
              9.706417359822666,
              4.047345511205606
            ],
            [
              9.225067700815345,
              3.266814816815753
            ],
            [
              8.10006849777534,
              2.817450442654064
            ],
            [
              6.300069772911229,
              2.592701464601845
            ]
          ],
          [
            [
              -0.204315619320994,
              5.558285889905761
            ],
            [
              -0.224925604720681,
              3.279837005485092
            ],
            [
              -0.224925604720681,
              1.481465914707456
            ]
          ],
          [
            [
              -4.026242911831901,
              5.323508791824736
            ],
            [
              -3.937422974752735,
              3.266814816815753
            ],
            [
              -3.824923054448732,
              2.367912558705314
            ],
            [
              -3.699923142999808,
              1.468426767332062
            ],
            [
              -3.599923213840782,
              1.01853421661562
            ]
          ],
          [
            [
              -17.44571340535299,
              14.686594841995088
            ],
            [
              -18.449912693968884,
              15.018578573757566
            ],
            [
              -19.349912056400854,
              15.018578573757566
            ]
          ],
          [
            [
              2.925072163791214,
              4.164912849976844
            ],
            [
              5.400070410479259,
              2.817450442654064
            ],
            [
              6.300069772911229,
              2.592701464601845
            ],
            [
              7.425068975951234,
              1.468426767332062
            ],
            [
              7.650068816559269,
              0.343586284889255
            ],
            [
              8.10006849777534,
              -4.825692499217524
            ],
            [
              8.55006817899124,
              -6.616650693475464
            ],
            [
              9.675067382031244,
              -10.620064860363328
            ],
            [
              9.45006754142321,
              -18.026426383713385
            ],
            [
              10.80006658507125,
              -23.493922445897766
            ],
            [
              14.4000640347993,
              -30.309953344646914
            ],
            [
              15.975062919055375,
              -32.61276000573585
            ],
            [
              17.55006180331145,
              -33.6490453774241
            ],
            [
              18.445861168718807,
              -33.72721819637752
            ]
          ],
          [
            [
              -9.102749315587062,
              38.443079483141986
            ],
            [
              -9.224919229040808,
              38.12273010839229
            ],
            [
              -9.56241898995276,
              37.232354321556215
            ],
            [
              -9.899918750864742,
              35.78566189952613
            ],
            [
              -10.349918432080813,
              33.93964008831958
            ],
            [
              -12.824916678768858,
              30.126049846722907
            ],
            [
              -13.724916041200828,
              28.161052262220892
            ],
            [
              -14.849915244240833,
              26.964304734562802
            ],
            [
              -17.999913012752813,
              22.884654113882362
            ],
            [
              -19.349912056400854,
              19.952622905164304
            ],
            [
              -19.349912056400854,
              15.018578573757566
            ],
            [
              -19.349912056400854,
              11.735650161405744
            ],
            [
              -17.999913012752813,
              8.635699417327544
            ],
            [
              -13.499916200592793,
              3.715978119297972
            ],
            [
              -10.799918113296798,
              1.01853421661562
            ],
            [
              -3.599923213840782,
              1.01853421661562
            ],
            [
              -0.224925604720681,
              1.481465914707456
            ],
            [
              1.575073120143173,
              2.592701464601845
            ],
            [
              2.250072641967279,
              2.92980888035018
            ],
            [
              2.925072163791214,
              4.164912849976844
            ],
            [
              3.423511810692077,
              6.439066911484701
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "scandinavian-ring-north",
        "name": "Scandinavian Ring North",
        "color": "#79c042",
        "feature_id": "scandinavian-ring-north-0",
        "coordinates": [
          12.676008213613358,
          56.01418278326781
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              12.640495281294534,
              56.07069401721387
            ],
            [
              12.668130261717408,
              56.05730958788601
            ],
            [
              12.695765242140624,
              56.043920511503416
            ],
            [
              12.68302025116924,
              56.02474048982961
            ],
            [
              12.670275260198025,
              56.00555093561886
            ],
            [
              12.64176028039833,
              56.01947311565067
            ],
            [
              12.613245300598663,
              56.03339027842571
            ],
            [
              12.6268702909465,
              56.05204665760559
            ],
            [
              12.640495281294534,
              56.07069401721387
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "scandinavian-ring-south",
        "name": "Scandinavian Ring South",
        "color": "#84489c",
        "feature_id": "scandinavian-ring-south-0",
        "coordinates": [
          12.869610233307554,
          55.574067478133344
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              12.670275260198025,
              55.5937361749767
            ],
            [
              12.825115150508111,
              55.58967885738048
            ],
            [
              12.918815084129932,
              55.55680364719609
            ],
            [
              12.768815190391422,
              55.55790652931407
            ],
            [
              12.670275260198025,
              55.5937361749767
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "scotland-northern-ireland-1",
        "name": "Scotland-Northern Ireland 1",
        "color": "#c0b230",
        "feature_id": "scotland-northern-ireland-1-0",
        "coordinates": [
          -5.295928196395863,
          54.68794520830264
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -5.115502140190387,
              54.844025368730655
            ],
            [
              -5.174922098096772,
              54.75449236881684
            ],
            [
              -5.287422018400775,
              54.68951871778469
            ],
            [
              -5.538691840398826,
              54.6430377426982
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "seabras-1",
        "name": "Seabras-1",
        "color": "#a9812d",
        "feature_id": "seabras-1-0",
        "coordinates": [
          -39.716096068711096,
          7.902502344602738
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -46.41249288501473,
              -24.00886839636476
            ],
            [
              -44.54989420449712,
              -25.36030508721572
            ],
            [
              -41.399896435985056,
              -25.5408960762594
            ],
            [
              -36.674899783217,
              -24.111502734257556
            ],
            [
              -31.049729121911753,
              -18.025284192896805
            ],
            [
              -29.249730397047728,
              -13.697820288632613
            ],
            [
              -28.574905521329924,
              -9.290424301035614
            ],
            [
              -30.824903908753015,
              -4.152767729405667
            ],
            [
              -34.199901536528955,
              0.568578852526286
            ],
            [
              -39.59989771112103,
              7.744889052551343
            ],
            [
              -48.59989133544116,
              19.952622905164304
            ],
            [
              -62.99988113435326,
              34.68301765985788
            ],
            [
              -65.69987922164925,
              37.58978657360316
            ],
            [
              -68.39987730894534,
              39.17701458492104
            ],
            [
              -71.09987539624133,
              39.611709593477684
            ],
            [
              -74.06286329723287,
              40.15283384719578
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "seychelles-to-east-africa-system-seas",
        "name": "Seychelles to East Africa System (SEAS)",
        "color": "#bb5235",
        "feature_id": "seychelles-to-east-africa-system-seas-0",
        "coordinates": [
          47.36066943060575,
          -5.742847166251124
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.2696764169327,
              -6.823132108349142
            ],
            [
              40.50004554532762,
              -6.840100757612362
            ],
            [
              42.75004395140763,
              -6.616650693475464
            ],
            [
              52.200037256943745,
              -4.825692499217524
            ],
            [
              54.000035981807684,
              -4.601453764837203
            ],
            [
              55.44505495814283,
              -4.617611322442229
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "shefa-2",
        "name": "SHEFA-2",
        "color": "#ca2026",
        "feature_id": "shefa-2-0",
        "coordinates": [
          -2.3828882655566304,
          60.1925980954027
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -4.333322694293685,
              60.333364648814836
            ],
            [
              -4.049922895056767,
              60.242806526349625
            ],
            [
              -3.599923213840782,
              60.242806526349625
            ],
            [
              -2.924923692016677,
              60.35428947498107
            ],
            [
              -2.699923851408727,
              60.409888455789456
            ],
            [
              -2.294924138314343,
              60.44103177964078
            ]
          ],
          [
            [
              -2.523653976279945,
              57.66686302746419
            ],
            [
              -2.699923851408727,
              57.93205658695149
            ],
            [
              -2.699923851408727,
              58.52439396084483
            ],
            [
              -2.900023709656125,
              58.833370284296706
            ],
            [
              -2.474924010800777,
              58.8750683108978
            ],
            [
              -1.687424569268728,
              59.394489266856795
            ],
            [
              -1.181174929092663,
              59.6796637072089
            ],
            [
              -1.12492496774874,
              59.79305890746801
            ],
            [
              -1.238544886663306,
              59.99607269143408
            ],
            [
              -1.267374866239862,
              60.004077667524854
            ],
            [
              -1.323654826370586,
              60.004077667524854
            ],
            [
              -1.799924488976671,
              60.01869762196867
            ],
            [
              -2.924923692016677,
              60.35428947498107
            ],
            [
              -6.299921301136777,
              61.875570783571504
            ],
            [
              -6.77184096682447,
              62.01764624983275
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "silphium",
        "name": "Silphium",
        "color": "#ce1e4c",
        "feature_id": "silphium-0",
        "coordinates": [
          23.07387014365935,
          34.58586349997978
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              22.63911819817619,
              32.76363502668917
            ],
            [
              22.95005797790344,
              33.565491482352144
            ],
            [
              23.175057818511476,
              35.419780517080454
            ],
            [
              23.51255757942343,
              35.74001826386845
            ],
            [
              23.737557420031465,
              35.74001826386845
            ],
            [
              24.01216722549529,
              35.51204255863749
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "sirius-south",
        "name": "Sirius South",
        "color": "#8f4a9c",
        "feature_id": "sirius-south-0",
        "coordinates": [
          -4.658602719796363,
          53.645278167215224
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -3.050753602877677,
              53.80897597127557
            ],
            [
              -3.599923213840782,
              53.802143574575354
            ],
            [
              -5.624921779312757,
              53.502097882664344
            ],
            [
              -6.248311337697743,
              53.348124632595216
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "sirius-north",
        "name": "Sirius North",
        "color": "#208ecb",
        "feature_id": "sirius-north-0",
        "coordinates": [
          -5.224192910352656,
          55.06951973785311
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -5.808121649532183,
              54.71395525212927
            ],
            [
              -5.592111802555621,
              54.71395525212927
            ],
            [
              -4.949922257488737,
              55.33458061322915
            ],
            [
              -4.783462375410465,
              55.63788181039317
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "south-america-1-sam-1",
        "name": "South America-1 (SAm-1)",
        "color": "#35ad49",
        "feature_id": "south-america-1-sam-1-0",
        "coordinates": [
          -44.930800409562124,
          6.775101791950533
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -46.328062944825774,
              -23.961842897596995
            ],
            [
              -44.54989420449712,
              -24.81691653965538
            ],
            [
              -42.749895479633096,
              -24.7261180292069
            ],
            [
              -40.94989675476907,
              -23.287413403488756
            ],
            [
              -39.037398109601014,
              -21.9373831356925
            ],
            [
              -37.34989930504102,
              -18.026426383713385
            ],
            [
              -36.44989994260905,
              -13.698987269610853
            ],
            [
              -33.749901855313055,
              -8.401139048122928
            ],
            [
              -33.749901855313055,
              -6.616650693475464
            ],
            [
              -34.649901217745025,
              -4.825692499217524
            ],
            [
              -35.999900261393066,
              -4.15276774801373
            ],
            [
              -38.54296845985962,
              -3.718735129291019
            ],
            [
              -38.474898508081026,
              -1.231315750217505
            ],
            [
              -39.59989771112103,
              1.468426767332062
            ],
            [
              -46.79989261057713,
              8.635699417327544
            ],
            [
              -52.19988878516919,
              13.929303843271725
            ],
            [
              -57.599884959761184,
              16.53419619825962
            ],
            [
              -59.39988368462521,
              17.823934412537824
            ],
            [
              -62.099881771921204,
              19.104405475930548
            ],
            [
              -65.24987954043327,
              18.998067525949068
            ],
            [
              -65.69987922164925,
              18.785187974742087
            ],
            [
              -65.98107902244473,
              18.67864702215462
            ],
            [
              -66.10666893347562,
              18.466104232947515
            ],
            [
              -66.37487874347327,
              19.104405475930548
            ],
            [
              -66.37487874347327,
              20.375041253465525
            ],
            [
              -67.49987794651328,
              22.05298561667763
            ],
            [
              -69.2998766713773,
              23.298598065875808
            ],
            [
              -73.34987380232134,
              25.348717422116806
            ],
            [
              -76.94987125204939,
              27.364667993860166
            ],
            [
              -77.84987061448136,
              27.46453393782076
            ],
            [
              -79.64986933934539,
              26.713351447732805
            ],
            [
              -80.08893155227206,
              26.35058457732009
            ]
          ],
          [
            [
              -46.328062944825774,
              -23.961842897596995
            ],
            [
              -45.44989356692909,
              -25.13418654706126
            ],
            [
              -43.649894842065066,
              -27.951747285219852
            ],
            [
              -45.89989324814505,
              -32.61276000573585
            ],
            [
              -50.84988974152117,
              -35.22626671976636
            ],
            [
              -53.99988751003313,
              -36.321527759917814
            ],
            [
              -56.69544560047457,
              -36.47095527632115
            ]
          ],
          [
            [
              -44.54989420449712,
              -24.81691653965538
            ],
            [
              -43.42489500145712,
              -23.905969261790176
            ],
            [
              -43.209565153998795,
              -22.903486555497864
            ]
          ],
          [
            [
              -80.9144209466341,
              -2.272903429730974
            ],
            [
              -81.44986806420931,
              -2.580536704984041
            ],
            [
              -84.14986615150539,
              -3.029995968008762
            ]
          ],
          [
            [
              -81.0537808443897,
              -4.15383493726489
            ],
            [
              -81.44986806420931,
              -3.479268678969987
            ],
            [
              -81.44986806420931,
              -2.580536704984041
            ]
          ],
          [
            [
              -66.10666893347562,
              18.466104232947515
            ],
            [
              -66.48737866377729,
              18.67864702215462
            ],
            [
              -67.0498782652973,
              18.785187974742087
            ],
            [
              -67.49987794651328,
              18.465364393137207
            ],
            [
              -67.72487778712132,
              17.823934412537824
            ],
            [
              -70.19987603380928,
              14.583511645118676
            ],
            [
              -71.54987507745732,
              13.710817738179543
            ],
            [
              -74.14709323756362,
              12.416907726166698
            ],
            [
              -74.8123727662734,
              11.735650161405744
            ],
            [
              -74.77975278938158,
              10.94044561572673
            ]
          ],
          [
            [
              -38.50448848711929,
              -12.969972035343062
            ],
            [
              -37.34989930504102,
              -13.698987269610853
            ],
            [
              -36.44989994260905,
              -13.698987269610853
            ]
          ],
          [
            [
              -80.08893155227206,
              26.35058457732009
            ],
            [
              -79.87486917995342,
              25.348717422116806
            ],
            [
              -80.3248688611693,
              24.73717827217618
            ],
            [
              -80.99986838299351,
              24.12261698700334
            ],
            [
              -83.24986678907334,
              24.12261698700334
            ],
            [
              -84.82486567332933,
              23.091785476922468
            ],
            [
              -85.72486503576138,
              21.635297384859456
            ],
            [
              -86.17486471697737,
              20.375041253465525
            ],
            [
              -86.9623641591054,
              18.251816319028308
            ],
            [
              -87.86236352153738,
              16.53419619825962
            ],
            [
              -88.31236320275336,
              16.10232559580288
            ],
            [
              -88.59713531004533,
              15.72723663872111
            ]
          ],
          [
            [
              -71.62043502747201,
              -33.0455412324782
            ],
            [
              -71.99987475867339,
              -31.851465665577166
            ],
            [
              -73.79987348353733,
              -27.153831285391675
            ],
            [
              -72.89987412110536,
              -19.51759387823722
            ],
            [
              -70.3067559580946,
              -18.473543073651314
            ],
            [
              -72.89987412110536,
              -19.0928985819682
            ],
            [
              -75.59987220840135,
              -18.453813775777263
            ],
            [
              -77.84987061448136,
              -15.441023659568003
            ],
            [
              -78.0748704550894,
              -13.698987269610853
            ],
            [
              -77.84987061449137,
              -12.822995625629687
            ],
            [
              -76.87428130559798,
              -12.278420041799535
            ],
            [
              -79.1998696581294,
              -12.383840433185668
            ],
            [
              -81.8998677454253,
              -11.50333384598423
            ],
            [
              -84.14986615150539,
              -6.616650693475464
            ],
            [
              -84.14986615150539,
              -3.029995968008762
            ],
            [
              -86.39986455758559,
              0.568578852526286
            ],
            [
              -90.44986168852954,
              3.715978119297972
            ],
            [
              -91.7998607321774,
              9.524411345019587
            ],
            [
              -91.3498610509616,
              13.054150695298716
            ],
            [
              -90.82222367756134,
              13.934797333208934
            ]
          ],
          [
            [
              -67.49987794651328,
              18.465364393137207
            ],
            [
              -68.06237754803328,
              18.67864702215462
            ],
            [
              -68.43815728182749,
              18.621371316104643
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "south-american-crossing-sac",
        "name": "South American Crossing (SAC)",
        "color": "#276fac",
        "feature_id": "south-american-crossing-sac-0",
        "coordinates": [
          -36.766792944679764,
          -3.3946055747121875
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -77.04030118798802,
              3.883678466264286
            ],
            [
              -78.29987029569733,
              4.164912849976844
            ],
            [
              -79.64986933934539,
              5.061986954416028
            ]
          ],
          [
            [
              -56.69544560047457,
              -36.47095527632115
            ],
            [
              -53.99988751003313,
              -36.14003339129533
            ],
            [
              -50.84988974152117,
              -34.857839362235865
            ],
            [
              -46.79989261057713,
              -32.61276000573585
            ],
            [
              -44.54989420449712,
              -27.951747285219852
            ],
            [
              -45.67489340753713,
              -25.13418654706126
            ],
            [
              -46.328062944825774,
              -23.961842897596995
            ],
            [
              -44.99989388571311,
              -24.31670674946909
            ],
            [
              -43.649894842065066,
              -23.70010845220321
            ],
            [
              -43.209565153998795,
              -22.903486555497864
            ],
            [
              -42.299895798417,
              -23.803113122349146
            ],
            [
              -40.94989675476907,
              -24.111502734257556
            ],
            [
              -37.68739906595306,
              -23.18403842445062
            ],
            [
              -33.749901855313055,
              -18.026426383713385
            ],
            [
              -31.949903130448945,
              -13.698987269610853
            ],
            [
              -30.59990408680099,
              -9.290424301035614
            ],
            [
              -31.837403200816965,
              -5.161910652822854
            ],
            [
              -34.199901536528955,
              -3.816084221750117
            ],
            [
              -35.999900261393066,
              -3.254657364797595
            ],
            [
              -38.54296845985962,
              -3.718735129291019
            ],
            [
              -38.69989834868906,
              -1.231315750217505
            ],
            [
              -40.049897392337016,
              1.468426767332062
            ],
            [
              -46.79989261057713,
              6.852191098754417
            ],
            [
              -51.29988942273715,
              8.635699417327544
            ],
            [
              -54.89988687246519,
              10.853089690745378
            ],
            [
              -57.1498852785452,
              13.054150695298716
            ],
            [
              -60.299883047057264,
              16.53419619825962
            ],
            [
              -61.64988209070522,
              17.39502263470061
            ],
            [
              -63.449880815569244,
              17.823934412537824
            ],
            [
              -64.12488033739326,
              18.03800543960884
            ],
            [
              -64.57488001860925,
              18.03800543960884
            ],
            [
              -64.81925984548829,
              17.773909269375793
            ],
            [
              -64.99142972352159,
              17.831274188571825
            ],
            [
              -66.14987890286532,
              16.965102599435824
            ],
            [
              -68.39987730894534,
              15.886035719079104
            ],
            [
              -70.19987603380928,
              15.23578178303569
            ],
            [
              -71.54987507745732,
              14.583511645118676
            ],
            [
              -74.2498731647534,
              13.273238157547667
            ],
            [
              -78.29987029569733,
              11.515266158038685
            ],
            [
              -79.87486917995342,
              9.96791518697421
            ],
            [
              -79.90025916196689,
              9.353803196949315
            ]
          ],
          [
            [
              -67.03390827661055,
              10.608129806992565
            ],
            [
              -67.38737802620926,
              11.294709319565555
            ],
            [
              -67.72487778712132,
              12.615395567393307
            ],
            [
              -68.84987699016132,
              13.929303843271725
            ],
            [
              -70.19987603380928,
              15.23578178303569
            ]
          ],
          [
            [
              -71.62043502747201,
              -33.0455412324782
            ],
            [
              -72.44987443988929,
              -31.851465665577166
            ],
            [
              -74.2498731647534,
              -27.153831285391675
            ],
            [
              -78.29987029569733,
              -15.441023659568003
            ],
            [
              -78.29987029569733,
              -13.698987269610853
            ],
            [
              -78.0748704550894,
              -12.822995625629687
            ],
            [
              -76.87428130559798,
              -12.278420041799535
            ],
            [
              -79.1998696581294,
              -12.163983680780518
            ],
            [
              -80.99986838299333,
              -11.50333384598423
            ],
            [
              -83.24986678907334,
              -6.616650693475464
            ],
            [
              -83.24986678907334,
              -3.029995968008762
            ],
            [
              -82.79986710785735,
              0.568578852526286
            ],
            [
              -81.89986774542548,
              2.367912558705314
            ],
            [
              -79.64986933934539,
              5.061986954416028
            ],
            [
              -79.1998696581294,
              7.298762754459602
            ],
            [
              -79.53736941904137,
              8.190543417795567
            ],
            [
              -79.54654941253825,
              8.934106573765888
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "south-atlantic-cable-system-sacs",
        "name": "South Atlantic Cable System (SACS)",
        "color": "#28ab4a",
        "feature_id": "south-atlantic-cable-system-sacs-0",
        "coordinates": [
          -13.018183576553156,
          -7.457062047705591
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -38.54296845985962,
              -3.718735129291019
            ],
            [
              -35.999900261393066,
              -2.805287932308005
            ],
            [
              -34.199901536528955,
              -2.580536704984041
            ],
            [
              -29.8124046166879,
              -3.14233268364373
            ],
            [
              -19.799911737616924,
              -6.616650693475464
            ],
            [
              -5.399921938704722,
              -8.401139048122928
            ],
            [
              7.200069135343199,
              -9.06830600387434
            ],
            [
              11.70006594750339,
              -9.06830600387434
            ],
            [
              12.60006530993536,
              -9.19851313135834
            ],
            [
              13.20144038206871,
              -9.490080988806199
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "southern-cross-cable-network-sccn",
        "name": "Southern Cross Cable Network (SCCN)",
        "color": "#2f85a6",
        "feature_id": "southern-cross-cable-network-sccn-0",
        "coordinates": [
          -161.3068448064071,
          9.768033580136176
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -155.82203598904985,
              20.02329313856839
            ],
            [
              -156.14981514606578,
              20.26954403592967
            ],
            [
              -156.8968852004637,
              20.456951123759666
            ],
            [
              -157.34732486998882,
              20.509644634906977
            ],
            [
              -157.8585139356077,
              20.893863273745836
            ],
            [
              -158.13057429534103,
              21.35406896575401
            ]
          ],
          [
            [
              -122.98980067585494,
              45.52289882456287
            ],
            [
              -123.52483825790574,
              45.8232172063386
            ],
            [
              -124.19983777972968,
              45.86240257160273
            ],
            [
              -125.09983714216165,
              45.33107107332478
            ],
            [
              -128.69983459188978,
              42.74371346443661
            ],
            [
              -138.5998275786418,
              35.05222991093683
            ],
            [
              -147.59982120296192,
              30.514495959759188
            ],
            [
              -152.99981737755388,
              26.562513149236622
            ],
            [
              -157.94981387093,
              22.90768438285798
            ],
            [
              -158.51231347245,
              22.05298561667763
            ],
            [
              -158.51231237260103,
              21.73983373091116
            ],
            [
              -158.51231346392393,
              21.635297384859456
            ],
            [
              -158.13057429534103,
              21.35406896575401
            ],
            [
              -158.73731331305802,
              20.796306105108954
            ],
            [
              -159.97481243640243,
              18.67864702215462
            ],
            [
              -162.8998103643061,
              14.801154224791475
            ],
            [
              -166.49980781403406,
              10.410816505402636
            ],
            [
              -171.8998039886261,
              2.367912558705314
            ],
            [
              -176.39980080078612,
              -6.616650693475464
            ],
            [
              -179.54979856929822,
              -16.953454989810012
            ],
            [
              -179.99979825051417,
              -17.383402005942372
            ]
          ],
          [
            [
              -179.99979825051417,
              -35.59302880961411
            ],
            [
              -175.49980143835418,
              -34.11602012163186
            ],
            [
              -172.79980335105807,
              -25.5408960762594
            ],
            [
              -169.19980590133002,
              -15.441023659568003
            ],
            [
              -168.29980653889805,
              -10.177457430361159
            ],
            [
              -163.79980972673806,
              2.367912558705314
            ],
            [
              -160.19981227700998,
              13.054150695298716
            ],
            [
              -157.4998141897139,
              18.67864702215462
            ],
            [
              -156.4873149075741,
              19.74098736552503
            ],
            [
              -155.8563559638703,
              20.003739828700503
            ],
            [
              -155.9248153054582,
              20.26954403592967
            ],
            [
              -155.69981546485,
              20.585819096040467
            ],
            [
              -152.99981737755388,
              23.298598065875808
            ],
            [
              -147.59982120296192,
              25.754704263415306
            ],
            [
              -138.5998275786418,
              27.364667993860166
            ],
            [
              -127.79983522945773,
              31.67051304708704
            ],
            [
              -122.39983905486591,
              34.49779087043359
            ],
            [
              -120.84720164908995,
              35.367078251717174
            ]
          ],
          [
            [
              179.9999603175593,
              -17.383402005942372
            ],
            [
              178.87496111451927,
              -18.02880365069919
            ],
            [
              178.43744782917761,
              -18.12381094353711
            ],
            [
              178.0874480765248,
              -18.66711083815875
            ],
            [
              177.97494815681674,
              -18.880139975101287
            ],
            [
              173.69996478053517,
              -23.905969261790176
            ],
            [
              166.49996988107907,
              -27.153831285391675
            ],
            [
              160.64997402527118,
              -31.08383471876715
            ],
            [
              154.80007176467663,
              -32.61276000573585
            ],
            [
              152.09996648689648,
              -33.83614190736935
            ],
            [
              151.27398707202818,
              -33.76116106060903
            ]
          ],
          [
            [
              151.1962571270927,
              -33.9135716055703
            ],
            [
              152.09996648689648,
              -34.20910789692206
            ],
            [
              155.69996393662453,
              -35.22626671976636
            ],
            [
              159.29996138635258,
              -35.775783044315574
            ],
            [
              167.39995564824065,
              -36.321527759917814
            ],
            [
              172.7999518228328,
              -36.683250670190546
            ],
            [
              174.62339053109176,
              -36.78757761230105
            ]
          ],
          [
            [
              174.76792042870525,
              -36.78780986154061
            ],
            [
              175.04990772894305,
              -36.5929784279503
            ],
            [
              175.04990772894305,
              -36.14003339129533
            ],
            [
              175.49990741015895,
              -35.775783044315574
            ],
            [
              178.6499051786711,
              -36.14003339129533
            ],
            [
              179.99990422231897,
              -35.59302880961411
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "st-thomas-st-croix-system",
        "name": "St. Thomas-St. Croix System",
        "color": "#939597",
        "feature_id": "st-thomas-st-croix-system-0",
        "coordinates": [
          -64.84149598647822,
          17.801341998886954
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -64.84313982857152,
              18.322468254678284
            ],
            [
              -64.684549940918,
              17.782279507518695
            ],
            [
              -64.70319992770615,
              17.74666281144755
            ],
            [
              -64.70319992770615,
              17.782279507518695
            ],
            [
              -64.72174991456525,
              17.801341998886954
            ],
            [
              -64.9000797882347,
              17.801341998886954
            ],
            [
              -64.9000797882347,
              17.748053328852308
            ],
            [
              -64.8814698014181,
              17.712429822041884
            ],
            [
              -64.9186297750937,
              17.748053328852308
            ],
            [
              -64.99386972179308,
              18.324604170097132
            ]
          ],
          [
            [
              -64.99386972179308,
              18.324604170097132
            ],
            [
              -64.98456972838117,
              18.333503533793703
            ],
            [
              -64.97526973496936,
              18.34240243947238
            ],
            [
              -64.96164474462152,
              18.339065996785592
            ],
            [
              -64.9480197542735,
              18.335729489696035
            ],
            [
              -64.94592475575759,
              18.331975248970792
            ],
            [
              -64.94382975724177,
              18.328220926737202
            ],
            [
              -64.95080975229712,
              18.320574387618336
            ],
            [
              -64.95778974735238,
              18.31292751058436
            ],
            [
              -64.92753976878183,
              18.302773872540598
            ],
            [
              -64.89728979021118,
              18.292619639280105
            ],
            [
              -64.87021480939134,
              18.307544590090146
            ],
            [
              -64.84313982857152,
              18.322468254678284
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "subcan-link-1",
        "name": "Subcan Link 1",
        "color": "#92c83d",
        "feature_id": "subcan-link-1-0",
        "coordinates": [
          -15.492297959982977,
          28.386459207827585
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -16.362314172843213,
              28.377623642828354
            ],
            [
              -15.288094933830251,
              28.38853301873357
            ],
            [
              -15.180095010338476,
              28.38853301873357
            ],
            [
              -15.180095010338476,
              28.06035879952458
            ],
            [
              -15.408754848353567,
              28.040969582855354
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "subcan-link-2",
        "name": "Subcan Link 2",
        "color": "#8dc63e",
        "feature_id": "subcan-link-2-0",
        "coordinates": [
          -15.338729479097452,
          28.47908983132234
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -16.362314172843213,
              28.377623642828354
            ],
            [
              -15.288094933830251,
              28.484109146622586
            ],
            [
              -15.072085086853605,
              28.484109146622586
            ],
            [
              -15.072085086853605,
              28.06035879952458
            ],
            [
              -15.408754848353567,
              28.040969582855354
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "suriname-guyana-submarine-cable-system-sg-scs",
        "name": "Suriname-Guyana Submarine Cable System (SG-SCS)",
        "color": "#63bb45",
        "feature_id": "suriname-guyana-submarine-cable-system-sg-scs-0",
        "coordinates": [
          -58.3801122892734,
          9.029604481528224
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -56.37537582721498,
              5.886620767353833
            ],
            [
              -56.36238583641716,
              6.405200795356109
            ],
            [
              -56.47488575672118,
              7.298762754459602
            ],
            [
              -57.37488511915315,
              8.190543417795567
            ],
            [
              -60.299883047057264,
              10.632033208117747
            ],
            [
              -60.74988272827325,
              10.853089690745378
            ],
            [
              -60.9748825688812,
              10.963556857789229
            ],
            [
              -61.42488225009727,
              10.853089690745378
            ],
            [
              -61.65081209004636,
              10.686261032786234
            ]
          ],
          [
            [
              -58.15486456660838,
              6.804293299288759
            ],
            [
              -57.7123848800652,
              7.298762754459602
            ],
            [
              -57.37488511915315,
              8.190543417795567
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "svalbard-undersea-cable-system",
        "name": "Svalbard Undersea Cable System",
        "color": "#944c9d",
        "feature_id": "svalbard-undersea-cable-system-0",
        "coordinates": [
          16.745715419787643,
          69.22582677233493
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              15.557773214667378,
              78.2219497310868
            ],
            [
              14.4000640347993,
              78.17901554875422
            ],
            [
              13.50006467236733,
              78.17901554875422
            ],
            [
              12.60006530993536,
              78.0399386484226
            ],
            [
              11.70006594750339,
              77.0706895251933
            ],
            [
              12.60006530993536,
              73.42211510500309
            ],
            [
              16.20006275966341,
              69.73724550802262
            ],
            [
              16.875062281487345,
              69.10459505606227
            ],
            [
              16.875062281487345,
              68.86251864159411
            ],
            [
              16.512462538356544,
              68.7069030402871
            ],
            [
              16.987562201791434,
              68.86251864159411
            ],
            [
              17.10006212209538,
              69.10459505606227
            ],
            [
              16.65006244087931,
              69.73724550802262
            ],
            [
              13.05006499115126,
              73.42211510500309
            ],
            [
              12.15006562871929,
              77.0706895251933
            ],
            [
              13.05006499115126,
              78.0399386484226
            ],
            [
              13.50006467236733,
              78.13283461740872
            ],
            [
              14.4000640347993,
              78.15594726944346
            ],
            [
              15.557773214667378,
              78.2219497310868
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "sweden-estonia-ee-s-1",
        "name": "Sweden-Estonia (EE-S 1)",
        "color": "#e23825",
        "feature_id": "sweden-estonia-ee-s-1-0",
        "coordinates": [
          21.772402244209474,
          59.13355734773768
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              24.752496701038922,
              59.43639985926242
            ],
            [
              24.4125569418554,
              59.50884868221257
            ],
            [
              23.85005734033541,
              59.451717318905224
            ],
            [
              23.175057818511476,
              59.22222391484422
            ],
            [
              22.736388129269216,
              59.00008283504653
            ],
            [
              22.05005861547147,
              59.1068949571908
            ],
            [
              20.25005989060736,
              59.279742670960275
            ],
            [
              18.90006084695932,
              59.279742670960275
            ],
            [
              18.688360996929788,
              59.29276676569168
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "taino-carib",
        "name": "Taino-Carib",
        "color": "#44bb90",
        "feature_id": "taino-carib-0",
        "coordinates": [
          -65.50997995744827,
          18.44236799169252
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -66.08278895039238,
              18.45360240878159
            ],
            [
              -66.03060773901413,
              18.44429123321632
            ],
            [
              -66.01686899709078,
              18.441839618642774
            ],
            [
              -65.69987922164925,
              18.465364393137207
            ],
            [
              -64.93708976201644,
              18.372992194090983
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "taiwan-strait-express-1-tse-1",
        "name": "Taiwan Strait Express-1 (TSE-1)",
        "color": "#63b545",
        "feature_id": "taiwan-strait-express-1-tse-1-0",
        "coordinates": [
          120.58041644458811,
          25.620763286564454
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              119.60781373392032,
              25.780770721177163
            ],
            [
              119.92498927876042,
              25.805355685864328
            ],
            [
              120.82498864178828,
              25.55188275942578
            ],
            [
              121.27498832419636,
              25.348717422116806
            ],
            [
              121.4625881907028,
              25.181712818924467
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tamares-north",
        "name": "Tamares North",
        "color": "#d41577",
        "feature_id": "tamares-north-0",
        "coordinates": [
          33.55564612776698,
          33.539914733769365
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              32.46665123625965,
              34.76657169708608
            ],
            [
              32.62505112404759,
              34.49779087043359
            ],
            [
              33.52505048647936,
              33.565491482352144
            ],
            [
              34.20005000830349,
              33.00121852265437
            ],
            [
              34.9719010000002,
              32.761700000000275
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tangerine",
        "name": "Tangerine",
        "color": "#25ae73",
        "feature_id": "tangerine-0",
        "coordinates": [
          2.1668424991065365,
          51.235991798271044
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              1.440993215126866,
              51.35857144425913
            ],
            [
              1.800072960751208,
              51.235991798271044
            ],
            [
              2.475072482575314,
              51.235991798271044
            ],
            [
              2.912772172504702,
              51.231220564626796
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tasman-global-access-tga-cable",
        "name": "Tasman Global Access (TGA) Cable",
        "color": "#cc3b95",
        "feature_id": "tasman-global-access-tga-cable-0",
        "coordinates": [
          162.83053633783075,
          -36.738268662023586
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              174.87183035509466,
              -37.80138208221919
            ],
            [
              174.59995054769675,
              -37.7582357647469
            ],
            [
              172.7999518228328,
              -37.40161074814375
            ],
            [
              167.39995564824065,
              -37.04328040742419
            ],
            [
              159.29996138635258,
              -36.502600461085024
            ],
            [
              155.69996393662453,
              -35.59302880961411
            ],
            [
              152.09996648689648,
              -34.30209296887173
            ],
            [
              151.24508709250128,
              -33.73712305097797
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tata-tgn-atlantic",
        "name": "Tata TGN-Atlantic",
        "color": "#b86d35",
        "feature_id": "tata-tgn-atlantic-0",
        "coordinates": [
          -38.50018287023792,
          45.914053785307374
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -2.974873656631701,
              51.22244696599773
            ],
            [
              -4.049922895056767,
              51.37665177535368
            ],
            [
              -5.399921938704722,
              51.165500079092226
            ],
            [
              -7.199920663568747,
              51.02419288763869
            ],
            [
              -10.799918113296798,
              50.31116725161084
            ],
            [
              -16.199914287888873,
              49.58728674004675
            ],
            [
              -23.39990918734489,
              49.00033438946335
            ],
            [
              -39.59989771112103,
              45.01383364395318
            ],
            [
              -50.39989006030518,
              42.57825408607275
            ],
            [
              -61.199882409489234,
              41.0693404382163
            ],
            [
              -68.39987730894534,
              40.72920412488665
            ],
            [
              -71.09987539624133,
              40.30157665795872
            ],
            [
              -74.06286329723287,
              40.15283384719578
            ]
          ],
          [
            [
              -2.974873656631701,
              51.22244696599773
            ],
            [
              -4.049922895056767,
              51.41174942497889
            ],
            [
              -5.399921938704722,
              51.235991798271044
            ],
            [
              -7.199920663568747,
              51.165500079092226
            ],
            [
              -10.799918113296798,
              50.45463912589386
            ],
            [
              -16.199914287888873,
              49.87814473780409
            ],
            [
              -23.39990918734489,
              49.58728674004675
            ],
            [
              -39.59989771112103,
              45.64654149518748
            ],
            [
              -50.39989006030518,
              43.07331078300331
            ],
            [
              -61.199882409489234,
              41.40772623743587
            ],
            [
              -68.39987730894534,
              40.89949091487156
            ],
            [
              -71.09987539624133,
              40.387320290775165
            ],
            [
              -74.06286329723287,
              40.15283384719578
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tata-tgn-gulf",
        "name": "Tata TGN-Gulf",
        "color": "#63bb45",
        "feature_id": "tata-tgn-gulf-0",
        "coordinates": [
          57.111832135870515,
          25.108889720008193
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              61.87503040308769,
              18.67864702215462
            ],
            [
              61.87503040308769,
              22.469443964829594
            ],
            [
              59.85003183761572,
              23.814220515026335
            ],
            [
              58.50003279396768,
              24.430271928048597
            ],
            [
              57.03753383001572,
              25.145210227401265
            ],
            [
              56.81253398940768,
              25.348717422116806
            ],
            [
              56.9250339097116,
              26.159307970773796
            ],
            [
              56.75628402925571,
              26.562513149236622
            ],
            [
              56.250034387887666,
              26.61281470860399
            ],
            [
              55.800034706671596,
              26.26024097157773
            ],
            [
              55.350035025455725,
              26.058287560298936
            ],
            [
              53.550036300591586,
              26.058287560298936
            ],
            [
              52.42503709755158,
              26.562513149236622
            ],
            [
              51.750037575727674,
              26.964304734562802
            ],
            [
              51.07503805390374,
              27.364667993860166
            ],
            [
              50.51253845238372,
              26.964304734562802
            ],
            [
              50.28753861177569,
              26.461843796189072
            ],
            [
              50.21419866373054,
              26.285375359318067
            ]
          ],
          [
            [
              55.308535054854815,
              25.26935399813027
            ],
            [
              55.181285144999634,
              25.55188275942578
            ],
            [
              55.23753510515161,
              25.8559854660721
            ],
            [
              55.350035025455725,
              26.058287560298936
            ]
          ],
          [
            [
              56.333724328601164,
              25.121690004958737
            ],
            [
              56.81253398940768,
              25.348717422116806
            ]
          ],
          [
            [
              58.591142729424746,
              23.61510487139472
            ],
            [
              58.950032475183775,
              23.814220515025227
            ],
            [
              59.85003183761572,
              23.814220515026335
            ]
          ],
          [
            [
              50.576018407413954,
              26.22949483839116
            ],
            [
              51.18753797420766,
              26.562513149236622
            ],
            [
              51.750037575727674,
              26.964304734562802
            ]
          ],
          [
            [
              51.51927773920005,
              25.294608758024538
            ],
            [
              51.97503741633571,
              26.058287560298936
            ],
            [
              52.42503709755158,
              26.562513149236622
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tata-tgn-pacific",
        "name": "Tata TGN-Pacific",
        "color": "#b86637",
        "feature_id": "tata-tgn-pacific-0",
        "coordinates": [
          -141.98468279027557,
          47.36074149138625
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -118.24535355799173,
              34.053396879397155
            ],
            [
              -118.79984160513769,
              33.93964008831958
            ],
            [
              -119.24984128694976,
              33.87739543625136
            ],
            [
              -120.59984033000163,
              33.93964008831958
            ],
            [
              -122.39983905486565,
              34.31215165223537
            ],
            [
              -123.2998384178938,
              35.05222991093683
            ],
            [
              -125.54983682337772,
              38.651811712711236
            ],
            [
              -126.44983618580969,
              42.74371346443661
            ],
            [
              -125.09983714216165,
              45.01383364395318
            ],
            [
              -124.19983777972968,
              45.74476367411535
            ],
            [
              -123.52483825790574,
              45.764387407712206
            ],
            [
              -122.98980067585494,
              45.52289882456287
            ],
            [
              -123.52483825790574,
              45.84281333892898
            ],
            [
              -124.19983777972968,
              45.921128871546586
            ],
            [
              -125.09983714216165,
              46.0383951936513
            ],
            [
              -129.59983395432175,
              46.272182853813746
            ],
            [
              -138.5998275786418,
              47.19740739556977
            ],
            [
              -151.19981865269014,
              47.805412175893
            ],
            [
              -179.99979825051417,
              47.805412175893
            ]
          ],
          [
            [
              -122.98980067585494,
              45.52289882456287
            ],
            [
              -123.52483825790574,
              45.86240257160273
            ],
            [
              -124.19983777972968,
              45.97979307748946
            ],
            [
              -125.09983714216165,
              46.19436398821867
            ],
            [
              -129.59983395432175,
              46.58235508209589
            ],
            [
              -138.5998275786418,
              47.805412175893
            ],
            [
              -151.19981865268997,
              48.40638249553794
            ],
            [
              -179.99979825051417,
              48.40638249553794
            ]
          ],
          [
            [
              179.99995828233065,
              48.40638249553794
            ],
            [
              172.7999518228328,
              48.40638249553794
            ],
            [
              160.19996074878455,
              45.33107107332478
            ],
            [
              149.39996839960057,
              39.351217571171134
            ],
            [
              141.2999741377125,
              35.05222991093683
            ],
            [
              140.3437248151284,
              35.05222991093683
            ],
            [
              140.06110501533894,
              35.06229841779472
            ]
          ],
          [
            [
              179.99995828233065,
              47.805412175893
            ],
            [
              172.7999518228328,
              47.805412175893
            ],
            [
              160.19996074878455,
              44.694829089578064
            ],
            [
              149.39996839960057,
              39.00237890905848
            ],
            [
              143.09997286257644,
              35.05222991093683
            ],
            [
              138.59997605041642,
              34.03292178996395
            ],
            [
              137.69997668798445,
              34.31215165223537
            ],
            [
              137.3915769064578,
              34.76914289401017
            ],
            [
              137.5874767676805,
              34.31215165223537
            ],
            [
              138.59997605041642,
              24.12261698700334
            ],
            [
              139.94997509406446,
              21.635297384859456
            ],
            [
              142.19997350014447,
              16.965102599435824
            ],
            [
              143.32497270318444,
              15.23578178303569
            ],
            [
              144.2249720656164,
              13.929303843271725
            ],
            [
              144.69470173285575,
              13.464772962370034
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tata-tgn-tata-indicom",
        "name": "Tata TGN-Tata Indicom",
        "color": "#94a139",
        "feature_id": "tata-tgn-tata-indicom-0",
        "coordinates": [
          92.85399911501483,
          8.55949483400093
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              80.2429873910547,
              13.063853101883296
            ],
            [
              81.45001653598385,
              12.17588718550806
            ],
            [
              83.70001494206386,
              10.410816505402636
            ],
            [
              89.10001111665602,
              9.08033076823304
            ],
            [
              93.15000824759997,
              8.518425924569556
            ],
            [
              95.40000665367998,
              7.521883237406598
            ],
            [
              97.42500521915215,
              6.572869518576994
            ],
            [
              99.2250039440161,
              5.286069860821101
            ],
            [
              100.01250338614412,
              4.613591578862867
            ],
            [
              100.57500298766413,
              3.266814816815753
            ],
            [
              101.25000250948804,
              2.592701464601845
            ],
            [
              102.15000187192001,
              2.086875539277429
            ],
            [
              102.68279723997185,
              1.726894676446761
            ],
            [
              103.34065102845304,
              1.327793781945104
            ],
            [
              103.50000091556822,
              1.243290124212365
            ],
            [
              103.91973061822782,
              1.17221597244378
            ]
          ],
          [
            [
              103.91973061822782,
              1.17221597244378
            ],
            [
              103.9870105705659,
              1.389451396800126
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tata-tgn-western-europe",
        "name": "Tata TGN-Western Europe",
        "color": "#613e98",
        "feature_id": "tata-tgn-western-europe-0",
        "coordinates": [
          -9.123172630879708,
          49.58613005355488
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -2.94919367482359,
              43.27422025200056
            ],
            [
              -4.049922895056767,
              44.694829089578064
            ],
            [
              -5.849921619920792,
              46.272182853813746
            ],
            [
              -6.974920822960797,
              48.1067757091962
            ],
            [
              -6.974920822960797,
              50.45463912589386
            ],
            [
              -5.399921938704722,
              51.02419288763869
            ],
            [
              -4.049922895056767,
              51.27119721416548
            ],
            [
              -2.974873656631701,
              51.22244696599773
            ],
            [
              -4.049922895056767,
              51.30637567738265
            ],
            [
              -5.399921938704722,
              51.09490046148102
            ],
            [
              -7.199920663568747,
              50.597677199053464
            ],
            [
              -10.799918113296798,
              48.70423463096077
            ],
            [
              -15.299914925456818,
              44.694829089578064
            ],
            [
              -15.299914925456818,
              39.69832335493328
            ],
            [
              -13.949915881808863,
              39.00237890905848
            ],
            [
              -10.799918113296798,
              38.651811712711236
            ],
            [
              -9.107439312264688,
              38.64265833034625
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "te-northtgn-eurasiaseacomalexandrosmedex",
        "name": "TE North/TGN-Eurasia/SEACOM/Alexandros/Medex",
        "color": "#3c54a4",
        "feature_id": "te-northtgn-eurasiaseacomalexandrosmedex-0",
        "coordinates": [
          15.326417266767237,
          33.97740194230826
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              8.10006849777534,
              37.94551049545976
            ],
            [
              7.987568577471222,
              37.232354321556215
            ],
            [
              7.755438741914361,
              36.90282046530186
            ]
          ],
          [
            [
              27.900054471279333,
              32.24321001626265
            ],
            [
              30.60005255857544,
              32.43331330641712
            ],
            [
              32.40005128343955,
              33.18971466460036
            ],
            [
              33.41255056617544,
              34.49779087043359
            ],
            [
              33.61060042587516,
              34.82728147271527
            ]
          ],
          [
            [
              5.372530429989041,
              43.29362778902916
            ],
            [
              6.075069932303194,
              41.744358789482135
            ],
            [
              7.425068975951234,
              38.651811712711236
            ],
            [
              8.10006849777534,
              37.94551049545976
            ],
            [
              9.000067859611391,
              37.85673997565843
            ],
            [
              10.348617229769246,
              37.85673997565843
            ],
            [
              10.912566505375338,
              37.500588446053314
            ],
            [
              11.700065946311412,
              37.232354321556215
            ],
            [
              11.812565867807308,
              36.240655233214795
            ],
            [
              12.262565549023208,
              35.78566189952613
            ],
            [
              12.65631527009242,
              35.419780517080454
            ],
            [
              14.4000640347993,
              34.2656775265242
            ],
            [
              16.65006244087931,
              33.565491482352144
            ],
            [
              19.35006052817539,
              33.93964008831958
            ],
            [
              22.05005861487558,
              33.87739543625136
            ],
            [
              25.20005638398345,
              33.14262853088806
            ],
            [
              27.900054471279333,
              32.24321001626265
            ],
            [
              29.700933195520264,
              31.072270031660224
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "telstra-endeavour",
        "name": "Telstra Endeavour",
        "color": "#393d98",
        "feature_id": "telstra-endeavour-0",
        "coordinates": [
          168.22007773832914,
          -24.283000798626006
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              151.22848710426095,
              -33.88203865028561
            ],
            [
              152.09996648689648,
              -33.92953699245888
            ],
            [
              154.80001816943465,
              -32.4230349944244
            ],
            [
              160.64997402527118,
              -30.697669744492572
            ],
            [
              166.49996988107907,
              -25.5408960762594
            ],
            [
              170.9999666932391,
              -22.25009967909017
            ],
            [
              173.69996478053517,
              -17.16855309422607
            ],
            [
              179.9999603175593,
              -8.401139048122928
            ]
          ],
          [
            [
              -179.99979825051417,
              -8.401139048122928
            ],
            [
              -176.39980080078612,
              -3.029995968008762
            ],
            [
              -172.79980335105807,
              2.367912558705314
            ],
            [
              -167.3998071764661,
              10.410816505402636
            ],
            [
              -163.79980972673806,
              14.801154224791475
            ],
            [
              -160.19981227701055,
              18.67864702215462
            ],
            [
              -158.84981323336194,
              20.796306105108954
            ],
            [
              -158.39981355214616,
              21.425997872385494
            ],
            [
              -158.24204999203226,
              21.54876173571651
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "thailand-indonesia-singapore-tis",
        "name": "Thailand-Indonesia-Singapore (TIS)",
        "color": "#79c042",
        "feature_id": "thailand-indonesia-singapore-tis-0",
        "coordinates": [
          104.39794658098717,
          5.261661736516999
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              103.96260058785819,
              1.134723598626692
            ],
            [
              104.03350053763214,
              1.290748482962698
            ],
            [
              103.9870105705659,
              1.389451396800126
            ],
            [
              104.19209042528556,
              1.302655424516928
            ],
            [
              104.28790035741284,
              1.355961034999325
            ],
            [
              104.37232529760544,
              1.468426767332062
            ],
            [
              104.65208343275523,
              2.367912558705314
            ],
            [
              104.38596586214547,
              5.398081130463737
            ],
            [
              103.03476681934731,
              6.893492138282852
            ],
            [
              101.70000219070411,
              7.075530930004675
            ],
            [
              100.59971297015935,
              7.075917959575207
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "the-east-african-marine-system-teams",
        "name": "The East African Marine System (TEAMS)",
        "color": "#73bf43",
        "feature_id": "the-east-african-marine-system-teams-0",
        "coordinates": [
          57.174355223971475,
          8.258620151746982
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.672896131287985,
              -4.052924364763157
            ],
            [
              42.30004427019156,
              -4.601453764837203
            ],
            [
              46.57504124174372,
              -2.355745736646099
            ],
            [
              53.100036619375715,
              1.468426767332062
            ],
            [
              56.250034388483755,
              5.510071711803135
            ],
            [
              57.600033431535735,
              9.524411345019587
            ],
            [
              62.10003024369573,
              15.669513225155328
            ],
            [
              63.0000296061277,
              20.796306105108954
            ],
            [
              63.0000296061277,
              22.469443964829594
            ],
            [
              59.85003183761572,
              24.327803111653537
            ],
            [
              58.50003279396768,
              24.66052224964892
            ],
            [
              56.9250339097116,
              24.915858493558915
            ],
            [
              56.333724328601164,
              25.121690004958737
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tobrok-emasaed-cable-system",
        "name": "Tobrok-Emasaed Cable System",
        "color": "#65ac44",
        "feature_id": "tobrok-emasaed-cable-system-0",
        "coordinates": [
          24.606382917057175,
          32.15675707571063
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              23.960407262162676,
              32.07985133144845
            ],
            [
              24.30005702155148,
              32.24321001626265
            ],
            [
              24.975056543375416,
              32.052708023486105
            ],
            [
              25.087356463821237,
              31.761227937714636
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tonga-cable",
        "name": "Tonga Cable",
        "color": "#42b549",
        "feature_id": "tonga-cable-0",
        "coordinates": [
          -177.62282479346848,
          -20.29710943833541
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -179.99979825051417,
              -19.30538407236122
            ],
            [
              -175.94980111957017,
              -20.995131543025877
            ],
            [
              -175.20000165073517,
              -21.13346565929308
            ]
          ],
          [
            [
              179.9999603175593,
              -19.30538407236122
            ],
            [
              179.09996095512733,
              -18.66711083815875
            ],
            [
              178.43744782917761,
              -18.12381094353711
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "trans-pacific-express-tpe-cable-system",
        "name": "Trans-Pacific Express (TPE) Cable System",
        "color": "#a2c539",
        "feature_id": "trans-pacific-express-tpe-cable-system-0",
        "coordinates": [
          -151.7250789081591,
          46.58235508209589
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              134.99997860068837,
              30.70813999354155
            ],
            [
              136.79997732555248,
              31.478822672736243
            ],
            [
              138.59997605041642,
              32.62301664000799
            ],
            [
              140.39997477528036,
              33.37780603565923
            ],
            [
              143.09997286257644,
              34.49779087043359
            ],
            [
              149.39996839960057,
              37.94551049545976
            ],
            [
              160.19996074878455,
              43.40114497315386
            ],
            [
              172.7999518228328,
              46.58235508209589
            ],
            [
              179.99995828233065,
              46.58235508209589
            ]
          ],
          [
            [
              132.74998019460836,
              30.320465424761352
            ],
            [
              132.74998019460836,
              30.70813999354155
            ],
            [
              130.49998178852834,
              30.70813999354155
            ],
            [
              128.92498290427224,
              31.67051304708704
            ],
            [
              128.02498354184027,
              33.18971466460036
            ],
            [
              128.36248330275228,
              34.31215165223537
            ],
            [
              128.62078311977027,
              34.88072781981958
            ],
            [
              127.91248362153638,
              34.31215165223537
            ],
            [
              127.12498417940836,
              33.18971466460036
            ],
            [
              125.99998497636832,
              32.81231878328768
            ],
            [
              124.19998625150438,
              33.18971466460036
            ],
            [
              122.84998720785634,
              33.93964008831958
            ],
            [
              120.8249886423844,
              35.419780517080454
            ],
            [
              120.34246898420574,
              36.087310907419294
            ],
            [
              120.59998880177616,
              35.419780517080454
            ],
            [
              122.39998752664027,
              33.93964008831958
            ],
            [
              123.74998657028831,
              33.00121852265437
            ],
            [
              124.19998625150438,
              31.861808602270827
            ]
          ],
          [
            [
              139.97546699999984,
              35.005433
            ],
            [
              140.10466248388653,
              34.89859296336232
            ],
            [
              140.06247501377246,
              34.69072647741018
            ],
            [
              139.94997509406446,
              34.40502275071583
            ],
            [
              139.0499757316325,
              33.93964008831958
            ],
            [
              136.79997732555248,
              31.861808602270827
            ],
            [
              134.99997860068837,
              31.094262827639596
            ],
            [
              132.74998019460836,
              30.70813999354155
            ]
          ],
          [
            [
              121.40818822924032,
              31.618965810829565
            ],
            [
              122.17498768603221,
              31.19054975154404
            ],
            [
              123.52498672968031,
              30.126049846722907
            ],
            [
              123.74998657028831,
              28.161052262220892
            ],
            [
              121.94998784542437,
              26.562513149236622
            ],
            [
              121.4999881642083,
              25.754704263415306
            ],
            [
              121.4625881907028,
              25.181712818924467
            ],
            [
              121.61248808451225,
              25.754704263415306
            ],
            [
              121.94998784542437,
              26.361086325391653
            ],
            [
              125.26189309097956,
              27.590347329291063
            ],
            [
              130.49998178852834,
              30.70813999354155
            ]
          ],
          [
            [
              134.99997860068837,
              30.70813999354155
            ],
            [
              132.74998019460836,
              30.320465424761352
            ],
            [
              130.49998178852834,
              30.514495959759188
            ],
            [
              124.19998625150438,
              31.861808602270827
            ],
            [
              122.84998720785634,
              31.957307911004875
            ],
            [
              121.94998784542437,
              31.766210259726904
            ],
            [
              121.39529823837174,
              31.619800328867854
            ]
          ],
          [
            [
              -123.94016937986757,
              45.64401775142804
            ],
            [
              -125.09983714216165,
              44.85455225626717
            ],
            [
              -129.59983395432175,
              44.051519228735145
            ],
            [
              -138.5998275786418,
              45.64654149518748
            ],
            [
              -151.19981865268997,
              46.58235508209589
            ],
            [
              -179.99979825051417,
              46.58235508209589
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "transworld-tw1",
        "name": "Transworld (TW1)",
        "color": "#61bb46",
        "feature_id": "transworld-tw1-0",
        "coordinates": [
          61.66320327305013,
          24.633379880846405
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              58.17620302337187,
              23.684877531684634
            ],
            [
              58.27508295332456,
              23.917101290935022
            ],
            [
              58.72503263457574,
              24.225251377401804
            ],
            [
              59.85003183761572,
              24.839312825594618
            ]
          ],
          [
            [
              56.333724328601164,
              25.121690004958737
            ],
            [
              56.9250339097116,
              25.068807301098985
            ],
            [
              58.72503263457574,
              25.145210227401265
            ],
            [
              59.85003183761572,
              24.839312825594618
            ],
            [
              62.55002992491163,
              24.532657566160623
            ],
            [
              67.02854675228852,
              24.889731701235718
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "trapani-kelibia",
        "name": "Trapani-Kelibia",
        "color": "#3859a8",
        "feature_id": "trapani-kelibia-0",
        "coordinates": [
          11.891845950276405,
          37.35162473013881
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              11.090886379051994,
              36.849936993962615
            ],
            [
              11.427366140686559,
              37.02916052566792
            ],
            [
              12.173015612461455,
              37.54682622128206
            ],
            [
              12.375065469327325,
              37.94551049545976
            ],
            [
              12.513735371092366,
              38.018253909057734
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tt-1",
        "name": "TT-1",
        "color": "#4abb84",
        "feature_id": "tt-1-0",
        "coordinates": [
          -60.970520635231395,
          11.021638590788513
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -60.94959258679687,
              10.831511900511515
            ],
            [
              -60.9748825688812,
              11.016811865272018
            ],
            [
              -60.84026266424726,
              11.165776379841446
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "turcyos-1",
        "name": "Turcyos-1",
        "color": "#97b93c",
        "feature_id": "turcyos-1-0",
        "coordinates": [
          33.033476586141205,
          35.67693164171169
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              32.96670088142375,
              36.099997364015536
            ],
            [
              32.96255088436365,
              35.90725015614032
            ],
            [
              33.07505080466757,
              35.541926812580044
            ],
            [
              33.236280691046744,
              35.299121866076945
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "turcyos-2",
        "name": "Turcyos-2",
        "color": "#81c341",
        "feature_id": "turcyos-2-0",
        "coordinates": [
          35.016392666816955,
          35.49639512283801
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              35.99936873364928,
              36.08234081367351
            ],
            [
              35.55004905195153,
              35.78566189952613
            ],
            [
              34.8750495301276,
              35.419780517080454
            ],
            [
              34.20005000830349,
              35.23621340052833
            ],
            [
              33.91945020708306,
              35.28438909238437
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "uae-iran",
        "name": "UAE-Iran",
        "color": "#d41f26",
        "feature_id": "uae-iran-0",
        "coordinates": [
          57.21294433749429,
          25.15368101875805
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              56.333724328601164,
              25.121690004958737
            ],
            [
              56.9250339097116,
              25.094280247013245
            ],
            [
              57.603643428978444,
              25.23428882352877
            ],
            [
              57.79730329178804,
              25.6813227078824
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "ugarit",
        "name": "UGARIT",
        "color": "#267cb5",
        "feature_id": "ugarit-0",
        "coordinates": [
          34.75274294807667,
          34.82107849658101
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              35.897798805602406,
              34.89170328553857
            ],
            [
              35.55004905195153,
              34.867831005273246
            ],
            [
              33.97505016769546,
              34.77547607575676
            ],
            [
              33.61060042587516,
              34.82728147271527
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "uk-channel-islands-8",
        "name": "UK-Channel Islands-8",
        "color": "#8fc73e",
        "feature_id": "uk-channel-islands-8-0",
        "coordinates": [
          -3.756824035935381,
          49.46667101389008
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -2.225994187144892,
              49.21570868235557
            ],
            [
              -2.420674049231877,
              49.218648051531005
            ],
            [
              -3.599923213840782,
              49.441203723128126
            ],
            [
              -4.499922576272752,
              49.58728674004675
            ],
            [
              -5.17297209947813,
              50.02254139117184
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "unityeac-pacific",
        "name": "Unity/EAC-Pacific",
        "color": "#77459a",
        "feature_id": "unityeac-pacific-0",
        "coordinates": [
          -148.18242739340178,
          44.066347113909586
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -118.388023453319,
              33.79790529169577
            ],
            [
              -120.59984033000163,
              33.79952573458132
            ],
            [
              -122.39983905486565,
              34.12610104005762
            ],
            [
              -129.59983395432175,
              37.94551049545976
            ],
            [
              -138.59982757864196,
              42.079235618164205
            ],
            [
              -151.19983391146837,
              44.69205655553225
            ],
            [
              -179.99981350929244,
              44.69205655553225
            ]
          ],
          [
            [
              139.9548550906076,
              34.97657002902224
            ],
            [
              140.06247501377246,
              34.96776525378368
            ],
            [
              140.3437248151284,
              34.96008232454844
            ],
            [
              141.2999741377125,
              34.867831005273246
            ],
            [
              143.09997286257644,
              34.867831005273246
            ],
            [
              149.39996839960057,
              36.14986678681771
            ],
            [
              160.19996074878455,
              41.40772623743587
            ],
            [
              172.79992073307184,
              44.694811588752685
            ],
            [
              179.9999271925697,
              44.694811588752685
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "west-africa-cable-system-wacs",
        "name": "West Africa Cable System (WACS)",
        "color": "#394da1",
        "feature_id": "west-africa-cable-system-wacs-0",
        "coordinates": [
          -6.06677813221728,
          -0.7813866362255055
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              14.533463940297622,
              -22.68542973223063
            ],
            [
              13.05006499115126,
              -22.87343495354625
            ],
            [
              9.90006722263928,
              -23.493922445897766
            ]
          ],
          [
            [
              12.349965487108477,
              -5.933373731471249
            ],
            [
              10.80006658507125,
              -5.945707155070551
            ],
            [
              8.10006849777534,
              -6.616650693475464
            ]
          ],
          [
            [
              9.00006786020731,
              -10.620064860363328
            ],
            [
              11.70006594750339,
              -9.290424301035614
            ],
            [
              12.60006530993536,
              -9.290424301035614
            ],
            [
              13.20144038206871,
              -9.490080988806199
            ]
          ],
          [
            [
              10.80006658507125,
              -5.945707155070551
            ],
            [
              11.250066265691402,
              -5.236602021940371
            ],
            [
              11.863635831628983,
              -4.778787768919273
            ]
          ],
          [
            [
              4.500071048047289,
              0.118588418888407
            ],
            [
              6.300069772911229,
              1.693340822791811
            ],
            [
              8.10006849777534,
              2.367912558705314
            ],
            [
              9.00006786020731,
              3.266814816815753
            ],
            [
              9.20865771244047,
              4.014706479784223
            ]
          ],
          [
            [
              3.423511810692077,
              6.439066911484701
            ],
            [
              3.375071845007284,
              4.164912849976844
            ],
            [
              2.250072641967279,
              2.030661890474562
            ]
          ],
          [
            [
              1.2278033661525,
              6.126307297218631
            ],
            [
              1.462573199243337,
              5.061986954416028
            ],
            [
              3.375071845007284,
              4.164912849976844
            ]
          ],
          [
            [
              -9.107439312264688,
              38.64265833034625
            ],
            [
              -9.449919069648843,
              38.29952060596935
            ],
            [
              -11.024917953904747,
              36.51238821239372
            ],
            [
              -11.924917316336803,
              35.419780517080454
            ],
            [
              -13.499916200592793,
              32.052708023486105
            ],
            [
              -14.624915403632798,
              30.514495959759188
            ],
            [
              -15.299914925456818,
              28.951554732193216
            ],
            [
              -16.199914287888873,
              27.763588526057674
            ],
            [
              -17.999913012752813,
              24.941363171753835
            ],
            [
              -18.89991237518487,
              22.884654113882362
            ],
            [
              -20.24991141883291,
              19.952622905164304
            ],
            [
              -20.24991141883291,
              15.23578178303569
            ],
            [
              -20.24991141883291,
              11.735650161405744
            ],
            [
              -18.89991237518487,
              8.635699417327544
            ],
            [
              -14.399915563024848,
              2.817450442654064
            ],
            [
              -10.799918113296798,
              -0.781386636225506
            ],
            [
              -3.599923213840782,
              -0.781386636225506
            ],
            [
              0.225074076495304,
              0.131631856785162
            ],
            [
              1.575073120143173,
              1.355961034999325
            ],
            [
              2.250072641967279,
              2.030661890474562
            ],
            [
              4.500071048047289,
              0.118588418888407
            ],
            [
              6.300069772911229,
              -1.231315750217505
            ],
            [
              7.650068816559269,
              -4.825692499217524
            ],
            [
              8.10006849777534,
              -6.616650693475464
            ],
            [
              9.00006786020731,
              -10.620064860363328
            ],
            [
              8.55006817899124,
              -18.026426383713385
            ],
            [
              9.90006722263928,
              -23.493922445897766
            ],
            [
              13.9500643535834,
              -30.309953344646914
            ],
            [
              15.75006307844734,
              -32.61276000573585
            ],
            [
              16.65006244087931,
              -33.273630771422376
            ],
            [
              18.155531374391046,
              -33.34805845646778
            ]
          ],
          [
            [
              -0.204315619320994,
              5.558285889905761
            ],
            [
              0.225074076495304,
              3.279837005485092
            ],
            [
              0.225074076495304,
              0.131631856785162
            ]
          ],
          [
            [
              -4.026242911831901,
              5.323508791824736
            ],
            [
              -4.16242281536077,
              3.266814816815753
            ],
            [
              -4.274922735664717,
              2.367912558705314
            ],
            [
              -4.149922824215793,
              1.468426767332062
            ],
            [
              -3.599923213840782,
              -0.781386636225506
            ]
          ],
          [
            [
              -23.521209101414883,
              14.923035560171769
            ],
            [
              -22.49990982491292,
              15.018578573757566
            ],
            [
              -20.24991141883291,
              15.018578573757566
            ]
          ],
          [
            [
              -16.199914287888873,
              27.763588526057674
            ],
            [
              -15.749914606672803,
              27.56430948794184
            ],
            [
              -15.468664805912795,
              27.56430948794184
            ],
            [
              -15.299914925456818,
              27.663994423746914
            ],
            [
              -15.299914925456818,
              27.813351446514247
            ],
            [
              -15.398744855444761,
              27.95939426104593
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "cross-straits-cable-network-cscn",
        "name": "Cross-Straits Cable Network (CSCN)",
        "color": "#1e92d1",
        "feature_id": "cross-straits-cable-network-cscn-0",
        "coordinates": [
          118.23949047397545,
          24.474717526356784
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              118.30692042620748,
              24.476050879560063
            ],
            [
              118.30389542835044,
              24.51639519463228
            ],
            [
              118.3008704304934,
              24.556726557732503
            ]
          ],
          [
            [
              118.18905050970758,
              24.493915539480668
            ],
            [
              118.23949047397545,
              24.474717526356784
            ],
            [
              118.28993043824354,
              24.45551658468574
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "yellow",
        "name": "Yellow",
        "color": "#b99633",
        "feature_id": "yellow-0",
        "coordinates": [
          -38.58918293813397,
          43.98165289376618
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -72.93786409419286,
              40.75584308487123
            ],
            [
              -71.09987539624133,
              40.129762553931045
            ],
            [
              -68.39987730894534,
              40.387320290775165
            ],
            [
              -61.199882409489234,
              40.387320290775165
            ],
            [
              -50.39989006030518,
              41.57626183009805
            ],
            [
              -39.59989771112103,
              43.72721479104973
            ],
            [
              -23.39990918734489,
              47.805412175893
            ],
            [
              -16.199914287888873,
              49.00033438946335
            ],
            [
              -10.799918113296798,
              50.02292045625484
            ],
            [
              -8.099920026000802,
              50.31116725161084
            ],
            [
              -5.399921938704722,
              50.81142185928286
            ],
            [
              -4.544402544762761,
              50.82820142743802
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "baltic-sea-submarine-cable",
        "name": "Baltic Sea Submarine Cable",
        "color": "#2fbfcc",
        "feature_id": "baltic-sea-submarine-cable-0",
        "coordinates": [
          20.74983052488085,
          59.55948018659498
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              21.375059093647366,
              59.53737813890871
            ],
            [
              20.25005989060736,
              59.451717318905224
            ],
            [
              18.90006084695932,
              59.33716441962133
            ],
            [
              18.062761440110165,
              59.332309018187175
            ],
            [
              18.90006084695932,
              59.394489266856795
            ],
            [
              20.25005989060736,
              59.50884868221257
            ],
            [
              21.375059093647366,
              59.622821769410336
            ],
            [
              23.85005734033541,
              59.6796637072089
            ],
            [
              24.4125569418554,
              59.79305890746801
            ],
            [
              24.93247657353959,
              60.171163188940454
            ],
            [
              24.918806583223443,
              60.07486799642308
            ],
            [
              24.86255662307147,
              59.962431634152296
            ],
            [
              24.75005670276738,
              59.6796637072089
            ],
            [
              24.752496701038922,
              59.43639985926242
            ],
            [
              24.4125569418554,
              59.56588346342974
            ],
            [
              23.85005734033541,
              59.56588346342974
            ],
            [
              21.375059093647366,
              59.53737813890871
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "solas",
        "name": "Solas",
        "color": "#af3143",
        "feature_id": "solas-0",
        "coordinates": [
          -5.483009534617139,
          51.47267219749361
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -4.169502810345278,
              51.55809471609601
            ],
            [
              -5.399921938704722,
              51.44682015166964
            ],
            [
              -5.849921620516682,
              51.586833980054195
            ],
            [
              -6.585031099162222,
              52.17164795778544
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "pan-european-crossing-uk-ireland",
        "name": "Pan European Crossing (UK-Ireland)",
        "color": "#59b947",
        "feature_id": "pan-european-crossing-uk-ireland-0",
        "coordinates": [
          -5.44635191162584,
          51.64417593731625
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -6.558811117736752,
              52.18425556836888
            ],
            [
              -6.074921461124717,
              51.586833980054195
            ],
            [
              -5.737421700212764,
              50.740281893948264
            ],
            [
              -5.698461727216397,
              50.07870033214297
            ]
          ],
          [
            [
              -4.544402544762761,
              50.82820142743802
            ],
            [
              -5.399921938704722,
              51.586833980054195
            ],
            [
              -5.849921619920792,
              52.14259270367222
            ],
            [
              -6.364861255132695,
              52.40176042819701
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "alaska-united-southeast-au-se",
        "name": "Alaska United Southeast (AU-SE)",
        "color": "#7e439a",
        "feature_id": "alaska-united-southeast-au-se-0",
        "coordinates": [
          -133.68772704901264,
          57.53296530197474
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -135.33440102024426,
              57.05301929960612
            ],
            [
              -135.47615091624644,
              57.14285437334877
            ],
            [
              -135.56560085061957,
              57.25632706167514
            ],
            [
              -135.62014081060522,
              57.29294273759123
            ],
            [
              -135.6847407632099,
              57.32790795452425
            ],
            [
              -135.70291074987915,
              57.35154490127687
            ],
            [
              -135.6523707869589,
              57.37664384009423
            ],
            [
              -135.61692081296758,
              57.39764169906019
            ],
            [
              -135.57234084567466,
              57.43153840198835
            ],
            [
              -135.5297608769144,
              57.465796336984425
            ],
            [
              -135.5324008749775,
              57.50700103750668
            ],
            [
              -135.5324008749775,
              57.54920559971703
            ],
            [
              -135.5054008947866,
              57.56853862106081
            ],
            [
              -135.41858095848391,
              57.58377679148188
            ],
            [
              -135.32532102690607,
              57.56374387311726
            ],
            [
              -135.16311114591485,
              57.4973463454385
            ],
            [
              -135.0597912217176,
              57.44987929314735
            ],
            [
              -134.9697512877774,
              57.430461772962474
            ],
            [
              -134.85725137031537,
              57.41589725419246
            ],
            [
              -134.771701433081,
              57.425573478879414
            ],
            [
              -134.58225157207494,
              57.50194029871798
            ],
            [
              -134.6965153443809,
              57.59460906161556
            ],
            [
              -134.76067779892767,
              57.734649479402236
            ],
            [
              -134.81067776350721,
              58.01297892893797
            ],
            [
              -134.81292411835494,
              58.07747851820187
            ],
            [
              -134.74172145507637,
              58.12820451017103
            ],
            [
              -134.6361615325227,
              58.2129773863893
            ],
            [
              -134.71174147707183,
              58.2916726856476
            ],
            [
              -134.70139148466535,
              58.30999281015934
            ],
            [
              -134.60315155674118,
              58.326917289684246
            ],
            [
              -134.5422116014511,
              58.31889328642495
            ],
            [
              -134.40686170075347,
              58.29957675077529
            ],
            [
              -134.29583062763368,
              58.237094932490095
            ],
            [
              -134.13655189907206,
              58.16513091439583
            ],
            [
              -134.12483074877156,
              58.10124945897567
            ],
            [
              -133.9914308432734,
              57.98640326883037
            ],
            [
              -133.82473096136502,
              57.823712369918354
            ],
            [
              -133.80256214411088,
              57.707894171078124
            ],
            [
              -133.6914310557961,
              57.558771470979536
            ],
            [
              -133.66477224520335,
              57.37303699157914
            ],
            [
              -133.63049227035367,
              57.31396150003877
            ],
            [
              -133.65813107938607,
              57.22966820564031
            ],
            [
              -133.62948227169068,
              57.13766184463276
            ],
            [
              -133.5163311798385,
              57.09442219161832
            ],
            [
              -133.2329425620245,
              57.051159243807234
            ],
            [
              -133.00823153978095,
              57.02097743143682
            ],
            [
              -132.9702427547599,
              56.98329605125812
            ],
            [
              -132.90110280548586,
              56.91336556277733
            ],
            [
              -132.96985275504602,
              56.80779122608294
            ],
            [
              -132.88744914822183,
              56.722763716398305
            ],
            [
              -132.8451078478744,
              56.66104033500301
            ],
            [
              -132.76073290764646,
              56.5991606623343
            ],
            [
              -132.7043829504098,
              56.53717947317284
            ],
            [
              -132.53558307425345,
              56.47504144368915
            ],
            [
              -132.38381318500652,
              56.470788497078956
            ],
            [
              -132.4590131298345,
              56.38622181235951
            ],
            [
              -132.5197530852713,
              56.36805414595554
            ],
            [
              -132.59182303239572,
              56.35047071868672
            ],
            [
              -132.59719302845582,
              56.31317190855842
            ],
            [
              -132.62600300731884,
              56.25964372839678
            ],
            [
              -132.72971293122976,
              56.23289599183161
            ],
            [
              -132.84104284955018,
              56.19050811225165
            ],
            [
              -132.8550128393008,
              56.12703903462045
            ],
            [
              -132.74182292234502,
              56.06679221717033
            ],
            [
              -132.61350301648972,
              56.00634486676583
            ],
            [
              -132.4514931353517,
              55.89266381393949
            ],
            [
              -132.3434832145955,
              55.7976529155012
            ],
            [
              -132.27600326410362,
              55.63377825521394
            ],
            [
              -132.13362336856375,
              55.526742438171766
            ],
            [
              -131.9755134845644,
              55.45213247504748
            ],
            [
              -131.91331353019882,
              55.4661995720777
            ],
            [
              -131.82532359475445,
              55.434270637976795
            ],
            [
              -131.64788372493703,
              55.34196841314818
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "america-movil-submarine-cable-system-1-amx-1",
        "name": "America Movil Submarine Cable System-1 (AMX-1)",
        "color": "#3e60ac",
        "feature_id": "america-movil-submarine-cable-system-1-amx-1-0",
        "coordinates": [
          -45.682834902323236,
          8.348070685535163
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -83.03765938887453,
              9.988597517410057
            ],
            [
              -83.02486694906139,
              10.410816505402636
            ],
            [
              -82.12486758662942,
              11.66220822386424
            ],
            [
              -82.23736750693344,
              12.98107818789262
            ],
            [
              -82.12486758662942,
              13.419186961310118
            ],
            [
              -81.89986774602139,
              14.074846740630335
            ],
            [
              -80.54986870177734,
              16.10232559580288
            ]
          ],
          [
            [
              -81.73000034886284,
              12.551127304038545
            ],
            [
              -81.89986774602139,
              12.871429169544712
            ],
            [
              -82.23736750693344,
              12.98107818789262
            ]
          ],
          [
            [
              -70.68356569115909,
              19.803388017159477
            ],
            [
              -70.19987603380928,
              20.058334551396143
            ],
            [
              -69.2998766713773,
              19.952622905164304
            ],
            [
              -67.0498782652973,
              19.210675111642757
            ],
            [
              -66.48737866377729,
              18.89166158430325
            ],
            [
              -66.10666893347562,
              18.466104232947515
            ]
          ],
          [
            [
              -70.69118568576094,
              19.79943635579707
            ],
            [
              -70.64987571502535,
              20.375041253465525
            ],
            [
              -70.64987571502535,
              21.21639789994191
            ]
          ],
          [
            [
              -69.2998766713773,
              22.261369678340685
            ],
            [
              -70.64987571502535,
              21.21639789994191
            ],
            [
              -73.34987380232134,
              20.585819096040467
            ],
            [
              -73.91237340384134,
              19.952622905164304
            ],
            [
              -74.47487300536135,
              19.316876111628602
            ],
            [
              -74.92487268657733,
              18.251816319028308
            ],
            [
              -75.26237244748938,
              17.39502263470061
            ],
            [
              -75.26237244748938,
              11.735650161405744
            ],
            [
              -74.77975278938158,
              10.94044561572673
            ]
          ],
          [
            [
              -86.76758665233308,
              21.095728792367282
            ],
            [
              -86.51236447788934,
              21.111485983488905
            ],
            [
              -86.17486471697737,
              21.21639789994191
            ],
            [
              -85.04986551393736,
              21.635297384859456
            ]
          ],
          [
            [
              -75.50573227509092,
              10.386807451633246
            ],
            [
              -76.04987188961734,
              11.515266158038685
            ],
            [
              -79.19986965872539,
              14.94612821851294
            ],
            [
              -80.54986870177734,
              16.10232559580288
            ],
            [
              -81.8998677454253,
              17.39502263470061
            ],
            [
              -84.59986583272129,
              19.104405475930548
            ],
            [
              -85.2748653545454,
              20.375041253465525
            ],
            [
              -85.04986551393736,
              21.635297384859456
            ],
            [
              -84.82486567332933,
              22.469443964829594
            ],
            [
              -83.24986678907334,
              23.50508968095727
            ],
            [
              -80.99986838299351,
              23.50508968095727
            ],
            [
              -79.64986933934539,
              24.73717827217618
            ],
            [
              -79.53736941904137,
              25.348717422116806
            ],
            [
              -80.16016897784436,
              26.01054866801087
            ]
          ],
          [
            [
              -88.59713531004533,
              15.72723663872111
            ],
            [
              -88.19986328244953,
              16.10232559580288
            ],
            [
              -87.63736368092934,
              16.53419619825962
            ],
            [
              -86.73736431849737,
              18.251816319028308
            ],
            [
              -85.2748653545454,
              20.375041253465525
            ]
          ],
          [
            [
              -38.50448848711929,
              -12.969972035343062
            ],
            [
              -37.34989930504102,
              -13.455974309054454
            ],
            [
              -35.999900261393066,
              -13.504596782343953
            ],
            [
              -35.549900580176995,
              -13.698987269610853
            ]
          ],
          [
            [
              -43.209565153998795,
              -22.903486555497864
            ],
            [
              -42.299895798417,
              -23.49405668871546
            ],
            [
              -40.94989675476907,
              -23.493922445897766
            ],
            [
              -38.69989834868906,
              -22.25009967909017
            ],
            [
              -36.44989994260905,
              -18.026426383713385
            ],
            [
              -35.549900580176995,
              -13.698987269610853
            ],
            [
              -33.299902174096985,
              -8.401139048122928
            ],
            [
              -33.299902174096985,
              -6.616650693475464
            ],
            [
              -34.199901536528955,
              -4.825692499217524
            ],
            [
              -35.999900261393066,
              -3.928327304142726
            ],
            [
              -38.54296845985962,
              -3.718735129291019
            ],
            [
              -38.249898667473076,
              -1.231315750217505
            ],
            [
              -39.149898029905046,
              1.468426767332062
            ],
            [
              -46.79989261057713,
              9.524411345019587
            ],
            [
              -52.19988878516919,
              14.801154224791475
            ],
            [
              -57.599884959761184,
              17.39502263470061
            ],
            [
              -59.39988368462521,
              18.251816319028308
            ],
            [
              -62.099881771921204,
              19.316876111628602
            ],
            [
              -63.899880496785315,
              19.316876111628602
            ],
            [
              -65.69987922164925,
              19.529070924351004
            ],
            [
              -67.49987794651328,
              21.21639789994191
            ],
            [
              -69.2998766713773,
              22.261369678340685
            ],
            [
              -73.34987380232134,
              24.32780311165172
            ],
            [
              -76.94987125204939,
              26.964304734562802
            ],
            [
              -79.1998696581294,
              29.1482487910327
            ],
            [
              -80.54986870177734,
              30.126049846722907
            ],
            [
              -81.65563040282986,
              30.332100867462543
            ]
          ],
          [
            [
              -65.69987922164925,
              19.529070924351004
            ],
            [
              -66.03737898256131,
              19.104405475930548
            ],
            [
              -66.10666893347562,
              18.466104232947515
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "america-movil-submarine-cable-system-1-amx-1",
        "name": "America Movil Submarine Cable System-1 (AMX-1)",
        "color": "#939597",
        "feature_id": "america-movil-submarine-cable-system-1-amx-1-1",
        "coordinates": [
          -70.80240522628634,
          17.16804012828206
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -69.86237627289731,
              17.39502263470061
            ],
            [
              -69.74987635318928,
              17.823934412537824
            ],
            [
              -69.80612631334124,
              18.144943564296312
            ],
            [
              -69.94053621812408,
              18.48680896806178
            ]
          ],
          [
            [
              -66.62663637762581,
              17.982654263677432
            ],
            [
              -66.82487842647723,
              17.609605913225096
            ],
            [
              -67.49987794651328,
              17.287636299591014
            ],
            [
              -69.86237627289731,
              17.39502263470061
            ],
            [
              -70.64987571502535,
              17.180187287481402
            ],
            [
              -71.99987475867339,
              17.0726759398961
            ],
            [
              -74.69987284596938,
              17.180187287481402
            ],
            [
              -75.26237244748938,
              17.39502263470061
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "avassa",
        "name": "Avassa",
        "color": "#bf6b28",
        "feature_id": "avassa-0",
        "coordinates": [
          44.855318309604485,
          -12.139111568457054
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              44.400042782532864,
              -12.166274798674806
            ],
            [
              44.400042782532864,
              -11.92173051164147
            ],
            [
              44.650042605430684,
              -11.92173051164147
            ],
            [
              45.2332941453748,
              -12.53937708921714
            ],
            [
              45.2332941453748,
              -12.783297381732638
            ]
          ],
          [
            [
              43.48920358402995,
              -11.923115980293488
            ],
            [
              43.48920358402995,
              -12.045379517998498
            ],
            [
              43.31254355292762,
              -12.053986862571492
            ],
            [
              43.200043632623704,
              -11.943944931746927
            ],
            [
              43.24330532072665,
              -11.700586528216121
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "indigo-central",
        "name": "INDIGO-Central",
        "color": "#a4bb39",
        "feature_id": "indigo-central-0",
        "coordinates": [
          132.73934944760762,
          -36.496860027874845
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              151.1962571270927,
              -33.9135716055703
            ],
            [
              151.5737949846414,
              -34.826981990554685
            ],
            [
              150.49996762035082,
              -37.94245352569678
            ],
            [
              149.39996839960057,
              -38.81782397325085
            ],
            [
              147.59996967473646,
              -39.341800653968136
            ],
            [
              146.24997063108842,
              -39.515593876112206
            ],
            [
              144.4499719056286,
              -39.341800653968136
            ],
            [
              143.9999722250084,
              -39.341800653968136
            ],
            [
              142.19997350014447,
              -39.16757423638754
            ],
            [
              140.39997477528036,
              -38.81782397325085
            ],
            [
              134.85085761257702,
              -36.67635860580856
            ],
            [
              127.09256232737633,
              -36.01682861014626
            ],
            [
              119.55018485796725,
              -36.07920493188047
            ],
            [
              115.44676979611498,
              -35.91724009701011
            ],
            [
              114.05321609582157,
              -34.61129978545125
            ],
            [
              114.05321609582157,
              -33.061828893734884
            ],
            [
              115.85731638027985,
              -31.953438943980444
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "eastern-africa-submarine-system-eassy",
        "name": "Eastern Africa Submarine System (EASSy)",
        "color": "#28a27f",
        "feature_id": "eastern-africa-submarine-system-eassy-0",
        "coordinates": [
          55.41468599005725,
          10.412568025506687
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              51.300037894511576,
              1.468426767332062
            ],
            [
              46.800041082351555,
              2.255504211923693
            ],
            [
              45.34418211369595,
              2.041205223228673
            ]
          ],
          [
            [
              43.24330360197786,
              -11.70058928227246
            ],
            [
              42.75004395140763,
              -11.943944931746927
            ],
            [
              42.0750444295835,
              -11.943944931746927
            ]
          ],
          [
            [
              43.66314330455961,
              -23.354724804059884
            ],
            [
              42.75004395140763,
              -23.597055964074997
            ],
            [
              41.40004490775959,
              -23.493922445897766
            ]
          ],
          [
            [
              32.580621155522095,
              -25.968268155408055
            ],
            [
              33.75005032708759,
              -26.450941899317456
            ],
            [
              35.55004905195153,
              -26.752713396100035
            ]
          ],
          [
            [
              39.672896131287985,
              -4.052924364763157
            ],
            [
              42.30004427019156,
              -4.15276774801373
            ]
          ],
          [
            [
              40.50004554532762,
              -6.169450529574419
            ],
            [
              41.62504474836763,
              -9.290424301035614
            ],
            [
              42.0750444295835,
              -11.943944931746927
            ],
            [
              42.52504411020368,
              -14.861883917662055
            ],
            [
              42.5250441107996,
              -15.22403228464728
            ],
            [
              42.5250441107996,
              -20.57441905727606
            ],
            [
              41.40004490775959,
              -23.493922445897766
            ],
            [
              35.55004905195153,
              -26.752713396100035
            ],
            [
              33.75005032708759,
              -28.546349210475853
            ],
            [
              32.85005096465545,
              -28.940898806450065
            ],
            [
              31.757961738301788,
              -28.95055966653794
            ]
          ],
          [
            [
              37.21967786917094,
              19.615566594546237
            ],
            [
              37.80004745803154,
              19.529070924351004
            ],
            [
              39.037546581971725,
              18.251816319028308
            ],
            [
              40.05004586470963,
              16.53419619825962
            ],
            [
              41.62504474896372,
              14.801154224791475
            ],
            [
              42.13129439033173,
              13.929303843271725
            ],
            [
              42.77816893267567,
              13.054150695298716
            ],
            [
              43.12973118362578,
              12.834868817846598
            ],
            [
              43.21410612385381,
              12.615395567393307
            ],
            [
              43.453168453307626,
              12.395734000022884
            ],
            [
              44.55004267627157,
              11.405009147532837
            ],
            [
              45.450042038703714,
              11.294709319565555
            ],
            [
              48.600039807215495,
              12.06589527357022
            ],
            [
              52.650036938159616,
              13.054150695298716
            ],
            [
              54.000035981807684,
              13.273238157547667
            ],
            [
              55.57503486606376,
              12.615395567393307
            ],
            [
              55.350035025455725,
              9.524411345019587
            ],
            [
              54.450035663619815,
              5.510071711803135
            ],
            [
              51.300037894511576,
              1.468426767332062
            ],
            [
              46.12504156052765,
              -1.906058394384871
            ],
            [
              42.30004427019156,
              -4.15276774801373
            ],
            [
              40.95004522654352,
              -5.497950688314972
            ],
            [
              40.50004554532762,
              -6.169450529574419
            ],
            [
              39.8250460229076,
              -6.523516459651859
            ],
            [
              39.2696764169327,
              -6.823132108349142
            ]
          ],
          [
            [
              43.16164365982672,
              11.573660387694133
            ],
            [
              43.6500433138396,
              11.565447393081172
            ],
            [
              44.55004267627157,
              11.405009147532837
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "dumai-melaka-cable-system",
        "name": "Dumai-Melaka Cable System",
        "color": "#6bbd44",
        "feature_id": "dumai-melaka-cable-system-0",
        "coordinates": [
          101.5821093055574,
          2.1897261952500457
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              101.44766236946415,
              1.665522797277134
            ],
            [
              101.4187523899441,
              2.143087178471944
            ],
            [
              101.81250211100806,
              2.255504211923693
            ],
            [
              102.22090182169396,
              2.273260323566632
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "aden-djibouti",
        "name": "Aden-Djibouti",
        "color": "#08acdb",
        "feature_id": "aden-djibouti-0",
        "coordinates": [
          44.07775301842676,
          12.217167550728703
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              43.14799288824679,
              11.594881329364313
            ],
            [
              43.6500433138396,
              11.955956037776701
            ],
            [
              45.033538427508574,
              12.800886250786618
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "chuuk-pohnpei-cable",
        "name": "Chuuk-Pohnpei Cable",
        "color": "#5abb5d",
        "feature_id": "chuuk-pohnpei-cable-0",
        "coordinates": [
          155.44471102712166,
          7.744889052551343
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              151.85848716577576,
              7.4399935397999
            ],
            [
              152.99996584932862,
              7.744889052551343
            ],
            [
              157.94996234270454,
              7.744889052551343
            ],
            [
              159.07026154907427,
              7.786404758723677
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "pacific-light-cable-network-plcn",
        "name": "Pacific Light Cable Network (PLCN)",
        "color": "#bc3e96",
        "feature_id": "pacific-light-cable-network-plcn-0",
        "coordinates": [
          -148.3938292404003,
          42.8039046118129
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -118.41596126184774,
              33.8732299876869
            ],
            [
              -120.59984033000163,
              33.61234928506826
            ],
            [
              -122.39983905486565,
              33.75276987113051
            ],
            [
              -129.59983395432175,
              37.232354321556215
            ],
            [
              -138.59982757864196,
              40.72920412488665
            ],
            [
              -151.19983391146837,
              43.39831121479662
            ],
            [
              -179.99981350929244,
              43.39831121479662
            ]
          ],
          [
            [
              125.99998497636832,
              21.00649984517682
            ],
            [
              130.94998146974442,
              22.469443964829594
            ],
            [
              132.74998019460836,
              23.298598065875808
            ],
            [
              138.59997605041642,
              25.754704263415306
            ],
            [
              160.19996074878455,
              40.04369219282995
            ],
            [
              172.79992073307184,
              43.40112708585761
            ],
            [
              179.9999271925697,
              43.40112708585761
            ]
          ],
          [
            [
              125.99998497636832,
              21.00649984517682
            ],
            [
              124.14876558466457,
              23.551705022234756
            ],
            [
              122.84998720785634,
              24.532657566160623
            ],
            [
              122.17498768603221,
              24.788256058863453
            ],
            [
              121.80145279439776,
              24.863504254255304
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "pacific-light-cable-network-plcn",
        "name": "Pacific Light Cable Network (PLCN)",
        "color": "#939597",
        "feature_id": "pacific-light-cable-network-plcn-1",
        "coordinates": [
          123.7420762068696,
          18.28965084436581
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              125.99998497636832,
              21.00649984517682
            ],
            [
              124.6499859321244,
              19.246083087004678
            ],
            [
              123.29998688907224,
              17.823934412537824
            ],
            [
              122.39998752664027,
              16.10232559580288
            ],
            [
              122.17498768603221,
              15.886035719079104
            ],
            [
              121.94998784542437,
              15.77780337181727
            ],
            [
              121.56018812156228,
              15.761539465842048
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "colombia-florida-subsea-fiber-cfx-1",
        "name": "Colombia-Florida Subsea Fiber (CFX-1)",
        "color": "#ca2e32",
        "feature_id": "colombia-florida-subsea-fiber-cfx-1-0",
        "coordinates": [
          -74.14918274409895,
          19.81212624538324
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -80.08893152830953,
              26.350585697437936
            ],
            [
              -79.42486949873734,
              25.95717997876443
            ],
            [
              -79.31236957843342,
              25.348717422116806
            ],
            [
              -78.97486981752137,
              24.73717827217618
            ],
            [
              -78.97486981752137,
              23.711258142484382
            ],
            [
              -78.29987029569733,
              22.884654113882362
            ],
            [
              -76.04987188961734,
              21.635297384859456
            ],
            [
              -74.69987284596938,
              21.00649984517682
            ],
            [
              -73.96862336399339,
              20.375041253465525
            ],
            [
              -73.91237340384134,
              20.16397503197578
            ],
            [
              -74.02487332414536,
              19.952622905164304
            ],
            [
              -74.58737292566536,
              19.316876111628602
            ],
            [
              -75.03737260688135,
              18.251816319028308
            ],
            [
              -75.59987220840135,
              17.39502263470061
            ],
            [
              -75.59987220840135,
              11.073982781226704
            ],
            [
              -75.50573165009138,
              10.386791448721794
            ]
          ],
          [
            [
              -76.68936118269117,
              17.94295278588291
            ],
            [
              -76.4998715708334,
              17.823934412537824
            ],
            [
              -76.27487173022537,
              17.823934412537824
            ],
            [
              -76.18434445060572,
              17.918365683338692
            ],
            [
              -76.04987188961734,
              17.823934412537824
            ],
            [
              -75.59987220840135,
              17.39502263470061
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "imewe",
        "name": "IMEWE",
        "color": "#2966b1",
        "feature_id": "imewe-0",
        "coordinates": [
          48.42281650540946,
          12.901288725928936
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              32.529931191431416,
              29.972545436050268
            ],
            [
              32.287551363135435,
              30.466024503890736
            ],
            [
              31.950051602223482,
              30.877238883975252
            ],
            [
              31.387552000703465,
              31.094220011720097
            ],
            [
              30.825052399183477,
              31.19054975154404
            ],
            [
              29.893513059094772,
              31.191465077638554
            ]
          ],
          [
            [
              56.333724328601164,
              25.121690004958737
            ],
            [
              56.9250339097116,
              24.864833316508822
            ],
            [
              58.50003279396768,
              24.507068968791344
            ],
            [
              59.85003183761572,
              23.91710129093613
            ],
            [
              62.10003024369573,
              22.469443964829594
            ],
            [
              62.10003024369573,
              19.104405475930548
            ]
          ],
          [
            [
              35.85922883292588,
              34.43974246013238
            ],
            [
              35.55004905195153,
              34.40502275071583
            ],
            [
              32.40005128343955,
              33.283811019051
            ],
            [
              30.60005255857544,
              32.62301664000799
            ],
            [
              28.8000538337115,
              32.62301664000799
            ]
          ],
          [
            [
              15.067443562021538,
              37.51344748573402
            ],
            [
              15.75006307844734,
              37.41128363492314
            ],
            [
              16.256312719815384,
              36.87321951208918
            ],
            [
              16.425062600271445,
              35.78566189952613
            ],
            [
              16.65006244087931,
              34.68301765985788
            ]
          ],
          [
            [
              5.372530429989041,
              43.29362778902916
            ],
            [
              5.962570011999247,
              41.744358789482135
            ],
            [
              7.200069135343199,
              38.651811712711236
            ],
            [
              7.875068657167304,
              37.94551049545976
            ],
            [
              9.000067859611391,
              37.76786242517883
            ],
            [
              10.348617229769246,
              37.76786242517883
            ],
            [
              10.912566505375338,
              37.41128363492314
            ],
            [
              11.587566026007465,
              37.232354321556215
            ],
            [
              12.712565230239278,
              35.96797434759347
            ],
            [
              13.668814552822482,
              35.419780517080454
            ],
            [
              14.4000640347993,
              35.098264766303174
            ],
            [
              16.65006244087931,
              34.68301765985788
            ],
            [
              19.35006052817539,
              35.05222991093683
            ],
            [
              22.05005861487558,
              34.71384862261547
            ],
            [
              25.20005638398345,
              33.98629373718458
            ],
            [
              28.8000538337115,
              32.62301664000799
            ],
            [
              29.893513059094772,
              31.191465077638554
            ],
            [
              30.825052399183477,
              31.118343723670165
            ],
            [
              31.387552000703465,
              30.997835356024154
            ],
            [
              31.950051602223482,
              30.780635088989612
            ],
            [
              32.287551363135435,
              30.41752891425901
            ],
            [
              32.529931191431416,
              29.972545436050268
            ]
          ],
          [
            [
              32.529931191431416,
              29.972545436050268
            ],
            [
              32.56877616391327,
              29.63833609362638
            ],
            [
              32.737551044351505,
              29.34456698948989
            ],
            [
              33.018800845111514,
              28.951554732193216
            ],
            [
              33.511262996247694,
              28.161052262220892
            ],
            [
              34.31254992860758,
              27.364667993860166
            ],
            [
              35.18442431096349,
              26.562513149236622
            ],
            [
              35.887548812863486,
              25.754704263415306
            ],
            [
              36.90004809559866,
              24.12261698700334
            ],
            [
              38.137547218943496,
              22.05298561667763
            ],
            [
              38.92504666107155,
              20.375041253465525
            ],
            [
              40.27504570471959,
              18.251816319028308
            ],
            [
              41.287544987456556,
              16.53419619825962
            ],
            [
              42.24379431003959,
              14.801154224791475
            ],
            [
              42.75004395140763,
              13.929303843271725
            ],
            [
              43.11566869239567,
              13.054150695298716
            ],
            [
              43.270356082813606,
              12.834868817846598
            ],
            [
              43.35473102304164,
              12.615395567393307
            ],
            [
              43.734418254067634,
              12.395734000022884
            ],
            [
              44.55004267627157,
              12.01088236045885
            ],
            [
              45.450042038703714,
              12.17588718550806
            ],
            [
              48.600039807215495,
              12.944533868662859
            ],
            [
              55.350035025455725,
              15.23578178303569
            ],
            [
              58.950032475183775,
              16.965102599435824
            ],
            [
              62.10003024369573,
              19.104405475930548
            ],
            [
              66.60002705585575,
              19.529070924351004
            ],
            [
              70.2000245055838,
              19.316876111628602
            ],
            [
              72.87590260996691,
              19.076074257285313
            ]
          ],
          [
            [
              67.02854675228852,
              24.889731701235718
            ],
            [
              66.48752713555183,
              23.298598065875808
            ],
            [
              66.15002737463988,
              20.375041253465525
            ],
            [
              66.60002705585575,
              19.529070924351004
            ]
          ],
          [
            [
              38.137547218943496,
              22.05298561667763
            ],
            [
              39.182756478507656,
              21.481533475503085
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "seacomtata-tgn-eurasia",
        "name": "SEACOM/Tata TGN-Eurasia",
        "color": "#2f95b3",
        "feature_id": "seacomtata-tgn-eurasia-0",
        "coordinates": [
          48.752402741352626,
          12.884160175093115
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              72.87590260996691,
              19.076074257285313
            ],
            [
              70.2000245055838,
              19.210675111642757
            ],
            [
              66.60002705585575,
              19.316876111628602
            ],
            [
              61.87503040308769,
              18.67864702215462
            ],
            [
              58.950032475183775,
              16.749771315644608
            ],
            [
              55.350035025455725,
              15.018578573757566
            ],
            [
              48.600039807215495,
              12.834868817846598
            ],
            [
              45.450042038703714,
              12.06589527357022
            ],
            [
              44.55004267627157,
              11.95585820711483
            ],
            [
              43.70629327399155,
              12.395734000022884
            ],
            [
              43.34066853300351,
              12.615395567393307
            ],
            [
              43.25629359277548,
              12.834868817846598
            ],
            [
              43.08754371231959,
              13.054150695298716
            ],
            [
              42.69379399125549,
              13.929303843271725
            ],
            [
              42.18754434988762,
              14.801154224791475
            ],
            [
              41.17504506715264,
              16.53419619825962
            ],
            [
              40.16254578441547,
              18.251816319028308
            ],
            [
              38.8125467407676,
              20.375041253465525
            ],
            [
              38.02504729863958,
              22.05298561667763
            ],
            [
              36.78754817529472,
              24.12261698700334
            ],
            [
              35.77504889255957,
              25.754704263415306
            ],
            [
              35.10004937073546,
              26.562513149236622
            ],
            [
              34.25629996845544,
              27.364667993860166
            ],
            [
              33.44102554600758,
              28.161052262220892
            ],
            [
              32.61372113207378,
              29.1042306694751
            ]
          ],
          [
            [
              32.580621155522095,
              -25.968268155408055
            ],
            [
              33.75005032708759,
              -26.55162080165701
            ],
            [
              35.77504889255957,
              -26.953450834802908
            ]
          ],
          [
            [
              42.30004427019156,
              -4.377144375531941
            ],
            [
              39.672896131287985,
              -4.052924364763157
            ]
          ],
          [
            [
              55.350035025455725,
              15.018578573757566
            ],
            [
              55.800034706671596,
              12.615395567393307
            ],
            [
              55.800034706671596,
              9.524411345019587
            ],
            [
              55.350035026051785,
              5.510071711803135
            ],
            [
              52.200037256943745,
              1.468426767332062
            ],
            [
              46.350041401135684,
              -2.130918480960247
            ],
            [
              42.30004427019156,
              -4.377144375531941
            ],
            [
              40.95004522654352,
              -5.721872747834027
            ],
            [
              40.61254546563154,
              -6.169450529574419
            ],
            [
              39.8250460229076,
              -6.579399093270837
            ],
            [
              39.2696764169327,
              -6.823132108349142
            ]
          ],
          [
            [
              31.757961738301788,
              -28.95055966653794
            ],
            [
              32.85005096465545,
              -29.03930285603505
            ],
            [
              33.75005032708759,
              -28.74381028114999
            ],
            [
              35.77504889255957,
              -26.953450834802908
            ],
            [
              41.62504474836763,
              -23.70010845220321
            ],
            [
              42.75004395140763,
              -20.57441905727606
            ],
            [
              42.75004395140763,
              -15.22403228464728
            ],
            [
              42.750043950811715,
              -14.861883917662055
            ],
            [
              42.30004427019156,
              -11.943944931746927
            ],
            [
              41.850044588975464,
              -9.290424301035614
            ],
            [
              40.61254546563154,
              -6.169450529574419
            ]
          ],
          [
            [
              43.147993669496344,
              11.594869371447714
            ],
            [
              43.6500433138396,
              11.790669845561695
            ],
            [
              44.55004267627157,
              11.95585820711483
            ]
          ],
          [
            [
              38.02504729863958,
              22.05298561667763
            ],
            [
              39.182756478507656,
              21.481533475503085
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "middle-east-north-africa-mena-cable-systemgulf-bridge-international",
        "name": "Middle East North Africa (MENA) Cable System/Gulf Bridge International",
        "color": "#51b847",
        "feature_id": "middle-east-north-africa-mena-cable-systemgulf-bridge-international-0",
        "coordinates": [
          45.41017982656689,
          12.595929135431334
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              44.55004267627157,
              12.17588718550806
            ],
            [
              43.6500433138396,
              11.845776373625682
            ],
            [
              43.35585139948304,
              11.721608446415672
            ]
          ],
          [
            [
              58.50003279396768,
              24.12261698700334
            ],
            [
              58.331282913511615,
              23.917101290935022
            ],
            [
              58.17620302337187,
              23.684877531684634
            ]
          ],
          [
            [
              32.65318110412005,
              29.113614162980156
            ],
            [
              33.426988055952876,
              28.161052262220892
            ],
            [
              34.0313001278476,
              27.364667993860166
            ],
            [
              34.76254960982348,
              26.562513149236622
            ],
            [
              35.3250492113435,
              25.754704263415306
            ],
            [
              36.337548494079556,
              24.12261698700334
            ],
            [
              37.57504761742351,
              22.05298561667763
            ],
            [
              38.36254705955153,
              20.375041253465525
            ],
            [
              39.71254610319957,
              18.251816319028308
            ],
            [
              40.72504538593765,
              16.53419619825962
            ],
            [
              41.96254450927958,
              14.801154224791475
            ],
            [
              42.468794150647625,
              13.929303843271725
            ],
            [
              42.97504379201547,
              13.054150695298716
            ],
            [
              43.200043632623704,
              12.834868817846598
            ],
            [
              43.284418572851564,
              12.615395567393307
            ],
            [
              43.59379335368763,
              12.395734000022884
            ],
            [
              44.55004267627157,
              12.17588718550806
            ],
            [
              45.450042038703714,
              12.615395567393307
            ],
            [
              48.600039807215495,
              13.382708036125592
            ],
            [
              55.350035025455725,
              16.10232559580288
            ],
            [
              58.950032475183775,
              17.823934412537824
            ],
            [
              60.07503167822378,
              19.104405475930548
            ],
            [
              60.18753159793181,
              22.469443964829594
            ],
            [
              59.85003183761572,
              22.988259503893147
            ],
            [
              58.50003279396768,
              24.12261698700334
            ]
          ],
          [
            [
              29.700933195520264,
              31.072270031660224
            ],
            [
              29.98130299690345,
              30.61136637963392
            ],
            [
              30.375052717967577,
              30.29618450441947
            ],
            [
              31.050052239791512,
              29.931250704427015
            ],
            [
              31.725051761615447,
              29.687214675557016
            ],
            [
              32.17505144283152,
              29.3934133712784
            ],
            [
              32.65318110412005,
              29.113614162980156
            ]
          ],
          [
            [
              29.700933195520264,
              31.072270031660224
            ],
            [
              29.98130299690345,
              30.65976529855473
            ],
            [
              30.375052717967577,
              30.393272076386182
            ],
            [
              31.050052239791512,
              30.028698127877362
            ],
            [
              31.725051761615447,
              29.784900534878517
            ],
            [
              32.17505144283152,
              29.4423669335807
            ],
            [
              32.65318110412005,
              29.113614162980156
            ]
          ],
          [
            [
              12.591375316091586,
              37.650586172786205
            ],
            [
              12.173015612461455,
              37.27875778048957
            ],
            [
              12.037565708415343,
              36.87321951208918
            ],
            [
              12.15006562871929,
              35.78566189952613
            ],
            [
              12.48756538963653,
              35.419780517080454
            ],
            [
              14.4000640347993,
              34.12610104005762
            ],
            [
              16.65006244087931,
              33.37780603565923
            ],
            [
              19.35006052817539,
              33.75276987113051
            ],
            [
              22.05005861487558,
              33.73717891339021
            ],
            [
              25.20005638398345,
              33.00121852265437
            ],
            [
              27.900054471279333,
              32.148008778540714
            ],
            [
              29.700933195520264,
              31.072270031660224
            ]
          ],
          [
            [
              37.57504761742351,
              22.05298561667763
            ],
            [
              39.182756478507656,
              21.481533475503085
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "saudi-arabia-sudan-2-sas-2",
        "name": "Saudi Arabia-Sudan-2 (SAS-2)",
        "color": "#3c67b1",
        "feature_id": "saudi-arabia-sudan-2-sas-2-0",
        "coordinates": [
          38.033152129884286,
          20.722932282700725
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              37.21967786917094,
              19.615566594546237
            ],
            [
              37.589237574003334,
              20.23602516723338
            ],
            [
              38.100047245508904,
              20.796306105108954
            ],
            [
              39.182756478507656,
              21.481533475503085
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "flag-europe-asia-fea",
        "name": "FLAG Europe-Asia (FEA)",
        "color": "#c52159",
        "feature_id": "flag-europe-asia-fea-0",
        "coordinates": [
          25.966152281906375,
          33.43515740422572
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              32.28445136473579,
              31.259278146448963
            ],
            [
              32.51255120314758,
              30.83702058239723
            ],
            [
              32.51255120314758,
              30.25570294203996
            ],
            [
              32.529931191431416,
              29.972545436050268
            ]
          ],
          [
            [
              29.893513059094772,
              31.191465077638554
            ],
            [
              30.150052877359542,
              31.574717129337273
            ],
            [
              31.050052239195594,
              31.702423093375245
            ],
            [
              32.00630156177954,
              31.510798430048965
            ],
            [
              32.28445136473579,
              31.259278146448963
            ]
          ],
          [
            [
              29.893513059094772,
              31.191465077638554
            ],
            [
              30.825052399183477,
              30.75649044225247
            ],
            [
              31.387552000703465,
              30.51445288474386
            ],
            [
              31.950051602223482,
              30.296162918703686
            ],
            [
              32.287551363135435,
              30.174689758499085
            ],
            [
              32.529931191431416,
              29.972545436050268
            ]
          ],
          [
            [
              -5.654511758350935,
              50.04314791189421
            ],
            [
              -6.074921460528742,
              49.950586997728095
            ],
            [
              -6.299921301136777,
              49.80559362880811
            ],
            [
              -7.499920451046137,
              49.34357257392173
            ],
            [
              -8.549919707216787,
              48.70423463096077
            ],
            [
              -12.599916838160823,
              44.051519228735145
            ],
            [
              -12.599916838160823,
              39.69832335493328
            ],
            [
              -11.024917953904747,
              37.94551049545976
            ],
            [
              -9.787418830560796,
              36.51238821239372
            ],
            [
              -8.999919388432772,
              36.14986678681771
            ],
            [
              -6.299921301136777,
              35.96797434759347
            ],
            [
              -5.624921779312757,
              35.967933880341064
            ],
            [
              -5.347581975782816,
              36.156019514134556
            ],
            [
              -4.499922576272752,
              36.05897312258671
            ],
            [
              -2.249924170192742,
              36.14986678681771
            ],
            [
              2.250072641967279,
              37.94551049545976
            ],
            [
              5.400070410479259,
              38.29952060596935
            ],
            [
              9.000067859611391,
              38.38775473578434
            ],
            [
              10.348617229769246,
              38.38775473578434
            ],
            [
              12.15006562871929,
              38.29952060596935
            ],
            [
              12.60006530993536,
              38.43179231910082
            ],
            [
              13.05006499115126,
              38.34365110568987
            ],
            [
              13.358654772543531,
              38.116121616583314
            ],
            [
              13.05006499115126,
              38.29948136690871
            ],
            [
              12.60006530993536,
              38.34361189050912
            ],
            [
              12.15006562871929,
              38.21117903702327
            ],
            [
              12.15006562871929,
              36.87321951208918
            ],
            [
              12.375065469327325,
              36.240655233214795
            ],
            [
              12.712565230239278,
              35.78566189952613
            ],
            [
              13.331314791912519,
              35.419780517080454
            ],
            [
              14.4000640347993,
              34.82166648408236
            ],
            [
              16.65006244087931,
              34.31215165223537
            ],
            [
              19.35006052817539,
              34.68301765985788
            ],
            [
              22.05005861487558,
              34.435956905755745
            ],
            [
              25.20005638398345,
              33.70598849685862
            ],
            [
              28.8000538337115,
              32.43331330641712
            ],
            [
              29.893513059094772,
              31.191465077638554
            ],
            [
              30.825052399183477,
              30.6839556754382
            ],
            [
              31.387552000703465,
              30.417485796339378
            ],
            [
              31.950051602223482,
              30.198979110224087
            ],
            [
              32.287551363135435,
              30.126049846722907
            ],
            [
              32.529931191431416,
              29.972545436050268
            ],
            [
              32.48440122368541,
              29.63833609362638
            ],
            [
              32.65327610405271,
              29.34456698948989
            ],
            [
              32.65318110412005,
              29.113614162980156
            ],
            [
              33.41295056589729,
              28.161052262220892
            ],
            [
              34.14380004815152,
              27.364667993860166
            ],
            [
              34.93129949027957,
              26.562513149236622
            ],
            [
              35.55004905195153,
              25.754704263415306
            ],
            [
              36.56254833468651,
              24.12261698700334
            ],
            [
              37.80004745803154,
              22.05298561667763
            ],
            [
              38.587546900159566,
              20.375041253465525
            ],
            [
              39.937545943807606,
              18.251816319028308
            ],
            [
              40.9500452265446,
              16.53419619825962
            ],
            [
              42.0750444295835,
              14.801154224791475
            ],
            [
              42.58129407095154,
              13.929303843271725
            ],
            [
              43.03129375216761,
              13.054150695298716
            ],
            [
              43.22816861269959,
              12.834868817846598
            ],
            [
              43.31254355292762,
              12.615395567393307
            ],
            [
              43.6500433138396,
              12.395734000022884
            ],
            [
              44.55004267627157,
              11.900822861999615
            ],
            [
              45.450042038703714,
              11.95585820711483
            ],
            [
              48.600039807215495,
              12.725155923562937
            ],
            [
              55.350035025455725,
              14.801154224791475
            ],
            [
              58.950032475183775,
              16.53419619825962
            ],
            [
              61.650030562479856,
              18.251816319028308
            ],
            [
              66.60002705585575,
              19.104405475930548
            ],
            [
              70.2000245055838,
              19.104405475930548
            ],
            [
              72.87590260996691,
              19.076074257285313
            ]
          ],
          [
            [
              -5.145872118676039,
              36.42741977174609
            ],
            [
              -4.724922416880787,
              36.240655233214795
            ],
            [
              -4.499922576272752,
              36.05897312258671
            ]
          ],
          [
            [
              56.333724328601164,
              25.121690004958737
            ],
            [
              56.9250339097116,
              24.8393128255928
            ],
            [
              58.50003279396768,
              24.353428099494664
            ],
            [
              59.85003183761572,
              23.711258142485292
            ],
            [
              61.650030562479856,
              22.469443964829594
            ],
            [
              61.650030562479856,
              18.251816319028308
            ]
          ],
          [
            [
              100.0661133481664,
              6.613518860854185
            ],
            [
              100.29385318683333,
              6.908035999527129
            ],
            [
              100.59971297015935,
              7.075917959575207
            ],
            [
              101.70000219070411,
              7.298762754459602
            ],
            [
              103.04883123518098,
              7.340023741610707
            ],
            [
              105.29999964043216,
              7.521883237406598
            ],
            [
              107.99999772772824,
              8.41318535956026
            ],
            [
              109.34999677137611,
              9.96791518697421
            ],
            [
              110.24999613380825,
              12.615395567393307
            ],
            [
              112.94999422110416,
              18.251816319028308
            ],
            [
              113.28749398201612,
              20.796306105108954
            ],
            [
              113.6249937429283,
              21.635297384859456
            ],
            [
              113.94911351331862,
              22.27149389584997
            ],
            [
              114.187543344413,
              21.84429407917378
            ],
            [
              115.19999262718417,
              21.21639789994191
            ],
            [
              116.54999167083221,
              21.00649984517682
            ],
            [
              118.79999007691222,
              21.32123529551194
            ],
            [
              121.02775554672925,
              21.143107267679284
            ],
            [
              122.84998720785634,
              21.32123529551194
            ],
            [
              123.97498641089615,
              22.05298561667763
            ],
            [
              124.87498577332835,
              24.12261698700334
            ],
            [
              125.09998561393635,
              25.55188275942578
            ],
            [
              125.32498545454442,
              26.159307970773796
            ],
            [
              126.22498481697616,
              28.55704546571141
            ],
            [
              126.4499846575844,
              29.540507745394393
            ],
            [
              127.57498386062443,
              32.43331330641712
            ],
            [
              127.57498386062443,
              33.18971466460036
            ],
            [
              128.1374834621444,
              34.31215165223537
            ],
            [
              128.62078311977027,
              34.88072781981958
            ]
          ],
          [
            [
              100.36299313785392,
              5.35384659465538
            ],
            [
              99.67500362523216,
              5.510071711803135
            ],
            [
              97.42500521915215,
              6.908035999527129
            ]
          ],
          [
            [
              72.87590260996691,
              19.076074257285313
            ],
            [
              72.67502275227184,
              16.965102599435824
            ],
            [
              73.74513399127406,
              13.492128176464178
            ],
            [
              75.09513303492213,
              9.524411345019587
            ],
            [
              77.29024347696432,
              6.852191098754417
            ],
            [
              78.69513048464998,
              5.061986954416028
            ],
            [
              81.00001685476795,
              4.389285926050889
            ],
            [
              85.50001366692797,
              5.510071711803135
            ],
            [
              90.00001047908799,
              6.405200795356109
            ],
            [
              92.7000085663839,
              6.405200795356109
            ],
            [
              94.27500745064,
              6.628746603597904
            ],
            [
              95.40000665367998,
              6.628746603597904
            ],
            [
              97.42500521915215,
              6.908035999527129
            ],
            [
              99.00000410340803,
              6.852191098754417
            ],
            [
              99.67500362523216,
              6.740481724921096
            ],
            [
              100.0661133481664,
              6.613518860854185
            ]
          ],
          [
            [
              121.92508786246773,
              30.86475026744725
            ],
            [
              122.17498768603221,
              30.997878215321975
            ],
            [
              122.84998720785634,
              31.19054975154404
            ],
            [
              124.64998593272028,
              30.80481662242596
            ],
            [
              125.6624852154562,
              30.223305674181546
            ],
            [
              126.4499846575844,
              29.540507745394393
            ]
          ],
          [
            [
              139.6208753272019,
              35.144036508332505
            ],
            [
              139.44372545269644,
              34.40502275071583
            ],
            [
              139.38747549254444,
              33.93964008831958
            ],
            [
              139.49997541284839,
              32.43331330641712
            ],
            [
              139.0499757316325,
              31.28673881439167
            ],
            [
              137.69997668798445,
              30.514495959759188
            ],
            [
              134.99997860068837,
              29.73606949729205
            ],
            [
              132.74998019460836,
              29.73606949729205
            ],
            [
              130.49998178852834,
              29.931250704427015
            ],
            [
              129.37498258548837,
              30.901396088515583
            ],
            [
              128.02498354184027,
              32.43331330641712
            ],
            [
              127.79998370123226,
              33.18971466460036
            ],
            [
              128.24998338244833,
              34.31215165223537
            ],
            [
              128.62078311977027,
              34.88072781981958
            ]
          ],
          [
            [
              35.00509500000021,
              29.581033231032706
            ],
            [
              34.71137964666866,
              29.089044023022637
            ],
            [
              34.517629783327095,
              28.313851747133725
            ],
            [
              34.461379822579204,
              28.115586087217935
            ],
            [
              34.44732233253748,
              27.991483447904812
            ],
            [
              34.44732233253748,
              27.91695317820549
            ],
            [
              34.76254960982348,
              26.964304734562802
            ],
            [
              35.55004905195153,
              25.754704263415306
            ]
          ],
          [
            [
              39.18783647490889,
              21.48407381384692
            ],
            [
              37.80004745803154,
              22.05298561667763
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "eac-c2c",
        "name": "EAC-C2C",
        "color": "#3a56a6",
        "feature_id": "eac-c2c-0",
        "coordinates": [
          124.21580763312508,
          20.72669709013303
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              120.12788799225589,
              21.457551614722092
            ],
            [
              118.79999007691222,
              21.530685333962452
            ],
            [
              117.44999103326418,
              21.84429407917378
            ],
            [
              115.64999230840024,
              22.05298561667763
            ],
            [
              114.97499278657611,
              22.36544568641881
            ],
            [
              114.25868329401678,
              22.318292678971574
            ]
          ],
          [
            [
              114.20292333351765,
              22.222050419736746
            ],
            [
              114.63749302566416,
              20.796306105108954
            ],
            [
              115.42499246779224,
              18.23846081095263
            ],
            [
              116.99999135204828,
              16.10232559580288
            ],
            [
              119.2499897581283,
              14.365653759228536
            ],
            [
              120.14998912056026,
              13.820086409698149
            ],
            [
              121.06600847164387,
              13.762418337904336
            ]
          ],
          [
            [
              121.4625881907028,
              25.168986122701654
            ],
            [
              121.38748824390436,
              25.742038029757737
            ],
            [
              121.94998784542437,
              26.751029869608203
            ],
            [
              123.52498672968031,
              28.1486537088812
            ],
            [
              123.29998688907224,
              30.113886122109335
            ],
            [
              122.17498768603221,
              30.74440510438197
            ],
            [
              121.92508786246773,
              30.852678537714866
            ],
            [
              122.17498768603221,
              31.034033881789867
            ],
            [
              122.84998720785634,
              31.46682894547817
            ],
            [
              128.02498354184027,
              32.800499176018576
            ],
            [
              128.6999830636644,
              34.30053553069055
            ],
            [
              128.99949285148878,
              35.158882668071755
            ],
            [
              129.59998242609637,
              34.30053553069055
            ],
            [
              129.59998242609637,
              32.800499176018576
            ],
            [
              129.59998242609637,
              31.658543951906694
            ],
            [
              130.49998178852834,
              30.889328974889338
            ],
            [
              132.74998019460836,
              30.889328974889338
            ],
            [
              134.99997860068837,
              31.274720539181885
            ],
            [
              136.79997732555248,
              32.04078843974219
            ],
            [
              137.02497716616054,
              32.800499176018576
            ],
            [
              136.87399727311598,
              34.32521554537476
            ],
            [
              137.69997668798445,
              33.927972678693465
            ],
            [
              138.59997605041642,
              33.927972678693465
            ],
            [
              139.27497557224055,
              34.114459241194936
            ],
            [
              139.8937251339125,
              34.39341949240347
            ],
            [
              140.0343500336966,
              34.6791629819069
            ],
            [
              140.02028754365844,
              34.840904848139274
            ],
            [
              139.9548550906076,
              34.9650466035836
            ],
            [
              140.09059999384837,
              34.840904848139274
            ],
            [
              140.3155998344566,
              34.6791629819069
            ],
            [
              140.4562247354323,
              34.39341949240347
            ],
            [
              140.7374745361923,
              33.927972678693465
            ],
            [
              140.62497461588836,
              32.421443555350606
            ],
            [
              140.1749749346723,
              30.889328974889338
            ],
            [
              138.82497589102448,
              28.54469307114414
            ],
            [
              137.24997700676838,
              26.95177029188136
            ],
            [
              132.74998019460836,
              23.698382121989823
            ],
            [
              130.94998146974442,
              22.871697869961935
            ],
            [
              125.99998497636832,
              21.2032879796254
            ],
            [
              122.84998720785634,
              20.36185803765278
            ],
            [
              121.04998848299223,
              19.303604751105865
            ],
            [
              120.14998912056026,
              18.66532468424984
            ],
            [
              119.2499897581283,
              17.81054639660823
            ],
            [
              119.02498991752029,
              15.222213115980765
            ],
            [
              119.58748951904025,
              14.569901804623044
            ],
            [
              120.14998912056026,
              13.915654478895874
            ],
            [
              121.06600847164387,
              13.748759175855781
            ],
            [
              120.14998912056026,
              13.259550944281187
            ],
            [
              118.79999007691222,
              12.60167220474645
            ],
            [
              116.09999198961614,
              9.066444239907042
            ],
            [
              114.0749934241442,
              7.730954611329906
            ],
            [
              110.69999581502415,
              5.943831970446496
            ],
            [
              110.02499629320002,
              5.496074035021934
            ],
            [
              107.99999772772824,
              4.599574515521397
            ],
            [
              107.0999983652961,
              4.038674649466145
            ],
            [
              105.74999932164823,
              3.25277508042663
            ],
            [
              105.24374968028005,
              2.803404866588352
            ],
            [
              104.62500011860826,
              1.45436885137326
            ],
            [
              104.28790035741284,
              1.215596520932166
            ],
            [
              104.16377044534775,
              1.2100052777283
            ],
            [
              103.9870105705659,
              1.375392999849822
            ],
            [
              104.17793043531677,
              1.249346186877318
            ],
            [
              104.28790035741284,
              1.299801162778834
            ],
            [
              104.42847525782813,
              1.45436885137326
            ],
            [
              104.79374999906415,
              2.803404866588352
            ],
            [
              105.74999932164823,
              4.150887372137561
            ],
            [
              107.0999983652961,
              4.93590597527708
            ],
            [
              107.99999772772824,
              5.608046166305712
            ],
            [
              110.47499597441615,
              7.730954611329906
            ],
            [
              111.71249509776024,
              9.954064678408741
            ],
            [
              112.7249943804961,
              12.60167220474645
            ],
            [
              114.29999326415631,
              17.09507926096478
            ],
            [
              114.52499310536027,
              18.23846081095263
            ],
            [
              114.18749334444826,
              20.783159233732903
            ],
            [
              114.25868329401678,
              22.305283024668313
            ]
          ],
          [
            [
              124.64998593272028,
              35.419780517080454
            ],
            [
              121.4999881642083,
              35.78566189952613
            ],
            [
              120.34246898420574,
              36.087310907419294
            ]
          ],
          [
            [
              103.9870105705659,
              1.389451396800126
            ],
            [
              104.1874004286079,
              1.289568782938488
            ],
            [
              104.28790035741284,
              1.341927435270179
            ],
            [
              104.40040027771695,
              1.468426767332062
            ],
            [
              104.73750003891215,
              2.817450442654064
            ],
            [
              105.74999932164823,
              4.277107602190398
            ],
            [
              107.0999983652961,
              5.061986954416028
            ],
            [
              107.99999772772824,
              5.733989114150035
            ],
            [
              110.24999613380825,
              7.744889052551343
            ],
            [
              111.48749525715218,
              9.96791518697421
            ],
            [
              112.49999453988826,
              12.615395567393307
            ],
            [
              114.07499342354825,
              17.108519960795775
            ],
            [
              114.2999932647522,
              18.251816319028308
            ],
            [
              114.0749934241442,
              20.796306105108954
            ],
            [
              114.20292333351765,
              22.222050419736746
            ]
          ],
          [
            [
              121.38328824687974,
              25.149980712893893
            ],
            [
              121.72498800481614,
              25.754704263415306
            ],
            [
              121.94998784542437,
              26.058287560298936
            ],
            [
              122.84998720785634,
              26.26024097157773
            ],
            [
              128.6999830636644,
              25.754704263415306
            ],
            [
              131.39998115096031,
              26.159307970773796
            ],
            [
              132.74998019460836,
              26.159307970773796
            ]
          ],
          [
            [
              114.25868329401678,
              22.318292678971574
            ],
            [
              114.97499278657611,
              22.261369678340685
            ],
            [
              115.64999230840024,
              21.94867813792706
            ],
            [
              117.44999103326418,
              21.635297384859456
            ],
            [
              118.79999007691222,
              21.00649984517682
            ],
            [
              121.02775554672925,
              20.827994119058957
            ],
            [
              122.84998720785634,
              20.796306105108954
            ],
            [
              125.99998497636832,
              22.05298561667763
            ],
            [
              128.6999830636644,
              23.298598065875808
            ],
            [
              132.74998019460836,
              26.159307970773796
            ]
          ],
          [
            [
              114.25868329401678,
              22.318292678971574
            ],
            [
              114.97499278657611,
              22.313417380769238
            ],
            [
              115.64999230840024,
              22.00084146791059
            ],
            [
              117.44999103326418,
              21.73983373091116
            ],
            [
              118.79999007691222,
              21.21639789994191
            ],
            [
              121.02775554672925,
              21.038143463338287
            ],
            [
              122.84998720785634,
              21.21639789994191
            ],
            [
              124.19998625150438,
              22.05298561667763
            ],
            [
              125.09998561393635,
              24.12261698700334
            ],
            [
              125.38123541469636,
              25.55188275942578
            ],
            [
              125.6624852154562,
              26.159307970773796
            ],
            [
              126.67498449819223,
              28.55704546571141
            ],
            [
              126.8999843388003,
              29.540507745394393
            ],
            [
              127.34998402001636,
              30.70813999354155
            ],
            [
              125.09998561393635,
              33.18971466460036
            ],
            [
              124.64998593272028,
              34.31215165223537
            ],
            [
              124.64998593272028,
              35.419780517080454
            ],
            [
              125.54998529515225,
              36.14986678681771
            ],
            [
              126.3915846989556,
              36.57633045558569
            ],
            [
              125.54998529515225,
              35.96797434759347
            ],
            [
              125.09998561393635,
              35.419780517080454
            ],
            [
              125.09998561393635,
              34.31215165223537
            ],
            [
              125.32498545454442,
              33.18971466460036
            ],
            [
              128.24998338244833,
              30.70813999354155
            ],
            [
              130.49998178852834,
              29.540507745394393
            ],
            [
              132.74998019460836,
              29.34456698948989
            ],
            [
              134.99997860068837,
              29.34456698948989
            ],
            [
              137.69997668798445,
              30.126049846722907
            ],
            [
              139.27497557224055,
              30.901396088515583
            ],
            [
              141.2999741377125,
              32.052708023486105
            ],
            [
              142.19997350014447,
              34.40502275071583
            ],
            [
              142.19997350014447,
              35.05222991093683
            ],
            [
              141.9749736595364,
              35.78566189952613
            ],
            [
              140.6124746247436,
              36.38348373531238
            ],
            [
              141.7499738189284,
              35.78566189952613
            ],
            [
              141.7499738189284,
              35.05222991093683
            ],
            [
              140.84997445649643,
              34.12610104005762
            ],
            [
              138.59997605041642,
              33.37780603565923
            ],
            [
              137.69997668798445,
              33.65918162905041
            ],
            [
              136.87399727311598,
              34.33682825203164
            ],
            [
              137.47497684737638,
              32.81231878328768
            ],
            [
              137.69997668798445,
              31.28673881439167
            ],
            [
              137.24997700676838,
              29.34456698948989
            ],
            [
              132.74998019460836,
              26.159307970773796
            ]
          ],
          [
            [
              125.09998561393635,
              24.1097818895268
            ],
            [
              123.74998657028831,
              25.23428656210018
            ],
            [
              122.84998720785634,
              25.539194978687192
            ],
            [
              121.94998784542437,
              25.437644438648675
            ],
            [
              121.38328824687974,
              25.137250705417642
            ],
            [
              121.94998784542437,
              25.336008217188635
            ],
            [
              122.39998752664027,
              24.928611492263542
            ],
            [
              122.84998720785634,
              24.007054825363127
            ],
            [
              122.39998752664027,
              22.45644844059953
            ],
            [
              121.04998848299223,
              19.727750791331374
            ],
            [
              119.69998943934436,
              18.66532468424984
            ],
            [
              118.79999007691222,
              17.81054639660823
            ],
            [
              118.79999007691222,
              15.222213115980765
            ],
            [
              119.36248967843224,
              14.569901804623044
            ],
            [
              120.14998912056026,
              14.352030559547387
            ],
            [
              120.82019864577748,
              14.273021484821356
            ],
            [
              120.14998912056026,
              14.024826773554622
            ],
            [
              118.79999007691222,
              13.040451242220257
            ],
            [
              115.64999230840024,
              9.066444239907042
            ],
            [
              113.84999358353613,
              7.730954611329906
            ],
            [
              110.47499597441615,
              5.943831970446496
            ],
            [
              109.79999645259218,
              5.496074035021934
            ],
            [
              107.99999772772824,
              4.711703227447089
            ],
            [
              107.0999983652961,
              4.150887372137561
            ],
            [
              105.74999932164823,
              3.365087426296213
            ],
            [
              105.18749972012822,
              2.803404866588352
            ],
            [
              104.59692513849686,
              1.45436885137326
            ],
            [
              104.28790035741284,
              1.229630815177529
            ],
            [
              104.17314043870985,
              1.236269273140266
            ],
            [
              103.9870105705659,
              1.375392999849822
            ]
          ],
          [
            [
              120.12788799225589,
              21.457551614722092
            ],
            [
              120.37498896116833,
              21.635297384859456
            ],
            [
              120.54373884162422,
              22.05298561667763
            ],
            [
              120.66219875770614,
              22.249254821356544
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "asia-submarine-cable-express-asecahaya-malaysia",
        "name": "Asia Submarine-cable Express (ASE)/Cahaya Malaysia",
        "color": "#1ab4dd",
        "feature_id": "asia-submarine-cable-express-asecahaya-malaysia-0",
        "coordinates": [
          115.7049575690742,
          11.374834005814165
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              130.90187508980645,
              23.865759963390786
            ],
            [
              128.6999830636644,
              25.55188275942578
            ],
            [
              128.02498354184027,
              25.95717997876443
            ],
            [
              127.70078377150665,
              26.08774963546199
            ]
          ],
          [
            [
              105.07499979982416,
              2.803404866588352
            ],
            [
              104.7975192542419,
              2.513406917740724
            ],
            [
              104.40000027800002,
              2.424113339601092
            ],
            [
              103.85068066714334,
              2.295702456949584
            ]
          ],
          [
            [
              126.85721436909918,
              19.4664763597265
            ],
            [
              125.54998529515225,
              19.303604751105865
            ],
            [
              121.04998848299223,
              19.93940396108411
            ],
            [
              119.47498959873613,
              18.66532468424984
            ],
            [
              118.57499023630416,
              17.81054639660823
            ],
            [
              117.56329095300165,
              15.439678695519973
            ],
            [
              116.21249190992026,
              12.60167220474645
            ],
            [
              114.74999294596827,
              9.066444239907042
            ],
            [
              112.94999422110416,
              7.730954611329906
            ],
            [
              110.02499629320002,
              5.943831970446496
            ],
            [
              109.34999677137611,
              5.496074035021934
            ],
            [
              107.99999772772824,
              4.93590597527708
            ],
            [
              107.0999983652961,
              4.375264548610318
            ],
            [
              105.74999932164823,
              3.589672857320551
            ],
            [
              105.07499979982416,
              2.803404866588352
            ],
            [
              104.54077517827395,
              1.45436885137326
            ],
            [
              104.28790035741284,
              1.271733251829176
            ],
            [
              104.19932042016362,
              1.308321449396317
            ],
            [
              103.98594057132394,
              1.371284183356892
            ]
          ],
          [
            [
              139.97546699999984,
              35.005433
            ],
            [
              140.1327874639624,
              34.89859296336232
            ],
            [
              140.11872497392463,
              34.69072647741018
            ],
            [
              140.0624750143684,
              34.40502275071583
            ],
            [
              139.7249752534564,
              33.93964008831958
            ],
            [
              139.94997509406446,
              32.43331330641712
            ],
            [
              139.49997541284839,
              30.901396088515583
            ],
            [
              138.82497589102448,
              29.73606949729205
            ],
            [
              137.24997700676838,
              28.55704546571141
            ],
            [
              132.74998019460836,
              25.348717422116806
            ],
            [
              130.94998146974442,
              23.917101290935022
            ],
            [
              126.85721436909918,
              19.479734448240038
            ],
            [
              124.19998625150438,
              15.23578178303569
            ],
            [
              123.52498672968031,
              14.365653759228536
            ],
            [
              122.95008713694452,
              14.11652289884887
            ]
          ],
          [
            [
              119.47498959873613,
              18.67864702215462
            ],
            [
              118.34999039569615,
              20.16397503197578
            ],
            [
              116.99999135204828,
              21.111485983488905
            ],
            [
              115.64999230840024,
              21.79207342302732
            ],
            [
              114.97499278657611,
              22.157216226160074
            ],
            [
              114.25868329401678,
              22.318292678971574
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "pldt-domestic-fiber-optic-network-dfon",
        "name": "PLDT Domestic Fiber Optic Network (DFON)",
        "color": "#57b947",
        "feature_id": "pldt-domestic-fiber-optic-network-dfon-0",
        "coordinates": [
          122.75657360976513,
          11.591340541815896
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              124.61277595908044,
              11.006888020676286
            ],
            [
              124.64998593272028,
              10.742581675476316
            ],
            [
              124.64998593272028,
              10.410816505402636
            ],
            [
              124.7624858530244,
              9.96791518697421
            ],
            [
              125.04373565318852,
              9.746236973759864
            ],
            [
              125.3812354141003,
              9.302441529883055
            ],
            [
              125.54061530179015,
              8.9476104639362
            ],
            [
              125.32498545454442,
              9.302441529883055
            ],
            [
              125.09998561393635,
              9.524411345019587
            ],
            [
              124.64998593272028,
              9.413444258507468
            ],
            [
              124.53748601241617,
              9.08033076823304
            ],
            [
              124.70623589287223,
              8.635699417327544
            ],
            [
              124.63191594552123,
              8.454147535358373
            ],
            [
              124.42498609211222,
              8.635699417327544
            ],
            [
              124.19998625150438,
              8.635699417327544
            ],
            [
              124.05765681986091,
              8.325649123940394
            ],
            [
              123.84998649944745,
              8.149939793212269
            ],
            [
              123.97498641089615,
              8.41318535956026
            ],
            [
              123.74998657028831,
              8.802499062914308
            ],
            [
              123.41248680937619,
              9.08033076823304
            ],
            [
              123.28143690221336,
              9.295503918748068
            ]
          ],
          [
            [
              124.61277595908044,
              11.006888020676286
            ],
            [
              124.53748601241617,
              10.742581675476316
            ],
            [
              124.42498609211222,
              10.52144468555204
            ],
            [
              124.19998625150438,
              10.410816505402636
            ],
            [
              123.89461646783141,
              10.309948397086572
            ],
            [
              124.08748633120027,
              10.410816505402636
            ],
            [
              124.19041625828382,
              10.932011625131736
            ],
            [
              124.08748633120027,
              11.294709319565555
            ],
            [
              123.74998657028831,
              11.294709319565555
            ],
            [
              123.29998688907224,
              10.95003788376323
            ]
          ],
          [
            [
              120.62298878548287,
              14.088232047424441
            ],
            [
              120.48748888147222,
              13.929303843271725
            ],
            [
              120.59998880177616,
              13.710817738179543
            ],
            [
              121.04998848299223,
              13.546819477168867
            ],
            [
              121.45623819460522,
              13.492128176464178
            ],
            [
              121.56873811490934,
              13.273238157547667
            ],
            [
              121.46453818932153,
              13.04578255071101
            ],
            [
              121.66873804406848,
              12.834868817846598
            ],
            [
              121.78123796437242,
              12.615395567393307
            ],
            [
              121.94998784542437,
              12.17588718550806
            ],
            [
              122.06248776572832,
              12.06589527357022
            ],
            [
              122.39998752664027,
              11.95585820711483
            ],
            [
              122.7499872786972,
              11.583202180445141
            ],
            [
              122.96248712816029,
              11.845776373625682
            ],
            [
              123.29998688907224,
              11.845776373625682
            ],
            [
              123.41248680937619,
              11.95585820711483
            ],
            [
              123.64617664382834,
              12.366734534391924
            ],
            [
              123.58133668976146,
              12.505588131780542
            ],
            [
              123.52498672968031,
              12.615395567393307
            ],
            [
              123.52498672968031,
              12.834868817846598
            ],
            [
              123.63748664998437,
              13.054150695298716
            ],
            [
              123.74373657471571,
              13.138994853951147
            ],
            [
              123.97498641089615,
              13.163718917913684
            ],
            [
              124.19998625150438,
              13.054150695298716
            ],
            [
              124.19871625240401,
              12.617971818017423
            ],
            [
              124.19998625150438,
              12.285833556268273
            ],
            [
              124.42498609092044,
              12.12089689803949
            ],
            [
              124.60027596793546,
              12.069904662870869
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "tata-tgn-intra-asia-tgn-ia",
        "name": "Tata TGN-Intra Asia (TGN-IA)",
        "color": "#3eb65c",
        "feature_id": "tata-tgn-intra-asia-tgn-ia-0",
        "coordinates": [
          118.50808105418767,
          16.59921974827327
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              119.92498927995226,
              18.67864702215462
            ],
            [
              120.59998880058438,
              18.785187974742087
            ],
            [
              121.04998848299223,
              18.785187974742087
            ],
            [
              121.51307815493519,
              18.418302135034224
            ]
          ],
          [
            [
              114.183973346942,
              22.24961578335288
            ],
            [
              114.74999294596827,
              21.896495662923684
            ],
            [
              115.64999230840024,
              21.63525090740862
            ],
            [
              116.99999135204828,
              21.32123529551194
            ],
            [
              118.34999039569615,
              20.585819096040467
            ],
            [
              120.14998912056026,
              19.74098736552503
            ],
            [
              121.04998848299223,
              19.529070924351004
            ]
          ],
          [
            [
              103.9870105705659,
              1.375392999849822
            ],
            [
              104.1826204319942,
              1.262333057432045
            ],
            [
              104.28790035741284,
              1.243665035636695
            ],
            [
              104.56885015838535,
              1.45436885137326
            ],
            [
              105.1312497599761,
              2.803404866588352
            ],
            [
              105.74999932164823,
              3.47738682854893
            ],
            [
              107.0999983652961,
              4.263084147817779
            ],
            [
              107.99999772772824,
              4.823813856115095
            ],
            [
              109.57499661198418,
              5.496074035021934
            ],
            [
              110.24999613380825,
              5.943831970446496
            ],
            [
              113.17499406171223,
              7.730954611329906
            ],
            [
              114.97499278657611,
              9.066444239907042
            ],
            [
              116.54999167083221,
              12.60167220474645
            ],
            [
              118.01327063423193,
              15.439678695519973
            ],
            [
              119.02498991752029,
              17.81054639660823
            ],
            [
              119.92498927995226,
              18.66532468424984
            ],
            [
              121.04998848299223,
              19.515816877621404
            ],
            [
              122.84998720785634,
              19.727750791331374
            ],
            [
              125.99998497636832,
              20.36185803765278
            ],
            [
              130.94998146974442,
              21.2032879796254
            ],
            [
              138.59997605041642,
              24.1097818895268
            ]
          ],
          [
            [
              107.0791983800311,
              10.342138429682905
            ],
            [
              107.77499788712001,
              9.63534238476447
            ],
            [
              108.6749972495522,
              9.302441529883055
            ],
            [
              111.59999517745612,
              9.302441529883055
            ],
            [
              113.39999390232023,
              9.08033076823304
            ],
            [
              114.97499278657611,
              9.08033076823304
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "asia-pacific-gateway-apg",
        "name": "Asia Pacific Gateway (APG)",
        "color": "#b63894",
        "feature_id": "asia-pacific-gateway-apg-0",
        "coordinates": [
          121.36481825910053,
          20.65939849597791
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              125.54998529515225,
              24.12261698700334
            ],
            [
              122.84998720785634,
              24.8393128255928
            ],
            [
              122.17498768603221,
              24.89034854048822
            ],
            [
              121.80144795065141,
              24.863504112487874
            ]
          ],
          [
            [
              121.92508786246773,
              30.86475026744725
            ],
            [
              122.17498768603221,
              30.80481662242596
            ],
            [
              124.19998625150438,
              30.126049846722907
            ],
            [
              125.54998529515225,
              29.540507745394393
            ],
            [
              127.57498386062443,
              28.55704546571141
            ]
          ],
          [
            [
              127.79998370123226,
              29.540507745394393
            ],
            [
              125.99998497636832,
              30.70813999354155
            ],
            [
              124.19998625150438,
              31.478822672736243
            ],
            [
              122.84998720785634,
              31.766210259726904
            ],
            [
              121.94998784542437,
              31.67051304708704
            ],
            [
              121.39529823837174,
              31.619800328867854
            ]
          ],
          [
            [
              136.79997732555248,
              31.28673881439167
            ],
            [
              137.24997700676838,
              32.81231878328768
            ],
            [
              136.87399727311598,
              34.33682825203164
            ]
          ],
          [
            [
              112.04999485867219,
              12.615395567393307
            ],
            [
              111.59999517745612,
              13.054150695298716
            ],
            [
              110.69999581502415,
              14.801154224791475
            ],
            [
              109.79999645259218,
              15.561165635263421
            ],
            [
              108.89999709016004,
              15.886035719079104
            ],
            [
              108.19247759137372,
              16.04339300520843
            ]
          ],
          [
            [
              107.99999772772824,
              6.852191098754417
            ],
            [
              105.29999964043216,
              7.075530930004675
            ],
            [
              103.04883123518098,
              7.116812251931691
            ],
            [
              101.70000219070411,
              7.187160551695541
            ],
            [
              100.59971297015935,
              7.075917959575207
            ]
          ],
          [
            [
              113.84999358353613,
              18.251816319028308
            ],
            [
              113.84999358353613,
              20.796306105108954
            ],
            [
              114.25868329401678,
              22.318292678971574
            ]
          ],
          [
            [
              128.6999830636644,
              30.514495959759188
            ],
            [
              128.6999830636644,
              31.67051304708704
            ],
            [
              128.92498290427224,
              32.81231878328768
            ],
            [
              129.1499827448803,
              34.31215165223537
            ],
            [
              128.99949285148878,
              35.17037876180012
            ]
          ],
          [
            [
              103.32266104119762,
              3.815173420941221
            ],
            [
              103.95000059678412,
              3.940475772228723
            ],
            [
              106.19999900286413,
              5.510071711803135
            ],
            [
              107.99999772772824,
              6.852191098754417
            ],
            [
              111.0374955759361,
              9.96791518697421
            ],
            [
              112.04999485867219,
              12.615395567393307
            ],
            [
              113.84999358353613,
              18.251816319028308
            ],
            [
              116.99999135204828,
              19.74098736552503
            ],
            [
              120.14998912056026,
              20.375041253465525
            ],
            [
              121.04998848299223,
              20.585819096040467
            ],
            [
              122.84998720785634,
              21.00649984517682
            ],
            [
              124.64998593272028,
              22.05298561667763
            ],
            [
              125.54998529515225,
              24.12261698700334
            ],
            [
              125.94373501621621,
              25.55188275942578
            ],
            [
              126.33748473728028,
              26.159307970773796
            ],
            [
              127.57498386062443,
              28.55704546571141
            ],
            [
              127.79998370123226,
              29.540507745394393
            ],
            [
              128.6999830636644,
              30.514495959759188
            ],
            [
              130.49998178852834,
              30.320465424761352
            ],
            [
              132.74998019460836,
              30.126049846722907
            ],
            [
              134.99997860068837,
              30.514495959759188
            ],
            [
              136.79997732555248,
              31.28673881439167
            ],
            [
              138.59997605041642,
              32.43331330641712
            ],
            [
              139.49997541284839,
              33.93964008831958
            ],
            [
              140.0062250542164,
              34.40502275071583
            ],
            [
              140.09059999384837,
              34.69072647741018
            ],
            [
              140.11872497392463,
              34.89859296336232
            ],
            [
              139.97546699999984,
              35.005433
            ]
          ],
          [
            [
              103.9870105705659,
              1.389451396800126
            ],
            [
              104.1874004286079,
              1.302655424516928
            ],
            [
              104.48462521805104,
              1.468426767332062
            ],
            [
              105.29999964043216,
              2.367912558705314
            ],
            [
              106.19999900286413,
              4.613591578862867
            ],
            [
              106.19999900286413,
              5.510071711803135
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "seamewe-4",
        "name": "SeaMeWe-4",
        "color": "#187bb6",
        "feature_id": "seamewe-4-0",
        "coordinates": [
          80.36459874739856,
          12.863368441127506
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              12.15006562871929,
              38.38775473578434
            ],
            [
              12.262565549023208,
              38.21117903702327
            ],
            [
              12.262565549023208,
              36.87321951208918
            ],
            [
              12.487565389631243,
              36.240655233214795
            ],
            [
              12.712565230239278,
              35.876870570092734
            ],
            [
              13.50006467236733,
              35.419780517080454
            ],
            [
              14.4000640347993,
              34.96008232454844
            ],
            [
              16.65006244087931,
              34.49779087043359
            ],
            [
              19.35006052817539,
              34.867831005273246
            ],
            [
              22.05005861487558,
              34.57501887961877
            ],
            [
              25.20005638398345,
              33.84625607000376
            ],
            [
              28.8000538337115,
              32.52821504536482
            ],
            [
              29.893513059094772,
              31.191465077638554
            ],
            [
              30.825052399183477,
              30.828970604876417
            ],
            [
              31.387552000703465,
              30.611323347603474
            ],
            [
              31.950051602223482,
              30.393250512072086
            ],
            [
              32.287551363135435,
              30.223305674181546
            ],
            [
              32.542821182299974,
              29.95909144105582
            ],
            [
              32.596876144006984,
              29.63833609362638
            ],
            [
              32.76567602442739,
              29.34456698948989
            ],
            [
              33.0469258251874,
              28.951554732193216
            ],
            [
              33.52527548632111,
              28.161052262220892
            ],
            [
              34.48129980906347,
              27.364667993860166
            ],
            [
              35.437549131647586,
              26.562513149236622
            ],
            [
              36.22504857377547,
              25.754704263415306
            ],
            [
              37.23754785650971,
              24.12261698700334
            ],
            [
              38.47504697985545,
              22.05298561667763
            ],
            [
              39.182756478507656,
              21.481533475503085
            ]
          ],
          [
            [
              29.893513059094772,
              31.191465077638554
            ],
            [
              30.825052399183477,
              30.901396088515583
            ],
            [
              31.387552000703465,
              30.708097004575848
            ],
            [
              31.950051602223482,
              30.490241707091457
            ],
            [
              32.287551363135435,
              30.271897570624247
            ],
            [
              32.529931191431416,
              29.972545436050268
            ]
          ],
          [
            [
              100.0661133481664,
              6.613518860854185
            ],
            [
              100.0579133539753,
              6.35444710390118
            ],
            [
              99.9924834003265,
              6.205536229871427
            ],
            [
              99.67350362629472,
              5.956366566270248
            ],
            [
              99.00000410340803,
              5.845915088460174
            ],
            [
              97.42500521915215,
              6.405200795356109
            ]
          ],
          [
            [
              62.55002992491163,
              19.952622905164304
            ],
            [
              62.55002992491163,
              22.469443964829594
            ],
            [
              59.85003183761572,
              24.12261698700445
            ],
            [
              58.50003279396768,
              24.583819112323454
            ],
            [
              56.9250339097116,
              24.89034854048822
            ],
            [
              56.333724328601164,
              25.121690004958737
            ]
          ],
          [
            [
              39.182756478507656,
              21.481533475503085
            ],
            [
              39.2625464219835,
              20.375041253465525
            ],
            [
              40.61254546563154,
              18.251816319028308
            ],
            [
              41.62504474836763,
              16.53419619825962
            ],
            [
              42.41254419049548,
              14.801154224791475
            ],
            [
              42.918793831863695,
              13.929303843271725
            ],
            [
              43.200043632623704,
              13.054150695298716
            ],
            [
              43.31254355292762,
              12.834868817846598
            ],
            [
              43.39691849315565,
              12.615395567393307
            ],
            [
              43.818793194295495,
              12.395734000022884
            ],
            [
              44.55004267627157,
              12.12089689803949
            ],
            [
              45.450042038703714,
              12.395734000022884
            ],
            [
              48.600039807215495,
              13.163718917913684
            ],
            [
              55.350035025455725,
              15.669513225155328
            ],
            [
              58.950032475183775,
              17.39502263470061
            ],
            [
              62.55002992491163,
              19.952622905164304
            ],
            [
              66.60002705585575,
              19.952622905164304
            ],
            [
              70.2000245055838,
              19.529070924351004
            ],
            [
              72.87590260996691,
              19.076074257285313
            ]
          ],
          [
            [
              13.358654772543531,
              38.116121616583314
            ],
            [
              13.05006499115126,
              38.38775473578434
            ],
            [
              12.60006530993536,
              38.519865191519216
            ],
            [
              12.15006562871929,
              38.38775473578434
            ],
            [
              10.348617229769246,
              38.47588134813884
            ],
            [
              9.000067859611391,
              38.47588134813884
            ],
            [
              8.10006849777534,
              38.38775473578434
            ],
            [
              7.650068816559269,
              38.651811712711236
            ],
            [
              6.187569852607282,
              41.744358789482135
            ],
            [
              5.372530429989041,
              43.29362778902916
            ]
          ],
          [
            [
              9.867357245811377,
              37.27681625347506
            ],
            [
              9.675067382031244,
              37.58978657360316
            ],
            [
              9.000067859611391,
              38.47588134813884
            ]
          ],
          [
            [
              7.755438741914361,
              36.90282046530186
            ],
            [
              7.875068657167304,
              37.232354321556215
            ],
            [
              7.987568577471222,
              37.94551049545976
            ],
            [
              8.10006849777534,
              38.38775473578434
            ]
          ],
          [
            [
              103.64609081207684,
              1.338585852071589
            ],
            [
              103.50000091556822,
              1.22928089599958
            ],
            [
              103.34065102845304,
              1.299701188578837
            ],
            [
              102.68279723997185,
              1.698782263242975
            ],
            [
              102.15000187192001,
              2.030661890474562
            ],
            [
              101.25000250948804,
              2.480311786858834
            ],
            [
              100.46250306736002,
              3.266814816815753
            ],
            [
              99.78750354553605,
              4.613591578862867
            ],
            [
              99.00000410340803,
              5.286069860821101
            ],
            [
              97.42500521915215,
              6.405200795356109
            ],
            [
              95.40000665367998,
              8.190543417795567
            ],
            [
              93.14492825119876,
              9.524411345019587
            ],
            [
              91.8000092039521,
              10.410816505402636
            ],
            [
              90.00001047908799,
              12.395734000022884
            ],
            [
              88.20001175422405,
              13.49207993623881
            ],
            [
              83.70001494206386,
              13.929255692764542
            ],
            [
              81.45001653598385,
              13.273238157547667
            ],
            [
              80.2429873910547,
              13.063853101883296
            ],
            [
              81.45001653598385,
              11.073982781226704
            ],
            [
              82.12501605602009,
              9.524411345019587
            ],
            [
              82.57501573723616,
              7.744852765543044
            ],
            [
              82.35001589841599,
              5.510023109330234
            ],
            [
              81.00001685476795,
              4.613591578862867
            ],
            [
              78.69513048464998,
              4.613591578862867
            ],
            [
              76.39024411453218,
              6.852191098754417
            ],
            [
              74.19513367248996,
              9.524411345019587
            ],
            [
              72.84513462884212,
              13.492128176464178
            ],
            [
              72.22502307105597,
              16.965102599435824
            ],
            [
              72.87590260996691,
              19.076074257285313
            ]
          ],
          [
            [
              79.87208765380373,
              6.927036656836265
            ],
            [
              79.4250179705119,
              5.957818681088611
            ],
            [
              78.46194979910254,
              4.80725241027767
            ]
          ],
          [
            [
              91.99482906593991,
              21.42927456664907
            ],
            [
              91.8000092039521,
              19.952622905164304
            ],
            [
              88.20001175422405,
              13.49207993623881
            ]
          ],
          [
            [
              66.60002705585575,
              19.952622905164304
            ],
            [
              66.37502721524771,
              20.375041253465525
            ],
            [
              66.60002705585575,
              23.298598065875808
            ],
            [
              67.02854675228852,
              24.889731701235718
            ]
          ],
          [
            [
              101.25000250948804,
              2.480311786858834
            ],
            [
              101.70000219070411,
              2.367912558705314
            ],
            [
              102.22090182169396,
              2.273260323566632
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "batam-rengit-cable-system-brcs",
        "name": "Batam-Rengit Cable System (BRCS)",
        "color": "#9f2f87",
        "feature_id": "batam-rengit-cable-system-brcs-0",
        "coordinates": [
          103.51644225290156,
          1.2521600488194053
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              103.14775116510523,
              1.677427721183818
            ],
            [
              103.34065102845304,
              1.383978004154756
            ],
            [
              103.50000091556822,
              1.27130835678102
            ],
            [
              103.66875079602411,
              1.074774789350442
            ],
            [
              103.78125071632823,
              1.01853421661562
            ],
            [
              103.9597205893024,
              1.133473844374663
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "southeast-asia-japan-cable-sjc",
        "name": "Southeast Asia-Japan Cable (SJC)",
        "color": "#6bbd44",
        "feature_id": "southeast-asia-japan-cable-sjc-0",
        "coordinates": [
          120.61553906063696,
          20.150773874415364
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              116.67753158048177,
              23.355006811273626
            ],
            [
              117.16874123250417,
              22.884654113882362
            ],
            [
              118.34999039569615,
              22.10511054810837
            ],
            [
              120.14998912056026,
              20.16397503197578
            ]
          ],
          [
            [
              115.19999262718417,
              14.801154224791475
            ],
            [
              116.99999135204828,
              14.14758350694865
            ],
            [
              118.79999007691222,
              13.929303843271725
            ],
            [
              120.14998912056026,
              14.14758350694865
            ],
            [
              120.62298878548287,
              14.088232047424441
            ]
          ],
          [
            [
              116.09999198961614,
              18.251816319028308
            ],
            [
              115.19999262718417,
              19.529070924351004
            ],
            [
              114.74999294596827,
              20.796306105108954
            ],
            [
              114.2999932647522,
              21.635297384859456
            ],
            [
              114.20292333351765,
              22.222050419736746
            ]
          ],
          [
            [
              103.64609081207684,
              1.324527159471245
            ],
            [
              104.16846044202524,
              1.223182297279749
            ],
            [
              104.28790035741284,
              1.257699181467302
            ],
            [
              104.51270019816243,
              1.45436885137326
            ],
            [
              105.01874983967215,
              2.803404866588352
            ],
            [
              105.74999932164823,
              3.701945083003608
            ],
            [
              107.0999983652961,
              4.487428146931876
            ],
            [
              107.99999772772824,
              5.047979159038675
            ],
            [
              108.6749972495522,
              5.496074035021934
            ],
            [
              109.34999677137611,
              5.943831970446496
            ],
            [
              111.82499501806419,
              7.730954611329906
            ],
            [
              113.73750165524976,
              9.954064678408741
            ],
            [
              114.74999294596827,
              13.040451242220257
            ],
            [
              115.19999262718417,
              14.787557926773019
            ],
            [
              116.09999198961614,
              18.23846081095263
            ],
            [
              120.14998912056026,
              20.150773874415364
            ],
            [
              121.04998848299223,
              20.150773874415364
            ],
            [
              122.84998720785634,
              20.572653976019353
            ],
            [
              125.99998497636832,
              21.412906652073104
            ],
            [
              130.94998146974442,
              23.285681659873603
            ],
            [
              132.74998019460836,
              24.1097818895268
            ],
            [
              137.24997700676838,
              27.352178406079418
            ],
            [
              138.82497589102448,
              28.939248910255202
            ],
            [
              139.94997509406446,
              30.889328974889338
            ],
            [
              140.39997477528036,
              32.421443555350606
            ],
            [
              139.94997509406446,
              33.927972678693465
            ],
            [
              140.3437248151284,
              34.39341949240347
            ],
            [
              140.25934987430443,
              34.6791629819069
            ],
            [
              140.06247501377246,
              34.840904848139274
            ],
            [
              139.9548550906076,
              34.9650466035836
            ]
          ],
          [
            [
              114.57069307298596,
              4.703623084487219
            ],
            [
              114.2999932647522,
              5.061986954416028
            ],
            [
              111.82499501806419,
              7.744889052551343
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "europe-india-gateway-eig",
        "name": "Europe India Gateway (EIG)",
        "color": "#a35e29",
        "feature_id": "europe-india-gateway-eig-0",
        "coordinates": [
          2.5895572750517033,
          37.8061075713924
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              29.700933195520264,
              31.072270031660224
            ],
            [
              29.98130299690345,
              30.562943259201205
            ],
            [
              30.375052717967577,
              30.199000717300606
            ],
            [
              31.050052239791512,
              29.833707764444142
            ],
            [
              31.725051761615447,
              29.589433775533877
            ],
            [
              32.17505144283152,
              29.344436236246537
            ],
            [
              32.65318110412005,
              29.113614162980156
            ]
          ],
          [
            [
              -5.624921779312757,
              35.922435577344146
            ],
            [
              -5.347581975782816,
              36.156019514134556
            ]
          ],
          [
            [
              32.65318110412005,
              29.113614162980156
            ],
            [
              33.455063036063336,
              28.161052262220892
            ],
            [
              34.368799888759554,
              27.364667993860166
            ],
            [
              35.268799251191524,
              26.562513149236622
            ],
            [
              36.0000487331676,
              25.754704263415306
            ],
            [
              37.01254801590258,
              24.12261698700334
            ],
            [
              38.25004713924761,
              22.05298561667763
            ],
            [
              39.037546581375466,
              20.375041253465525
            ],
            [
              40.387545625023506,
              18.251816319028308
            ],
            [
              41.40004490776067,
              16.53419619825962
            ],
            [
              42.30004427019156,
              14.801154224791475
            ],
            [
              42.80629391155958,
              13.929303843271725
            ],
            [
              43.14379367247156,
              13.054150695298716
            ],
            [
              43.284418572851564,
              12.834868817846598
            ],
            [
              43.368793513079595,
              12.615395567393307
            ],
            [
              43.76254323414352,
              12.395734000022884
            ],
            [
              44.55004267627157,
              12.06589527357022
            ],
            [
              45.450042038703714,
              12.285833556268273
            ],
            [
              48.600039807215495,
              13.054150695298716
            ],
            [
              55.350035025455725,
              15.452760959322148
            ],
            [
              58.950032475183775,
              17.180187287481402
            ],
            [
              62.32503008430379,
              19.529070924351004
            ],
            [
              66.60002705585575,
              19.74098736552503
            ],
            [
              70.2000245055838,
              19.42300815558187
            ],
            [
              72.87590260996691,
              19.076074257285313
            ]
          ],
          [
            [
              32.65318110412005,
              29.113614162980156
            ],
            [
              32.17505144283152,
              29.295435552938898
            ],
            [
              31.725051761615447,
              29.491558027403585
            ],
            [
              31.050052239791512,
              29.73606949729205
            ],
            [
              30.375052717967577,
              30.101720899554863
            ],
            [
              29.98130299690345,
              30.514495959759188
            ],
            [
              29.700933195520264,
              31.072270031660224
            ],
            [
              27.900054471279333,
              32.052708023486105
            ],
            [
              25.20005638398345,
              32.859581490460556
            ],
            [
              22.05005861487558,
              33.59673284538093
            ],
            [
              19.35006052817539,
              33.565491482352144
            ],
            [
              16.65006244087931,
              33.18971466460036
            ],
            [
              14.4000640347993,
              33.98629373718458
            ],
            [
              12.318815509181547,
              35.419780517080454
            ],
            [
              12.037565708415343,
              35.78566189952613
            ],
            [
              11.925065788111255,
              36.240655233214795
            ],
            [
              11.925065786919447,
              37.232354321556215
            ],
            [
              10.912566505375338,
              37.67887792909195
            ],
            [
              10.348617229769246,
              38.12273010839229
            ],
            [
              9.000067859611391,
              38.12273010839229
            ],
            [
              5.400070410479259,
              38.12273010839229
            ],
            [
              2.250072641967279,
              37.76786242517883
            ],
            [
              -2.249924170192742,
              36.05897312258671
            ],
            [
              -4.499922576272752,
              35.96797434759347
            ],
            [
              -5.624921779312757,
              35.922435577344146
            ],
            [
              -6.299921301136777,
              35.876870570092734
            ],
            [
              -8.999919388432772,
              36.05897312258671
            ],
            [
              -9.899918750864742,
              36.42191605012607
            ],
            [
              -11.249917794512783,
              37.94551049545976
            ],
            [
              -13.499916200592793,
              39.69832335493328
            ],
            [
              -13.499916200592793,
              44.051519228735145
            ],
            [
              -9.449919069648843,
              48.70423463096077
            ],
            [
              -7.649920344784732,
              49.58728674004675
            ],
            [
              -6.074881460557094,
              50.31116725161084
            ],
            [
              -4.544402544762761,
              50.82820142743802
            ]
          ],
          [
            [
              -11.249917794512783,
              37.94551049545976
            ],
            [
              -10.349918432080813,
              37.94551049545976
            ],
            [
              -9.33741914934481,
              38.12273010839229
            ],
            [
              -9.102749315587062,
              38.443079483141986
            ]
          ],
          [
            [
              14.4000640347993,
              33.98629373718458
            ],
            [
              13.50006467236733,
              33.09551711711572
            ],
            [
              13.187364893886866,
              32.87762290319543
            ]
          ],
          [
            [
              7.426728974775415,
              43.73825568793552
            ],
            [
              7.312569055647288,
              43.40114497315386
            ],
            [
              7.425068975951234,
              41.744358789482135
            ],
            [
              7.969108590548586,
              39.423519823304105
            ],
            [
              8.325068338383204,
              38.651811712711236
            ],
            [
              8.437568258687293,
              38.38775473578434
            ],
            [
              9.000067859611391,
              38.12273010839229
            ]
          ],
          [
            [
              44.55004267627157,
              12.06589527357022
            ],
            [
              43.6500433138396,
              11.818224495851751
            ],
            [
              43.16164365982672,
              11.573660387694133
            ]
          ],
          [
            [
              38.25004713924761,
              22.05298561667763
            ],
            [
              39.182756478507656,
              21.481533475503085
            ]
          ],
          [
            [
              56.333724328601164,
              25.121690004958737
            ],
            [
              56.9250339097116,
              24.66052224964892
            ],
            [
              58.00442314506233,
              23.679602601493002
            ],
            [
              58.16253303305572,
              23.96851099673472
            ],
            [
              58.72503263457574,
              24.32780311165172
            ],
            [
              59.85003183761572,
              24.01990020343348
            ],
            [
              62.32503008430379,
              22.469443964829594
            ],
            [
              62.32503008430379,
              19.529070924351004
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "asia-africa-europe-1-aae-1",
        "name": "Asia Africa Europe-1 (AAE-1)",
        "color": "#a1489b",
        "feature_id": "asia-africa-europe-1-aae-1-0",
        "coordinates": [
          50.33568329165678,
          12.983871550143533
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              29.700933195520264,
              31.072270031660224
            ],
            [
              29.98130299690345,
              30.75649044225247
            ],
            [
              30.375052717967577,
              30.587157843201965
            ],
            [
              31.050052239791512,
              30.223305674181546
            ],
            [
              31.725051761615447,
              29.979986367503585
            ],
            [
              32.17505144283152,
              29.491296898779723
            ],
            [
              32.65318110412005,
              29.113614162980156
            ]
          ],
          [
            [
              107.0791983800311,
              10.342138429682905
            ],
            [
              107.77499788712001,
              9.85709470870242
            ],
            [
              108.6749972495522,
              9.746236973759864
            ],
            [
              110.24999613380825,
              9.96791518697421
            ]
          ],
          [
            [
              94.39121736831603,
              16.858032255708565
            ],
            [
              93.60000792881604,
              16.749771315644608
            ],
            [
              92.250008885168,
              14.801154224791475
            ],
            [
              90.90000984151996,
              9.524411345019587
            ],
            [
              90.45001016030389,
              5.061986954416028
            ],
            [
              90.00001047908799,
              3.715978119297972
            ]
          ],
          [
            [
              100.0661133481664,
              6.613518860854185
            ],
            [
              100.35005314702076,
              6.908035999527129
            ],
            [
              100.59971297015935,
              7.075917959575207
            ]
          ],
          [
            [
              19.35006052817539,
              34.12610104005762
            ],
            [
              19.35006052817539,
              37.67887792909195
            ],
            [
              18.95777080607718,
              39.48474996079955
            ],
            [
              18.787560926655374,
              40.04369219282995
            ],
            [
              18.675061006351456,
              40.387320290775165
            ],
            [
              18.00006148452735,
              40.89949091487156
            ],
            [
              16.86881228591494,
              41.125709058522546
            ]
          ],
          [
            [
              45.03354233375589,
              12.800877546853712
            ],
            [
              45.450042038703714,
              11.735650161405744
            ]
          ],
          [
            [
              22.57920153405354,
              33.89459266962973
            ],
            [
              22.95005797790344,
              35.419780517080454
            ],
            [
              23.51255757942343,
              35.78566189952613
            ],
            [
              23.737557420031465,
              35.78566189952613
            ],
            [
              24.01216722549529,
              35.51204255863749
            ]
          ],
          [
            [
              103.50674091079347,
              10.630401703210367
            ],
            [
              103.72500075617612,
              9.524411345019587
            ],
            [
              103.72500075617612,
              8.190543417795567
            ],
            [
              103.04883123518098,
              7.228431783286396
            ]
          ],
          [
            [
              97.42500521915215,
              5.957818681088611
            ],
            [
              99.00000410340803,
              6.069699469735895
            ],
            [
              100.0661133481664,
              6.613518860854185
            ],
            [
              100.2376532266459,
              6.908035999527129
            ],
            [
              100.59971297015935,
              7.075917959575207
            ],
            [
              101.70000219070411,
              7.242965106491965
            ],
            [
              103.04883123518098,
              7.228431783286396
            ],
            [
              105.29999964043216,
              7.298762754459602
            ],
            [
              107.99999772772824,
              8.079175518240902
            ],
            [
              110.24999613380825,
              9.96791518697421
            ],
            [
              111.59999517745612,
              12.615395567393307
            ],
            [
              113.6249937429283,
              18.251816319028308
            ],
            [
              113.73749366323219,
              20.796306105108954
            ],
            [
              114.26024329291155,
              22.208034251394366
            ]
          ],
          [
            [
              58.60608271884112,
              23.57608486453742
            ],
            [
              58.950032475183775,
              23.608214441358456
            ],
            [
              59.85003183761572,
              23.50508968095727
            ]
          ],
          [
            [
              61.200030881263785,
              17.39502263470061
            ],
            [
              61.200030881263785,
              22.469443964829594
            ],
            [
              59.85003183761572,
              23.50508968095727
            ],
            [
              59.04197976437254,
              23.91203783447051
            ],
            [
              58.50003279396768,
              24.19960051856532
            ],
            [
              56.9250339097116,
              24.788256058863453
            ],
            [
              56.333724328601164,
              25.121690004958737
            ],
            [
              56.9250339097116,
              25.348717422116806
            ],
            [
              56.981283869863745,
              26.159307970773796
            ],
            [
              56.81253398940768,
              26.562513149236622
            ],
            [
              56.250034387887666,
              26.663094151095322
            ],
            [
              55.800034706671596,
              26.310674619140343
            ],
            [
              55.350035025455725,
              26.159307970773796
            ],
            [
              53.550036300591586,
              26.159307970773796
            ],
            [
              52.200037256943745,
              25.95717997876443
            ],
            [
              51.51927773920005,
              25.294608758024538
            ]
          ],
          [
            [
              72.87590260996691,
              19.076074257285313
            ],
            [
              71.7750233898399,
              16.965102599435824
            ]
          ],
          [
            [
              5.372530429989041,
              43.29362778902916
            ],
            [
              6.300069772911229,
              41.744358789482135
            ],
            [
              7.875068657167304,
              38.651811712711236
            ],
            [
              8.212568418079258,
              38.38775473578434
            ],
            [
              9.000067859611391,
              38.21117903702327
            ],
            [
              10.348617229769246,
              38.21117903702327
            ],
            [
              10.912566505375338,
              37.76786242517883
            ],
            [
              12.037565707223564,
              37.232354321556215
            ],
            [
              12.037565708415343,
              36.240655233214795
            ],
            [
              12.375065469327325,
              35.78566189952613
            ],
            [
              12.825065150547204,
              35.419780517080454
            ],
            [
              14.4000640347993,
              34.40502275071583
            ],
            [
              16.65006244087931,
              33.75276987113051
            ],
            [
              19.35006052817539,
              34.12610104005762
            ],
            [
              22.05005861487558,
              34.01738195075367
            ],
            [
              25.20005638398345,
              33.283811019051
            ],
            [
              27.900054471279333,
              32.33831157801282
            ],
            [
              29.700933195520264,
              31.072270031660224
            ],
            [
              29.98130299690345,
              30.70813999354155
            ],
            [
              30.375052717967577,
              30.490263249963675
            ],
            [
              31.050052239791512,
              30.126049846722907
            ],
            [
              31.725051761615447,
              29.88249116219714
            ],
            [
              32.17505144283152,
              29.491296898779723
            ],
            [
              32.65318110412005,
              29.113614162980156
            ],
            [
              33.398913075842444,
              28.161052262220892
            ],
            [
              33.97505016769546,
              27.364667993860166
            ],
            [
              34.67817466959545,
              26.562513149236622
            ],
            [
              35.21254929103955,
              25.754704263415306
            ],
            [
              36.22504857377547,
              24.12261698700334
            ],
            [
              37.46254769711956,
              22.05298561667763
            ],
            [
              38.25004713924761,
              20.375041253465525
            ],
            [
              39.600046182895454,
              18.251816319028308
            ],
            [
              40.61254546563373,
              16.53419619825962
            ],
            [
              41.90629454912761,
              14.801154224791475
            ],
            [
              42.41254419049548,
              13.929303843271725
            ],
            [
              42.94691881193958,
              13.054150695298716
            ],
            [
              43.185981142585575,
              12.834868817846598
            ],
            [
              43.270356082813606,
              12.615395567393307
            ],
            [
              43.565668373611544,
              12.395734000022884
            ],
            [
              44.55004267627157,
              11.735650161405744
            ],
            [
              45.450042038703714,
              11.735650161405744
            ],
            [
              48.600039807215495,
              12.505588131780542
            ],
            [
              55.350035025455725,
              14.365653759228536
            ],
            [
              58.950032475183775,
              16.10232559580288
            ],
            [
              61.200030881263785,
              17.39502263470061
            ],
            [
              66.60002705585575,
              18.465364393137207
            ],
            [
              71.7750233898399,
              16.965102599435824
            ],
            [
              71.94513526640995,
              13.492128176464178
            ],
            [
              73.29513431005799,
              9.524411345019587
            ],
            [
              75.49024475210021,
              6.852191098754417
            ],
            [
              78.69513048464998,
              4.164912849976844
            ],
            [
              79.65001781052402,
              3.64113270007644
            ],
            [
              81.00001685476795,
              3.266814816815753
            ],
            [
              85.50001366692797,
              2.817450442654064
            ],
            [
              90.00001047908799,
              3.715978119297972
            ],
            [
              92.7000085663839,
              5.061986954416028
            ],
            [
              94.27500745064,
              5.957818681088611
            ],
            [
              95.40000665367998,
              6.293390948068463
            ],
            [
              97.42500521915215,
              5.957818681088611
            ],
            [
              99.67500362523216,
              5.733989114150035
            ],
            [
              100.37321313001817,
              5.420859215720895
            ]
          ],
          [
            [
              43.147993669496344,
              11.594869371447714
            ],
            [
              43.6500433138396,
              11.735552251813374
            ],
            [
              44.55004267627157,
              11.735650161405744
            ]
          ],
          [
            [
              39.182756478507656,
              21.481533475503085
            ],
            [
              37.46254769711956,
              22.05298561667763
            ]
          ],
          [
            [
              67.02854675228852,
              24.889731701235718
            ],
            [
              65.25002801161182,
              24.1568376182791
            ],
            [
              61.200030881263785,
              22.469443964829594
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "pishgaman-oman-iran-poi-network",
        "name": "Pishgaman Oman Iran (POI) Network",
        "color": "#939597",
        "feature_id": "pishgaman-oman-iran-poi-network-0",
        "coordinates": [
          57.94912494279393,
          24.691134419401862
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              57.79730329178804,
              25.6813227078824
            ],
            [
              57.959313177018714,
              25.190816399183404
            ],
            [
              57.93753319244769,
              24.12261698700334
            ],
            [
              58.00442314506233,
              23.679602601493002
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "pishgaman-oman-iran-poi-network",
        "name": "Pishgaman Oman Iran (POI) Network",
        "color": "#90499c",
        "feature_id": "pishgaman-oman-iran-poi-network-1",
        "coordinates": [
          59.02428730966156,
          24.856138290059135
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              60.63284128306631,
              25.295928769129716
            ],
            [
              60.300031518831815,
              25.145210227401265
            ],
            [
              58.950032475183775,
              24.8393128255928
            ],
            [
              58.050033112751606,
              24.12261698700334
            ],
            [
              58.00442314506233,
              23.679602601493002
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "omranepeg-cable-system",
        "name": "OMRAN/EPEG Cable System",
        "color": "#3e67b1",
        "feature_id": "omranepeg-cable-system-0",
        "coordinates": [
          59.08830105766842,
          24.773753100947744
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              56.257944538534105,
              25.616974601949437
            ],
            [
              56.58753414879965,
              25.348717422116806
            ],
            [
              57.32386175217832,
              24.420846844473175
            ],
            [
              58.004427676309035,
              23.679602315303896
            ]
          ],
          [
            [
              56.24681173392068,
              26.181306905191207
            ],
            [
              56.36253430819161,
              26.41147606086841
            ],
            [
              56.58753414879965,
              26.41147606086841
            ],
            [
              56.700034069103765,
              26.159307970773796
            ],
            [
              56.57083494187961,
              25.79084837431668
            ],
            [
              56.257944538534105,
              25.616974601949437
            ]
          ],
          [
            [
              57.79729891679102,
              25.681326932340312
            ],
            [
              57.79729891679102,
              25.190816964742595
            ],
            [
              57.32386175217832,
              24.420846844473175
            ]
          ],
          [
            [
              58.004427676309035,
              23.679602315303896
            ],
            [
              58.10628307290375,
              24.12261698700334
            ],
            [
              58.950032475183775,
              24.73717827217618
            ],
            [
              60.300031518831815,
              25.094280247013245
            ],
            [
              60.632843783064516,
              25.295930181805318
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "bay-of-bengal-gateway-bbg",
        "name": "Bay of Bengal Gateway (BBG)",
        "color": "#a4332b",
        "feature_id": "bay-of-bengal-gateway-bbg-0",
        "coordinates": [
          73.99086922217361,
          9.225265276259876
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              79.88937764155523,
              6.820776084597128
            ],
            [
              79.53751789081602,
              5.957818681088611
            ],
            [
              78.45083428096896,
              4.580645089774947
            ]
          ],
          [
            [
              80.2429873910547,
              13.063853101883296
            ],
            [
              81.45001653598385,
              12.615395567393307
            ],
            [
              83.70001494206386,
              12.17588718550806
            ],
            [
              89.10001111665602,
              10.853089690745378
            ],
            [
              93.15000824759997,
              9.296323017976874
            ],
            [
              95.40000665367998,
              7.967776882259614
            ],
            [
              97.42500521915215,
              7.131299528983504
            ],
            [
              99.67500362523216,
              5.622041180883144
            ],
            [
              100.36299313785392,
              5.35384659465538
            ]
          ],
          [
            [
              72.00002323044774,
              16.965102599435824
            ],
            [
              72.87590260996691,
              19.076074257285313
            ]
          ],
          [
            [
              97.42500521915215,
              7.131299528983504
            ],
            [
              95.40000665367998,
              6.405200795356109
            ],
            [
              94.27500745064,
              6.181557032537114
            ],
            [
              92.7000085663839,
              5.510071711803135
            ],
            [
              90.00001047908799,
              4.613591578862867
            ],
            [
              85.50001366692797,
              3.715978119297972
            ],
            [
              81.00001685476795,
              3.491423322320486
            ],
            [
              79.65001781052402,
              3.865649782481938
            ],
            [
              78.69513048464998,
              4.389285926050889
            ],
            [
              75.94024443331628,
              6.852191098754417
            ],
            [
              73.74513399127406,
              9.524411345019587
            ],
            [
              72.39513494762602,
              13.492128176464178
            ],
            [
              72.00002323044774,
              16.965102599435824
            ],
            [
              66.82502689646378,
              20.585819096040467
            ],
            [
              62.662529845215744,
              23.917101290935022
            ],
            [
              59.85003183761572,
              24.73717827217783
            ],
            [
              58.72503263457574,
              24.941363171753835
            ],
            [
              56.9250339097116,
              24.96686257111105
            ],
            [
              56.333724328601164,
              25.121690004958737
            ]
          ],
          [
            [
              58.72503263457574,
              24.941363171753835
            ],
            [
              58.2187829932077,
              23.917101290935022
            ],
            [
              58.2294229856702,
              23.679602601493002
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "jasuka",
        "name": "JaSuKa",
        "color": "#32499f",
        "feature_id": "jasuka-0",
        "coordinates": [
          108.86108464351273,
          0.12453318129601598
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              107.12099835041957,
              -5.981154260263196
            ],
            [
              107.21249828560005,
              -5.273944363641391
            ],
            [
              107.66249796681612,
              -4.601453764837203
            ],
            [
              107.32599820519593,
              -3.029995968008762
            ],
            [
              107.30493637508945,
              -2.783713633979005
            ],
            [
              107.41104629497707,
              -2.628238536322556
            ],
            [
              107.6628879665399,
              -2.767442755874555
            ],
            [
              107.6628879665399,
              -2.517708985005584
            ],
            [
              107.7605478973567,
              -2.237970538313903
            ],
            [
              108.22499756833614,
              -1.231315750217505
            ],
            [
              108.89999709016004,
              -0.218910724747275
            ],
            [
              109.33554678161275,
              -0.027021392288191
            ],
            [
              108.89999709016004,
              0.118588418888407
            ],
            [
              104.84999995921609,
              0.737317714243915
            ],
            [
              104.40000027800002,
              0.793562652607278
            ],
            [
              104.28750035769608,
              0.906050180868988
            ],
            [
              104.17500043739219,
              0.962292662396749
            ],
            [
              104.01663700000026,
              1.066798000000432
            ],
            [
              103.88438064327008,
              1.159128690702147
            ],
            [
              103.50000091556822,
              1.159083699942101
            ],
            [
              103.34065102845304,
              1.131139301987133
            ],
            [
              102.68279723997185,
              1.473918781768162
            ],
            [
              102.15000187192001,
              1.637114620371079
            ],
            [
              101.7281271707802,
              1.693340822791811
            ],
            [
              101.44766236946415,
              1.665522797277134
            ]
          ],
          [
            [
              105.25409967294817,
              -5.409293894311929
            ],
            [
              105.3562496005841,
              -5.721872747834027
            ],
            [
              105.88026922936368,
              -5.868190997616892
            ],
            [
              106.4249988434722,
              -5.721872747834027
            ],
            [
              106.83339855415792,
              -6.171588071824203
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "indonesia-global-gateway-igg-system",
        "name": "Indonesia Global Gateway (IGG) System",
        "color": "#5a9f43",
        "feature_id": "indonesia-global-gateway-igg-system-0",
        "coordinates": [
          113.4020215219152,
          -5.649295492359784
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              116.83129147155691,
              -1.265389667587925
            ],
            [
              117.08124129449001,
              -1.402870227222166
            ],
            [
              117.85190631946367,
              -1.515333651974942
            ]
          ],
          [
            [
              120.59998880177616,
              1.918228780215685
            ],
            [
              118.79999007691222,
              2.817450442654064
            ],
            [
              117.89999071448025,
              3.154491498099929
            ],
            [
              117.67499087387225,
              3.154491498099929
            ],
            [
              117.57851094221976,
              3.327354396392252
            ]
          ],
          [
            [
              117.44999103326418,
              -4.377144375531941
            ],
            [
              118.79999007691222,
              -4.937784304559415
            ],
            [
              119.41238964308272,
              -5.152180217334632
            ]
          ],
          [
            [
              116.58310164737688,
              -5.749115659923348
            ],
            [
              116.54999167083221,
              -6.616650693475464
            ],
            [
              115.8749921490083,
              -8.17849027894485
            ],
            [
              115.8749921490083,
              -8.401139048122928
            ],
            [
              115.76249222870419,
              -8.512415748196815
            ],
            [
              115.59696234596723,
              -8.463932731980094
            ]
          ],
          [
            [
              114.46970572643434,
              -5.945707155070551
            ],
            [
              113.39999390232023,
              -6.616650693475464
            ],
            [
              113.28349007860268,
              -6.902248768835818
            ]
          ],
          [
            [
              103.96260058785819,
              1.134723598626692
            ],
            [
              103.89883584657085,
              1.191468968816821
            ]
          ],
          [
            [
              103.64609081207684,
              1.338585852071589
            ],
            [
              103.89883584657085,
              1.191468968816821
            ]
          ],
          [
            [
              107.21249828560005,
              -4.601453764837203
            ],
            [
              106.76249860438415,
              -5.273944363641391
            ],
            [
              106.83339855415792,
              -6.128964849210604
            ]
          ],
          [
            [
              101.44766236946415,
              1.665522797277134
            ],
            [
              101.7281271707802,
              1.749465440761298
            ],
            [
              102.15000187192001,
              1.749565394075489
            ],
            [
              102.68279723997185,
              1.530149411894001
            ],
            [
              103.34065102845304,
              1.215421560433896
            ],
            [
              103.50000091556822,
              1.187252773694183
            ],
            [
              103.89883584657085,
              1.191468968816821
            ],
            [
              104.13781046373808,
              1.238391276726333
            ],
            [
              104.28790035741284,
              1.173518198634099
            ],
            [
              104.62500011860826,
              1.131014326431796
            ],
            [
              104.84999995921609,
              1.01853421661562
            ],
            [
              105.29999964043216,
              1.01853421661562
            ],
            [
              106.64999868408003,
              -0.331409329660175
            ],
            [
              107.03664841017385,
              -2.130918480960247
            ],
            [
              107.1530983276796,
              -3.029995968008762
            ],
            [
              107.21249828560005,
              -4.601453764837203
            ],
            [
              112.04999485867219,
              -5.273944363641391
            ],
            [
              114.46970572643434,
              -5.945707155070551
            ],
            [
              116.58310164737688,
              -5.749115659923348
            ],
            [
              117.44999103326418,
              -4.377144375531941
            ],
            [
              117.59999092700289,
              -2.730375485267956
            ],
            [
              117.85190631946367,
              -1.515333651974942
            ],
            [
              118.54128832686715,
              -0.810555324740847
            ],
            [
              119.47498959873613,
              0.568578852526286
            ],
            [
              119.69998943934436,
              1.01853421661562
            ],
            [
              120.59998880177616,
              1.918228780215685
            ],
            [
              124.19998625150438,
              1.918228780215685
            ],
            [
              124.83963579837055,
              1.490779296094786
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "global-caribbean-network-gcn",
        "name": "Global Caribbean Network (GCN)",
        "color": "#5dba46",
        "feature_id": "global-caribbean-network-gcn-0",
        "coordinates": [
          -62.59655370308951,
          16.777516554530678
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -64.74698,
              17.727510000000336
            ],
            [
              -64.57488001860925,
              18.144943564296312
            ],
            [
              -64.12488033739326,
              18.251816319028308
            ],
            [
              -63.449880815569244,
              18.144943564296312
            ],
            [
              -63.08246600000031,
              18.067518999999663
            ],
            [
              -62.99988113435326,
              17.986414235906807
            ],
            [
              -62.85055124073591,
              17.897908000000143
            ]
          ],
          [
            [
              -63.08246600000031,
              18.067518999999663
            ],
            [
              -63.46419080602789,
              17.71178205639049
            ],
            [
              -63.46419080602789,
              17.616496978706607
            ],
            [
              -63.28321093423578,
              17.422853263294712
            ],
            [
              -62.943581174236684,
              17.180187287481402
            ],
            [
              -61.874881931313254,
              15.94013010690935
            ],
            [
              -61.74619202307433,
              16.020844700000282
            ],
            [
              -61.60779212052215,
              15.892797111330793
            ],
            [
              -61.52639217818664,
              15.98876894276924
            ],
            [
              -61.57168214610279,
              16.24447966793278
            ]
          ],
          [
            [
              -64.57488001860925,
              18.144943564296312
            ],
            [
              -64.83573983381382,
              18.415247054142867
            ],
            [
              -65.36237946073729,
              18.57203905256688
            ],
            [
              -65.53112934119326,
              18.57203905256688
            ],
            [
              -65.69987922164925,
              18.67864702215462
            ],
            [
              -65.86862910210533,
              18.67864702215462
            ],
            [
              -66.10666893347562,
              18.466104232947515
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "americas-ii",
        "name": "Americas-II",
        "color": "#398eb1",
        "feature_id": "americas-ii-0",
        "coordinates": [
          -46.8483424432562,
          5.977059419270773
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -58.049884640977254,
              13.054150695298716
            ],
            [
              -58.49988432219324,
              12.615395567393307
            ],
            [
              -60.74988272827325,
              11.735650161405744
            ],
            [
              -61.08738248918522,
              11.294709319565555
            ],
            [
              -61.31238232979325,
              10.853089690745378
            ],
            [
              -61.516682185065264,
              10.649792459143697
            ]
          ],
          [
            [
              -38.54296845985962,
              -3.718735129291019
            ],
            [
              -38.92489818929701,
              -1.231315750217505
            ],
            [
              -40.499897073553086,
              1.468426767332062
            ],
            [
              -46.79989261057713,
              5.957818681088611
            ],
            [
              -51.29988942273715,
              7.744889052551343
            ],
            [
              -54.89988687246519,
              9.96791518697421
            ],
            [
              -58.049884640977254,
              13.054150695298716
            ]
          ],
          [
            [
              -80.16016897784436,
              26.01054866801087
            ],
            [
              -79.64986933934539,
              26.2097854122473
            ],
            [
              -78.86236989721743,
              26.964304734562802
            ],
            [
              -77.84987061448136,
              27.56430948794184
            ],
            [
              -76.94987125204939,
              27.56430948794184
            ],
            [
              -73.34987380232134,
              26.159307970773796
            ],
            [
              -69.2998766713773,
              24.12261698700334
            ],
            [
              -67.27487810590533,
              22.05298561667763
            ],
            [
              -66.14987890286532,
              20.375041253465525
            ],
            [
              -66.26237882316926,
              19.104405475930548
            ],
            [
              -66.10666893347562,
              18.466104232947515
            ]
          ],
          [
            [
              -66.10666893347562,
              18.466104232947515
            ],
            [
              -65.92482906229267,
              18.67864702215462
            ],
            [
              -65.69987922164925,
              18.731925895976644
            ],
            [
              -65.4748793810413,
              18.57203905256688
            ],
            [
              -65.24987954043327,
              18.251816319028308
            ],
            [
              -64.81662984735141,
              17.773909269375793
            ],
            [
              -64.57488001860925,
              17.931002277731153
            ],
            [
              -63.449880815569244,
              17.180187287481402
            ],
            [
              -62.099881771921204,
              15.94013010690935
            ],
            [
              -61.874881931313254,
              15.831926792594288
            ],
            [
              -61.60779212052215,
              15.73035531673716
            ],
            [
              -61.25613236964119,
              15.669513225155328
            ],
            [
              -60.74988272827325,
              15.23578178303569
            ],
            [
              -60.74988272827325,
              15.018578573757566
            ],
            [
              -59.849883365841194,
              14.365653759228536
            ],
            [
              -58.049884640977254,
              13.054150695298716
            ]
          ],
          [
            [
              -61.094172484375164,
              14.615455776713844
            ],
            [
              -61.199882409489234,
              14.801154224791475
            ],
            [
              -61.199882409489234,
              14.9098938602896
            ],
            [
              -60.74988272827325,
              15.018578573757566
            ]
          ],
          [
            [
              -52.32092869942332,
              4.941547448310331
            ],
            [
              -51.29988942273715,
              7.744889052551343
            ]
          ],
          [
            [
              -68.89264695986272,
              12.090439618305055
            ],
            [
              -68.39987730894534,
              11.845776373625682
            ],
            [
              -65.69987922164925,
              11.294709319565555
            ],
            [
              -64.79987985921728,
              12.17588718550806
            ],
            [
              -62.99988113435326,
              13.054150695298716
            ],
            [
              -61.199882409489234,
              13.492128176464178
            ],
            [
              -60.299883047057264,
              13.929303843271725
            ],
            [
              -59.849883365841194,
              14.365653759228536
            ]
          ],
          [
            [
              -66.87829838684605,
              10.606498174462416
            ],
            [
              -66.59987858408131,
              10.853089690745378
            ],
            [
              -65.69987922164925,
              11.294709319565555
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "jakarta-bangka-bintan-batam-singapore-b3js",
        "name": "Jakarta-Bangka-Bintan-Batam-Singapore (B3JS)",
        "color": "#1ebab0",
        "feature_id": "jakarta-bangka-bintan-batam-singapore-b3js-0",
        "coordinates": [
          105.64166169501848,
          -1.677986060248486
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              106.82782855810385,
              -6.171876390816408
            ],
            [
              106.4249988434722,
              -5.273944363641391
            ],
            [
              106.53749876377609,
              -4.601453764837203
            ],
            [
              106.53749876377609,
              -3.479268678969987
            ],
            [
              106.54745875672049,
              -3.079924874677914
            ],
            [
              106.19999900286413,
              -2.580536704984041
            ],
            [
              105.74999932164823,
              -1.906058394384871
            ],
            [
              105.58270944015786,
              -1.55387979369155
            ],
            [
              105.41249956133217,
              -1.231315750217505
            ],
            [
              105.07499980042022,
              0.118588418888407
            ],
            [
              104.84999995921609,
              0.290337139338931
            ],
            [
              104.17500043739219,
              0.398835084041281
            ],
            [
              103.66875079602411,
              0.793562652607278
            ],
            [
              103.66875079602411,
              1.01853421661562
            ],
            [
              103.87813064769747,
              1.190951974272878
            ],
            [
              103.96260058785819,
              1.134723598626692
            ],
            [
              103.97815057624663,
              1.185378176915862
            ],
            [
              103.94648059927786,
              1.327258925921086
            ],
            [
              104.0113305533375,
              1.233727365552483
            ],
            [
              104.14960045538581,
              1.184523360363414
            ],
            [
              104.34415031696889,
              1.201587148227134
            ],
            [
              104.42580025912721,
              1.136713206281769
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "seamewe-5",
        "name": "SeaMeWe-5",
        "color": "#c71f8e",
        "feature_id": "seamewe-5-0",
        "coordinates": [
          41.39347184626996,
          16.736193525917884
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              38.10697724059963,
              24.070648010417926
            ],
            [
              37.80004745803154,
              24.01990020343257
            ],
            [
              37.34051417738746,
              24.035381460187438
            ],
            [
              37.12504793620579,
              24.12261698700334
            ]
          ],
          [
            [
              43.008543768284085,
              14.68565653336259
            ],
            [
              42.75004395140763,
              14.692360031374477
            ],
            [
              42.5250441107996,
              14.692360031374477
            ],
            [
              42.35629423034351,
              14.801154224791475
            ]
          ],
          [
            [
              101.25000250948804,
              2.367912558705314
            ],
            [
              101.36255242975685,
              2.143087178471944
            ],
            [
              101.44766236946415,
              1.665522797277134
            ]
          ],
          [
            [
              99.56250370492805,
              4.613591578862867
            ],
            [
              98.67598433294691,
              3.752031394331624
            ]
          ],
          [
            [
              27.900054471279333,
              32.43331330641712
            ],
            [
              28.8000538337115,
              34.31215165223537
            ],
            [
              28.575053993103467,
              36.14986678681771
            ],
            [
              28.46255407279935,
              36.51238821239372
            ],
            [
              28.25357422084295,
              36.85525019317086
            ]
          ],
          [
            [
              16.65006244087931,
              33.93964008831958
            ],
            [
              16.425062600271445,
              34.68301765985788
            ],
            [
              16.20006275966341,
              35.78566189952613
            ],
            [
              16.087562839359293,
              36.87321951208918
            ],
            [
              15.75006307844734,
              37.32187222983512
            ],
            [
              15.067443562021538,
              37.51344748573402
            ]
          ],
          [
            [
              94.39121736831603,
              16.858032255708565
            ],
            [
              93.60000792881604,
              16.965102599435824
            ],
            [
              91.8000092039521,
              17.823934412537824
            ]
          ],
          [
            [
              102.22090182169396,
              2.273260323566632
            ],
            [
              102.15000187192001,
              1.974446286104069
            ]
          ],
          [
            [
              90.11661039648769,
              21.820818029820288
            ],
            [
              90.90000984151996,
              20.375041253465525
            ],
            [
              91.8000092039521,
              17.823934412537824
            ],
            [
              91.8000092039521,
              14.801154224791475
            ],
            [
              90.45001016030389,
              9.524411345019587
            ],
            [
              90.22501031969605,
              7.967776882259614
            ],
            [
              90.00001047908799,
              7.298762754459602
            ]
          ],
          [
            [
              67.02854675228852,
              24.889731701235718
            ],
            [
              65.25002801161182,
              24.054148269801203
            ],
            [
              63.22502944673573,
              22.469443964829594
            ]
          ],
          [
            [
              65.75491565746177,
              16.886337034233264
            ],
            [
              63.45836261477359,
              20.880771168805865
            ],
            [
              63.22502944673573,
              22.469443964829594
            ],
            [
              59.85003183761572,
              24.430271928050416
            ],
            [
              58.50003279396768,
              24.73717827217618
            ],
            [
              56.9250339097116,
              24.941363171753835
            ],
            [
              56.333724328601164,
              25.121690004958737
            ]
          ],
          [
            [
              29.700933195520264,
              31.072270031660224
            ],
            [
              29.98130299690345,
              30.417485796339378
            ],
            [
              30.375052717967577,
              29.90687391673123
            ],
            [
              31.050052239791512,
              29.540507745394393
            ],
            [
              31.725051761615447,
              29.295522763665588
            ],
            [
              32.17505144283152,
              29.197363639715576
            ],
            [
              32.65318110412005,
              29.113614162980156
            ]
          ],
          [
            [
              5.930340034831232,
              43.125291587015084
            ],
            [
              6.300069772911229,
              42.74371346443661
            ],
            [
              6.525069613519264,
              41.744358789482135
            ],
            [
              8.10006849777534,
              38.651811712711236
            ],
            [
              8.325068338383204,
              38.38775473578434
            ],
            [
              9.000067859611391,
              38.29952060596935
            ],
            [
              10.348617229769246,
              38.29952060596935
            ],
            [
              10.912566505375338,
              37.85673997565843
            ],
            [
              12.150065627527482,
              37.232354321556215
            ],
            [
              12.15006562871929,
              36.240655233214795
            ],
            [
              12.487565389631243,
              35.78566189952613
            ],
            [
              12.993815031002384,
              35.419780517080454
            ],
            [
              14.4000640347993,
              34.544136272978676
            ],
            [
              16.65006244087931,
              33.93964008831958
            ],
            [
              19.35006052817539,
              34.31215165223537
            ],
            [
              22.05005861487558,
              34.157137999942535
            ],
            [
              25.20005638398345,
              33.42476549736131
            ],
            [
              27.900054471279333,
              32.43331330641712
            ],
            [
              29.700933195520264,
              31.072270031660224
            ],
            [
              29.98130299690345,
              30.466024503890736
            ],
            [
              30.375052717967577,
              30.00434523699687
            ],
            [
              31.050052239791512,
              29.63833609362638
            ],
            [
              31.725051761615447,
              29.393587625053613
            ],
            [
              32.17505144283152,
              29.246411345890152
            ],
            [
              32.65318110412005,
              29.113614162980156
            ],
            [
              33.46910052611801,
              28.161052262220892
            ],
            [
              34.42504984891153,
              27.364667993860166
            ],
            [
              35.353174191419555,
              26.562513149236622
            ],
            [
              36.11254865347152,
              25.754704263415306
            ],
            [
              37.12504793620579,
              24.12261698700334
            ],
            [
              38.36254705955153,
              22.05298561667763
            ],
            [
              39.15004650167958,
              20.375041253465525
            ],
            [
              40.50004554532762,
              18.251816319028308
            ],
            [
              41.51254482806351,
              16.53419619825962
            ],
            [
              42.35629423034351,
              14.801154224791475
            ],
            [
              42.86254387171155,
              13.929303843271725
            ],
            [
              43.17191865254762,
              13.054150695298716
            ],
            [
              43.29848106288949,
              12.834868817846598
            ],
            [
              43.382856003117524,
              12.615395567393307
            ],
            [
              43.537543393535486,
              12.395734000022884
            ],
            [
              44.55004267627157,
              11.680570534838523
            ],
            [
              45.450042038703714,
              11.625479959569759
            ],
            [
              48.600039807215495,
              12.395734000022884
            ],
            [
              55.350035025455725,
              14.14758350694865
            ],
            [
              58.950032475183775,
              15.886035719079104
            ],
            [
              60.975031038271766,
              15.994209911785873
            ],
            [
              65.75491565746177,
              16.886337034233264
            ],
            [
              70.6500241867999,
              13.412055032890692
            ],
            [
              72.1097991585246,
              9.280734132427938
            ],
            [
              74.30490960056682,
              6.606897166243414
            ],
            [
              76.95001972382383,
              5.510071711803135
            ],
            [
              79.65001781111991,
              5.510071711803135
            ],
            [
              80.53985718074958,
              5.940820740520053
            ]
          ],
          [
            [
              80.53985718074958,
              5.940820740520053
            ],
            [
              81.00001685476795,
              5.510071711803135
            ],
            [
              82.80001557963189,
              5.510071711803135
            ],
            [
              85.50001366692797,
              6.405200795356109
            ],
            [
              90.00001047908799,
              7.298762754459602
            ],
            [
              92.7000085663839,
              6.852191098754417
            ],
            [
              94.27500745064,
              6.852191098754417
            ],
            [
              95.40000665367998,
              6.740481724921096
            ],
            [
              97.42500521915215,
              6.237476972533045
            ],
            [
              98.77500426280002,
              5.286069860821101
            ],
            [
              99.56250370492805,
              4.613591578862867
            ],
            [
              100.35000314705607,
              3.266814816815753
            ],
            [
              101.25000250948804,
              2.367912558705314
            ],
            [
              102.15000187192001,
              1.974446286104069
            ],
            [
              102.68279723997185,
              1.670619462234868
            ],
            [
              103.34065102845304,
              1.271608282704705
            ],
            [
              103.50000091556822,
              1.21527159428436
            ],
            [
              103.64609081207684,
              1.338585852071589
            ]
          ],
          [
            [
              43.16164365982672,
              11.573660387694133
            ],
            [
              43.6500433138396,
              11.707989320553992
            ],
            [
              44.55004267627157,
              11.680570534838523
            ]
          ],
          [
            [
              58.591142729424746,
              23.61510487139472
            ],
            [
              58.950032475183775,
              23.865671119262572
            ],
            [
              59.85003183761572,
              24.430271928050416
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "italy-malta",
        "name": "Italy-Malta",
        "color": "#a7732a",
        "feature_id": "italy-malta-0",
        "coordinates": [
          15.732272649578704,
          36.53052862520212
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              15.067443562021538,
              37.51344748573402
            ],
            [
              15.75006307844734,
              37.232354321556215
            ],
            [
              15.918812958903402,
              36.87321951208918
            ],
            [
              15.525063237839305,
              36.14986678681771
            ],
            [
              15.075063556623405,
              35.922435577344146
            ],
            [
              14.488053972466645,
              35.89189979559495
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "epic-malta-sicily-cable-system-emscs",
        "name": "Epic Malta-Sicily Cable System (EMSCS)",
        "color": "#48b648",
        "feature_id": "epic-malta-sicily-cable-system-emscs-0",
        "coordinates": [
          15.538157320636543,
          36.53259125431131
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              15.067443562021538,
              37.51344748573402
            ],
            [
              15.637563158143394,
              37.232354321556215
            ],
            [
              15.75006307844734,
              36.87321951208918
            ],
            [
              15.30006339723144,
              36.14986678681771
            ],
            [
              15.075063556623405,
              36.01348686719726
            ],
            [
              14.380044048981688,
              35.93406361398968
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "hannibal-system",
        "name": "HANNIBAL System",
        "color": "#5ab946",
        "feature_id": "hannibal-system-0",
        "coordinates": [
          11.869247045735108,
          37.15682288531826
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              11.090886379051994,
              36.849936993962615
            ],
            [
              11.427366140686559,
              36.849310578248605
            ],
            [
              12.173015612461455,
              37.36822050784963
            ],
            [
              12.591375316091586,
              37.650586172786205
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "didon",
        "name": "Didon",
        "color": "#37b44d",
        "feature_id": "didon-0",
        "coordinates": [
          11.846552497834153,
          37.230660817970914
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              11.090886379051994,
              36.849936993962615
            ],
            [
              11.427366140686559,
              36.939295480359135
            ],
            [
              12.173015612461455,
              37.45757668483847
            ],
            [
              12.591375316091586,
              37.650586172786205
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "mednautilus-submarine-system",
        "name": "MedNautilus Submarine System",
        "color": "#53b847",
        "feature_id": "mednautilus-submarine-system-0",
        "coordinates": [
          25.344772701433158,
          34.44049274661012
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              28.9882337004031,
              41.04061756347724
            ],
            [
              28.283554199604737,
              40.74451752713381
            ],
            [
              27.67066463378157,
              40.571463184887904
            ],
            [
              27.20435496411966,
              40.51728976261825
            ],
            [
              26.755425282145524,
              40.45564648003207
            ],
            [
              26.644005361076495,
              40.375490343336395
            ],
            [
              26.592925397262036,
              40.31736166816421
            ],
            [
              26.516555451363246,
              40.27677072796641
            ],
            [
              26.447805500066295,
              40.22370340243219
            ],
            [
              26.37222555360802,
              40.19439284484727
            ],
            [
              26.376225550774166,
              40.146787754907734
            ],
            [
              26.344195573464617,
              40.10459536589312
            ],
            [
              26.246055642987784,
              40.055649089877534
            ],
            [
              25.31255630428737,
              39.524987333511575
            ],
            [
              25.31255630428737,
              38.651811712711236
            ],
            [
              25.65005606519935,
              37.41128363492314
            ],
            [
              25.20005638398345,
              37.232354321556215
            ],
            [
              24.637556782463435,
              37.27712658287683
            ],
            [
              24.075057180943446,
              37.232354321556215
            ],
            [
              23.96255726063933,
              37.58978657360316
            ],
            [
              23.73618742100203,
              37.9760779757319
            ]
          ],
          [
            [
              25.875055905807386,
              36.60275474032986
            ],
            [
              25.425056224591486,
              36.60275474032986
            ],
            [
              24.975056543375416,
              36.33133835588808
            ],
            [
              24.525056862159346,
              36.33133835588808
            ],
            [
              24.187557101843453,
              36.51238821239372
            ],
            [
              23.85005734033541,
              37.58978657360316
            ],
            [
              23.73618742100203,
              37.9760779757319
            ]
          ],
          [
            [
              28.9882337004031,
              41.04061756347724
            ],
            [
              28.305814183835423,
              40.72743084616869
            ],
            [
              27.6788646279727,
              40.552318658169696
            ],
            [
              27.18003498134803,
              40.49568113006444
            ],
            [
              26.75493528249268,
              40.43735937424697
            ],
            [
              26.677105337628177,
              40.37980969563341
            ],
            [
              26.60562538826511,
              40.30783009842784
            ],
            [
              26.53169544063789,
              40.26268557434719
            ],
            [
              26.48169547605832,
              40.221389871891915
            ],
            [
              26.38862554198991,
              40.191704009093456
            ],
            [
              26.390775540466933,
              40.132453843941306
            ],
            [
              26.348195570630935,
              40.08674097664061
            ],
            [
              26.260015633098476,
              40.04234480534558
            ],
            [
              26.098195747733115,
              39.97772318814779
            ],
            [
              25.425056224591486,
              39.524987333511575
            ],
            [
              25.425056224591486,
              38.651811712711236
            ],
            [
              25.875055905807386,
              37.41128363492314
            ],
            [
              26.21255566671934,
              37.232354321556215
            ],
            [
              26.325055587023456,
              36.87321951208918
            ],
            [
              25.875055905807386,
              36.60275474032986
            ],
            [
              25.93362449116799,
              35.83658635443777
            ]
          ],
          [
            [
              34.20005000830349,
              33.09551711711572
            ],
            [
              33.75005032708759,
              33.565491482352144
            ],
            [
              33.75005032708759,
              34.49779087043359
            ],
            [
              33.61060042587516,
              34.82728147271527
            ]
          ],
          [
            [
              24.01216722549529,
              35.51204255863749
            ],
            [
              23.96255726063933,
              35.78566189952613
            ],
            [
              23.850057339143603,
              36.51238821239372
            ],
            [
              23.737557420031465,
              37.58978657360316
            ],
            [
              23.73618742100203,
              37.9760779757319
            ]
          ],
          [
            [
              15.067443562021538,
              37.51344748573402
            ],
            [
              15.75006307844734,
              37.500588446053314
            ],
            [
              18.00006148452735,
              36.51238821239372
            ],
            [
              19.342345691678787,
              36.1495513657909
            ],
            [
              22.05005861487558,
              35.40449918351331
            ],
            [
              23.400057658523423,
              34.89859296336232
            ],
            [
              25.20005638398345,
              34.474608501824434
            ],
            [
              31.050052239791512,
              33.09551711711572
            ],
            [
              33.75005032708759,
              32.33831157801282
            ],
            [
              34.769679604772676,
              32.04501185826473
            ],
            [
              34.537549768619556,
              32.24321001626265
            ],
            [
              34.650049688923644,
              32.62301664000799
            ],
            [
              34.9719010000002,
              32.761700000000275
            ]
          ],
          [
            [
              34.9719010000002,
              32.761700000000275
            ],
            [
              34.20005000830349,
              33.09551711711572
            ],
            [
              31.050052239791512,
              33.565491482352144
            ],
            [
              28.8000538337115,
              34.31215165223537
            ],
            [
              26.574796150245902,
              35.455341306260806
            ],
            [
              25.93362449116799,
              35.83658635443777
            ],
            [
              25.38628283097998,
              35.785858781086006
            ],
            [
              24.01216722549529,
              35.51204255863749
            ],
            [
              23.737557420031465,
              35.83127933955626
            ],
            [
              22.95005797790344,
              35.96797434759347
            ],
            [
              18.00006148452735,
              36.87321951208918
            ],
            [
              15.75006307844734,
              37.58978657360316
            ],
            [
              15.067443562021538,
              37.51344748573402
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "channel-islands-9-liberty-submarine-cable",
        "name": "Channel Islands-9 Liberty Submarine Cable",
        "color": "#3a499e",
        "feature_id": "channel-islands-9-liberty-submarine-cable-0",
        "coordinates": [
          -2.8686519662639682,
          50.119156425037886
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -3.610863206090812,
              50.31911737342912
            ],
            [
              -3.149923532624712,
              50.23926850952162
            ],
            [
              -2.81242377171273,
              50.09514516168238
            ],
            [
              -2.474924010800777,
              49.58728674004675
            ],
            [
              -2.53337396939429,
              49.503391023483346
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "circe-south",
        "name": "Circe South",
        "color": "#cb218e",
        "feature_id": "circe-south-0",
        "coordinates": [
          0.9178745154388253,
          50.47986575279057
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              0.366673976184586,
              50.81931962098224
            ],
            [
              0.450073917103168,
              50.740281893948264
            ],
            [
              1.350073279535309,
              50.23926850952162
            ],
            [
              1.493433177977636,
              50.17917387330175
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "uk-channel-islands-7",
        "name": "UK-Channel Islands-7",
        "color": "#923e97",
        "feature_id": "uk-channel-islands-7-0",
        "coordinates": [
          -2.756140759980269,
          50.191231418220184
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -2.53337396939429,
              49.503391023483346
            ],
            [
              -2.418674050648718,
              49.58728674004675
            ],
            [
              -2.699923851408727,
              50.16726116292705
            ],
            [
              -3.03742361232068,
              50.31116725161084
            ],
            [
              -3.578393229092768,
              50.35174683018397
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "pan-european-crossing-uk-belgium",
        "name": "Pan European Crossing (UK-Belgium)",
        "color": "#60b446",
        "feature_id": "pan-european-crossing-uk-belgium-0",
        "coordinates": [
          2.2013985551098756,
          51.30637567738265
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              1.440993215126866,
              51.35857144425913
            ],
            [
              1.800072960751208,
              51.30637567738265
            ],
            [
              2.475072482575314,
              51.30637567738265
            ],
            [
              2.96189213770765,
              51.24665953842634
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "concerto",
        "name": "Concerto",
        "color": "#33c0cc",
        "feature_id": "concerto-0",
        "coordinates": [
          2.097545458355313,
          52.211991368836664
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              4.524191030960452,
              52.3636663818082
            ],
            [
              4.520747594069206,
              52.363306844766385
            ],
            [
              4.516013673110507,
              52.3628251277558
            ],
            [
              4.510043038254423,
              52.36222631775303
            ],
            [
              4.502889459669916,
              52.36151550105225
            ],
            [
              4.494606707526515,
              52.36069776339659
            ],
            [
              4.485248551993919,
              52.35977819010574
            ],
            [
              4.47486876324146,
              52.35876186619436
            ],
            [
              4.463521111438808,
              52.35765387648755
            ],
            [
              4.451259366754954,
              52.35645930572929
            ],
            [
              4.438137299359596,
              52.3551832386845
            ],
            [
              4.424208679422605,
              52.35383076023629
            ],
            [
              4.409527277112772,
              52.35240695547685
            ],
            [
              4.394146862600167,
              52.350916909792545
            ],
            [
              4.37812120605372,
              52.34936570894263
            ],
            [
              4.36150407764319,
              52.34775843913329
            ],
            [
              4.344349247538048,
              52.34610018708452
            ],
            [
              4.326710485907626,
              52.34439604009199
            ],
            [
              4.30864156292165,
              52.342651086082896
            ],
            [
              4.290196248749226,
              52.34087041366564
            ],
            [
              4.271428313560278,
              52.33905911217391
            ],
            [
              4.25239152752377,
              52.3372222717048
            ],
            [
              4.233139660809371,
              52.33536498315135
            ],
            [
              4.213726483586782,
              52.333492338228496
            ],
            [
              4.194205766024993,
              52.331609429493994
            ],
            [
              4.174631278293873,
              52.32972135036334
            ],
            [
              4.155056790562725,
              52.327833195118046
            ],
            [
              4.135536073001134,
              52.32595005890886
            ],
            [
              4.116122895778375,
              52.32407703775311
            ],
            [
              4.096871029063976,
              52.322219228525114
            ],
            [
              4.07783424302761,
              52.32038172894295
            ],
            [
              4.059066307838492,
              52.318569637546744
            ],
            [
              4.040620993666096,
              52.316788053672184
            ],
            [
              4.022552070680092,
              52.31504207741972
            ],
            [
              4.004913309049698,
              52.31333680961447
            ],
            [
              3.987758478944727,
              52.31167735176419
            ],
            [
              3.971141350534168,
              52.310068806007536
            ],
            [
              3.95511569398758,
              52.30851627506007
            ],
            [
              3.939735279474945,
              52.30702486215105
            ],
            [
              3.925053877165311,
              52.305599670957434
            ],
            [
              3.91112525722815,
              52.304245805529035
            ],
            [
              3.898003189832963,
              52.30296837021086
            ],
            [
              3.885741445149108,
              52.301772469556425
            ],
            [
              3.874393793346286,
              52.300663208238035
            ],
            [
              3.864014004593798,
              52.299645690949205
            ],
            [
              3.854655849061203,
              52.29872502230219
            ],
            [
              3.846373096918001,
              52.29790630671937
            ],
            [
              3.839219518333493,
              52.297194648318396
            ],
            [
              3.833248883477239,
              52.29659515079291
            ],
            [
              3.825071525627266,
              52.29575305025401
            ],
            [
              3.816071532002979,
              52.29422399049014
            ],
            [
              3.807071538378693,
              52.29269488653958
            ],
            [
              3.798071544754407,
              52.291165738043816
            ],
            [
              3.78907155112995,
              52.28963654464468
            ],
            [
              3.780071557505664,
              52.28810730598386
            ],
            [
              3.771071563881378,
              52.28657802170295
            ],
            [
              3.762071570256921,
              52.28504869144348
            ],
            [
              3.753071576632806,
              52.28351931484693
            ],
            [
              3.74407158300852,
              52.28198989155493
            ],
            [
              3.735071589384233,
              52.2804604212088
            ],
            [
              3.726071595759777,
              52.278930903450004
            ],
            [
              3.717071602135491,
              52.27740133791983
            ],
            [
              3.708071608511204,
              52.27587172425939
            ],
            [
              3.699071614886918,
              52.27434206211021
            ],
            [
              3.690071621262462,
              52.272812351113146
            ],
            [
              3.681071627638175,
              52.27128259090955
            ],
            [
              3.672071634013889,
              52.26975278114034
            ],
            [
              3.663071640389603,
              52.26822292144681
            ],
            [
              3.654071646765317,
              52.26669301146967
            ],
            [
              3.645071653141031,
              52.265163050849935
            ],
            [
              3.636071659516745,
              52.263633039228466
            ],
            [
              3.627071665892288,
              52.26210297624621
            ],
            [
              3.618071672268002,
              52.26057286154395
            ],
            [
              3.609071678643716,
              52.2590426947622
            ],
            [
              3.600071685019429,
              52.257512475541944
            ],
            [
              3.591071691394944,
              52.25598220352356
            ],
            [
              3.582071697770658,
              52.25445187834774
            ],
            [
              3.573071704146372,
              52.252921499654946
            ],
            [
              3.564071710521915,
              52.251391067085876
            ],
            [
              3.555071716897629,
              52.24986058028057
            ],
            [
              3.546071723273343,
              52.2483300388798
            ],
            [
              3.537071729649057,
              52.24679944252392
            ],
            [
              3.528071736024771,
              52.24526879085295
            ],
            [
              3.519071742400484,
              52.24373808350711
            ],
            [
              3.510071748776198,
              52.242207320126965
            ],
            [
              3.501071755151912,
              52.24067650035218
            ],
            [
              3.492071761527455,
              52.239145623823305
            ],
            [
              3.483071767903169,
              52.23761469018004
            ],
            [
              3.474071774278883,
              52.23608369906253
            ],
            [
              3.465071780654426,
              52.23455265011063
            ],
            [
              3.45607178703014,
              52.233021542964394
            ],
            [
              3.447071793405854,
              52.23149037726347
            ],
            [
              3.438071799781738,
              52.229959152647865
            ],
            [
              3.429071806157282,
              52.22842786875705
            ],
            [
              3.420071812532996,
              52.2268965252309
            ],
            [
              3.411071818908709,
              52.22536512170922
            ],
            [
              3.402071825284423,
              52.22383365783131
            ],
            [
              3.393071831659967,
              52.22230213323684
            ],
            [
              3.375071844411394,
              52.219238900455935
            ],
            [
              2.025072800763354,
              52.2115802243913
            ],
            [
              1.620287931266688,
              52.207211794377145
            ],
            [
              1.617138519435912,
              52.195458286472814
            ],
            [
              1.613256686247809,
              52.180932313611464
            ],
            [
              2.025072800763354,
              52.14259270367222
            ],
            [
              3.150072003803359,
              51.586833980054195
            ],
            [
              3.206907901040211,
              51.33094567001683
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "farland-north",
        "name": "Farland North",
        "color": "#52bb77",
        "feature_id": "farland-north-0",
        "coordinates": [
          2.5254728496129815,
          51.77218715330889
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              1.590603109141597,
              52.157840488057786
            ],
            [
              2.025072801359244,
              52.00429650272403
            ],
            [
              2.925072163791214,
              51.586833980054195
            ],
            [
              3.496161759226112,
              51.56395094476625
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "circe-north",
        "name": "Circe North",
        "color": "#3cb54e",
        "feature_id": "circe-north-0",
        "coordinates": [
          3.1268354377585013,
          52.4179012603154
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              1.729273010906667,
              52.46882263773057
            ],
            [
              2.250072641967279,
              52.4179012603154
            ],
            [
              3.825071526223184,
              52.4179012603154
            ],
            [
              4.524191030960452,
              52.3636663818082
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "e-llan",
        "name": "E-LLAN",
        "color": "#44b549",
        "feature_id": "e-llan-0",
        "coordinates": [
          -3.7714668107178966,
          53.95209894866878
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -4.480882589760824,
              54.15047817510964
            ],
            [
              -4.274922735664717,
              54.100057482410676
            ],
            [
              -3.599923213840782,
              53.901684726074365
            ],
            [
              -3.050753602877677,
              53.80897597127557
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "lanis-3",
        "name": "Lanis-3",
        "color": "#2d8cb2",
        "feature_id": "lanis-3-0",
        "coordinates": [
          -5.219973632599675,
          55.20510575766687
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -4.659932462920153,
              55.54133175647158
            ],
            [
              -4.949922257488737,
              55.462350188098384
            ],
            [
              -5.624921779312757,
              54.81936191424907
            ],
            [
              -5.71872171286401,
              54.75370176047079
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "scotland-northern-ireland-2",
        "name": "Scotland-Northern Ireland 2",
        "color": "#48b648",
        "feature_id": "scotland-northern-ireland-2-0",
        "coordinates": [
          -5.362699194716868,
          55.125840868454134
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -4.854172325318928,
              55.240403607445785
            ],
            [
              -5.174922098096772,
              55.20639768894037
            ],
            [
              -5.624921779312757,
              55.0133467567922
            ],
            [
              -5.809831648320881,
              54.85780200253134
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "finland-estonia-connection-fec",
        "name": "Finland Estonia Connection (FEC)",
        "color": "#57a344",
        "feature_id": "finland-estonia-connection-fec-0",
        "coordinates": [
          24.758527110884444,
          59.437734752712885
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              24.93247657353959,
              60.171163188940454
            ],
            [
              24.975056543375416,
              60.07486799642308
            ],
            [
              24.975056543375416,
              59.962431634152296
            ],
            [
              24.86255662307147,
              59.6796637072089
            ],
            [
              24.752496701038922,
              59.42371055209489
            ],
            [
              24.975056543375416,
              59.6796637072089
            ],
            [
              25.087556463679334,
              59.962431634152296
            ],
            [
              25.03130650352736,
              60.07486799642308
            ],
            [
              24.93247657353959,
              60.171163188940454
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "finland-estonia-3-eesf-3",
        "name": "Finland-Estonia 3 (EESF-3)",
        "color": "#41b769",
        "feature_id": "finland-estonia-3-eesf-3-0",
        "coordinates": [
          24.568446825338384,
          59.81027316027874
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              24.276617038156644,
              59.40095452286678
            ],
            [
              24.4125569418554,
              59.6796637072089
            ],
            [
              24.75005670276738,
              59.962431634152296
            ],
            [
              24.93247657353959,
              60.171163188940454
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "bcs-east",
        "name": "BCS East",
        "color": "#bc6f29",
        "feature_id": "bcs-east-0",
        "coordinates": [
          20.81255949212735,
          56.24404101569351
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              21.011189351416164,
              56.508369140884156
            ],
            [
              20.81255949212735,
              56.407518054313044
            ],
            [
              20.81255949212735,
              56.15772578558838
            ],
            [
              21.082679300772185,
              56.02850685047366
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "batam-dumai-melaka-bdm-cable-system",
        "name": "Batam Dumai Melaka (BDM) Cable System",
        "color": "#3cbba0",
        "feature_id": "batam-dumai-melaka-bdm-cable-system-0",
        "coordinates": [
          102.7178772742557,
          1.4852525616055914
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              101.44766236946415,
              1.665522797277134
            ],
            [
              101.7281271707802,
              1.777527123428371
            ],
            [
              101.92500203131218,
              1.918228780215685
            ],
            [
              102.22090182169396,
              2.273260323566632
            ]
          ],
          [
            [
              103.96260058785819,
              1.134723598626692
            ],
            [
              103.50000091556822,
              1.173168272269322
            ],
            [
              103.34065102845304,
              1.187327757538839
            ],
            [
              102.68279723997185,
              1.502034277710749
            ],
            [
              102.15000187192001,
              1.693340822791811
            ],
            [
              101.7281271707802,
              1.72140333830734
            ],
            [
              101.44766236946415,
              1.665522797277134
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "pgascom",
        "name": "PGASCOM",
        "color": "#7c9e3e",
        "feature_id": "pgascom-0",
        "coordinates": [
          103.84975898407251,
          0.22416442959573146
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              103.70596076966436,
              1.259306292565526
            ],
            [
              103.86557565659174,
              1.185378176915862
            ],
            [
              103.96260058785819,
              1.134723598626692
            ]
          ],
          [
            [
              103.46670093915824,
              -0.816543192375462
            ],
            [
              103.89375063663218,
              -0.331409329660175
            ],
            [
              103.89375063663218,
              0.118588418888407
            ],
            [
              103.61250083587217,
              0.793562652607278
            ],
            [
              103.61250083587217,
              1.01853421661562
            ],
            [
              103.96260058785819,
              1.134723598626692
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "boracay-palawan-submarine-cable-system-bpscs",
        "name": "Boracay-Palawan Submarine Cable System (BPSCS)",
        "color": "#2b51a3",
        "feature_id": "boracay-palawan-submarine-cable-system-bpscs-0",
        "coordinates": [
          120.40542620577972,
          11.407132406675657
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              121.94481206784073,
              11.949266020482952
            ],
            [
              121.93593035478696,
              11.937514333212079
            ],
            [
              121.95077784426886,
              11.93092980247377
            ]
          ],
          [
            [
              120.20076908458739,
              12.005434247136094
            ],
            [
              120.37498896116833,
              11.845776373625682
            ],
            [
              120.59998880177616,
              11.735650161405744
            ]
          ],
          [
            [
              119.50037958074992,
              10.820000490489491
            ],
            [
              120.14998912056026,
              10.975828448783346
            ],
            [
              120.59998880177616,
              11.735650161405744
            ],
            [
              120.8249886423844,
              12.17588718550806
            ],
            [
              121.0501884828507,
              12.363012914770593
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "saudi-arabia-sudan-1-sas-1",
        "name": "Saudi Arabia-Sudan-1 (SAS-1)",
        "color": "#68bc45",
        "feature_id": "saudi-arabia-sudan-1-sas-1-0",
        "coordinates": [
          38.09834760314976,
          20.654265086135553
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              37.21967786917094,
              19.615566594546237
            ],
            [
              37.589237574003334,
              20.130435865140754
            ],
            [
              38.100047245508904,
              20.656013867895894
            ],
            [
              39.182756478507656,
              21.481533475503085
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "falcon",
        "name": "FALCON",
        "color": "#c62026",
        "feature_id": "falcon-0",
        "coordinates": [
          57.703018161594315,
          24.388577673430234
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              43.008543768284085,
              14.68565653336259
            ],
            [
              42.75004395140763,
              14.583511645118676
            ],
            [
              42.5250441107996,
              14.583511645118676
            ],
            [
              42.13129438973547,
              14.801154224791475
            ]
          ],
          [
            [
              48.531779855571585,
              29.92363278689706
            ],
            [
              48.71253972751961,
              29.540507745394393
            ],
            [
              48.600039807215495,
              29.1482487910327
            ]
          ],
          [
            [
              48.531779855571585,
              29.92363278689706
            ],
            [
              48.600039807215495,
              29.540507745394393
            ],
            [
              48.48753988691158,
              29.246454972180487
            ]
          ],
          [
            [
              60.6273712869413,
              25.25866457904604
            ],
            [
              59.85003183761572,
              24.53265756616244
            ]
          ],
          [
            [
              52.18245726939753,
              16.21300386243118
            ],
            [
              52.650036938159616,
              15.669513225155328
            ],
            [
              52.650036938159616,
              15.23578178303569
            ]
          ],
          [
            [
              75.545132716138,
              6.628746603597904
            ],
            [
              79.20001812990384,
              6.628746603597904
            ],
            [
              79.87208765380373,
              6.927036656836265
            ]
          ],
          [
            [
              76.97022970950692,
              8.798160747261278
            ],
            [
              76.50002004260776,
              8.190543417795567
            ],
            [
              75.545132716138,
              6.628746603597904
            ],
            [
              74.25002163652775,
              5.398081130463737
            ],
            [
              73.50002216783449,
              4.166668198861885
            ]
          ],
          [
            [
              72.87590260996691,
              19.076074257285313
            ],
            [
              70.2000245055838,
              19.74098736552503
            ],
            [
              66.60002705585575,
              20.375041253465525
            ],
            [
              63.450029287343796,
              22.469443964829594
            ],
            [
              59.85003183761572,
              24.53265756616244
            ],
            [
              58.50003279396768,
              23.96851099673472
            ],
            [
              58.17620302337187,
              23.684877531684634
            ],
            [
              57.881283232295715,
              24.12261698700334
            ],
            [
              57.37503359092767,
              25.348717422116806
            ],
            [
              57.150033750319835,
              26.159307970773796
            ],
            [
              56.981283869863745,
              26.562513149236622
            ],
            [
              56.74300403866354,
              26.973181618689154
            ],
            [
              56.27415437080103,
              27.187252945938212
            ],
            [
              56.250034387887666,
              26.813799487940685
            ],
            [
              55.800034706671596,
              26.41147606086841
            ],
            [
              55.350035025455725,
              26.361086325391653
            ],
            [
              53.550036300591586,
              26.361086325391653
            ],
            [
              52.87503677876768,
              26.562513149236622
            ],
            [
              52.200037256943745,
              26.964304734562802
            ],
            [
              51.52503773511961,
              27.364667993860166
            ],
            [
              50.17503869147157,
              27.962503359972544
            ],
            [
              49.16253940873568,
              28.65581241773313
            ],
            [
              48.600039807215495,
              29.1482487910327
            ],
            [
              48.48753988691158,
              29.246454972180487
            ],
            [
              47.97484025011303,
              29.37410420420028
            ],
            [
              48.600039807215495,
              28.853067255226364
            ],
            [
              49.16253940873568,
              28.458185766004647
            ],
            [
              50.17503869147157,
              26.964304734562802
            ],
            [
              50.17503869147157,
              26.461843796189072
            ],
            [
              50.21419866373054,
              26.285375359318067
            ],
            [
              50.34378857192766,
              26.461843796189072
            ],
            [
              50.51253845238372,
              26.461843796189072
            ],
            [
              50.576018407413954,
              26.22949483839116
            ],
            [
              51.18753797420766,
              26.361086325391653
            ],
            [
              51.63753765542373,
              26.26024097157773
            ],
            [
              51.750037575727674,
              26.058287560298936
            ],
            [
              51.51927773920005,
              25.294608758024538
            ],
            [
              52.200037256943745,
              25.754704263415306
            ],
            [
              53.550036300591586,
              25.8559854660721
            ],
            [
              55.12503518484766,
              25.8559854660721
            ],
            [
              55.12503518484766,
              25.55188275942578
            ],
            [
              55.308535054854815,
              25.26935399813027
            ],
            [
              55.237585105116494,
              25.55188275942578
            ],
            [
              55.350035025455725,
              25.754704263415306
            ],
            [
              55.800034706671596,
              26.108808676862424
            ],
            [
              56.24681439016874,
              26.18130536278207
            ],
            [
              56.36253430819161,
              26.461843796189072
            ],
            [
              56.58753414879965,
              26.461843796189072
            ],
            [
              56.75628402925571,
              26.159307970773796
            ],
            [
              57.26253367062375,
              25.348717422116806
            ],
            [
              57.82503327214377,
              24.12261698700334
            ],
            [
              58.17620302337187,
              23.684877531684634
            ],
            [
              58.50003279396768,
              23.814220515025227
            ],
            [
              58.950032475183775,
              23.659746441192254
            ],
            [
              59.85003183761572,
              22.884654113882362
            ],
            [
              60.07503167703197,
              22.469443964829594
            ],
            [
              59.85003183761572,
              19.104405475930548
            ],
            [
              58.950032475183775,
              18.03800543960884
            ],
            [
              55.350035025455725,
              16.31838002635962
            ],
            [
              52.650036938159616,
              15.23578178303569
            ],
            [
              48.600039807215495,
              13.492128176464178
            ],
            [
              45.450042038703714,
              12.725155923562937
            ],
            [
              44.55004267627157,
              12.230866087669114
            ],
            [
              43.67816829391566,
              12.395734000022884
            ],
            [
              43.32660604296558,
              12.615395567393307
            ],
            [
              43.24223110273755,
              12.834868817846598
            ],
            [
              43.05941873224353,
              13.054150695298716
            ],
            [
              42.637544031103516,
              13.929303843271725
            ],
            [
              42.13129438973547,
              14.801154224791475
            ],
            [
              41.06254514684872,
              16.53419619825962
            ],
            [
              40.05004586411155,
              18.251816319028308
            ],
            [
              38.70004682046351,
              20.375041253465525
            ],
            [
              37.91254737833546,
              22.05298561667763
            ],
            [
              36.67504825499063,
              24.12261698700334
            ],
            [
              35.66254897225545,
              25.754704263415306
            ],
            [
              35.0156744305074,
              26.562513149236622
            ],
            [
              34.20005000830349,
              27.364667993860166
            ],
            [
              33.49725050617553,
              28.161052262220892
            ],
            [
              32.99067586503543,
              28.951554732193216
            ],
            [
              32.70942606427545,
              29.34456698948989
            ],
            [
              32.54067268382204,
              29.63833609362638
            ],
            [
              32.540681183815934,
              29.974234637029554
            ]
          ],
          [
            [
              39.182756478507656,
              21.481533475503085
            ],
            [
              38.70004682046351,
              20.375041253465525
            ],
            [
              37.589237574003334,
              19.81323778521059
            ],
            [
              37.21967786917094,
              19.615566594546237
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "seamewe-3",
        "name": "SeaMeWe-3",
        "color": "#6fbc44",
        "feature_id": "seamewe-3-0",
        "coordinates": [
          77.60108302525074,
          6.02593702048415
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              106.83339855415792,
              -6.128964849210604
            ],
            [
              106.31249892316825,
              -5.385957847172989
            ]
          ],
          [
            [
              32.542821182299974,
              29.95909144105582
            ],
            [
              32.287551363135435,
              30.36900921360788
            ],
            [
              31.950051602223482,
              30.68393417557087
            ],
            [
              31.387552000703465,
              30.901353185916747
            ],
            [
              30.825052399183477,
              31.046082721692102
            ],
            [
              29.893513059094772,
              31.191465077638554
            ],
            [
              28.8000538337115,
              32.81231878328768
            ],
            [
              25.20005638398345,
              34.2656775265242
            ],
            [
              23.400057658523423,
              34.62132128520639
            ],
            [
              22.05005861487558,
              34.99080971857586
            ],
            [
              19.35006052817539,
              35.419780517080454
            ],
            [
              16.65006244087931,
              35.05222991093683
            ],
            [
              14.4000640347993,
              35.37392782452352
            ],
            [
              13.33131479191124,
              35.96797434759347
            ],
            [
              12.712565230239278,
              36.87321951208918
            ],
            [
              12.60006530993536,
              37.232354321556215
            ],
            [
              12.591375316091586,
              37.650586172786205
            ],
            [
              11.70006594750339,
              37.67887792909195
            ],
            [
              10.348617229769246,
              38.034173900641946
            ],
            [
              9.000067859611391,
              38.034173900641946
            ],
            [
              5.400070410479259,
              37.94551049545976
            ],
            [
              2.250072641967279,
              37.58978657360316
            ],
            [
              -2.249924170192742,
              35.96797434759347
            ],
            [
              -4.499922576272752,
              35.876870570092734
            ],
            [
              -5.624921779312757,
              35.967933880341064
            ],
            [
              -6.299921301136777,
              36.05897312258671
            ],
            [
              -8.999919388432772,
              36.240655233214795
            ],
            [
              -9.449919069648843,
              36.69301553274448
            ],
            [
              -9.449919069648843,
              37.232354321556215
            ],
            [
              -9.112419308736776,
              38.12273010839229
            ],
            [
              -9.102749315587062,
              38.443079483141986
            ]
          ],
          [
            [
              114.74999294596827,
              21.21639789994191
            ],
            [
              114.41249318505615,
              21.635297384859456
            ],
            [
              114.183973346942,
              22.24961578335288
            ]
          ],
          [
            [
              100.0661133481664,
              6.613518860854185
            ],
            [
              99.00000410340803,
              6.181557032537114
            ],
            [
              97.42500521915215,
              6.069699469735895
            ]
          ],
          [
            [
              125.99998497636832,
              29.540507745394393
            ],
            [
              125.54998529515225,
              30.126049846722907
            ],
            [
              124.64998593272028,
              30.70813999354155
            ],
            [
              122.84998720785634,
              31.094262827639596
            ],
            [
              122.17498768603221,
              31.238656526340208
            ],
            [
              121.47258699999978,
              31.247573900928387
            ]
          ],
          [
            [
              -9.102749315587062,
              38.443079483141986
            ],
            [
              -10.799918113296798,
              38.82731109526628
            ],
            [
              -11.699917475728853,
              39.69832335493328
            ],
            [
              -11.699917475728853,
              43.40114497315386
            ],
            [
              -8.099920026000802,
              46.89076287862241
            ],
            [
              -5.849921619920792,
              47.72979657245895
            ],
            [
              -4.338542690595716,
              47.81102015174923
            ],
            [
              -5.174922098096772,
              47.95631365986799
            ],
            [
              -5.399921938704722,
              48.40638249553794
            ],
            [
              -4.949922257488737,
              49.441203723128126
            ],
            [
              -5.17297209947813,
              50.02254139117184
            ],
            [
              -4.499922576272752,
              49.950586997728095
            ],
            [
              -3.599923213840782,
              50.02292045625484
            ],
            [
              -2.249924170192742,
              50.16726116292705
            ],
            [
              0.000074235887269,
              50.31116725161084
            ],
            [
              0.900073598319238,
              50.669033691984
            ],
            [
              1.348973280314624,
              50.981023970649176
            ],
            [
              1.800072960751208,
              51.09490046148102
            ],
            [
              2.475072482575314,
              51.165500079092226
            ],
            [
              2.912772172504702,
              51.231220564626796
            ]
          ],
          [
            [
              58.591142729424746,
              23.61510487139472
            ],
            [
              58.950032475183775,
              23.762749511610537
            ],
            [
              59.85003183761572,
              23.608214441359365
            ]
          ],
          [
            [
              32.46665123625965,
              34.76657169708608
            ],
            [
              31.500051920411693,
              34.34312011914997
            ],
            [
              28.8000538337115,
              32.81231878328768
            ],
            [
              29.025053674319537,
              34.31215165223537
            ],
            [
              28.687553913407385,
              36.14986678681771
            ],
            [
              28.575053993103467,
              36.51238821239372
            ],
            [
              28.25357422084295,
              36.85525019317086
            ]
          ],
          [
            [
              -5.391811944449927,
              35.565918934421404
            ],
            [
              -4.724922416880787,
              35.694348446523605
            ],
            [
              -4.499922576272752,
              35.876870570092734
            ]
          ],
          [
            [
              23.400057658523423,
              34.62132128520639
            ],
            [
              23.40005765911934,
              35.419780517080454
            ],
            [
              23.51255757942343,
              35.694348446523605
            ],
            [
              23.737557420031465,
              35.694348446523605
            ],
            [
              24.01216722549529,
              35.51204255863749
            ]
          ],
          [
            [
              56.333724328601164,
              25.121690004958737
            ],
            [
              56.9250339097116,
              24.81378707171703
            ],
            [
              58.50003279396768,
              24.276537592931618
            ],
            [
              59.85003183761572,
              23.608214441359365
            ],
            [
              61.42503072187162,
              22.469443964829594
            ],
            [
              61.42503072187162,
              17.823934412537824
            ]
          ],
          [
            [
              121.02775554672925,
              21.457551614722092
            ],
            [
              120.71248872208028,
              21.635297384859456
            ],
            [
              120.59998880177616,
              22.05298561667763
            ],
            [
              120.66219875770614,
              22.249254821356544
            ]
          ],
          [
            [
              113.56112378817429,
              22.156493825893108
            ],
            [
              113.6249937429283,
              21.84429407917378
            ],
            [
              113.84999358353613,
              21.635297384859456
            ],
            [
              114.74999294596827,
              21.21639789994191
            ],
            [
              115.19999262718417,
              20.796306105108954
            ],
            [
              116.3249918302242,
              20.16397503197578
            ],
            [
              116.99999135204828,
              19.952622905164304
            ]
          ],
          [
            [
              115.85731216153286,
              -31.95344133032441
            ],
            [
              113.84999358353613,
              -31.276331888746853
            ],
            [
              112.49999453988826,
              -30.309953344646914
            ],
            [
              110.24999613380825,
              -27.153831285391675
            ],
            [
              107.99999772713235,
              -20.433922197637315
            ],
            [
              104.40000027800002,
              -11.943944931746927
            ],
            [
              104.40000027800002,
              -7.509810688339655
            ],
            [
              104.62500011860826,
              -6.616650693475464
            ],
            [
              105.29999964043216,
              -5.945707155070551
            ],
            [
              105.74999932164823,
              -5.889756955647925
            ],
            [
              106.31249892316825,
              -5.385957847172989
            ],
            [
              106.64999868408003,
              -5.161910662112973
            ],
            [
              106.98749844499216,
              -4.601453764837203
            ],
            [
              107.04374840514416,
              -3.029995968008762
            ],
            [
              106.93024848554879,
              -2.130918480960247
            ],
            [
              106.08749908256002,
              -0.781386636225506
            ],
            [
              105.41249956073611,
              0.118588418888407
            ],
            [
              104.84999995921609,
              0.456083447840484
            ],
            [
              104.17500043739219,
              0.570578752286423
            ],
            [
              103.55625087572,
              0.793562652607278
            ],
            [
              103.55625087572,
              1.01853421661562
            ],
            [
              103.64609081207684,
              1.338585852071589
            ],
            [
              103.50000091556822,
              1.201262219903469
            ],
            [
              103.34065102845304,
              1.243515071072778
            ],
            [
              102.68279723997185,
              1.642556216317956
            ],
            [
              102.15000187192001,
              1.918228780215685
            ],
            [
              101.25000250948804,
              2.255504211923693
            ],
            [
              100.23750322675212,
              3.266814816815753
            ],
            [
              99.33750386431998,
              4.613591578862867
            ],
            [
              98.55000442219213,
              5.286069860821101
            ],
            [
              97.42500521915215,
              6.069699469735895
            ],
            [
              95.40000665367998,
              6.516986153883067
            ],
            [
              94.27500745064,
              6.405200795356109
            ],
            [
              92.7000085663839,
              5.957818681088611
            ],
            [
              90.00001047908799,
              5.510071711803135
            ],
            [
              85.50001366692797,
              4.613591578862867
            ],
            [
              81.00001685476795,
              4.164912849976844
            ],
            [
              78.69513048464998,
              4.837826391986653
            ],
            [
              76.84024379574825,
              6.852191098754417
            ],
            [
              74.64513335370603,
              9.524411345019587
            ],
            [
              73.29513431005799,
              13.492128176464178
            ],
            [
              72.45002291166381,
              16.965102599435824
            ],
            [
              72.87590260996691,
              19.076074257285313
            ],
            [
              70.2000245055838,
              18.998067525949068
            ],
            [
              66.60002705585575,
              18.89166158430325
            ],
            [
              61.42503072187162,
              17.823934412537824
            ],
            [
              58.950032475183775,
              16.31838002635962
            ],
            [
              55.350035025455725,
              14.583511645118676
            ],
            [
              48.600039807215495,
              12.615395567393307
            ],
            [
              45.450042038703714,
              11.845776373625682
            ],
            [
              44.55004267627157,
              11.845776373625682
            ],
            [
              43.62191833376352,
              12.395734000022884
            ],
            [
              43.29848106288949,
              12.615395567393307
            ],
            [
              43.21410612266146,
              12.834868817846598
            ],
            [
              43.003168772091556,
              13.054150695298716
            ],
            [
              42.5250441107996,
              13.929303843271725
            ],
            [
              42.018794469431555,
              14.801154224791475
            ],
            [
              40.837545306240685,
              16.53419619825962
            ],
            [
              39.82504602350352,
              18.251816319028308
            ],
            [
              38.47504697985545,
              20.375041253465525
            ],
            [
              37.687547537727596,
              22.05298561667763
            ],
            [
              36.45004841438259,
              24.12261698700334
            ],
            [
              35.437549131647586,
              25.754704263415306
            ],
            [
              34.84692455005151,
              26.562513149236622
            ],
            [
              34.087550087999546,
              27.364667993860166
            ],
            [
              33.48321301611958,
              28.161052262220892
            ],
            [
              32.96260088492426,
              28.951554732193216
            ],
            [
              32.68135108416425,
              29.34456698948989
            ],
            [
              32.51257620372584,
              29.63833609362638
            ],
            [
              32.529931191431416,
              29.972545436050268
            ],
            [
              32.287551363135435,
              30.320465424761352
            ],
            [
              31.950051602223482,
              30.587136321812352
            ],
            [
              31.387552000703465,
              30.804773676604498
            ],
            [
              30.825052399183477,
              30.97376681878157
            ],
            [
              29.893513059094772,
              31.191465077638554
            ]
          ],
          [
            [
              127.68084378563216,
              26.212414126750332
            ],
            [
              126.8999843388003,
              26.562513149236622
            ],
            [
              125.54998529515225,
              26.562513149236622
            ],
            [
              124.64998593272028,
              26.159307970773796
            ]
          ],
          [
            [
              124.19998625150438,
              24.12261698700334
            ],
            [
              122.84998720785634,
              24.73717827217618
            ],
            [
              122.17498768603221,
              24.8393128255928
            ],
            [
              121.80144795065141,
              24.863504112487874
            ]
          ],
          [
            [
              121.06600847164387,
              13.762418337904336
            ],
            [
              120.14998912056026,
              13.710817738179543
            ],
            [
              118.79999007691222,
              14.14758350694865
            ],
            [
              116.99999135204828,
              14.365653759228536
            ],
            [
              115.64999230840024,
              14.801154224791475
            ]
          ],
          [
            [
              115.19999262718417,
              13.054150695298716
            ],
            [
              114.2999932647522,
              13.929303843271725
            ],
            [
              110.69999581502415,
              15.23578178303569
            ],
            [
              109.79999645259218,
              15.77780337181727
            ],
            [
              108.89999709016004,
              15.994209911785873
            ],
            [
              108.19247759137372,
              16.04339300520843
            ]
          ],
          [
            [
              98.67598433294691,
              3.752031394331624
            ],
            [
              99.33750386431998,
              4.613591578862867
            ]
          ],
          [
            [
              76.26955020587488,
              9.938386424893082
            ],
            [
              75.60002068017599,
              9.746236973759864
            ],
            [
              74.64513335370603,
              9.524411345019587
            ]
          ],
          [
            [
              110.92499565563222,
              5.957818681088611
            ],
            [
              112.94999422050827,
              5.435413643888296
            ],
            [
              114.2999932647522,
              4.949916167507411
            ],
            [
              114.88563284987973,
              4.926762452886777
            ]
          ],
          [
            [
              100.36299313785392,
              5.35384659465538
            ],
            [
              99.67500362523216,
              5.286069860821101
            ],
            [
              98.55000442219213,
              5.286069860821101
            ]
          ],
          [
            [
              79.86681765753698,
              6.833088156653076
            ],
            [
              79.31251805020796,
              5.957818681088611
            ],
            [
              78.47683671825345,
              5.033562173490822
            ]
          ],
          [
            [
              95.68213645381681,
              16.282761781662003
            ],
            [
              95.85000633489605,
              14.801154224791475
            ],
            [
              96.07500617490817,
              11.294709319565555
            ],
            [
              95.85000633430016,
              8.33898542602023
            ],
            [
              95.40000665367998,
              6.516986153883067
            ]
          ],
          [
            [
              116.99999135204828,
              19.952622905164304
            ],
            [
              116.54999167083221,
              18.251816319028308
            ],
            [
              115.64999230840024,
              14.801154224791475
            ],
            [
              115.19999262718417,
              13.054150695298716
            ],
            [
              114.18748535243108,
              9.96791518697421
            ],
            [
              112.49999453988826,
              7.744889052551343
            ],
            [
              110.92499565563222,
              5.957818681088611
            ],
            [
              110.24999613380825,
              5.510071711803135
            ],
            [
              107.99999772772824,
              4.501447394015287
            ],
            [
              107.0999983652961,
              3.940475772228723
            ],
            [
              105.74999932164823,
              3.154491498099929
            ],
            [
              105.29999964043216,
              2.803404866588352
            ],
            [
              105.01854983981409,
              1.468426767332062
            ],
            [
              105.41249956073611,
              0.118588418888407
            ]
          ],
          [
            [
              116.99999135204828,
              19.952622905164304
            ],
            [
              116.99999135204828,
              20.796306105108954
            ],
            [
              116.88749143174417,
              22.469443964829594
            ],
            [
              116.67753158048177,
              23.355006811273626
            ],
            [
              117.22499119265612,
              22.884654113882362
            ],
            [
              118.34999039569615,
              22.261369678340685
            ],
            [
              118.79999007691222,
              22.05298561667763
            ],
            [
              120.12788799225589,
              21.562216383585792
            ],
            [
              121.02775554672925,
              21.457551614722092
            ]
          ],
          [
            [
              128.62078311977027,
              34.88072781981958
            ],
            [
              128.02498354184027,
              34.31215165223537
            ],
            [
              127.34998402001636,
              33.18971466460036
            ],
            [
              127.34998402001636,
              32.43331330641712
            ],
            [
              125.99998497636832,
              29.540507745394393
            ],
            [
              125.77498513576032,
              28.55704546571141
            ],
            [
              124.64998593272028,
              26.159307970773796
            ],
            [
              124.53748601241617,
              25.55188275942578
            ],
            [
              124.19998625150438,
              24.12261698700334
            ],
            [
              123.29998688907224,
              22.05298561667763
            ],
            [
              122.84998720785634,
              21.635297384859456
            ],
            [
              121.02775554672925,
              21.457551614722092
            ]
          ],
          [
            [
              43.147993669496344,
              11.594869371447714
            ],
            [
              43.6500433138396,
              11.763112428849638
            ],
            [
              44.55004267627157,
              11.845776373625682
            ]
          ],
          [
            [
              66.60002705585575,
              18.89166158430325
            ],
            [
              65.92502753403164,
              20.375041253465525
            ],
            [
              66.37502721524771,
              23.298598065875808
            ],
            [
              67.02854675228852,
              24.889731701235718
            ]
          ],
          [
            [
              37.687547537727596,
              22.05298561667763
            ],
            [
              39.182756478507656,
              21.481533475503085
            ]
          ],
          [
            [
              114.183973346942,
              22.24961578335288
            ],
            [
              114.2999932647522,
              22.05298561667763
            ],
            [
              115.64999230840024,
              22.10511054810837
            ],
            [
              117.44999103326418,
              21.94867813792706
            ],
            [
              118.34999039569615,
              22.261369678340685
            ]
          ],
          [
            [
              105.29999964043216,
              2.803404866588352
            ],
            [
              104.4562502381522,
              2.367912558705314
            ],
            [
              103.85068066714334,
              2.295702456949584
            ]
          ]
        ]
      }
    }
  ]
}

import ThreeGlobe from "three-globe";
import { WebGLRenderer, Scene } from "three";
import {
  PerspectiveCamera,
  AmbientLight,
  DirectionalLight,
  Color,
  Fog,
  AxesHelper,
  DirectionalLightHelper,
  PointLightHelper,
  CameraHelper,
  OctahedronGeometry,
  PointLight,
  MeshLambertMaterial,
  Mesh,
  SphereGeometry,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import countries from "./files/globe-data-min.json";
import awsRegionsData from "./files/regions-data-aws.json";
import googleRegionsData from "./files/regions-data-google.json";
import azureRegionsData from "./files/regions-data-azure.json";
import labelfont from "../assets/src/files/helvetiker_bold.typeface.json"

var renderer, camera, scene, controls;
let mouseX = 0;
let mouseY = 0;
let windowHalfX = window.innerWidth / 2;
let windowHalfY = window.innerHeight / 2;
var GlobeRegionsAWS;
var GlobeRegionsGoogle;
var GlobeRegionsAzure;
let isLongName = true; // Default display mode
let selectedProvider = 'AWS'; // Default provider
let showLocalZones = false; // Default local zones visibility
let showPoPs = false; // Default PoPs visibility
let showCables = false;
let cablePaths = [];


init();
initGlobes();
onWindowResize();
animate();

// SECTION Initializing core ThreeJS elements
function init() {
  // Initialize renderer
  renderer = new WebGLRenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  // renderer.outputEncoding = THREE.sRGBEncoding;
  document.body.appendChild(renderer.domElement);

  // Initialize scene, light
  scene = new Scene();
  scene.add(new AmbientLight(0xbbbbbb, Math.PI));
  scene.background = new Color(0x191b20);

  // Initialize camera, light
  camera = new PerspectiveCamera();
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  var dLight = new DirectionalLight(0xd4dada, 2.5 * Math.PI);
  dLight.position.set(-800, 2000, 400);
  camera.add(dLight);

  var dLight1 = new DirectionalLight(0xd4dada, 0.1 * Math.PI);
  dLight1.position.set(-200, 500, 200);
  camera.add(dLight1);

  var dLight2 = new PointLight(0xd4dada, 1 * Math.PI);
  dLight2.position.set(-200, 500, 200);
  camera.add(dLight2);

  camera.position.z = 300;
  camera.position.x = 0;
  camera.position.y = 150;

  scene.add(camera);

  // Additional effects
  scene.fog = new Fog(0x535ef3, 400, 2000);

  // Helpers
  // const axesHelper = new AxesHelper(800);
  // scene.add(axesHelper);
  // var helper = new DirectionalLightHelper(dLight1);
  // scene.add(helper);
  // var pointHelper = new PointLightHelper(dLight2);
  // scene.add(pointHelper);
  // var helperCamera = new CameraHelper(dLight.shadow.camera);
  // scene.add(helperCamera);

  // Initialize controls
  controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true;
  controls.dynamicDampingFactor = 0.01;
  controls.enablePan = false;
  controls.minDistance = 170;
  controls.maxDistance = 500;
  controls.rotateSpeed = 0.8;
  controls.zoomSpeed = 0.5;
  controls.autoRotate = true;
  controls.autoRotateSpeed = 0.05;

  //controls.minPolarAngle = Math.PI / 3.5;
  //controls.maxPolarAngle = Math.PI - Math.PI / 3;

  window.addEventListener("resize", onWindowResize, false);
  document.addEventListener("mousemove", onMouseMove);
  //document.addEventListener("mousedown", onMouseDown);
  //document.addEventListener("mouseup", onMouseUp);
  //document.addEventListener("touchstart", onMouseDown, false);
  //document.addEventListener("touchend", onMouseUp, false);
  document.getElementById('cloudProviderSelect').addEventListener('change', (event) => {
    selectedProvider = event.target.value;
    loadProviderData(selectedProvider);
  });
  document.getElementById('nameDisplaySelect').addEventListener('change', updateNameDisplay);
  document.getElementById('localZonesCheckbox').addEventListener('change', toggleLocalZones);
  document.getElementById('popCheckbox').addEventListener('change', togglePoPs);
  document.getElementById('cloudProviderSelect').addEventListener('change', uncheckCheckboxes);
  document.getElementById('cables').addEventListener('change', uncheckCables);

  cableData.features.forEach(({ geometry, properties }) => {
    geometry.coordinates.forEach(coords => cablePaths.push({ coords, properties }));
  });

}

function uncheckCables() {
  showCables = showCables ? false : true;
  // Check which globe is currently visible
  if (selectedProvider === 'AWS') {
    updateGlobe(GlobeRegionsAWS, awsRegionsData);
  } else if (selectedProvider === 'Google') {
    updateGlobe(GlobeRegionsGoogle, googleRegionsData);
  } else if (selectedProvider === 'Azure') {
    updateGlobe(GlobeRegionsAzure, azureRegionsData);
  }
}


function uncheckCheckboxes() {
  document.getElementById('localZonesCheckbox').checked = false;
  document.getElementById('popCheckbox').checked = false;
  document.getElementById('cables').checked = false;
  document.getElementById('nameDisplaySelect').value = 'longName';
}

function initGlobes() {
  if (!GlobeRegionsAWS && !GlobeRegionsGoogle) {
    GlobeRegionsAWS = initGlobe(true, awsRegionsData);
    GlobeRegionsGoogle = initGlobe(false, googleRegionsData);
    GlobeRegionsAzure = initGlobe(false, azureRegionsData);

  }
}

function loadProviderData(provider) {
  isLongName = true; // Default display mode
  showLocalZones = false; // Default local zones visibility
  showPoPs = false; // Default PoPs visibility
  showCables = false;

  switch (provider) {
    case 'aws':
      scene.remove(GlobeRegionsGoogle);
      scene.remove(GlobeRegionsAzure);
      scene.add(GlobeRegionsAWS);
      selectedProvider = 'AWS';
      break;
    case 'google':
      scene.remove(GlobeRegionsAWS);
      scene.remove(GlobeRegionsAzure);
      scene.add(GlobeRegionsGoogle);
      selectedProvider = 'Google';
      break;
    case 'azure':
      scene.remove(GlobeRegionsAWS);
      scene.remove(GlobeRegionsGoogle);
      scene.add(GlobeRegionsAzure); // Add Azure globe
      selectedProvider = 'Azure';
      break;
    default:
      console.error('Unknown provider:', provider);
  }
}


function toggleLocalZones() {
  showLocalZones = showLocalZones ? false : true;

  // Check which globe is currently visible
  if (selectedProvider === 'AWS') {
    updateGlobe(GlobeRegionsAWS, awsRegionsData);
  } else if (selectedProvider === 'Google') {
    updateGlobe(GlobeRegionsGoogle, googleRegionsData);
  } else if (selectedProvider === 'Azure') {
    updateGlobe(GlobeRegionsAzure, azureRegionsData);
  }

}

function togglePoPs() {
  showPoPs = showPoPs ? false : true;

  // Check which globe is currently visible
  if (selectedProvider === 'AWS') {
    updateGlobe(GlobeRegionsAWS, awsRegionsData);
  } else if (selectedProvider === 'Google') {
    updateGlobe(GlobeRegionsGoogle, googleRegionsData);
  } else if (selectedProvider === 'Azure') {
    updateGlobe(GlobeRegionsAzure, azureRegionsData);
  }

}


function updateNameDisplay() {
  isLongName = isLongName ? false : true;

  // Check which globe is currently visible
  if (selectedProvider === 'AWS') {
    updateGlobe(GlobeRegionsAWS, awsRegionsData);
  } else if (selectedProvider === 'Google') {
    updateGlobe(GlobeRegionsGoogle, googleRegionsData);
  } else if (selectedProvider === 'Azure') {
    updateGlobe(GlobeRegionsAzure, azureRegionsData);
  }
}


function updateGlobe(globe, regionsData) {
  // Filter data based on local zones visibility
  const filteredRegionsData = regionsData.regions.filter((e) =>
    e.type === "Region" || (showLocalZones && e.type === "Local Zone") || (showPoPs && e.type === "PoP"));

  // Update the labels on the globe
  globe
    .labelsData(filteredRegionsData.map((e) => {
      return {
        ...e,
        name: isLongName ? e.longName : e.name,
      };
    }))
    .labelsTransitionDuration(0)
    .labelColor((e) => {
      if (e.type == "Region") {
        if (e.status == "available") {
          return "#f1f3f3";
        }
        return "#ff6633";
      } else if (e.type == "Local Zone") {
        return "#ffee53"
      } else if (e.type == "PoP") {
        return "#337aff"
      } else return "#ff6633";
    })
    .labelDotRadius((e) => {
      if (e.type == "Region") {
        return 1;
      } else if (e.type == "Local Zone") {
        return 0.5;
      } else if (e.type == "PoP") {
        return 0.4;
      } else return 0;
    })
    .labelSize((e) => {
      if (e.type == "Region") {
        return 1.2;
      } else if (e.type == "Local Zone") {
        return 0.8;
      } else if (e.type == "PoP") {
        return 0.7;
      } else return 0;
    })
    .labelText((e) => {
      if (e.type == "Region") {
        return isLongName ? e.longName : e.name;
      } else if (e.type == "Local Zone") {
        return isLongName ? e.longName : e.name;
      } else if (e.type == "PoP") {
        return isLongName ? e.longName : e.name;
      } else return "";
    })
    .labelResolution(6)
    .labelAltitude((e) => {
      if (e.type == "Region") {
        return 0.02;
      } else if (e.type == "Local Zone") {
        return 0.01;
      } else if (e.type == "PoP") {
        return 0.005;
      } else return 0;
    })
    .labelDotOrientation((e) => {
      if (e.type == "Region") {
        return "right";
      } else return "left";
    })
    .labelTypeFace(labelfont)
    .pointsData(filteredRegionsData.filter((e) => e.type == "Region"))
    .pointColor((e) => {
      if (e.type == "Region") {
        if (e.status == "available") {
          return "#f1f3f3";
        }
        return "#ff6633";
      } else if (e.type == "Local Zone") {
        return "#ffee53"
      } else if (e.type == "PoP") {
        return "#337aff"
      } else return "#ff6633";
    })
    .pointsMerge(true)
    .pointAltitude(0)
    .pointRadius(0.05)
    .pathsData(showCables ? cablePaths : []);

}



// SECTION Globe
function initGlobe(addToScene, regionsData) {
  // Initialize the Globe
  let Globe = new ThreeGlobe({
    waitForGlobeReady: true,
    animateIn: true,
  })
    .hexPolygonsData(countries.features)
    .hexPolygonResolution(3)
    .hexPolygonMargin(0.7)
    .objectLat('lat')
    .objectLng('lng')
    .objectAltitude('alt')
    .objectFacesSurface(false)
    .showAtmosphere(true)
    .atmosphereColor("#232F3E")
    .atmosphereAltitude(0.25)
    .hexPolygonColor((e) => {
      if (
        [""].includes(
          e.properties.ISO_A3
        )
      ) {
        return "rgba(80,80,0, 0.8)";
      } else return "rgba(255,153,0, 0.8)";
    });

  // Filter data based on local zones visibility
  const filteredRegionsData = regionsData.regions.filter((e) =>
    e.type === "Region" || (showLocalZones && e.type === "Local Zone") || (showPoPs && e.type === "PoP"));

  // NOTE Arc animations are followed after the globe enters the scene
  Globe
    // .ringsData(regionsData.regions)
    // .ringAltitude(0.05)
    // .ringMaxRadius(2)
    // .ringColor(() => "#f1f3f3")
    // .ringRepeatPeriod(100)
    .labelsData(filteredRegionsData.map((e) => {
      return {
        ...e,
        name: isLongName ? e.longName : e.name,
      };
    }))
    .labelColor((e) => {
      if (e.type == "Region") {
        if (e.status == "available") {
          return "#f1f3f3";
        }
        return "#ff6633";
      } else if (e.type == "Local Zone") {
        return "#ffee53"
      } else if (e.type == "PoP") {
        return "#337aff"
      } else return "#ff6633";
    })
    .labelDotRadius((e) => {
      if (e.type == "Region") {
        return 1;
      } else if (e.type == "Local Zone") {
        return 0.5;
      } else if (e.type == "PoP") {
        return 0.4;
      } else return 0;
    })
    .labelSize((e) => {
      if (e.type == "Region") {
        return 1.2;
      } else if (e.type == "Local Zone") {
        return 0.8;
      } else if (e.type == "PoP") {
        return 0.7;
      } else return 0;
    })
    .labelText((e) => {
      if (e.type == "Region") {
        return isLongName ? e.longName : e.name;
      } else if (e.type == "Local Zone") {
        return isLongName ? e.longName : e.name;
      } else if (e.type == "PoP") {
        return isLongName ? e.longName : e.name;
      } else return "";
    })
    .labelResolution(6)
    .labelAltitude((e) => {
      if (e.type == "Region") {
        return 0.02;
      } else if (e.type == "Local Zone") {
        return 0.01;
      } else if (e.type == "PoP") {
        return 0.005;
      } else return 0;
    })
    .labelDotOrientation((e) => {
      if (e.type == "Region") {
        return "right";
      } else return "left";
    })
    .labelTypeFace(labelfont)
    .pointsData(filteredRegionsData.filter((e) => e.type == "Region"))
    .pointColor((e) => {
      if (e.type == "Region") {
        if (e.status == "available") {
          return "#f1f3f3";
        }
        return "#ff6633";
      } else if (e.type == "Local Zone") {
        return "#ffee53"
      } else if (e.type == "PoP") {
        return "#337aff"
      } else return "#ff6633";
    })
    .pointsMerge(true)
    .pointAltitude((e) => { return 0 })
    .pointRadius(0.05)
    .pathsData([])
    .pathPoints("coords")
    .pathColor(() => "#abbbd4")
    .pathDashLength(0.2)
    .pathStroke(0.5)
    .pathDashGap(0.008)
    .pathDashAnimateTime(24000)
    .pathPointLat(p => p[1])
    .pathPointLng(p => p[0]);



  const EARTH_RADIUS_KM = 6371; // km
  const SAT_SIZE = 80; // km
  const TIME_STEP = 0.5 * 1000; // per frame

  const satGeometry = new OctahedronGeometry(SAT_SIZE * 100 / EARTH_RADIUS_KM / 2, 0);
  const satMaterial = new MeshLambertMaterial({ color: 'palegreen', transparent: true, opacity: 0.3 });
  Globe.objectThreeObject(() => new Mesh(satGeometry, satMaterial));



  const tleData = satelitesData.replace(/\r/g, '').split(/\n(?=[^12])/).map(tle => tle.split('\n'));
  const satData = tleData.map(([name, ...tle]) => ({
    satrec: satellite.twoline2satrec(...tle),
    name: name.trim().replace(/^0 /, '')
  }));

  // time ticker
  let time = new Date();
  (function frameTicker() {
    requestAnimationFrame(frameTicker);

    time = new Date(+time + TIME_STEP);

    // Update satellite positions
    const gmst = satellite.gstime(time);
    satData.forEach(d => {
      const eci = satellite.propagate(d.satrec, time);
      if (eci.position) {
        const gdPos = satellite.eciToGeodetic(eci.position, gmst);
        d.lat = satellite.radiansToDegrees(gdPos.latitude);
        d.lng = satellite.radiansToDegrees(gdPos.longitude);
        d.alt = 1;
      }
    });

    Globe.objectsData(satData);
  })();




  //Globe.rotateY(-Math.PI * (5 / 9));
  //Globe.rotateZ(-Math.PI / 6);
  const globeMaterial = Globe.globeMaterial();
  globeMaterial.color = new Color(0x232F3E);
  globeMaterial.emissive = new Color(0x232F3E);
  globeMaterial.emissiveIntensity = 0.1;
  globeMaterial.shininess = 0.7;

  // NOTE Cool stuff
  // globeMaterial.wireframe = true;
  if (addToScene) {
    scene.add(Globe);
  }

  return Globe;
}

function onMouseMove(event) {
  mouseX = event.clientX - windowHalfX;
  mouseY = event.clientY - windowHalfY;
  // console.log("x: " + mouseX + " y: " + mouseY);
}


function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  windowHalfX = window.innerWidth / 1.5;
  windowHalfY = window.innerHeight / 1.5;
  renderer.setSize(window.innerWidth, window.innerHeight);
}

function animate() {
  //camera.position.x +=
  //  Math.abs(mouseX) <= windowHalfX / 2
  //    ? (mouseX / 2 - camera.position.x) * 0.005
  //    : 0;
  //camera.position.y += (-mouseY / 2 - camera.position.y) * 0.005;
  camera.lookAt(scene.position);
  controls.update();
  renderer.render(scene, camera);
  requestAnimationFrame(animate);
}
